export const airportData = [
  {
    airport_id: 1,
    airport_code: "ANW",
    airport_name: "Ainsworth Minicipal Arpt",
    airport_city_code: "ANW",
    airport_city_name: "Ainsworth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "42.58",
    airport_lon: "-99.9933",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2,
    airport_code: "ANU",
    airport_name: "V C Bird Intl Arpt",
    airport_city_code: "ANU",
    airport_city_name: "Antigua",
    airport_country_name: "ANTIGUA AND BARBUDA",
    airport_country_code: "AG",
    airport_timezone: "-4",
    airport_lat: "17.136749",
    airport_lon: "-61.792667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3,
    airport_code: "ZWE",
    airport_name: "Antwerp Brussels North",
    airport_city_code: "ANR",
    airport_city_name: "Antwerp",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "1",
    airport_lat: "51.189444",
    airport_lon: "4.460278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4,
    airport_code: "ANP",
    airport_name: "Lee Annapolis Arpt",
    airport_city_code: "ANP",
    airport_city_name: "Annapolis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 5,
    airport_code: "ANI",
    airport_name: "Aniak Arpt",
    airport_city_code: "ANI",
    airport_city_name: "Aniak",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "61.5816",
    airport_lon: "-159.543",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 6,
    airport_code: "ESB",
    airport_name: "Esenboga Arpt",
    airport_city_code: "ANK",
    airport_city_name: "Ankara",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.128082",
    airport_lon: "32.995083",
    airport_num_airports: 1,
  },
  {
    airport_id: 7,
    airport_code: "ANG",
    airport_name: "Brie Champniers",
    airport_city_code: "ANG",
    airport_city_name: "Angouleme",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.729247",
    airport_lon: "0.221456",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 8,
    airport_code: "ANF",
    airport_name: "Cerro Moreno Arpt",
    airport_city_code: "ANF",
    airport_city_name: "Antofagasta",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-23.444478",
    airport_lon: "-70.4451",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 9,
    airport_code: "ANE",
    airport_name: "Marce Arpt",
    airport_city_code: "ANE",
    airport_city_name: "Angers",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.5603",
    airport_lon: "-0.312222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 10,
    airport_code: "AND",
    airport_name: "Anderson Arpt",
    airport_city_code: "AND",
    airport_city_name: "Anderson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.494583",
    airport_lon: "-82.709389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 11,
    airport_code: "ANC",
    airport_name: "Anchorage Intl Arpt",
    airport_city_code: "ANC",
    airport_city_name: "Anchorage",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "61.174361",
    airport_lon: "-149.996361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 12,
    airport_code: "ANB",
    airport_name: "Anniston Municipal Arpt",
    airport_city_code: "ANB",
    airport_city_name: "Anniston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.588167",
    airport_lon: "-85.858111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 13,
    airport_code: "ANA",
    airport_name: "Anaheim Arpt",
    airport_city_code: "ANA",
    airport_city_name: "Anaheim",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 14,
    airport_code: "AMW",
    airport_name: "Ames Minicipal Arpt",
    airport_city_code: "AMW",
    airport_city_name: "Ames",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 15,
    airport_code: "AMV",
    airport_name: "Amderma Airport",
    airport_city_code: "AMV",
    airport_city_name: "Amderma Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "69.7633",
    airport_lon: "61.5564",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 16,
    airport_code: "AMS",
    airport_name: "Schiphol Arpt",
    airport_city_code: "AMS",
    airport_city_name: "Amsterdam",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "52.308613",
    airport_lon: "4.763889",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 17,
    airport_code: "ZYA",
    airport_name: "Amsterdam Central Rail Station",
    airport_city_code: "AMS",
    airport_city_name: "Amsterdam",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "52.7873",
    airport_lon: "4.90074",
    airport_num_airports: 2,
  },
  {
    airport_id: 18,
    airport_code: "AMQ",
    airport_name: "Pattimura Arpt",
    airport_city_code: "AMQ",
    airport_city_name: "Ambon",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-3.710264",
    airport_lon: "128.089136",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 19,
    airport_code: "AMM",
    airport_name: "Queen Alia Intl Arpt",
    airport_city_code: "AMM",
    airport_city_name: "Amman",
    airport_country_name: "JORDAN",
    airport_country_code: "JO",
    airport_timezone: "2",
    airport_lat: "31.722556",
    airport_lon: "35.993214",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 20,
    airport_code: "ADJ",
    airport_name: "Civil Marka Arpt",
    airport_city_code: "AMM",
    airport_city_name: "Amman",
    airport_country_name: "JORDAN",
    airport_country_code: "JO",
    airport_timezone: "2",
    airport_lat: "31.972703",
    airport_lon: "35.991569",
    airport_num_airports: 2,
  },
  {
    airport_id: 21,
    airport_code: "AMI",
    airport_name: "Selaparang Airport",
    airport_city_code: "AMI",
    airport_city_name: "Mataram",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "-8.560708",
    airport_lon: "116.094656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 22,
    airport_code: "AMD",
    airport_name: "Sardar Vallabh Bhai Patel Intl Arpt",
    airport_city_code: "AMD",
    airport_city_name: "Ahmedabad",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "23.077242",
    airport_lon: "72.63465",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 23,
    airport_code: "AMA",
    airport_name: "Amarillo Intl Arpt",
    airport_city_code: "AMA",
    airport_city_name: "Amarillo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.219369",
    airport_lon: "-101.705931",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 24,
    airport_code: "TDW",
    airport_name: "Tradewind Airport",
    airport_city_code: "AMA",
    airport_city_name: "Amarillo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 25,
    airport_code: "ALY",
    airport_name: "El Nouzha Arpt",
    airport_city_code: "ALY",
    airport_city_name: "Alexandria",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "31.183903",
    airport_lon: "29.948889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 26,
    airport_code: "ALX",
    airport_name: "Thomas C Russell Field",
    airport_city_code: "ALX",
    airport_city_name: "Alexander City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.806346",
    airport_lon: "-77.0621",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 27,
    airport_code: "ALW",
    airport_name: "Walla Walla City County",
    airport_city_code: "ALW",
    airport_city_name: "Walla Walla",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.0949",
    airport_lon: "-118.288",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 28,
    airport_code: "ALS",
    airport_name: "Alamosa Municipal",
    airport_city_code: "ALS",
    airport_city_name: "Alamosa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.435",
    airport_lon: "-105.866667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 29,
    airport_code: "ALR",
    airport_name: "Alexandra Arpt",
    airport_city_code: "ALR",
    airport_city_name: "Alexandra",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-45.211666",
    airport_lon: "169.373333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 30,
    airport_code: "ALP",
    airport_name: "Nejrab Arpt",
    airport_city_code: "ALP",
    airport_city_name: "Aleppo",
    airport_country_name: "SYRIA",
    airport_country_code: "SY",
    airport_timezone: "2",
    airport_lat: "36.180675",
    airport_lon: "37.224358",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 31,
    airport_code: "ALO",
    airport_name: "Livingston Betsworth Fld",
    airport_city_code: "ALO",
    airport_city_name: "Waterloo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.5571",
    airport_lon: "-92.4003",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 32,
    airport_code: "ALM",
    airport_name: "Alamogordo Municipal",
    airport_city_code: "ALM",
    airport_city_name: "Alamogordo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.8399",
    airport_lon: "-105.991",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 33,
    airport_code: "ALL",
    airport_name: "Albenga Arpt",
    airport_city_code: "ALL",
    airport_city_name: "Albenga",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.050608",
    airport_lon: "8.127428",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 34,
    airport_code: "ALJ",
    airport_name: "Kortdoorn Arpt",
    airport_city_code: "ALJ",
    airport_city_name: "Alexander Bay",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-28.575001",
    airport_lon: "16.533333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 35,
    airport_code: "ALH",
    airport_name: "Albany Airport",
    airport_city_code: "ALH",
    airport_city_name: "Albany",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-34.9433",
    airport_lon: "117.809",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 36,
    airport_code: "ALG",
    airport_name: "Houari Boumedienne Arpt",
    airport_city_code: "ALG",
    airport_city_name: "Algiers",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "36.691014",
    airport_lon: "3.215408",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 37,
    airport_code: "ALF",
    airport_name: "Elvebakken Arpt",
    airport_city_code: "ALF",
    airport_city_name: "Alta",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "69.976111",
    airport_lon: "23.371667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 38,
    airport_code: "ALE",
    airport_name: "Alpine Aprt",
    airport_city_code: "ALE",
    airport_city_name: "Alpine",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 39,
    airport_code: "ALC",
    airport_name: "Alicante Arpt",
    airport_city_code: "ALC",
    airport_city_name: "Alicante",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "38.282169",
    airport_lon: "-0.558156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 40,
    airport_code: "ALB",
    airport_name: "Albany Cty Arpt",
    airport_city_code: "ALB",
    airport_city_name: "Albany",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.748267",
    airport_lon: "-73.801692",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 41,
    airport_code: "ALA",
    airport_name: "Almaty Arpt",
    airport_city_code: "ALA",
    airport_city_name: "Almaty",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "43.352072",
    airport_lon: "77.040508",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 42,
    airport_code: "AKX",
    airport_name: "Aqtobe Arpt",
    airport_city_code: "AKX",
    airport_city_name: "Aqtobe",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "5",
    airport_lat: "50.245833",
    airport_lon: "57.206667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 43,
    airport_code: "AKV",
    airport_name: "Akulivik Arpt",
    airport_city_code: "AKV",
    airport_city_name: "Akulivik",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "60.8186",
    airport_lon: "-78.1486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 44,
    airport_code: "AKU",
    airport_name: "Aksu Arpt",
    airport_city_code: "AKU",
    airport_city_name: "Aksu",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "41.2625",
    airport_lon: "80.2917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 45,
    airport_code: "AKN",
    airport_name: "King Salmon Arpt",
    airport_city_code: "AKN",
    airport_city_name: "King Salmon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "58.676778",
    airport_lon: "-156.649278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 46,
    airport_code: "AKL",
    airport_name: "Auckland Arpt",
    airport_city_code: "AKL",
    airport_city_name: "Auckland",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-37.008056",
    airport_lon: "174.791667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 47,
    airport_code: "AKJ",
    airport_name: "Asahikawa Arpt",
    airport_city_code: "AKJ",
    airport_city_name: "Asahikawa",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "43.670833",
    airport_lon: "142.4475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 48,
    airport_code: "AJU",
    airport_name: "Santa Maria Arpt",
    airport_city_code: "AJU",
    airport_city_name: "Aracaju",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-10.984",
    airport_lon: "-37.070333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 49,
    airport_code: "AJR",
    airport_name: "Arvidsjaur Arpt",
    airport_city_code: "AJR",
    airport_city_name: "Arvidsjaur",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "65.590278",
    airport_lon: "19.281944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 50,
    airport_code: "AJI",
    airport_name: "Agri Airport",
    airport_city_code: "AJI",
    airport_city_name: "Agri",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.6546",
    airport_lon: "43.0271",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 51,
    airport_code: "AJF",
    airport_name: "JOUF",
    airport_city_code: "AJF",
    airport_city_name: "Al Jouf",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "1",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 52,
    airport_code: "AJA",
    airport_name: "Campo Dell Oro Arpt",
    airport_city_code: "AJA",
    airport_city_name: "Ajaccio",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "41.923637",
    airport_lon: "8.802917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 53,
    airport_code: "AIZ",
    airport_name: "Lee C Fine Memorial",
    airport_city_code: "AIZ",
    airport_city_name: "Lake Of The Ozarks",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.096035",
    airport_lon: "-92.549488",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 54,
    airport_code: "ACY",
    airport_name: "Pomona Field",
    airport_city_code: "AIY",
    airport_city_name: "Atlantic City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.457583",
    airport_lon: "-74.577167",
    airport_num_airports: 2,
  },
  {
    airport_id: 55,
    airport_code: "AIY",
    airport_name: "Bader Field",
    airport_city_code: "AIY",
    airport_city_name: "Atlantic City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 56,
    airport_code: "AIT",
    airport_name: "Aitutaki Arpt",
    airport_city_code: "AIT",
    airport_city_name: "Aitutaki",
    airport_country_name: "COOK ISLANDS",
    airport_country_code: "CK",
    airport_timezone: "-10",
    airport_lat: "-18.830922",
    airport_lon: "-159.764233",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 57,
    airport_code: "AIN",
    airport_name: "Wainwright Municipal Arpt",
    airport_city_code: "AIN",
    airport_city_name: "Wainwright",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "70.638056",
    airport_lon: "-159.994722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 58,
    airport_code: "AIK",
    airport_name: "Aiken Municipal Arpt",
    airport_city_code: "AIK",
    airport_city_name: "Aiken",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 59,
    airport_code: "AID",
    airport_name: "Anderson Municipal Arpt",
    airport_city_code: "AID",
    airport_city_name: "Anderson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.1087",
    airport_lon: "-85.613",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 60,
    airport_code: "AIA",
    airport_name: "Alliance Municipal",
    airport_city_code: "AIA",
    airport_city_name: "Alliance",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "42.053333",
    airport_lon: "-102.803889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 61,
    airport_code: "AHU",
    airport_name: "Charif Al Idrissi Arpt",
    airport_city_code: "AHU",
    airport_city_name: "Al Hoceima",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "35.177103",
    airport_lon: "-3.839525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 62,
    airport_code: "AHO",
    airport_name: "Fertilia Arpt",
    airport_city_code: "AHO",
    airport_city_name: "Alghero",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "40.632133",
    airport_lon: "8.290772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 63,
    airport_code: "AHN",
    airport_name: "Athens Municipal",
    airport_city_code: "AHN",
    airport_city_name: "Athens",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.9486",
    airport_lon: "-83.3263",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 64,
    airport_code: "AHI",
    airport_name: "Amahai Airport",
    airport_city_code: "AHI",
    airport_city_name: "Amahai",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 65,
    airport_code: "AHB",
    airport_name: "Abha Airport",
    airport_city_code: "AHB",
    airport_city_name: "Abha",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "18.240367",
    airport_lon: "42.656625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 66,
    airport_code: "AGU",
    airport_name: "Aguascalientes Municipal Arpt",
    airport_city_code: "AGU",
    airport_city_name: "Aguascalientes",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "21.705558",
    airport_lon: "-102.317858",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 67,
    airport_code: "AGT",
    airport_name: "Alejo Garcia Arpt",
    airport_city_code: "AGT",
    airport_city_name: "Ciudad Del Este",
    airport_country_name: "PARAGUAY",
    airport_country_code: "PY",
    airport_timezone: "-4",
    airport_lat: "-25.4555",
    airport_lon: "-54.843592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 68,
    airport_code: "AGS",
    airport_name: "Bush Field",
    airport_city_code: "AGS",
    airport_city_name: "Augusta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.369944",
    airport_lon: "-81.9645",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 69,
    airport_code: "DNL",
    airport_name: "Daniel Airport",
    airport_city_code: "AGS",
    airport_city_name: "Augusta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "33.466503",
    airport_lon: "-82.039381",
    airport_num_airports: 2,
  },
  {
    airport_id: 70,
    airport_code: "AGR",
    airport_name: "Kheria Arpt",
    airport_city_code: "AGR",
    airport_city_name: "Agra",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "27.155831",
    airport_lon: "77.960892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 71,
    airport_code: "AGQ",
    airport_name: "Agrinion Arpt",
    airport_city_code: "AGQ",
    airport_city_name: "Agrinion",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "38.602022",
    airport_lon: "21.351208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 72,
    airport_code: "AGP",
    airport_name: "Malaga Arpt",
    airport_city_code: "AGP",
    airport_city_name: "Malaga",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "36.6749",
    airport_lon: "-4.499106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 73,
    airport_code: "AGO",
    airport_name: "Magnolia Municipal",
    airport_city_code: "AGO",
    airport_city_name: "Magnolia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 74,
    airport_code: "AGN",
    airport_name: "Angoon Arpt",
    airport_city_code: "AGN",
    airport_city_name: "Angoon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "57.503611",
    airport_lon: "-134.585",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 75,
    airport_code: "AGH",
    airport_name: "Angelholm Helsingborg Arpt",
    airport_city_code: "AGH",
    airport_city_name: "Angelholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "56.2961",
    airport_lon: "12.8471",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 76,
    airport_code: "AGF",
    airport_name: "La Garenne Arpt",
    airport_city_code: "AGF",
    airport_city_name: "Agen",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.174721",
    airport_lon: "0.590556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 77,
    airport_code: "AGE",
    airport_name: "Flugplatz Arpt",
    airport_city_code: "AGE",
    airport_city_name: "Wangerooge",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.7828",
    airport_lon: "7.91389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 78,
    airport_code: "AGB",
    airport_name: "Muehlhausen Arpt",
    airport_city_code: "AGB",
    airport_city_name: "Augsburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.425158",
    airport_lon: "10.931764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 79,
    airport_code: "AGA",
    airport_name: "Agadir Almassira Arpt",
    airport_city_code: "AGA",
    airport_city_name: "Agadir",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "30.381353",
    airport_lon: "-9.546311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 80,
    airport_code: "AFY",
    airport_name: "Afyon Arpt",
    airport_city_code: "AFY",
    airport_city_name: "Afyon Arpt",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.726425",
    airport_lon: "30.601114",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 81,
    airport_code: "AFL",
    airport_name: "Alta Floresta Arpt",
    airport_city_code: "AFL",
    airport_city_name: "Alta Floresta",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-9.866092",
    airport_lon: "-56.106206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 82,
    airport_code: "AFK",
    airport_name: "Kondavattavan Tank Arpt",
    airport_city_code: "AFK",
    airport_city_name: "Ampara",
    airport_country_name: "SRI LANKA",
    airport_country_code: "LK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 83,
    airport_code: "AFA",
    airport_name: "San Rafael Arpt",
    airport_city_code: "AFA",
    airport_city_name: "San Rafael",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-34.588314",
    airport_lon: "-68.403854",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 84,
    airport_code: "AEY",
    airport_name: "Akureyri",
    airport_city_code: "AEY",
    airport_city_name: "Akureyri",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "65.659994",
    airport_lon: "-18.072703",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 85,
    airport_code: "ESF",
    airport_name: "Esler Field",
    airport_city_code: "AEX",
    airport_city_name: "Alexandria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.394903",
    airport_lon: "-92.295772",
    airport_num_airports: 2,
  },
  {
    airport_id: 86,
    airport_code: "AEX",
    airport_name: "Alexandria Intl Arpt",
    airport_city_code: "AEX",
    airport_city_name: "Alexandria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.3274",
    airport_lon: "-92.549833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 87,
    airport_code: "AES",
    airport_name: "Vigra Airport",
    airport_city_code: "AES",
    airport_city_name: "Aalesund",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "62.560372",
    airport_lon: "6.110164",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 88,
    airport_code: "AER",
    airport_name: "Alder Sochi Arpt",
    airport_city_code: "AER",
    airport_city_name: "Adler Sochi",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "43.449928",
    airport_lon: "39.956589",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 89,
    airport_code: "AEL",
    airport_name: "Albert Lea Arpt",
    airport_city_code: "AEL",
    airport_city_name: "Albert Lea",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 90,
    airport_code: "AEI",
    airport_name: "Algeciras Arpt",
    airport_city_code: "AEI",
    airport_city_name: "Algeciras",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 91,
    airport_code: "ADZ",
    airport_name: "San Andres Island Arpt",
    airport_city_code: "ADZ",
    airport_city_name: "San Andres",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "12.583594",
    airport_lon: "-81.711192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 92,
    airport_code: "ADX",
    airport_name: "Leuchars Arpt",
    airport_city_code: "ADX",
    airport_city_name: "St Andrews",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "56.372889",
    airport_lon: "-2.868444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 93,
    airport_code: "ADW",
    airport_name: "Andrews Air Force Base",
    airport_city_code: "ADW",
    airport_city_name: "Camp Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.810806",
    airport_lon: "-76.867028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 94,
    airport_code: "ADU",
    airport_name: "Ardabil Arpt",
    airport_city_code: "ADU",
    airport_city_name: "Ardabil",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "38.325678",
    airport_lon: "48.424356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 95,
    airport_code: "ADT",
    airport_name: "Ada Municipal Arpt",
    airport_city_code: "ADT",
    airport_city_name: "Ada OK",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 96,
    airport_code: "ADQ",
    airport_name: "Kodiak Arpt",
    airport_city_code: "ADQ",
    airport_city_name: "Kodiak",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "57.749967",
    airport_lon: "-152.493856",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 97,
    airport_code: "KDK",
    airport_name: "Kodiak Municipal",
    airport_city_code: "ADQ",
    airport_city_name: "Kodiak",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 98,
    airport_code: "ADM",
    airport_name: "Ardmore Municipal Arpt",
    airport_city_code: "ADM",
    airport_city_name: "Ardmore",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.300833",
    airport_lon: "-97.008889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 99,
    airport_code: "ADL",
    airport_name: "Adelaide Arpt",
    airport_city_code: "ADL",
    airport_city_name: "Adelaide",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-34.945",
    airport_lon: "138.530556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 100,
    airport_code: "ADK",
    airport_name: "Adak Island Ns",
    airport_city_code: "ADK",
    airport_city_name: "Adak Island",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "51.878",
    airport_lon: "-176.646",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 101,
    airport_code: "ADH",
    airport_name: "Aldan Airport",
    airport_city_code: "ADH",
    airport_city_name: "Aldan Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 102,
    airport_code: "ADG",
    airport_name: "Lenawee County Arpt",
    airport_city_code: "ADG",
    airport_city_name: "Adrian",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 103,
    airport_code: "ADF",
    airport_name: "Adiyaman Airport",
    airport_city_code: "ADF",
    airport_city_name: "Adiyaman",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.7314",
    airport_lon: "38.4689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 104,
    airport_code: "ADE",
    airport_name: "Yemen Intl Arpt",
    airport_city_code: "ADE",
    airport_city_name: "Aden",
    airport_country_name: "YEMEN",
    airport_country_code: "YE",
    airport_timezone: "3",
    airport_lat: "12.829542",
    airport_lon: "45.028792",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 105,
    airport_code: "ADD",
    airport_name: "Bole Airport",
    airport_city_code: "ADD",
    airport_city_name: "Addis Ababa",
    airport_country_name: "ETHIOPIA",
    airport_country_code: "ET",
    airport_timezone: "3",
    airport_lat: "8.977889",
    airport_lon: "38.799319",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 106,
    airport_code: "ADA",
    airport_name: "Adana Arpt",
    airport_city_code: "ADA",
    airport_city_name: "Adana",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "36.982166",
    airport_lon: "35.280388",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 107,
    airport_code: "ACX",
    airport_name: "Xingyi Arpt",
    airport_city_code: "ACX",
    airport_city_name: "Xingyi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "25.0882",
    airport_lon: "104.9587",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 108,
    airport_code: "ACV",
    airport_name: "Arcata Eureka Arpt",
    airport_city_code: "ACV",
    airport_city_name: "Eureka",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "40.978111",
    airport_lon: "-124.108611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 109,
    airport_code: "ACT",
    airport_name: "Madison Cooper Arpt",
    airport_city_code: "ACT",
    airport_city_name: "Waco",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.611289",
    airport_lon: "-97.230519",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 110,
    airport_code: "ACS",
    airport_name: "Achinsk Airport",
    airport_city_code: "ACS",
    airport_city_name: "Achinsk Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 111,
    airport_code: "ACO",
    airport_name: "Ascona Arpt",
    airport_city_code: "ACO",
    airport_city_name: "Ascona",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 112,
    airport_code: "ACK",
    airport_name: "Nantucket Memorial",
    airport_city_code: "ACK",
    airport_city_name: "Nantucket",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.253053",
    airport_lon: "-70.060181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 113,
    airport_code: "ACI",
    airport_name: "The Blaye Arpt",
    airport_city_code: "ACI",
    airport_city_name: "Alderney",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "49.706111",
    airport_lon: "-2.214722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 114,
    airport_code: "ACH",
    airport_name: "Altenrhein Arpt",
    airport_city_code: "ACH",
    airport_city_name: "Altenrhein",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "47.485033",
    airport_lon: "9.560775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 115,
    airport_code: "ACE",
    airport_name: "Lanzarote Airport",
    airport_city_code: "ACE",
    airport_city_name: "Lanzarote",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "28.945464",
    airport_lon: "-13.605225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 116,
    airport_code: "ACC",
    airport_name: "Kotoka Airport",
    airport_city_code: "ACC",
    airport_city_name: "Accra",
    airport_country_name: "GHANA",
    airport_country_code: "GH",
    airport_timezone: "0",
    airport_lat: "5.605186",
    airport_lon: "-0.166786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 117,
    airport_code: "ACA",
    airport_name: "Alvarez Intl Arpt",
    airport_city_code: "ACA",
    airport_city_name: "Acapulco",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "16.757061",
    airport_lon: "-99.753953",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 118,
    airport_code: "ABZ",
    airport_name: "Dyce Airport",
    airport_city_code: "ABZ",
    airport_city_name: "Aberdeen",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "57.201944",
    airport_lon: "-2.197778",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 119,
    airport_code: "ZXA",
    airport_name: "Aberdeen ScotRail Station",
    airport_city_code: "ABZ",
    airport_city_name: "Aberdeen",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 120,
    airport_code: "ABY",
    airport_name: "Dougherty Cty Arpt",
    airport_city_code: "ABY",
    airport_city_name: "Albany",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "31.5355",
    airport_lon: "-84.1945",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 121,
    airport_code: "ABX",
    airport_name: "Albury Airport",
    airport_city_code: "ABX",
    airport_city_name: "Albury",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-36.067778",
    airport_lon: "146.958056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 122,
    airport_code: "ABV",
    airport_name: "Abuja Intl",
    airport_city_code: "ABV",
    airport_city_name: "Abuja",
    airport_country_name: "NIGERIA",
    airport_country_code: "NG",
    airport_timezone: "1",
    airport_lat: "9.006792",
    airport_lon: "7.263172",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 123,
    airport_code: "ABT",
    airport_name: "Al Aqiq",
    airport_city_code: "ABT",
    airport_city_name: "Al Baha",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "20.296139",
    airport_lon: "41.634277",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 124,
    airport_code: "ABS",
    airport_name: "Abu Simbel Arpt",
    airport_city_code: "ABS",
    airport_city_name: "Abu Simbel",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "22.375953",
    airport_lon: "31.611722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 125,
    airport_code: "ABR",
    airport_name: "Aberdeen Arpt",
    airport_city_code: "ABR",
    airport_city_name: "Aberdeen",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "45.4491",
    airport_lon: "-98.4218",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 126,
    airport_code: "ABQ",
    airport_name: "Albuquerque Intl Arpt",
    airport_city_code: "ABQ",
    airport_city_name: "Albuquerque",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.040222",
    airport_lon: "-106.609194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 127,
    airport_code: "ABM",
    airport_name: "Bamaga Arpt",
    airport_city_code: "ABM",
    airport_city_name: "Bamaga",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-10.950833",
    airport_lon: "142.459444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 128,
    airport_code: "ABL",
    airport_name: "Ambler Arpt",
    airport_city_code: "ABL",
    airport_city_name: "Ambler",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "67.106389",
    airport_lon: "-157.8575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 129,
    airport_code: "ABI",
    airport_name: "Abilene Municipal Arpt",
    airport_city_code: "ABI",
    airport_city_name: "Abilene",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.411319",
    airport_lon: "-99.681897",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 130,
    airport_code: "ABE",
    airport_name: "Lehigh Valley Intl Arpt",
    airport_city_code: "ABE",
    airport_city_name: "Allentown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.652083",
    airport_lon: "-75.440806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 131,
    airport_code: "ABD",
    airport_name: "Abadan Arpt",
    airport_city_code: "ABD",
    airport_city_name: "Abadan",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "30.371111",
    airport_lon: "48.228333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 132,
    airport_code: "ABC",
    airport_name: "Los Llanos Arpt",
    airport_city_code: "ABC",
    airport_city_name: "Albacete",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "-100",
    airport_lat: "38.948",
    airport_lon: "-1.863",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 133,
    airport_code: "ABA",
    airport_name: "Abakan Arpt",
    airport_city_code: "ABA",
    airport_city_name: "Abakan",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "53.74",
    airport_lon: "91.385",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 134,
    airport_code: "AAY",
    airport_name: "Al Ghaydah Arpt",
    airport_city_code: "AAY",
    airport_city_name: "Al Ghaydah",
    airport_country_name: "YEMEN",
    airport_country_code: "YE",
    airport_timezone: "3",
    airport_lat: "16.191667",
    airport_lon: "52.175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 135,
    airport_code: "AAT",
    airport_name: "Altay Arpt",
    airport_city_code: "AAT",
    airport_city_name: "Altay",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "47.866667",
    airport_lon: "88.116667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 136,
    airport_code: "AAR",
    airport_name: "Tirstrup Arpt",
    airport_city_code: "AAR",
    airport_city_name: "Aarhus",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "56.300017",
    airport_lon: "10.619008",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 137,
    airport_code: "ZID",
    airport_name: "Aarhus Bus Service",
    airport_city_code: "AAR",
    airport_city_name: "Aarhus",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 138,
    airport_code: "AAQ",
    airport_name: "Anapa Arpt",
    airport_city_code: "AAQ",
    airport_city_name: "Anapa",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "45.002097",
    airport_lon: "37.347272",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 139,
    airport_code: "AAN",
    airport_name: "Al Ain Arpt",
    airport_city_code: "AAN",
    airport_city_name: "Al Ain",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "4",
    airport_lat: "24.261667",
    airport_lon: "55.609167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 140,
    airport_code: "AAM",
    airport_name: "Mala Mala Arpt",
    airport_city_code: "AAM",
    airport_city_name: "Mala Mala",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-24.818111",
    airport_lon: "31.544584",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 141,
    airport_code: "AAL",
    airport_name: "Aalborg Arpt",
    airport_city_code: "AAL",
    airport_city_name: "Aalborg",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "57.092789",
    airport_lon: "9.849164",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 142,
    airport_code: "AAH",
    airport_name: "Aachen Merzbruck Arpt",
    airport_city_code: "AAH",
    airport_city_name: "Aachen",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.823194",
    airport_lon: "6.186389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 143,
    airport_code: "AAG",
    airport_name: "Arapoti Arpt",
    airport_city_code: "AAG",
    airport_city_name: "Arapoti",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-24.1036",
    airport_lon: "-49.79",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 144,
    airport_code: "AAF",
    airport_name: "Apalachicola Municipal Arpt",
    airport_city_code: "AAF",
    airport_city_name: "Apalachicola",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.727607",
    airport_lon: "-85.027442",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 145,
    airport_code: "AAE",
    airport_name: "Les Salines Arpt",
    airport_city_code: "AAE",
    airport_city_name: "Annaba",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "36.822225",
    airport_lon: "7.809167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 146,
    airport_code: "ANX",
    airport_name: "Andenes Arpt",
    airport_city_code: "ANX",
    airport_city_name: "Andenes",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "69.2925",
    airport_lon: "16.144167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 147,
    airport_code: "AOC",
    airport_name: "Altenburg Nobitz Arpt",
    airport_city_code: "AOC",
    airport_city_name: "Altenburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.981817",
    airport_lon: "12.506361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 148,
    airport_code: "AOE",
    airport_name: "Anadolu University Airport",
    airport_city_code: "AOE",
    airport_city_name: "Eskisehir",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.809858",
    airport_lon: "30.519378",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 149,
    airport_code: "AOH",
    airport_name: "Allen County Arpt",
    airport_city_code: "AOH",
    airport_city_name: "Lima",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 150,
    airport_code: "AOI",
    airport_name: "Falconara Arpt",
    airport_city_code: "AOI",
    airport_city_name: "Ancona",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "43.616389",
    airport_lon: "13.362222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 151,
    airport_code: "AOJ",
    airport_name: "Aomori Arpt",
    airport_city_code: "AOJ",
    airport_city_name: "Aomori",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "40.734722",
    airport_lon: "140.690833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 152,
    airport_code: "AOK",
    airport_name: "Karpathos Arpt",
    airport_city_code: "AOK",
    airport_city_name: "Karpathos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "35.421408",
    airport_lon: "27.146008",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 153,
    airport_code: "AOL",
    airport_name: "Paso De Los Libres Arpt",
    airport_city_code: "AOL",
    airport_city_name: "Paso De Los Libres",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-29.689425",
    airport_lon: "-57.152078",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 154,
    airport_code: "AOO",
    airport_name: "Blair Cty Arpt",
    airport_city_code: "AOO",
    airport_city_name: "Altoona",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.296372",
    airport_lon: "-78.320022",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 155,
    airport_code: "AOR",
    airport_name: "Sultan Abdul Hamlin Arpt",
    airport_city_code: "AOR",
    airport_city_name: "Alor Setar",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "6.189667",
    airport_lon: "100.398183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 156,
    airport_code: "AOT",
    airport_name: "Corrado Gex Arpt",
    airport_city_code: "AOT",
    airport_city_name: "Aosta",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.738456",
    airport_lon: "7.368719",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 157,
    airport_code: "APC",
    airport_name: "Napa Cty Arpt",
    airport_city_code: "APC",
    airport_city_name: "Napa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.213194",
    airport_lon: "-122.280694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 158,
    airport_code: "APF",
    airport_name: "Naples Municipal",
    airport_city_code: "APF",
    airport_city_name: "Naples",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.152619",
    airport_lon: "-81.775294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 159,
    airport_code: "APL",
    airport_name: "Nampula Arpt",
    airport_city_code: "APL",
    airport_city_name: "Nampula",
    airport_country_name: "MOZAMBIQUE",
    airport_country_code: "MZ",
    airport_timezone: "2",
    airport_lat: "-15.105611",
    airport_lon: "39.2818",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 160,
    airport_code: "APN",
    airport_name: "Phelps Collins Arpt",
    airport_city_code: "APN",
    airport_city_name: "Alpena",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "45.0781",
    airport_lon: "-83.5603",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 161,
    airport_code: "APO",
    airport_name: "Apartado Arpt",
    airport_city_code: "APO",
    airport_city_name: "Apartado",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "7.033",
    airport_lon: "-77.2",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 162,
    airport_code: "APS",
    airport_name: "Anapolis Arpt",
    airport_city_code: "APS",
    airport_city_name: "Anapolis",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-16.3619",
    airport_lon: "-48.9275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 163,
    airport_code: "APW",
    airport_name: "Apia Airport",
    airport_city_code: "APW",
    airport_city_name: "Apia",
    airport_country_name: "SAMOA",
    airport_country_code: "WS",
    airport_timezone: "-11",
    airport_lat: "-13.829969",
    airport_lon: "-172.008336",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 164,
    airport_code: "FGI",
    airport_name: "Fagali Arpt",
    airport_city_code: "APW",
    airport_city_name: "Apia",
    airport_country_name: "SAMOA",
    airport_country_code: "WS",
    airport_timezone: "-11",
    airport_lat: "-13.848611",
    airport_lon: "-171.740833",
    airport_num_airports: 2,
  },
  {
    airport_id: 165,
    airport_code: "AQA",
    airport_name: "Araraquara Arpt",
    airport_city_code: "AQA",
    airport_city_name: "Araraquara",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-21.812",
    airport_lon: "-48.133028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 166,
    airport_code: "AQG",
    airport_name: "Anqing Arpt",
    airport_city_code: "AQG",
    airport_city_name: "Anqing",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.5822",
    airport_lon: "117.0502",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 167,
    airport_code: "AQI",
    airport_name: "Qaisumah Arpt",
    airport_city_code: "AQI",
    airport_city_name: "Qaisumah",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "28.335192",
    airport_lon: "46.125069",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 168,
    airport_code: "AQJ",
    airport_name: "Aqaba Airport",
    airport_city_code: "AQJ",
    airport_city_name: "Aqaba",
    airport_country_name: "JORDAN",
    airport_country_code: "JO",
    airport_timezone: "2",
    airport_lat: "29.611619",
    airport_lon: "35.018067",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 169,
    airport_code: "AQP",
    airport_name: "Rodriguez Ballon Arpt",
    airport_city_code: "AQP",
    airport_city_name: "Arequipa",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-16.341072",
    airport_lon: "-71.583083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 170,
    airport_code: "ARB",
    airport_name: "Ann Arbor Municipal",
    airport_city_code: "ARB",
    airport_city_name: "Ann Arbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 171,
    airport_code: "ARH",
    airport_name: "Arkhangelsk Arpt",
    airport_city_code: "ARH",
    airport_city_name: "Arkhangelsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "64.360281",
    airport_lon: "40.430167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 172,
    airport_code: "ARI",
    airport_name: "Chacalluta Arpt",
    airport_city_code: "ARI",
    airport_city_name: "Arica",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-18.348531",
    airport_lon: "-70.338742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 173,
    airport_code: "ARK",
    airport_name: "Arusha Arpt",
    airport_city_code: "ARK",
    airport_city_name: "Arusha",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-3.367794",
    airport_lon: "36.633333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 174,
    airport_code: "ARM",
    airport_name: "Armidale Arpt",
    airport_city_code: "ARM",
    airport_city_name: "Armidale",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-30.528056",
    airport_lon: "151.617222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 175,
    airport_code: "ART",
    airport_name: "Watertown Arpt",
    airport_city_code: "ART",
    airport_city_name: "Watertown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.991922",
    airport_lon: "-76.021739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 176,
    airport_code: "ARU",
    airport_name: "Aracatuba Arpt",
    airport_city_code: "ARU",
    airport_city_name: "Aracatuba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-21.141342",
    airport_lon: "-50.424722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 177,
    airport_code: "ARV",
    airport_name: "Noble F Lee Memorial Field",
    airport_city_code: "ARV",
    airport_city_name: "Minocqua",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "45.927778",
    airport_lon: "-89.730833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 178,
    airport_code: "ARW",
    airport_name: "Arad Arpt",
    airport_city_code: "ARW",
    airport_city_name: "Arad",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "46.17655",
    airport_lon: "21.262022",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 179,
    airport_code: "ARY",
    airport_name: "Ararat Arpt",
    airport_city_code: "ARY",
    airport_city_name: "Ararat",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-37.3119",
    airport_lon: "142.9906",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 180,
    airport_code: "ASB",
    airport_name: "Ashgabat Arpt",
    airport_city_code: "ASB",
    airport_city_name: "Ashgabat",
    airport_country_name: "TURKMENISTAN",
    airport_country_code: "TM",
    airport_timezone: "5",
    airport_lat: "37.986814",
    airport_lon: "58.360967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 181,
    airport_code: "ASD",
    airport_name: "Andros Town Arpt",
    airport_city_code: "ASD",
    airport_city_name: "Andros Town",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "24.698283",
    airport_lon: "-77.795611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 182,
    airport_code: "ASE",
    airport_name: "Pitkin Cty Arptt Sardy Fld",
    airport_city_code: "ASE",
    airport_city_name: "Aspen",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "39.2232",
    airport_lon: "-106.869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 183,
    airport_code: "ASF",
    airport_name: "Astrakhan Arpt",
    airport_city_code: "ASF",
    airport_city_name: "Astrakhan",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "46.283333",
    airport_lon: "48.006278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 184,
    airport_code: "ASH",
    airport_name: "Boire Field Arpt",
    airport_city_code: "ASH",
    airport_city_name: "Nashua",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "42.78175",
    airport_lon: "-71.514778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 185,
    airport_code: "ASJ",
    airport_name: "Amami O Shima Arpt",
    airport_city_code: "ASJ",
    airport_city_name: "Amami O Shima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "28.430633",
    airport_lon: "129.712542",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 186,
    airport_code: "ASK",
    airport_name: "Yamoussoukro Arpt",
    airport_city_code: "ASK",
    airport_city_name: "Yamoussouro",
    airport_country_name: "COTE D'IVOIRE",
    airport_country_code: "CI",
    airport_timezone: "0",
    airport_lat: "6.903167",
    airport_lon: "-5.365581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 187,
    airport_code: "ASL",
    airport_name: "Harrison County Arpt",
    airport_city_code: "ASL",
    airport_city_name: "Marshall",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 188,
    airport_code: "ASM",
    airport_name: "Asmara Intl Arpt",
    airport_city_code: "ASM",
    airport_city_name: "Asmara",
    airport_country_name: "ERITREA",
    airport_country_code: "ER",
    airport_timezone: "3",
    airport_lat: "15.291853",
    airport_lon: "38.910667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 189,
    airport_code: "ASN",
    airport_name: "Talladega Arpt",
    airport_city_code: "ASN",
    airport_city_name: "Talladega",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 190,
    airport_code: "ASP",
    airport_name: "Alice Springs Arpt",
    airport_city_code: "ASP",
    airport_city_name: "Alice Springs",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-23.806667",
    airport_lon: "133.902222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 191,
    airport_code: "ASR",
    airport_name: "Kayseri Arpt",
    airport_city_code: "ASR",
    airport_city_name: "Kayseri",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.770386",
    airport_lon: "35.495428",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 192,
    airport_code: "AST",
    airport_name: "Astoria Arpt",
    airport_city_code: "AST",
    airport_city_name: "Astoria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.157972",
    airport_lon: "-123.878694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 193,
    airport_code: "ASU",
    airport_name: "Salvio Pettirosse Arpt",
    airport_city_code: "ASU",
    airport_city_name: "Asuncion",
    airport_country_name: "PARAGUAY",
    airport_country_code: "PY",
    airport_timezone: "-4",
    airport_lat: "-25.23985",
    airport_lon: "-57.519133",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 194,
    airport_code: "ASW",
    airport_name: "Daraw Arpt",
    airport_city_code: "ASW",
    airport_city_name: "Aswan",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "23.964356",
    airport_lon: "32.819975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 195,
    airport_code: "ATC",
    airport_name: "Arthurs Town Arpt",
    airport_city_code: "ATC",
    airport_city_name: "Arthurs Town",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "24.629417",
    airport_lon: "-75.673775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 196,
    airport_code: "ATH",
    airport_name: "Eleftherios Venizelos Intl Arpt",
    airport_city_code: "ATH",
    airport_city_name: "Athens",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.936358",
    airport_lon: "23.944467",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 197,
    airport_code: "HEW",
    airport_name: "Athens Hellinikon Arpt",
    airport_city_code: "ATH",
    airport_city_name: "Athens",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.8933",
    airport_lon: "23.7261",
    airport_num_airports: 2,
  },
  {
    airport_id: 198,
    airport_code: "ATI",
    airport_name: "Artigas Arpt",
    airport_city_code: "ATI",
    airport_city_name: "Artigas",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 199,
    airport_code: "PDK",
    airport_name: "Dekalb Peachtree",
    airport_city_code: "ATL",
    airport_city_name: "Atlanta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "33.875611",
    airport_lon: "-84.301972",
    airport_num_airports: 3,
  },
  {
    airport_id: 200,
    airport_code: "FTY",
    airport_name: "Fulton Cty Arpt",
    airport_city_code: "ATL",
    airport_city_name: "Atlanta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.7791",
    airport_lon: "-84.5214",
    airport_num_airports: 3,
  },
  {
    airport_id: 201,
    airport_code: "ATL",
    airport_name: "Hartsfield Jackson Intl Arpt",
    airport_city_code: "ATL",
    airport_city_name: "Atlanta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.636719",
    airport_lon: "-84.428067",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 202,
    airport_code: "ATM",
    airport_name: "Altamira Arpt",
    airport_city_code: "ATM",
    airport_city_name: "Altamira",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-3.253906",
    airport_lon: "-52.253978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 203,
    airport_code: "ATO",
    airport_name: "Ohio University Arpt",
    airport_city_code: "ATO",
    airport_city_name: "Athens",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 204,
    airport_code: "ATQ",
    airport_name: "Raja Sansi Arpt",
    airport_city_code: "ATQ",
    airport_city_name: "Amritsar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "31.709594",
    airport_lon: "74.797264",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 205,
    airport_code: "ATR",
    airport_name: "Mouakchott Arpt",
    airport_city_code: "ATR",
    airport_city_name: "Atar",
    airport_country_name: "MAURITANIA",
    airport_country_code: "MR",
    airport_timezone: "0",
    airport_lat: "20.506828",
    airport_lon: "-13.043194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 206,
    airport_code: "ATW",
    airport_name: "Outagamie Cty Arpt",
    airport_city_code: "ATW",
    airport_city_name: "Appleton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.257526",
    airport_lon: "-88.507576",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 207,
    airport_code: "ATY",
    airport_name: "Watertown Municipal",
    airport_city_code: "ATY",
    airport_city_name: "Watertown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.914",
    airport_lon: "-97.1547",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 208,
    airport_code: "AUA",
    airport_name: "Reina Beatrix Arpt",
    airport_city_code: "AUA",
    airport_city_name: "Aruba",
    airport_country_name: "ARUBA",
    airport_country_code: "AW",
    airport_timezone: "-4",
    airport_lat: "12.501389",
    airport_lon: "-70.015221",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 209,
    airport_code: "AUG",
    airport_name: "Maine State Arpt",
    airport_city_code: "AUG",
    airport_city_name: "Augusta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.320647",
    airport_lon: "-69.797317",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 210,
    airport_code: "ZVJ",
    airport_name: "Abu Dhabi-bus Station",
    airport_city_code: "AUH",
    airport_city_name: "Abu Dhabi",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 211,
    airport_code: "AUH",
    airport_name: "Dhabi Intl Arpt",
    airport_city_code: "AUH",
    airport_city_name: "Abu Dhabi",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "4",
    airport_lat: "24.432972",
    airport_lon: "54.651138",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 212,
    airport_code: "AUO",
    airport_name: "Auburn Opelika",
    airport_city_code: "AUO",
    airport_city_name: "Auburn",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 213,
    airport_code: "AUQ",
    airport_name: "Atuona Arpt",
    airport_city_code: "AUQ",
    airport_city_name: "Atuona",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-9",
    airport_lat: "-9.768794",
    airport_lon: "-139.011256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 214,
    airport_code: "AUR",
    airport_name: "Tronquieres Arpt",
    airport_city_code: "AUR",
    airport_city_name: "Aurillac",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.891388",
    airport_lon: "2.421944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 215,
    airport_code: "AUS",
    airport_name: "Bergstrom Intl Arpt",
    airport_city_code: "AUS",
    airport_city_name: "Austin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.194528",
    airport_lon: "-97.669889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 216,
    airport_code: "AUU",
    airport_name: "Aurukin Arpt",
    airport_city_code: "AUU",
    airport_city_name: "Aurukun Mission",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-13.3539",
    airport_lon: "141.721",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 217,
    airport_code: "AUW",
    airport_name: "Wausau Municipal Arpt",
    airport_city_code: "AUW",
    airport_city_name: "Wausau",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.926285",
    airport_lon: "-89.627002",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 218,
    airport_code: "CWA",
    airport_name: "Central Wisconsin Arpt",
    airport_city_code: "AUW",
    airport_city_name: "Wausau",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.772726",
    airport_lon: "-89.646635",
    airport_num_airports: 2,
  },
  {
    airport_id: 219,
    airport_code: "AUX",
    airport_name: "Araguaina Arpt",
    airport_city_code: "AUX",
    airport_city_name: "Araguaina",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-7.228333",
    airport_lon: "-48.240833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 220,
    airport_code: "AUZ",
    airport_name: "Aurora Municipal Arpt",
    airport_city_code: "AUZ",
    airport_city_name: "Aurora",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 221,
    airport_code: "AVI",
    airport_name: "Maximo Gomez Arpt",
    airport_city_code: "AVI",
    airport_city_name: "Ciego De Avila",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "22.027053",
    airport_lon: "-78.789617",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 222,
    airport_code: "AVL",
    airport_name: "Asheville Municipal Arpt",
    airport_city_code: "AVL",
    airport_city_name: "Asheville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.436194",
    airport_lon: "-82.541806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 223,
    airport_code: "XZN",
    airport_name: "Avignon Railway Station",
    airport_city_code: "AVN",
    airport_city_name: "Avignon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "46.4",
    airport_lon: "5.8333",
    airport_num_airports: 2,
  },
  {
    airport_id: 224,
    airport_code: "AVN",
    airport_name: "Avignon Caumont Arpt",
    airport_city_code: "AVN",
    airport_city_name: "Avignon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.9073",
    airport_lon: "4.901831",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 225,
    airport_code: "AVP",
    airport_name: "Wilkes Barre Scranton Intl Arpt",
    airport_city_code: "AVP",
    airport_city_name: "Scranton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.338478",
    airport_lon: "-75.723403",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 226,
    airport_code: "SXC",
    airport_name: "Catalina Arpt",
    airport_city_code: "AVX",
    airport_city_name: "Catalina Island",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 227,
    airport_code: "AXA",
    airport_name: "Wallblake Municipal",
    airport_city_code: "AXA",
    airport_city_name: "Anguilla",
    airport_country_name: "ANGUILLA",
    airport_country_code: "AI",
    airport_timezone: "-4",
    airport_lat: "18.204834",
    airport_lon: "-63.055084",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 228,
    airport_code: "AXD",
    airport_name: "Dhmokritos Arpt",
    airport_city_code: "AXD",
    airport_city_name: "Alexandroupolis",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "40.855869",
    airport_lon: "25.956264",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 229,
    airport_code: "AXM",
    airport_name: "El Eden Arpt",
    airport_city_code: "AXM",
    airport_city_name: "Armenia",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "4.452775",
    airport_lon: "-75.766447",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 230,
    airport_code: "AXT",
    airport_name: "Akita Airport",
    airport_city_code: "AXT",
    airport_city_name: "Akita",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "39.615556",
    airport_lon: "140.218611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 231,
    airport_code: "AXV",
    airport_name: "Neil Armstrong Arpt",
    airport_city_code: "AXV",
    airport_city_name: "Wapakoneta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 232,
    airport_code: "AYP",
    airport_name: "Yanamilla Arpt",
    airport_city_code: "AYP",
    airport_city_name: "Ayacucho",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-13.154819",
    airport_lon: "-74.204417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 233,
    airport_code: "AYQ",
    airport_name: "Connellan Arpt",
    airport_city_code: "AYQ",
    airport_city_name: "Ayers Rock",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-25.186111",
    airport_lon: "130.975556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 234,
    airport_code: "AYR",
    airport_name: "Ayr Arpt",
    airport_city_code: "AYR",
    airport_city_name: "Ayr Au",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-19.5958",
    airport_lon: "147.3258",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 235,
    airport_code: "AYS",
    airport_name: "Ware County Arpt",
    airport_city_code: "AYS",
    airport_city_name: "Waycross",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "31.2491",
    airport_lon: "-82.3955",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 236,
    airport_code: "AYT",
    airport_name: "Antalya Airport",
    airport_city_code: "AYT",
    airport_city_name: "Antalya",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "36.898731",
    airport_lon: "30.800461",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 237,
    airport_code: "AZD",
    airport_name: "Yazd Arpt",
    airport_city_code: "AZD",
    airport_city_name: "Yazd",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "31.904908",
    airport_lon: "54.276503",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 238,
    airport_code: "AZG",
    airport_name: "Apatzingan Arpt",
    airport_city_code: "AZG",
    airport_city_name: "Apatzingan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 239,
    airport_code: "AZN",
    airport_name: "Andizhan",
    airport_city_code: "AZN",
    airport_city_name: "Andizhan",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "40.7277",
    airport_lon: "72.294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 240,
    airport_code: "AZO",
    airport_name: "Kalamazoo Cty Arpt",
    airport_city_code: "AZO",
    airport_city_name: "Kalamazoo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.234875",
    airport_lon: "-85.552058",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 241,
    airport_code: "AZS",
    airport_name: "Samana International Arpt",
    airport_city_code: "AZS",
    airport_city_name: "Samana",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "19.267",
    airport_lon: "-69.742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 242,
    airport_code: "BAG",
    airport_name: "Loakan Arpt",
    airport_city_code: "BAG",
    airport_city_name: "Baguio",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "16.375103",
    airport_lon: "120.619636",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 243,
    airport_code: "BAH",
    airport_name: "Bahrain Intl Arpt",
    airport_city_code: "BAH",
    airport_city_name: "Muharraq",
    airport_country_name: "BAHRAIN",
    airport_country_code: "BH",
    airport_timezone: "3",
    airport_lat: "26.270834",
    airport_lon: "50.63361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 244,
    airport_code: "GYD",
    airport_name: "Heydar Aliyev Intl Arpt",
    airport_city_code: "BAK",
    airport_city_name: "Baku",
    airport_country_name: "AZERBAIJAN",
    airport_country_code: "AZ",
    airport_timezone: "4",
    airport_lat: "40.4675",
    airport_lon: "50.046667",
    airport_num_airports: 3,
  },
  {
    airport_id: 245,
    airport_code: "ZXT",
    airport_name: "Zabrat Arpt",
    airport_city_code: "BAK",
    airport_city_name: "Baku",
    airport_country_name: "AZERBAIJAN",
    airport_country_code: "AZ",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 246,
    airport_code: "BAK",
    airport_name: "Baku Airport",
    airport_city_code: "BAK",
    airport_city_name: "Baku",
    airport_country_name: "AZERBAIJAN",
    airport_country_code: "AZ",
    airport_timezone: "4",
    airport_lat: "40.4675",
    airport_lon: "50.046667",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 247,
    airport_code: "BAL",
    airport_name: "Batman Arpt",
    airport_city_code: "BAL",
    airport_city_name: "Batman",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.928969",
    airport_lon: "41.116583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 248,
    airport_code: "BAO",
    airport_name: "Udorn Arpt",
    airport_city_code: "BAO",
    airport_city_name: "Ban Mak Khaen",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 249,
    airport_code: "BAQ",
    airport_name: "E Cortissoz Arpt",
    airport_city_code: "BAQ",
    airport_city_name: "Barranquilla",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "10.889589",
    airport_lon: "-74.780819",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 250,
    airport_code: "BAS",
    airport_name: "Balalae Arpt",
    airport_city_code: "BAS",
    airport_city_name: "Balalae",
    airport_country_name: "SOLOMON ISLANDS",
    airport_country_code: "SB",
    airport_timezone: "11",
    airport_lat: "-6.967",
    airport_lon: "155.883",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 251,
    airport_code: "BAT",
    airport_name: "Barretos Arpt",
    airport_city_code: "BAT",
    airport_city_name: "Barretos",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-20.585",
    airport_lon: "-48.5953",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 252,
    airport_code: "BAU",
    airport_name: "Bauru Arpt",
    airport_city_code: "BAU",
    airport_city_name: "Bauru",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.345042",
    airport_lon: "-49.0538",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 253,
    airport_code: "BAV",
    airport_name: "Baotou Arpt",
    airport_city_code: "BAV",
    airport_city_name: "Baotou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "40.56",
    airport_lon: "109.997",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 254,
    airport_code: "BAX",
    airport_name: "Barnaul Arpt",
    airport_city_code: "BAX",
    airport_city_name: "Barnaul",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "7",
    airport_lat: "53.363775",
    airport_lon: "83.538533",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 255,
    airport_code: "BAY",
    airport_name: "Baia Mare Arpt",
    airport_city_code: "BAY",
    airport_city_name: "Baia Mare",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "47.658389",
    airport_lon: "23.470022",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 256,
    airport_code: "BBA",
    airport_name: "Teniente Vidal Airport",
    airport_city_code: "BBA",
    airport_city_name: "Balmaceda",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-3",
    airport_lat: "-45.916058",
    airport_lon: "-71.689475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 257,
    airport_code: "BBH",
    airport_name: "Barth Arpt",
    airport_city_code: "BBH",
    airport_city_name: "Barth",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.33754",
    airport_lon: "12.699705",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 258,
    airport_code: "BBI",
    airport_name: "Biju Patnaik Arpt",
    airport_city_code: "BBI",
    airport_city_name: "Bhubaneswar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "6",
    airport_lat: "20.244364",
    airport_lon: "85.817781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 259,
    airport_code: "BBJ",
    airport_name: "Bitburg Air Base",
    airport_city_code: "BBJ",
    airport_city_name: "Bitburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.193531",
    airport_lon: "14.519747",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 260,
    airport_code: "BBK",
    airport_name: "Kasane Arpt",
    airport_city_code: "BBK",
    airport_city_name: "Kasane",
    airport_country_name: "BOTSWANA",
    airport_country_code: "BW",
    airport_timezone: "2",
    airport_lat: "-17.832875",
    airport_lon: "25.1624",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 261,
    airport_code: "BBN",
    airport_name: "Bario Arpt",
    airport_city_code: "BBN",
    airport_city_name: "Bario",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.73389",
    airport_lon: "115.479",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 262,
    airport_code: "BBR",
    airport_name: "Baillif Arpt",
    airport_city_code: "BBR",
    airport_city_name: "Basse Terre",
    airport_country_name: "GUADELOUPE",
    airport_country_code: "GP",
    airport_timezone: "-4",
    airport_lat: "16.0133",
    airport_lon: "-61.7422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 263,
    airport_code: "BBW",
    airport_name: "Broken Bow Municipal",
    airport_city_code: "BBW",
    airport_city_name: "Broken Bow",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.4367",
    airport_lon: "-99.6417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 264,
    airport_code: "BCB",
    airport_name: "Virginia Tech Arpt",
    airport_city_code: "BCB",
    airport_city_name: "Blacksburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 265,
    airport_code: "BCD",
    airport_name: "Bacolod Arpt",
    airport_city_code: "BCD",
    airport_city_name: "Bacolod",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "10.642511",
    airport_lon: "122.929617",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 266,
    airport_code: "BCE",
    airport_name: "Bryce Arpt",
    airport_city_code: "BCE",
    airport_city_name: "Bryce",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.706444",
    airport_lon: "-112.145806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 267,
    airport_code: "BCH",
    airport_name: "English Madeira Arpt",
    airport_city_code: "BCH",
    airport_city_name: "Baucau",
    airport_country_name: "TIMOR-LESTE",
    airport_country_code: "TL",
    airport_timezone: "-100",
    airport_lat: "-8.4854",
    airport_lon: "126.3993",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 268,
    airport_code: "BCI",
    airport_name: "Barcaldine Arpt",
    airport_city_code: "BCI",
    airport_city_name: "Barcaldine",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-23.5653",
    airport_lon: "145.307",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 269,
    airport_code: "BCM",
    airport_name: "Bacau Arpt",
    airport_city_code: "BCM",
    airport_city_name: "Bacau",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "46.521946",
    airport_lon: "26.910278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 270,
    airport_code: "BCN",
    airport_name: "Barcelona Arpt",
    airport_city_code: "BCN",
    airport_city_name: "Barcelona",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "41.297078",
    airport_lon: "2.078464",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 271,
    airport_code: "BDA",
    airport_name: "Bermuda International",
    airport_city_code: "BDA",
    airport_city_name: "Bermuda",
    airport_country_name: "BERMUDA",
    airport_country_code: "BM",
    airport_timezone: "-4",
    airport_lat: "32.364042",
    airport_lon: "-64.678703",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 272,
    airport_code: "BDB",
    airport_name: "Bundaberg Arpt",
    airport_city_code: "BDB",
    airport_city_name: "Bundaberg",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-24.903889",
    airport_lon: "152.318611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 273,
    airport_code: "BDD",
    airport_name: "Badu Island Arpt",
    airport_city_code: "BDD",
    airport_city_name: "Badu Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-10.15",
    airport_lon: "141.175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 274,
    airport_code: "BDJ",
    airport_name: "Sjamsudin Noor Arpt",
    airport_city_code: "BDJ",
    airport_city_name: "Banjarmasin",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "-3.442356",
    airport_lon: "114.762553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 275,
    airport_code: "BDM",
    airport_name: "Bandirma Arpt",
    airport_city_code: "BDM",
    airport_city_name: "Bandirma Arpt",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.317972",
    airport_lon: "27.977694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 276,
    airport_code: "BDO",
    airport_name: "Husein Sastranegara Arpt",
    airport_city_code: "BDO",
    airport_city_name: "Bandung",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-6.900625",
    airport_lon: "107.576294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 277,
    airport_code: "BDQ",
    airport_name: "Vadodara Arpt",
    airport_city_code: "BDQ",
    airport_city_name: "Vadodara",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "22.336164",
    airport_lon: "73.226289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 278,
    airport_code: "BDR",
    airport_name: "Sikorsky Memorial Arpt",
    airport_city_code: "BDR",
    airport_city_name: "Bridgeport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.163472",
    airport_lon: "-73.126167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 279,
    airport_code: "BDS",
    airport_name: "Papola Casale Arpt",
    airport_city_code: "BDS",
    airport_city_name: "Brindisi",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "40.657633",
    airport_lon: "17.947033",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 280,
    airport_code: "BDU",
    airport_name: "Bardufoss Arpt",
    airport_city_code: "BDU",
    airport_city_name: "Bardufoss",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "69.055758",
    airport_lon: "18.540356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 281,
    airport_code: "BEB",
    airport_name: "Benbecula Arpt",
    airport_city_code: "BEB",
    airport_city_name: "Benbecula",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "57.481111",
    airport_lon: "-7.362778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 282,
    airport_code: "BED",
    airport_name: "Bedford Arpt",
    airport_city_code: "BED",
    airport_city_name: "Bedford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.469953",
    airport_lon: "-71.289031",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 283,
    airport_code: "BJY",
    airport_name: "Batajnica Arpt",
    airport_city_code: "BEG",
    airport_city_name: "Belgrade",
    airport_country_name: "SERBIA",
    airport_country_code: "RS",
    airport_timezone: "-100",
    airport_lat: "44.9236",
    airport_lon: "20.279",
    airport_num_airports: 2,
  },
  {
    airport_id: 284,
    airport_code: "BEG",
    airport_name: "Belgrade Beograd Arpt",
    airport_city_code: "BEG",
    airport_city_name: "Belgrade",
    airport_country_name: "SERBIA",
    airport_country_code: "RS",
    airport_timezone: "1",
    airport_lat: "44.818444",
    airport_lon: "20.309139",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 285,
    airport_code: "BEH",
    airport_name: "Ross Field",
    airport_city_code: "BEH",
    airport_city_name: "Benton Harbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.128583",
    airport_lon: "-86.4285",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 286,
    airport_code: "BEL",
    airport_name: "Val De Cans Arpt",
    airport_city_code: "BEL",
    airport_city_name: "Belem",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-1.37925",
    airport_lon: "-48.476292",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 287,
    airport_code: "BEN",
    airport_name: "Benina Intl Arpt",
    airport_city_code: "BEN",
    airport_city_name: "Benghazi",
    airport_country_name: "LIBYAN ARAB JAMAHIRIYA",
    airport_country_code: "LY",
    airport_timezone: "1",
    airport_lat: "32.096786",
    airport_lon: "20.269472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 288,
    airport_code: "BEQ",
    airport_name: "Honington Arpt",
    airport_city_code: "BEQ",
    airport_city_name: "Bury St Edmunds",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.342611",
    airport_lon: "0.772939",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 289,
    airport_code: "BML",
    airport_name: "Berlin Arpt",
    airport_city_code: "BER",
    airport_city_name: "Berlin",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-4",
    airport_lat: "44.574722",
    airport_lon: "-71.17639",
    airport_num_airports: 3,
  },
  {
    airport_id: 290,
    airport_code: "SXF",
    airport_name: "Schoenefeld Arpt",
    airport_city_code: "BER",
    airport_city_name: "Berlin",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "52.380001",
    airport_lon: "13.5225",
    airport_num_airports: 3,
  },
  {
    airport_id: 291,
    airport_code: "TXL",
    airport_name: "Tegel Airport",
    airport_city_code: "BER",
    airport_city_name: "Berlin",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "52.559686",
    airport_lon: "13.287711",
    airport_num_airports: 3,
  },
  {
    airport_id: 292,
    airport_code: "BES",
    airport_name: "Guipavas Arpt",
    airport_city_code: "BES",
    airport_city_name: "Brest",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.447911",
    airport_lon: "-4.418539",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 293,
    airport_code: "BET",
    airport_name: "Bethel Airport",
    airport_city_code: "BET",
    airport_city_name: "Bethel",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "60.779778",
    airport_lon: "-161.838",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 294,
    airport_code: "BEU",
    airport_name: "Bedourie Arpt",
    airport_city_code: "BEU",
    airport_city_name: "Bedourie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-24.3461",
    airport_lon: "139.46",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 295,
    airport_code: "BEW",
    airport_name: "Beira Arpt",
    airport_city_code: "BEW",
    airport_city_name: "Beira",
    airport_country_name: "MOZAMBIQUE",
    airport_country_code: "MZ",
    airport_timezone: "2",
    airport_lat: "-19.796419",
    airport_lon: "34.907556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 296,
    airport_code: "BEY",
    airport_name: "Beirut Intl Arpt",
    airport_city_code: "BEY",
    airport_city_name: "Beirut",
    airport_country_name: "LEBANON",
    airport_country_code: "LB",
    airport_timezone: "2",
    airport_lat: "33.820931",
    airport_lon: "35.488389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 297,
    airport_code: "BEZ",
    airport_name: "Beru Arpt",
    airport_city_code: "BEZ",
    airport_city_name: "Beru",
    airport_country_name: "KIRIBATI",
    airport_country_code: "KI",
    airport_timezone: "10",
    airport_lat: "-1.254722",
    airport_lon: "176.007222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 298,
    airport_code: "BFD",
    airport_name: "Bradford Regional Arpt",
    airport_city_code: "BFD",
    airport_city_name: "Bradford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.8031",
    airport_lon: "-78.6401",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 299,
    airport_code: "BFE",
    airport_name: "Bielefeld Arpt",
    airport_city_code: "BFE",
    airport_city_name: "Bielefeld",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 300,
    airport_code: "BFF",
    airport_name: "Scottsbluff Municipal",
    airport_city_code: "BFF",
    airport_city_name: "Scottsbluff",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "41.874",
    airport_lon: "-103.596",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 301,
    airport_code: "BFG",
    airport_name: "Bullfrog Basin Arpt",
    airport_city_code: "BFG",
    airport_city_name: "Bullfrog Basin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 302,
    airport_code: "BFJ",
    airport_name: "Ba Arpt",
    airport_city_code: "BFJ",
    airport_city_name: "Ba City",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 303,
    airport_code: "BFL",
    airport_name: "Meadows Field",
    airport_city_code: "BFL",
    airport_city_name: "Bakersfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "35.433598",
    airport_lon: "-119.05677",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 304,
    airport_code: "BFN",
    airport_name: "Bloemfontein International",
    airport_city_code: "BFN",
    airport_city_name: "Bloemfontein",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-29.092722",
    airport_lon: "26.302444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 305,
    airport_code: "BFP",
    airport_name: "Beaver Falls Arpt",
    airport_city_code: "BFP",
    airport_city_name: "Beaver Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.7725",
    airport_lon: "-80.3915",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 306,
    airport_code: "BFR",
    airport_name: "Virgil I Grissom Municipal",
    airport_city_code: "BFR",
    airport_city_name: "Bedford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 307,
    airport_code: "BFS",
    airport_name: "Belfast Intl Arpt",
    airport_city_code: "BFS",
    airport_city_name: "Belfast",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "54.6575",
    airport_lon: "-6.215833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 308,
    airport_code: "BHD",
    airport_name: "Belfast City Arpt",
    airport_city_code: "BFS",
    airport_city_name: "Belfast",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "54.618056",
    airport_lon: "-5.8725",
    airport_num_airports: 2,
  },
  {
    airport_id: 309,
    airport_code: "BFT",
    airport_name: "Beaufort County Arpt",
    airport_city_code: "BFT",
    airport_city_name: "Beaufort",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.41083",
    airport_lon: "-80.635",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 310,
    airport_code: "BFV",
    airport_name: "Buri Ram Arpt",
    airport_city_code: "BFV",
    airport_city_name: "Buri Ram",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "15.229539",
    airport_lon: "103.253231",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 311,
    airport_code: "BGA",
    airport_name: "Palo Negro Arpt",
    airport_city_code: "BGA",
    airport_city_name: "Bucaramanga",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "7.1265",
    airport_lon: "-73.184778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 312,
    airport_code: "BGC",
    airport_name: "Braganca Arpt",
    airport_city_code: "BGC",
    airport_city_name: "Braganca",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "41.8578",
    airport_lon: "-6.707125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 313,
    airport_code: "BGF",
    airport_name: "Bangui Airport",
    airport_city_code: "BGF",
    airport_city_name: "Bangui",
    airport_country_name: "CENTRAL AFRICAN REPUBLIC",
    airport_country_code: "CF",
    airport_timezone: "1",
    airport_lat: "4.398475",
    airport_lon: "18.518786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 314,
    airport_code: "BGI",
    airport_name: "Grantley Adams Intl Arpt",
    airport_city_code: "BGI",
    airport_city_name: "Bridgetown",
    airport_country_name: "BARBADOS",
    airport_country_code: "BB",
    airport_timezone: "-4",
    airport_lat: "13.074603",
    airport_lon: "-59.492456",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 315,
    airport_code: "BGM",
    airport_name: "Edwin A Link Field",
    airport_city_code: "BGM",
    airport_city_name: "Binghamton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.208689",
    airport_lon: "-75.979839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 316,
    airport_code: "XKN",
    airport_name: "Finse Rail Station",
    airport_city_code: "BGO",
    airport_city_name: "Bergen",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 317,
    airport_code: "BGO",
    airport_name: "Flesland Airport",
    airport_city_code: "BGO",
    airport_city_name: "Bergen",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "60.293386",
    airport_lon: "5.218142",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 318,
    airport_code: "XVK",
    airport_name: "Voss Rail Station",
    airport_city_code: "BGO",
    airport_city_name: "Bergen",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 319,
    airport_code: "BGR",
    airport_name: "Bangor Intl Arpt",
    airport_city_code: "BGR",
    airport_city_name: "Bangor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.807444",
    airport_lon: "-68.828139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 320,
    airport_code: "BGV",
    airport_name: "Bento Goncalves Arpt",
    airport_city_code: "BGV",
    airport_city_name: "Bento Goncalves",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 321,
    airport_code: "BGW",
    airport_name: "Baghdad International Arpt",
    airport_city_code: "BGW",
    airport_city_name: "Baghdad",
    airport_country_name: "IRAQ",
    airport_country_code: "IQ",
    airport_timezone: "3",
    airport_lat: "33.262539",
    airport_lon: "44.234578",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 322,
    airport_code: "BGY",
    airport_name: "Orio Al Serio Arpt",
    airport_city_code: "BGY",
    airport_city_name: "Bergamo",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.673889",
    airport_lon: "9.704166",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 323,
    airport_code: "BHB",
    airport_name: "Hancock County",
    airport_city_code: "BHB",
    airport_city_name: "Bar Harbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.449769",
    airport_lon: "-68.361565",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 324,
    airport_code: "BHE",
    airport_name: "Woodbourne Arpt",
    airport_city_code: "BHE",
    airport_city_name: "Blenheim",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-41.518333",
    airport_lon: "173.870278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 325,
    airport_code: "BHI",
    airport_name: "Commandante Airport",
    airport_city_code: "BHI",
    airport_city_name: "Bahia Blanca",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-38.724967",
    airport_lon: "-62.169317",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 326,
    airport_code: "BHJ",
    airport_name: "Rudra Mata Arpt",
    airport_city_code: "BHJ",
    airport_city_name: "Bhuj",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "23.287828",
    airport_lon: "69.670147",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 327,
    airport_code: "BHK",
    airport_name: "Bukhara Arpt",
    airport_city_code: "BHK",
    airport_city_name: "Bukhara",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "39.775",
    airport_lon: "64.483333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 328,
    airport_code: "BHL",
    airport_name: "Bahia De Los Angeles Arpt",
    airport_city_code: "BHL",
    airport_city_name: "Bahia De Los Angeles",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 329,
    airport_code: "BHM",
    airport_name: "Birmingham International Arpt",
    airport_city_code: "BHM",
    airport_city_name: "Birmingham",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.562942",
    airport_lon: "-86.75355",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 330,
    airport_code: "BHO",
    airport_name: "Raja Bhoj Arpt",
    airport_city_code: "BHO",
    airport_city_name: "Bhopal",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "23.287467",
    airport_lon: "77.337375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 331,
    airport_code: "BHQ",
    airport_name: "Broken Hill Arpt",
    airport_city_code: "BHQ",
    airport_city_name: "Broken Hill",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-32.0014",
    airport_lon: "141.472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 332,
    airport_code: "BHS",
    airport_name: "Raglan Airport",
    airport_city_code: "BHS",
    airport_city_name: "Bathurst",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-33.4094",
    airport_lon: "149.652",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 333,
    airport_code: "BHU",
    airport_name: "Bhavnagar Arpt",
    airport_city_code: "BHU",
    airport_city_name: "Bhavnagar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "21.752206",
    airport_lon: "72.185181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 334,
    airport_code: "BHV",
    airport_name: "Bahawalpur Arpt",
    airport_city_code: "BHV",
    airport_city_name: "Bahawalpur",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "29.4",
    airport_lon: "71.683334",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 335,
    airport_code: "XVB",
    airport_name: "Stafford Rail Station",
    airport_city_code: "BHX",
    airport_city_name: "Birmingham",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 336,
    airport_code: "XNV",
    airport_name: "Nuneaton Rail Station",
    airport_city_code: "BHX",
    airport_city_name: "Birmingham",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "31.2083",
    airport_lon: "35.0123",
    airport_num_airports: 4,
  },
  {
    airport_id: 337,
    airport_code: "QQN",
    airport_name: "Birmingham - New Street Rail Service",
    airport_city_code: "BHX",
    airport_city_name: "Birmingham",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 338,
    airport_code: "BHX",
    airport_name: "Birmingham Intl Arpt",
    airport_city_code: "BHX",
    airport_city_name: "Birmingham",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.453856",
    airport_lon: "-1.748028",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 339,
    airport_code: "BHY",
    airport_name: "Beihai Airport",
    airport_city_code: "BHY",
    airport_city_name: "Beihai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "21.5394",
    airport_lon: "109.294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 340,
    airport_code: "PLU",
    airport_name: "Pampulha Arpt",
    airport_city_code: "BHZ",
    airport_city_name: "Belo Horizonte",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-19.851181",
    airport_lon: "-43.950628",
    airport_num_airports: 2,
  },
  {
    airport_id: 341,
    airport_code: "CNF",
    airport_name: "Tancredo Neves Intl Arpt",
    airport_city_code: "BHZ",
    airport_city_name: "Belo Horizonte",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-19.63375",
    airport_lon: "-43.968856",
    airport_num_airports: 2,
  },
  {
    airport_id: 342,
    airport_code: "BIA",
    airport_name: "Poretta Airport",
    airport_city_code: "BIA",
    airport_city_name: "Bastia",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "42.552664",
    airport_lon: "9.483731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 343,
    airport_code: "BID",
    airport_name: "Block Island Municipal",
    airport_city_code: "BID",
    airport_city_name: "Block Island",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.168056",
    airport_lon: "-71.577778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 344,
    airport_code: "BIH",
    airport_name: "Bishop Airport",
    airport_city_code: "BIH",
    airport_city_name: "Bishop",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "37.3731",
    airport_lon: "-118.3636",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 345,
    airport_code: "BII",
    airport_name: "Enyu Airfield",
    airport_city_code: "BII",
    airport_city_name: "Bikini Atoll",
    airport_country_name: "MARSHALL ISLANDS",
    airport_country_code: "MH",
    airport_timezone: "12",
    airport_lat: "11.5225",
    airport_lon: "165.565",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 346,
    airport_code: "BIK",
    airport_name: "Mokmer Arpt",
    airport_city_code: "BIK",
    airport_city_name: "Biak",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-1.190017",
    airport_lon: "136.107997",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 347,
    airport_code: "BIL",
    airport_name: "Logan Field",
    airport_city_code: "BIL",
    airport_city_name: "Billings",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "45.80921",
    airport_lon: "-108.537654",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 348,
    airport_code: "BIM",
    airport_name: "Bimini Airport",
    airport_city_code: "BIM",
    airport_city_name: "Bimini",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.699881",
    airport_lon: "-79.264656",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 349,
    airport_code: "NSB",
    airport_name: "North Seaplane Base",
    airport_city_code: "BIM",
    airport_city_name: "Bimini",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.767",
    airport_lon: "-79.25",
    airport_num_airports: 2,
  },
  {
    airport_id: 350,
    airport_code: "BIO",
    airport_name: "Bilbao Arpt",
    airport_city_code: "BIO",
    airport_city_name: "Bilbao",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "43.301097",
    airport_lon: "-2.910608",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 351,
    airport_code: "BIQ",
    airport_name: "Bayonne Anglet Arpt",
    airport_city_code: "BIQ",
    airport_city_name: "Biarritz",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.468419",
    airport_lon: "-1.523325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 352,
    airport_code: "BIS",
    airport_name: "Bismarck Municipal Arpt",
    airport_city_code: "BIS",
    airport_city_name: "Bismarck",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "46.775842",
    airport_lon: "-100.757931",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 353,
    airport_code: "BIY",
    airport_name: "Bisho Arpt",
    airport_city_code: "BIY",
    airport_city_name: "Bisho",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "-32.895",
    airport_lon: "27.285",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 354,
    airport_code: "BJA",
    airport_name: "Bejaia Arpt",
    airport_city_code: "BJA",
    airport_city_name: "Bejaia",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "36.711997",
    airport_lon: "5.069922",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 355,
    airport_code: "BJC",
    airport_name: "Jeffco Arpt",
    airport_city_code: "BJC",
    airport_city_name: "Broomfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "39.908889",
    airport_lon: "-105.117222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 356,
    airport_code: "BJF",
    airport_name: "Batsfjord Arpt",
    airport_city_code: "BJF",
    airport_city_name: "Batsfjord",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "70.600278",
    airport_lon: "29.6925",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 357,
    airport_code: "BJI",
    airport_name: "Bemidji Municipal Arpt",
    airport_city_code: "BJI",
    airport_city_name: "Bemidji",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "47.510722",
    airport_lon: "-94.934722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 358,
    airport_code: "BJL",
    airport_name: "Yundum Intl Arpt",
    airport_city_code: "BJL",
    airport_city_name: "Banjul",
    airport_country_name: "GAMBIA",
    airport_country_code: "GM",
    airport_timezone: "0",
    airport_lat: "13.337961",
    airport_lon: "-16.652206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 359,
    airport_code: "BJM",
    airport_name: "Bujumbura Intl Arpt",
    airport_city_code: "BJM",
    airport_city_name: "Bujumbura",
    airport_country_name: "BURUNDI",
    airport_country_code: "BI",
    airport_timezone: "2",
    airport_lat: "-3.324019",
    airport_lon: "29.318519",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 360,
    airport_code: "BJR",
    airport_name: "Bahar Dar Arpt",
    airport_city_code: "BJR",
    airport_city_name: "Bahar Dar",
    airport_country_name: "ETHIOPIA",
    airport_country_code: "ET",
    airport_timezone: "3",
    airport_lat: "11.608075",
    airport_lon: "37.321644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 361,
    airport_code: "NAY",
    airport_name: "Nanyuan Arpt",
    airport_city_code: "BJS",
    airport_city_name: "Beijing",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "39.7825",
    airport_lon: "116.387778",
    airport_num_airports: 2,
  },
  {
    airport_id: 362,
    airport_code: "PEK",
    airport_name: "Beijing Capital Arpt",
    airport_city_code: "BJS",
    airport_city_name: "Beijing",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "40.080111",
    airport_lon: "116.584556",
    airport_num_airports: 2,
  },
  {
    airport_id: 363,
    airport_code: "BJX",
    airport_name: "Del Bajio Arpt",
    airport_city_code: "BJX",
    airport_city_name: "Leon",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.993464",
    airport_lon: "-101.480847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 364,
    airport_code: "BJZ",
    airport_name: "Talaveral La Real Arpt",
    airport_city_code: "BJZ",
    airport_city_name: "Badajoz",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "38.89125",
    airport_lon: "-6.821333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 365,
    airport_code: "BKB",
    airport_name: "Bikaner Arpt",
    airport_city_code: "BKB",
    airport_city_name: "Bikaner",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 366,
    airport_code: "BKG",
    airport_name: "Branson Regional Arpt",
    airport_city_code: "BKG",
    airport_city_name: "Branson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.531994",
    airport_lon: "-93.200556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 367,
    airport_code: "BKI",
    airport_name: "Kota Kinabalu Arpt",
    airport_city_code: "BKI",
    airport_city_name: "Kota Kinabalu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.937208",
    airport_lon: "116.051181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 368,
    airport_code: "DMK",
    airport_name: "Don Mueang International Arpt",
    airport_city_code: "BKK",
    airport_city_name: "Bangkok",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "13.912583",
    airport_lon: "100.60675",
    airport_num_airports: 2,
  },
  {
    airport_id: 369,
    airport_code: "BKK",
    airport_name: "Suvarnabhumi Intl Arpt",
    airport_city_code: "BKK",
    airport_city_name: "Bangkok",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "13.681108",
    airport_lon: "100.747283",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 370,
    airport_code: "BKM",
    airport_name: "Bakalalan Arpt",
    airport_city_code: "BKM",
    airport_city_name: "Bakalalan",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.974",
    airport_lon: "115.618",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 371,
    airport_code: "BKO",
    airport_name: "Senou Airport",
    airport_city_code: "BKO",
    airport_city_name: "Bamako",
    airport_country_name: "MALI",
    airport_country_code: "ML",
    airport_timezone: "0",
    airport_lat: "12.533544",
    airport_lon: "-7.949944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 372,
    airport_code: "BKQ",
    airport_name: "Blackall Arpt",
    airport_city_code: "BKQ",
    airport_city_name: "Blackall",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-24.427778",
    airport_lon: "145.428611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 373,
    airport_code: "BKS",
    airport_name: "Padangkemiling Arpt",
    airport_city_code: "BKS",
    airport_city_name: "Bengkulu",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-3.8637",
    airport_lon: "102.339036",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 374,
    airport_code: "BKW",
    airport_name: "Raleigh Cty Memorial",
    airport_city_code: "BKW",
    airport_city_name: "Beckley",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.7873",
    airport_lon: "-81.1242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 375,
    airport_code: "BKX",
    airport_name: "Brookings Municipal",
    airport_city_code: "BKX",
    airport_city_name: "Brookings",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.3048",
    airport_lon: "-96.8169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 376,
    airport_code: "BLA",
    airport_name: "Gen J A Anzoategui Arpt",
    airport_city_code: "BLA",
    airport_city_name: "Barcelona",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.107139",
    airport_lon: "-64.689161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 377,
    airport_code: "XXO",
    airport_name: "Leksand Rail Station",
    airport_city_code: "BLE",
    airport_city_name: "Borlange",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "28.615",
    airport_lon: "-80.695",
    airport_num_airports: 4,
  },
  {
    airport_id: 378,
    airport_code: "XXU",
    airport_name: "Hedemora Rail Station",
    airport_city_code: "BLE",
    airport_city_name: "Borlange",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 379,
    airport_code: "XYB",
    airport_name: "Borlange Railway Service",
    airport_city_code: "BLE",
    airport_city_name: "Borlange",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 380,
    airport_code: "BLE",
    airport_name: "Dala Airport",
    airport_city_code: "BLE",
    airport_city_name: "Borlange",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "60.422017",
    airport_lon: "15.515211",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 381,
    airport_code: "BLF",
    airport_name: "Mercer County",
    airport_city_code: "BLF",
    airport_city_name: "Bluefield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.2958",
    airport_lon: "-81.2077",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 382,
    airport_code: "BLG",
    airport_name: "Belaga Arpt",
    airport_city_code: "BLG",
    airport_city_name: "Belaga",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "2.65",
    airport_lon: "113.767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 383,
    airport_code: "BLH",
    airport_name: "Blythe Airport",
    airport_city_code: "BLH",
    airport_city_name: "Blythe",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.619167",
    airport_lon: "-114.716889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 384,
    airport_code: "BLI",
    airport_name: "Bellingham Intl Arpt",
    airport_city_code: "BLI",
    airport_city_name: "Bellingham",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.792694",
    airport_lon: "-122.537528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 385,
    airport_code: "BLJ",
    airport_name: "Batna Arpt",
    airport_city_code: "BLJ",
    airport_city_name: "Batna",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "35.752106",
    airport_lon: "6.308589",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 386,
    airport_code: "XQL",
    airport_name: "Lancaster Rail Station",
    airport_city_code: "BLK",
    airport_city_name: "Blackpool",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 387,
    airport_code: "BLK",
    airport_name: "Blackpool Airport",
    airport_city_code: "BLK",
    airport_city_name: "Blackpool",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.771667",
    airport_lon: "-3.028611",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 388,
    airport_code: "BLL",
    airport_name: "Billund Airport",
    airport_city_code: "BLL",
    airport_city_name: "Billund",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.740322",
    airport_lon: "9.151778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 389,
    airport_code: "BLQ",
    airport_name: "Guglielmo Marconi",
    airport_city_code: "BLQ",
    airport_city_name: "Bologna",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.535444",
    airport_lon: "11.288667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 390,
    airport_code: "BLR",
    airport_name: "Bengaluru Intl Arpt",
    airport_city_code: "BLR",
    airport_city_name: "Bangalore",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "12.949986",
    airport_lon: "77.668206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 391,
    airport_code: "BLT",
    airport_name: "Blackwater Arpt",
    airport_city_code: "BLT",
    airport_city_name: "Blackwater",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-23.6031",
    airport_lon: "148.807",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 392,
    airport_code: "BLV",
    airport_name: "Scott AFB Mid America",
    airport_city_code: "BLV",
    airport_city_name: "Belleville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.545178",
    airport_lon: "-89.835183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 393,
    airport_code: "BLX",
    airport_name: "Belluno Arpt",
    airport_city_code: "BLX",
    airport_city_name: "Belluno",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 394,
    airport_code: "BLZ",
    airport_name: "Chileka Airport",
    airport_city_code: "BLZ",
    airport_city_name: "Blantyre",
    airport_country_name: "MALAWI",
    airport_country_code: "MW",
    airport_timezone: "2",
    airport_lat: "-15.679053",
    airport_lon: "34.974014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 395,
    airport_code: "BME",
    airport_name: "Broome Airport",
    airport_city_code: "BME",
    airport_city_name: "Broome",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-17.8",
    airport_lon: "122.2",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 396,
    airport_code: "BMG",
    airport_name: "Monroe Cty Arpt Indiana",
    airport_city_code: "BMG",
    airport_city_name: "Bloomington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "39.146021",
    airport_lon: "-86.616681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 397,
    airport_code: "BMI",
    airport_name: "Normal Airport",
    airport_city_code: "BMI",
    airport_city_name: "Bloomington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.477111",
    airport_lon: "-88.915917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 398,
    airport_code: "BMP",
    airport_name: "Brampton Island Arpt",
    airport_city_code: "BMP",
    airport_city_name: "Brampton Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 399,
    airport_code: "BMV",
    airport_name: "Phung Duc Arpt",
    airport_city_code: "BMV",
    airport_city_name: "Banmethuot",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "12.668311",
    airport_lon: "108.120272",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 400,
    airport_code: "BNA",
    airport_name: "Nashville International Arpt",
    airport_city_code: "BNA",
    airport_city_name: "Nashville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.124472",
    airport_lon: "-86.678194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 401,
    airport_code: "BND",
    airport_name: "Bandar Abbas Arpt",
    airport_city_code: "BND",
    airport_city_name: "Bandar Abbas",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "27.218317",
    airport_lon: "56.37785",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 402,
    airport_code: "BNE",
    airport_name: "Brisbane Arpt",
    airport_city_code: "BNE",
    airport_city_name: "Brisbane",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-27.384167",
    airport_lon: "153.1175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 403,
    airport_code: "BNJ",
    airport_name: "Bonn Railroad Station",
    airport_city_code: "BNJ",
    airport_city_name: "Bonn",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "50.7319",
    airport_lon: "7.0967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 404,
    airport_code: "BNK",
    airport_name: "Ballina Arpt",
    airport_city_code: "BNK",
    airport_city_name: "Ballina",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-28.833889",
    airport_lon: "153.5625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 405,
    airport_code: "BNN",
    airport_name: "Bronnoy Arpt",
    airport_city_code: "BNN",
    airport_city_name: "Bronnoysund",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "65.461111",
    airport_lon: "12.2175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 406,
    airport_code: "BNO",
    airport_name: "Burns Municipal Arpt",
    airport_city_code: "BNO",
    airport_city_name: "Burns",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "43.5919",
    airport_lon: "-118.9554",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 407,
    airport_code: "BNU",
    airport_name: "Blumenau Arpt",
    airport_city_code: "BNU",
    airport_city_name: "Blumenau",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-26.834239",
    airport_lon: "-49.091696",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 408,
    airport_code: "BNX",
    airport_name: "Banja Luka Arpt",
    airport_city_code: "BNX",
    airport_city_name: "Banja Luka",
    airport_country_name: "BOSNIA AND HERZEGOVINA",
    airport_country_code: "BA",
    airport_timezone: "1",
    airport_lat: "44.941444",
    airport_lon: "17.297501",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 409,
    airport_code: "BOB",
    airport_name: "Motu Mute Arpt",
    airport_city_code: "BOB",
    airport_city_name: "Bora Bora",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-16.444378",
    airport_lon: "-151.751286",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 410,
    airport_code: "BOC",
    airport_name: "Bocas Del Toro Arpt",
    airport_city_code: "BOC",
    airport_city_name: "Bocas Del Toro",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "9.340853",
    airport_lon: "-82.250842",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 411,
    airport_code: "ZFQ",
    airport_name: "Gare de Bordeaux",
    airport_city_code: "BOD",
    airport_city_name: "Bordeaux",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "48.6833",
    airport_lon: "1.1",
    airport_num_airports: 2,
  },
  {
    airport_id: 412,
    airport_code: "BOD",
    airport_name: "Bordeaux Arpt",
    airport_city_code: "BOD",
    airport_city_name: "Bordeaux",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.828335",
    airport_lon: "-0.715556",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 413,
    airport_code: "BOG",
    airport_name: "Eldorado Airport",
    airport_city_code: "BOG",
    airport_city_name: "Bogota",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "4.701594",
    airport_lon: "-74.146947",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 414,
    airport_code: "BOH",
    airport_name: "Bournemouth Intl Arpt",
    airport_city_code: "BOH",
    airport_city_name: "Bournemouth",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.78",
    airport_lon: "-1.8425",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 415,
    airport_code: "BOI",
    airport_name: "Boise Municipal Arpt Gowen Field",
    airport_city_code: "BOI",
    airport_city_name: "Boise",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.564361",
    airport_lon: "-116.222861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 416,
    airport_code: "BOJ",
    airport_name: "Bourgas Arpt",
    airport_city_code: "BOJ",
    airport_city_name: "Bourgas",
    airport_country_name: "BULGARIA",
    airport_country_code: "BG",
    airport_timezone: "2",
    airport_lat: "42.569583",
    airport_lon: "27.515236",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 417,
    airport_code: "BOK",
    airport_name: "Brookings State Arpt",
    airport_city_code: "BOK",
    airport_city_name: "Brookings",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 418,
    airport_code: "BOM",
    airport_name: "Chhatrapati Shivaji",
    airport_city_code: "BOM",
    airport_city_name: "Mumbai",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "19.088686",
    airport_lon: "72.867919",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 419,
    airport_code: "BON",
    airport_name: "Flamingo Field",
    airport_city_code: "BON",
    airport_city_name: "Bonaire",
    airport_country_name: "NETHERLANDS ANTILLES",
    airport_country_code: "AN",
    airport_timezone: "-4",
    airport_lat: "12.131044",
    airport_lon: "-68.268511",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 420,
    airport_code: "BOO",
    airport_name: "Bodo Arpt",
    airport_city_code: "BOO",
    airport_city_name: "Bodo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "67.269167",
    airport_lon: "14.365278",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 421,
    airport_code: "ZXM",
    airport_name: "Rognan Rail Station",
    airport_city_code: "BOO",
    airport_city_name: "Bodo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 422,
    airport_code: "BOS",
    airport_name: "Logan Intl Arpt",
    airport_city_code: "BOS",
    airport_city_name: "Boston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.364347",
    airport_lon: "-71.005181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 423,
    airport_code: "BOU",
    airport_name: "Bourges Arpt",
    airport_city_code: "BOU",
    airport_city_name: "Bourges",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.058056",
    airport_lon: "2.370278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 424,
    airport_code: "BOW",
    airport_name: "Bartow Arpt",
    airport_city_code: "BOW",
    airport_city_name: "Bartow",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "27.9434",
    airport_lon: "-81.7834",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 425,
    airport_code: "BOX",
    airport_name: "Borroloola Arpt",
    airport_city_code: "BOX",
    airport_city_name: "Borroloola",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "3",
    airport_lat: "51.478506",
    airport_lon: "7.2222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 426,
    airport_code: "BOY",
    airport_name: "Borgo Arpt",
    airport_city_code: "BOY",
    airport_city_name: "Bobo Dioulasso",
    airport_country_name: "BURKINA FASO",
    airport_country_code: "BF",
    airport_timezone: "0",
    airport_lat: "11.160056",
    airport_lon: "-4.330969",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 427,
    airport_code: "BPN",
    airport_name: "Sepingan Arpt",
    airport_city_code: "BPN",
    airport_city_name: "Balikpapan",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "-1.268272",
    airport_lon: "116.894478",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 428,
    airport_code: "BPS",
    airport_name: "Porto Seguro Arpt",
    airport_city_code: "BPS",
    airport_city_name: "Porto Seguro",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-16.438611",
    airport_lon: "-39.080833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 429,
    airport_code: "BPT",
    airport_name: "Jefferson Cty Arpt",
    airport_city_code: "BPT",
    airport_city_name: "Beaumont",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.950833",
    airport_lon: "-94.020694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 430,
    airport_code: "BQB",
    airport_name: "Busselton Arpt",
    airport_city_code: "BQB",
    airport_city_name: "Busselton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-33.6867",
    airport_lon: "115.4",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 431,
    airport_code: "BQK",
    airport_name: "Gylnco Jet Port",
    airport_city_code: "BQK",
    airport_city_name: "Brunswick",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "31.2588",
    airport_lon: "-81.4665",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 432,
    airport_code: "BQL",
    airport_name: "Boulia Arpt",
    airport_city_code: "BQL",
    airport_city_name: "Boulia",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-22.9133",
    airport_lon: "139.9",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 433,
    airport_code: "BQN",
    airport_name: "Borinquen Municipal Arpt",
    airport_city_code: "BQN",
    airport_city_name: "Aguadilla",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.494861",
    airport_lon: "-67.129444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 434,
    airport_code: "BQS",
    airport_name: "Blagoveshchensk Arpt",
    airport_city_code: "BQS",
    airport_city_name: "Blagoveshchensk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "10",
    airport_lat: "50.425394",
    airport_lon: "127.412478",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 435,
    airport_code: "BQT",
    airport_name: "Brest Arpt",
    airport_city_code: "BQT",
    airport_city_name: "Brest",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "52.06",
    airport_lon: "23.53",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 436,
    airport_code: "BRA",
    airport_name: "Barreiras Arpt",
    airport_city_code: "BRA",
    airport_city_name: "Barreiras",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-12.083333",
    airport_lon: "-45",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 437,
    airport_code: "BRC",
    airport_name: "San Carlos De Bariloche Intl Arpt",
    airport_city_code: "BRC",
    airport_city_name: "San Carlos Bariloche",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-41.151172",
    airport_lon: "-71.157542",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 438,
    airport_code: "BRD",
    airport_name: "Crowwing Cty Arpt",
    airport_city_code: "BRD",
    airport_city_name: "Brainerd",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "46.398308",
    airport_lon: "-94.138078",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 439,
    airport_code: "BRE",
    airport_name: "Bremen Airport",
    airport_city_code: "BRE",
    airport_city_name: "Bremen",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.0475",
    airport_lon: "8.786667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 440,
    airport_code: "BRF",
    airport_name: "Bradford Rail Station",
    airport_city_code: "BRF",
    airport_city_name: "Bradford",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 441,
    airport_code: "BRI",
    airport_name: "Bari Airport",
    airport_city_code: "BRI",
    airport_city_name: "Bari",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "41.138856",
    airport_lon: "16.760594",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 442,
    airport_code: "BRK",
    airport_name: "Bourke Airport",
    airport_city_code: "BRK",
    airport_city_name: "Bourke",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-30.0392",
    airport_lon: "145.952",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 443,
    airport_code: "BRL",
    airport_name: "Burlington Municipal Arpt",
    airport_city_code: "BRL",
    airport_city_name: "Burlington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.7832",
    airport_lon: "-91.1255",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 444,
    airport_code: "BRM",
    airport_name: "Airport Barquisimeto",
    airport_city_code: "BRM",
    airport_city_name: "Barquisimeto",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.042747",
    airport_lon: "-69.358619",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 445,
    airport_code: "BRN",
    airport_name: "Belp Airport",
    airport_city_code: "BRN",
    airport_city_name: "Berne",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "46.9141",
    airport_lon: "7.497153",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 446,
    airport_code: "ZDJ",
    airport_name: "Berne Railroad Station",
    airport_city_code: "BRN",
    airport_city_name: "Berne",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 447,
    airport_code: "BRO",
    airport_name: "South Padre Island Intl Arpt",
    airport_city_code: "BRO",
    airport_city_name: "Brownsville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "25.906833",
    airport_lon: "-97.425861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 448,
    airport_code: "BRQ",
    airport_name: "Turnay Arpt",
    airport_city_code: "BRQ",
    airport_city_name: "Brno",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "1",
    airport_lat: "49.151269",
    airport_lon: "16.694433",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 449,
    airport_code: "BRR",
    airport_name: "North Bay Arpt",
    airport_city_code: "BRR",
    airport_city_name: "Barra",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "57.0228",
    airport_lon: "-7.44306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 450,
    airport_code: "BRS",
    airport_name: "Bristol Intl Arpt",
    airport_city_code: "BRS",
    airport_city_name: "Bristol",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.382669",
    airport_lon: "-2.719089",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 451,
    airport_code: "BRT",
    airport_name: "Bathurst Island Arpt",
    airport_city_code: "BRT",
    airport_city_name: "Bathurst Isl",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-11.769167",
    airport_lon: "130.619722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 452,
    airport_code: "CRL",
    airport_name: "Brussels South Charleroi Arpt",
    airport_city_code: "BRU",
    airport_city_name: "Brussels",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "1",
    airport_lat: "50.459197",
    airport_lon: "4.453817",
    airport_num_airports: 4,
  },
  {
    airport_id: 453,
    airport_code: "ZYR",
    airport_name: "Midi Railway Station",
    airport_city_code: "BRU",
    airport_city_name: "Brussels",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "1",
    airport_lat: "50.8",
    airport_lon: "4.4",
    airport_num_airports: 4,
  },
  {
    airport_id: 454,
    airport_code: "ZYZ",
    airport_name: "Antwerp Berchem Rail Station",
    airport_city_code: "BRU",
    airport_city_name: "Brussels",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "-100",
    airport_lat: "51.2167",
    airport_lon: "4.4167",
    airport_num_airports: 4,
  },
  {
    airport_id: 455,
    airport_code: "BRU",
    airport_name: "Brussels National Arpt",
    airport_city_code: "BRU",
    airport_city_name: "Brussels",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "1",
    airport_lat: "50.901389",
    airport_lon: "4.484444",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 456,
    airport_code: "BRV",
    airport_name: "Bremerhaven Arpt",
    airport_city_code: "BRV",
    airport_city_name: "Bremerhaven",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.507081",
    airport_lon: "8.572878",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 457,
    airport_code: "BRW",
    airport_name: "Barrow WBAS",
    airport_city_code: "BRW",
    airport_city_name: "Barrow",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "71.285446",
    airport_lon: "-156.766003",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 458,
    airport_code: "BRX",
    airport_name: "Barahona Arpt",
    airport_city_code: "BRX",
    airport_city_name: "Barahona",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "18.251464",
    airport_lon: "-71.1204",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 459,
    airport_code: "BSB",
    airport_name: "Brasilia Intl Arpt",
    airport_city_code: "BSB",
    airport_city_name: "Brasilia",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-15.8711",
    airport_lon: "-47.918625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 460,
    airport_code: "BSD",
    airport_name: "Baoshan Arpt",
    airport_city_code: "BSD",
    airport_city_name: "Baoshan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "25.053333",
    airport_lon: "99.168333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 461,
    airport_code: "BSH",
    airport_name: "Brighton Airport",
    airport_city_code: "BSH",
    airport_city_name: "Brighton",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 462,
    airport_code: "BSI",
    airport_name: "Blairsville Arpt",
    airport_city_code: "BSI",
    airport_city_name: "Blairsville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 463,
    airport_code: "BSJ",
    airport_name: "Bairnsdale Airport",
    airport_city_code: "BSJ",
    airport_city_name: "Bairnsdale",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-37.8883",
    airport_lon: "147.5683",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 464,
    airport_code: "BSK",
    airport_name: "Biskra Arpt",
    airport_city_code: "BSK",
    airport_city_name: "Biskra",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "34.793289",
    airport_lon: "5.738231",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 465,
    airport_code: "BSL",
    airport_name: "Basel EuroAirport Swiss",
    airport_city_code: "BSL",
    airport_city_name: "Basel",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "47.59",
    airport_lon: "7.529167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 466,
    airport_code: "BSQ",
    airport_name: "Bisbee Municipal Arpt",
    airport_city_code: "BSQ",
    airport_city_name: "Bisbee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 467,
    airport_code: "BSR",
    airport_name: "Basra Intl Arpt",
    airport_city_code: "BSR",
    airport_city_name: "Basra",
    airport_country_name: "IRAQ",
    airport_country_code: "IQ",
    airport_timezone: "3",
    airport_lat: "30.549069",
    airport_lon: "47.662142",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 468,
    airport_code: "BTH",
    airport_name: "Hang Nadim Arpt",
    airport_city_code: "BTH",
    airport_city_name: "Batam",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "1.121028",
    airport_lon: "104.118753",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 469,
    airport_code: "BTJ",
    airport_name: "Blang Bintang Arpt",
    airport_city_code: "BTJ",
    airport_city_name: "Banda Aceh",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "5.523522",
    airport_lon: "95.420372",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 470,
    airport_code: "BTK",
    airport_name: "Bratsk Arpt",
    airport_city_code: "BTK",
    airport_city_name: "Bratsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "9",
    airport_lat: "56.370556",
    airport_lon: "101.698331",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 471,
    airport_code: "BTL",
    airport_name: "WK Kellogg Regional",
    airport_city_code: "BTL",
    airport_city_name: "Battle Creek",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "42.3075",
    airport_lon: "-85.2517",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 472,
    airport_code: "BTM",
    airport_name: "Bert Mooney Arpt",
    airport_city_code: "BTM",
    airport_city_name: "Butte",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "45.954806",
    airport_lon: "-112.497472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 473,
    airport_code: "EBR",
    airport_name: "Baton Rouge Downtown Arpt",
    airport_city_code: "BTR",
    airport_city_name: "Baton Rouge",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 474,
    airport_code: "BTR",
    airport_name: "Ryan Airport",
    airport_city_code: "BTR",
    airport_city_name: "Baton Rouge",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.533167",
    airport_lon: "-91.149639",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 475,
    airport_code: "ZRG",
    airport_name: "Bratislava Bus Station",
    airport_city_code: "BTS",
    airport_city_name: "Bratislava",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 476,
    airport_code: "BTS",
    airport_name: "Ivanka Arpt",
    airport_city_code: "BTS",
    airport_city_name: "Bratislava",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "1",
    airport_lat: "48.170167",
    airport_lon: "17.212667",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 477,
    airport_code: "BTT",
    airport_name: "Bettles Arpt",
    airport_city_code: "BTT",
    airport_city_name: "Bettles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "66.913944",
    airport_lon: "-151.529056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 478,
    airport_code: "BTU",
    airport_name: "Bintulu Arpt",
    airport_city_code: "BTU",
    airport_city_name: "Bintulu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.12385",
    airport_lon: "113.020472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 479,
    airport_code: "BTV",
    airport_name: "Burlington Intl Arpt",
    airport_city_code: "BTV",
    airport_city_name: "Burlington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.471861",
    airport_lon: "-73.153278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 480,
    airport_code: "YEI",
    airport_name: "Yenisehir Arpt",
    airport_city_code: "BTZ",
    airport_city_name: "Bursa",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.255208",
    airport_lon: "29.562569",
    airport_num_airports: 2,
  },
  {
    airport_id: 481,
    airport_code: "BTZ",
    airport_name: "Bursa Arpt",
    airport_city_code: "BTZ",
    airport_city_name: "Bursa",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.2489",
    airport_lon: "29.5494",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 482,
    airport_code: "BUC",
    airport_name: "Burketown Arpt",
    airport_city_code: "BUC",
    airport_city_name: "Burketown",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-17.7486",
    airport_lon: "139.534",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 483,
    airport_code: "BUD",
    airport_name: "Ferihegy Arpt",
    airport_city_code: "BUD",
    airport_city_name: "Budapest",
    airport_country_name: "HUNGARY",
    airport_country_code: "HU",
    airport_timezone: "1",
    airport_lat: "47.436933",
    airport_lon: "19.255592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 484,
    airport_code: "EZE",
    airport_name: "Ministro Pistarini",
    airport_city_code: "BUE",
    airport_city_name: "Buenos Aires",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-34.822222",
    airport_lon: "-58.535833",
    airport_num_airports: 2,
  },
  {
    airport_id: 485,
    airport_code: "AEP",
    airport_name: "Jorge Newbery",
    airport_city_code: "BUE",
    airport_city_name: "Buenos Aires",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-34.559175",
    airport_lon: "-58.415606",
    airport_num_airports: 2,
  },
  {
    airport_id: 486,
    airport_code: "BUF",
    airport_name: "Greater Buffalo Intl Arpt",
    airport_city_code: "BUF",
    airport_city_name: "Buffalo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.940525",
    airport_lon: "-78.732167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 487,
    airport_code: "OTP",
    airport_name: "Otopeni International",
    airport_city_code: "BUH",
    airport_city_name: "Bucharest",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "44.572161",
    airport_lon: "26.102178",
    airport_num_airports: 2,
  },
  {
    airport_id: 488,
    airport_code: "BBU",
    airport_name: "Baneasa Airport",
    airport_city_code: "BUH",
    airport_city_name: "Bucharest",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "44.503194",
    airport_lon: "26.102111",
    airport_num_airports: 2,
  },
  {
    airport_id: 489,
    airport_code: "BUN",
    airport_name: "Buenaventura Arpt",
    airport_city_code: "BUN",
    airport_city_name: "Buenaventura",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "3.819628",
    airport_lon: "-76.989767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 490,
    airport_code: "BUQ",
    airport_name: "Bulawayo Arpt",
    airport_city_code: "BUQ",
    airport_city_name: "Bulawayo",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "2",
    airport_lat: "-20.017431",
    airport_lon: "28.617869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 491,
    airport_code: "BUR",
    airport_name: "Burbank Glendale Pasadena Arpt",
    airport_city_code: "BUR",
    airport_city_name: "Burbank",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.200667",
    airport_lon: "-118.358667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 492,
    airport_code: "BUS",
    airport_name: "Batumi Arpt",
    airport_city_code: "BUS",
    airport_city_name: "Batumi",
    airport_country_name: "GEORGIA",
    airport_country_code: "GE",
    airport_timezone: "2",
    airport_lat: "41.610278",
    airport_lon: "41.599694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 493,
    airport_code: "BUY",
    airport_name: "Bunbury Arpt",
    airport_city_code: "BUY",
    airport_city_name: "Bunbury",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-4",
    airport_lat: "36.048543",
    airport_lon: "-79.474889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 494,
    airport_code: "BUZ",
    airport_name: "Bushehr Arpt",
    airport_city_code: "BUZ",
    airport_city_name: "Bushehr",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "28.944811",
    airport_lon: "50.834637",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 495,
    airport_code: "BVB",
    airport_name: "Boa Vista Arpt",
    airport_city_code: "BVB",
    airport_city_name: "Boa Vista",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "2.846311",
    airport_lon: "-60.690069",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 496,
    airport_code: "BVC",
    airport_name: "Rabil Arpt",
    airport_city_code: "BVC",
    airport_city_name: "Boa Vista",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "16.136531",
    airport_lon: "-22.888897",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 497,
    airport_code: "BVE",
    airport_name: "Laroche Airport",
    airport_city_code: "BVE",
    airport_city_name: "Brive La Gaill",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.150833",
    airport_lon: "1.469167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 498,
    airport_code: "BVH",
    airport_name: "Vilhena Arpt",
    airport_city_code: "BVH",
    airport_city_name: "Vilhena",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-12.694375",
    airport_lon: "-60.098269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 499,
    airport_code: "BVI",
    airport_name: "Birdsville Arpt",
    airport_city_code: "BVI",
    airport_city_name: "Birdsville",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-25.8975",
    airport_lon: "139.348",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 500,
    airport_code: "BVO",
    airport_name: "Bartlesville Municipal Arpt",
    airport_city_code: "BVO",
    airport_city_name: "Bartlesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 501,
    airport_code: "BVR",
    airport_name: "Esperadinha",
    airport_city_code: "BVR",
    airport_city_name: "Brava",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 502,
    airport_code: "BVX",
    airport_name: "Batesville Municipal",
    airport_city_code: "BVX",
    airport_city_name: "Batesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 503,
    airport_code: "BVY",
    airport_name: "Beverly Municipal Arpt",
    airport_city_code: "BVY",
    airport_city_name: "Beverly",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "42.5842",
    airport_lon: "-70.9165",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 504,
    airport_code: "BWC",
    airport_name: "Brawley Arpt",
    airport_city_code: "BWC",
    airport_city_name: "Brawley",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 505,
    airport_code: "BWD",
    airport_name: "Brownwood Municipal Arpt",
    airport_city_code: "BWD",
    airport_city_name: "Brownwood",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.793611",
    airport_lon: "-98.9565",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 506,
    airport_code: "BWE",
    airport_name: "Braunschweig Arpt",
    airport_city_code: "BWE",
    airport_city_name: "Braunschweig",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "52.319167",
    airport_lon: "10.556111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 507,
    airport_code: "BWF",
    airport_name: "Walney Island",
    airport_city_code: "BWF",
    airport_city_name: "Barrow In Furness",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "54.131167",
    airport_lon: "-3.263667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 508,
    airport_code: "BWG",
    airport_name: "Warren Cty Arpt",
    airport_city_code: "BWG",
    airport_city_name: "Bowling Green",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "36.964528",
    airport_lon: "-86.419667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 509,
    airport_code: "BWI",
    airport_name: "Baltimore Washington Intl Arpt",
    airport_city_code: "BWI",
    airport_city_name: "Baltimore",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.175361",
    airport_lon: "-76.668333",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 510,
    airport_code: "APG",
    airport_name: "Phillips Army Air Field",
    airport_city_code: "BWI",
    airport_city_name: "Baltimore",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.466219",
    airport_lon: "-76.168808",
    airport_num_airports: 2,
  },
  {
    airport_id: 511,
    airport_code: "BWK",
    airport_name: "Bol Airport",
    airport_city_code: "BWK",
    airport_city_name: "Bol Airport",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "43.285719",
    airport_lon: "16.679719",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 512,
    airport_code: "BWN",
    airport_name: "Brunei Intl Arpt",
    airport_city_code: "BWN",
    airport_city_name: "Bandar Seri Begawan",
    airport_country_name: "BRUNEI DARUSSALAM",
    airport_country_code: "BN",
    airport_timezone: "8",
    airport_lat: "4.9442",
    airport_lon: "114.928353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 513,
    airport_code: "BWO",
    airport_name: "Balakovo Airport",
    airport_city_code: "BWO",
    airport_city_name: "Balakovo Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "51.8583",
    airport_lon: "47.7456",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 514,
    airport_code: "BWQ",
    airport_name: "Brewarrina Arpt",
    airport_city_code: "BWQ",
    airport_city_name: "Brewarrina",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-29.9756",
    airport_lon: "146.8156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 515,
    airport_code: "BWT",
    airport_name: "Burnie Wynyard Arpt",
    airport_city_code: "BWT",
    airport_city_name: "Burnie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-40.9989",
    airport_lon: "145.731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 516,
    airport_code: "BWU",
    airport_name: "Bankstown Aerodrome",
    airport_city_code: "BWU",
    airport_city_name: "Bankstown",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-33.924444",
    airport_lon: "150.988333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 517,
    airport_code: "BJV",
    airport_name: "Milas Arpt",
    airport_city_code: "BXN",
    airport_city_name: "Bodrum",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.82",
    airport_lon: "28.204",
    airport_num_airports: 1,
  },
  {
    airport_id: 518,
    airport_code: "BXS",
    airport_name: "Borrego Valley Arpt",
    airport_city_code: "BXS",
    airport_city_name: "Borrego Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.259028",
    airport_lon: "-116.320972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 519,
    airport_code: "BXU",
    airport_name: "Butuan Arpt",
    airport_city_code: "BXU",
    airport_city_name: "Butuan",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "8.951322",
    airport_lon: "125.477972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 520,
    airport_code: "BYF",
    airport_name: "Albert Arpt",
    airport_city_code: "BYF",
    airport_city_name: "Meaulte",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "49.97",
    airport_lon: "2.6928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 521,
    airport_code: "BYI",
    airport_name: "Burley Arpt",
    airport_city_code: "BYI",
    airport_city_name: "Burley",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "42.5433",
    airport_lon: "-113.7717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 522,
    airport_code: "BYR",
    airport_name: "Laeso Airport",
    airport_city_code: "BYR",
    airport_city_name: "Laeso Island",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "-100",
    airport_lat: "57.2772",
    airport_lon: "11.0001",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 523,
    airport_code: "BYU",
    airport_name: "Bindlacher Berg Arpt",
    airport_city_code: "BYU",
    airport_city_name: "Bayreuth",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.984428",
    airport_lon: "11.638569",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 524,
    airport_code: "BYW",
    airport_name: "Blakely Is Municipal",
    airport_city_code: "BYW",
    airport_city_name: "Blakely Island",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.56025",
    airport_lon: "-122.80243",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 525,
    airport_code: "BZC",
    airport_name: "Buzios Arpt",
    airport_city_code: "BZC",
    airport_city_name: "Buzios",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-22.7656",
    airport_lon: "-41.965",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 526,
    airport_code: "TZA",
    airport_name: "Belize City Municipal",
    airport_city_code: "BZE",
    airport_city_name: "Belize City",
    airport_country_name: "BELIZE",
    airport_country_code: "BZ",
    airport_timezone: "-6",
    airport_lat: "17.5344",
    airport_lon: "-88.298",
    airport_num_airports: 2,
  },
  {
    airport_id: 527,
    airport_code: "BZE",
    airport_name: "Belize Intl Arpt",
    airport_city_code: "BZE",
    airport_city_name: "Belize City",
    airport_country_name: "BELIZE",
    airport_country_code: "BZ",
    airport_timezone: "-6",
    airport_lat: "17.539144",
    airport_lon: "-88.308203",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 528,
    airport_code: "BZG",
    airport_name: "Bydgoszcz Arpt",
    airport_city_code: "BZG",
    airport_city_name: "Bydgoszcz",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "53.0968",
    airport_lon: "17.9777",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 529,
    airport_code: "BZH",
    airport_name: "Bumi Hills Arpt",
    airport_city_code: "BZH",
    airport_city_name: "Bumi Hills",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 530,
    airport_code: "BZI",
    airport_name: "Balikesir",
    airport_city_code: "BZI",
    airport_city_name: "Balikesir",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.619258",
    airport_lon: "27.925958",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 531,
    airport_code: "MQJ",
    airport_name: "Merkez Arpt",
    airport_city_code: "BZI",
    airport_city_name: "Balikesir",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 532,
    airport_code: "BZN",
    airport_name: "Gallatin Field",
    airport_city_code: "BZN",
    airport_city_name: "Bozeman",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "45.777643",
    airport_lon: "-111.160151",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 533,
    airport_code: "BZO",
    airport_name: "Bolzano Arpt",
    airport_city_code: "BZO",
    airport_city_name: "Bolzano",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "46.460194",
    airport_lon: "11.326383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 534,
    airport_code: "BZR",
    airport_name: "Beziers Vias Arpt",
    airport_city_code: "BZR",
    airport_city_name: "Beziers",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.323522",
    airport_lon: "3.353903",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 535,
    airport_code: "BZT",
    airport_name: "Brazoria County Arpt",
    airport_city_code: "BZT",
    airport_city_name: "Brazoria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 536,
    airport_code: "BZV",
    airport_name: "Maya Maya Arpt",
    airport_city_code: "BZV",
    airport_city_name: "Brazzaville",
    airport_country_name: "CONGO",
    airport_country_code: "CG",
    airport_timezone: "1",
    airport_lat: "-4.2517",
    airport_lon: "15.253031",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 537,
    airport_code: "CAC",
    airport_name: "Cascavel Arpt",
    airport_city_code: "CAC",
    airport_city_name: "Cascavel",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-25.000339",
    airport_lon: "-53.500764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 538,
    airport_code: "CAD",
    airport_name: "Wexford County Arpt",
    airport_city_code: "CAD",
    airport_city_name: "Cadillac",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 539,
    airport_code: "OGB",
    airport_name: "Orangeburg Municpal",
    airport_city_code: "CAE",
    airport_city_name: "Columbia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 540,
    airport_code: "CAE",
    airport_name: "Columbia Metro Arpt",
    airport_city_code: "CAE",
    airport_city_name: "Columbia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.938833",
    airport_lon: "-81.119528",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 541,
    airport_code: "CAG",
    airport_name: "Elmas Airport",
    airport_city_code: "CAG",
    airport_city_name: "Cagliari",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "39.251469",
    airport_lon: "9.054283",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 542,
    airport_code: "CAI",
    airport_name: "Cairo Intl Arpt",
    airport_city_code: "CAI",
    airport_city_name: "Cairo",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "30.121944",
    airport_lon: "31.405556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 543,
    airport_code: "CAK",
    airport_name: "Akron Canton Regional Arpt",
    airport_city_code: "CAK",
    airport_city_name: "Akron",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.916083",
    airport_lon: "-81.442194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 544,
    airport_code: "CAL",
    airport_name: "Machrihanish Arpt",
    airport_city_code: "CAL",
    airport_city_name: "Campbelltown",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.4372",
    airport_lon: "-5.68639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 545,
    airport_code: "CAM",
    airport_name: "Choreti Arpt",
    airport_city_code: "CAM",
    airport_city_name: "Camiri",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 546,
    airport_code: "CAN",
    airport_name: "Baiyun Airport",
    airport_city_code: "CAN",
    airport_city_name: "Guangzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "23.392436",
    airport_lon: "113.298786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 547,
    airport_code: "CAP",
    airport_name: "Cap Haitien Numicipal",
    airport_city_code: "CAP",
    airport_city_name: "Cap Haitien",
    airport_country_name: "HAITI",
    airport_country_code: "HT",
    airport_timezone: "-5",
    airport_lat: "19.732989",
    airport_lon: "-72.194739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 548,
    airport_code: "CAR",
    airport_name: "Caribou Municipal Arpt",
    airport_city_code: "CAR",
    airport_city_name: "Caribou",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "46.8715",
    airport_lon: "-68.017917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 549,
    airport_code: "CMN",
    airport_name: "Mohamed V Arpt",
    airport_city_code: "CAS",
    airport_city_name: "Casablanca",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "33.367467",
    airport_lon: "-7.589967",
    airport_num_airports: 2,
  },
  {
    airport_id: 550,
    airport_code: "CAS",
    airport_name: "Anfa Airport",
    airport_city_code: "CAS",
    airport_city_name: "Casablanca",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "-100",
    airport_lat: "33.5533",
    airport_lon: "-7.6614",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 551,
    airport_code: "CAU",
    airport_name: "Caruaru Arpt",
    airport_city_code: "CAU",
    airport_city_name: "Caruaru",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-8.28239",
    airport_lon: "-36.0135",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 552,
    airport_code: "CAW",
    airport_name: "Bartolomeu Lisandro Arpt",
    airport_city_code: "CAW",
    airport_city_name: "Campos",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-21.698333",
    airport_lon: "-41.301669",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 553,
    airport_code: "XPF",
    airport_name: "Penrith Rail Station",
    airport_city_code: "CAX",
    airport_city_name: "Carlisle",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 554,
    airport_code: "CAX",
    airport_name: "Carlisle Arpt",
    airport_city_code: "CAX",
    airport_city_name: "Carlisle",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "54.9375",
    airport_lon: "-2.809167",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 555,
    airport_code: "CAY",
    airport_name: "Rochambeau Airport",
    airport_city_code: "CAY",
    airport_city_name: "Cayenne",
    airport_country_name: "FRENCH GUIANA",
    airport_country_code: "GF",
    airport_timezone: "-3",
    airport_lat: "4.819808",
    airport_lon: "-52.360447",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 556,
    airport_code: "CAZ",
    airport_name: "Cobar Arpt",
    airport_city_code: "CAZ",
    airport_city_name: "Cobar",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-31.5383",
    airport_lon: "145.794",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 557,
    airport_code: "CBB",
    airport_name: "J Wilsterman Arpt",
    airport_city_code: "CBB",
    airport_city_name: "Cochabamba",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-17.421058",
    airport_lon: "-66.177114",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 558,
    airport_code: "CBE",
    airport_name: "Cumberland Municipal Arpt",
    airport_city_code: "CBE",
    airport_city_name: "Cumberland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 559,
    airport_code: "CBF",
    airport_name: "Council Bluffs Municipal Arpt",
    airport_city_code: "CBF",
    airport_city_name: "Council Bluffs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 560,
    airport_code: "CBG",
    airport_name: "Cambridge Arpt",
    airport_city_code: "CBG",
    airport_city_name: "Cambridge",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.205",
    airport_lon: "0.175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 561,
    airport_code: "CBK",
    airport_name: "Colby Municipal Arpt",
    airport_city_code: "CBK",
    airport_city_name: "Colby",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 562,
    airport_code: "CBL",
    airport_name: "Ciudad Bolivar Arpt",
    airport_city_code: "CBL",
    airport_city_name: "Ciudad Bolivar",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "8.121898",
    airport_lon: "-63.537353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 563,
    airport_code: "CBN",
    airport_name: "Penggung Arpt",
    airport_city_code: "CBN",
    airport_city_name: "Cirebon",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-6.756144",
    airport_lon: "108.539672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 564,
    airport_code: "CBO",
    airport_name: "Awang Arpt",
    airport_city_code: "CBO",
    airport_city_name: "Cotabato",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "7.165242",
    airport_lon: "124.209619",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 565,
    airport_code: "CBP",
    airport_name: "Coimbra Arpt",
    airport_city_code: "CBP",
    airport_city_name: "Coimbra",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 566,
    airport_code: "CBR",
    airport_name: "Canberra Arpt",
    airport_city_code: "CBR",
    airport_city_name: "Canberra",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-35.306944",
    airport_lon: "149.195",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 567,
    airport_code: "CBS",
    airport_name: "Oro Negro Arpt",
    airport_city_code: "CBS",
    airport_city_name: "Cabimas",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 568,
    airport_code: "CCC",
    airport_name: "Cayo Coco Arpt",
    airport_city_code: "CCC",
    airport_city_name: "Cayo Coco",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "22.5132",
    airport_lon: "-78.511",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 569,
    airport_code: "CCE",
    airport_name: "Grand Case Arpt",
    airport_city_code: "CCE",
    airport_city_name: "Saint Martin",
    airport_country_name: "GUADELOUPE",
    airport_country_code: "GP",
    airport_timezone: "-100",
    airport_lat: "18.1006",
    airport_lon: "-63.0489",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 570,
    airport_code: "CCF",
    airport_name: "Salvaza",
    airport_city_code: "CCF",
    airport_city_name: "Carcassonne",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.215978",
    airport_lon: "2.306317",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 571,
    airport_code: "CCJ",
    airport_name: "Kozhikode Arpt",
    airport_city_code: "CCJ",
    airport_city_name: "Kozhikode",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "11.136839",
    airport_lon: "75.9553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 572,
    airport_code: "CCK",
    airport_name: "Cocos Islands Arpt",
    airport_city_code: "CCK",
    airport_city_name: "Cocos Islands",
    airport_country_name: "COCOS (KEELING) ISLANDS",
    airport_country_code: "CC",
    airport_timezone: "6",
    airport_lat: "-12.1883",
    airport_lon: "96.8339",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 573,
    airport_code: "CCM",
    airport_name: "Criciuma Arpt",
    airport_city_code: "CCM",
    airport_city_name: "Criciuma",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-28.725817",
    airport_lon: "-49.424739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 574,
    airport_code: "CCP",
    airport_name: "Carriel Sur Arpt",
    airport_city_code: "CCP",
    airport_city_name: "Concepcion",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-36.77265",
    airport_lon: "-73.063106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 575,
    airport_code: "CCR",
    airport_name: "Buchanan Field",
    airport_city_code: "CCR",
    airport_city_name: "Concord",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.989667",
    airport_lon: "-122.056889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 576,
    airport_code: "CCS",
    airport_name: "Simon Bolivar Arpt",
    airport_city_code: "CCS",
    airport_city_name: "Caracas",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.603117",
    airport_lon: "-66.990583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 577,
    airport_code: "CCU",
    airport_name: "Netaji Subhas Chandra Bose Intl",
    airport_city_code: "CCU",
    airport_city_name: "Kolkata",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "22.654739",
    airport_lon: "88.446722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 578,
    airport_code: "CCV",
    airport_name: "Craig Cove Arpt",
    airport_city_code: "CCV",
    airport_city_name: "Craig Cove",
    airport_country_name: "VANUATU",
    airport_country_code: "VU",
    airport_timezone: "11",
    airport_lat: "-16.265",
    airport_lon: "167.924",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 579,
    airport_code: "CDA",
    airport_name: "Cooinda Airport",
    airport_city_code: "CDA",
    airport_city_name: "Cooinda",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-12.9033",
    airport_lon: "132.532",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 580,
    airport_code: "CDC",
    airport_name: "Cedar City Municipal",
    airport_city_code: "CDC",
    airport_city_name: "Cedar City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.700967",
    airport_lon: "-113.098847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 581,
    airport_code: "CDD",
    airport_name: "Cauquira Arpt",
    airport_city_code: "CDD",
    airport_city_name: "Cauquira",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 582,
    airport_code: "CDH",
    airport_name: "Harrell Fieldsandro Arpt",
    airport_city_code: "CDH",
    airport_city_name: "Camden",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "33.6233",
    airport_lon: "-92.7633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 583,
    airport_code: "CDI",
    airport_name: "Cachoeiro De Itapemirim Arpt",
    airport_city_code: "CDI",
    airport_city_name: "Cachoeiro De Itapemirim",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-5",
    airport_lat: "39.975028",
    airport_lon: "-81.577583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 584,
    airport_code: "CDR",
    airport_name: "Chadron Arpt",
    airport_city_code: "CDR",
    airport_city_name: "Chadron",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "42.8375",
    airport_lon: "-103.095556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 585,
    airport_code: "CDV",
    airport_name: "Mudhole Smith Arpt",
    airport_city_code: "CDV",
    airport_city_name: "Cordova",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "60.491778",
    airport_lon: "-145.477556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 586,
    airport_code: "CDW",
    airport_name: "Caldwell Wright Arpt",
    airport_city_code: "CDW",
    airport_city_name: "Caldwell",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "40.875222",
    airport_lon: "-74.281361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 587,
    airport_code: "CEB",
    airport_name: "Cebu Intl",
    airport_city_code: "CEB",
    airport_city_name: "Cebu",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "10.307542",
    airport_lon: "123.979439",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 588,
    airport_code: "CEC",
    airport_name: "Crescent City Municipal Arpt",
    airport_city_code: "CEC",
    airport_city_name: "Crescent City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "41.7802",
    airport_lon: "-124.237",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 589,
    airport_code: "CED",
    airport_name: "Ceduna Arpt",
    airport_city_code: "CED",
    airport_city_name: "Ceduna",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-32.1306",
    airport_lon: "133.71",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 590,
    airport_code: "CEE",
    airport_name: "Cherepovets Airport",
    airport_city_code: "CEE",
    airport_city_name: "Cherepovets Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "59.2736",
    airport_lon: "38.0158",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 591,
    airport_code: "XRC",
    airport_name: "Runcorn Rail Station",
    airport_city_code: "CEG",
    airport_city_name: "Chester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 592,
    airport_code: "CEG",
    airport_name: "Chester Arpt",
    airport_city_code: "CEG",
    airport_city_name: "Chester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.178056",
    airport_lon: "-2.977778",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 593,
    airport_code: "CEH",
    airport_name: "Chelinda Arpt",
    airport_city_code: "CEH",
    airport_city_name: "Chelinda",
    airport_country_name: "MALAWI",
    airport_country_code: "MW",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 594,
    airport_code: "CEI",
    airport_name: "Chaing Rai Arpt",
    airport_city_code: "CEI",
    airport_city_name: "Chiang Rai",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "19.952342",
    airport_lon: "99.882928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 595,
    airport_code: "CEK",
    airport_name: "Chelyabinsk Arpt",
    airport_city_code: "CEK",
    airport_city_name: "Chelyabinsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "55.305836",
    airport_lon: "61.503333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 596,
    airport_code: "CEN",
    airport_name: "Ciudad Obregon Arpt",
    airport_city_code: "CEN",
    airport_city_name: "Ciudad Obregon",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "27.392639",
    airport_lon: "-109.833111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 597,
    airport_code: "CEQ",
    airport_name: "Mandelieu Arpt",
    airport_city_code: "CEQ",
    airport_city_name: "Cannes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.54205",
    airport_lon: "6.953478",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 598,
    airport_code: "CER",
    airport_name: "Maupertius Arpt",
    airport_city_code: "CER",
    airport_city_name: "Cherbourg",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.650106",
    airport_lon: "-1.470281",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 599,
    airport_code: "CES",
    airport_name: "Cessnock Arpt",
    airport_city_code: "CES",
    airport_city_name: "Cessnock",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-32.7875",
    airport_lon: "151.342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 600,
    airport_code: "CET",
    airport_name: "Le Pontreau Arpt",
    airport_city_code: "CET",
    airport_city_name: "Cholet",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.082136",
    airport_lon: "-0.877064",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 601,
    airport_code: "CEU",
    airport_name: "Clemson Oconee Cty Arpt",
    airport_city_code: "CEU",
    airport_city_name: "Clemson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 602,
    airport_code: "CEY",
    airport_name: "Calloway Cty Arpt",
    airport_city_code: "CEY",
    airport_city_name: "Murray",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 603,
    airport_code: "CEZ",
    airport_name: "Montezuma County",
    airport_city_code: "CEZ",
    airport_city_name: "Cortez",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.303",
    airport_lon: "-108.628056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 604,
    airport_code: "CFB",
    airport_name: "Cabo Frio Arpt",
    airport_city_code: "CFB",
    airport_city_name: "Cabo Frio",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.921667",
    airport_lon: "-42.074167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 605,
    airport_code: "CFE",
    airport_name: "Aulnat Arpt",
    airport_city_code: "CFE",
    airport_city_name: "Clermont Ferrand",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.786661",
    airport_lon: "3.169169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 606,
    airport_code: "CFG",
    airport_name: "Cienfuegos Arpt",
    airport_city_code: "CFG",
    airport_city_name: "Cienfuegos",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "22.15",
    airport_lon: "-80.414167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 607,
    airport_code: "CFK",
    airport_name: "Chlef Arpt",
    airport_city_code: "CFK",
    airport_city_name: "Chlef",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "-100",
    airport_lat: "36.21",
    airport_lon: "1.33",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 608,
    airport_code: "CFN",
    airport_name: "Donegal Arpt",
    airport_city_code: "CFN",
    airport_city_name: "Donegal",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "55.044192",
    airport_lon: "-8.341",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 609,
    airport_code: "CFQ",
    airport_name: "Creston Arpt",
    airport_city_code: "CFQ",
    airport_city_name: "Creston",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 610,
    airport_code: "CFR",
    airport_name: "Carpiquet Arpt",
    airport_city_code: "CFR",
    airport_city_name: "Caen",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.173333",
    airport_lon: "-0.45",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 611,
    airport_code: "CFS",
    airport_name: "Coffs Harbour Arpt",
    airport_city_code: "CFS",
    airport_city_name: "Coffs Harbour",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-30.320556",
    airport_lon: "153.116389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 612,
    airport_code: "CFU",
    airport_name: "I Kapodistrias Arpt",
    airport_city_code: "CFU",
    airport_city_name: "Kerkyra",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.601944",
    airport_lon: "19.911667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 613,
    airport_code: "CGA",
    airport_name: "Craig Seaplane Base",
    airport_city_code: "CGA",
    airport_city_name: "Craig",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "55.478889",
    airport_lon: "-133.147778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 614,
    airport_code: "CGB",
    airport_name: "Marechal Rondon Arpt",
    airport_city_code: "CGB",
    airport_city_name: "Cuiaba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-15.652931",
    airport_lon: "-56.116719",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 615,
    airport_code: "CGD",
    airport_name: "Changde Arpt",
    airport_city_code: "CGD",
    airport_city_name: "Changde",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.9189",
    airport_lon: "111.64",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 616,
    airport_code: "CGI",
    airport_name: "Cape Girardeau Municipal Arpt",
    airport_city_code: "CGI",
    airport_city_name: "Cape Girardeau",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.2253",
    airport_lon: "-89.5708",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 617,
    airport_code: "QKL",
    airport_name: "Cologne Railroad Station",
    airport_city_code: "CGN",
    airport_city_name: "Cologne",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.9",
    airport_lon: "7.183",
    airport_num_airports: 2,
  },
  {
    airport_id: 618,
    airport_code: "CGN",
    airport_name: "Koeln Bonn Arpt",
    airport_city_code: "CGN",
    airport_city_name: "Cologne",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.865917",
    airport_lon: "7.142744",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 619,
    airport_code: "CGO",
    airport_name: "Zhengzhou Arpt",
    airport_city_code: "CGO",
    airport_city_name: "Zhengzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.519672",
    airport_lon: "113.840889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 620,
    airport_code: "CGP",
    airport_name: "Patenga Arpt",
    airport_city_code: "CGP",
    airport_city_name: "Chittagong",
    airport_country_name: "BANGLADESH",
    airport_country_code: "BD",
    airport_timezone: "6",
    airport_lat: "22.249611",
    airport_lon: "91.813286",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 621,
    airport_code: "CGQ",
    airport_name: "Changchun Arpt",
    airport_city_code: "CGQ",
    airport_city_name: "Changchun",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "43.5412",
    airport_lon: "125.1201",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 622,
    airport_code: "CGR",
    airport_name: "Internacional",
    airport_city_code: "CGR",
    airport_city_name: "Campogrande",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-20.468667",
    airport_lon: "-54.6725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 623,
    airport_code: "CGY",
    airport_name: "Lumbia Arpt",
    airport_city_code: "CGY",
    airport_city_name: "Cagayan",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "8.415619",
    airport_lon: "124.611219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 624,
    airport_code: "CHA",
    airport_name: "Chattanooga Lovell Fld",
    airport_city_code: "CHA",
    airport_city_name: "Chattanooga",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.035278",
    airport_lon: "-85.203808",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 625,
    airport_code: "CHC",
    airport_name: "Christchurch Arpt",
    airport_city_code: "CHC",
    airport_city_name: "Christchurch",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-43.489358",
    airport_lon: "172.532225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 626,
    airport_code: "CHD",
    airport_name: "Williams AFB Airport",
    airport_city_code: "CHD",
    airport_city_name: "Chandler",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 627,
    airport_code: "PWK",
    airport_name: "Pal Waukee Arpt",
    airport_city_code: "CHI",
    airport_city_name: "Chicago",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.11429",
    airport_lon: "-87.901538",
    airport_num_airports: 5,
  },
  {
    airport_id: 628,
    airport_code: "RFD",
    airport_name: "Chicago Rockford Arpt",
    airport_city_code: "CHI",
    airport_city_name: "Chicago",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.195361",
    airport_lon: "-89.097222",
    airport_num_airports: 5,
  },
  {
    airport_id: 629,
    airport_code: "DPA",
    airport_name: "Dupage County Arpt",
    airport_city_code: "CHI",
    airport_city_name: "Chicago",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.907778",
    airport_lon: "-88.248611",
    airport_num_airports: 5,
  },
  {
    airport_id: 630,
    airport_code: "ORD",
    airport_name: "O'Hare Intl Arpt",
    airport_city_code: "CHI",
    airport_city_name: "Chicago",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.978603",
    airport_lon: "-87.904842",
    airport_num_airports: 5,
  },
  {
    airport_id: 631,
    airport_code: "MDW",
    airport_name: "Midway",
    airport_city_code: "CHI",
    airport_city_name: "Chicago",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.785972",
    airport_lon: "-87.752417",
    airport_num_airports: 5,
  },
  {
    airport_id: 632,
    airport_code: "CHO",
    airport_name: "Charlottesville Albemarle Arpt",
    airport_city_code: "CHO",
    airport_city_name: "Charlottesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.138639",
    airport_lon: "-78.452861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 633,
    airport_code: "CHQ",
    airport_name: "Souda Arpt",
    airport_city_code: "CHQ",
    airport_city_name: "Chania",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "35.531747",
    airport_lon: "24.149678",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 634,
    airport_code: "CHR",
    airport_name: "Chateauroux Arpt",
    airport_city_code: "CHR",
    airport_city_name: "Chateauroux",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.862194",
    airport_lon: "1.730667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 635,
    airport_code: "CHS",
    airport_name: "Charleston Intl Arpt",
    airport_city_code: "CHS",
    airport_city_name: "Charleston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.898647",
    airport_lon: "-80.040528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 636,
    airport_code: "CHW",
    airport_name: "Jiuquan Arpt",
    airport_city_code: "CHW",
    airport_city_name: "Jiuquan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 637,
    airport_code: "CHX",
    airport_name: "Changuinola Arpt",
    airport_city_code: "CHX",
    airport_city_name: "Changuinola",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "9.458636",
    airport_lon: "-82.516806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 638,
    airport_code: "CIC",
    airport_name: "Chico Municipal Arpt",
    airport_city_code: "CIC",
    airport_city_name: "Chico",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "39.795383",
    airport_lon: "-121.858422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 639,
    airport_code: "CID",
    airport_name: "Cedar Rapids Municipal Arpt",
    airport_city_code: "CID",
    airport_city_name: "Cedar Rapids",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.884694",
    airport_lon: "-91.710806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 640,
    airport_code: "CIG",
    airport_name: "Craig Moffat",
    airport_city_code: "CIG",
    airport_city_name: "Craig",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.495",
    airport_lon: "-107.5214",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 641,
    airport_code: "CIL",
    airport_name: "Melsing Creek Arpt",
    airport_city_code: "CIL",
    airport_city_name: "Council",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "64.897778",
    airport_lon: "-163.703333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 642,
    airport_code: "CIO",
    airport_name: "Mcal Lopez Arpt",
    airport_city_code: "CIO",
    airport_city_name: "Concepcion",
    airport_country_name: "PARAGUAY",
    airport_country_code: "PY",
    airport_timezone: "2",
    airport_lat: "45.4715",
    airport_lon: "21.1136",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 643,
    airport_code: "CIT",
    airport_name: "Shimkent Arpt",
    airport_city_code: "CIT",
    airport_city_name: "Shimkent",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "42.364167",
    airport_lon: "69.478889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 644,
    airport_code: "CIW",
    airport_name: "Canouan Island Minicipal",
    airport_city_code: "CIW",
    airport_city_name: "Canovan Island",
    airport_country_name: "DOMINICA",
    airport_country_code: "DM",
    airport_timezone: "-4",
    airport_lat: "12.699042",
    airport_lon: "-61.342431",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 645,
    airport_code: "CIX",
    airport_name: "Cornel Ruiz Arpt",
    airport_city_code: "CIX",
    airport_city_name: "Chiclayo",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-6.787475",
    airport_lon: "-79.828097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 646,
    airport_code: "CIY",
    airport_name: "Comiso Arpt",
    airport_city_code: "CIY",
    airport_city_name: "Comiso",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 647,
    airport_code: "CJB",
    airport_name: "Peelamedu Airport",
    airport_city_code: "CJB",
    airport_city_name: "Coimbatore",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "11.030031",
    airport_lon: "77.043383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 648,
    airport_code: "CJC",
    airport_name: "El Loa Arpt",
    airport_city_code: "CJC",
    airport_city_name: "Calama",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-22.498175",
    airport_lon: "-68.903575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 649,
    airport_code: "CJI",
    airport_name: "Ciudad Real City Arpt",
    airport_city_code: "CJI",
    airport_city_name: "Ciudad Real City",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 650,
    airport_code: "CJJ",
    airport_name: "Cheongju Arpt",
    airport_city_code: "CJJ",
    airport_city_name: "Cheongju",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "36.7166",
    airport_lon: "127.499119",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 651,
    airport_code: "CJM",
    airport_name: "Chumphon Arpt",
    airport_city_code: "CJM",
    airport_city_name: "Chumphon",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "10.7112",
    airport_lon: "99.361706",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 652,
    airport_code: "CJN",
    airport_name: "El Cajon Arpt",
    airport_city_code: "CJN",
    airport_city_name: "El Cajon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 653,
    airport_code: "CJS",
    airport_name: "Intl Abraham Gonzalez",
    airport_city_code: "CJS",
    airport_city_name: "Ciudad Juarez",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "31.636133",
    airport_lon: "-106.428667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 654,
    airport_code: "CJU",
    airport_name: "Jeju Intl Arpt",
    airport_city_code: "CJU",
    airport_city_name: "Jeju City",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "33.511306",
    airport_lon: "126.493028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 655,
    airport_code: "CKB",
    airport_name: "Clarksburg Benedum Arpt",
    airport_city_code: "CKB",
    airport_city_name: "Clarksburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.2966",
    airport_lon: "-80.2281",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 656,
    airport_code: "CKG",
    airport_name: "Chongqing Arpt",
    airport_city_code: "CKG",
    airport_city_name: "Chongqing",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.719217",
    airport_lon: "106.641678",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 657,
    airport_code: "CKH",
    airport_name: "Chokurdakh Airport",
    airport_city_code: "CKH",
    airport_city_name: "Chokurdakh Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "12",
    airport_lat: "70.6231",
    airport_lon: "147.902",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 658,
    airport_code: "CKM",
    airport_name: "Fletcher Field",
    airport_city_code: "CKM",
    airport_city_name: "Clarksdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 659,
    airport_code: "CKS",
    airport_name: "Carajas Arpt",
    airport_city_code: "CKS",
    airport_city_name: "Carajas",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-6.11781",
    airport_lon: "-50.0035",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 660,
    airport_code: "CKV",
    airport_name: "Outlaw Field",
    airport_city_code: "CKV",
    airport_city_name: "Clarksville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 661,
    airport_code: "CKY",
    airport_name: "Conakry Airport",
    airport_city_code: "CKY",
    airport_city_name: "Conakry",
    airport_country_name: "GUINEA",
    airport_country_code: "GN",
    airport_timezone: "0",
    airport_lat: "9.576889",
    airport_lon: "-13.611961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 662,
    airport_code: "CKZ",
    airport_name: "Canakkale Arpt",
    airport_city_code: "CKZ",
    airport_city_name: "Canakkale",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.137722",
    airport_lon: "26.426777",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 663,
    airport_code: "CLC",
    airport_name: "Metroport",
    airport_city_code: "CLC",
    airport_city_name: "Clear Lake City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 664,
    airport_code: "CLD",
    airport_name: "Carlsbad Arpt",
    airport_city_code: "CLD",
    airport_city_name: "Carlsbad",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.0742",
    airport_lon: "-117.1648",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 665,
    airport_code: "CGF",
    airport_name: "Cuyahoga County Airport",
    airport_city_code: "CLE",
    airport_city_name: "Cleveland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.565124",
    airport_lon: "-81.486356",
    airport_num_airports: 3,
  },
  {
    airport_id: 666,
    airport_code: "CLE",
    airport_name: "Hopkins Intl Arpt",
    airport_city_code: "CLE",
    airport_city_name: "Cleveland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.411689",
    airport_lon: "-81.849794",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 667,
    airport_code: "BKL",
    airport_name: "Burke Lakefront Arpt",
    airport_city_code: "CLE",
    airport_city_name: "Cleveland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.525",
    airport_lon: "-81.6833",
    airport_num_airports: 3,
  },
  {
    airport_id: 668,
    airport_code: "CLJ",
    airport_name: "Napoca Arpt",
    airport_city_code: "CLJ",
    airport_city_name: "Cluj",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "46.785167",
    airport_lon: "23.686167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 669,
    airport_code: "CLL",
    airport_name: "Easterwood Field",
    airport_city_code: "CLL",
    airport_city_name: "College Station",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.588583",
    airport_lon: "-96.363833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 670,
    airport_code: "CLM",
    airport_name: "William Fairchild Intl Arpt",
    airport_city_code: "CLM",
    airport_city_name: "Port Angeles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.1202",
    airport_lon: "-123.5",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 671,
    airport_code: "CLO",
    airport_name: "Alfonso Bonella Aragon Arpt",
    airport_city_code: "CLO",
    airport_city_name: "Cali",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "3.543222",
    airport_lon: "-76.381583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 672,
    airport_code: "CLQ",
    airport_name: "Colima Arpt",
    airport_city_code: "CLQ",
    airport_city_name: "Colima",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.277011",
    airport_lon: "-103.577397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 673,
    airport_code: "CLT",
    airport_name: "Charlotte Douglas Intl Arpt",
    airport_city_code: "CLT",
    airport_city_name: "Charlotte",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.214",
    airport_lon: "-80.943139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 674,
    airport_code: "CLU",
    airport_name: "Columbus Municipal Arpt",
    airport_city_code: "CLU",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.2617",
    airport_lon: "-85.8967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 675,
    airport_code: "CLW",
    airport_name: "Clearwater Executive Airpark",
    airport_city_code: "CLW",
    airport_city_name: "Clearwater",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 676,
    airport_code: "CLY",
    airport_name: "Ste Catherine Arpt",
    airport_city_code: "CLY",
    airport_city_name: "Calvi",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "42.530753",
    airport_lon: "8.793189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 677,
    airport_code: "CMA",
    airport_name: "Cunnamulla Arpt",
    airport_city_code: "CMA",
    airport_city_name: "Cunnamulla",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-28.03",
    airport_lon: "145.622",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 678,
    airport_code: "CMB",
    airport_name: "Bandaranaike Intl Arpt",
    airport_city_code: "CMB",
    airport_city_name: "Colombo",
    airport_country_name: "SRI LANKA",
    airport_country_code: "LK",
    airport_timezone: "6",
    airport_lat: "7.180756",
    airport_lon: "79.884117",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 679,
    airport_code: "CME",
    airport_name: "Ciudad Del Carmen Arpt",
    airport_city_code: "CME",
    airport_city_name: "Ciudad Del Carmen",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "18.653739",
    airport_lon: "-91.799017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 680,
    airport_code: "CMF",
    airport_name: "Chambery Aix Les Bains Arpt",
    airport_city_code: "CMF",
    airport_city_name: "Chambery",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.63805",
    airport_lon: "5.880225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 681,
    airport_code: "CMG",
    airport_name: "Internacional Corumba",
    airport_city_code: "CMG",
    airport_city_name: "Corumba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-19.011931",
    airport_lon: "-57.673053",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 682,
    airport_code: "OSU",
    airport_name: "Ohio State Univ Arpt",
    airport_city_code: "CMH",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.0798",
    airport_lon: "-83.073",
    airport_num_airports: 3,
  },
  {
    airport_id: 683,
    airport_code: "LCK",
    airport_name: "Rickenbacker Intl Arpt",
    airport_city_code: "CMH",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.813786",
    airport_lon: "-82.927822",
    airport_num_airports: 3,
  },
  {
    airport_id: 684,
    airport_code: "CMH",
    airport_name: "Port Columbus Intl Arpt",
    airport_city_code: "CMH",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.997972",
    airport_lon: "-82.891889",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 685,
    airport_code: "CMI",
    airport_name: "Univ Of Illinois Willard Arpt",
    airport_city_code: "CMI",
    airport_city_name: "Champaign",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.03925",
    airport_lon: "-88.278056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 686,
    airport_code: "CMQ",
    airport_name: "Clermont Arpt",
    airport_city_code: "CMQ",
    airport_city_name: "Clermont",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-22.7694",
    airport_lon: "147.6194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 687,
    airport_code: "CMR",
    airport_name: "Colmar Houssen Arpt",
    airport_city_code: "CMR",
    airport_city_name: "Colmar",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.109853",
    airport_lon: "7.359011",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 688,
    airport_code: "CMV",
    airport_name: "Coromandel Arpt",
    airport_city_code: "CMV",
    airport_city_name: "Coromandel",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 689,
    airport_code: "CMW",
    airport_name: "Ign Agramonte Intl Arpt",
    airport_city_code: "CMW",
    airport_city_name: "Camaguey",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "21.420428",
    airport_lon: "-77.847433",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 690,
    airport_code: "CMX",
    airport_name: "Houghton Cty Memorial Arpt",
    airport_city_code: "CMX",
    airport_city_name: "Houghton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "47.1684",
    airport_lon: "-88.4891",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 691,
    airport_code: "CMY",
    airport_name: "Camp Mccoy Aaf",
    airport_city_code: "CMY",
    airport_city_name: "Sparta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 692,
    airport_code: "CNB",
    airport_name: "Coonamble Arpt",
    airport_city_code: "CNB",
    airport_city_name: "Coonamble",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-30.9833",
    airport_lon: "148.376",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 693,
    airport_code: "CNC",
    airport_name: "Coconut Island Arpt",
    airport_city_code: "CNC",
    airport_city_name: "Coconut Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-10.05",
    airport_lon: "143.07",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 694,
    airport_code: "CND",
    airport_name: "Kogalniceanu Arpt",
    airport_city_code: "CND",
    airport_city_name: "Constanta",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "44.362222",
    airport_lon: "28.488333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 695,
    airport_code: "CNG",
    airport_name: "Parvaud Arpt",
    airport_city_code: "CNG",
    airport_city_name: "Cognac",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.658333",
    airport_lon: "-0.3175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 696,
    airport_code: "CNJ",
    airport_name: "Cloncurry Arpt",
    airport_city_code: "CNJ",
    airport_city_name: "Cloncurry",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.6686",
    airport_lon: "140.504",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 697,
    airport_code: "CNM",
    airport_name: "Carlsbad Airport",
    airport_city_code: "CNM",
    airport_city_name: "Carlsbad",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.337472",
    airport_lon: "-104.263278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 698,
    airport_code: "CNQ",
    airport_name: "Camba Punta Arpt",
    airport_city_code: "CNQ",
    airport_city_name: "Corrientes",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-27.445503",
    airport_lon: "-58.761864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 699,
    airport_code: "CNS",
    airport_name: "Cairns Airport",
    airport_city_code: "CNS",
    airport_city_name: "Cairns",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-16.885833",
    airport_lon: "145.755278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 700,
    airport_code: "CNX",
    airport_name: "Chiang Mai Intl Arpt",
    airport_city_code: "CNX",
    airport_city_name: "Chiang Mai",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "18.766847",
    airport_lon: "98.962644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 701,
    airport_code: "CNY",
    airport_name: "Moab Municipal",
    airport_city_code: "CNY",
    airport_city_name: "Moab",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.755",
    airport_lon: "-109.754722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 702,
    airport_code: "COC",
    airport_name: "Concordia Arpt",
    airport_city_code: "COC",
    airport_city_name: "Concordia",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-31.296944",
    airport_lon: "-57.996631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 703,
    airport_code: "COD",
    airport_name: "Yellowstone Regional Arpt",
    airport_city_code: "COD",
    airport_city_name: "Cody",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.520194",
    airport_lon: "-109.023806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 704,
    airport_code: "COE",
    airport_name: "Coeur d Alene Municipal Arpt",
    airport_city_code: "COE",
    airport_city_name: "Coeur D Alene",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "47.7743",
    airport_lon: "-116.8196",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 705,
    airport_code: "COF",
    airport_name: "Patrick AFB",
    airport_city_code: "COI",
    airport_city_name: "Cocoa Metro Area",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "28.234922",
    airport_lon: "-80.610125",
    airport_num_airports: 2,
  },
  {
    airport_id: 706,
    airport_code: "COI",
    airport_name: "Merrit Island Arpt",
    airport_city_code: "COI",
    airport_city_name: "Cocoa Metro Area",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 707,
    airport_code: "COJ",
    airport_name: "Coonabarabran Arpt",
    airport_city_code: "COJ",
    airport_city_name: "Coonabarabrn",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-31.335",
    airport_lon: "149.27",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 708,
    airport_code: "COK",
    airport_name: "Kochi Intl Arpt",
    airport_city_code: "COK",
    airport_city_name: "Kochi",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "10.155556",
    airport_lon: "76.391389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 709,
    airport_code: "CON",
    airport_name: "Concord Arpt",
    airport_city_code: "CON",
    airport_city_name: "Concord",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.20267",
    airport_lon: "-71.50233",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 710,
    airport_code: "COO",
    airport_name: "Cotonou Airport",
    airport_city_code: "COO",
    airport_city_name: "Cotonou",
    airport_country_name: "BENIN",
    airport_country_code: "BJ",
    airport_timezone: "1",
    airport_lat: "6.357228",
    airport_lon: "2.384353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 711,
    airport_code: "COR",
    airport_name: "Pajas Blanco Arpt",
    airport_city_code: "COR",
    airport_city_name: "Cordoba",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-31.323619",
    airport_lon: "-64.207953",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 712,
    airport_code: "COS",
    airport_name: "Colorado Springs Municipal Arpt",
    airport_city_code: "COS",
    airport_city_name: "Colorado Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.805805",
    airport_lon: "-104.700778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 713,
    airport_code: "COU",
    airport_name: "Columbia Regional",
    airport_city_code: "COU",
    airport_city_name: "Columbia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.818094",
    airport_lon: "-92.219631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 714,
    airport_code: "CPC",
    airport_name: "Chapelco Arpt",
    airport_city_code: "CPC",
    airport_city_name: "Chapelco",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-40.075383",
    airport_lon: "-71.137294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 715,
    airport_code: "CPD",
    airport_name: "Coober Pedy Arpt",
    airport_city_code: "CPD",
    airport_city_name: "Coober Pedy",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-29.04",
    airport_lon: "134.721",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 716,
    airport_code: "CPE",
    airport_name: "Campeche Intl Arpt",
    airport_city_code: "CPE",
    airport_city_name: "Campeche",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.816794",
    airport_lon: "-90.500314",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 717,
    airport_code: "ZGH",
    airport_name: "Copenhagen Rail Station",
    airport_city_code: "CPH",
    airport_city_name: "Copenhagen",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 718,
    airport_code: "CPH",
    airport_name: "Copenhagen Arpt",
    airport_city_code: "CPH",
    airport_city_name: "Copenhagen",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.617917",
    airport_lon: "12.655972",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 719,
    airport_code: "CPO",
    airport_name: "Chamonate Arpt",
    airport_city_code: "CPO",
    airport_city_name: "Copiapo",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-27",
    airport_lon: "-70",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 720,
    airport_code: "CPQ",
    airport_name: "International Campinas",
    airport_city_code: "CPQ",
    airport_city_name: "Campinas",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-22.8592",
    airport_lon: "-47.1082",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 721,
    airport_code: "CPR",
    airport_name: "Natrona Cty Intl Arpt",
    airport_city_code: "CPR",
    airport_city_name: "Casper",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "42.908",
    airport_lon: "-106.464417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 722,
    airport_code: "CPT",
    airport_name: "Cape Town International",
    airport_city_code: "CPT",
    airport_city_name: "Cape Town",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-33.964806",
    airport_lon: "18.601667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 723,
    airport_code: "CPV",
    airport_name: "Joao Suassuana Arpt",
    airport_city_code: "CPV",
    airport_city_name: "Campina Grande",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-7.269917",
    airport_lon: "-35.896364",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 724,
    airport_code: "CPX",
    airport_name: "Culebra Arpt",
    airport_city_code: "CPX",
    airport_city_name: "Culebra",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.3127",
    airport_lon: "-65.3034",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 725,
    airport_code: "CQD",
    airport_name: "Shahre Kord Arpt",
    airport_city_code: "CQD",
    airport_city_name: "Shahre Kord",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "32.2972",
    airport_lon: "50.8422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 726,
    airport_code: "CQM",
    airport_name: "Ciudad Real Central Arpt",
    airport_city_code: "CQM",
    airport_city_name: "Ciudad Real",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "38.856389",
    airport_lon: "-3.97",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 727,
    airport_code: "CRA",
    airport_name: "Craiova Arpt",
    airport_city_code: "CRA",
    airport_city_name: "Craiova",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "44.318139",
    airport_lon: "23.888611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 728,
    airport_code: "CRD",
    airport_name: "Comodoro Rivadavia Arpt",
    airport_city_code: "CRD",
    airport_city_name: "Comodoro Rivadavia",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-45.785347",
    airport_lon: "-67.465508",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 729,
    airport_code: "CRK",
    airport_name: "Clark Field Arpt",
    airport_city_code: "CRK",
    airport_city_name: "Luzon Island",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "15.185833",
    airport_lon: "120.560278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 730,
    airport_code: "CRP",
    airport_name: "Corpus Christi Intl Arpt",
    airport_city_code: "CRP",
    airport_city_name: "Corpus Christi",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "27.770361",
    airport_lon: "-97.501222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 731,
    airport_code: "CRV",
    airport_name: "Crotone Arpt",
    airport_city_code: "CRV",
    airport_city_name: "Crotone",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "38.997225",
    airport_lon: "17.080169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 732,
    airport_code: "CRW",
    airport_name: "Yeager Arpt",
    airport_city_code: "CRW",
    airport_city_name: "Charleston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.373147",
    airport_lon: "-81.593189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 733,
    airport_code: "CRX",
    airport_name: "Roscoe Turner Arpt",
    airport_city_code: "CRX",
    airport_city_name: "Corinth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 734,
    airport_code: "CRZ",
    airport_name: "Turkmenabad Arpt",
    airport_city_code: "CRZ",
    airport_city_name: "Turkmenabad",
    airport_country_name: "TURKMENISTAN",
    airport_country_code: "TM",
    airport_timezone: "5",
    airport_lat: "39.083333",
    airport_lon: "63.602222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 735,
    airport_code: "CSF",
    airport_name: "Creil Arpt",
    airport_city_code: "CSF",
    airport_city_name: "Creil",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.253547",
    airport_lon: "2.519139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 736,
    airport_code: "CSG",
    airport_name: "Columbus Metro Ft Benning Arpt",
    airport_city_code: "CSG",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.516333",
    airport_lon: "-84.938861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 737,
    airport_code: "CSI",
    airport_name: "Casino Arpt",
    airport_city_code: "CSI",
    airport_city_name: "Casino",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-28.8833",
    airport_lon: "153.0583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 738,
    airport_code: "SBP",
    airport_name: "San Luis Obispo County Arpt",
    airport_city_code: "CSL",
    airport_city_name: "San Luis Obispo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "35.2368",
    airport_lon: "-120.642",
    airport_num_airports: 2,
  },
  {
    airport_id: 739,
    airport_code: "CSL",
    airport_name: "O Sullivan Army Air Field",
    airport_city_code: "CSL",
    airport_city_name: "San Luis Obispo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 740,
    airport_code: "CLK",
    airport_name: "Clinton Municipal Arpt",
    airport_city_code: "CSM",
    airport_city_name: "Clinton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 741,
    airport_code: "CSM",
    airport_name: "Sherman Arpt",
    airport_city_code: "CSM",
    airport_city_name: "Clinton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "35.34",
    airport_lon: "-99.2",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 742,
    airport_code: "CSN",
    airport_name: "Carson Arpt",
    airport_city_code: "CSN",
    airport_city_name: "Carson City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 743,
    airport_code: "CSU",
    airport_name: "Santa Cruz Do Sul Arpt",
    airport_city_code: "CSU",
    airport_city_name: "Santa Cruz Do Sul",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 744,
    airport_code: "CSX",
    airport_name: "Changsha Arpt",
    airport_city_code: "CSX",
    airport_city_name: "Changsha",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.189158",
    airport_lon: "113.219633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 745,
    airport_code: "CSY",
    airport_name: "Cheboksary Arpt",
    airport_city_code: "CSY",
    airport_city_name: "Cheboksary",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "56.0903",
    airport_lon: "47.3473",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 746,
    airport_code: "CTA",
    airport_name: "Fontanarossa Arpt",
    airport_city_code: "CTA",
    airport_city_name: "Catania",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "37.466781",
    airport_lon: "15.0664",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 747,
    airport_code: "CTC",
    airport_name: "Choya Arpt",
    airport_city_code: "CTC",
    airport_city_name: "Catamarca",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-28.593214",
    airport_lon: "-65.750925",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 748,
    airport_code: "CTD",
    airport_name: "Chitre Airfield",
    airport_city_code: "CTD",
    airport_city_name: "Chitre",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "7.98784",
    airport_lon: "-80.4097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 749,
    airport_code: "CTG",
    airport_name: "Rafael Nunez Arpt",
    airport_city_code: "CTG",
    airport_city_name: "Cartagena",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "10.442381",
    airport_lon: "-75.512961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 750,
    airport_code: "CTL",
    airport_name: "Charleville Arpt",
    airport_city_code: "CTL",
    airport_city_name: "Charleville",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-26.413334",
    airport_lon: "146.2625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 751,
    airport_code: "CTM",
    airport_name: "Chetumal International",
    airport_city_code: "CTM",
    airport_city_name: "Chetumal",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "18.504667",
    airport_lon: "-88.326847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 752,
    airport_code: "CTN",
    airport_name: "Cooktown Arpt",
    airport_city_code: "CTN",
    airport_city_name: "Cooktown",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-15.4447",
    airport_lon: "145.184",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 753,
    airport_code: "CTU",
    airport_name: "Chengdu Arpt",
    airport_city_code: "CTU",
    airport_city_name: "Chengdu",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.578528",
    airport_lon: "103.947086",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 754,
    airport_code: "CTW",
    airport_name: "Cottonwood Airport",
    airport_city_code: "CTW",
    airport_city_name: "Cottonwood",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 755,
    airport_code: "CUA",
    airport_name: "Ciudad Constitucion Arpt",
    airport_city_code: "CUA",
    airport_city_name: "Ciudad Constitucion",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "25.0538",
    airport_lon: "-111.615",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 756,
    airport_code: "CUC",
    airport_name: "Camilo Dazo Arpt",
    airport_city_code: "CUC",
    airport_city_name: "Cucuta",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "7.927567",
    airport_lon: "-72.511547",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 757,
    airport_code: "CUE",
    airport_name: "Cuenca Arpt",
    airport_city_code: "CUE",
    airport_city_name: "Cuenca",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-5",
    airport_lat: "-2.889467",
    airport_lon: "-78.984397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 758,
    airport_code: "CUF",
    airport_name: "Levaldigi Arpt",
    airport_city_code: "CUF",
    airport_city_name: "Cuneo",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.547019",
    airport_lon: "7.623217",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 759,
    airport_code: "CUG",
    airport_name: "Cudal Arpt",
    airport_city_code: "CUG",
    airport_city_name: "Cudal",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-33.2783",
    airport_lon: "148.7633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 760,
    airport_code: "CUL",
    airport_name: "Fedl De Bachigualato Arpt",
    airport_city_code: "CUL",
    airport_city_name: "Culiacan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "24.764547",
    airport_lon: "-107.474717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 761,
    airport_code: "CUM",
    airport_name: "Antonio Jose De Sucre",
    airport_city_code: "CUM",
    airport_city_name: "Cumana",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.450333",
    airport_lon: "-64.130472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 762,
    airport_code: "CUN",
    airport_name: "Cancun Aeropuerto Internacional",
    airport_city_code: "CUN",
    airport_city_name: "Cancun",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "21.036528",
    airport_lon: "-86.877083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 763,
    airport_code: "CUQ",
    airport_name: "Coen Arpt",
    airport_city_code: "CUQ",
    airport_city_name: "Coen",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-13.7608",
    airport_lon: "143.114",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 764,
    airport_code: "CUR",
    airport_name: "Areopuerto Hato Arpt",
    airport_city_code: "CUR",
    airport_city_name: "Curacao",
    airport_country_name: "NETHERLANDS ANTILLES",
    airport_country_code: "AN",
    airport_timezone: "-4",
    airport_lat: "12.188853",
    airport_lon: "-68.959803",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 765,
    airport_code: "CUS",
    airport_name: "Columbus Municipal",
    airport_city_code: "CUS",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 766,
    airport_code: "CUU",
    airport_name: "Chihuahua Airport",
    airport_city_code: "CUU",
    airport_city_name: "Chihuahua",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "28.702875",
    airport_lon: "-105.964567",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 767,
    airport_code: "CUZ",
    airport_name: "Tte Velazco Astete Arpt",
    airport_city_code: "CUZ",
    airport_city_name: "Cuzco",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-13.535722",
    airport_lon: "-71.938781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 768,
    airport_code: "CVF",
    airport_name: "Courchevel Arpt",
    airport_city_code: "CVF",
    airport_city_name: "Courchevel",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.3967",
    airport_lon: "6.63472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 769,
    airport_code: "LUK",
    airport_name: "Cincinnati Municipal Arpt",
    airport_city_code: "CVG",
    airport_city_name: "Cincinnati",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.103333",
    airport_lon: "-84.418611",
    airport_num_airports: 2,
  },
  {
    airport_id: 770,
    airport_code: "CVG",
    airport_name: "Cincinnati No Kentucky Intl Arpt",
    airport_city_code: "CVG",
    airport_city_name: "Cincinnati",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.048836",
    airport_lon: "-84.667822",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 771,
    airport_code: "CVJ",
    airport_name: "Cuernavaca Arpt",
    airport_city_code: "CVJ",
    airport_city_name: "Cuernavaca",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "18.834764",
    airport_lon: "-99.2613",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 772,
    airport_code: "CVM",
    airport_name: "Ciudad Victoria Arpt",
    airport_city_code: "CVM",
    airport_city_name: "Ciudad Victoria",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "23.703336",
    airport_lon: "-98.956486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 773,
    airport_code: "CVN",
    airport_name: "Clovis Airport",
    airport_city_code: "CVN",
    airport_city_name: "Clovis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.425139",
    airport_lon: "-103.079278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 774,
    airport_code: "QWY",
    airport_name: "Albany Bus Service",
    airport_city_code: "CVO",
    airport_city_name: "Corvallis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 775,
    airport_code: "CVO",
    airport_name: "Corvallis Municipal Arpt",
    airport_city_code: "CVO",
    airport_city_name: "Corvallis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 776,
    airport_code: "CVQ",
    airport_name: "Carnarvon Arpt",
    airport_city_code: "CVQ",
    airport_city_name: "Carnarvon",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-24.8806",
    airport_lon: "113.672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 777,
    airport_code: "CVT",
    airport_name: "Baginton Arpt",
    airport_city_code: "CVT",
    airport_city_name: "Coventry",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.369722",
    airport_lon: "-1.479722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 778,
    airport_code: "CVU",
    airport_name: "Corvo Arpt",
    airport_city_code: "CVU",
    airport_city_name: "Corvo Island",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "39.6715",
    airport_lon: "-31.1136",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 779,
    airport_code: "CWB",
    airport_name: "Afonso Pena Arpt",
    airport_city_code: "CWB",
    airport_city_name: "Curitiba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-25.528475",
    airport_lon: "-49.175775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 780,
    airport_code: "CWC",
    airport_name: "Chernovtsy Arpt",
    airport_city_code: "CWC",
    airport_city_name: "Chernovtsy",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.259322",
    airport_lon: "25.980831",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 781,
    airport_code: "CWI",
    airport_name: "Clinton Municipal",
    airport_city_code: "CWI",
    airport_city_name: "Clinton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 782,
    airport_code: "CWL",
    airport_name: "Cardiff Wales Arpt",
    airport_city_code: "CWL",
    airport_city_name: "Cardiff",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.396667",
    airport_lon: "-3.343333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 783,
    airport_code: "CWT",
    airport_name: "Cowra Arpt",
    airport_city_code: "CWT",
    airport_city_name: "Cowra",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-33.8467",
    airport_lon: "148.6494",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 784,
    airport_code: "CXI",
    airport_name: "Cassidy",
    airport_city_code: "CXI",
    airport_city_name: "Christmas Island",
    airport_country_name: "KIRIBATI",
    airport_country_code: "KI",
    airport_timezone: "-12",
    airport_lat: "1.986161",
    airport_lon: "-157.349778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 785,
    airport_code: "CXJ",
    airport_name: "Campo Dos Bugres Arpt",
    airport_city_code: "CXJ",
    airport_city_name: "Caxias Do Sul",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-29.197064",
    airport_lon: "-51.187536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 786,
    airport_code: "CXL",
    airport_name: "Calexico Intl Arpt",
    airport_city_code: "CXL",
    airport_city_name: "Calexico",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "32.669502",
    airport_lon: "-115.51333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 787,
    airport_code: "CXO",
    airport_name: "Montgomery Co Arpt",
    airport_city_code: "CXO",
    airport_city_name: "Conroe",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.351833",
    airport_lon: "-95.414467",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 788,
    airport_code: "CXP",
    airport_name: "Tunggul Wulung Arpt",
    airport_city_code: "CXP",
    airport_city_name: "Cilacap",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-7.645056",
    airport_lon: "109.033911",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 789,
    airport_code: "CXR",
    airport_name: "Cam Ranh Arpt",
    airport_city_code: "CXR",
    airport_city_name: "Cam Ranh",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "11.998153",
    airport_lon: "109.219372",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 790,
    airport_code: "CYB",
    airport_name: "Gerrard Smith Arpt",
    airport_city_code: "CYB",
    airport_city_name: "Cayman Brac",
    airport_country_name: "CAYMAN ISLANDS",
    airport_country_code: "KY",
    airport_timezone: "-5",
    airport_lat: "19.686981",
    airport_lon: "-79.882789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 791,
    airport_code: "CYO",
    airport_name: "Cayo Largo Del Sur Arpt",
    airport_city_code: "CYO",
    airport_city_name: "Cayo Largo Del Sur",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "21.616453",
    airport_lon: "-81.545989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 792,
    airport_code: "CYR",
    airport_name: "Colonia Arpt",
    airport_city_code: "CYR",
    airport_city_name: "Colonia",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-4",
    airport_lat: "-34.4564",
    airport_lon: "-57.7706",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 793,
    airport_code: "CYS",
    airport_name: "Cheyenne Arpt",
    airport_city_code: "CYS",
    airport_city_name: "Cheyenne",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "41.155722",
    airport_lon: "-104.811839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 794,
    airport_code: "CZA",
    airport_name: "Chichen Itza Arpt",
    airport_city_code: "CZA",
    airport_city_name: "Chichen Itza",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "20.6413",
    airport_lon: "-88.4462",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 795,
    airport_code: "CZE",
    airport_name: "Coro Arpt",
    airport_city_code: "CZE",
    airport_city_name: "Coro",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "11.414867",
    airport_lon: "-69.681656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 796,
    airport_code: "CZL",
    airport_name: "Ain El Bey Arpt",
    airport_city_code: "CZL",
    airport_city_name: "Constantine",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "36.276028",
    airport_lon: "6.620386",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 797,
    airport_code: "CZM",
    airport_name: "Aeropuerto Intl De Cozumel",
    airport_city_code: "CZM",
    airport_city_name: "Cozumel",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.522403",
    airport_lon: "-86.925644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 798,
    airport_code: "CZS",
    airport_name: "Campo Intl Arpt",
    airport_city_code: "CZS",
    airport_city_name: "Cruzeiro Do Sul",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-5",
    airport_lat: "-7.599906",
    airport_lon: "-72.769489",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 799,
    airport_code: "CZX",
    airport_name: "Changzhou Arpt",
    airport_city_code: "CZX",
    airport_city_name: "Changzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.941667",
    airport_lon: "119.711667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 800,
    airport_code: "DAB",
    airport_name: "Daytona Beach Regional Arpt",
    airport_city_code: "DAB",
    airport_city_name: "Daytona Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "29.179917",
    airport_lon: "-81.058056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 801,
    airport_code: "DAC",
    airport_name: "Shahjalal International Airport",
    airport_city_code: "DAC",
    airport_city_name: "Dhaka",
    airport_country_name: "BANGLADESH",
    airport_country_code: "BD",
    airport_timezone: "6",
    airport_lat: "23.843333",
    airport_lon: "90.397781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 802,
    airport_code: "DAD",
    airport_name: "Da Nang Arpt",
    airport_city_code: "DAD",
    airport_city_name: "Da Nang",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "16.043917",
    airport_lon: "108.19937",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 803,
    airport_code: "DAK",
    airport_name: "Dakhla Arpt",
    airport_city_code: "DAK",
    airport_city_name: "Dakhla Oasis",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "-100",
    airport_lat: "25.4117",
    airport_lon: "29.0017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 804,
    airport_code: "DAM",
    airport_name: "Damascus Intl",
    airport_city_code: "DAM",
    airport_city_name: "Damascus",
    airport_country_name: "SYRIA",
    airport_country_code: "SY",
    airport_timezone: "2",
    airport_lat: "33.410636",
    airport_lon: "36.514267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 805,
    airport_code: "DAN",
    airport_name: "Danville Municipal",
    airport_city_code: "DAN",
    airport_city_name: "Danville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "36.5729",
    airport_lon: "-79.3361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 806,
    airport_code: "DAR",
    airport_name: "Es Salaam Intl",
    airport_city_code: "DAR",
    airport_city_name: "Dar Es Salaam",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-6.878111",
    airport_lon: "39.202625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 807,
    airport_code: "DAT",
    airport_name: "Datong Arpt",
    airport_city_code: "DAT",
    airport_city_name: "Datong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "40.0603",
    airport_lon: "113.482",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 808,
    airport_code: "DAV",
    airport_name: "Enrique Malek Arpt",
    airport_city_code: "DAV",
    airport_city_name: "David",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "8.391003",
    airport_lon: "-82.434992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 809,
    airport_code: "DAY",
    airport_name: "Dayton International Airport",
    airport_city_code: "DAY",
    airport_city_name: "Dayton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.902375",
    airport_lon: "-84.219375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 810,
    airport_code: "DBN",
    airport_name: "Dublin Municipal Arpt",
    airport_city_code: "DBN",
    airport_city_name: "Dublin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 811,
    airport_code: "DBO",
    airport_name: "Dubbo Arpt",
    airport_city_code: "DBO",
    airport_city_name: "Dubbo",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-32.216667",
    airport_lon: "148.574722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 812,
    airport_code: "DBQ",
    airport_name: "Dubuque Municipal Arpt",
    airport_city_code: "DBQ",
    airport_city_name: "Dubuque",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.402",
    airport_lon: "-90.709472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 813,
    airport_code: "DBV",
    airport_name: "Dubrovnik Arpt",
    airport_city_code: "DBV",
    airport_city_name: "Dubrovnik",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "42.561353",
    airport_lon: "18.268244",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 814,
    airport_code: "DBY",
    airport_name: "Dalby",
    airport_city_code: "DBY",
    airport_city_name: "Dalby",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-27.155",
    airport_lon: "151.2667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 815,
    airport_code: "DCM",
    airport_name: "Mazamet Arpt",
    airport_city_code: "DCM",
    airport_city_name: "Castres",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.55625",
    airport_lon: "2.289183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 816,
    airport_code: "DCU",
    airport_name: "Pyor Arpt",
    airport_city_code: "DCU",
    airport_city_name: "Decatur",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 817,
    airport_code: "DDC",
    airport_name: "Dodge City Municipal Arpt",
    airport_city_code: "DDC",
    airport_city_name: "Dodge City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.7634",
    airport_lon: "-99.9656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 818,
    airport_code: "DDG",
    airport_name: "Dandong Arpt",
    airport_city_code: "DDG",
    airport_city_name: "Dandong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "40.0255",
    airport_lon: "124.2866",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 819,
    airport_code: "DDI",
    airport_name: "Daydream Island Arpt",
    airport_city_code: "DDI",
    airport_city_name: "Daydream Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 820,
    airport_code: "DEB",
    airport_name: "Debrecen Arpt",
    airport_city_code: "DEB",
    airport_city_name: "Debrecen",
    airport_country_name: "HUNGARY",
    airport_country_code: "HU",
    airport_timezone: "1",
    airport_lat: "47.488917",
    airport_lon: "21.615333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 821,
    airport_code: "DEC",
    airport_name: "Decatur Municipal",
    airport_city_code: "DEC",
    airport_city_name: "Decatur",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.834564",
    airport_lon: "-88.865689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 822,
    airport_code: "DED",
    airport_name: "Jolly Grant Airport",
    airport_city_code: "DED",
    airport_city_name: "Dehra Dun",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "30.189689",
    airport_lon: "78.180256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 823,
    airport_code: "DEH",
    airport_name: "Decorah Municipal",
    airport_city_code: "DEH",
    airport_city_name: "Decorah",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 824,
    airport_code: "DEL",
    airport_name: "Delhi Indira Gandhi Intl",
    airport_city_code: "DEL",
    airport_city_name: "Delhi",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "28.5665",
    airport_lon: "77.103088",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 825,
    airport_code: "APA",
    airport_name: "Centennial Arpt",
    airport_city_code: "DEN",
    airport_city_name: "Denver",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "39.570129",
    airport_lon: "-104.849294",
    airport_num_airports: 3,
  },
  {
    airport_id: 826,
    airport_code: "DEN",
    airport_name: "Denver Intl Arpt",
    airport_city_code: "DEN",
    airport_city_name: "Denver",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "39.861656",
    airport_lon: "-104.673178",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 827,
    airport_code: "QWM",
    airport_name: "Longmont Bus Service",
    airport_city_code: "DEN",
    airport_city_name: "Denver",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 828,
    airport_code: "DEZ",
    airport_name: "Al Jafrah Arpt",
    airport_city_code: "DEZ",
    airport_city_name: "Deirezzor",
    airport_country_name: "SYRIA",
    airport_country_code: "SY",
    airport_timezone: "2",
    airport_lat: "35.285374",
    airport_lon: "40.175961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 829,
    airport_code: "DFI",
    airport_name: "Defiance Memorial Arpt",
    airport_city_code: "DFI",
    airport_city_name: "Defiance",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 830,
    airport_code: "AFW",
    airport_name: "Fort Worth Alliance Arpt",
    airport_city_code: "DFW",
    airport_city_name: "Dallas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.987778",
    airport_lon: "-97.318889",
    airport_num_airports: 4,
  },
  {
    airport_id: 831,
    airport_code: "DFW",
    airport_name: "Dallas Ft Worth Intl",
    airport_city_code: "DFW",
    airport_city_name: "Dallas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.896828",
    airport_lon: "-97.037997",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 832,
    airport_code: "ADS",
    airport_name: "Addison Arpt",
    airport_city_code: "DFW",
    airport_city_name: "Dallas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.968559",
    airport_lon: "-96.836448",
    airport_num_airports: 4,
  },
  {
    airport_id: 833,
    airport_code: "DAL",
    airport_name: "Love Field",
    airport_city_code: "DFW",
    airport_city_name: "Dallas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.847111",
    airport_lon: "-96.851778",
    airport_num_airports: 4,
  },
  {
    airport_id: 834,
    airport_code: "DGE",
    airport_name: "Mudgee Arpt",
    airport_city_code: "DGE",
    airport_city_name: "Mudgee",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-32.5625",
    airport_lon: "149.611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 835,
    airport_code: "DGM",
    airport_name: "Dongguan Arpt",
    airport_city_code: "DGM",
    airport_city_name: "Dongguan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 836,
    airport_code: "DGO",
    airport_name: "Guadalupe Victoria Arpt",
    airport_city_code: "DGO",
    airport_city_name: "Durango",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "24.124194",
    airport_lon: "-104.528014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 837,
    airport_code: "DGP",
    airport_name: "Daugavpils Arpt",
    airport_city_code: "DGP",
    airport_city_name: "Daugavpils",
    airport_country_name: "LATVIA",
    airport_country_code: "LV",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 838,
    airport_code: "DGT",
    airport_name: "Dumaguete Arpt",
    airport_city_code: "DGT",
    airport_city_name: "Dumaguete",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "9.333714",
    airport_lon: "123.300472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 839,
    airport_code: "DHA",
    airport_name: "Dhahran Intl",
    airport_city_code: "DHA",
    airport_city_name: "Dhahran",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "26.265417",
    airport_lon: "50.152027",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 840,
    airport_code: "DHM",
    airport_name: "Gaggal Arpt",
    airport_city_code: "DHM",
    airport_city_name: "Dharamsala",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "32.1651",
    airport_lon: "76.2634",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 841,
    airport_code: "DHN",
    airport_name: "Dothan Municipal",
    airport_city_code: "DHN",
    airport_city_name: "Dothan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.321339",
    airport_lon: "-85.449628",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 842,
    airport_code: "DIB",
    airport_name: "Mohanbari Arpt",
    airport_city_code: "DIB",
    airport_city_name: "Dibrugarh",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "27.4839",
    airport_lon: "95.0169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 843,
    airport_code: "DIE",
    airport_name: "Antsiranana Arrachart Arpt",
    airport_city_code: "DIE",
    airport_city_name: "Diegosuarez",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-12.3494",
    airport_lon: "49.291747",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 844,
    airport_code: "DIG",
    airport_name: "Diging Arpt",
    airport_city_code: "DIG",
    airport_city_name: "Diqing",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "27.7936",
    airport_lon: "99.6772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 845,
    airport_code: "DIJ",
    airport_name: "Longvic Airport",
    airport_city_code: "DIJ",
    airport_city_name: "Dijon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.26889",
    airport_lon: "5.09",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 846,
    airport_code: "DIK",
    airport_name: "Dickinson Municipal",
    airport_city_code: "DIK",
    airport_city_name: "Dickinson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "46.7975",
    airport_lon: "-102.801944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 847,
    airport_code: "DIL",
    airport_name: "Comoro Arpt",
    airport_city_code: "DIL",
    airport_city_name: "Dili",
    airport_country_name: "TIMOR-LESTE",
    airport_country_code: "TL",
    airport_timezone: "8",
    airport_lat: "-8.546553",
    airport_lon: "125.524719",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 848,
    airport_code: "DIN",
    airport_name: "Gialam Arpt",
    airport_city_code: "DIN",
    airport_city_name: "Dien Bien Phu",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "21.397481",
    airport_lon: "103.007831",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 849,
    airport_code: "DIR",
    airport_name: "Aba Tenna D Yilma Arpt",
    airport_city_code: "DIR",
    airport_city_name: "Dire Dawa",
    airport_country_name: "ETHIOPIA",
    airport_country_code: "ET",
    airport_timezone: "3",
    airport_lat: "9.6247",
    airport_lon: "41.854203",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 850,
    airport_code: "DIU",
    airport_name: "Diu Arpt",
    airport_city_code: "DIU",
    airport_city_name: "Diu In",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "20.7131",
    airport_lon: "70.9211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 851,
    airport_code: "DIY",
    airport_name: "Diyarbakir Arpt",
    airport_city_code: "DIY",
    airport_city_name: "Diyarbak?r",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.893897",
    airport_lon: "40.201019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 852,
    airport_code: "DJB",
    airport_name: "Sultan Taha Syarifudin Arpt",
    airport_city_code: "DJB",
    airport_city_name: "Jambi",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-1.638017",
    airport_lon: "103.644378",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 853,
    airport_code: "DJE",
    airport_name: "Melita Airport",
    airport_city_code: "DJE",
    airport_city_name: "Djerba",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "33.875031",
    airport_lon: "10.775461",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 854,
    airport_code: "DJJ",
    airport_name: "Sentani Arpt",
    airport_city_code: "DJJ",
    airport_city_name: "Jayapura",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-2.576953",
    airport_lon: "140.516372",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 855,
    airport_code: "DKI",
    airport_name: "Dunk Island Arpt",
    airport_city_code: "DKI",
    airport_city_name: "Dunk Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-17.939722",
    airport_lon: "146.141944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 856,
    airport_code: "DKR",
    airport_name: "Leopold Sedar Senghor Arpt",
    airport_city_code: "DKR",
    airport_city_name: "Dakar",
    airport_country_name: "SENEGAL",
    airport_country_code: "SN",
    airport_timezone: "0",
    airport_lat: "14.739708",
    airport_lon: "-17.490225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 857,
    airport_code: "DKS",
    airport_name: "Dikson Airport",
    airport_city_code: "DKS",
    airport_city_name: "Dikson Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 858,
    airport_code: "DLA",
    airport_name: "Douala Arpt",
    airport_city_code: "DLA",
    airport_city_name: "Douala",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "1",
    airport_lat: "4.006081",
    airport_lon: "9.719481",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 859,
    airport_code: "DLC",
    airport_name: "Dalian Arpt",
    airport_city_code: "DLC",
    airport_city_name: "Dalian",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "38.965667",
    airport_lon: "121.5386",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 860,
    airport_code: "DLD",
    airport_name: "Dagali Arpt",
    airport_city_code: "DLD",
    airport_city_name: "Geilo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 861,
    airport_code: "DLE",
    airport_name: "Tavaux Arpt",
    airport_city_code: "DLE",
    airport_city_name: "Dole",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.039014",
    airport_lon: "5.42725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 862,
    airport_code: "DLG",
    airport_name: "Dillingham Municipal Arpt",
    airport_city_code: "DLG",
    airport_city_name: "Dillingham",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.044667",
    airport_lon: "-158.5055",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 863,
    airport_code: "DLH",
    airport_name: "Duluth Intl",
    airport_city_code: "DLH",
    airport_city_name: "Duluth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "46.842091",
    airport_lon: "-92.193649",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 864,
    airport_code: "DLI",
    airport_name: "Lienkhang",
    airport_city_code: "DLI",
    airport_city_name: "Dalat",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "11.75",
    airport_lon: "108.367",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 865,
    airport_code: "DLL",
    airport_name: "Dillon Arpt",
    airport_city_code: "DLL",
    airport_city_name: "Dillon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 866,
    airport_code: "DLM",
    airport_name: "Dalaman Airport",
    airport_city_code: "DLM",
    airport_city_name: "Dalaman",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "36.713056",
    airport_lon: "28.7925",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 867,
    airport_code: "DLS",
    airport_name: "The Dalles Municipal Arpt",
    airport_city_code: "DLS",
    airport_city_name: "The Dalles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "45.6185",
    airport_lon: "-121.1673",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 868,
    airport_code: "DLU",
    airport_name: "Dali Arpt",
    airport_city_code: "DLU",
    airport_city_name: "Dali City",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "25.649444",
    airport_lon: "100.319444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 869,
    airport_code: "DMB",
    airport_name: "ZHAMBYL",
    airport_city_code: "DMB",
    airport_city_name: "ZHAMBYL",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "42.853611",
    airport_lon: "71.303611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 870,
    airport_code: "DMD",
    airport_name: "Doomadgee Arpt",
    airport_city_code: "DMD",
    airport_city_name: "Doomadgee",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-17.9403",
    airport_lon: "138.822",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 871,
    airport_code: "DMM",
    airport_name: "King Fahad Arpt",
    airport_city_code: "DMM",
    airport_city_name: "Dammam",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "26.471161",
    airport_lon: "49.79789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 872,
    airport_code: "DMO",
    airport_name: "Sedalia Memorial Arpt",
    airport_city_code: "DMO",
    airport_city_name: "Sedalia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 873,
    airport_code: "ZDU",
    airport_name: "Dundee ScotRail",
    airport_city_code: "DND",
    airport_city_name: "Dundee",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 874,
    airport_code: "DND",
    airport_name: "Riverside Park Arpt",
    airport_city_code: "DND",
    airport_city_name: "Dundee",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "56.452499",
    airport_lon: "-3.025833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 875,
    airport_code: "DNH",
    airport_name: "Dunhuang Arpt",
    airport_city_code: "DNH",
    airport_city_name: "Dunhuang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "40.094",
    airport_lon: "94.4818",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 876,
    airport_code: "DNK",
    airport_name: "Dnepropetrovsk Arpt",
    airport_city_code: "DNK",
    airport_city_name: "Dnepropetrovsk",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.357222",
    airport_lon: "35.100556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 877,
    airport_code: "DNM",
    airport_name: "Denham Arpt",
    airport_city_code: "DNM",
    airport_city_name: "Denham",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-25.8939",
    airport_lon: "113.577",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 878,
    airport_code: "DNQ",
    airport_name: "Denilinquin Arpt",
    airport_city_code: "DNQ",
    airport_city_name: "Deniliquin",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-35.5597",
    airport_lon: "144.9472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 879,
    airport_code: "DNR",
    airport_name: "Pleurtuit Arpt",
    airport_city_code: "DNR",
    airport_city_name: "Dinard",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.587683",
    airport_lon: "-2.079958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 880,
    airport_code: "DNV",
    airport_name: "Vermillion Cty",
    airport_city_code: "DNV",
    airport_city_name: "Danville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.1992",
    airport_lon: "-87.5959",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 881,
    airport_code: "DNZ",
    airport_name: "Cardak Arpt",
    airport_city_code: "DNZ",
    airport_city_name: "Denizli",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.785567",
    airport_lon: "29.701297",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 882,
    airport_code: "DOC",
    airport_name: "Dornoch Arpt",
    airport_city_code: "DOC",
    airport_city_name: "Dornoch",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 883,
    airport_code: "DOH",
    airport_name: "Doha International Arpt",
    airport_city_code: "DOH",
    airport_city_name: "Doha",
    airport_country_name: "QATAR",
    airport_country_code: "QA",
    airport_timezone: "4",
    airport_lat: "25.261125",
    airport_lon: "51.565056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 884,
    airport_code: "DOK",
    airport_name: "Donetsk Arpt",
    airport_city_code: "DOK",
    airport_city_name: "Donetsk",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.073611",
    airport_lon: "37.739722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 885,
    airport_code: "DOL",
    airport_name: "Saint Gatien Arpt",
    airport_city_code: "DOL",
    airport_city_name: "Deauville",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.365339",
    airport_lon: "0.154306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 886,
    airport_code: "DCF",
    airport_name: "Canefield Arpt",
    airport_city_code: "DOM",
    airport_city_name: "Dominica",
    airport_country_name: "DOMINICA",
    airport_country_code: "DM",
    airport_timezone: "-4",
    airport_lat: "15.336719",
    airport_lon: "-61.392211",
    airport_num_airports: 2,
  },
  {
    airport_id: 887,
    airport_code: "DOM",
    airport_name: "Melville Hall Arpt",
    airport_city_code: "DOM",
    airport_city_name: "Dominica",
    airport_country_name: "DOMINICA",
    airport_country_code: "DM",
    airport_timezone: "-4",
    airport_lat: "15.547028",
    airport_lon: "-61.3",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 888,
    airport_code: "DVX",
    airport_name: "Delaware Airpark",
    airport_city_code: "DOV",
    airport_city_name: "Dover",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 889,
    airport_code: "DOV",
    airport_name: "Dover AFB",
    airport_city_code: "DOV",
    airport_city_name: "Dover",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.129539",
    airport_lon: "-75.465958",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 890,
    airport_code: "DOX",
    airport_name: "Dongara",
    airport_city_code: "DOX",
    airport_city_name: "Dongara",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 891,
    airport_code: "DPE",
    airport_name: "Dieppe Arpt",
    airport_city_code: "DPE",
    airport_city_name: "Dieppe",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 892,
    airport_code: "DPL",
    airport_name: "Dipolog Arpt",
    airport_city_code: "DPL",
    airport_city_name: "Dipolog",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "8.601261",
    airport_lon: "123.334481",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 893,
    airport_code: "DPO",
    airport_name: "Devonport Arpt",
    airport_city_code: "DPO",
    airport_city_name: "Devonport",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-41.1697",
    airport_lon: "146.43",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 894,
    airport_code: "DPS",
    airport_name: "Ngurah Rai Arpt",
    airport_city_code: "DPS",
    airport_city_name: "Denpasar Bali",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "-8.748169",
    airport_lon: "115.167172",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 895,
    airport_code: "DRB",
    airport_name: "Derby Airport",
    airport_city_code: "DRB",
    airport_city_name: "Derby",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-17.39",
    airport_lon: "123.68",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 896,
    airport_code: "DRK",
    airport_name: "Drake Bay Arpt",
    airport_city_code: "DRK",
    airport_city_name: "Drake Bay Arpt",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "8.71889",
    airport_lon: "-83.6417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 897,
    airport_code: "DRO",
    airport_name: "Durango La Plata Cty Arpt",
    airport_city_code: "DRO",
    airport_city_name: "Durango",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.151516",
    airport_lon: "-107.75377",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 898,
    airport_code: "AMK",
    airport_name: "Animas Airpark",
    airport_city_code: "DRO",
    airport_city_name: "Durango",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 899,
    airport_code: "DRS",
    airport_name: "Dresden Arpt",
    airport_city_code: "DRS",
    airport_city_name: "Dresden",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.132767",
    airport_lon: "13.767161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 900,
    airport_code: "DRT",
    airport_name: "International Del Rio",
    airport_city_code: "DRT",
    airport_city_name: "Del Rio",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.374208",
    airport_lon: "-100.927158",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 901,
    airport_code: "DRW",
    airport_name: "Darwin Airport",
    airport_city_code: "DRW",
    airport_city_name: "Darwin",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-12.408333",
    airport_lon: "130.87266",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 902,
    airport_code: "DSA",
    airport_name: "Finningley Arpt",
    airport_city_code: "DSA",
    airport_city_name: "Doncaster",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.474722",
    airport_lon: "-1.004444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 903,
    airport_code: "DSD",
    airport_name: "La Desirade Arpt",
    airport_city_code: "DSD",
    airport_city_name: "La Desirade",
    airport_country_name: "GUADELOUPE",
    airport_country_code: "GP",
    airport_timezone: "-4",
    airport_lat: "16.2969",
    airport_lon: "-61.0844",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 904,
    airport_code: "DSI",
    airport_name: "Destin Arpt",
    airport_city_code: "DSI",
    airport_city_name: "Destin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 905,
    airport_code: "DSM",
    airport_name: "Des Moines Municipal Airport",
    airport_city_code: "DSM",
    airport_city_name: "Des Moines",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.533972",
    airport_lon: "-93.663083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 906,
    airport_code: "DTM",
    airport_name: "Wickede Dortmund Arpt",
    airport_city_code: "DTM",
    airport_city_name: "Dortmund",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.518314",
    airport_lon: "7.612242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 907,
    airport_code: "UIZ",
    airport_name: "Berz Macomb Arpt",
    airport_city_code: "DTT",
    airport_city_name: "Detroit",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 908,
    airport_code: "YIP",
    airport_name: "Willow Run Arpt",
    airport_city_code: "DTT",
    airport_city_name: "Detroit",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.237928",
    airport_lon: "-83.530408",
    airport_num_airports: 5,
  },
  {
    airport_id: 909,
    airport_code: "MTC",
    airport_name: "Selfridge Air Natl Guard",
    airport_city_code: "DTT",
    airport_city_name: "Detroit",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.608333",
    airport_lon: "-82.8355",
    airport_num_airports: 5,
  },
  {
    airport_id: 910,
    airport_code: "DET",
    airport_name: "Detroit City Apt",
    airport_city_code: "DTT",
    airport_city_name: "Detroit",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.409195",
    airport_lon: "-83.009861",
    airport_num_airports: 5,
  },
  {
    airport_id: 911,
    airport_code: "DTW",
    airport_name: "Detroit Metro Wayne Cnty Arpt",
    airport_city_code: "DTT",
    airport_city_name: "Detroit",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.212444",
    airport_lon: "-83.353389",
    airport_num_airports: 5,
  },
  {
    airport_id: 912,
    airport_code: "DUB",
    airport_name: "Dublin Arpt",
    airport_city_code: "DUB",
    airport_city_name: "Dublin",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "53.421333",
    airport_lon: "-6.270075",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 913,
    airport_code: "DUC",
    airport_name: "Halliburton Arpt",
    airport_city_code: "DUC",
    airport_city_name: "Duncan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.471306",
    airport_lon: "-97.959861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 914,
    airport_code: "DUD",
    airport_name: "Momona Airport",
    airport_city_code: "DUD",
    airport_city_name: "Dunedin",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-45.928055",
    airport_lon: "170.198333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 915,
    airport_code: "DUG",
    airport_name: "Bisbee Douglas Intl",
    airport_city_code: "DUG",
    airport_city_name: "Douglas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "31.469028",
    airport_lon: "-109.603667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 916,
    airport_code: "DUJ",
    airport_name: "Dubois Jefferson Cty Arpt",
    airport_city_code: "DUJ",
    airport_city_name: "Dubois",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.1783",
    airport_lon: "-78.8987",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 917,
    airport_code: "DUQ",
    airport_name: "Duncan/Quam Rail Station",
    airport_city_code: "DUQ",
    airport_city_name: "Duncan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 918,
    airport_code: "DUR",
    airport_name: "Durban International",
    airport_city_code: "DUR",
    airport_city_name: "Durban",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-29.970089",
    airport_lon: "30.950519",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 919,
    airport_code: "VIR",
    airport_name: "Virginia Arpt",
    airport_city_code: "DUR",
    airport_city_name: "Durban",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-29.770606",
    airport_lon: "31.058406",
    airport_num_airports: 2,
  },
  {
    airport_id: 920,
    airport_code: "NRN",
    airport_name: "Niederrhein Arpt",
    airport_city_code: "DUS",
    airport_city_name: "Dusseldorf",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.602222",
    airport_lon: "6.141944",
    airport_num_airports: 4,
  },
  {
    airport_id: 921,
    airport_code: "QDU",
    airport_name: "Dusseldorf Rail Station",
    airport_city_code: "DUS",
    airport_city_name: "Dusseldorf",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.220278",
    airport_lon: "6.792778",
    airport_num_airports: 4,
  },
  {
    airport_id: 922,
    airport_code: "DUS",
    airport_name: "Dusseldorf Arpt",
    airport_city_code: "DUS",
    airport_city_name: "Dusseldorf",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.289453",
    airport_lon: "6.766775",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 923,
    airport_code: "MGL",
    airport_name: "Moenchen Gi Dus Exp",
    airport_city_code: "DUS",
    airport_city_name: "Dusseldorf",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.230356",
    airport_lon: "6.504494",
    airport_num_airports: 4,
  },
  {
    airport_id: 924,
    airport_code: "DUT",
    airport_name: "Emergency Field",
    airport_city_code: "DUT",
    airport_city_name: "Dutch Harbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "53.900139",
    airport_lon: "-166.5435",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 925,
    airport_code: "DVL",
    airport_name: "Devils Lake Arpt",
    airport_city_code: "DVL",
    airport_city_name: "Devils Lake",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "48.114444",
    airport_lon: "-98.908611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 926,
    airport_code: "DVN",
    airport_name: "Davenport Airport",
    airport_city_code: "DVN",
    airport_city_name: "Davenport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 927,
    airport_code: "DVO",
    airport_name: "Mati Airport",
    airport_city_code: "DVO",
    airport_city_name: "Davao",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "7.125522",
    airport_lon: "125.645778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 928,
    airport_code: "DWD",
    airport_name: "Dawadmi Airport",
    airport_city_code: "DWD",
    airport_city_name: "Dawadmi Airport",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "24.5",
    airport_lon: "44.4",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 929,
    airport_code: "SHJ",
    airport_name: "Sharjah Airport",
    airport_city_code: "DXB",
    airport_city_name: "Dubai",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "4",
    airport_lat: "25.328575",
    airport_lon: "55.51715",
    airport_num_airports: 3,
  },
  {
    airport_id: 930,
    airport_code: "XNB",
    airport_name: "Dubai Bus Station Arpt",
    airport_city_code: "DXB",
    airport_city_name: "Dubai",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 931,
    airport_code: "DXB",
    airport_name: "Dubai Intl Arpt",
    airport_city_code: "DXB",
    airport_city_name: "Dubai",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "4",
    airport_lat: "25.252778",
    airport_lon: "55.364444",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 932,
    airport_code: "DXR",
    airport_name: "Danbury Municipal Arpt",
    airport_city_code: "DXR",
    airport_city_name: "Danbury",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "41.371535",
    airport_lon: "-73.482191",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 933,
    airport_code: "DYG",
    airport_name: "Dayong Arpt",
    airport_city_code: "DYG",
    airport_city_name: "Dayong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.1028",
    airport_lon: "110.443",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 934,
    airport_code: "DYL",
    airport_name: "Doylestown Arpt",
    airport_city_code: "DYL",
    airport_city_name: "Doylestown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 935,
    airport_code: "DYR",
    airport_name: "Anadyr Arpt",
    airport_city_code: "DYR",
    airport_city_name: "Anadyr",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "12",
    airport_lat: "64.73495",
    airport_lon: "177.741483",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 936,
    airport_code: "DYU",
    airport_name: "Dushanbe Arpt",
    airport_city_code: "DYU",
    airport_city_name: "Dushanbe",
    airport_country_name: "TAJIKISTAN",
    airport_country_code: "TJ",
    airport_timezone: "5",
    airport_lat: "38.543333",
    airport_lon: "68.825",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 937,
    airport_code: "DZA",
    airport_name: "Dzaoudzi Arpt",
    airport_city_code: "DZA",
    airport_city_name: "Dzaoudzi",
    airport_country_name: "MAYOTTE",
    airport_country_code: "YT",
    airport_timezone: "3",
    airport_lat: "-12.804722",
    airport_lon: "45.281113",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 938,
    airport_code: "DZN",
    airport_name: "ZHEZKAZGAN",
    airport_city_code: "DZN",
    airport_city_name: "ZHEZKAZGAN",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "47.708333",
    airport_lon: "67.733333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 939,
    airport_code: "EAA",
    airport_name: "Eagle Airport",
    airport_city_code: "EAA",
    airport_city_name: "Eagle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "64.778056",
    airport_lon: "-141.149722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 940,
    airport_code: "EAM",
    airport_name: "Nejran Arpt",
    airport_city_code: "EAM",
    airport_city_name: "Nejran",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "17.611436",
    airport_lon: "44.419169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 941,
    airport_code: "MHL",
    airport_name: "Mulhouse",
    airport_city_code: "EAP",
    airport_city_name: "Mulhouse Basel",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 942,
    airport_code: "MLH",
    airport_name: "Mulhouse Euroairport French",
    airport_city_code: "EAP",
    airport_city_name: "Mulhouse Basel",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.589583",
    airport_lon: "7.529914",
    airport_num_airports: 4,
  },
  {
    airport_id: 943,
    airport_code: "ZBA",
    airport_name: "Basel/Mullhouse Rail Service",
    airport_city_code: "EAP",
    airport_city_name: "Mulhouse Basel",
    airport_country_name: "FRANCE",
    airport_country_code: "CH",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 944,
    airport_code: "ZDH",
    airport_name: "Basel/Mullhouse SBB Rail Service",
    airport_city_code: "EAP",
    airport_city_name: "Mulhouse Basel",
    airport_country_name: "FRANCE",
    airport_country_code: "CH",
    airport_timezone: "-100",
    airport_lat: "47.6",
    airport_lon: "7.52",
    airport_num_airports: 4,
  },
  {
    airport_id: 945,
    airport_code: "EAR",
    airport_name: "Kearney Municipal Arrpt",
    airport_city_code: "EAR",
    airport_city_name: "Kearney",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.727",
    airport_lon: "-99.0068",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 946,
    airport_code: "EAS",
    airport_name: "San Sebastian Arpt",
    airport_city_code: "EAS",
    airport_city_name: "San Sebastian",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "43.356519",
    airport_lon: "-1.790611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 947,
    airport_code: "EAT",
    airport_name: "Pangborn Memorial Fld",
    airport_city_code: "EAT",
    airport_city_name: "Wenatchee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.398",
    airport_lon: "-120.206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 948,
    airport_code: "EAU",
    airport_name: "Claire Municipal Airport",
    airport_city_code: "EAU",
    airport_city_name: "Eau Claire",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.8658",
    airport_lon: "-91.4843",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 949,
    airport_code: "EBA",
    airport_name: "Marina Di Campo Arpt",
    airport_city_code: "EBA",
    airport_city_name: "Elba Island",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "42.760277",
    airport_lon: "10.239445",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 950,
    airport_code: "EBB",
    airport_name: "Entebbe Airport",
    airport_city_code: "EBB",
    airport_city_name: "Entebbe",
    airport_country_name: "UGANDA",
    airport_country_code: "UG",
    airport_timezone: "3",
    airport_lat: "0.042386",
    airport_lon: "32.443503",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 951,
    airport_code: "EBJ",
    airport_name: "Esbjerg Arpt",
    airport_city_code: "EBJ",
    airport_city_name: "Esbjerg",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.525942",
    airport_lon: "8.553403",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 952,
    airport_code: "EBL",
    airport_name: "Erbil Arpt",
    airport_city_code: "EBL",
    airport_city_name: "Erbil",
    airport_country_name: "IRAQ",
    airport_country_code: "IQ",
    airport_timezone: "3",
    airport_lat: "36.237611",
    airport_lon: "43.963158",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 953,
    airport_code: "EBO",
    airport_name: "Ebon Arpt",
    airport_city_code: "EBO",
    airport_city_name: "Ebon",
    airport_country_name: "MARSHALL ISLANDS",
    airport_country_code: "MH",
    airport_timezone: "1",
    airport_lat: "51.478506",
    airport_lon: "7.222781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 954,
    airport_code: "EBU",
    airport_name: "Boutheon Arpt",
    airport_city_code: "EBU",
    airport_city_name: "St Etienne",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.540554",
    airport_lon: "4.296389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 955,
    airport_code: "ECG",
    airport_name: "Elizabeth Municipal Cgas",
    airport_city_code: "ECG",
    airport_city_name: "Elizabeth City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "36.260581",
    airport_lon: "-76.174572",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 956,
    airport_code: "ECH",
    airport_name: "Echuca Arpt",
    airport_city_code: "ECH",
    airport_city_name: "Echuca",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-36.1567",
    airport_lon: "144.7617",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 957,
    airport_code: "ECN",
    airport_name: "Ercan Airport",
    airport_city_code: "ECN",
    airport_city_name: "Ercan",
    airport_country_name: "CYPRUS",
    airport_country_code: "CY",
    airport_timezone: "2",
    airport_lat: "35.1547",
    airport_lon: "33.4961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 958,
    airport_code: "EDE",
    airport_name: "Edenton Municipal Arpt",
    airport_city_code: "EDE",
    airport_city_name: "Edenton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 959,
    airport_code: "ZXE",
    airport_name: "Edinburgh ScotRail",
    airport_city_code: "EDI",
    airport_city_name: "Edinburgh",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 960,
    airport_code: "EDI",
    airport_name: "Edinburgh Arpt",
    airport_city_code: "EDI",
    airport_city_name: "Edinburgh",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.95",
    airport_lon: "-3.3725",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 961,
    airport_code: "EDL",
    airport_name: "Eldoret Arpt",
    airport_city_code: "EDL",
    airport_city_name: "Eldoret",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "0.404458",
    airport_lon: "35.238928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 962,
    airport_code: "EDM",
    airport_name: "Les Ajoncs Arpt",
    airport_city_code: "EDM",
    airport_city_name: "La Roche",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.701944",
    airport_lon: "-1.378625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 963,
    airport_code: "EDO",
    airport_name: "Edremit Airport",
    airport_city_code: "EDO",
    airport_city_name: "Edremit",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.5546",
    airport_lon: "27.0138",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 964,
    airport_code: "EDR",
    airport_name: "Edward River Arpt",
    airport_city_code: "EDR",
    airport_city_name: "Edward River",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-14.8967",
    airport_lon: "141.609",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 965,
    airport_code: "EED",
    airport_name: "Needles Arpt",
    airport_city_code: "EED",
    airport_city_name: "Needles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "34.7667",
    airport_lon: "-114.6233",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 966,
    airport_code: "EEN",
    airport_name: "Dilliant Hopkins Arpt",
    airport_city_code: "EEN",
    airport_city_name: "Keene",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 967,
    airport_code: "EFL",
    airport_name: "Argostoli Arpt",
    airport_city_code: "EFL",
    airport_city_name: "Kefalonia",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "38.120069",
    airport_lon: "20.500481",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 968,
    airport_code: "EGC",
    airport_name: "Roumaniere Arpt",
    airport_city_code: "EGC",
    airport_city_name: "Bergerac",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.825279",
    airport_lon: "0.518611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 969,
    airport_code: "ZBV",
    airport_name: "Beaver Creek Van Service",
    airport_city_code: "EGE",
    airport_city_name: "Eagle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 970,
    airport_code: "QBF",
    airport_name: "Vail Van Service",
    airport_city_code: "EGE",
    airport_city_name: "Eagle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 971,
    airport_code: "EGE",
    airport_name: "Eagle County Arpt",
    airport_city_code: "EGE",
    airport_city_name: "Eagle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "39.642556",
    airport_lon: "-106.917694",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 972,
    airport_code: "EGM",
    airport_name: "Sege Arpt",
    airport_city_code: "EGM",
    airport_city_name: "Sege",
    airport_country_name: "SOLOMON ISLANDS",
    airport_country_code: "SB",
    airport_timezone: "11",
    airport_lat: "-8.57889",
    airport_lon: "157.876",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 973,
    airport_code: "EGN",
    airport_name: "Geneina Arpt",
    airport_city_code: "EGN",
    airport_city_name: "Geneina",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "2",
    airport_lat: "13.4817",
    airport_lon: "22.4672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 974,
    airport_code: "EGO",
    airport_name: "Belgorod Arpt",
    airport_city_code: "EGO",
    airport_city_name: "Belgorod",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "50.6438",
    airport_lon: "36.5901",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 975,
    airport_code: "EGP",
    airport_name: "Maverick County Arpt",
    airport_city_code: "EGP",
    airport_city_name: "Eagle Pass",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 976,
    airport_code: "EGS",
    airport_name: "Egilsstadir Arpt",
    airport_city_code: "EGS",
    airport_city_name: "Egilsstadir",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "65.283333",
    airport_lon: "-14.401389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 977,
    airport_code: "EGV",
    airport_name: "Eagle River Union Arpt",
    airport_city_code: "EGV",
    airport_city_name: "Eagle River",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "45.932333",
    airport_lon: "-89.268283",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 978,
    airport_code: "EHL",
    airport_name: "El Bolson Arpt",
    airport_city_code: "EHL",
    airport_city_name: "El Bolson",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-41.943189",
    airport_lon: "-71.532289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 979,
    airport_code: "EHT",
    airport_name: "Rentschler Arpt",
    airport_city_code: "EHT",
    airport_city_name: "East Hartford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 980,
    airport_code: "EIB",
    airport_name: "Eisenach Arpt",
    airport_city_code: "EIB",
    airport_city_name: "Eisenach",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 981,
    airport_code: "EIE",
    airport_name: "Eniseysk Airport",
    airport_city_code: "EIE",
    airport_city_name: "Eniseysk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "58.4742",
    airport_lon: "92.1125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 982,
    airport_code: "ZYE",
    airport_name: "Eindhoven Rail Station",
    airport_city_code: "EIN",
    airport_city_name: "Eindhoven",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 983,
    airport_code: "EIN",
    airport_name: "Welschap Arpt",
    airport_city_code: "EIN",
    airport_city_name: "Eindhoven",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "51.450139",
    airport_lon: "5.374528",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 984,
    airport_code: "EIS",
    airport_name: "Beef Island Arpt",
    airport_city_code: "EIS",
    airport_city_name: "Beef Island",
    airport_country_name: "VIRGIN ISLANDS, BRITISH",
    airport_country_code: "VG",
    airport_timezone: "-4",
    airport_lat: "18.444834",
    airport_lon: "-64.542975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 985,
    airport_code: "EJA",
    airport_name: "Variguies Arpt",
    airport_city_code: "EJA",
    airport_city_name: "Barrancabermeja",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "7.024331",
    airport_lon: "-73.8068",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 986,
    airport_code: "EKI",
    airport_name: "Elkhart Municipal Arpt",
    airport_city_code: "EKI",
    airport_city_name: "Elkhart",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "37.0007",
    airport_lon: "-101.88",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 987,
    airport_code: "EKN",
    airport_name: "Randolph County Arpt",
    airport_city_code: "EKN",
    airport_city_name: "Elkins",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.889444",
    airport_lon: "-79.857139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 988,
    airport_code: "EKO",
    airport_name: "J C Harris Field",
    airport_city_code: "EKO",
    airport_city_name: "Elko",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "40.8249",
    airport_lon: "-115.792",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 989,
    airport_code: "EKT",
    airport_name: "Eskilstuna Arpt",
    airport_city_code: "EKT",
    airport_city_name: "Eskilstuna",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "59.3522",
    airport_lon: "16.7083",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 990,
    airport_code: "XFJ",
    airport_name: "Eskilstuna Rail Station",
    airport_city_code: "EKT",
    airport_city_name: "Eskilstuna",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 991,
    airport_code: "EKX",
    airport_name: "Addington Field",
    airport_city_code: "EKX",
    airport_city_name: "Elizabethtown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 992,
    airport_code: "ELD",
    airport_name: "Goodwin Field",
    airport_city_code: "ELD",
    airport_city_name: "El Dorado",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.220972",
    airport_lon: "-92.813278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 993,
    airport_code: "ELH",
    airport_name: "North Eleuthera Intl",
    airport_city_code: "ELH",
    airport_city_name: "North Eleuthera",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.474861",
    airport_lon: "-76.683489",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 994,
    airport_code: "ELK",
    airport_name: "Elk City Municipal",
    airport_city_code: "ELK",
    airport_city_name: "Elk City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 995,
    airport_code: "ELM",
    airport_name: "Elmira Corning Regional Arpt",
    airport_city_code: "ELM",
    airport_city_name: "Elmira",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.159889",
    airport_lon: "-76.891611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 996,
    airport_code: "ELP",
    airport_name: "El Paso Intl Arpt",
    airport_city_code: "ELP",
    airport_city_name: "El Paso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "31.80725",
    airport_lon: "-106.377583",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 997,
    airport_code: "BIF",
    airport_name: "Biggs Army Air Field",
    airport_city_code: "ELP",
    airport_city_name: "El Paso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "31.849528",
    airport_lon: "-106.380039",
    airport_num_airports: 2,
  },
  {
    airport_id: 998,
    airport_code: "ELQ",
    airport_name: "Gassim Arpt",
    airport_city_code: "ELQ",
    airport_city_name: "Gassim",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "26.302822",
    airport_lon: "43.773911",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 999,
    airport_code: "ELS",
    airport_name: "East London Arpt",
    airport_city_code: "ELS",
    airport_city_name: "East London",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-33.035569",
    airport_lon: "27.825939",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1000,
    airport_code: "ELY",
    airport_name: "Yelland Field",
    airport_city_code: "ELY",
    airport_city_name: "Ely NV",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "39.299722",
    airport_lon: "-114.841944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1001,
    airport_code: "EMD",
    airport_name: "Emerald Arpt",
    airport_city_code: "EMD",
    airport_city_name: "Emerald",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-23.5675",
    airport_lon: "148.179167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1002,
    airport_code: "EMT",
    airport_name: "El Monte Arpt",
    airport_city_code: "EMT",
    airport_city_name: "El Monte",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1003,
    airport_code: "ENA",
    airport_name: "Kenai Municipal Arpt",
    airport_city_code: "ENA",
    airport_city_name: "Kenai",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "60.573111",
    airport_lon: "-151.245",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1004,
    airport_code: "ENC",
    airport_name: "Essey Airport",
    airport_city_code: "ENC",
    airport_city_name: "Nancy",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.692069",
    airport_lon: "6.230458",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1005,
    airport_code: "ENH",
    airport_name: "Enshi Arpt",
    airport_city_code: "ENH",
    airport_city_name: "Enshi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.3203",
    airport_lon: "109.485",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1006,
    airport_code: "ENK",
    airport_name: "St Angelo Arpt",
    airport_city_code: "ENK",
    airport_city_name: "Enniskillen",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "54.398889",
    airport_lon: "-7.651667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1007,
    airport_code: "ENL",
    airport_name: "Centralia Municipal Arpt",
    airport_city_code: "ENL",
    airport_city_name: "Centralia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "38.5151",
    airport_lon: "-89.0911",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1008,
    airport_code: "ENS",
    airport_name: "Twente Airport",
    airport_city_code: "ENS",
    airport_city_name: "Enschede",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "52.27",
    airport_lon: "6.874167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1009,
    airport_code: "ENW",
    airport_name: "Kenosha Municipal Arpt",
    airport_city_code: "ENW",
    airport_city_name: "Kenosha",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.595694",
    airport_lon: "-87.927806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1010,
    airport_code: "ENY",
    airport_name: "Yanan Arpt",
    airport_city_code: "ENY",
    airport_city_name: "Yanan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "36.6369",
    airport_lon: "109.554",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1011,
    airport_code: "EOI",
    airport_name: "Eday Arpt",
    airport_city_code: "EOI",
    airport_city_name: "Eday",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.1906",
    airport_lon: "-2.77222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1012,
    airport_code: "EOK",
    airport_name: "Keokuk Arpt",
    airport_city_code: "EOK",
    airport_city_name: "Keokuk",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1013,
    airport_code: "EPL",
    airport_name: "Mirecourt Arpt",
    airport_city_code: "EPL",
    airport_city_name: "Epinal",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.324961",
    airport_lon: "6.069983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1014,
    airport_code: "EPR",
    airport_name: "Esperance Arpt",
    airport_city_code: "EPR",
    airport_city_name: "Esperance",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-33.6844",
    airport_lon: "121.823",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1015,
    airport_code: "EPS",
    airport_name: "El Portillo Arpt",
    airport_city_code: "EPS",
    airport_city_name: "El Portillo Samana",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "19.198586",
    airport_lon: "-69.429772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1016,
    airport_code: "EPU",
    airport_name: "Parnu Arpt",
    airport_city_code: "EPU",
    airport_city_name: "Parnu",
    airport_country_name: "ESTONIA",
    airport_country_code: "EE",
    airport_timezone: "2",
    airport_lat: "58.419044",
    airport_lon: "24.472819",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1017,
    airport_code: "EQS",
    airport_name: "Esquel Airport",
    airport_city_code: "EQS",
    airport_city_name: "Esquel",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-42.90795",
    airport_lon: "-71.139472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1018,
    airport_code: "ERC",
    airport_name: "Erzincan Arpt",
    airport_city_code: "ERC",
    airport_city_name: "Erzincan",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.710203",
    airport_lon: "39.527003",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1019,
    airport_code: "ERF",
    airport_name: "Erfurt Arpt",
    airport_city_code: "ERF",
    airport_city_name: "Erfurt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.979811",
    airport_lon: "10.958106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1020,
    airport_code: "ERH",
    airport_name: "Errachidia Arpt",
    airport_city_code: "ERH",
    airport_city_name: "Errachidia",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "31.9475",
    airport_lon: "-4.398333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1021,
    airport_code: "ERI",
    airport_name: "Erie Intl",
    airport_city_code: "ERI",
    airport_city_name: "Erie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.082022",
    airport_lon: "-80.176217",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1022,
    airport_code: "ERV",
    airport_name: "Kerrville Arpt",
    airport_city_code: "ERV",
    airport_city_name: "Kerrville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.976667",
    airport_lon: "-99.085472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1023,
    airport_code: "ERZ",
    airport_name: "Erzurum Arpt",
    airport_city_code: "ERZ",
    airport_city_name: "Erzurum",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.956501",
    airport_lon: "41.170166",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1024,
    airport_code: "ESC",
    airport_name: "Delta County",
    airport_city_code: "ESC",
    airport_city_name: "Escanaba",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "45.722778",
    airport_lon: "-87.093611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1025,
    airport_code: "ESD",
    airport_name: "Eastsound Orcas Is Arpt",
    airport_city_code: "ESD",
    airport_city_name: "East Sound",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.708056",
    airport_lon: "-122.910556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1026,
    airport_code: "ESE",
    airport_name: "Ensenada Arpt",
    airport_city_code: "ESE",
    airport_city_name: "Ensenada",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-8",
    airport_lat: "31.795281",
    airport_lon: "-116.602772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1027,
    airport_code: "ESK",
    airport_name: "Eskisehir Airport",
    airport_city_code: "ESK",
    airport_city_name: "Eskisehir Airport",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.784138",
    airport_lon: "30.582111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1028,
    airport_code: "ESL",
    airport_name: "Elista Arpt",
    airport_city_code: "ESL",
    airport_city_name: "Elista",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "46.3739",
    airport_lon: "44.3309",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1029,
    airport_code: "ESN",
    airport_name: "Easton Municipal Arpt",
    airport_city_code: "ESN",
    airport_city_name: "Easton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.804167",
    airport_lon: "-76.069",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1030,
    airport_code: "ESP",
    airport_name: "Birchwood Pocono Arpt",
    airport_city_code: "ESP",
    airport_city_name: "East Stroudsburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1031,
    airport_code: "ESR",
    airport_name: "El Salvador Arpt",
    airport_city_code: "ESR",
    airport_city_name: "El Salvador",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-26.3111",
    airport_lon: "-69.7652",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1032,
    airport_code: "ESS",
    airport_name: "Essen Arpt",
    airport_city_code: "ESS",
    airport_city_name: "Essen",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.402333",
    airport_lon: "6.937333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1033,
    airport_code: "ESU",
    airport_name: "Essaouira Arpt",
    airport_city_code: "ESU",
    airport_city_name: "Essaouira",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "31.3975",
    airport_lon: "-9.681667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1034,
    airport_code: "ETB",
    airport_name: "West Bend Arpt",
    airport_city_code: "ETB",
    airport_city_name: "West Bend",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1035,
    airport_code: "ETH",
    airport_name: "Elat Airport",
    airport_city_code: "ETH",
    airport_city_name: "Elat",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "2",
    airport_lat: "29.561281",
    airport_lon: "34.960081",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1036,
    airport_code: "ETS",
    airport_name: "Enterprise Municipal",
    airport_city_code: "ETS",
    airport_city_name: "Enterprise",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1037,
    airport_code: "ETZ",
    airport_name: "Metz Nancy Lorraine",
    airport_city_code: "ETZ",
    airport_city_name: "Metz Nancy",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.982142",
    airport_lon: "6.251319",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1038,
    airport_code: "XZI",
    airport_name: "Railway Station Metz",
    airport_city_code: "ETZ",
    airport_city_name: "Metz Nancy",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "49.1203",
    airport_lon: "6.1778",
    airport_num_airports: 2,
  },
  {
    airport_id: 1039,
    airport_code: "EUF",
    airport_name: "Weedon Field",
    airport_city_code: "EUF",
    airport_city_name: "Eufaula",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1040,
    airport_code: "EUG",
    airport_name: "Eugene Arpt",
    airport_city_code: "EUG",
    airport_city_name: "Eugene",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "44.124583",
    airport_lon: "-123.211972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1041,
    airport_code: "EUN",
    airport_name: "Hassan I Arpt",
    airport_city_code: "EUN",
    airport_city_name: "El Aaiun",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "27.1517",
    airport_lon: "-13.2192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1042,
    airport_code: "EUX",
    airport_name: "Roosevelt Field",
    airport_city_code: "EUX",
    airport_city_name: "St Eustatius",
    airport_country_name: "NETHERLANDS ANTILLES",
    airport_country_code: "AN",
    airport_timezone: "-4",
    airport_lat: "17.496492",
    airport_lon: "-62.979439",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1043,
    airport_code: "EVE",
    airport_name: "Evenes Arpt",
    airport_city_code: "EVE",
    airport_city_name: "Harstad Narvik",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "68.4913",
    airport_lon: "16.678108",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1044,
    airport_code: "EVG",
    airport_name: "Sveg Arpt",
    airport_city_code: "EVG",
    airport_city_name: "Sveg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "62.047811",
    airport_lon: "14.42295",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1045,
    airport_code: "EVM",
    airport_name: "Eveleth Virginia Municipal Arpt",
    airport_city_code: "EVM",
    airport_city_name: "Eveleth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1046,
    airport_code: "EVN",
    airport_name: "Yerevan Arpt",
    airport_city_code: "EVN",
    airport_city_name: "Yerevan",
    airport_country_name: "ARMENIA",
    airport_country_code: "AM",
    airport_timezone: "4",
    airport_lat: "40.147275",
    airport_lon: "44.395881",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1047,
    airport_code: "EVV",
    airport_name: "Evansville Regional Arpt",
    airport_city_code: "EVV",
    airport_city_name: "Evansville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.036997",
    airport_lon: "-87.532364",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1048,
    airport_code: "EVW",
    airport_name: "Evanston Arpt",
    airport_city_code: "EVW",
    airport_city_name: "Evanston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1049,
    airport_code: "EVX",
    airport_name: "Evreux Arpt",
    airport_city_code: "EVX",
    airport_city_name: "Evreux",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "49.0286",
    airport_lon: "1.22",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1050,
    airport_code: "EWB",
    airport_name: "New Bedford Municipal",
    airport_city_code: "EWB",
    airport_city_name: "New Bedford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.6761",
    airport_lon: "-70.9569",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1051,
    airport_code: "EWK",
    airport_name: "Newton City County Arpt",
    airport_city_code: "EWK",
    airport_city_name: "Newton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1052,
    airport_code: "EWN",
    airport_name: "Craven County Regional Arpt",
    airport_city_code: "EWN",
    airport_city_name: "New Bern",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.072972",
    airport_lon: "-77.042944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1053,
    airport_code: "EWR",
    airport_name: "Newark Liberty Intl Arpt",
    airport_city_code: "NYC",
    airport_city_name: "New York",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.6925",
    airport_lon: "-74.168667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1054,
    airport_code: "EXI",
    airport_name: "Excursion Inlet Municipal",
    airport_city_code: "EXI",
    airport_city_name: "Excursion Inlet",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "58.420556",
    airport_lon: "-135.449167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1055,
    airport_code: "EXM",
    airport_name: "Exmouth Gulf",
    airport_city_code: "EXM",
    airport_city_name: "Exmouth Gulf",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-21.933",
    airport_lon: "114.128",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1056,
    airport_code: "EXT",
    airport_name: "Exeter Arpt",
    airport_city_code: "EXT",
    airport_city_name: "Exeter",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.734444",
    airport_lon: "-3.413889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1057,
    airport_code: "EYW",
    airport_name: "Key West Intl",
    airport_city_code: "EYW",
    airport_city_name: "Key West",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "24.556111",
    airport_lon: "-81.759556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1058,
    airport_code: "EZS",
    airport_name: "Elazig Arpt",
    airport_city_code: "EZS",
    airport_city_name: "Elazig",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.606925",
    airport_lon: "39.291417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1059,
    airport_code: "FAB",
    airport_name: "Farnborough Arpt",
    airport_city_code: "FAB",
    airport_city_name: "Farnborough Hampshire",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.275833",
    airport_lon: "-0.776333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1060,
    airport_code: "FAE",
    airport_name: "Faeroe Airport",
    airport_city_code: "FAE",
    airport_city_name: "Faeroe Islands",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "0",
    airport_lat: "62.063628",
    airport_lon: "-7.277219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1061,
    airport_code: "FAI",
    airport_name: "Fairbanks Intl Arpt",
    airport_city_code: "FAI",
    airport_city_name: "Fairbanks",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "64.815114",
    airport_lon: "-147.856267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1062,
    airport_code: "FAJ",
    airport_name: "Fajardo Arpt",
    airport_city_code: "FAJ",
    airport_city_name: "Fajardo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.308889",
    airport_lon: "-65.661861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1063,
    airport_code: "FAO",
    airport_name: "Faro Airport",
    airport_city_code: "FAO",
    airport_city_name: "Faro",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "37.014425",
    airport_lon: "-7.965911",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1064,
    airport_code: "FAR",
    airport_name: "Hector Airport",
    airport_city_code: "FAR",
    airport_city_name: "Fargo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "46.92065",
    airport_lon: "-96.815764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1065,
    airport_code: "FAT",
    airport_name: "Fresno Air Terminal",
    airport_city_code: "FAT",
    airport_city_name: "Fresno",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "36.776194",
    airport_lon: "-119.71814",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1066,
    airport_code: "FCH",
    airport_name: "Fresno Chandler Arpt",
    airport_city_code: "FAT",
    airport_city_name: "Fresno",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1067,
    airport_code: "FAY",
    airport_name: "Fayetteville Municipal",
    airport_city_code: "FAY",
    airport_city_name: "Fayetteville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.9912",
    airport_lon: "-78.8803",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1068,
    airport_code: "FBD",
    airport_name: "Faizabad Arpt",
    airport_city_code: "FBD",
    airport_city_name: "Faizabad",
    airport_country_name: "AFGHANISTAN",
    airport_country_code: "AF",
    airport_timezone: "4",
    airport_lat: "37.1211",
    airport_lon: "70.5181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1069,
    airport_code: "FBM",
    airport_name: "Luano",
    airport_city_code: "FBM",
    airport_city_name: "Lubumbashi",
    airport_country_name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    airport_country_code: "CD",
    airport_timezone: "2",
    airport_lat: "-11.591333",
    airport_lon: "27.530889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1070,
    airport_code: "FCA",
    airport_name: "Glacier Park Intl",
    airport_city_code: "FCA",
    airport_city_name: "Kalispell",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "48.310472",
    airport_lon: "-114.256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1071,
    airport_code: "FCB",
    airport_name: "Ficksburg Sentra Oes",
    airport_city_code: "FCB",
    airport_city_name: "Ficksburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1072,
    airport_code: "FCN",
    airport_name: "Cuxhaven Nordholz Arpt",
    airport_city_code: "FCN",
    airport_city_name: "Cuxhaven",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "53.7683",
    airport_lon: "8.6642",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1073,
    airport_code: "FCY",
    airport_name: "Forrest City Municipal Arpt",
    airport_city_code: "FCY",
    airport_city_name: "Forrest City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1074,
    airport_code: "FDE",
    airport_name: "Bringeland Arpt",
    airport_city_code: "FDE",
    airport_city_name: "Forde",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "61.25",
    airport_lon: "5.45",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1075,
    airport_code: "FDF",
    airport_name: "Lamentin Arpt",
    airport_city_code: "FDF",
    airport_city_name: "Ft De France",
    airport_country_name: "MARTINIQUE",
    airport_country_code: "MQ",
    airport_timezone: "-4",
    airport_lat: "14.591033",
    airport_lon: "-61.003175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1076,
    airport_code: "FDH",
    airport_name: "Friedrichshafen Lowenthal",
    airport_city_code: "FDH",
    airport_city_name: "Friedrichshafen",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "47.671317",
    airport_lon: "9.511486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1077,
    airport_code: "FDK",
    airport_name: "Frederick Municipal",
    airport_city_code: "FDK",
    airport_city_name: "Frederick",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.4178",
    airport_lon: "-77.3744",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1078,
    airport_code: "FEG",
    airport_name: "Fergana",
    airport_city_code: "FEG",
    airport_city_name: "Fergana",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "40.3588",
    airport_lon: "71.745",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1079,
    airport_code: "FEN",
    airport_name: "Fernando De Noronha Arpt",
    airport_city_code: "FEN",
    airport_city_name: "Fernando De Noronha",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-2",
    airport_lat: "-3.854928",
    airport_lon: "-32.423336",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1080,
    airport_code: "FEZ",
    airport_name: "Fez Airport",
    airport_city_code: "FEZ",
    airport_city_name: "Fez Ma",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "33.927261",
    airport_lon: "-4.977958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1081,
    airport_code: "FFM",
    airport_name: "Fergus Falls Municipal",
    airport_city_code: "FFM",
    airport_city_name: "Fergus Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1082,
    airport_code: "FHU",
    airport_name: "Sierra Vista Municipal",
    airport_city_code: "FHU",
    airport_city_name: "Ft Huachuca",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "31.588472",
    airport_lon: "-110.344389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1083,
    airport_code: "FIE",
    airport_name: "Fair Isle Arpt",
    airport_city_code: "FIE",
    airport_city_name: "Fair Isle",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.5358",
    airport_lon: "-1.62806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1084,
    airport_code: "FIH",
    airport_name: "Kinshasa Arpt",
    airport_city_code: "FIH",
    airport_city_name: "Kinshasa",
    airport_country_name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    airport_country_code: "CD",
    airport_timezone: "1",
    airport_lat: "-4.38575",
    airport_lon: "15.444569",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1085,
    airport_code: "NLO",
    airport_name: "N Dolo Airport",
    airport_city_code: "FIH",
    airport_city_name: "Kinshasa",
    airport_country_name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    airport_country_code: "CD",
    airport_timezone: "1",
    airport_lat: "-4.326689",
    airport_lon: "15.327342",
    airport_num_airports: 2,
  },
  {
    airport_id: 1086,
    airport_code: "FJR",
    airport_name: "Fujairah Intl Arpt",
    airport_city_code: "FJR",
    airport_city_name: "Al Fujairah",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "4",
    airport_lat: "25.112225",
    airport_lon: "56.323964",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1087,
    airport_code: "FKB",
    airport_name: "Soellingen Arpt",
    airport_city_code: "FKB",
    airport_city_name: "Karlsruhe Baden Baden",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.7793",
    airport_lon: "8.08048",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1088,
    airport_code: "FKL",
    airport_name: "Chess Lamberton Arpt",
    airport_city_code: "FKL",
    airport_city_name: "Franklin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.377874",
    airport_lon: "-79.860362",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1089,
    airport_code: "FKS",
    airport_name: "Fukushima Arpt",
    airport_city_code: "FKS",
    airport_city_name: "Fukushima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "37.2274",
    airport_lon: "140.431",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1090,
    airport_code: "FLB",
    airport_name: "Cangapara Arpt",
    airport_city_code: "FLB",
    airport_city_name: "Floriano",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-6.8458",
    airport_lon: "-43.0775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1091,
    airport_code: "FLC",
    airport_name: "Falls Creek Arpt",
    airport_city_code: "FLC",
    airport_city_name: "Falls Creek",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1092,
    airport_code: "FLF",
    airport_name: "Schaferhaus Arpt",
    airport_city_code: "FLF",
    airport_city_name: "Flensburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.771772",
    airport_lon: "9.378214",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1093,
    airport_code: "FLG",
    airport_name: "Flagstaff Arpt",
    airport_city_code: "FLG",
    airport_city_name: "Flagstaff",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.140318",
    airport_lon: "-111.669239",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1094,
    airport_code: "FXE",
    airport_name: "Ft Lauderdale Excutive",
    airport_city_code: "FLL",
    airport_city_name: "Ft Lauderdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.197281",
    airport_lon: "-80.170706",
    airport_num_airports: 3,
  },
  {
    airport_id: 1095,
    airport_code: "FLL",
    airport_name: "Ft Lauderdale Hollywood Intl Arpt",
    airport_city_code: "FLL",
    airport_city_name: "Ft Lauderdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.072583",
    airport_lon: "-80.15275",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1096,
    airport_code: "BCT",
    airport_name: "Boca Raton Public",
    airport_city_code: "FLL",
    airport_city_name: "Ft Lauderdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.3785",
    airport_lon: "-80.107694",
    airport_num_airports: 3,
  },
  {
    airport_id: 1097,
    airport_code: "FLN",
    airport_name: "Hercilio Luz Arpt",
    airport_city_code: "FLN",
    airport_city_name: "Florianopolis",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-27.670489",
    airport_lon: "-48.547181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1098,
    airport_code: "FLO",
    airport_name: "Gilbert Field",
    airport_city_code: "FLO",
    airport_city_name: "Florence",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.185361",
    airport_lon: "-79.723889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1099,
    airport_code: "FLP",
    airport_name: "Flippin Arpt",
    airport_city_code: "FLP",
    airport_city_name: "Flippin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1100,
    airport_code: "FLR",
    airport_name: "Amerigo Vespucci Arpt",
    airport_city_code: "FLR",
    airport_city_name: "Florence",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "43.809953",
    airport_lon: "11.2051",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1101,
    airport_code: "FLW",
    airport_name: "Aerodromo Das Flores",
    airport_city_code: "FLW",
    airport_city_name: "Santa Cruz Flores",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "39.455272",
    airport_lon: "-31.131361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1102,
    airport_code: "FMA",
    airport_name: "El Pucu Arpt",
    airport_city_code: "FMA",
    airport_city_name: "Formosa",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-26.212722",
    airport_lon: "-58.228111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1103,
    airport_code: "FMH",
    airport_name: "Otis AFB",
    airport_city_code: "FMH",
    airport_city_name: "Falmouth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.658439",
    airport_lon: "-70.521417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1104,
    airport_code: "FMM",
    airport_name: "Allgaeu Arpt",
    airport_city_code: "FMM",
    airport_city_name: "Memmingen Allgaeu",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "47.988758",
    airport_lon: "10.2395",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1105,
    airport_code: "FMN",
    airport_name: "Four Corners Regional Arpt",
    airport_city_code: "FMN",
    airport_city_name: "Farmington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "36.74125",
    airport_lon: "-108.229944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1106,
    airport_code: "FMO",
    airport_name: "Muenster Airport",
    airport_city_code: "FMO",
    airport_city_name: "Muenster",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "52.134642",
    airport_lon: "7.684831",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1107,
    airport_code: "FMS",
    airport_name: "Fort Madison Municipal",
    airport_city_code: "FMS",
    airport_city_name: "Fort Madison",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1108,
    airport_code: "RSW",
    airport_name: "Regional Southwest Arpt",
    airport_city_code: "FMY",
    airport_city_name: "Fort Myers",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.536167",
    airport_lon: "-81.755167",
    airport_num_airports: 1,
  },
  {
    airport_id: 1109,
    airport_code: "FNA",
    airport_name: "Lungi Intl Arpt",
    airport_city_code: "FNA",
    airport_city_name: "Freetown",
    airport_country_name: "SIERRA LEONE",
    airport_country_code: "SL",
    airport_timezone: "0",
    airport_lat: "8.616444",
    airport_lon: "-13.195489",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1110,
    airport_code: "FNC",
    airport_name: "Madeira Arpt",
    airport_city_code: "FNC",
    airport_city_name: "Funchal",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "32.697889",
    airport_lon: "-16.774453",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1111,
    airport_code: "FNI",
    airport_name: "Nimes Airport",
    airport_city_code: "FNI",
    airport_city_name: "Nimes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.757444",
    airport_lon: "4.416347",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1112,
    airport_code: "FNJ",
    airport_name: "Sunan Arpt",
    airport_city_code: "FNJ",
    airport_city_name: "Pyongyang",
    airport_country_name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    airport_country_code: "KP",
    airport_timezone: "9",
    airport_lat: "39.224061",
    airport_lon: "125.67015",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1113,
    airport_code: "QWF",
    airport_name: "Fort Collins Bus Service",
    airport_city_code: "FNL",
    airport_city_name: "Ft Collins",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1114,
    airport_code: "FNL",
    airport_name: "Municipal Airport",
    airport_city_code: "FNL",
    airport_city_name: "Ft Collins",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "40.451828",
    airport_lon: "-105.011336",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1115,
    airport_code: "QWH",
    airport_name: "Loveland Bus Service",
    airport_city_code: "FNL",
    airport_city_name: "Ft Collins",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1116,
    airport_code: "FNT",
    airport_name: "Bishop Intl Arpt",
    airport_city_code: "FNT",
    airport_city_name: "Flint",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.965424",
    airport_lon: "-83.743629",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1117,
    airport_code: "FOB",
    airport_name: "Fort Bragg Arpt",
    airport_city_code: "FOB",
    airport_city_name: "Fort Bragg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1118,
    airport_code: "FOC",
    airport_name: "Fuzhou Arpt",
    airport_city_code: "FOC",
    airport_city_name: "Fuzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "25.935064",
    airport_lon: "119.663272",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1119,
    airport_code: "FOD",
    airport_name: "Ft Dodge Municipal",
    airport_city_code: "FOD",
    airport_city_name: "Fort Dodge",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.5512",
    airport_lon: "-94.191842",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1120,
    airport_code: "FOG",
    airport_name: "Gino Lisa Arpt",
    airport_city_code: "FOG",
    airport_city_name: "Foggia",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "41.432917",
    airport_lon: "15.535028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1121,
    airport_code: "FOK",
    airport_name: "Suffolk County Arpt",
    airport_city_code: "FOK",
    airport_city_name: "Westhampton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.843656",
    airport_lon: "-72.631789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1122,
    airport_code: "FOR",
    airport_name: "Pinto Martines Arpt",
    airport_city_code: "FOR",
    airport_city_name: "Fortaleza",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-3.776283",
    airport_lon: "-38.532556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1123,
    airport_code: "FOT",
    airport_name: "Forster",
    airport_city_code: "FOT",
    airport_city_name: "Forster",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1124,
    airport_code: "FPO",
    airport_name: "Grand Bahama Intl Arpt",
    airport_city_code: "FPO",
    airport_city_name: "Freeport",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "26.558686",
    airport_lon: "-78.695553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1125,
    airport_code: "FPR",
    airport_name: "St Lucie County Arpt",
    airport_city_code: "FPR",
    airport_city_name: "Fort Pierce",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "27.495",
    airport_lon: "-80.3683",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1126,
    airport_code: "HHN",
    airport_name: "Hahn Arpt",
    airport_city_code: "FRA",
    airport_city_name: "Frankfurt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.948672",
    airport_lon: "7.263892",
    airport_num_airports: 5,
  },
  {
    airport_id: 1127,
    airport_code: "QGV",
    airport_name: "Neu Isenburg Arpt",
    airport_city_code: "FRA",
    airport_city_name: "Frankfurt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 1128,
    airport_code: "ZRB",
    airport_name: "Frankfurt HBF Railway Service",
    airport_city_code: "FRA",
    airport_city_name: "Frankfurt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.107026",
    airport_lon: "8.662761",
    airport_num_airports: 5,
  },
  {
    airport_id: 1129,
    airport_code: "FRF",
    airport_name: "Rhein-Main Air Force Base",
    airport_city_code: "FRA",
    airport_city_name: "Frankfurt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "2",
    airport_lat: "50.1",
    airport_lon: "8.683333",
    airport_num_airports: 5,
  },
  {
    airport_id: 1130,
    airport_code: "FRA",
    airport_name: "Frankfurt Intl",
    airport_city_code: "FRA",
    airport_city_name: "Frankfurt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.026421",
    airport_lon: "8.543125",
    airport_num_airports: 5,
    airport_city: "true",
  },
  {
    airport_id: 1131,
    airport_code: "FRD",
    airport_name: "Friday Harbor Airport",
    airport_city_code: "FRD",
    airport_city_name: "Friday Harbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.521944",
    airport_lon: "-123.024444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1132,
    airport_code: "FRG",
    airport_name: "Republic Arpt",
    airport_city_code: "FRG",
    airport_city_name: "Farmingdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.7288",
    airport_lon: "-73.4134",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1133,
    airport_code: "FRH",
    airport_name: "French Lick Municipal Arpt",
    airport_city_code: "FRH",
    airport_city_name: "French Lick",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1134,
    airport_code: "FRJ",
    airport_name: "Frejus Arpt",
    airport_city_code: "FRJ",
    airport_city_name: "Frejus",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.416667",
    airport_lon: "6.733333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1135,
    airport_code: "FRL",
    airport_name: "Luigi Ridolfi Arpt",
    airport_city_code: "FRL",
    airport_city_name: "Forli",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.194753",
    airport_lon: "12.070094",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1136,
    airport_code: "FRM",
    airport_name: "Fairmont Municipal",
    airport_city_code: "FRM",
    airport_city_name: "Fairmont",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1137,
    airport_code: "FRO",
    airport_name: "Flora Arpt",
    airport_city_code: "FRO",
    airport_city_name: "Floro",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "61.583611",
    airport_lon: "5.024722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1138,
    airport_code: "FRR",
    airport_name: "Warren County Arpt",
    airport_city_code: "FRR",
    airport_city_name: "Front Royal",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1139,
    airport_code: "FRS",
    airport_name: "Flores Airport",
    airport_city_code: "FRS",
    airport_city_name: "Flores",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-6",
    airport_lat: "16.913819",
    airport_lon: "-89.866383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1140,
    airport_code: "FRU",
    airport_name: "Bishkek Arpt",
    airport_city_code: "FRU",
    airport_city_name: "Bishkek",
    airport_country_name: "KYRGYZSTAN",
    airport_country_code: "KG",
    airport_timezone: "5",
    airport_lat: "43.061306",
    airport_lon: "74.477556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1141,
    airport_code: "FRW",
    airport_name: "Francistown Arpt",
    airport_city_code: "FRW",
    airport_city_name: "Francistown",
    airport_country_name: "BOTSWANA",
    airport_country_code: "BW",
    airport_timezone: "2",
    airport_lat: "-21.159597",
    airport_lon: "27.474525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1142,
    airport_code: "FRZ",
    airport_name: "Fritzlar Airbase",
    airport_city_code: "FRZ",
    airport_city_name: "Fritzlar",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "51.1145",
    airport_lon: "9.2858",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1143,
    airport_code: "FSC",
    airport_name: "Sud Corse Arpt",
    airport_city_code: "FSC",
    airport_city_name: "Figari",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "41.500557",
    airport_lon: "9.097777",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1144,
    airport_code: "FSD",
    airport_name: "Regional Joe Foss Field",
    airport_city_code: "FSD",
    airport_city_name: "Sioux Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "43.582014",
    airport_lon: "-96.741914",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1145,
    airport_code: "FSM",
    airport_name: "Ft Smith Municipal",
    airport_city_code: "FSM",
    airport_city_name: "Ft Smith",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.336583",
    airport_lon: "-94.367444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1146,
    airport_code: "FSP",
    airport_name: "St Pierre Arpt",
    airport_city_code: "FSP",
    airport_city_name: "St Pierre",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "46.762904",
    airport_lon: "-56.173088",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1147,
    airport_code: "FST",
    airport_name: "Pecos County Arpt",
    airport_city_code: "FST",
    airport_city_name: "Fort Stockton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.915667",
    airport_lon: "-102.916139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1148,
    airport_code: "FTE",
    airport_name: "El Calafate Arpt",
    airport_city_code: "FTE",
    airport_city_name: "El Calafate",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-50.280322",
    airport_lon: "-72.053103",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1149,
    airport_code: "FTU",
    airport_name: "Marillac Arpt",
    airport_city_code: "FTU",
    airport_city_name: "Fort Dauphin",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-25.038056",
    airport_lon: "46.956111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1150,
    airport_code: "FTW",
    airport_name: "Meacham Field",
    airport_city_code: "FTW",
    airport_city_name: "Ft Worth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.819778",
    airport_lon: "-97.362444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1151,
    airport_code: "FUE",
    airport_name: "Fuerteventura Arpt",
    airport_city_code: "FUE",
    airport_city_name: "Puerto Del Rosario",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "28.452717",
    airport_lon: "-13.863761",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1152,
    airport_code: "FUG",
    airport_name: "Fuyang Aprt",
    airport_city_code: "FUG",
    airport_city_name: "Fuyang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "32.9",
    airport_lon: "115.816667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1153,
    airport_code: "FUJ",
    airport_name: "Fukue Arpt",
    airport_city_code: "FUJ",
    airport_city_name: "Fukue",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "32.666269",
    airport_lon: "128.832808",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1154,
    airport_code: "FUK",
    airport_name: "Itazuke Arpt",
    airport_city_code: "FUK",
    airport_city_name: "Fukuoka",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.585942",
    airport_lon: "130.450686",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1155,
    airport_code: "FUL",
    airport_name: "Fullerton Municipal Arpt",
    airport_city_code: "FUL",
    airport_city_name: "Fullerton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.521925",
    airport_lon: "-117.584722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1156,
    airport_code: "FUN",
    airport_name: "Funafuti Intl Arpt",
    airport_city_code: "FUN",
    airport_city_name: "Funafuti",
    airport_country_name: "TUVALU",
    airport_country_code: "TV",
    airport_timezone: "12",
    airport_lat: "-8.525",
    airport_lon: "179.196389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1157,
    airport_code: "FWA",
    airport_name: "Baer Field",
    airport_city_code: "FWA",
    airport_city_name: "Ft Wayne",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.978472",
    airport_lon: "-85.195139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1158,
    airport_code: "FWM",
    airport_name: "Fort William Arpt",
    airport_city_code: "FWM",
    airport_city_name: "Fort William",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "56.816666",
    airport_lon: "-5.116667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1159,
    airport_code: "FYV",
    airport_name: "Fayetteville Municipal Arpt",
    airport_city_code: "FYV",
    airport_city_name: "Fayetteville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.005094",
    airport_lon: "-94.170059",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1160,
    airport_code: "XNA",
    airport_name: "Northwest Arkansas Regional Arpt",
    airport_city_code: "FYV",
    airport_city_name: "Fayetteville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.281869",
    airport_lon: "-94.306811",
    airport_num_airports: 2,
  },
  {
    airport_id: 1161,
    airport_code: "GAD",
    airport_name: "Gadsden Municipal",
    airport_city_code: "GAD",
    airport_city_name: "Gadsden",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.972649",
    airport_lon: "-86.089083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1162,
    airport_code: "GAI",
    airport_name: "Montgomery Cty Arpt",
    airport_city_code: "GAI",
    airport_city_name: "Gaithersburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.1006",
    airport_lon: "-77.09576",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1163,
    airport_code: "GAJ",
    airport_name: "Yamagata Airport",
    airport_city_code: "GAJ",
    airport_city_name: "Yamagata",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "38.411894",
    airport_lon: "140.371331",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1164,
    airport_code: "GAL",
    airport_name: "Galena Arpt",
    airport_city_code: "GAL",
    airport_city_name: "Galena",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "64.736178",
    airport_lon: "-156.937417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1165,
    airport_code: "GAN",
    airport_name: "Gan International Arpt",
    airport_city_code: "GAN",
    airport_city_name: "Gan Island",
    airport_country_name: "MALDIVES",
    airport_country_code: "MV",
    airport_timezone: "5",
    airport_lat: "-0.693342",
    airport_lon: "73.1556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1166,
    airport_code: "GAR",
    airport_name: "Garaina Arpt",
    airport_city_code: "GAR",
    airport_city_name: "Garaina",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1167,
    airport_code: "GAT",
    airport_name: "Tallard Arpt",
    airport_city_code: "GAT",
    airport_city_name: "Gap France",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1168,
    airport_code: "GAU",
    airport_name: "Lokpriya Gopinath Bordoloi Intl Arpt",
    airport_city_code: "GAU",
    airport_city_name: "Guwahati",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.106092",
    airport_lon: "91.585939",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1169,
    airport_code: "GAY",
    airport_name: "Gaya Arpt",
    airport_city_code: "GAY",
    airport_city_name: "Gaya",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "24.744308",
    airport_lon: "84.951175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1170,
    airport_code: "GBD",
    airport_name: "Greate Bend Municipal",
    airport_city_code: "GBD",
    airport_city_name: "Great Bend",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "38.3443",
    airport_lon: "-98.8592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1171,
    airport_code: "GBE",
    airport_name: "Gaborone Arpt",
    airport_city_code: "GBE",
    airport_city_name: "Gaborone",
    airport_country_name: "BOTSWANA",
    airport_country_code: "BW",
    airport_timezone: "2",
    airport_lat: "-24.555225",
    airport_lon: "25.918208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1172,
    airport_code: "GBG",
    airport_name: "Galesburg Arpt",
    airport_city_code: "GBG",
    airport_city_name: "Galesburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1173,
    airport_code: "GBK",
    airport_name: "Gbangbatok Arpt",
    airport_city_code: "GBK",
    airport_city_name: "Gbangbatok",
    airport_country_name: "SIERRA LEONE",
    airport_country_code: "SL",
    airport_timezone: "0",
    airport_lat: "7.767",
    airport_lon: "-12.383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1174,
    airport_code: "GBN",
    airport_name: "San Giovanni Rotondo Arpt",
    airport_city_code: "GBN",
    airport_city_name: "San Giovanni Rotondo",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-5",
    airport_lat: "38.344167",
    airport_lon: "-98.859167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1175,
    airport_code: "GBQ",
    airport_name: "Muharraq Arpt",
    airport_city_code: "GBQ",
    airport_city_name: "Muharraq Town",
    airport_country_name: "BAHRAIN",
    airport_country_code: "BH",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1176,
    airport_code: "GBR",
    airport_name: "Great Barrington Arpt",
    airport_city_code: "GBR",
    airport_city_name: "Great Barrington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1177,
    airport_code: "GBZ",
    airport_name: "Great Barrier Island Arpt",
    airport_city_code: "GBZ",
    airport_city_name: "Great Barrier Island",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "11",
    airport_lat: "-36.1429",
    airport_lon: "175.2819",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1178,
    airport_code: "GCC",
    airport_name: "Campbell Cty Arpt",
    airport_city_code: "GCC",
    airport_city_name: "Gillette",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.348917",
    airport_lon: "-105.539361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1179,
    airport_code: "GCI",
    airport_name: "Guernsey Arpt",
    airport_city_code: "GCI",
    airport_city_name: "Guernsey",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "49.434956",
    airport_lon: "-2.601969",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1180,
    airport_code: "GCK",
    airport_name: "Garden City Municipal",
    airport_city_code: "GCK",
    airport_city_name: "Garden City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.927528",
    airport_lon: "-100.724417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1181,
    airport_code: "GCM",
    airport_name: "Owen Roberts Arpt",
    airport_city_code: "GCM",
    airport_city_name: "Grand Cayman Island",
    airport_country_name: "CAYMAN ISLANDS",
    airport_country_code: "KY",
    airport_timezone: "-5",
    airport_lat: "19.292778",
    airport_lon: "-81.35775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1182,
    airport_code: "GCN",
    airport_name: "Grand Canyon Natl Park Arpt",
    airport_city_code: "GCN",
    airport_city_name: "Grand Canyon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.952361",
    airport_lon: "-112.146972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1183,
    airport_code: "GCV",
    airport_name: "Gravatai Arpt",
    airport_city_code: "GCV",
    airport_city_name: "Gravatai",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1184,
    airport_code: "GCY",
    airport_name: "Municipal Greeneville Arpt",
    airport_city_code: "GCY",
    airport_city_name: "Greeneville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1185,
    airport_code: "GDG",
    airport_name: "Magdagachi Airport",
    airport_city_code: "GDG",
    airport_city_name: "Magdagachi Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1186,
    airport_code: "GDL",
    airport_name: "Miguel Hidalgo Arpt",
    airport_city_code: "GDL",
    airport_city_name: "Guadalajara",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.5218",
    airport_lon: "-103.311167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1187,
    airport_code: "GDN",
    airport_name: "Rebiechowo",
    airport_city_code: "GDN",
    airport_city_name: "Gdansk",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "54.377569",
    airport_lon: "18.466222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1188,
    airport_code: "GDT",
    airport_name: "Grand Turk Is Arpt",
    airport_city_code: "GDT",
    airport_city_name: "Grand Turk",
    airport_country_name: "TURKS AND CAICOS ISLANDS",
    airport_country_code: "TC",
    airport_timezone: "-5",
    airport_lat: "21.4445",
    airport_lon: "-71.1423",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1189,
    airport_code: "GDX",
    airport_name: "Magadan Arpt",
    airport_city_code: "GDX",
    airport_city_name: "Magadan",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "12",
    airport_lat: "59.910989",
    airport_lon: "150.720439",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1190,
    airport_code: "GDZ",
    airport_name: "Gelendzhik Arpt.",
    airport_city_code: "GDZ",
    airport_city_name: "Gelendzhik Arpt.",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "44.566667",
    airport_lon: "38.016667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1191,
    airport_code: "GED",
    airport_name: "Sussex County Arpt",
    airport_city_code: "GED",
    airport_city_name: "Georgetown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.689194",
    airport_lon: "-75.358889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1192,
    airport_code: "SFF",
    airport_name: "Felts Field",
    airport_city_code: "GEG",
    airport_city_name: "Spokane",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.682819",
    airport_lon: "-117.322558",
    airport_num_airports: 2,
  },
  {
    airport_id: 1193,
    airport_code: "GEG",
    airport_name: "Spokane Intl Arpt",
    airport_city_code: "GEG",
    airport_city_name: "Spokane",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.619861",
    airport_lon: "-117.533833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1194,
    airport_code: "GEO",
    airport_name: "Cheddi Jagan Intl",
    airport_city_code: "GEO",
    airport_city_name: "Georgetown",
    airport_country_name: "GUYANA",
    airport_country_code: "GY",
    airport_timezone: "-4",
    airport_lat: "6.498553",
    airport_lon: "-58.254119",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1195,
    airport_code: "GER",
    airport_name: "Rafael Cabrera Arpt",
    airport_city_code: "GER",
    airport_city_name: "Nueva Gerona",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "21.834689",
    airport_lon: "-82.783819",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1196,
    airport_code: "GES",
    airport_name: "Buayan Arpt",
    airport_city_code: "GES",
    airport_city_name: "General Santos",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "6.106439",
    airport_lon: "125.2353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1197,
    airport_code: "GET",
    airport_name: "Geraldton Arpt",
    airport_city_code: "GET",
    airport_city_name: "Geraldton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-28.7961",
    airport_lon: "114.707",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1198,
    airport_code: "GEV",
    airport_name: "Gallivare Arpt",
    airport_city_code: "GEV",
    airport_city_name: "Gallivare",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "67.132408",
    airport_lon: "20.814636",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1199,
    airport_code: "GEX",
    airport_name: "Geelong Arpt",
    airport_city_code: "GEX",
    airport_city_name: "Geelong",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-38.225",
    airport_lon: "144.333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1200,
    airport_code: "GFD",
    airport_name: "Pope Field Arpt",
    airport_city_code: "GFD",
    airport_city_name: "Greenfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1201,
    airport_code: "GFF",
    airport_name: "Griffith Arpt",
    airport_city_code: "GFF",
    airport_city_name: "Griffith",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-34.2508",
    airport_lon: "146.067",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1202,
    airport_code: "GFK",
    airport_name: "Grand Forks Mark Andrews Intl",
    airport_city_code: "GFK",
    airport_city_name: "Grand Forks",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "47.949256",
    airport_lon: "-97.176111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1203,
    airport_code: "GFL",
    airport_name: "Warren County",
    airport_city_code: "GFL",
    airport_city_name: "Glens Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "43.3411",
    airport_lon: "-73.6103",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1204,
    airport_code: "GFN",
    airport_name: "Grafton Arpt",
    airport_city_code: "GFN",
    airport_city_name: "Grafton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-29.7594",
    airport_lon: "153.03",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1205,
    airport_code: "GGG",
    airport_name: "Greg County Arpt",
    airport_city_code: "GGG",
    airport_city_name: "Longview",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.384014",
    airport_lon: "-94.711486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1206,
    airport_code: "GGT",
    airport_name: "George Town Airport",
    airport_city_code: "GGT",
    airport_city_name: "George Town",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "23.562631",
    airport_lon: "-75.877958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1207,
    airport_code: "GGW",
    airport_name: "International Glasgow",
    airport_city_code: "GGW",
    airport_city_name: "Glasgow",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "48.2125",
    airport_lon: "-106.615",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1208,
    airport_code: "GHA",
    airport_name: "Noumerate Arpt",
    airport_city_code: "GHA",
    airport_city_name: "Ghardaia",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "32.384106",
    airport_lon: "3.794114",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1209,
    airport_code: "GHB",
    airport_name: "Governors Harbour Municipal Arpt",
    airport_city_code: "GHB",
    airport_city_name: "Governor S Harbour",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.284706",
    airport_lon: "-76.331011",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1210,
    airport_code: "GIB",
    airport_name: "North Front Arpt",
    airport_city_code: "GIB",
    airport_city_name: "Gibraltar",
    airport_country_name: "GIBRALTAR",
    airport_country_code: "GI",
    airport_timezone: "1",
    airport_lat: "36.151219",
    airport_lon: "-5.349664",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1211,
    airport_code: "GIC",
    airport_name: "Boigu Island Arpt",
    airport_city_code: "GIC",
    airport_city_name: "Boigu Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-9.23278",
    airport_lon: "142.218",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1212,
    airport_code: "GIF",
    airport_name: "Gilbert Fld",
    airport_city_code: "GIF",
    airport_city_name: "Winter Haven",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1213,
    airport_code: "GIL",
    airport_name: "Gilgit Arpt",
    airport_city_code: "GIL",
    airport_city_name: "Gilgit",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "35.918786",
    airport_lon: "74.333644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1214,
    airport_code: "GIR",
    airport_name: "Girardot Arpt",
    airport_city_code: "GIR",
    airport_city_name: "Girardot",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-100",
    airport_lat: "4.2761",
    airport_lon: "-74.7958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1215,
    airport_code: "GIS",
    airport_name: "Gisborne Arpt",
    airport_city_code: "GIS",
    airport_city_name: "Gisborne",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-38.663333",
    airport_lon: "177.978333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1216,
    airport_code: "GIZ",
    airport_name: "Gizan Aprt",
    airport_city_code: "GIZ",
    airport_city_name: "Gizan",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "16.901111",
    airport_lon: "42.585833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1217,
    airport_code: "GJT",
    airport_name: "Walker Field Arpt",
    airport_city_code: "GJT",
    airport_city_name: "Grand Junction",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "39.122413",
    airport_lon: "-108.526735",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1218,
    airport_code: "GKA",
    airport_name: "Goroka Arpt",
    airport_city_code: "GKA",
    airport_city_name: "Goroka",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-6.081689",
    airport_lon: "145.391881",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1219,
    airport_code: "GKD",
    airport_name: "GOKCEADA",
    airport_city_code: "GKD",
    airport_city_name: "GOKCEADA",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "1",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1220,
    airport_code: "GKT",
    airport_name: "Gatlinburg Arpt",
    airport_city_code: "GKT",
    airport_city_name: "Gatlinburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1221,
    airport_code: "PIK",
    airport_name: "Prestwick Arpt",
    airport_city_code: "GLA",
    airport_city_name: "Glasgow",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.509444",
    airport_lon: "-4.586667",
    airport_num_airports: 3,
  },
  {
    airport_id: 1222,
    airport_code: "ZGG",
    airport_name: "Glasgow ScotRail",
    airport_city_code: "GLA",
    airport_city_name: "Glasgow",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1223,
    airport_code: "GLA",
    airport_name: "Glasgow Intl",
    airport_city_code: "GLA",
    airport_city_name: "Glasgow",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.871944",
    airport_lon: "-4.433056",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1224,
    airport_code: "GLD",
    airport_name: "Goodland Municipal",
    airport_city_code: "GLD",
    airport_city_name: "Goodland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.370621",
    airport_lon: "-101.698992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1225,
    airport_code: "GLE",
    airport_name: "Gainesville Municipal Arpt",
    airport_city_code: "GLE",
    airport_city_name: "Gainesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "33.6514",
    airport_lon: "-97.1969",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1226,
    airport_code: "GLH",
    airport_name: "Greenville Municipal",
    airport_city_code: "GLH",
    airport_city_name: "Greenville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.4829",
    airport_lon: "-90.9856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1227,
    airport_code: "GLI",
    airport_name: "Glen Innes Arpt",
    airport_city_code: "GLI",
    airport_city_name: "Glen Innes",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-29.6767",
    airport_lon: "151.6917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1228,
    airport_code: "XKZ",
    airport_name: "Vinstra Rail Station",
    airport_city_code: "GLL",
    airport_city_name: "Gol City",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1229,
    airport_code: "XGP",
    airport_name: "Dombas Rail Station",
    airport_city_code: "GLL",
    airport_city_name: "Gol City",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1230,
    airport_code: "GLL",
    airport_name: "Klanten Arpt",
    airport_city_code: "GLL",
    airport_city_name: "Gol City",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1231,
    airport_code: "GLO",
    airport_name: "Staverton Arpt",
    airport_city_code: "GLO",
    airport_city_name: "Gloucester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.894167",
    airport_lon: "-2.167222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1232,
    airport_code: "GLR",
    airport_name: "Otsego Arpt",
    airport_city_code: "GLR",
    airport_city_name: "Gaylord",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1233,
    airport_code: "GLS",
    airport_name: "Scholes Field",
    airport_city_code: "GLS",
    airport_city_name: "Galveston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.265322",
    airport_lon: "-94.860406",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1234,
    airport_code: "GLT",
    airport_name: "Gladstone Airport",
    airport_city_code: "GLT",
    airport_city_name: "Gladstone",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-23.8697",
    airport_lon: "151.223",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1235,
    airport_code: "GLW",
    airport_name: "Glasgow Municipal Arpt",
    airport_city_code: "GLW",
    airport_city_name: "Glasgow",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1236,
    airport_code: "GME",
    airport_name: "Gomel Arpt",
    airport_city_code: "GME",
    airport_city_name: "Gomel",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "52.527022",
    airport_lon: "31.016692",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1237,
    airport_code: "GMN",
    airport_name: "Greymouth Arpt",
    airport_city_code: "GMN",
    airport_city_name: "Greymouth",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "-100",
    airport_lat: "-42.4617",
    airport_lon: "171.19",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1238,
    airport_code: "GMR",
    airport_name: "Gambier Island Arpt",
    airport_city_code: "GMR",
    airport_city_name: "Gambier Island",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-9",
    airport_lat: "-23.079861",
    airport_lon: "-134.890333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1239,
    airport_code: "GMZ",
    airport_name: "La Gomera Arpt",
    airport_city_code: "GMZ",
    airport_city_name: "San Sebastian De La Gomera",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "28.0296",
    airport_lon: "-17.2146",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1240,
    airport_code: "GNA",
    airport_name: "Grodna Arpt",
    airport_city_code: "GNA",
    airport_city_name: "Grodna",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "53.602",
    airport_lon: "24.0538",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1241,
    airport_code: "GNB",
    airport_name: "Saint Geoirs Arpt",
    airport_city_code: "GNB",
    airport_city_name: "Grenoble",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.362944",
    airport_lon: "5.329375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1242,
    airport_code: "GND",
    airport_name: "Port Saline Intl",
    airport_city_code: "GND",
    airport_city_name: "Grenada",
    airport_country_name: "DOMINICA",
    airport_country_code: "DM",
    airport_timezone: "-4",
    airport_lat: "12.004247",
    airport_lon: "-61.786192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1243,
    airport_code: "GNS",
    airport_name: "Gunungsitoli Arpt",
    airport_city_code: "GNS",
    airport_city_name: "Gunungsitoli",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "1.166381",
    airport_lon: "97.704681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1244,
    airport_code: "GNT",
    airport_name: "Grants Milan Municipal Arpt",
    airport_city_code: "GNT",
    airport_city_name: "Grants",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.167286",
    airport_lon: "-107.901989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1245,
    airport_code: "GNV",
    airport_name: "Gainesville Regional",
    airport_city_code: "GNV",
    airport_city_name: "Gainesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "29.690056",
    airport_lon: "-82.271778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1246,
    airport_code: "GOA",
    airport_name: "Christoforo Colombo",
    airport_city_code: "GOA",
    airport_city_name: "Genoa",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.413333",
    airport_lon: "8.8375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1247,
    airport_code: "GOH",
    airport_name: "Nuuk Arpt",
    airport_city_code: "GOH",
    airport_city_name: "Nuuk",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "64.190922",
    airport_lon: "-51.678064",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1248,
    airport_code: "GOI",
    airport_name: "Dabolim Arpt",
    airport_city_code: "GOI",
    airport_city_name: "Goa In",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "15.380833",
    airport_lon: "73.831422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1249,
    airport_code: "GOJ",
    airport_name: "Nizhniy Novgorod Arpt",
    airport_city_code: "GOJ",
    airport_city_name: "Nizhniy Novgorod",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "56.230119",
    airport_lon: "43.784042",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1250,
    airport_code: "GON",
    airport_name: "Groton New London Arpt",
    airport_city_code: "GON",
    airport_city_name: "New London",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.330056",
    airport_lon: "-72.045139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1251,
    airport_code: "GOO",
    airport_name: "Goondiwindi Arpt",
    airport_city_code: "GOO",
    airport_city_name: "Goondiwindi",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-28.5217",
    airport_lon: "150.32",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1252,
    airport_code: "GOP",
    airport_name: "Gorakhpur Arpt",
    airport_city_code: "GOP",
    airport_city_name: "Gorakhpur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.739708",
    airport_lon: "83.449708",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1253,
    airport_code: "GOQ",
    airport_name: "Golmud Arpt",
    airport_city_code: "GOQ",
    airport_city_name: "Golmud",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.633",
    airport_lon: "98.867",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1254,
    airport_code: "GSE",
    airport_name: "Saeve Arpt",
    airport_city_code: "GOT",
    airport_city_name: "Gothenburg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "57.774722",
    airport_lon: "11.870372",
    airport_num_airports: 3,
  },
  {
    airport_id: 1255,
    airport_code: "GOT",
    airport_name: "Landvetter Arpt",
    airport_city_code: "GOT",
    airport_city_name: "Gothenburg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "57.662836",
    airport_lon: "12.279819",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1256,
    airport_code: "XWL",
    airport_name: "Gothenburg Rail",
    airport_city_code: "GOT",
    airport_city_name: "Gothenburg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1257,
    airport_code: "GOU",
    airport_name: "Garoua Arpt",
    airport_city_code: "GOU",
    airport_city_name: "Garoua",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "1",
    airport_lat: "9.335892",
    airport_lon: "13.370103",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1258,
    airport_code: "GOV",
    airport_name: "Nhulunbuy Arpt",
    airport_city_code: "GOV",
    airport_city_name: "Gove",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-12.2694",
    airport_lon: "136.818",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1259,
    airport_code: "GPA",
    airport_name: "Araxos Arpt",
    airport_city_code: "GPA",
    airport_city_name: "Patras",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "38.151111",
    airport_lon: "21.425556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1260,
    airport_code: "GPS",
    airport_name: "Baltra Arpt",
    airport_city_code: "GPS",
    airport_city_name: "Galapagos Is",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-6",
    airport_lat: "-0.453758",
    airport_lon: "-90.265914",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1261,
    airport_code: "GPT",
    airport_name: "Biloxi Regional Arpt",
    airport_city_code: "GPT",
    airport_city_name: "Gulfport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.407278",
    airport_lon: "-89.070111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1262,
    airport_code: "GPZ",
    airport_name: "Itasca County",
    airport_city_code: "GPZ",
    airport_city_name: "Grand Rapids",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "47.211111",
    airport_lon: "-93.509722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1263,
    airport_code: "GQQ",
    airport_name: "Galion Municipal Arpt",
    airport_city_code: "GQQ",
    airport_city_name: "Galion",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1264,
    airport_code: "GRB",
    airport_name: "Austin Straubel Fld",
    airport_city_code: "GRB",
    airport_city_name: "Green Bay",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.485072",
    airport_lon: "-88.129589",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1265,
    airport_code: "GRI",
    airport_name: "Hall Cty Regional",
    airport_city_code: "GRI",
    airport_city_name: "Grand Island",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.9675",
    airport_lon: "-98.3096",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1266,
    airport_code: "GRJ",
    airport_name: "George Arpt",
    airport_city_code: "GRJ",
    airport_city_name: "George",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-34.005553",
    airport_lon: "22.378889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1267,
    airport_code: "GRO",
    airport_name: "Costa Brava Arpt",
    airport_city_code: "GRO",
    airport_city_name: "Gerona",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "41.900969",
    airport_lon: "2.760547",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1268,
    airport_code: "GRQ",
    airport_name: "Eelde Arpt",
    airport_city_code: "GRQ",
    airport_city_name: "Groningen",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "53.11972",
    airport_lon: "6.579444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1269,
    airport_code: "GRR",
    airport_name: "Kent County Intl",
    airport_city_code: "GRR",
    airport_city_name: "Grand Rapids",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.880833",
    airport_lon: "-85.522806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1270,
    airport_code: "GRS",
    airport_name: "Baccarini Arpt",
    airport_city_code: "GRS",
    airport_city_name: "Grosseto",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "42.759747",
    airport_lon: "11.071897",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1271,
    airport_code: "GRV",
    airport_name: "Groznyj",
    airport_city_code: "GRV",
    airport_city_name: "Groznyj",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "43.2981",
    airport_lon: "45.7841",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1272,
    airport_code: "GRW",
    airport_name: "Graciosa Arpt",
    airport_city_code: "GRW",
    airport_city_name: "Graciosa Island",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "39.092169",
    airport_lon: "-28.029847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1273,
    airport_code: "GRX",
    airport_name: "Granada Arpt",
    airport_city_code: "GRX",
    airport_city_name: "Granada",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "37.188731",
    airport_lon: "-3.777356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1274,
    airport_code: "GRZ",
    airport_name: "Thalerhof Arpt",
    airport_city_code: "GRZ",
    airport_city_name: "Graz",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "1",
    airport_lat: "46.991067",
    airport_lon: "15.439628",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1275,
    airport_code: "GSB",
    airport_name: "Seymour Johnson AFB",
    airport_city_code: "GSB",
    airport_city_name: "Goldsboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.339383",
    airport_lon: "-77.960589",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1276,
    airport_code: "GSO",
    airport_name: "Piedmont Triad Intl",
    airport_city_code: "GSO",
    airport_city_name: "Greensboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "36.09775",
    airport_lon: "-79.937306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1277,
    airport_code: "SPA",
    airport_name: "Downtown Memorial",
    airport_city_code: "GSP",
    airport_city_name: "Greenville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1278,
    airport_code: "GSP",
    airport_name: "Greenville Spartanburg Arpt",
    airport_city_code: "GSP",
    airport_city_name: "Greenville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.895556",
    airport_lon: "-82.218889",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1279,
    airport_code: "GMU",
    airport_name: "Greenville Downtown Arpt",
    airport_city_code: "GSP",
    airport_city_name: "Greenville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "34.8481",
    airport_lon: "-82.35",
    airport_num_airports: 3,
  },
  {
    airport_id: 1280,
    airport_code: "GST",
    airport_name: "Gustavus Arpt",
    airport_city_code: "GST",
    airport_city_name: "Glacier Bay",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "58.4253",
    airport_lon: "-135.707",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1281,
    airport_code: "GSY",
    airport_name: "Binbrook Arpt",
    airport_city_code: "GSY",
    airport_city_name: "Grimsby",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1282,
    airport_code: "GTE",
    airport_name: "Alyangula Arpt",
    airport_city_code: "GTE",
    airport_city_name: "Groote Eylandt",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-13.975",
    airport_lon: "136.46",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1283,
    airport_code: "GTF",
    airport_name: "Great Falls Intl Arpt",
    airport_city_code: "GTF",
    airport_city_name: "Great Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "47.482",
    airport_lon: "-111.370689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1284,
    airport_code: "GTI",
    airport_name: "Guettin Arpt",
    airport_city_code: "GTI",
    airport_city_name: "Guettin",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.383333",
    airport_lon: "13.325278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1285,
    airport_code: "GTY",
    airport_name: "Gettysburg Arpt",
    airport_city_code: "GTY",
    airport_city_name: "Gettysburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1286,
    airport_code: "GUA",
    airport_name: "La Aurora Arpt",
    airport_city_code: "GUA",
    airport_city_name: "Guatemala City",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-6",
    airport_lat: "14.583272",
    airport_lon: "-90.527475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1287,
    airport_code: "GUC",
    airport_name: "Gunnison Cty",
    airport_city_code: "GUC",
    airport_city_name: "Gunnison",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.533889",
    airport_lon: "-106.933056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1288,
    airport_code: "GUF",
    airport_name: "Edwards Arpt",
    airport_city_code: "GUF",
    airport_city_name: "Gulf Shores",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1289,
    airport_code: "GUH",
    airport_name: "Gunnedah Airport",
    airport_city_code: "GUH",
    airport_city_name: "Gunnedah",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-30.9611",
    airport_lon: "150.2506",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1290,
    airport_code: "UAM",
    airport_name: "Anderson Air Force Base",
    airport_city_code: "GUM",
    airport_city_name: "Guam",
    airport_country_name: "GUAM",
    airport_country_code: "GU",
    airport_timezone: "10",
    airport_lat: "13.583953",
    airport_lon: "144.930025",
    airport_num_airports: 2,
  },
  {
    airport_id: 1291,
    airport_code: "GUM",
    airport_name: "Antonio B Won Pat Intl",
    airport_city_code: "GUM",
    airport_city_name: "Guam",
    airport_country_name: "GUAM",
    airport_country_code: "GU",
    airport_timezone: "10",
    airport_lat: "13.48345",
    airport_lon: "144.795983",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1292,
    airport_code: "GUP",
    airport_name: "Gallup Municipal",
    airport_city_code: "GUP",
    airport_city_name: "Gallup",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.511058",
    airport_lon: "-108.789308",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1293,
    airport_code: "GUR",
    airport_name: "Gurney Arpt",
    airport_city_code: "GUR",
    airport_city_name: "Alotau",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-10.3115",
    airport_lon: "150.334",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1294,
    airport_code: "GUT",
    airport_name: "Guetersloh Arpt",
    airport_city_code: "GUT",
    airport_city_name: "Gutersloh",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.922833",
    airport_lon: "8.306333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1295,
    airport_code: "GUW",
    airport_name: "Atyrau Arpt",
    airport_city_code: "GUW",
    airport_city_name: "Atyrau",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "5",
    airport_lat: "47.121944",
    airport_lon: "51.821389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1296,
    airport_code: "GUY",
    airport_name: "Guymon Municipal Arpt",
    airport_city_code: "GUY",
    airport_city_name: "Guymon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "36.6853",
    airport_lon: "-101.5078",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1297,
    airport_code: "GVA",
    airport_name: "Geneve Cointrin",
    airport_city_code: "GVA",
    airport_city_name: "Geneva",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "46.238064",
    airport_lon: "6.10895",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1298,
    airport_code: "GVR",
    airport_name: "Governador Valadares Arpt",
    airport_city_code: "GVR",
    airport_city_name: "Governador Valadares",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-18.8952",
    airport_lon: "-41.9822",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1299,
    airport_code: "XFU",
    airport_name: "Tierp Rail Station",
    airport_city_code: "GVX",
    airport_city_name: "Gavle",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1300,
    airport_code: "GVX",
    airport_name: "Sandviken Arpt",
    airport_city_code: "GVX",
    airport_city_name: "Gavle",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "60.593333",
    airport_lon: "16.951389",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1301,
    airport_code: "QYU",
    airport_name: "Gavle Rail Station",
    airport_city_code: "GVX",
    airport_city_name: "Gavle",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1302,
    airport_code: "GWE",
    airport_name: "Gweru Airport",
    airport_city_code: "GWE",
    airport_city_name: "Gweru",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "2",
    airport_lat: "-19.436394",
    airport_lon: "29.861911",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1303,
    airport_code: "GWL",
    airport_name: "Gwalior Airport",
    airport_city_code: "GWL",
    airport_city_name: "Gwalior",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.293336",
    airport_lon: "78.227753",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1304,
    airport_code: "GWO",
    airport_name: "Leflore Arpt",
    airport_city_code: "GWO",
    airport_city_name: "Greenwood",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.494328",
    airport_lon: "-90.084706",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1305,
    airport_code: "GWS",
    airport_name: "Glenwood Springs Arpt",
    airport_city_code: "GWS",
    airport_city_name: "Glenwood Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1306,
    airport_code: "GWT",
    airport_name: "Westerland Airport",
    airport_city_code: "GWT",
    airport_city_name: "Westerland",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.91325",
    airport_lon: "8.340472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1307,
    airport_code: "GWY",
    airport_name: "Carnmore Arpt",
    airport_city_code: "GWY",
    airport_city_name: "Galway",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "53.300175",
    airport_lon: "-8.941592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1308,
    airport_code: "GXQ",
    airport_name: "Teniente Vidal Arpt",
    airport_city_code: "GXQ",
    airport_city_name: "Coyhaique",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-45.594211",
    airport_lon: "-72.106133",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1309,
    airport_code: "GXY",
    airport_name: "Weld County Regional Arpt",
    airport_city_code: "GXY",
    airport_city_name: "Greeley",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1310,
    airport_code: "GYE",
    airport_name: "Simon Bolivar Airport",
    airport_city_code: "GYE",
    airport_city_name: "Guayaquil",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-5",
    airport_lat: "-2.157419",
    airport_lon: "-79.883558",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1311,
    airport_code: "GYI",
    airport_name: "Gisenyi Arpt",
    airport_city_code: "GYI",
    airport_city_name: "Gisenyi",
    airport_country_name: "RWANDA",
    airport_country_code: "RW",
    airport_timezone: "2",
    airport_lat: "-1.677203",
    airport_lon: "29.258875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1312,
    airport_code: "GYM",
    airport_name: "Gen Jose M Yanez Arpt",
    airport_city_code: "GYM",
    airport_city_name: "Guaymas",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "27.968983",
    airport_lon: "-110.925169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1313,
    airport_code: "GYN",
    airport_name: "Santa Genoveva",
    airport_city_code: "GYN",
    airport_city_name: "Goiania",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-16.632033",
    airport_lon: "-49.220686",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1314,
    airport_code: "GYP",
    airport_name: "Gympie Arpt",
    airport_city_code: "GYP",
    airport_city_name: "Gympie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-26.2833",
    airport_lon: "152.7",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1315,
    airport_code: "GYR",
    airport_name: "Litchfield Goodyear Arpt",
    airport_city_code: "GYR",
    airport_city_name: "Goodyear",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "33.4237",
    airport_lon: "-112.3745",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1316,
    airport_code: "GYS",
    airport_name: "Guang Yuan Arpt",
    airport_city_code: "GYS",
    airport_city_name: "Guang Yuan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "32.3911",
    airport_lon: "105.702",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1317,
    airport_code: "GYY",
    airport_name: "Gary International Arpt",
    airport_city_code: "GYY",
    airport_city_name: "Gary",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.6163",
    airport_lon: "-87.4128",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1318,
    airport_code: "GZA",
    airport_name: "Gaza International Arpt",
    airport_city_code: "GZA",
    airport_city_name: "Gaza",
    airport_country_name: "PALESTINIAN TERRITORY, OCCUPIED",
    airport_country_code: "PS",
    airport_timezone: "2",
    airport_lat: "31.246389",
    airport_lon: "34.276111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1319,
    airport_code: "GZM",
    airport_name: "Gozo Arpt",
    airport_city_code: "GZM",
    airport_city_name: "Gozo",
    airport_country_name: "MALTA",
    airport_country_code: "MT",
    airport_timezone: "1",
    airport_lat: "36.027222",
    airport_lon: "14.272778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1320,
    airport_code: "GZO",
    airport_name: "Gizo Arpt",
    airport_city_code: "GZO",
    airport_city_name: "Gizo",
    airport_country_name: "SOLOMON ISLANDS",
    airport_country_code: "SB",
    airport_timezone: "11",
    airport_lat: "-8.09778",
    airport_lon: "156.864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1321,
    airport_code: "GZP",
    airport_name: "Gazipasa Arpt",
    airport_city_code: "GZP",
    airport_city_name: "Gazipasa",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "36.2993",
    airport_lon: "32.3014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1322,
    airport_code: "GZT",
    airport_name: "Gaziantep Arpt",
    airport_city_code: "GZT",
    airport_city_name: "Gaziantep",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "36.947183",
    airport_lon: "37.478683",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1323,
    airport_code: "HAC",
    airport_name: "Hachijo Jima Arpt",
    airport_city_code: "HAC",
    airport_city_name: "Hachijo Jima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.115",
    airport_lon: "139.785833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1324,
    airport_code: "HAD",
    airport_name: "Halmstad Arpt",
    airport_city_code: "HAD",
    airport_city_name: "Halmstad",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "56.691128",
    airport_lon: "12.820211",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1325,
    airport_code: "XYM",
    airport_name: "Falkenberg Rail Station",
    airport_city_code: "HAD",
    airport_city_name: "Halmstad",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1326,
    airport_code: "ZVR",
    airport_name: "Hanover HBF Railway Service",
    airport_city_code: "HAJ",
    airport_city_name: "Hanover",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "52.376",
    airport_lon: "9.741",
    airport_num_airports: 2,
  },
  {
    airport_id: 1327,
    airport_code: "HAJ",
    airport_name: "Hanover Arpt",
    airport_city_code: "HAJ",
    airport_city_name: "Hanover",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "52.461056",
    airport_lon: "9.685078",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1328,
    airport_code: "HAK",
    airport_name: "Haikou Arpt",
    airport_city_code: "HAK",
    airport_city_name: "Haikou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "19.934856",
    airport_lon: "110.458961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1329,
    airport_code: "ZMB",
    airport_name: "Hamburg Railway Service",
    airport_city_code: "HAM",
    airport_city_name: "Hamburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "53.58",
    airport_lon: "9.98",
    airport_num_airports: 3,
  },
  {
    airport_id: 1330,
    airport_code: "HAM",
    airport_name: "Fuhlsbuettel Arpt",
    airport_city_code: "HAM",
    airport_city_name: "Hamburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.630389",
    airport_lon: "9.988228",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1331,
    airport_code: "LBC",
    airport_name: "Blankensee Arpt",
    airport_city_code: "HAM",
    airport_city_name: "Hamburg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.805367",
    airport_lon: "10.719222",
    airport_num_airports: 3,
  },
  {
    airport_id: 1332,
    airport_code: "HAN",
    airport_name: "Noibai Arpt",
    airport_city_code: "HAN",
    airport_city_name: "Hanoi",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "21.221192",
    airport_lon: "105.807178",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1333,
    airport_code: "HAP",
    airport_name: "Long Island Arpt",
    airport_city_code: "HAP",
    airport_city_name: "Long Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1334,
    airport_code: "MDT",
    airport_name: "Harrisburg Intl",
    airport_city_code: "HAR",
    airport_city_name: "Harrisburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.193494",
    airport_lon: "-76.763403",
    airport_num_airports: 1,
  },
  {
    airport_id: 1335,
    airport_code: "HAS",
    airport_name: "Hail Arpt",
    airport_city_code: "HAS",
    airport_city_name: "Hail",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "27.437917",
    airport_lon: "41.686292",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1336,
    airport_code: "HAU",
    airport_name: "Karmoy Arpt",
    airport_city_code: "HAU",
    airport_city_name: "Haugesund",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "59.345267",
    airport_lon: "5.208364",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1337,
    airport_code: "HAV",
    airport_name: "Jose Marti Intl Arpt",
    airport_city_code: "HAV",
    airport_city_name: "Havana",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "22.989153",
    airport_lon: "-82.409086",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1338,
    airport_code: "HBA",
    airport_name: "Hobart International Arpt",
    airport_city_code: "HBA",
    airport_city_name: "Hobart",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-42.836111",
    airport_lon: "147.510278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1339,
    airport_code: "HBE",
    airport_name: "Borg El Arab Arpt",
    airport_city_code: "HBE",
    airport_city_name: "Borg El Arab",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "30.917669",
    airport_lon: "29.696408",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1340,
    airport_code: "HBG",
    airport_name: "Bobby L Chain Municipal",
    airport_city_code: "HBG",
    airport_city_name: "Hattiesburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1341,
    airport_code: "HBI",
    airport_name: "Harbour Island Arpt",
    airport_city_code: "HBI",
    airport_city_name: "Harbour Island",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1342,
    airport_code: "HBT",
    airport_name: "Hafr Albatin Arpt",
    airport_city_code: "HBT",
    airport_city_name: "Hafr Albatin",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "27.900917",
    airport_lon: "45.528194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1343,
    airport_code: "HBX",
    airport_name: "Hubli Arpt",
    airport_city_code: "HBX",
    airport_city_name: "Hubli",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "15.3617",
    airport_lon: "75.0849",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1344,
    airport_code: "HCN",
    airport_name: "Hengchun Arpt",
    airport_city_code: "HCN",
    airport_city_name: "Hengchun",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "22.041075",
    airport_lon: "120.730208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1345,
    airport_code: "HCQ",
    airport_name: "Halls Creek Arpt",
    airport_city_code: "HCQ",
    airport_city_name: "Halls Creek",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-18.233889",
    airport_lon: "127.669722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1346,
    airport_code: "HDB",
    airport_name: "Heidelberg Arpt",
    airport_city_code: "HDB",
    airport_city_name: "Heidelberg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.393333",
    airport_lon: "8.6525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1347,
    airport_code: "HDD",
    airport_name: "Hyderabad",
    airport_city_code: "HDD",
    airport_city_name: "Hyderabad",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "25.3181",
    airport_lon: "68.3661",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1348,
    airport_code: "HDF",
    airport_name: "Heringsdorf Arpt",
    airport_city_code: "HDF",
    airport_city_name: "Heringsdorf",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.878706",
    airport_lon: "14.152347",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1349,
    airport_code: "HDN",
    airport_name: "Hayden Arpt",
    airport_city_code: "HDN",
    airport_city_name: "Hayden",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "40.481181",
    airport_lon: "-107.21766",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1350,
    airport_code: "HDS",
    airport_name: "Hoedspruit Arpt",
    airport_city_code: "HDS",
    airport_city_name: "Hoedspruit",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-24.368642",
    airport_lon: "31.048744",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1351,
    airport_code: "HDY",
    airport_name: "Hat Yai Arpt",
    airport_city_code: "HDY",
    airport_city_name: "Hat Yai",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "6.933206",
    airport_lon: "100.392975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1352,
    airport_code: "HEA",
    airport_name: "Herat Arpt",
    airport_city_code: "HEA",
    airport_city_name: "Herat",
    airport_country_name: "AFGHANISTAN",
    airport_country_code: "AF",
    airport_timezone: "5",
    airport_lat: "34.210017",
    airport_lon: "62.2283",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1353,
    airport_code: "HEH",
    airport_name: "Heho Arpt",
    airport_city_code: "HEH",
    airport_city_name: "Heho",
    airport_country_name: "MYANMAR",
    airport_country_code: "MM",
    airport_timezone: "6",
    airport_lat: "20.747036",
    airport_lon: "96.792044",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1354,
    airport_code: "HEI",
    airport_name: "Heide Arpt",
    airport_city_code: "HEI",
    airport_city_name: "Heide Buesum",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.1533",
    airport_lon: "8.90167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1355,
    airport_code: "HEL",
    airport_name: "Helsinki Arpt",
    airport_city_code: "HEL",
    airport_city_name: "Helsinki",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "60.317222",
    airport_lon: "24.963333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1356,
    airport_code: "HER",
    airport_name: "N Kazantzakis Arpt",
    airport_city_code: "HER",
    airport_city_name: "Heraklion",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "35.339719",
    airport_lon: "25.180297",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1357,
    airport_code: "HET",
    airport_name: "Hohhot Arpt",
    airport_city_code: "HET",
    airport_city_name: "Hohhot",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "40.851422",
    airport_lon: "111.824103",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1358,
    airport_code: "HEZ",
    airport_name: "Natchez Adams Cty",
    airport_city_code: "HEZ",
    airport_city_name: "Natchez",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "31.6133",
    airport_lon: "-91.2967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1359,
    airport_code: "HFA",
    airport_name: "Haifa Arpt",
    airport_city_code: "HFA",
    airport_city_name: "Haifa",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "2",
    airport_lat: "32.809444",
    airport_lon: "35.043056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1360,
    airport_code: "BDL",
    airport_name: "Bradley Intl Arpt",
    airport_city_code: "HFD",
    airport_city_name: "Hartford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.938889",
    airport_lon: "-72.683222",
    airport_num_airports: 2,
  },
  {
    airport_id: 1361,
    airport_code: "HFD",
    airport_name: "Brainard Arpt",
    airport_city_code: "HFD",
    airport_city_name: "Hartford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.736722",
    airport_lon: "-72.649444",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1362,
    airport_code: "HFE",
    airport_name: "Hefei Arpt",
    airport_city_code: "HFE",
    airport_city_name: "Hefei",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.780019",
    airport_lon: "117.298436",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1363,
    airport_code: "HFN",
    airport_name: "Hornafjordur Arpt",
    airport_city_code: "HFN",
    airport_city_name: "Hornafjordur",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "64.295556",
    airport_lon: "-15.227222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1364,
    airport_code: "HFT",
    airport_name: "Hammerfest Arpt",
    airport_city_code: "HFT",
    airport_city_name: "Hammerfest",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "70.679722",
    airport_lon: "23.668889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1365,
    airport_code: "HGA",
    airport_name: "Hargeisa Arpt",
    airport_city_code: "HGA",
    airport_city_name: "Hargeisa",
    airport_country_name: "SOMALIA",
    airport_country_code: "SO",
    airport_timezone: "3",
    airport_lat: "9.518167",
    airport_lon: "44.088758",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1366,
    airport_code: "HGD",
    airport_name: "Hughenden Arpt",
    airport_city_code: "HGD",
    airport_city_name: "Hughenden",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.815",
    airport_lon: "144.225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1367,
    airport_code: "HGH",
    airport_name: "Hangzhou Arpt",
    airport_city_code: "HGH",
    airport_city_name: "Hangzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.229503",
    airport_lon: "120.434453",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1368,
    airport_code: "HGL",
    airport_name: "Helgoland Arpt",
    airport_city_code: "HGL",
    airport_city_name: "Helgoland",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.1853",
    airport_lon: "7.91583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1369,
    airport_code: "HGN",
    airport_name: "Mae Hong Son Arpt",
    airport_city_code: "HGN",
    airport_city_name: "Mae Hongson",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "19.301667",
    airport_lon: "97.975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1370,
    airport_code: "HGR",
    airport_name: "Washington Cty Regional",
    airport_city_code: "HGR",
    airport_city_name: "Hagerstown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.7079",
    airport_lon: "-77.7295",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1371,
    airport_code: "HGU",
    airport_name: "Kagamuga Arpt",
    airport_city_code: "HGU",
    airport_city_name: "Mt Hagen",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-5.826789",
    airport_lon: "144.295861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1372,
    airport_code: "HHA",
    airport_name: "Changsha Huanghua Arpt",
    airport_city_code: "HHA",
    airport_city_name: "Huanghua",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.189158",
    airport_lon: "113.219633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1373,
    airport_code: "HHE",
    airport_name: "Hachinohe Arpt",
    airport_city_code: "HHE",
    airport_city_name: "Hachinohe",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "-100",
    airport_lat: "40.5522",
    airport_lon: "141.4672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1374,
    airport_code: "HHH",
    airport_name: "Hilton Head Municipal",
    airport_city_code: "HHH",
    airport_city_name: "Hilton Head",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.216",
    airport_lon: "-80.752",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1375,
    airport_code: "HHQ",
    airport_name: "Hua Hin Arpt",
    airport_city_code: "HHQ",
    airport_city_name: "Hua Hin",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "12.636225",
    airport_lon: "99.951533",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1376,
    airport_code: "HHR",
    airport_name: "Hawthorne Arpt",
    airport_city_code: "HHR",
    airport_city_name: "Hawthorne",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.922839",
    airport_lon: "-118.335186",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1377,
    airport_code: "HIB",
    airport_name: "Hibbing Chisolm Arpt",
    airport_city_code: "HIB",
    airport_city_name: "Hibbing",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "47.3866",
    airport_lon: "-92.838994",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1378,
    airport_code: "HID",
    airport_name: "Horn Island Arpt",
    airport_city_code: "HID",
    airport_city_name: "Horn Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-10.5864",
    airport_lon: "142.29",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1379,
    airport_code: "HIE",
    airport_name: "Mt washington Regional Arpt",
    airport_city_code: "HIE",
    airport_city_name: "Whitefield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1380,
    airport_code: "HII",
    airport_name: "Lake Havasu City Municipal Airport",
    airport_city_code: "HII",
    airport_city_name: "Lake Havasu Cty",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "34.571111",
    airport_lon: "-114.358278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1381,
    airport_code: "HIJ",
    airport_name: "Hiroshima Airport",
    airport_city_code: "HIJ",
    airport_city_name: "Hiroshima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.436111",
    airport_lon: "132.919444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1382,
    airport_code: "HIN",
    airport_name: "Sacheon Arpt",
    airport_city_code: "HIN",
    airport_city_name: "Chinju",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.088543",
    airport_lon: "128.07037",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1383,
    airport_code: "HIO",
    airport_name: "Portland Hillsboro Arpt",
    airport_city_code: "HIO",
    airport_city_name: "Hillsboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "45.540394",
    airport_lon: "-122.949825",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1384,
    airport_code: "HIR",
    airport_name: "Henderson Intl Arpt",
    airport_city_code: "HIR",
    airport_city_name: "Honiara",
    airport_country_name: "SOLOMON ISLANDS",
    airport_country_code: "SB",
    airport_timezone: "11",
    airport_lat: "-9.428",
    airport_lon: "160.054789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1385,
    airport_code: "HIS",
    airport_name: "Hayman Island Arpt",
    airport_city_code: "HIS",
    airport_city_name: "Hayman Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.066668",
    airport_lon: "148.86667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1386,
    airport_code: "HJR",
    airport_name: "Khajuraho Arpt",
    airport_city_code: "HJR",
    airport_city_name: "Khajuraho",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "24.817197",
    airport_lon: "79.918597",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1387,
    airport_code: "HKA",
    airport_name: "Blytheville Municipal Arpt",
    airport_city_code: "HKA",
    airport_city_name: "Blytheville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1388,
    airport_code: "HKB",
    airport_name: "Healy Lake Arpt",
    airport_city_code: "HKB",
    airport_city_name: "Healy Lake",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "63.8675",
    airport_lon: "-148.968889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1389,
    airport_code: "HKD",
    airport_name: "Hakodate Arpt",
    airport_city_code: "HKD",
    airport_city_name: "Hakodate",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "41.77",
    airport_lon: "140.821944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1390,
    airport_code: "HKG",
    airport_name: "Hong Kong Intl",
    airport_city_code: "HKG",
    airport_city_name: "Hong Kong",
    airport_country_name: "HONG KONG",
    airport_country_code: "HK",
    airport_timezone: "8",
    airport_lat: "22.308919",
    airport_lon: "113.914603",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1391,
    airport_code: "HKK",
    airport_name: "Hokitika Airport",
    airport_city_code: "HKK",
    airport_city_name: "Hokitika",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-42.713611",
    airport_lon: "170.985278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1392,
    airport_code: "HKN",
    airport_name: "Hoskins Arpt",
    airport_city_code: "HKN",
    airport_city_name: "Hoskins",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-5.46217",
    airport_lon: "150.405",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1393,
    airport_code: "HKT",
    airport_name: "Phuket Intl Airport",
    airport_city_code: "HKT",
    airport_city_name: "Phuket",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "8.1132",
    airport_lon: "98.316872",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1394,
    airport_code: "HKY",
    airport_name: "Hickory Municipal",
    airport_city_code: "HKY",
    airport_city_name: "Hickory",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.741147",
    airport_lon: "-81.38955",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1395,
    airport_code: "HLB",
    airport_name: "Hillenbrand",
    airport_city_code: "HLB",
    airport_city_name: "Batesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1396,
    airport_code: "HLD",
    airport_name: "Hailer Arpt",
    airport_city_code: "HLD",
    airport_city_name: "Hailar",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "49.204997",
    airport_lon: "119.825",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1397,
    airport_code: "HLF",
    airport_name: "Hultsfred Arpt",
    airport_city_code: "HLF",
    airport_city_name: "Hultsfred",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "57.525833",
    airport_lon: "15.823333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1398,
    airport_code: "HLM",
    airport_name: "Park Township",
    airport_city_code: "HLM",
    airport_city_name: "Holland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1399,
    airport_code: "HLN",
    airport_name: "Helena Municipal",
    airport_city_code: "HLN",
    airport_city_name: "Helena",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "46.606806",
    airport_lon: "-111.98275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1400,
    airport_code: "HLS",
    airport_name: "St Helens Arpt",
    airport_city_code: "HLS",
    airport_city_name: "St Helens",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1401,
    airport_code: "HLW",
    airport_name: "Hluhluwe Arpt",
    airport_city_code: "HLW",
    airport_city_name: "Hluhluwe",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1402,
    airport_code: "HLY",
    airport_name: "Holyhead Rail Station",
    airport_city_code: "HLY",
    airport_city_name: "Holyhead",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.248097",
    airport_lon: "-4.535339",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1403,
    airport_code: "HLZ",
    airport_name: "Hamilton Arpt",
    airport_city_code: "HLZ",
    airport_city_name: "Hamilton",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-37.866661",
    airport_lon: "175.332056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1404,
    airport_code: "HMA",
    airport_name: "Khanty Mansiysk Arpt",
    airport_city_code: "HMA",
    airport_city_name: "Khanty Mansiysk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "61.028479",
    airport_lon: "69.086067",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1405,
    airport_code: "HMB",
    airport_name: "Mubarak International Arpt",
    airport_city_code: "HMB",
    airport_city_name: "Mubarak International Arpt",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "26.341189",
    airport_lon: "31.742983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1406,
    airport_code: "HME",
    airport_name: "Oued Irara Arpt",
    airport_city_code: "HME",
    airport_city_name: "Hassi Messaoud",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "31.672972",
    airport_lon: "6.140444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1407,
    airport_code: "HMJ",
    airport_name: "(HMJ) Khmelnitskiy Airport, UA",
    airport_city_code: "HMJ",
    airport_city_name: "(HMJ) Khmelnitskiy Airport, UA",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "0",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1408,
    airport_code: "HMO",
    airport_name: "Gen Ignacio Pesqueira Garcia Arpt",
    airport_city_code: "HMO",
    airport_city_name: "Hermosillo",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "29.095858",
    airport_lon: "-111.047858",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1409,
    airport_code: "HMR",
    airport_name: "Hamar Arpt",
    airport_city_code: "HMR",
    airport_city_name: "Hamar",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "60.8181",
    airport_lon: "11.068",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1410,
    airport_code: "HMV",
    airport_name: "Hemavan Flyg",
    airport_city_code: "HMV",
    airport_city_name: "Hemavan",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "65.806111",
    airport_lon: "15.082778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1411,
    airport_code: "HNA",
    airport_name: "Hanamaki Arpt",
    airport_city_code: "HNA",
    airport_city_name: "Morioka",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "39.428611",
    airport_lon: "141.135278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1412,
    airport_code: "HNB",
    airport_name: "Huntingburg Municipal",
    airport_city_code: "HNB",
    airport_city_name: "Huntingburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1413,
    airport_code: "HNH",
    airport_name: "Hoonah Municipal Arpt",
    airport_city_code: "HNH",
    airport_city_name: "Hoonah",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "58.096111",
    airport_lon: "-135.409722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1414,
    airport_code: "HIK",
    airport_name: "Hickam Air Force Base",
    airport_city_code: "HNL",
    airport_city_name: "Honolulu",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "21.3187",
    airport_lon: "-157.9224",
    airport_num_airports: 2,
  },
  {
    airport_id: 1415,
    airport_code: "HNL",
    airport_name: "Honolulu Intl",
    airport_city_code: "HNL",
    airport_city_name: "Honolulu",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "21.318681",
    airport_lon: "-157.922428",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1416,
    airport_code: "HNM",
    airport_name: "Hana Municipal",
    airport_city_code: "HNM",
    airport_city_name: "Hana",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "20.795636",
    airport_lon: "-156.014439",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1417,
    airport_code: "HNS",
    airport_name: "Haines Municipal Arpt",
    airport_city_code: "HNS",
    airport_city_name: "Haines",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.2438",
    airport_lon: "-135.524",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1418,
    airport_code: "HNY",
    airport_name: "Hengyang Arpt",
    airport_city_code: "HNY",
    airport_city_name: "Hengyang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1419,
    airport_code: "HOB",
    airport_name: "Lea County Arpt",
    airport_city_code: "HOB",
    airport_city_name: "Hobbs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.687528",
    airport_lon: "-103.217028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1420,
    airport_code: "HOD",
    airport_name: "Hodeidah Arpt",
    airport_city_code: "HOD",
    airport_city_name: "Hodeidah",
    airport_country_name: "YEMEN",
    airport_country_code: "YE",
    airport_timezone: "3",
    airport_lat: "14.753",
    airport_lon: "42.976336",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1421,
    airport_code: "HOF",
    airport_name: "Alahsa Arpt",
    airport_city_code: "HOF",
    airport_city_name: "Hofuf",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "25.285306",
    airport_lon: "49.485189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1422,
    airport_code: "HOG",
    airport_name: "Frank Pias Arpt",
    airport_city_code: "HOG",
    airport_city_name: "Holguin",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "20.785589",
    airport_lon: "-76.315108",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1423,
    airport_code: "HOI",
    airport_name: "Hao Island Arpt",
    airport_city_code: "HOI",
    airport_city_name: "Hao Island",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-18.074814",
    airport_lon: "-140.945886",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1424,
    airport_code: "HOM",
    airport_name: "Homer Municipal Arpt",
    airport_city_code: "HOM",
    airport_city_name: "Homer",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.645556",
    airport_lon: "-151.476583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1425,
    airport_code: "HON",
    airport_name: "Huron Municipal",
    airport_city_code: "HON",
    airport_city_name: "Huron",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.3852",
    airport_lon: "-98.228542",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1426,
    airport_code: "HOP",
    airport_name: "Hopkinsville Christian Country Arpt",
    airport_city_code: "HOP",
    airport_city_name: "Hopkinsville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.668567",
    airport_lon: "-87.496183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1427,
    airport_code: "HOQ",
    airport_name: "Hof Pirk Arpt",
    airport_city_code: "HOQ",
    airport_city_name: "Hof De",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "50.288836",
    airport_lon: "11.854919",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1428,
    airport_code: "HOR",
    airport_name: "Horta Arpt",
    airport_city_code: "HOR",
    airport_city_name: "Horta",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "38.519894",
    airport_lon: "-28.715872",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1429,
    airport_code: "HOT",
    airport_name: "Memorial Field",
    airport_city_code: "HOT",
    airport_city_name: "Hot Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.478",
    airport_lon: "-93.0962",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1430,
    airport_code: "IAH",
    airport_name: "George Bush Intercontinental",
    airport_city_code: "HOU",
    airport_city_name: "Houston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.984433",
    airport_lon: "-95.341442",
    airport_num_airports: 5,
  },
  {
    airport_id: 1431,
    airport_code: "IWS",
    airport_name: "West Houston",
    airport_city_code: "HOU",
    airport_city_name: "Houston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.818194",
    airport_lon: "-95.672611",
    airport_num_airports: 5,
  },
  {
    airport_id: 1432,
    airport_code: "HOU",
    airport_name: "Houston Hobby Arpt",
    airport_city_code: "HOU",
    airport_city_name: "Houston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.645419",
    airport_lon: "-95.278889",
    airport_num_airports: 5,
    airport_city: "true",
  },
  {
    airport_id: 1433,
    airport_code: "DWH",
    airport_name: "David Wayne Hooks Arpt",
    airport_city_code: "HOU",
    airport_city_name: "Houston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.063746",
    airport_lon: "-95.554276",
    airport_num_airports: 5,
  },
  {
    airport_id: 1434,
    airport_code: "EFD",
    airport_name: "Ellington Field",
    airport_city_code: "HOU",
    airport_city_name: "Houston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.607333",
    airport_lon: "-95.15875",
    airport_num_airports: 5,
  },
  {
    airport_id: 1435,
    airport_code: "HOV",
    airport_name: "Hovden Arpt",
    airport_city_code: "HOV",
    airport_city_name: "Orsta Volda",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "62.18",
    airport_lon: "6.0742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1436,
    airport_code: "HPA",
    airport_name: "Salote Pilolevu Arpt",
    airport_city_code: "HPA",
    airport_city_name: "Ha Apai",
    airport_country_name: "TONGA",
    airport_country_code: "TO",
    airport_timezone: "13",
    airport_lat: "-19.777",
    airport_lon: "-174.341",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1437,
    airport_code: "HPH",
    airport_name: "Catbi Arpt",
    airport_city_code: "HPH",
    airport_city_name: "Haiphong",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "20.819386",
    airport_lon: "106.724989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1438,
    airport_code: "HPN",
    airport_name: "Westchester County Arpt",
    airport_city_code: "HPN",
    airport_city_name: "White Plains",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.066959",
    airport_lon: "-73.707575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1439,
    airport_code: "HPV",
    airport_name: "Princeville Arpt",
    airport_city_code: "HPV",
    airport_city_name: "Princeville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1440,
    airport_code: "HPY",
    airport_name: "Baytown Arpt",
    airport_city_code: "HPY",
    airport_city_name: "Baytown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1441,
    airport_code: "HRB",
    airport_name: "Harbin Arpt",
    airport_city_code: "HRB",
    airport_city_name: "Harbin",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "45.623403",
    airport_lon: "126.250328",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1442,
    airport_code: "HRE",
    airport_name: "Harare Arpt",
    airport_city_code: "HRE",
    airport_city_name: "Harare",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "2",
    airport_lat: "-17.931806",
    airport_lon: "31.092847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1443,
    airport_code: "HRG",
    airport_name: "Hurghada Airport",
    airport_city_code: "HRG",
    airport_city_name: "Hurghada",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "27.178317",
    airport_lon: "33.799436",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1444,
    airport_code: "HRK",
    airport_name: "Kharkov Arpt",
    airport_city_code: "HRK",
    airport_city_name: "Kharkov",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "49.924786",
    airport_lon: "36.289986",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1445,
    airport_code: "HRL",
    airport_name: "Rio Grande Valley Intl Arpt",
    airport_city_code: "HRL",
    airport_city_name: "Harlingen",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "26.2285",
    airport_lon: "-97.654389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1446,
    airport_code: "HRO",
    airport_name: "Boone County Arpt",
    airport_city_code: "HRO",
    airport_city_name: "Harrison",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.261519",
    airport_lon: "-93.154728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1447,
    airport_code: "HRS",
    airport_name: "Harrismith Arpt",
    airport_city_code: "HRS",
    airport_city_name: "Harrismith",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1448,
    airport_code: "XTK",
    airport_name: "Thirsk Rail Station",
    airport_city_code: "HRT",
    airport_city_name: "Harrogate",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1449,
    airport_code: "HRT",
    airport_name: "Linton On Ouse",
    airport_city_code: "HRT",
    airport_city_name: "Harrogate",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-6",
    airport_lat: "30.427803",
    airport_lon: "-86.689278",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1450,
    airport_code: "HRZ",
    airport_name: "Horizontina Arpt",
    airport_city_code: "HRZ",
    airport_city_name: "Horizontina",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1451,
    airport_code: "HSG",
    airport_name: "Saga Arpt",
    airport_city_code: "HSG",
    airport_city_name: "Saga",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.1497",
    airport_lon: "130.302",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1452,
    airport_code: "HSI",
    airport_name: "Hastings Municipal",
    airport_city_code: "HSI",
    airport_city_name: "Hastings",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.6053",
    airport_lon: "-98.4278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1453,
    airport_code: "HSL",
    airport_name: "Huslia Arpt",
    airport_city_code: "HSL",
    airport_city_name: "Huslia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "65.697778",
    airport_lon: "-156.351389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1454,
    airport_code: "HSN",
    airport_name: "Zhoushan Arpt",
    airport_city_code: "HSN",
    airport_city_name: "Zhoushan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.9342",
    airport_lon: "122.362",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1455,
    airport_code: "HSP",
    airport_name: "Ingalls Field",
    airport_city_code: "HSP",
    airport_city_name: "Hot Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1456,
    airport_code: "HST",
    airport_name: "Homestead Municipal",
    airport_city_code: "HST",
    airport_city_name: "Homestead",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "25.48855",
    airport_lon: "-80.383567",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1457,
    airport_code: "HSV",
    airport_name: "Huntsville Intl Arpt",
    airport_city_code: "HSV",
    airport_city_name: "Huntsville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.637194",
    airport_lon: "-86.775056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1458,
    airport_code: "HSZ",
    airport_name: "Hsinchun Arpt",
    airport_city_code: "HSZ",
    airport_city_name: "Hsinchun",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "-100",
    airport_lat: "24.8167",
    airport_lon: "120.9333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1459,
    airport_code: "HTA",
    airport_name: "Chita Arpt",
    airport_city_code: "HTA",
    airport_city_name: "Chita",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "10",
    airport_lat: "52.026317",
    airport_lon: "113.305556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1460,
    airport_code: "HTF",
    airport_name: "Hatfield Arpt",
    airport_city_code: "HTF",
    airport_city_name: "Hatfield",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.765",
    airport_lon: "-0.24833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1461,
    airport_code: "HTG",
    airport_name: "Khatanga Airport",
    airport_city_code: "HTG",
    airport_city_name: "Khatanga Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "71.978058",
    airport_lon: "102.490514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1462,
    airport_code: "HTI",
    airport_name: "Hamilton Island Arpt",
    airport_city_code: "HTI",
    airport_city_name: "Hamilton Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.3581",
    airport_lon: "148.952",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1463,
    airport_code: "HTO",
    airport_name: "East Hampton Arpt",
    airport_city_code: "HTO",
    airport_city_name: "East Hampton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1464,
    airport_code: "HTS",
    airport_name: "Tri State Milton Arpt",
    airport_city_code: "HTS",
    airport_city_name: "Huntington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.3667",
    airport_lon: "-82.558",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1465,
    airport_code: "PMH",
    airport_name: "Portsmith Regional",
    airport_city_code: "HTS",
    airport_city_name: "Huntington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1466,
    airport_code: "HTV",
    airport_name: "Huntsville Arpt",
    airport_city_code: "HTV",
    airport_city_name: "Huntsville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1467,
    airport_code: "HTY",
    airport_name: "Hatay Airport",
    airport_city_code: "HTY",
    airport_city_name: "Hatay",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "36.362778",
    airport_lon: "36.282222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1468,
    airport_code: "HUC",
    airport_name: "Humacao Arpt",
    airport_city_code: "HUC",
    airport_city_name: "Humacao",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1469,
    airport_code: "HUF",
    airport_name: "Hulman Field",
    airport_city_code: "HUF",
    airport_city_name: "Terre Haute",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.451464",
    airport_lon: "-87.307561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1470,
    airport_code: "HUH",
    airport_name: "Huahine Arpt",
    airport_city_code: "HUH",
    airport_city_name: "Huahine",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-16.687242",
    airport_lon: "-151.021667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1471,
    airport_code: "HUI",
    airport_name: "Phu Bai Arpt",
    airport_city_code: "HUI",
    airport_city_name: "Hu PG",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "16.401499",
    airport_lon: "107.702614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1472,
    airport_code: "HUL",
    airport_name: "Houlton International",
    airport_city_code: "HUL",
    airport_city_name: "Houlton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "46.123083",
    airport_lon: "-67.792056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1473,
    airport_code: "HUM",
    airport_name: "Terrebonne Arpt",
    airport_city_code: "HUM",
    airport_city_name: "Houma",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1474,
    airport_code: "HUN",
    airport_name: "Hualien Arpt",
    airport_city_code: "HUN",
    airport_city_name: "Hualien",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "24.023725",
    airport_lon: "121.616906",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1475,
    airport_code: "HUS",
    airport_name: "Hughes Municipal Arpt",
    airport_city_code: "HUS",
    airport_city_name: "Hughes",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "66.039167",
    airport_lon: "-154.264722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1476,
    airport_code: "HUT",
    airport_name: "Hutchinson Municipal",
    airport_city_code: "HUT",
    airport_city_name: "Hutchinson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.0655",
    airport_lon: "-97.8606",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1477,
    airport_code: "HUU",
    airport_name: "Huanuco Arpt",
    airport_city_code: "HUU",
    airport_city_name: "Huanuco",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-9.878811",
    airport_lon: "-76.204797",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1478,
    airport_code: "HUV",
    airport_name: "Hudiksvall Arpt",
    airport_city_code: "HUV",
    airport_city_name: "Hudiksvall",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "61.768092",
    airport_lon: "17.080719",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1479,
    airport_code: "HUX",
    airport_name: "Bahia De Huatulco Arpt",
    airport_city_code: "HUX",
    airport_city_name: "Santa Cruz Huatulco",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "15.775317",
    airport_lon: "-96.262572",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1480,
    airport_code: "HUY",
    airport_name: "Humberside Arpt",
    airport_city_code: "HUY",
    airport_city_name: "Humberside",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.574444",
    airport_lon: "-0.350833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1481,
    airport_code: "HUZ",
    airport_name: "Huizhou Arpt",
    airport_city_code: "HUZ",
    airport_city_name: "Huizhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "23.083332",
    airport_lon: "114.36667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1482,
    airport_code: "HVB",
    airport_name: "Hervey Bay",
    airport_city_code: "HVB",
    airport_city_name: "Hervey Bay",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-25.318889",
    airport_lon: "152.880278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1483,
    airport_code: "HVE",
    airport_name: "Intermediate Arpt",
    airport_city_code: "HVE",
    airport_city_name: "Hanksville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1484,
    airport_code: "HVG",
    airport_name: "Valan Arpt",
    airport_city_code: "HVG",
    airport_city_name: "Honningsvag",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "70.99",
    airport_lon: "25.83",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1485,
    airport_code: "HVN",
    airport_name: "Tweed New Haven Arpt",
    airport_city_code: "HVN",
    airport_city_name: "New Haven",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.26375",
    airport_lon: "-72.886806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1486,
    airport_code: "HVR",
    airport_name: "City County",
    airport_city_code: "HVR",
    airport_city_name: "Havre",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "48.542983",
    airport_lon: "-109.762342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1487,
    airport_code: "HWD",
    airport_name: "Hayward Air Terminal",
    airport_city_code: "HWD",
    airport_city_name: "Hayward",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "37.6589",
    airport_lon: "-122.1217",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1488,
    airport_code: "HWN",
    airport_name: "Hwange Arpt",
    airport_city_code: "HWN",
    airport_city_name: "Hwange",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "-100",
    airport_lat: "-18.6339",
    airport_lon: "27.0081",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1489,
    airport_code: "HYA",
    airport_name: "Barnstable Cty Arpt",
    airport_city_code: "HYA",
    airport_city_name: "Hyannis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.669336",
    airport_lon: "-70.280356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1490,
    airport_code: "HYD",
    airport_name: "Rajiv Gandhi Intl Arpt",
    airport_city_code: "HYD",
    airport_city_name: "Shamshabad",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "17.453117",
    airport_lon: "78.467586",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1491,
    airport_code: "HYN",
    airport_name: "Huangyan Arpt",
    airport_city_code: "HYN",
    airport_city_name: "Huangyan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.5622",
    airport_lon: "121.429",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1492,
    airport_code: "HYR",
    airport_name: "Hayward Municipal",
    airport_city_code: "HYR",
    airport_city_name: "Hayward",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1493,
    airport_code: "HYS",
    airport_name: "Hays Municipal",
    airport_city_code: "HYS",
    airport_city_name: "Hays",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.8422",
    airport_lon: "-99.2732",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1494,
    airport_code: "HZB",
    airport_name: "Merville Calonne Arpt",
    airport_city_code: "HZB",
    airport_city_name: "Hazebrouck",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "50.6167",
    airport_lon: "2.64",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1495,
    airport_code: "HZG",
    airport_name: "Hanzhong Arpt",
    airport_city_code: "HZG",
    airport_city_name: "Hanzhong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "33.0636",
    airport_lon: "107.008",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1496,
    airport_code: "HZK",
    airport_name: "Husavik Arpt",
    airport_city_code: "HZK",
    airport_city_name: "Husavik",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "65.952328",
    airport_lon: "-17.425978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1497,
    airport_code: "IAA",
    airport_name: "Igarka Airport",
    airport_city_code: "IAA",
    airport_city_name: "Igarka Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "67.4372",
    airport_lon: "86.6219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1498,
    airport_code: "IAG",
    airport_name: "Niagara Falls Intl Arpt",
    airport_city_code: "IAG",
    airport_city_name: "Niagara Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.107333",
    airport_lon: "-78.946194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1499,
    airport_code: "IAR",
    airport_name: "Yaroslavl Arpt",
    airport_city_code: "IAR",
    airport_city_name: "Yaroslavl",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "57.560667",
    airport_lon: "40.157369",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1500,
    airport_code: "IAS",
    airport_name: "Iasi Arpt",
    airport_city_code: "IAS",
    airport_city_name: "Iasi",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "47.178492",
    airport_lon: "27.620631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1501,
    airport_code: "IBE",
    airport_name: "Ibague Arpt",
    airport_city_code: "IBE",
    airport_city_name: "Ibague",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "4.421608",
    airport_lon: "-75.1333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1502,
    airport_code: "IBZ",
    airport_name: "Ibiza Airport",
    airport_city_code: "IBZ",
    airport_city_name: "Ibiza",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "38.872858",
    airport_lon: "1.373117",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1503,
    airport_code: "ICT",
    airport_name: "Mid Continent Arpt",
    airport_city_code: "ICT",
    airport_city_name: "Wichita",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.649944",
    airport_lon: "-97.433056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1504,
    airport_code: "IDA",
    airport_name: "Fanning Field",
    airport_city_code: "IDA",
    airport_city_name: "Idaho Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.514556",
    airport_lon: "-112.07075",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1505,
    airport_code: "IDI",
    airport_name: "Indiana County Arpt",
    airport_city_code: "IDI",
    airport_city_name: "Indiana",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1506,
    airport_code: "IDR",
    airport_name: "Devi Ahilya Bai Holkar Arpt",
    airport_city_code: "IDR",
    airport_city_name: "Indore",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "22.721786",
    airport_lon: "75.801086",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1507,
    airport_code: "IEG",
    airport_name: "Babimost Arpt",
    airport_city_code: "IEG",
    airport_city_name: "Zielona",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "52.138517",
    airport_lon: "15.798556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1508,
    airport_code: "IEV",
    airport_name: "Zhulhany Arpt",
    airport_city_code: "IEV",
    airport_city_name: "Kiev",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "50.401694",
    airport_lon: "30.449697",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1509,
    airport_code: "KBP",
    airport_name: "Borispol Arpt",
    airport_city_code: "IEV",
    airport_city_name: "Kiev",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "50.345",
    airport_lon: "30.894722",
    airport_num_airports: 2,
  },
  {
    airport_id: 1510,
    airport_code: "IFJ",
    airport_name: "Isafjordur Arpt",
    airport_city_code: "IFJ",
    airport_city_name: "Isafjordur",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "66.058056",
    airport_lon: "-23.135278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1511,
    airport_code: "IFN",
    airport_name: "Isfahan Arpt",
    airport_city_code: "IFN",
    airport_city_name: "Isfahan",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "32.750836",
    airport_lon: "51.861267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1512,
    airport_code: "IFO",
    airport_name: "Ivano Frankovsk Arpt",
    airport_city_code: "IFO",
    airport_city_name: "Ivano Frankovsk",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.884167",
    airport_lon: "24.686111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1513,
    airport_code: "IFP",
    airport_name: "Laughlin Bullhead Intl Arpt",
    airport_city_code: "IFP",
    airport_city_name: "Bullhead City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.1574",
    airport_lon: "-114.56",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1514,
    airport_code: "IGD",
    airport_name: "I?d?r Havaliman?",
    airport_city_code: "IGD",
    airport_city_name: "I?d?r Havaliman?",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "3",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1515,
    airport_code: "IGH",
    airport_name: "Ingham Arpt",
    airport_city_code: "IGH",
    airport_city_name: "Ingham",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-18.6656",
    airport_lon: "146.1475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1516,
    airport_code: "IGM",
    airport_name: "Mohave County",
    airport_city_code: "IGM",
    airport_city_name: "Kingman",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.2595",
    airport_lon: "-113.938",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1517,
    airport_code: "IGR",
    airport_name: "Iguazu Intl",
    airport_city_code: "IGR",
    airport_city_name: "Iguazu",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-25.737281",
    airport_lon: "-54.473444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1518,
    airport_code: "IGU",
    airport_name: "Cataratas Arpt",
    airport_city_code: "IGU",
    airport_city_name: "Iguassu Falls",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-25.59615",
    airport_lon: "-54.487206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1519,
    airport_code: "IJK",
    airport_name: "Izhevsk Arpt",
    airport_city_code: "IJK",
    airport_city_name: "Izhevsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "56.8281",
    airport_lon: "53.4575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1520,
    airport_code: "IJX",
    airport_name: "Jacksonville Municipal Arpt",
    airport_city_code: "IJX",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.7744",
    airport_lon: "-90.2386",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1521,
    airport_code: "IKK",
    airport_name: "Greater Kankakee Arpt",
    airport_city_code: "IKK",
    airport_city_name: "Kankakee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.071389",
    airport_lon: "-87.846278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1522,
    airport_code: "IKS",
    airport_name: "Tiksi Airport",
    airport_city_code: "IKS",
    airport_city_name: "Tiksi Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "10",
    airport_lat: "71.6977",
    airport_lon: "128.903",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1523,
    airport_code: "IKT",
    airport_name: "Irkutsk Airport",
    airport_city_code: "IKT",
    airport_city_name: "Irkutsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "9",
    airport_lat: "52.268028",
    airport_lon: "104.388975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1524,
    airport_code: "GRK",
    airport_name: "Gray AAF",
    airport_city_code: "ILE",
    airport_city_name: "Killeen",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.06725",
    airport_lon: "-97.828917",
    airport_num_airports: 2,
  },
  {
    airport_id: 1525,
    airport_code: "ILE",
    airport_name: "Killeen Municipal",
    airport_city_code: "ILE",
    airport_city_name: "Killeen",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1526,
    airport_code: "ILF",
    airport_name: "Ilford Rail Station",
    airport_city_code: "ILF",
    airport_city_name: "Ilford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "56.0614",
    airport_lon: "-95.6139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1527,
    airport_code: "ILG",
    airport_name: "Greater Wilmington New Castle",
    airport_city_code: "ILG",
    airport_city_name: "Wilmington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.678722",
    airport_lon: "-75.606528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1528,
    airport_code: "ILM",
    airport_name: "New Hanover Cty Arpt",
    airport_city_code: "ILM",
    airport_city_name: "Wilmington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.270615",
    airport_lon: "-77.902569",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1529,
    airport_code: "ILO",
    airport_name: "Mandurriao Arpt",
    airport_city_code: "ILO",
    airport_city_name: "Iloilo",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "10.713044",
    airport_lon: "122.545297",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1530,
    airport_code: "ILP",
    airport_name: "Ile Des Pins Arpt",
    airport_city_code: "ILP",
    airport_city_name: "Ile Des Pins",
    airport_country_name: "NEW CALEDONIA",
    airport_country_code: "NC",
    airport_timezone: "11",
    airport_lat: "-22.5889",
    airport_lon: "167.456",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1531,
    airport_code: "ILY",
    airport_name: "Islay Arpt",
    airport_city_code: "ILY",
    airport_city_name: "Islay",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.681944",
    airport_lon: "-6.256667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1532,
    airport_code: "ILZ",
    airport_name: "Zilina Arpt",
    airport_city_code: "ILZ",
    airport_city_name: "Zilina",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "1",
    airport_lat: "49.231528",
    airport_lon: "18.6135",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1533,
    airport_code: "IMF",
    airport_name: "Tulihal Arpt",
    airport_city_code: "IMF",
    airport_city_name: "Imphal",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "24.75995",
    airport_lon: "93.896697",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1534,
    airport_code: "IMP",
    airport_name: "Imperatriz Arpt",
    airport_city_code: "IMP",
    airport_city_name: "Imperatriz",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-5.531292",
    airport_lon: "-47.46005",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1535,
    airport_code: "IMT",
    airport_name: "Ford Arpt",
    airport_city_code: "IMT",
    airport_city_name: "Iron Mountain",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "45.818361",
    airport_lon: "-88.114556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1536,
    airport_code: "INA",
    airport_name: "Inta Airport",
    airport_city_code: "INA",
    airport_city_name: "Inta Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1537,
    airport_code: "INC",
    airport_name: "Yinchuan Arpt",
    airport_city_code: "INC",
    airport_city_name: "Yinchuan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "38.481944",
    airport_lon: "106.009167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1538,
    airport_code: "IND",
    airport_name: "Indianapolis Intl Arpt",
    airport_city_code: "IND",
    airport_city_name: "Indianapolis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.717331",
    airport_lon: "-86.294383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1539,
    airport_code: "INI",
    airport_name: "Nis Arpt",
    airport_city_code: "INI",
    airport_city_name: "Nis Rs",
    airport_country_name: "SERBIA",
    airport_country_code: "RS",
    airport_timezone: "1",
    airport_lat: "43.337289",
    airport_lon: "21.853722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1540,
    airport_code: "INL",
    airport_name: "Intl Falls Arpt",
    airport_city_code: "INL",
    airport_city_name: "International Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "48.566186",
    airport_lon: "-93.403067",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1541,
    airport_code: "INN",
    airport_name: "Kranebitten Airport",
    airport_city_code: "INN",
    airport_city_name: "Innsbruck",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "1",
    airport_lat: "47.260219",
    airport_lon: "11.343964",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1542,
    airport_code: "INT",
    airport_name: "Smith Reynolds Arpt",
    airport_city_code: "INT",
    airport_city_name: "Winston-Salem",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "36.133722",
    airport_lon: "-80.222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1543,
    airport_code: "INU",
    airport_name: "Nauru Intl Arpt",
    airport_city_code: "INU",
    airport_city_name: "Nauru Island",
    airport_country_name: "NAURU",
    airport_country_code: "NR",
    airport_timezone: "12",
    airport_lat: "-0.547458",
    airport_lon: "166.9191",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1544,
    airport_code: "ZIV",
    airport_name: "Inverness ScotRail Station",
    airport_city_code: "INV",
    airport_city_name: "Inverness",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1545,
    airport_code: "INV",
    airport_name: "Inverness Arpt",
    airport_city_code: "INV",
    airport_city_name: "Inverness",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "57.5425",
    airport_lon: "-4.0475",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1546,
    airport_code: "INW",
    airport_name: "Winslow Municipal",
    airport_city_code: "INW",
    airport_city_name: "Winslow",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.021917",
    airport_lon: "-110.722528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1547,
    airport_code: "IOA",
    airport_name: "Ioannina Arpt",
    airport_city_code: "IOA",
    airport_city_name: "Ioannina",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.696388",
    airport_lon: "20.8225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1548,
    airport_code: "IOM",
    airport_name: "Ronaldsway Arpt",
    airport_city_code: "IOM",
    airport_city_name: "Isle Of Man",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "54.083333",
    airport_lon: "-4.623889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1549,
    airport_code: "IOS",
    airport_name: "Eduardo Gomes Airport",
    airport_city_code: "IOS",
    airport_city_name: "Ilheus",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-14.815964",
    airport_lon: "-39.033197",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1550,
    airport_code: "IOW",
    airport_name: "Iowa City Municipal Airport",
    airport_city_code: "IOW",
    airport_city_name: "Iowa City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1551,
    airport_code: "IPC",
    airport_name: "Mataveri Intl Arpt",
    airport_city_code: "IPC",
    airport_city_name: "Easter Island",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-6",
    airport_lat: "-27.164792",
    airport_lon: "-109.421831",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1552,
    airport_code: "IPH",
    airport_name: "Ipoh Arpt",
    airport_city_code: "IPH",
    airport_city_name: "Ipoh",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.567972",
    airport_lon: "101.092194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1553,
    airport_code: "IPI",
    airport_name: "San Luis Arpt",
    airport_city_code: "IPI",
    airport_city_name: "Ipiales",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "0.861925",
    airport_lon: "-77.671764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1554,
    airport_code: "IPL",
    airport_name: "Imperial County",
    airport_city_code: "IPL",
    airport_city_name: "El Centro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "32.834219",
    airport_lon: "-115.578744",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1555,
    airport_code: "IPN",
    airport_name: "Usiminas Arpt",
    airport_city_code: "IPN",
    airport_city_name: "Ipatinga",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-19.470722",
    airport_lon: "-42.487583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1556,
    airport_code: "IPT",
    airport_name: "Williamsport Lycoming Municipal",
    airport_city_code: "IPT",
    airport_city_name: "Williamsport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.241836",
    airport_lon: "-76.921094",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1557,
    airport_code: "IPW",
    airport_name: "Ipswitch Rail Station",
    airport_city_code: "IPW",
    airport_city_name: "Ipswich",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1558,
    airport_code: "IQQ",
    airport_name: "Cavancha Chucumata Arpt",
    airport_city_code: "IQQ",
    airport_city_name: "Iquique",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-20.535222",
    airport_lon: "-70.181275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1559,
    airport_code: "IQT",
    airport_name: "C F Secada Arpt",
    airport_city_code: "IQT",
    airport_city_name: "Iquitos",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-3.784739",
    airport_lon: "-73.308806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1560,
    airport_code: "IRG",
    airport_name: "Lockhart River Arpt",
    airport_city_code: "IRG",
    airport_city_name: "Lockhart",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-12.7869",
    airport_lon: "143.305",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1561,
    airport_code: "IRJ",
    airport_name: "La Rioja Arpt",
    airport_city_code: "IRJ",
    airport_city_name: "La Rioja",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-29.381636",
    airport_lon: "-66.795839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1562,
    airport_code: "IRK",
    airport_name: "Kirksville Municipal",
    airport_city_code: "IRK",
    airport_city_name: "Kirksville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.0935",
    airport_lon: "-92.5449",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1563,
    airport_code: "IRS",
    airport_name: "Kirsch Municipal",
    airport_city_code: "IRS",
    airport_city_name: "Sturgis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1564,
    airport_code: "ISA",
    airport_name: "Mount Isa Arpt",
    airport_city_code: "ISA",
    airport_city_name: "Mount Isa",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.663889",
    airport_lon: "139.488611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1565,
    airport_code: "ISB",
    airport_name: "Islamabad Intl",
    airport_city_code: "ISB",
    airport_city_name: "Islamabad",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "33.616653",
    airport_lon: "73.099233",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1566,
    airport_code: "ISC",
    airport_name: "St Marys Arpt",
    airport_city_code: "ISC",
    airport_city_name: "Isles Of Scilly",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "49.919",
    airport_lon: "-6.3075",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1567,
    airport_code: "TSO",
    airport_name: "Tresco Arpt",
    airport_city_code: "ISC",
    airport_city_name: "Isles Of Scilly",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "49.945556",
    airport_lon: "-6.331389",
    airport_num_airports: 2,
  },
  {
    airport_id: 1568,
    airport_code: "ISE",
    airport_name: "Isparta Suleyman Demirel Airport",
    airport_city_code: "ISE",
    airport_city_name: "Isparta",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.8554",
    airport_lon: "30.3684",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1569,
    airport_code: "ISG",
    airport_name: "Ishigaki Airport",
    airport_city_code: "ISG",
    airport_city_name: "Ishigaki",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "24.344525",
    airport_lon: "124.186983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1570,
    airport_code: "ISH",
    airport_name: "Ischia Arpt",
    airport_city_code: "ISH",
    airport_city_name: "Ischia",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1571,
    airport_code: "ISJ",
    airport_name: "Isla Mujeres Arpt",
    airport_city_code: "ISJ",
    airport_city_name: "Isla Mujeres",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "21.245033",
    airport_lon: "-86.739967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1572,
    airport_code: "ISM",
    airport_name: "Kissimmee Municipal Arpt",
    airport_city_code: "ISM",
    airport_city_name: "Kissimmee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "28.289806",
    airport_lon: "-81.437083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1573,
    airport_code: "ISN",
    airport_name: "Sloulin Field Intl Arpt",
    airport_city_code: "ISN",
    airport_city_name: "Williston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "48.177939",
    airport_lon: "-103.642347",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1574,
    airport_code: "ISO",
    airport_name: "East Reg Jetport Stallings",
    airport_city_code: "ISO",
    airport_city_name: "Kinston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "7",
    airport_lat: "35.331389",
    airport_lon: "-77.608889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1575,
    airport_code: "ISP",
    airport_name: "Long Island Macarthur Arpt",
    airport_city_code: "ISP",
    airport_city_name: "Islip",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.79525",
    airport_lon: "-73.100222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1576,
    airport_code: "ISQ",
    airport_name: "Schoolcraft County Arpt",
    airport_city_code: "ISQ",
    airport_city_name: "Manistique",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1577,
    airport_code: "ISS",
    airport_name: "Wiscasset Arpt",
    airport_city_code: "ISS",
    airport_city_name: "Wiscasset",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1578,
    airport_code: "IST",
    airport_name: "Ataturk Arpt",
    airport_city_code: "IST",
    airport_city_name: "Istanbul",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.976922",
    airport_lon: "28.814606",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1579,
    airport_code: "SAW",
    airport_name: "Sabiha Gokcen Arpt",
    airport_city_code: "IST",
    airport_city_name: "Istanbul",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.898553",
    airport_lon: "29.309219",
    airport_num_airports: 2,
  },
  {
    airport_id: 1580,
    airport_code: "ISU",
    airport_name: "Sulaimaniyah International Airport",
    airport_city_code: "ISU",
    airport_city_name: "Sulaimaniyah",
    airport_country_name: "IRAQ",
    airport_country_code: "IQ",
    airport_timezone: "3",
    airport_lat: "35.5608",
    airport_lon: "45.3147",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1581,
    airport_code: "ISW",
    airport_name: "Alexander Field",
    airport_city_code: "ISW",
    airport_city_name: "Wisconsin Rapids",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1582,
    airport_code: "ITH",
    airport_name: "Tomkins County",
    airport_city_code: "ITH",
    airport_city_name: "Ithaca",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.491028",
    airport_lon: "-76.458444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1583,
    airport_code: "ITO",
    airport_name: "Hilo Hawaii Intl",
    airport_city_code: "ITO",
    airport_city_name: "Hilo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "19.721375",
    airport_lon: "-155.048469",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1584,
    airport_code: "ITP",
    airport_name: "Itaperuna Arpt",
    airport_city_code: "ITP",
    airport_city_name: "Itaperuna",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1585,
    airport_code: "IUE",
    airport_name: "Hanan Arpt",
    airport_city_code: "IUE",
    airport_city_name: "Niue Island",
    airport_country_name: "NIUE",
    airport_country_code: "NU",
    airport_timezone: "-11",
    airport_lat: "-19.080028",
    airport_lon: "-169.925639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1586,
    airport_code: "IVC",
    airport_name: "Invercargill Arpt",
    airport_city_code: "IVC",
    airport_city_name: "Invercargill",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-46.412408",
    airport_lon: "168.312992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1587,
    airport_code: "IVL",
    airport_name: "Ivalo Arpt",
    airport_city_code: "IVL",
    airport_city_name: "Ivalo",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "68.607269",
    airport_lon: "27.405328",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1588,
    airport_code: "IVR",
    airport_name: "Inverell Arpt",
    airport_city_code: "IVR",
    airport_city_name: "Inverell",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-29.8894",
    airport_lon: "151.1469",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1589,
    airport_code: "IWA",
    airport_name: "Ivanovo Arpt",
    airport_city_code: "IWA",
    airport_city_name: "Ivanovo",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "56.939444",
    airport_lon: "40.940833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1590,
    airport_code: "IWD",
    airport_name: "Ironwood Arpt",
    airport_city_code: "IWD",
    airport_city_name: "Ironwood",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "46.527475",
    airport_lon: "-90.131397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1591,
    airport_code: "IWJ",
    airport_name: "Iwami Arpt",
    airport_city_code: "IWJ",
    airport_city_name: "Iwami",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.6764",
    airport_lon: "131.79",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1592,
    airport_code: "IXA",
    airport_name: "Singerbhil Arpt",
    airport_city_code: "IXA",
    airport_city_name: "Agartala",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "23.886978",
    airport_lon: "91.24045",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1593,
    airport_code: "IXB",
    airport_name: "Bagdogra Arpt",
    airport_city_code: "IXB",
    airport_city_name: "Bagdogra",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.681206",
    airport_lon: "88.328567",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1594,
    airport_code: "IXC",
    airport_name: "Chandigarh Arpt",
    airport_city_code: "IXC",
    airport_city_name: "Chandigarh",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "30.673469",
    airport_lon: "76.788542",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1595,
    airport_code: "IXD",
    airport_name: "Bamrauli Arpt",
    airport_city_code: "IXD",
    airport_city_name: "Allahabad",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "25.440064",
    airport_lon: "81.733872",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1596,
    airport_code: "IXE",
    airport_name: "Bajpe Arpt",
    airport_city_code: "IXE",
    airport_city_name: "Mangalore",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "12.961267",
    airport_lon: "74.890069",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1597,
    airport_code: "IXG",
    airport_name: "Sambre Arpt",
    airport_city_code: "IXG",
    airport_city_name: "Belgaum",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "15.859286",
    airport_lon: "74.618292",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1598,
    airport_code: "IXI",
    airport_name: "Lilabari Arpt",
    airport_city_code: "IXI",
    airport_city_name: "Lilabari",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "27.295494",
    airport_lon: "94.09765",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1599,
    airport_code: "IXJ",
    airport_name: "Satwari Arpt",
    airport_city_code: "IXJ",
    airport_city_name: "Jammu",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "32.689142",
    airport_lon: "74.837389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1600,
    airport_code: "IXL",
    airport_name: "Leh Kushok Bakula Rimpochee Arpt",
    airport_city_code: "IXL",
    airport_city_name: "Leh IN",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "34.135872",
    airport_lon: "77.546514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1601,
    airport_code: "IXM",
    airport_name: "Madurai Airport",
    airport_city_code: "IXM",
    airport_city_name: "Madurai",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "9.834508",
    airport_lon: "78.093378",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1602,
    airport_code: "IXP",
    airport_name: "Pathankot Arpt",
    airport_city_code: "IXP",
    airport_city_name: "Pathankot",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "32.233778",
    airport_lon: "75.634628",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1603,
    airport_code: "IXR",
    airport_name: "Birsa Munda Arpt",
    airport_city_code: "IXR",
    airport_city_name: "Ranchi",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "23.31425",
    airport_lon: "85.321675",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1604,
    airport_code: "IXS",
    airport_name: "Kumbhigram Arpt",
    airport_city_code: "IXS",
    airport_city_name: "Silchar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "24.912928",
    airport_lon: "92.978742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1605,
    airport_code: "IXU",
    airport_name: "Chikkalthana Arpt",
    airport_city_code: "IXU",
    airport_city_name: "Aurangabad",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "19.862728",
    airport_lon: "75.398114",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1606,
    airport_code: "IXY",
    airport_name: "Kandla Arpt",
    airport_city_code: "IXY",
    airport_city_name: "Kandla",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "23.112719",
    airport_lon: "70.100289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1607,
    airport_code: "IXZ",
    airport_name: "Veer Savarkar Arpt",
    airport_city_code: "IXZ",
    airport_city_name: "Port Blair",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "11.641161",
    airport_lon: "92.729744",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1608,
    airport_code: "IYK",
    airport_name: "Kern Cty Airport",
    airport_city_code: "IYK",
    airport_city_name: "Inyokern",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "35.658889",
    airport_lon: "-117.829444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1609,
    airport_code: "ADB",
    airport_name: "Adnan Menderes Airport",
    airport_city_code: "IZM",
    airport_city_name: "Izmir",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.292392",
    airport_lon: "27.156953",
    airport_num_airports: 1,
  },
  {
    airport_id: 1610,
    airport_code: "IZO",
    airport_name: "Izumo Arpt",
    airport_city_code: "IZO",
    airport_city_name: "Izumo",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.413611",
    airport_lon: "132.89",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1611,
    airport_code: "IZT",
    airport_name: "Ixtepec Arpt",
    airport_city_code: "IZT",
    airport_city_name: "Ixtepec",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1612,
    airport_code: "JAB",
    airport_name: "Jabiru Arpt",
    airport_city_code: "JAB",
    airport_city_name: "Jabiru",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-12.6571",
    airport_lon: "132.893",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1613,
    airport_code: "JAC",
    airport_name: "Jackson Hole Arpt",
    airport_city_code: "JAC",
    airport_city_name: "Jackson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.607333",
    airport_lon: "-110.73775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1614,
    airport_code: "JAI",
    airport_name: "Sanganeer Arpt",
    airport_city_code: "JAI",
    airport_city_name: "Jaipur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.824192",
    airport_lon: "75.812161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1615,
    airport_code: "JAL",
    airport_name: "Jalapa Arpt",
    airport_city_code: "JAL",
    airport_city_name: "Jalapa",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.475083",
    airport_lon: "-96.797506",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1616,
    airport_code: "HKS",
    airport_name: "Hawkins Field",
    airport_city_code: "JAN",
    airport_city_name: "Jackson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1617,
    airport_code: "JAN",
    airport_name: "Jackson Evers Intl Arpt",
    airport_city_code: "JAN",
    airport_city_name: "Jackson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.311167",
    airport_lon: "-90.075889",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1618,
    airport_code: "JAP",
    airport_name: "Punta Renes Arpt",
    airport_city_code: "JAP",
    airport_city_name: "Punta Renes",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1619,
    airport_code: "JAV",
    airport_name: "Ilulissat Arpt",
    airport_city_code: "JAV",
    airport_city_name: "Ilulissat",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "69.23444",
    airport_lon: "-51.05111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1620,
    airport_code: "JAX",
    airport_name: "Jacksonville Intl Arpt",
    airport_city_code: "JAX",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "30.494056",
    airport_lon: "-81.687861",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 1621,
    airport_code: "NIP",
    airport_name: "Jacksonville Nas",
    airport_city_code: "JAX",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "30.235834",
    airport_lon: "-81.680556",
    airport_num_airports: 4,
  },
  {
    airport_id: 1622,
    airport_code: "NZC",
    airport_name: "Cecil Field Nas",
    airport_city_code: "JAX",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "30.2187",
    airport_lon: "-81.8767",
    airport_num_airports: 4,
  },
  {
    airport_id: 1623,
    airport_code: "CRG",
    airport_name: "Craig Municipal Arpt",
    airport_city_code: "JAX",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 1624,
    airport_code: "JBQ",
    airport_name: "Dr Joaquin Balaguer",
    airport_city_code: "JBQ",
    airport_city_name: "Higuero",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "18.5725",
    airport_lon: "-69.9856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1625,
    airport_code: "JBR",
    airport_name: "Jonesboro Municipal",
    airport_city_code: "JBR",
    airport_city_name: "Jonesboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.831708",
    airport_lon: "-90.646417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1626,
    airport_code: "JCB",
    airport_name: "Joacaba Arpt",
    airport_city_code: "JCB",
    airport_city_name: "Joacaba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-27.172778",
    airport_lon: "-51.500833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1627,
    airport_code: "JCK",
    airport_name: "Julia Creek Arpt",
    airport_city_code: "JCK",
    airport_city_name: "Julia Creek",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.6683",
    airport_lon: "141.723",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1628,
    airport_code: "JDF",
    airport_name: "Francisco De Assis Arpt",
    airport_city_code: "JDF",
    airport_city_name: "Juiz De Fora",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-21.7915",
    airport_lon: "-43.386778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1629,
    airport_code: "JDH",
    airport_name: "Jodhpur Arpt",
    airport_city_code: "JDH",
    airport_city_name: "Jodhpur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.251092",
    airport_lon: "73.048869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1630,
    airport_code: "JDO",
    airport_name: "Regional Do Cariri Arpt",
    airport_city_code: "JDO",
    airport_city_name: "Juazeiro Do Norte",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-7.218958",
    airport_lon: "-39.2701",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1631,
    airport_code: "JDZ",
    airport_name: "Jingdezhen Arpt",
    airport_city_code: "JDZ",
    airport_city_name: "Jingdezhen",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.3386",
    airport_lon: "117.176",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1632,
    airport_code: "JED",
    airport_name: "Jeddah Intl",
    airport_city_code: "JED",
    airport_city_name: "Jeddah",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "21.679564",
    airport_lon: "39.156536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1633,
    airport_code: "JEF",
    airport_name: "Jefferson City Memorial",
    airport_city_code: "JEF",
    airport_city_name: "Jefferson City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.5912",
    airport_lon: "-92.1561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1634,
    airport_code: "JEG",
    airport_name: "Aasiaat Arpt",
    airport_city_code: "JEG",
    airport_city_name: "Aasiaat",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "68.7",
    airport_lon: "-52.75",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1635,
    airport_code: "JER",
    airport_name: "States Airport",
    airport_city_code: "JER",
    airport_city_name: "Jersey",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "49.207947",
    airport_lon: "-2.195508",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1636,
    airport_code: "JGA",
    airport_name: "Govardhanpur Arpt",
    airport_city_code: "JGA",
    airport_city_name: "Jamnagar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "22.465522",
    airport_lon: "70.012556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1637,
    airport_code: "JGN",
    airport_name: "Jiayuguan Arpt",
    airport_city_code: "JGN",
    airport_city_name: "Jiayuguan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "39.8569",
    airport_lon: "98.3414",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1638,
    airport_code: "JGS",
    airport_name: "Jing Gang Shan Arpt",
    airport_city_code: "JGS",
    airport_city_name: "Jian",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "26.8997",
    airport_lon: "114.7375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1639,
    airport_code: "JHB",
    airport_name: "Sultan Ismail Intl Arpt",
    airport_city_code: "JHB",
    airport_city_name: "Johor Bahru",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "1.641308",
    airport_lon: "103.669619",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1640,
    airport_code: "XWP",
    airport_name: "Hassleholm Rail Station",
    airport_city_code: "JHE",
    airport_city_name: "Helsingborg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1641,
    airport_code: "XYH",
    airport_name: "Helsingborg Railway service",
    airport_city_code: "JHE",
    airport_city_name: "Helsingborg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1642,
    airport_code: "JHG",
    airport_name: "Jinghong Arpt",
    airport_city_code: "JHG",
    airport_city_name: "Jinghong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "21.973914",
    airport_lon: "100.759611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1643,
    airport_code: "JHM",
    airport_name: "Kapalua Arpt",
    airport_city_code: "JHM",
    airport_city_name: "Kapalua",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "20.962936",
    airport_lon: "-156.673031",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1644,
    airport_code: "JHS",
    airport_name: "Sisimiut Arpt",
    airport_city_code: "JHS",
    airport_city_name: "Sisimiut",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "66.9513",
    airport_lon: "-53.7293",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1645,
    airport_code: "JHW",
    airport_name: "Chautauqua Cty Arpt",
    airport_city_code: "JHW",
    airport_city_name: "Jamestown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.153333",
    airport_lon: "-79.258056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1646,
    airport_code: "JIB",
    airport_name: "Ambouli Airport",
    airport_city_code: "JIB",
    airport_city_name: "Djibouti",
    airport_country_name: "DJIBOUTI",
    airport_country_code: "DJ",
    airport_timezone: "3",
    airport_lat: "11.5472",
    airport_lon: "43.1594",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1647,
    airport_code: "JIK",
    airport_name: "Ikaria Arpt",
    airport_city_code: "JIK",
    airport_city_name: "Ikaria Island",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.682717",
    airport_lon: "26.347061",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1648,
    airport_code: "JIL",
    airport_name: "Jilin Arpt",
    airport_city_code: "JIL",
    airport_city_name: "Jilin",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1649,
    airport_code: "JIM",
    airport_name: "Jimma Arpt",
    airport_city_code: "JIM",
    airport_city_name: "Jimma",
    airport_country_name: "ETHIOPIA",
    airport_country_code: "ET",
    airport_timezone: "3",
    airport_lat: "7.666094",
    airport_lon: "36.816639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1650,
    airport_code: "JIU",
    airport_name: "Jiujiang Airport",
    airport_city_code: "JIU",
    airport_city_name: "Jiujiang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.733",
    airport_lon: "115.983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1651,
    airport_code: "JJN",
    airport_name: "Jinjiang Arpt",
    airport_city_code: "JJN",
    airport_city_name: "Jinjiang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "24.7964",
    airport_lon: "118.59",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1652,
    airport_code: "JJU",
    airport_name: "Qaqortoq Arpt",
    airport_city_code: "JJU",
    airport_city_name: "Qaqortoq",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "60.7158",
    airport_lon: "-46.0294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1653,
    airport_code: "XYC",
    airport_name: "Herrljunga Rail Station",
    airport_city_code: "JKG",
    airport_city_name: "Jonkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1654,
    airport_code: "XYF",
    airport_name: "Falkoping Rail Station",
    airport_city_code: "JKG",
    airport_city_name: "Jonkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1655,
    airport_code: "JKG",
    airport_name: "Axamo Airport",
    airport_city_code: "JKG",
    airport_city_name: "Jonkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "57.757594",
    airport_lon: "14.068731",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1656,
    airport_code: "JKH",
    airport_name: "Chios Arpt",
    airport_city_code: "JKH",
    airport_city_name: "Chios",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "38.343175",
    airport_lon: "26.140572",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1657,
    airport_code: "JKL",
    airport_name: "Kalymnos Arpt",
    airport_city_code: "JKL",
    airport_city_name: "Kalymnos Island",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.963333",
    airport_lon: "26.940556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1658,
    airport_code: "HLP",
    airport_name: "Halim Perdana Kusuma Arpt",
    airport_city_code: "JKT",
    airport_city_name: "Jakarta",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-6.26661",
    airport_lon: "106.891",
    airport_num_airports: 2,
  },
  {
    airport_id: 1659,
    airport_code: "CGK",
    airport_name: "Soekarno Hatta Intl",
    airport_city_code: "JKT",
    airport_city_name: "Jakarta",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-6.125567",
    airport_lon: "106.655897",
    airport_num_airports: 2,
  },
  {
    airport_id: 1660,
    airport_code: "JKV",
    airport_name: "Cherokee County Arpt",
    airport_city_code: "JKV",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1661,
    airport_code: "JLN",
    airport_name: "Joplin Municipal Arpt",
    airport_city_code: "JLN",
    airport_city_name: "Joplin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.151814",
    airport_lon: "-94.498269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1662,
    airport_code: "JMC",
    airport_name: "Marin County Arpt",
    airport_city_code: "JMC",
    airport_city_name: "Sausalito",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1663,
    airport_code: "JMK",
    airport_name: "Mykonos Greece Arpt",
    airport_city_code: "JMK",
    airport_city_name: "Mykonos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.435128",
    airport_lon: "25.348103",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1664,
    airport_code: "JMS",
    airport_name: "Jamestown Arpt",
    airport_city_code: "JMS",
    airport_city_name: "Jamestown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "46.9297",
    airport_lon: "-98.6782",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1665,
    airport_code: "HLA",
    airport_name: "Lanseria International Arpt",
    airport_city_code: "JNB",
    airport_city_name: "Johannesburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.938514",
    airport_lon: "27.926133",
    airport_num_airports: 4,
  },
  {
    airport_id: 1666,
    airport_code: "QRA",
    airport_name: "Randgermiston Arpt",
    airport_city_code: "JNB",
    airport_city_name: "Johannesburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-26.242506",
    airport_lon: "28.151169",
    airport_num_airports: 4,
  },
  {
    airport_id: 1667,
    airport_code: "GCJ",
    airport_name: "Grand Central Arpt",
    airport_city_code: "JNB",
    airport_city_name: "Johannesburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.986267",
    airport_lon: "28.140061",
    airport_num_airports: 4,
  },
  {
    airport_id: 1668,
    airport_code: "JNB",
    airport_name: "O R Tambo International Arpt",
    airport_city_code: "JNB",
    airport_city_name: "Johannesburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-26.139166",
    airport_lon: "28.246",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 1669,
    airport_code: "JNN",
    airport_name: "Nanortalik Arpt",
    airport_city_code: "JNN",
    airport_city_name: "Nanortalik",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "60.14",
    airport_lon: "-45.2317",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1670,
    airport_code: "JNS",
    airport_name: "Narsaq Heleport",
    airport_city_code: "JNS",
    airport_city_name: "Narsaq",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "60.9167",
    airport_lon: "-46.0586",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1671,
    airport_code: "JNU",
    airport_name: "Juneau Arpt",
    airport_city_code: "JNU",
    airport_city_name: "Juneau",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "58.354972",
    airport_lon: "-134.576278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1672,
    airport_code: "JNX",
    airport_name: "Naxos Arpt",
    airport_city_code: "JNX",
    airport_city_name: "Naxos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.080556",
    airport_lon: "25.368056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1673,
    airport_code: "JNZ",
    airport_name: "Jinzhou Arpt",
    airport_city_code: "JNZ",
    airport_city_name: "Jinzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "41.1014",
    airport_lon: "121.062",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1674,
    airport_code: "JOE",
    airport_name: "Joensuu Arpt",
    airport_city_code: "JOE",
    airport_city_name: "Joensuu",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "62.662906",
    airport_lon: "29.60755",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1675,
    airport_code: "JOG",
    airport_name: "Adisutjipto Arpt",
    airport_city_code: "JOG",
    airport_city_name: "Yogyakarta",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-7.788181",
    airport_lon: "110.431758",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1676,
    airport_code: "JOI",
    airport_name: "Cubatao Arpt",
    airport_city_code: "JOI",
    airport_city_name: "Joinville",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-26.224453",
    airport_lon: "-48.797364",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1677,
    airport_code: "JOK",
    airport_name: "Yoshkar-Ola Airport",
    airport_city_code: "JOK",
    airport_city_name: "Yoshkar-Ola Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "56.7006",
    airport_lon: "47.9047",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1678,
    airport_code: "JOT",
    airport_name: "Joliet Park District Arpt",
    airport_city_code: "JOT",
    airport_city_name: "Joliet",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.5178",
    airport_lon: "-88.1756",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1679,
    airport_code: "JPA",
    airport_name: "Castro Pinto Arpt",
    airport_city_code: "JPA",
    airport_city_name: "Joao Pessoa",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-7.148381",
    airport_lon: "-34.950681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1680,
    airport_code: "JPR",
    airport_name: "Ji Parana Arpt",
    airport_city_code: "JPR",
    airport_city_name: "Ji Parana",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-10.8708",
    airport_lon: "-61.8465",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1681,
    airport_code: "JQA",
    airport_name: "Qaarsut Arpt",
    airport_city_code: "JQA",
    airport_city_name: "Qaarsut",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "70.7342",
    airport_lon: "-52.6962",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1682,
    airport_code: "JRH",
    airport_name: "Rowriah Arpt",
    airport_city_code: "JRH",
    airport_city_name: "Jorhat",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.731528",
    airport_lon: "94.175536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1683,
    airport_code: "JRO",
    airport_name: "Kilimanjaro Arpt",
    airport_city_code: "JRO",
    airport_city_name: "Kilimanjaro",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-3.429406",
    airport_lon: "37.074461",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1684,
    airport_code: "JRS",
    airport_name: "Atarot Airport",
    airport_city_code: "JRS",
    airport_city_name: "Jerusalem",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "-100",
    airport_lat: "31.8647",
    airport_lon: "35.2192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1685,
    airport_code: "JSA",
    airport_name: "Jaisalmer Arpt",
    airport_city_code: "JSA",
    airport_city_name: "Jaisalmer",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.888653",
    airport_lon: "70.864967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1686,
    airport_code: "JSI",
    airport_name: "Skiathos Arpt",
    airport_city_code: "JSI",
    airport_city_name: "Skiathos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.1771",
    airport_lon: "23.503675",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1687,
    airport_code: "XEZ",
    airport_name: "Sodertalje S Rail Station",
    airport_city_code: "JSO",
    airport_city_name: "Sodertalje",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 1688,
    airport_code: "JST",
    airport_name: "Johnstown Cambria Arpt",
    airport_city_code: "JST",
    airport_city_name: "Johnstown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.316111",
    airport_lon: "-78.833889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1689,
    airport_code: "JSU",
    airport_name: "Maniitsoq Heleport",
    airport_city_code: "JSU",
    airport_city_name: "Maniitsoq",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "65.4125",
    airport_lon: "-52.9394",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1690,
    airport_code: "JSY",
    airport_name: "Syros Island Arpt",
    airport_city_code: "JSY",
    airport_city_name: "Syros Island",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.422792",
    airport_lon: "24.950936",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1691,
    airport_code: "JTR",
    airport_name: "Santorini Arpt",
    airport_city_code: "JTR",
    airport_city_name: "Thira Island",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.399169",
    airport_lon: "25.479333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1692,
    airport_code: "JTY",
    airport_name: "Astypalaia Arpt",
    airport_city_code: "JTY",
    airport_city_name: "Astypalaia Island",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.579886",
    airport_lon: "26.375822",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1693,
    airport_code: "JUB",
    airport_name: "Juba Arpt",
    airport_city_code: "JUB",
    airport_city_name: "Juba",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "2",
    airport_lat: "4.872006",
    airport_lon: "31.601117",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1694,
    airport_code: "JUI",
    airport_name: "Juist Arpt",
    airport_city_code: "JUI",
    airport_city_name: "Juist",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.681572",
    airport_lon: "7.055731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1695,
    airport_code: "JUJ",
    airport_name: "El Cadillal Airport",
    airport_city_code: "JUJ",
    airport_city_name: "Jujuy",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-24.392778",
    airport_lon: "-65.097778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1696,
    airport_code: "JUL",
    airport_name: "Juliaca Arpt",
    airport_city_code: "JUL",
    airport_city_name: "Juliaca",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-15.467103",
    airport_lon: "-70.158169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1697,
    airport_code: "JUO",
    airport_name: "Jurado Arpt",
    airport_city_code: "JUO",
    airport_city_name: "Jurado",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1698,
    airport_code: "JUZ",
    airport_name: "Juzhou Arpt",
    airport_city_code: "JUZ",
    airport_city_name: "Juzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.9658",
    airport_lon: "118.899",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1699,
    airport_code: "JVL",
    airport_name: "Rock County Arpt",
    airport_city_code: "JVL",
    airport_city_name: "Beloit",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.62025",
    airport_lon: "-89.041556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1700,
    airport_code: "JWN",
    airport_name: "Zanjan Arpt",
    airport_city_code: "JWN",
    airport_city_name: "Zanjan",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "-100",
    airport_lat: "36.7747",
    airport_lon: "48.3592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1701,
    airport_code: "JXN",
    airport_name: "Jackson Reynolds Municipal",
    airport_city_code: "JXN",
    airport_city_name: "Jackson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "42.2598",
    airport_lon: "-84.4594",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1702,
    airport_code: "JYV",
    airport_name: "Jyvaskyla Arpt",
    airport_city_code: "JYV",
    airport_city_name: "Jyvaskyla",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "62.399453",
    airport_lon: "25.678253",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1703,
    airport_code: "JZH",
    airport_name: "Jiu Zhai Huang Long Arpt",
    airport_city_code: "JZH",
    airport_city_name: "Songpan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "32.857",
    airport_lon: "103.683",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1704,
    airport_code: "KAB",
    airport_name: "Kariba Arpt",
    airport_city_code: "KAB",
    airport_city_name: "Kariba Dam",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "2",
    airport_lat: "-16.519761",
    airport_lon: "28.884981",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1705,
    airport_code: "KAE",
    airport_name: "Kake Arpt",
    airport_city_code: "KAE",
    airport_city_name: "Kake",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "56.973056",
    airport_lon: "-133.945556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1706,
    airport_code: "KAJ",
    airport_name: "Kajaani Airport",
    airport_city_code: "KAJ",
    airport_city_name: "Kajaani",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "64.285472",
    airport_lon: "27.692414",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1707,
    airport_code: "KAN",
    airport_name: "Aminu Kano Intl Arpt",
    airport_city_code: "KAN",
    airport_city_name: "Kano",
    airport_country_name: "NIGERIA",
    airport_country_code: "NG",
    airport_timezone: "1",
    airport_lat: "12.047589",
    airport_lon: "8.524622",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1708,
    airport_code: "KAO",
    airport_name: "Kuusamo",
    airport_city_code: "KAO",
    airport_city_name: "Kuusamo",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "65.987575",
    airport_lon: "29.239381",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1709,
    airport_code: "KAT",
    airport_name: "Kaitaia Arpt",
    airport_city_code: "KAT",
    airport_city_name: "Kaitaia",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-35.07",
    airport_lon: "173.285278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1710,
    airport_code: "KBJ",
    airport_name: "Kings Canyon Arpt",
    airport_city_code: "KBJ",
    airport_city_name: "Kings Canyon",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1711,
    airport_code: "KBL",
    airport_name: "Khwaja Rawash Arpt",
    airport_city_code: "KBL",
    airport_city_name: "Kabul",
    airport_country_name: "AFGHANISTAN",
    airport_country_code: "AF",
    airport_timezone: "5",
    airport_lat: "34.565853",
    airport_lon: "69.212328",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1712,
    airport_code: "KBR",
    airport_name: "Pengkalan Chepa",
    airport_city_code: "KBR",
    airport_city_name: "Kota Bharu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "6.16685",
    airport_lon: "102.293014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1713,
    airport_code: "KBV",
    airport_name: "Krabi Arpt",
    airport_city_code: "KBV",
    airport_city_name: "Krabi",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "8.095969",
    airport_lon: "98.988764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1714,
    airport_code: "KBZ",
    airport_name: "Kaikoura Arpt",
    airport_city_code: "KBZ",
    airport_city_name: "Kaikoura",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-42.416668",
    airport_lon: "173.68333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1715,
    airport_code: "KCE",
    airport_name: "Collinsville Arpt",
    airport_city_code: "KCE",
    airport_city_name: "Collinsville",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1716,
    airport_code: "KCH",
    airport_name: "Kuching Airport",
    airport_city_code: "KCH",
    airport_city_name: "Kuching",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "1.484697",
    airport_lon: "110.346933",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1717,
    airport_code: "OJC",
    airport_name: "Johnson Executive Arpt",
    airport_city_code: "KCK",
    airport_city_name: "Kansas City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1718,
    airport_code: "KCK",
    airport_name: "Fairfax Municipal Arpt",
    airport_city_code: "KCK",
    airport_city_name: "Kansas City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1719,
    airport_code: "KCM",
    airport_name: "Kahramanmaras aRPT",
    airport_city_code: "KCM",
    airport_city_name: "Kahramanmaras",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.539",
    airport_lon: "36.9534",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1720,
    airport_code: "KCO",
    airport_name: "Kocaeli",
    airport_city_code: "KCO",
    airport_city_name: "Kocaeli",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1721,
    airport_code: "KCP",
    airport_name: "Kamenets Podolski Arpt",
    airport_city_code: "KCP",
    airport_city_name: "Kamenets Podolski",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1722,
    airport_code: "KCZ",
    airport_name: "Kochi Airport",
    airport_city_code: "KCZ",
    airport_city_name: "Kochi",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.546111",
    airport_lon: "133.669444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1723,
    airport_code: "KDH",
    airport_name: "Kandahar Airport",
    airport_city_code: "KDH",
    airport_city_name: "Kandahar Airport",
    airport_country_name: "AFGHANISTAN",
    airport_country_code: "AF",
    airport_timezone: "5",
    airport_lat: "31.505756",
    airport_lon: "65.847822",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1724,
    airport_code: "KEJ",
    airport_name: "Kemerovo Arpt",
    airport_city_code: "KEJ",
    airport_city_name: "Kemerovo",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "55.270094",
    airport_lon: "86.107208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1725,
    airport_code: "KEL",
    airport_name: "Holtenau Arpt",
    airport_city_code: "KEL",
    airport_city_name: "Kiel",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "54.3795",
    airport_lon: "10.145167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1726,
    airport_code: "KEM",
    airport_name: "Kemi Tornio Arpt",
    airport_city_code: "KEM",
    airport_city_name: "Kemi",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "65.781889",
    airport_lon: "24.5991",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1727,
    airport_code: "KER",
    airport_name: "Kerman Arpt",
    airport_city_code: "KER",
    airport_city_name: "Kerman",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "30.274444",
    airport_lon: "56.951111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1728,
    airport_code: "KFS",
    airport_name: "Kastamonu Arpt",
    airport_city_code: "KFS",
    airport_city_name: "Kastamonu",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "3",
    airport_lat: "41.305557",
    airport_lon: "33.79111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1729,
    airport_code: "KGC",
    airport_name: "Kingscote Arpt",
    airport_city_code: "KGC",
    airport_city_name: "Kingscote",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-35.713889",
    airport_lon: "137.521389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1730,
    airport_code: "KGD",
    airport_name: "Kaliningrad Arpt",
    airport_city_code: "KGD",
    airport_city_name: "Kaliningrad",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "3",
    airport_lat: "54.89005",
    airport_lon: "20.592633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1731,
    airport_code: "KGF",
    airport_name: "Karaganda Arpt",
    airport_city_code: "KGF",
    airport_city_name: "Karaganda",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "5",
    airport_lat: "49.670833",
    airport_lon: "73.334444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1732,
    airport_code: "KGI",
    airport_name: "Kalgoorlie Arpt",
    airport_city_code: "KGI",
    airport_city_name: "Kalgoorlie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-30.789444",
    airport_lon: "121.461667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1733,
    airport_code: "KGL",
    airport_name: "Kigali Intl Arpt",
    airport_city_code: "KGL",
    airport_city_name: "Kigali",
    airport_country_name: "RWANDA",
    airport_country_code: "RW",
    airport_timezone: "2",
    airport_lat: "-1.968628",
    airport_lon: "30.13945",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1734,
    airport_code: "KGP",
    airport_name: "Kogalym Airport",
    airport_city_code: "KGP",
    airport_city_name: "Kogalym Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "62.18",
    airport_lon: "74.53",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1735,
    airport_code: "KGS",
    airport_name: "Kos Arpt",
    airport_city_code: "KGS",
    airport_city_name: "Kos Gr",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.793335",
    airport_lon: "27.091667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1736,
    airport_code: "KHD",
    airport_name: "Khorramabad Arpt",
    airport_city_code: "KHD",
    airport_city_name: "Khorramabad",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "33.435378",
    airport_lon: "48.282889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1737,
    airport_code: "KHG",
    airport_name: "Kashi Arpt",
    airport_city_code: "KHG",
    airport_city_name: "Kashi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "39.542922",
    airport_lon: "76.019956",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1738,
    airport_code: "KHH",
    airport_name: "Kaohsiung Intl",
    airport_city_code: "KHH",
    airport_city_name: "Kaohsiung",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "22.577094",
    airport_lon: "120.350006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1739,
    airport_code: "KHI",
    airport_name: "Quaid E Azam International",
    airport_city_code: "KHI",
    airport_city_name: "Karachi",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "24.906547",
    airport_lon: "67.160797",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1740,
    airport_code: "KHN",
    airport_name: "Nanchang Arpt",
    airport_city_code: "KHN",
    airport_city_name: "Nanchang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.865",
    airport_lon: "115.9",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1741,
    airport_code: "KHV",
    airport_name: "Novyy Arpt",
    airport_city_code: "KHV",
    airport_city_name: "Khabarovsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "11",
    airport_lat: "48.528044",
    airport_lon: "135.188361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1742,
    airport_code: "KIC",
    airport_name: "Mesa Del Rey Arpt",
    airport_city_code: "KIC",
    airport_city_name: "King City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1743,
    airport_code: "XYU",
    airport_name: "Solvesborg Rail Station",
    airport_city_code: "KID",
    airport_city_name: "Kristianstad",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1744,
    airport_code: "KID",
    airport_name: "Kristianstad Arpt",
    airport_city_code: "KID",
    airport_city_name: "Kristianstad",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "55.921686",
    airport_lon: "14.085536",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1745,
    airport_code: "KIH",
    airport_name: "Kish Island Arpt",
    airport_city_code: "KIH",
    airport_city_name: "Kish Island",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "26.526156",
    airport_lon: "53.980211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1746,
    airport_code: "KIJ",
    airport_name: "Niigata Arpt",
    airport_city_code: "KIJ",
    airport_city_name: "Niigata",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "37.5711",
    airport_lon: "139.0646",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1747,
    airport_code: "KIM",
    airport_name: "Kimberley Arpt",
    airport_city_code: "KIM",
    airport_city_name: "Kimberley",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-28.802834",
    airport_lon: "24.765167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1748,
    airport_code: "KIN",
    airport_name: "Norman Manly Arpt",
    airport_city_code: "KIN",
    airport_city_name: "Kingston",
    airport_country_name: "JAMAICA",
    airport_country_code: "JM",
    airport_timezone: "-5",
    airport_lat: "17.935667",
    airport_lon: "-76.7875",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1749,
    airport_code: "KTP",
    airport_name: "Tinson Arpt",
    airport_city_code: "KIN",
    airport_city_name: "Kingston",
    airport_country_name: "JAMAICA",
    airport_country_code: "JM",
    airport_timezone: "-5",
    airport_lat: "17.988558",
    airport_lon: "-76.823761",
    airport_num_airports: 2,
  },
  {
    airport_id: 1750,
    airport_code: "KIR",
    airport_name: "Kerry County Arpt",
    airport_city_code: "KIR",
    airport_city_name: "Kerry County",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "52.180878",
    airport_lon: "-9.523783",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1751,
    airport_code: "KIS",
    airport_name: "Kisumu Arpt",
    airport_city_code: "KIS",
    airport_city_name: "Kisumu",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-0.086139",
    airport_lon: "34.728892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1752,
    airport_code: "KIT",
    airport_name: "Kithira Arpt",
    airport_city_code: "KIT",
    airport_city_name: "Kithira",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.274258",
    airport_lon: "23.016978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1753,
    airport_code: "KIV",
    airport_name: "Chisinau Arpt",
    airport_city_code: "KIV",
    airport_city_name: "Chisinau",
    airport_country_name: "MOLDOVA, REPUBLIC OF",
    airport_country_code: "MD",
    airport_timezone: "2",
    airport_lat: "46.927744",
    airport_lon: "28.930978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1754,
    airport_code: "KJA",
    airport_name: "Krasnojarsk Arpt",
    airport_city_code: "KJA",
    airport_city_name: "Krasnoyarsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "56.18",
    airport_lon: "92.475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1755,
    airport_code: "KJK",
    airport_name: "Kortrijk Arpt",
    airport_city_code: "KJK",
    airport_city_name: "Kortrijk",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "-100",
    airport_lat: "50.8172",
    airport_lon: "3.2047",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1756,
    airport_code: "KKC",
    airport_name: "Khon Kaen Arpt",
    airport_city_code: "KKC",
    airport_city_name: "Khon Kaen",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "16.466628",
    airport_lon: "102.783661",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1757,
    airport_code: "KKE",
    airport_name: "Kerikeri Arpt",
    airport_city_code: "KKE",
    airport_city_name: "Kerikeri",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-35.262779",
    airport_lon: "173.911944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1758,
    airport_code: "KKJ",
    airport_name: "Kokura Arpt",
    airport_city_code: "KKJ",
    airport_city_name: "Kita Kyushu",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.845942",
    airport_lon: "131.034689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1759,
    airport_code: "KKN",
    airport_name: "Hoeybuktmoen Arpt",
    airport_city_code: "KKN",
    airport_city_name: "Kirkenes",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "69.725781",
    airport_lon: "29.891295",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1760,
    airport_code: "KKT",
    airport_name: "Kentland Arpt",
    airport_city_code: "KKT",
    airport_city_name: "Kentland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1761,
    airport_code: "KLD",
    airport_name: "Migalovo Arpt",
    airport_city_code: "KLD",
    airport_city_name: "Tver",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "56.824736",
    airport_lon: "35.757678",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1762,
    airport_code: "KLF",
    airport_name: "Kaluga Grabtsevo AirportINA",
    airport_city_code: "KLF",
    airport_city_name: "Kaluga Grabtsevo AirportINA",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1763,
    airport_code: "KLG",
    airport_name: "Kalskag Municipal Arpt",
    airport_city_code: "KLG",
    airport_city_name: "Kalskag",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "61.5363",
    airport_lon: "-160.341",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1764,
    airport_code: "KLH",
    airport_name: "Kolhapur Arpt",
    airport_city_code: "KLH",
    airport_city_name: "Kolhapur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "16.664658",
    airport_lon: "74.289353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1765,
    airport_code: "KLJ",
    airport_name: "Klaipeda Arpt",
    airport_city_code: "KLJ",
    airport_city_name: "Klaipeda",
    airport_country_name: "LITHUANIA",
    airport_country_code: "LT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1766,
    airport_code: "KLO",
    airport_name: "Kalibo Arpt",
    airport_city_code: "KLO",
    airport_city_name: "Kalibo",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "11.679431",
    airport_lon: "122.376294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1767,
    airport_code: "KLR",
    airport_name: "Kalmar Arpt",
    airport_city_code: "KLR",
    airport_city_name: "Kalmar",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "56.685531",
    airport_lon: "16.287578",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1768,
    airport_code: "KLS",
    airport_name: "Kelso Longview Regional Arpt",
    airport_city_code: "KLS",
    airport_city_name: "Kelso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.118",
    airport_lon: "-122.898389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1769,
    airport_code: "KLT",
    airport_name: "Kaiserslautern Rail Station",
    airport_city_code: "KLT",
    airport_city_name: "Kaiserslautern",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1770,
    airport_code: "KLU",
    airport_name: "Klagenfurt Arpt",
    airport_city_code: "KLU",
    airport_city_name: "Klagenfurt",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "1",
    airport_lat: "46.642514",
    airport_lon: "14.337739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1771,
    airport_code: "KLV",
    airport_name: "Karlovy Vary Arpt",
    airport_city_code: "KLV",
    airport_city_name: "Karlovy Vary",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "1",
    airport_lat: "50.202978",
    airport_lon: "12.914983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1772,
    airport_code: "KLW",
    airport_name: "Klawock Arpt",
    airport_city_code: "KLW",
    airport_city_name: "Klawock",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "55.579167",
    airport_lon: "-133.076111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1773,
    airport_code: "KLX",
    airport_name: "Kalamata Airport",
    airport_city_code: "KLX",
    airport_city_name: "Kalamata",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.068319",
    airport_lon: "22.025525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1774,
    airport_code: "KMG",
    airport_name: "Kunming Airport",
    airport_city_code: "KMG",
    airport_city_name: "Kunming",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "24.992364",
    airport_lon: "102.743536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1775,
    airport_code: "KMH",
    airport_name: "Kuruman Arpt",
    airport_city_code: "KMH",
    airport_city_name: "Kuruman",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1776,
    airport_code: "KMI",
    airport_name: "Miyazaki Arpt",
    airport_city_code: "KMI",
    airport_city_name: "Miyazaki",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "31.877222",
    airport_lon: "131.448611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1777,
    airport_code: "KMJ",
    airport_name: "Kumamoto Airport",
    airport_city_code: "KMJ",
    airport_city_name: "Kumamoto",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "32.837319",
    airport_lon: "130.85505",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1778,
    airport_code: "KMQ",
    airport_name: "Komatsu Airport",
    airport_city_code: "KMQ",
    airport_city_name: "Komatsu",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "36.394611",
    airport_lon: "136.406544",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1779,
    airport_code: "KMS",
    airport_name: "Sunyani",
    airport_city_code: "KMS",
    airport_city_name: "Kumasi",
    airport_country_name: "GHANA",
    airport_country_code: "GH",
    airport_timezone: "0",
    airport_lat: "6.714564",
    airport_lon: "-1.590818",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1780,
    airport_code: "KMW",
    airport_name: "Kostroma Arpt",
    airport_city_code: "KMW",
    airport_city_name: "Kostroma",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "57.7961",
    airport_lon: "41.0204",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1781,
    airport_code: "KMX",
    airport_name: "Khamis Mushait Arpt",
    airport_city_code: "KMX",
    airport_city_name: "Khamis Mushait",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "-100",
    airport_lat: "18.3011",
    airport_lon: "42.8009",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1782,
    airport_code: "KNA",
    airport_name: "Vina Del Mar Arpt",
    airport_city_code: "KNA",
    airport_city_name: "Vina Del Mar",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-100",
    airport_lat: "-32.9497",
    airport_lon: "-71.4786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1783,
    airport_code: "KNF",
    airport_name: "Marham Raf",
    airport_city_code: "KNF",
    airport_city_name: "Kings Lynn",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.648353",
    airport_lon: "0.550692",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1784,
    airport_code: "KNH",
    airport_name: "Shang Yi Arpt",
    airport_city_code: "KNH",
    airport_city_name: "Kinmen",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "24.427892",
    airport_lon: "118.359197",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1785,
    airport_code: "KNS",
    airport_name: "King Island Arpt",
    airport_city_code: "KNS",
    airport_city_name: "King Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-39.8775",
    airport_lon: "143.878",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1786,
    airport_code: "KNT",
    airport_name: "Kennett Municipal Arpt",
    airport_city_code: "KNT",
    airport_city_name: "Kennett",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1787,
    airport_code: "KNU",
    airport_name: "Chakeri Arpt",
    airport_city_code: "KNU",
    airport_city_name: "Kanpur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.441444",
    airport_lon: "80.364864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1788,
    airport_code: "KNX",
    airport_name: "Kununurra Airport",
    airport_city_code: "KNX",
    airport_city_name: "Kununurra",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-15.778056",
    airport_lon: "128.7075",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1789,
    airport_code: "KOA",
    airport_name: "Keahole Arpt",
    airport_city_code: "KOA",
    airport_city_name: "Kona",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "19.738767",
    airport_lon: "-156.045631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1790,
    airport_code: "KOB",
    airport_name: "Koutaba Arpt",
    airport_city_code: "KOB",
    airport_city_name: "Koutaba",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1791,
    airport_code: "KOC",
    airport_name: "Koumac Arpt",
    airport_city_code: "KOC",
    airport_city_name: "Koumac",
    airport_country_name: "NEW CALEDONIA",
    airport_country_code: "NC",
    airport_timezone: "11",
    airport_lat: "-20.546314",
    airport_lon: "164.255625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1792,
    airport_code: "KOI",
    airport_name: "Kirkwall Arpt",
    airport_city_code: "KOI",
    airport_city_name: "Kirkwall",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "58.957778",
    airport_lon: "-2.905",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1793,
    airport_code: "KOJ",
    airport_name: "Kagoshima Arpt",
    airport_city_code: "KOJ",
    airport_city_name: "Kagoshima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "31.803397",
    airport_lon: "130.719408",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1794,
    airport_code: "KOK",
    airport_name: "Kokkola Arpt",
    airport_city_code: "KOK",
    airport_city_name: "Kokkola",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "63.721172",
    airport_lon: "23.143131",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1795,
    airport_code: "KOP",
    airport_name: "Nakhon Phanom Arpt",
    airport_city_code: "KOP",
    airport_city_name: "Nakhon Phanom",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "17.383794",
    airport_lon: "104.643022",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1796,
    airport_code: "KOR",
    airport_name: "Kokoro Arpt",
    airport_city_code: "KOR",
    airport_city_name: "Kokoro",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1797,
    airport_code: "KOS",
    airport_name: "Sihanoukville Arpt",
    airport_city_code: "KOS",
    airport_city_name: "Sihanoukville",
    airport_country_name: "CAMBODIA",
    airport_country_code: "KH",
    airport_timezone: "7",
    airport_lat: "10.579686",
    airport_lon: "103.636828",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1798,
    airport_code: "KOV",
    airport_name: "KOKSHETAU",
    airport_city_code: "KOV",
    airport_city_name: "KOKSHETAU",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "53.3291",
    airport_lon: "69.5946",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1799,
    airport_code: "KOW",
    airport_name: "Ganzhou Arpt",
    airport_city_code: "KOW",
    airport_city_name: "Ganzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "25.8258",
    airport_lon: "114.912",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1800,
    airport_code: "KPD",
    airport_name: "King Of Prussia Arpt",
    airport_city_code: "KPD",
    airport_city_name: "King Of Prussia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1801,
    airport_code: "KPO",
    airport_name: "Pohang Arpt",
    airport_city_code: "KPO",
    airport_city_name: "Pohang",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.987858",
    airport_lon: "129.420486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1802,
    airport_code: "KPS",
    airport_name: "Kempsey Arpt",
    airport_city_code: "KPS",
    airport_city_name: "Kempsey",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-31.06",
    airport_lon: "152.7897",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1803,
    airport_code: "KRB",
    airport_name: "Karumba Arpt",
    airport_city_code: "KRB",
    airport_city_name: "Karumba",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-17.4567",
    airport_lon: "140.83",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1804,
    airport_code: "KRF",
    airport_name: "Kramfors Arpt",
    airport_city_code: "KRF",
    airport_city_name: "Kramfors",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "63.048597",
    airport_lon: "17.768856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1805,
    airport_code: "KRK",
    airport_name: "John Paul Ii International",
    airport_city_code: "KRK",
    airport_city_name: "Krakow",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "50.077731",
    airport_lon: "19.784836",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1806,
    airport_code: "KRL",
    airport_name: "Korla Arpt",
    airport_city_code: "KRL",
    airport_city_name: "Korla",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "41.6978",
    airport_lon: "86.1289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1807,
    airport_code: "KRN",
    airport_name: "Kiruna Airport",
    airport_city_code: "KRN",
    airport_city_name: "Kiruna",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "67.821986",
    airport_lon: "20.336764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1808,
    airport_code: "KRO",
    airport_name: "Kurgan Airport",
    airport_city_code: "KRO",
    airport_city_name: "Kurgan Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "55.4753",
    airport_lon: "65.4156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1809,
    airport_code: "KRP",
    airport_name: "Karup Arpt",
    airport_city_code: "KRP",
    airport_city_name: "Karup",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "56.297458",
    airport_lon: "9.124628",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1810,
    airport_code: "KRR",
    airport_name: "Krasnodar Arpt",
    airport_city_code: "KRR",
    airport_city_name: "Krasnodar",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "45.034689",
    airport_lon: "39.170539",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1811,
    airport_code: "XGD",
    airport_name: "Arendal Rail Station",
    airport_city_code: "KRS",
    airport_city_name: "Kristiansand",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1812,
    airport_code: "XKR",
    airport_name: "Kristiansand Rail Station",
    airport_city_code: "KRS",
    airport_city_name: "Kristiansand",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 1813,
    airport_code: "KRS",
    airport_name: "Kjevik Airport",
    airport_city_code: "KRS",
    airport_city_name: "Kristiansand",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "58.204214",
    airport_lon: "8.085369",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1814,
    airport_code: "KRT",
    airport_name: "Civil Arpt",
    airport_city_code: "KRT",
    airport_city_name: "Khartoum",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "2",
    airport_lat: "15.589497",
    airport_lon: "32.553161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1815,
    airport_code: "KRW",
    airport_name: "Turkmanbashi Arpt",
    airport_city_code: "KRW",
    airport_city_name: "Turkmanbashi",
    airport_country_name: "TURKMENISTAN",
    airport_country_code: "TM",
    airport_timezone: "5",
    airport_lat: "40.063333",
    airport_lon: "53.007222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1816,
    airport_code: "KRY",
    airport_name: "Karamay Arpt",
    airport_city_code: "KRY",
    airport_city_name: "Karamay",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "45.617",
    airport_lon: "84.883",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1817,
    airport_code: "KSC",
    airport_name: "Barca Arpt",
    airport_city_code: "KSC",
    airport_city_name: "Kosice",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "1",
    airport_lat: "48.663055",
    airport_lon: "21.241112",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1818,
    airport_code: "KSD",
    airport_name: "Karlstad Arpt",
    airport_city_code: "KSD",
    airport_city_name: "Karlstad",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "59.4447",
    airport_lon: "13.3374",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1819,
    airport_code: "KSF",
    airport_name: "Kassel Calden Arpt",
    airport_city_code: "KSF",
    airport_city_name: "Kassel",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.408394",
    airport_lon: "9.377631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1820,
    airport_code: "KSH",
    airport_name: "Kermanshah Arpt",
    airport_city_code: "KSH",
    airport_city_name: "Kermanshah",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "34.345853",
    airport_lon: "47.158128",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1821,
    airport_code: "KSN",
    airport_name: "Kostanay Arpt",
    airport_city_code: "KSN",
    airport_city_name: "Kostanay",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "53.206944",
    airport_lon: "63.550278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1822,
    airport_code: "KSO",
    airport_name: "Aristoteles Arpt",
    airport_city_code: "KSO",
    airport_city_name: "Kastoria",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "40.446294",
    airport_lon: "21.282186",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1823,
    airport_code: "KSQ",
    airport_name: "Karshi Arpt",
    airport_city_code: "KSQ",
    airport_city_name: "Karshi Arpt",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "38.8336",
    airport_lon: "65.9215",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1824,
    airport_code: "KSR",
    airport_name: "SANDY RIVER",
    airport_city_code: "KSR",
    airport_city_name: "SANDY RIVER",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "1",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1825,
    airport_code: "XGI",
    airport_name: "Andalsnes Rail Station",
    airport_city_code: "KSU",
    airport_city_name: "Kristiansund",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1826,
    airport_code: "KSU",
    airport_name: "Kvernberget Arpt",
    airport_city_code: "KSU",
    airport_city_name: "Kristiansund",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "63.111781",
    airport_lon: "7.824522",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1827,
    airport_code: "KSW",
    airport_name: "Kiryat Shmona Arpt",
    airport_city_code: "KSW",
    airport_city_name: "Kiryat Shmona",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1828,
    airport_code: "KSY",
    airport_name: "Kars Arpt",
    airport_city_code: "KSY",
    airport_city_name: "Kars",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.562222",
    airport_lon: "43.115002",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1829,
    airport_code: "KSZ",
    airport_name: "Kotlas Airport",
    airport_city_code: "KSZ",
    airport_city_name: "Kotlas Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "61.2358",
    airport_lon: "46.6975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1830,
    airport_code: "KTA",
    airport_name: "Karratha Airport",
    airport_city_code: "KTA",
    airport_city_name: "Karratha",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-20.712222",
    airport_lon: "116.773333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1831,
    airport_code: "KTE",
    airport_name: "Kertech Arpt",
    airport_city_code: "KTE",
    airport_city_name: "Kerteh",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.537222",
    airport_lon: "103.426756",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1832,
    airport_code: "KTM",
    airport_name: "Tribuvan Arpt",
    airport_city_code: "KTM",
    airport_city_name: "Kathmandu",
    airport_country_name: "NEPAL",
    airport_country_code: "NP",
    airport_timezone: "5",
    airport_lat: "27.696583",
    airport_lon: "85.3591",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1833,
    airport_code: "KTN",
    airport_name: "Ketchikan Intl Arpt",
    airport_city_code: "KTN",
    airport_city_name: "Ketchikan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "55.355556",
    airport_lon: "-131.71375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1834,
    airport_code: "KTR",
    airport_name: "Tindal Airport",
    airport_city_code: "KTR",
    airport_city_name: "Katherine",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-14.5211",
    airport_lon: "132.378",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1835,
    airport_code: "KTS",
    airport_name: "Brevig Mission Arpt",
    airport_city_code: "KTS",
    airport_city_name: "Brevig Mission",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "65.331389",
    airport_lon: "-166.465833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1836,
    airport_code: "KTT",
    airport_name: "Kittila Arpt",
    airport_city_code: "KTT",
    airport_city_name: "Kittila",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "67.701022",
    airport_lon: "24.84685",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1837,
    airport_code: "KTW",
    airport_name: "Pyrzowice",
    airport_city_code: "KTW",
    airport_city_name: "Katowice",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "50.474253",
    airport_lon: "19.080019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1838,
    airport_code: "KUA",
    airport_name: "Kuantan Arpt",
    airport_city_code: "KUA",
    airport_city_name: "Kuantan",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.775389",
    airport_lon: "103.209056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1839,
    airport_code: "KUB",
    airport_name: "Kuala Belait Arpt",
    airport_city_code: "KUB",
    airport_city_name: "Kuala Belait",
    airport_country_name: "BRUNEI DARUSSALAM",
    airport_country_code: "BN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1840,
    airport_code: "KUD",
    airport_name: "Kudat Arpt",
    airport_city_code: "KUD",
    airport_city_name: "Kudat",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "6.9225",
    airport_lon: "116.836",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1841,
    airport_code: "KUF",
    airport_name: "Samara Arpt",
    airport_city_code: "KUF",
    airport_city_name: "Samara",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "53.5",
    airport_lon: "50.15",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1842,
    airport_code: "KUH",
    airport_name: "Kushiro Arpt",
    airport_city_code: "KUH",
    airport_city_name: "Kushiro",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "43.041",
    airport_lon: "144.193",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1843,
    airport_code: "KUL",
    airport_name: "Kuala Lumpur International Arpt",
    airport_city_code: "KUL",
    airport_city_name: "Kuala Lumpur",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "2.745578",
    airport_lon: "101.709917",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1844,
    airport_code: "SZB",
    airport_name: "Sultan Abdul Aziz Shah Arpt",
    airport_city_code: "KUL",
    airport_city_name: "Kuala Lumpur",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.130583",
    airport_lon: "101.549333",
    airport_num_airports: 2,
  },
  {
    airport_id: 1845,
    airport_code: "KUN",
    airport_name: "Kaunas Arpt",
    airport_city_code: "KUN",
    airport_city_name: "Kaunas",
    airport_country_name: "LITHUANIA",
    airport_country_code: "LT",
    airport_timezone: "2",
    airport_lat: "54.963919",
    airport_lon: "24.084778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1846,
    airport_code: "KUO",
    airport_name: "Kuopio Arpt",
    airport_city_code: "KUO",
    airport_city_name: "Kuopio",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "63.00715",
    airport_lon: "27.797756",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1847,
    airport_code: "KUS",
    airport_name: "Kulusuk Arpt",
    airport_city_code: "KUS",
    airport_city_name: "Kulusuk",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-2",
    airport_lat: "65.566667",
    airport_lon: "-37.116667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1848,
    airport_code: "KUT",
    airport_name: "Kutaisi Arpt",
    airport_city_code: "KUT",
    airport_city_name: "Kutaisi Arpt",
    airport_country_name: "GEORGIA",
    airport_country_code: "GE",
    airport_timezone: "4",
    airport_lat: "42.25",
    airport_lon: "42.7",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1849,
    airport_code: "KUU",
    airport_name: "Bhuntar Arpt",
    airport_city_code: "KUU",
    airport_city_name: "Kulu",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "31.876706",
    airport_lon: "77.154367",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1850,
    airport_code: "KUV",
    airport_name: "Kunsan Arpt",
    airport_city_code: "KUV",
    airport_city_name: "Kunsan",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.903756",
    airport_lon: "126.615906",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1851,
    airport_code: "KVA",
    airport_name: "Megas Alexandros",
    airport_city_code: "KVA",
    airport_city_name: "Kavalla",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "40.913306",
    airport_lon: "24.619223",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1852,
    airport_code: "KVB",
    airport_name: "Skovde Arpt",
    airport_city_code: "KVB",
    airport_city_name: "Skovde",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "58.4564",
    airport_lon: "13.972672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1853,
    airport_code: "KVD",
    airport_name: "Ganja",
    airport_city_code: "KVD",
    airport_city_name: "Ganja",
    airport_country_name: "AZERBAIJAN",
    airport_country_code: "AZ",
    airport_timezone: "4",
    airport_lat: "40.7377",
    airport_lon: "46.3176",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1854,
    airport_code: "KVG",
    airport_name: "Kavieng Arpt",
    airport_city_code: "KVG",
    airport_city_name: "Kavieng",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-2.5794",
    airport_lon: "150.808",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1855,
    airport_code: "KVK",
    airport_name: "Kirovsk-Apatity Airport",
    airport_city_code: "KVK",
    airport_city_name: "Kirovsk-Apatity Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "67.4633",
    airport_lon: "33.5883",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1856,
    airport_code: "KVX",
    airport_name: "Pobedilovo Airport",
    airport_city_code: "KVX",
    airport_city_name: "Kirov",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "58.5033",
    airport_lon: "49.3483",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1857,
    airport_code: "KWA",
    airport_name: "Kwajalein Arpt",
    airport_city_code: "KWA",
    airport_city_name: "Kwajalein",
    airport_country_name: "MARSHALL ISLANDS",
    airport_country_code: "MH",
    airport_timezone: "12",
    airport_lat: "8.720122",
    airport_lon: "167.731661",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1858,
    airport_code: "KWE",
    airport_name: "Guiyang Arpt",
    airport_city_code: "KWE",
    airport_city_name: "Guiyang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "26.538522",
    airport_lon: "106.800703",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1859,
    airport_code: "KWG",
    airport_name: "Krivoy Rog Arpt",
    airport_city_code: "KWG",
    airport_city_name: "Krivoy Rog",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.0433",
    airport_lon: "33.21",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1860,
    airport_code: "KWI",
    airport_name: "Kuwait Intl",
    airport_city_code: "KWI",
    airport_city_name: "Kuwait",
    airport_country_name: "KUWAIT",
    airport_country_code: "KW",
    airport_timezone: "3",
    airport_lat: "29.226567",
    airport_lon: "47.968928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1861,
    airport_code: "KWJ",
    airport_name: "Kwangju Arpt",
    airport_city_code: "KWJ",
    airport_city_name: "Kwangju",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.126389",
    airport_lon: "126.808889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1862,
    airport_code: "KWL",
    airport_name: "Guilin Airport",
    airport_city_code: "KWL",
    airport_city_name: "Guilin",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "25.218106",
    airport_lon: "110.039197",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1863,
    airport_code: "KXK",
    airport_name: "Komsomolsk Na Amure Arpt",
    airport_city_code: "KXK",
    airport_city_name: "Komsomolsk Na Amure",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "11",
    airport_lat: "50.4094",
    airport_lon: "136.934",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1864,
    airport_code: "KYA",
    airport_name: "Konya Arpt",
    airport_city_code: "KYA",
    airport_city_name: "Konya",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.979",
    airport_lon: "32.561861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1865,
    airport_code: "KYL",
    airport_name: "Port Largo Arpt",
    airport_city_code: "KYL",
    airport_city_name: "Key Largo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1866,
    airport_code: "KYN",
    airport_name: "Milton Keynes Rail Station",
    airport_city_code: "KYN",
    airport_city_name: "Milton Keynes",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1867,
    airport_code: "KYS",
    airport_name: "KAYES",
    airport_city_code: "KYS",
    airport_city_name: "KAYES",
    airport_country_name: "MALI",
    airport_country_code: "ML",
    airport_timezone: "0",
    airport_lat: "14.481233",
    airport_lon: "-11.404397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1868,
    airport_code: "KYZ",
    airport_name: "Kyzyl Airport",
    airport_city_code: "KYZ",
    airport_city_name: "Kyzyl Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "51.6694",
    airport_lon: "94.4006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1869,
    airport_code: "KZB",
    airport_name: "Zachar Bay Sea Plane Base",
    airport_city_code: "KZB",
    airport_city_name: "Zachar Bay",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "57.55",
    airport_lon: "-153.75",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1870,
    airport_code: "KZI",
    airport_name: "Philippos Arpt",
    airport_city_code: "KZI",
    airport_city_name: "Kozani",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "40.28611",
    airport_lon: "21.840834",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1871,
    airport_code: "KZN",
    airport_name: "Kazan Arpt",
    airport_city_code: "KZN",
    airport_city_name: "Kazan",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "55.606186",
    airport_lon: "49.278728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1872,
    airport_code: "KZO",
    airport_name: "Kzyl Orda Arpt",
    airport_city_code: "KZO",
    airport_city_name: "Kzyl Orda",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "44.709",
    airport_lon: "65.591",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1873,
    airport_code: "KZR",
    airport_name: "Zafer Arpt",
    airport_city_code: "KZR",
    airport_city_name: "Kütahya Zafer Arpt",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.4",
    airport_lon: "30",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1874,
    airport_code: "KZS",
    airport_name: "Kastelorizo Arpt",
    airport_city_code: "KZS",
    airport_city_name: "Kastelorizo",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.127777",
    airport_lon: "29.566656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1875,
    airport_code: "LAA",
    airport_name: "Lamar Arpt",
    airport_city_code: "LAA",
    airport_city_name: "Lamar",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.069694",
    airport_lon: "-102.6885",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1876,
    airport_code: "LAD",
    airport_name: "Four De Fevereiro Arpt",
    airport_city_code: "LAD",
    airport_city_name: "Luanda",
    airport_country_name: "ANGOLA",
    airport_country_code: "AO",
    airport_timezone: "1",
    airport_lat: "-8.858375",
    airport_lon: "13.231178",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1877,
    airport_code: "LAE",
    airport_name: "Nadzab Arpt",
    airport_city_code: "LAE",
    airport_city_name: "Lae Pg",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-6.569828",
    airport_lon: "146.726242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1878,
    airport_code: "LAF",
    airport_name: "Lafayette Arpt",
    airport_city_code: "LAF",
    airport_city_name: "Lafayette",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.412306",
    airport_lon: "-86.936889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1879,
    airport_code: "LAG",
    airport_name: "La Guaira Arpt",
    airport_city_code: "LAG",
    airport_city_name: "La Guaira Arpt",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.6",
    airport_lon: "-66.933334",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1880,
    airport_code: "LAI",
    airport_name: "Servel Airport",
    airport_city_code: "LAI",
    airport_city_name: "Lannion",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.754378",
    airport_lon: "-3.471656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1881,
    airport_code: "LAJ",
    airport_name: "Lages Arpt",
    airport_city_code: "LAJ",
    airport_city_name: "Lajes",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-27.7828",
    airport_lon: "-50.2806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1882,
    airport_code: "LAL",
    airport_name: "Lakeland Arpt",
    airport_city_code: "LAL",
    airport_city_name: "Lakeland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "27.988917",
    airport_lon: "-82.018556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1883,
    airport_code: "LAM",
    airport_name: "Los Alamos Municipal",
    airport_city_code: "LAM",
    airport_city_name: "Los Alamos",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.879802",
    airport_lon: "-106.269415",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1884,
    airport_code: "LAN",
    airport_name: "Lansing Arpt",
    airport_city_code: "LAN",
    airport_city_name: "Lansing",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.7787",
    airport_lon: "-84.587357",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1885,
    airport_code: "LAO",
    airport_name: "Laoag Arpt",
    airport_city_code: "LAO",
    airport_city_name: "Laoag",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "18.178092",
    airport_lon: "120.531522",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1886,
    airport_code: "LAP",
    airport_name: "Aeropuerto Gen Marquez De Leon",
    airport_city_code: "LAP",
    airport_city_name: "La Paz",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "24.072694",
    airport_lon: "-110.362475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1887,
    airport_code: "SAA",
    airport_name: "Lar Shively Arpt",
    airport_city_code: "LAR",
    airport_city_name: "Laramie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.444859",
    airport_lon: "-106.823526",
    airport_num_airports: 2,
  },
  {
    airport_id: 1888,
    airport_code: "LAR",
    airport_name: "General Brees Fld",
    airport_city_code: "LAR",
    airport_city_name: "Laramie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "41.3121",
    airport_lon: "-105.675",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1889,
    airport_code: "HSH",
    airport_name: "Henderson Sky Harbor Arpt",
    airport_city_code: "LAS",
    airport_city_name: "Las Vegas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "35.972778",
    airport_lon: "-115.134444",
    airport_num_airports: 4,
  },
  {
    airport_id: 1890,
    airport_code: "VGT",
    airport_name: "Las Vegas North Air Terminal",
    airport_city_code: "LAS",
    airport_city_name: "Las Vegas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "36.210694",
    airport_lon: "-115.194444",
    airport_num_airports: 4,
  },
  {
    airport_id: 1891,
    airport_code: "LAS",
    airport_name: "McCarran Intl",
    airport_city_code: "LAS",
    airport_city_name: "Las Vegas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "36.080056",
    airport_lon: "-115.15225",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 1892,
    airport_code: "BLD",
    airport_name: "Boulder City Municipal Arpt",
    airport_city_code: "LAS",
    airport_city_name: "Las Vegas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "35.5651",
    airport_lon: "-114.514",
    airport_num_airports: 4,
  },
  {
    airport_id: 1893,
    airport_code: "LAU",
    airport_name: "Lamu Arpt",
    airport_city_code: "LAU",
    airport_city_name: "Lamu",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-2.252417",
    airport_lon: "40.913097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1894,
    airport_code: "LAW",
    airport_name: "Lawton Municipal",
    airport_city_code: "LAW",
    airport_city_name: "Lawton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.567714",
    airport_lon: "-98.416637",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1895,
    airport_code: "VNY",
    airport_name: "Van Nuys Arpt",
    airport_city_code: "LAX",
    airport_city_name: "Los Angeles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.209811",
    airport_lon: "-118.489972",
    airport_num_airports: 3,
  },
  {
    airport_id: 1896,
    airport_code: "SMO",
    airport_name: "Santa Monica Municipal Arpt",
    airport_city_code: "LAX",
    airport_city_name: "Los Angeles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "34.015833",
    airport_lon: "-118.451306",
    airport_num_airports: 3,
  },
  {
    airport_id: 1897,
    airport_code: "LAX",
    airport_name: "Los Angeles Intl Arpt",
    airport_city_code: "LAX",
    airport_city_name: "Los Angeles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.942536",
    airport_lon: "-118.408075",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1898,
    airport_code: "LAY",
    airport_name: "Ladysmith Arpt",
    airport_city_code: "LAY",
    airport_city_name: "Ladysmith",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-28.581667",
    airport_lon: "29.749722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1899,
    airport_code: "LBA",
    airport_name: "Leeds Bradford Arpt",
    airport_city_code: "LBA",
    airport_city_name: "Leeds",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.865897",
    airport_lon: "-1.660569",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1900,
    airport_code: "LBB",
    airport_name: "Lubbock Preston Smith Intl Arpt",
    airport_city_code: "LBB",
    airport_city_name: "Lubbock",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.663639",
    airport_lon: "-101.822778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1901,
    airport_code: "LBD",
    airport_name: "Khudzhand Arpt",
    airport_city_code: "LBD",
    airport_city_name: "LBD",
    airport_country_name: "TAJIKISTAN",
    airport_country_code: "TJ",
    airport_timezone: "5",
    airport_lat: "40.2154",
    airport_lon: "69.6947",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1902,
    airport_code: "LBE",
    airport_name: "Westmorland County",
    airport_city_code: "LBE",
    airport_city_name: "Latrobe",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.2759",
    airport_lon: "-79.4048",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1903,
    airport_code: "LBF",
    airport_name: "Lee Bird Field",
    airport_city_code: "LBF",
    airport_city_name: "North Platte",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.1262",
    airport_lon: "-100.684",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1904,
    airport_code: "LBI",
    airport_name: "Le Sequestre Arpt",
    airport_city_code: "LBI",
    airport_city_name: "Albi",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.913887",
    airport_lon: "2.113056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1905,
    airport_code: "LBL",
    airport_name: "Liberal Municipal",
    airport_city_code: "LBL",
    airport_city_name: "Liberal",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.044222",
    airport_lon: "-100.95986",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1906,
    airport_code: "LBP",
    airport_name: "Long Banga Airfield Arpt",
    airport_city_code: "LBP",
    airport_city_name: "Long Banga",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.18495",
    airport_lon: "115.454",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1907,
    airport_code: "LBS",
    airport_name: "Labasa Arpt",
    airport_city_code: "LBS",
    airport_city_name: "Labasa",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "12",
    airport_lat: "-16.466749",
    airport_lon: "179.33986",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1908,
    airport_code: "LBT",
    airport_name: "Lumberton Arpt",
    airport_city_code: "LBT",
    airport_city_name: "Lumberton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1909,
    airport_code: "LBU",
    airport_name: "Labuan Arpt",
    airport_city_code: "LBU",
    airport_city_name: "Labuan",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.300683",
    airport_lon: "115.250181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1910,
    airport_code: "LBV",
    airport_name: "Libreville Arpt",
    airport_city_code: "LBV",
    airport_city_name: "Libreville",
    airport_country_name: "GABON",
    airport_country_code: "GA",
    airport_timezone: "1",
    airport_lat: "0.4586",
    airport_lon: "9.412283",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1911,
    airport_code: "LBY",
    airport_name: "Montoir Arpt",
    airport_city_code: "LBY",
    airport_city_name: "La Baule",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1912,
    airport_code: "LCA",
    airport_name: "Larnaca Intl",
    airport_city_code: "LCA",
    airport_city_name: "Larnaca",
    airport_country_name: "CYPRUS",
    airport_country_code: "CY",
    airport_timezone: "2",
    airport_lat: "34.875117",
    airport_lon: "33.62485",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1913,
    airport_code: "LCC",
    airport_name: "Galatina Arpt",
    airport_city_code: "LCC",
    airport_city_name: "Lecce",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "40.239228",
    airport_lon: "18.133325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1914,
    airport_code: "LCE",
    airport_name: "Goloson Intl Arpt",
    airport_city_code: "LCE",
    airport_city_name: "La Ceiba",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-6",
    airport_lat: "15.742481",
    airport_lon: "-86.853036",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1915,
    airport_code: "LCF",
    airport_name: "Las Vegas Airport",
    airport_city_code: "LCF",
    airport_city_name: "Rio Dulce",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1916,
    airport_code: "LCG",
    airport_name: "La Coruna Arpt",
    airport_city_code: "LCG",
    airport_city_name: "La Coruna",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "43.302061",
    airport_lon: "-8.377256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1917,
    airport_code: "LCH",
    airport_name: "Lake Charles Municipal",
    airport_city_code: "LCH",
    airport_city_name: "Lake Charles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.126112",
    airport_lon: "-93.223335",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1918,
    airport_code: "LCI",
    airport_name: "Laconia Municipal Arpt",
    airport_city_code: "LCI",
    airport_city_name: "Laconia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "43.5736",
    airport_lon: "-71.4189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1919,
    airport_code: "LCJ",
    airport_name: "Lodz Lublinek Arpt",
    airport_city_code: "LCJ",
    airport_city_name: "Lodz",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "51.721881",
    airport_lon: "19.398133",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1920,
    airport_code: "LCV",
    airport_name: "Lucca Arpt",
    airport_city_code: "LCV",
    airport_city_name: "Lucca",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1921,
    airport_code: "LDB",
    airport_name: "Londrina Arpt",
    airport_city_code: "LDB",
    airport_city_name: "Londrina",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.333625",
    airport_lon: "-51.130072",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1922,
    airport_code: "LDE",
    airport_name: "Tarbes Intl Arpt",
    airport_city_code: "LDE",
    airport_city_name: "Lourdes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.178675",
    airport_lon: "-0.006439",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1923,
    airport_code: "LDG",
    airport_name: "Leshukonskoye Airport",
    airport_city_code: "LDG",
    airport_city_name: "Leshukonskoye Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "64.895833",
    airport_lon: "45.722778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1924,
    airport_code: "LDH",
    airport_name: "Lord Howe Island Arpt",
    airport_city_code: "LDH",
    airport_city_name: "Lord Howe Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-31.5383",
    airport_lon: "159.077",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1925,
    airport_code: "LDJ",
    airport_name: "Linden Municipal",
    airport_city_code: "LDJ",
    airport_city_name: "Linden",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1926,
    airport_code: "LDK",
    airport_name: "Hovby Airport",
    airport_city_code: "LDK",
    airport_city_name: "Lidkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "58.465522",
    airport_lon: "13.174414",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1927,
    airport_code: "LDU",
    airport_name: "Lahad Datu Arpt",
    airport_city_code: "LDU",
    airport_city_name: "Lahad Datu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.032247",
    airport_lon: "118.324036",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1928,
    airport_code: "LDX",
    airport_name: "Saint Laurent du Maroni Arpt",
    airport_city_code: "LDX",
    airport_city_name: "Saint Laurent du Maroni",
    airport_country_name: "FRENCH GUIANA",
    airport_country_code: "GF",
    airport_timezone: "-4",
    airport_lat: "5.483056",
    airport_lon: "-54.034444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1929,
    airport_code: "LDY",
    airport_name: "Eglinton Arpt",
    airport_city_code: "LDY",
    airport_city_name: "Londonderry",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.042778",
    airport_lon: "-7.161111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1930,
    airport_code: "LEA",
    airport_name: "Learmonth Arpt",
    airport_city_code: "LEA",
    airport_city_name: "Learmonth",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-22.235556",
    airport_lon: "114.088611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1931,
    airport_code: "CNH",
    airport_name: "Claremont Municipal",
    airport_city_code: "LEB",
    airport_city_name: "Lebanon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1932,
    airport_code: "LEB",
    airport_name: "Lebanon Regional",
    airport_city_code: "LEB",
    airport_city_name: "Lebanon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.6261",
    airport_lon: "-72.3042",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1933,
    airport_code: "LEC",
    airport_name: "Chapada Diamantina Arpt",
    airport_city_code: "LEC",
    airport_city_name: "Lencois",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-12.4823",
    airport_lon: "-41.277",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1934,
    airport_code: "LED",
    airport_name: "Pulkovo Airport",
    airport_city_code: "LED",
    airport_city_name: "St Petersburg",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "59.800292",
    airport_lon: "30.262503",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 1935,
    airport_code: "RVH",
    airport_name: "Rzhevka Arpt",
    airport_city_code: "LED",
    airport_city_name: "St Petersburg",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 1936,
    airport_code: "LEE",
    airport_name: "Leesburg Municipal Arpt",
    airport_city_code: "LEE",
    airport_city_name: "Leesburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1937,
    airport_code: "LEH",
    airport_name: "Octeville Arpt",
    airport_city_code: "LEH",
    airport_city_name: "Le Havre",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.533889",
    airport_lon: "0.088056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1938,
    airport_code: "LEI",
    airport_name: "Almeria Arpt",
    airport_city_code: "LEI",
    airport_city_name: "Almeria",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "36.843936",
    airport_lon: "-2.370097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1939,
    airport_code: "LEJ",
    airport_name: "Leipzig Arpt",
    airport_city_code: "LEJ",
    airport_city_name: "Leipzig",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.432447",
    airport_lon: "12.241633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1940,
    airport_code: "LEN",
    airport_name: "Leon Arpt",
    airport_city_code: "LEN",
    airport_city_name: "Leon",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "42.589",
    airport_lon: "-5.655556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1941,
    airport_code: "LEQ",
    airport_name: "St Just Arpt",
    airport_city_code: "LEQ",
    airport_city_name: "Lands End",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.1028",
    airport_lon: "-5.67056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1942,
    airport_code: "LER",
    airport_name: "Leinster Arpt",
    airport_city_code: "LER",
    airport_city_name: "Leinster",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-27.8433",
    airport_lon: "120.703",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1943,
    airport_code: "LES",
    airport_name: "Lesobeng Arpt",
    airport_city_code: "LES",
    airport_city_name: "Lesobeng",
    airport_country_name: "LESOTHO",
    airport_country_code: "LS",
    airport_timezone: "2",
    airport_lat: "47.75",
    airport_lon: "10.601",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1944,
    airport_code: "LET",
    airport_name: "General A V Cobo Arpt",
    airport_city_code: "LET",
    airport_city_name: "Leticia",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "-4.193549",
    airport_lon: "-69.943163",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1945,
    airport_code: "LEW",
    airport_name: "Auburn Lewiston Arpt",
    airport_city_code: "LEW",
    airport_city_name: "Lewiston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1946,
    airport_code: "LEX",
    airport_name: "Blue Grass Field",
    airport_city_code: "LEX",
    airport_city_name: "Lexington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.0365",
    airport_lon: "-84.605889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1947,
    airport_code: "LEY",
    airport_name: "Lelystad Arpt",
    airport_city_code: "LEY",
    airport_city_name: "Lelystad",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "52.4603",
    airport_lon: "5.52722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1948,
    airport_code: "LFK",
    airport_name: "Angelina Cty Arpt",
    airport_city_code: "LFK",
    airport_city_name: "Lufkin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.234014",
    airport_lon: "-94.75",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1949,
    airport_code: "LFT",
    airport_name: "Lafayette Municipal",
    airport_city_code: "LFT",
    airport_city_name: "Lafayette",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.205278",
    airport_lon: "-91.987611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1950,
    airport_code: "LFW",
    airport_name: "Lome Airport",
    airport_city_code: "LFW",
    airport_city_name: "Lome",
    airport_country_name: "TOGO",
    airport_country_code: "TG",
    airport_timezone: "1",
    airport_lat: "6.165611",
    airport_lon: "1.254511",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1951,
    airport_code: "LGB",
    airport_name: "Long Beach Municipal",
    airport_city_code: "LGB",
    airport_city_name: "Long Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.817722",
    airport_lon: "-118.151611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1952,
    airport_code: "LGC",
    airport_name: "Calloway Arpt",
    airport_city_code: "LGC",
    airport_city_name: "La Grange",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "33.008861",
    airport_lon: "-85.072611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1953,
    airport_code: "LGG",
    airport_name: "Bierset Airport",
    airport_city_code: "LGG",
    airport_city_name: "Liege",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "1",
    airport_lat: "50.637417",
    airport_lon: "5.443222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1954,
    airport_code: "LGI",
    airport_name: "Deadmans Cay Arpt",
    airport_city_code: "LGI",
    airport_city_name: "Deadmans Cay",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "23.179014",
    airport_lon: "-75.093597",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1955,
    airport_code: "LGK",
    airport_name: "Langkawi Arpt",
    airport_city_code: "LGK",
    airport_city_name: "Langkawi",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "6.329728",
    airport_lon: "99.728667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1956,
    airport_code: "LGL",
    airport_name: "Long Lellang Arpt",
    airport_city_code: "LGL",
    airport_city_name: "Long Lellang",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.421",
    airport_lon: "115.154",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1957,
    airport_code: "LGP",
    airport_name: "Legaspi Arpt",
    airport_city_code: "LGP",
    airport_city_name: "Legaspi",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "13.157064",
    airport_lon: "123.746247",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1958,
    airport_code: "LGS",
    airport_name: "Malargue Arpt",
    airport_city_code: "LGS",
    airport_city_name: "Malargue",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-35.493597",
    airport_lon: "-69.574267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1959,
    airport_code: "LGU",
    airport_name: "Logan Arpt",
    airport_city_code: "LGU",
    airport_city_name: "Logan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "41.791",
    airport_lon: "-111.852",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1960,
    airport_code: "LHA",
    airport_name: "Lahr Arpt",
    airport_city_code: "LHA",
    airport_city_name: "Lahr",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.3693",
    airport_lon: "7.82772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1961,
    airport_code: "LHE",
    airport_name: "Lahore Arpt",
    airport_city_code: "LHE",
    airport_city_name: "Lahore",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "31.521564",
    airport_lon: "74.403594",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1962,
    airport_code: "LHG",
    airport_name: "Lightning Ridge Arpt",
    airport_city_code: "LHG",
    airport_city_name: "Lightning Ridge",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-29.4567",
    airport_lon: "147.984",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1963,
    airport_code: "LHW",
    airport_name: "Lanzhou Arpt",
    airport_city_code: "LHW",
    airport_city_name: "Lanzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "36.117",
    airport_lon: "103.617",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1964,
    airport_code: "LIA",
    airport_name: "Lianping Arpt",
    airport_city_code: "LIA",
    airport_city_name: "Lianping",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1965,
    airport_code: "LIF",
    airport_name: "Lifou Arpt",
    airport_city_code: "LIF",
    airport_city_name: "Lifou",
    airport_country_name: "NEW CALEDONIA",
    airport_country_code: "NC",
    airport_timezone: "11",
    airport_lat: "-20.7748",
    airport_lon: "167.239864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1966,
    airport_code: "LIG",
    airport_name: "Bellegarde Arpt",
    airport_city_code: "LIG",
    airport_city_name: "Limoges",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.862778",
    airport_lon: "1.179444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1967,
    airport_code: "LIH",
    airport_name: "Lihue Municipal",
    airport_city_code: "LIH",
    airport_city_name: "Lihue",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "21.975983",
    airport_lon: "-159.338958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1968,
    airport_code: "XDB",
    airport_name: "Europe Railway Service",
    airport_city_code: "LIL",
    airport_city_name: "Lille",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "50.563333",
    airport_lon: "3.08805",
    airport_num_airports: 3,
  },
  {
    airport_id: 1969,
    airport_code: "LIL",
    airport_name: "Lesquin Arpt",
    airport_city_code: "LIL",
    airport_city_name: "Lille",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "50.561942",
    airport_lon: "3.089444",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 1970,
    airport_code: "XFA",
    airport_name: "Lille Flanders Rail Service",
    airport_city_code: "LIL",
    airport_city_name: "Lille",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "50.6325",
    airport_lon: "3.0583",
    airport_num_airports: 3,
  },
  {
    airport_id: 1971,
    airport_code: "LIM",
    airport_name: "Jorge Chavez International Arpt",
    airport_city_code: "LIM",
    airport_city_name: "Lima",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-12.021889",
    airport_lon: "-77.114319",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1972,
    airport_code: "LIO",
    airport_name: "Limon Arpt",
    airport_city_code: "LIO",
    airport_city_name: "Limon",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "9.957961",
    airport_lon: "-83.022006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1973,
    airport_code: "LIR",
    airport_name: "Liberia Arpt",
    airport_city_code: "LIR",
    airport_city_name: "Liberia",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "10.593289",
    airport_lon: "-85.544408",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1974,
    airport_code: "LIS",
    airport_name: "Lisboa",
    airport_city_code: "LIS",
    airport_city_name: "Lisbon",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "38.781311",
    airport_lon: "-9.135919",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1975,
    airport_code: "LIT",
    airport_name: "Little Rock Regional",
    airport_city_code: "LIT",
    airport_city_name: "Little Rock",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.729444",
    airport_lon: "-92.224306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1976,
    airport_code: "LJG",
    airport_name: "Lijiang Arpt",
    airport_city_code: "LJG",
    airport_city_name: "Lijiang City",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "26.883333",
    airport_lon: "100.23333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1977,
    airport_code: "LJN",
    airport_name: "Lake Jackson Arpt",
    airport_city_code: "LJN",
    airport_city_name: "Lake Jackson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1978,
    airport_code: "LJU",
    airport_name: "Brnik Airport",
    airport_city_code: "LJU",
    airport_city_name: "Ljubljana",
    airport_country_name: "SLOVENIA",
    airport_country_code: "SI",
    airport_timezone: "1",
    airport_lat: "46.223686",
    airport_lon: "14.457611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1979,
    airport_code: "LKG",
    airport_name: "Lokichoggio Arpt",
    airport_city_code: "LKG",
    airport_city_name: "Lokichoggio",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "4.204117",
    airport_lon: "34.348186",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1980,
    airport_code: "LKH",
    airport_name: "Long Akah Arpt",
    airport_city_code: "LKH",
    airport_city_name: "Long Akah",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.3",
    airport_lon: "114.783",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1981,
    airport_code: "LKL",
    airport_name: "Banak Airport",
    airport_city_code: "LKL",
    airport_city_name: "Lakselv",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "70.068814",
    airport_lon: "24.973489",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1982,
    airport_code: "LKN",
    airport_name: "Leknes Arpt",
    airport_city_code: "LKN",
    airport_city_name: "Leknes",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "68.1525",
    airport_lon: "13.6094",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1983,
    airport_code: "LKO",
    airport_name: "Amausi Arpt",
    airport_city_code: "LKO",
    airport_city_name: "Lucknow",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "26.760594",
    airport_lon: "80.889339",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1984,
    airport_code: "LLA",
    airport_name: "Kallax Airport",
    airport_city_code: "LLA",
    airport_city_name: "Lulea",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "65.543758",
    airport_lon: "22.121989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1985,
    airport_code: "LLE",
    airport_name: "Municipal Arpt",
    airport_city_code: "LLE",
    airport_city_name: "Malelane",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1986,
    airport_code: "LLF",
    airport_name: "Ling Ling Arpt",
    airport_city_code: "LLF",
    airport_city_name: "Ling Ling",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "26.7008",
    airport_lon: "111.9575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1987,
    airport_code: "LLK",
    airport_name: "Lankaran International Arpt",
    airport_city_code: "LLK",
    airport_city_name: "Lankaran International Arpt",
    airport_country_name: "AZERBAIJAN",
    airport_country_code: "AZ",
    airport_timezone: "5",
    airport_lat: "38.74203",
    airport_lon: "48.824093",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1988,
    airport_code: "LLU",
    airport_name: "Alluitsup Paa Arpt",
    airport_city_code: "LLU",
    airport_city_name: "Alluitsup Paa",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "60.4644",
    airport_lon: "-45.5778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1989,
    airport_code: "LLW",
    airport_name: "Lilongwe Intl Arpt",
    airport_city_code: "LLW",
    airport_city_name: "Lilongwe",
    airport_country_name: "MALAWI",
    airport_country_code: "MW",
    airport_timezone: "2",
    airport_lat: "-13.789378",
    airport_lon: "33.781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1990,
    airport_code: "LLY",
    airport_name: "Burlington Country Arpt",
    airport_city_code: "LLY",
    airport_city_name: "Mount Holly",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1991,
    airport_code: "LME",
    airport_name: "Arnage Arpt",
    airport_city_code: "LME",
    airport_city_name: "Le Mans",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.948611",
    airport_lon: "0.201667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1992,
    airport_code: "LMM",
    airport_name: "Federal Los Mochis Arpt",
    airport_city_code: "LMM",
    airport_city_name: "Los Mochis",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "25.685194",
    airport_lon: "-109.080806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1993,
    airport_code: "LMN",
    airport_name: "Limbang Arpt",
    airport_city_code: "LMN",
    airport_city_name: "Limbang",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.808303",
    airport_lon: "115.010439",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1994,
    airport_code: "LMP",
    airport_name: "Lampedusa Arpt",
    airport_city_code: "LMP",
    airport_city_name: "Lampedusa",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "35.497914",
    airport_lon: "12.618083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1995,
    airport_code: "LMS",
    airport_name: "Louisville Winston County Arpt",
    airport_city_code: "LMS",
    airport_city_name: "Louisville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "33.1462",
    airport_lon: "-89.0625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1996,
    airport_code: "LMT",
    airport_name: "Kingsley Field",
    airport_city_code: "LMT",
    airport_city_name: "Klamath Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "42.1561",
    airport_lon: "-121.733",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1997,
    airport_code: "LNK",
    airport_name: "Lincoln Municipal",
    airport_city_code: "LNK",
    airport_city_name: "Lincoln",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.850971",
    airport_lon: "-96.75925",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1998,
    airport_code: "LNO",
    airport_name: "Leonora Arpt",
    airport_city_code: "LNO",
    airport_city_name: "Leonora",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-28.8781",
    airport_lon: "121.315",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 1999,
    airport_code: "LNS",
    airport_name: "Lancaster Arpt",
    airport_city_code: "LNS",
    airport_city_name: "Lancaster",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.1217",
    airport_lon: "-76.2961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2000,
    airport_code: "LNV",
    airport_name: "Lihir Island Arpt",
    airport_city_code: "LNV",
    airport_city_name: "Lihir Island",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-3.04361",
    airport_lon: "152.629",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2001,
    airport_code: "LNY",
    airport_name: "Lanai Airport",
    airport_city_code: "LNY",
    airport_city_name: "Lanai",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "20.785611",
    airport_lon: "-156.951419",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2002,
    airport_code: "LNZ",
    airport_name: "Hoersching Arpt",
    airport_city_code: "LNZ",
    airport_city_name: "Linz",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "1",
    airport_lat: "48.233219",
    airport_lon: "14.187511",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2003,
    airport_code: "LOE",
    airport_name: "Loei Arpt",
    airport_city_code: "LOE",
    airport_city_name: "Loei",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "17.439133",
    airport_lon: "101.722064",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2004,
    airport_code: "LOH",
    airport_name: "Aeropuerto Camilo Enriquez",
    airport_city_code: "LOH",
    airport_city_name: "Loja",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-5",
    airport_lat: "-3.99589",
    airport_lon: "-79.3719",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2005,
    airport_code: "LOM",
    airport_name: "Francisco P V Y R",
    airport_city_code: "LOM",
    airport_city_name: "Lagos De Moreno",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2006,
    airport_code: "QQP",
    airport_name: "London - Paddington Rail Service",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.515833",
    airport_lon: "-0.176111",
    airport_num_airports: 12,
  },
  {
    airport_id: 2007,
    airport_code: "QQS",
    airport_name: "QQS St Pancras Intl Rail St",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.532519",
    airport_lon: "-0.1263",
    airport_num_airports: 12,
  },
  {
    airport_id: 2008,
    airport_code: "QQU",
    airport_name: "London - Euston Rail Service",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 12,
  },
  {
    airport_id: 2009,
    airport_code: "QQW",
    airport_name: "London - Waterloo Rail Service",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 12,
  },
  {
    airport_id: 2010,
    airport_code: "XQE",
    airport_name: "XQE Ebbsfleet Kent Rail St",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 12,
  },
  {
    airport_id: 2011,
    airport_code: "ZEP",
    airport_name: "London - Victoria Railway Station",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 12,
  },
  {
    airport_id: 2012,
    airport_code: "LGW",
    airport_name: "Gatwick Arpt",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.148056",
    airport_lon: "-0.190278",
    airport_num_airports: 12,
  },
  {
    airport_id: 2013,
    airport_code: "LHR",
    airport_name: "Heathrow",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.4775",
    airport_lon: "-0.461389",
    airport_num_airports: 12,
  },
  {
    airport_id: 2014,
    airport_code: "QQK",
    airport_name: "London - Kings Cross Rail Service",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "20.2461",
    airport_lon: "0.9808",
    airport_num_airports: 12,
  },
  {
    airport_id: 2015,
    airport_code: "LCY",
    airport_name: "London City Arpt",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.505278",
    airport_lon: "0.055278",
    airport_num_airports: 12,
  },
  {
    airport_id: 2016,
    airport_code: "STN",
    airport_name: "Stansted Arpt",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.885",
    airport_lon: "0.235",
    airport_num_airports: 12,
  },
  {
    airport_id: 2017,
    airport_code: "QQH",
    airport_name: "Harwich Rail Station",
    airport_city_code: "LON",
    airport_city_name: "London",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 12,
  },
  {
    airport_id: 2018,
    airport_code: "LOS",
    airport_name: "Murtala Muhammed Arpt",
    airport_city_code: "LOS",
    airport_city_name: "Lagos",
    airport_country_name: "NIGERIA",
    airport_country_code: "NG",
    airport_timezone: "1",
    airport_lat: "6.577369",
    airport_lon: "3.321156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2019,
    airport_code: "LOV",
    airport_name: "Monclova Arpt",
    airport_city_code: "LOV",
    airport_city_name: "Monclova",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "26.955733",
    airport_lon: "-101.470136",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2020,
    airport_code: "LOZ",
    airport_name: "Corbin London Arpt",
    airport_city_code: "LOZ",
    airport_city_name: "London",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "37.087",
    airport_lon: "-84.0773",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2021,
    airport_code: "LPA",
    airport_name: "Aeropuerto De Gran Canaria",
    airport_city_code: "LPA",
    airport_city_name: "Gran Canaria",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "27.931886",
    airport_lon: "-15.386586",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2022,
    airport_code: "LPB",
    airport_name: "El Alto Arpt",
    airport_city_code: "LPB",
    airport_city_name: "La Paz",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-16.513339",
    airport_lon: "-68.192256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2023,
    airport_code: "LPG",
    airport_name: "La Plata Arpt",
    airport_city_code: "LPG",
    airport_city_name: "La Plata",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-34.972222",
    airport_lon: "-57.894694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2024,
    airport_code: "LPH",
    airport_name: "Lochgilphead Heliport",
    airport_city_code: "LPH",
    airport_city_name: "Lochgilphead",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2025,
    airport_code: "LPI",
    airport_name: "Saab Airport",
    airport_city_code: "LPI",
    airport_city_name: "Linkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "58.40615",
    airport_lon: "15.680508",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2026,
    airport_code: "LPK",
    airport_name: "Lipetsk Airport",
    airport_city_code: "LPK",
    airport_city_name: "Lipetsk Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "52.7028",
    airport_lon: "39.5378",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2027,
    airport_code: "LPL",
    airport_name: "Liverpool Arpt",
    airport_city_code: "LPL",
    airport_city_name: "Liverpool",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.333611",
    airport_lon: "-2.849722",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2028,
    airport_code: "LON",
    airport_name: "london all aiport",
    airport_city_code: "LON",
    airport_city_name: "london",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 12,
    airport_city: "true",
  },
  {
    airport_id: 2029,
    airport_code: "XWN",
    airport_name: "Warrington B Q Rail Station",
    airport_city_code: "LPL",
    airport_city_name: "Liverpool",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2030,
    airport_code: "LPO",
    airport_name: "La Porte Arpt",
    airport_city_code: "LPO",
    airport_city_name: "La Porte",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2031,
    airport_code: "LPP",
    airport_name: "Lappeenranta Arpt",
    airport_city_code: "LPP",
    airport_city_name: "Lappeenranta",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "61.044553",
    airport_lon: "28.144397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2032,
    airport_code: "LPQ",
    airport_name: "Louangphrabang Arpt",
    airport_city_code: "LPQ",
    airport_city_name: "Louangphrabang",
    airport_country_name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    airport_country_code: "LA",
    airport_timezone: "7",
    airport_lat: "19.897914",
    airport_lon: "102.160764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2033,
    airport_code: "LPS",
    airport_name: "Lopez Island Arpt",
    airport_city_code: "LPS",
    airport_city_name: "Lopez Island",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.4839",
    airport_lon: "-122.938",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2034,
    airport_code: "LPT",
    airport_name: "Lampang Arpt",
    airport_city_code: "LPT",
    airport_city_name: "Lampang",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "18.270933",
    airport_lon: "99.504167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2035,
    airport_code: "LPX",
    airport_name: "Liepaya Arpt",
    airport_city_code: "LPX",
    airport_city_name: "Liepaja",
    airport_country_name: "LATVIA",
    airport_country_code: "LV",
    airport_timezone: "2",
    airport_lat: "56.5175",
    airport_lon: "21.096944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2036,
    airport_code: "LPY",
    airport_name: "Loudes Airport",
    airport_city_code: "LPY",
    airport_city_name: "Le Puy",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.080689",
    airport_lon: "3.762889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2037,
    airport_code: "LRA",
    airport_name: "Larisa Arpt",
    airport_city_code: "LRA",
    airport_city_name: "Larisa",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.650253",
    airport_lon: "22.4655",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2038,
    airport_code: "LRD",
    airport_name: "Laredo Intl",
    airport_city_code: "LRD",
    airport_city_name: "Laredo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "27.54375",
    airport_lon: "-99.461556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2039,
    airport_code: "LRE",
    airport_name: "Longreach Arpt",
    airport_city_code: "LRE",
    airport_city_name: "Longreach",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-23.4342",
    airport_lon: "144.28",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2040,
    airport_code: "LRH",
    airport_name: "Laleu Airport",
    airport_city_code: "LRH",
    airport_city_name: "La Rochelle",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.1792",
    airport_lon: "-1.19528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2041,
    airport_code: "LRM",
    airport_name: "La Romana Arpt",
    airport_city_code: "LRM",
    airport_city_name: "La Romana",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "18.450711",
    airport_lon: "-68.911833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2042,
    airport_code: "LRS",
    airport_name: "Leros Arpt",
    airport_city_code: "LRS",
    airport_city_name: "Leros",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.184903",
    airport_lon: "26.800289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2043,
    airport_code: "LRT",
    airport_name: "Lann Bihoue Arpt",
    airport_city_code: "LRT",
    airport_city_name: "Lorient",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.760555",
    airport_lon: "-3.44",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2044,
    airport_code: "LRU",
    airport_name: "Las Cruces Crawford Arpt",
    airport_city_code: "LRU",
    airport_city_name: "Las Cruces",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.289417",
    airport_lon: "-106.921972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2045,
    airport_code: "LSC",
    airport_name: "La Florida",
    airport_city_code: "LSC",
    airport_city_name: "La Serena",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-29.916233",
    airport_lon: "-71.199522",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2046,
    airport_code: "LSE",
    airport_name: "La Crosse Municipal",
    airport_city_code: "LSE",
    airport_city_name: "La Crosse",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "43.878986",
    airport_lon: "-91.256711",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2047,
    airport_code: "LSI",
    airport_name: "Sumburgh Airport",
    airport_city_code: "LSI",
    airport_city_name: "Lerwick",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.878889",
    airport_lon: "-1.295556",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2048,
    airport_code: "LWK",
    airport_name: "Tingwall Arpt",
    airport_city_code: "LSI",
    airport_city_name: "Lerwick",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "60.1922",
    airport_lon: "-1.24361",
    airport_num_airports: 2,
  },
  {
    airport_id: 2049,
    airport_code: "LSN",
    airport_name: "Los Banos Arpt",
    airport_city_code: "LSN",
    airport_city_name: "Los Banos",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2050,
    airport_code: "LSO",
    airport_name: "Talmont Arpt",
    airport_city_code: "LSO",
    airport_city_name: "Les Sables",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2051,
    airport_code: "LSP",
    airport_name: "Josefa Camejo Arpt",
    airport_city_code: "LSP",
    airport_city_name: "Las Piedras",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "11.780775",
    airport_lon: "-70.151497",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2052,
    airport_code: "LSQ",
    airport_name: "Maria Dolores Arpt",
    airport_city_code: "LSQ",
    airport_city_name: "Los Angeles",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-37.401731",
    airport_lon: "-72.425444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2053,
    airport_code: "LST",
    airport_name: "Launceston Arpt",
    airport_city_code: "LST",
    airport_city_name: "Launceston",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-41.545278",
    airport_lon: "147.214167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2054,
    airport_code: "LSY",
    airport_name: "Lismore Airport",
    airport_city_code: "LSY",
    airport_city_name: "Lismore",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-28.8303",
    airport_lon: "153.26",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2055,
    airport_code: "LTA",
    airport_name: "Letaba Aprt",
    airport_city_code: "LTA",
    airport_city_name: "Tzaneen",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-23.824417",
    airport_lon: "30.329306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2056,
    airport_code: "LTK",
    airport_name: "Hmelmin Airport",
    airport_city_code: "LTK",
    airport_city_name: "Latakia",
    airport_country_name: "SYRIA",
    airport_country_code: "SY",
    airport_timezone: "2",
    airport_lat: "35.401094",
    airport_lon: "35.948681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2057,
    airport_code: "LTN",
    airport_name: "Luton Arpt",
    airport_city_code: "LTN",
    airport_city_name: "Luton",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.874722",
    airport_lon: "-0.368333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2058,
    airport_code: "LTO",
    airport_name: "Loreto Intl Arpt",
    airport_city_code: "LTO",
    airport_city_name: "Loreto",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "25.989194",
    airport_lon: "-111.348361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2059,
    airport_code: "LTQ",
    airport_name: "Le Touquet Arpt",
    airport_city_code: "LTQ",
    airport_city_name: "Le Touquet",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "50.517385",
    airport_lon: "1.620587",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2060,
    airport_code: "AXS",
    airport_name: "Altus Municipal Arpt",
    airport_city_code: "LTS",
    airport_city_name: "Altus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 2061,
    airport_code: "LTT",
    airport_name: "La Mole Arpt",
    airport_city_code: "LTT",
    airport_city_name: "St Tropez",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.2054",
    airport_lon: "6.482",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2062,
    airport_code: "LTU",
    airport_name: "Latur Arpt",
    airport_city_code: "LTU",
    airport_city_name: "Latur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "18.411944",
    airport_lon: "76.465",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2063,
    airport_code: "LTX",
    airport_name: "Cotapaxi Intl Arpt",
    airport_city_code: "LTX",
    airport_city_name: "Latacunga",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-5",
    airport_lat: "-0.5425",
    airport_lon: "-78.3657",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2064,
    airport_code: "LUD",
    airport_name: "Luderitz Arpt",
    airport_city_code: "LUD",
    airport_city_name: "Luderitz",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-26.6874",
    airport_lon: "15.2429",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2065,
    airport_code: "LUG",
    airport_name: "Agno Airport",
    airport_city_code: "LUG",
    airport_city_name: "Lugano",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "46.004275",
    airport_lon: "8.910578",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2066,
    airport_code: "LUH",
    airport_name: "Ludhiana Arpt",
    airport_city_code: "LUH",
    airport_city_name: "Ludhiana",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "30.854681",
    airport_lon: "75.952592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2067,
    airport_code: "PIB",
    airport_name: "Hattiesburg Laurel Regional Arpt",
    airport_city_code: "LUL",
    airport_city_name: "Laurel",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.4671",
    airport_lon: "-89.3371",
    airport_num_airports: 2,
  },
  {
    airport_id: 2068,
    airport_code: "LUL",
    airport_name: "Hesler Noble Field",
    airport_city_code: "LUL",
    airport_city_name: "Laurel",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2069,
    airport_code: "LUN",
    airport_name: "Lusaka Airport",
    airport_city_code: "LUN",
    airport_city_name: "Lusaka",
    airport_country_name: "ZAMBIA",
    airport_country_code: "ZM",
    airport_timezone: "2",
    airport_lat: "-15.330817",
    airport_lon: "28.452628",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2070,
    airport_code: "LUP",
    airport_name: "Kalaupapa Municipal",
    airport_city_code: "LUP",
    airport_city_name: "Kalaupapa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "21.211",
    airport_lon: "-156.974",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2071,
    airport_code: "LUQ",
    airport_name: "San Luis Cty Arpt",
    airport_city_code: "LUQ",
    airport_city_name: "San Luis",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-33.273192",
    airport_lon: "-66.356422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2072,
    airport_code: "LUX",
    airport_name: "Luxembourg Arpt",
    airport_city_code: "LUX",
    airport_city_name: "Luxembourg",
    airport_country_name: "LUXEMBOURG",
    airport_country_code: "LU",
    airport_timezone: "1",
    airport_lat: "49.626575",
    airport_lon: "6.211517",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2073,
    airport_code: "LVI",
    airport_name: "Livingstone Arpt",
    airport_city_code: "LVI",
    airport_city_name: "Livingstone",
    airport_country_name: "ZAMBIA",
    airport_country_code: "ZM",
    airport_timezone: "2",
    airport_lat: "-17.821756",
    airport_lon: "25.822692",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2074,
    airport_code: "LVK",
    airport_name: "Bron Airport",
    airport_city_code: "LVK",
    airport_city_name: "Livermore",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.41362",
    airport_lon: "-121.49133",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2075,
    airport_code: "LVM",
    airport_name: "Mission Field",
    airport_city_code: "LVM",
    airport_city_name: "Livingston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "45.699389",
    airport_lon: "-110.448306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2076,
    airport_code: "LVO",
    airport_name: "Laverton Airport",
    airport_city_code: "LVO",
    airport_city_name: "Laverton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-28.6136",
    airport_lon: "122.424",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2077,
    airport_code: "LVS",
    airport_name: "Las Vegas Arpt",
    airport_city_code: "LVS",
    airport_city_name: "Las Vegas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.654222",
    airport_lon: "-105.142389",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2078,
    airport_code: "TCC",
    airport_name: "Tucumcari Arpt",
    airport_city_code: "LVS",
    airport_city_name: "Las Vegas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.182777",
    airport_lon: "-103.603186",
    airport_num_airports: 2,
  },
  {
    airport_id: 2079,
    airport_code: "LWB",
    airport_name: "Greenbrier Valley Arpt",
    airport_city_code: "LWB",
    airport_city_name: "Lewisburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.858333",
    airport_lon: "-80.399444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2080,
    airport_code: "LWC",
    airport_name: "Lawrence Municipal",
    airport_city_code: "LWC",
    airport_city_name: "Lawrence",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "39.009167",
    airport_lon: "-95.2175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2081,
    airport_code: "LWM",
    airport_name: "Lawrence Arpt",
    airport_city_code: "LWM",
    airport_city_name: "Lawrence",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "42.717194",
    airport_lon: "-71.123417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2082,
    airport_code: "LWO",
    airport_name: "Snilow Arpt",
    airport_city_code: "LWO",
    airport_city_name: "Lvov",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "49.8125",
    airport_lon: "23.956111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2083,
    airport_code: "LWS",
    airport_name: "Lewiston Nez Pierce Arpt",
    airport_city_code: "LWS",
    airport_city_name: "Lewiston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.3745",
    airport_lon: "-117.015389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2084,
    airport_code: "LWT",
    airport_name: "Lewistown Municipal",
    airport_city_code: "LWT",
    airport_city_name: "Lewistown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "47.0493",
    airport_lon: "-109.467",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2085,
    airport_code: "LWY",
    airport_name: "Lawas Arpt",
    airport_city_code: "LWY",
    airport_city_name: "Lawas",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.84917",
    airport_lon: "115.408",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2086,
    airport_code: "LXA",
    airport_name: "Lhasa Arpt",
    airport_city_code: "LXA",
    airport_city_name: "Lhasa",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.297778",
    airport_lon: "90.911944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2087,
    airport_code: "LXN",
    airport_name: "Jim Kelley Field",
    airport_city_code: "LXN",
    airport_city_name: "Lexington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2088,
    airport_code: "LXR",
    airport_name: "Luxor Airport",
    airport_city_code: "LXR",
    airport_city_name: "Luxor",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "25.671028",
    airport_lon: "32.706583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2089,
    airport_code: "LXS",
    airport_name: "Limnos Airport",
    airport_city_code: "LXS",
    airport_city_name: "Limnos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.917072",
    airport_lon: "25.236308",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2090,
    airport_code: "SEB",
    airport_name: "Sebha Airport",
    airport_city_code: "LY",
    airport_city_name: "Sebha Airport",
    airport_country_name: "LIBYAN ARAB JAMAHIRIYA",
    airport_country_code: "LY",
    airport_timezone: "2",
    airport_lat: "27.016666",
    airport_lon: "14.4625",
    airport_num_airports: 1,
  },
  {
    airport_id: 2091,
    airport_code: "LYA",
    airport_name: "Luoyang Arpt",
    airport_city_code: "LYA",
    airport_city_name: "Luoyang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.41",
    airport_lon: "112.28",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2092,
    airport_code: "LYB",
    airport_name: "Little Cayman Arpt",
    airport_city_code: "LYB",
    airport_city_name: "Little Cayman",
    airport_country_name: "CAYMAN ISLANDS",
    airport_country_code: "KY",
    airport_timezone: "-5",
    airport_lat: "19.659167",
    airport_lon: "-80.090833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2093,
    airport_code: "LYC",
    airport_name: "Hedlunda Arpt",
    airport_city_code: "LYC",
    airport_city_name: "Lycksele",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "64.548322",
    airport_lon: "18.716219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2094,
    airport_code: "LYG",
    airport_name: "Lianyungang Arpt",
    airport_city_code: "LYG",
    airport_city_name: "Lianyungang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.55",
    airport_lon: "119.25",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2095,
    airport_code: "LYH",
    airport_name: "Lynchburg Municipal",
    airport_city_code: "LYH",
    airport_city_name: "Lynchburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.3267",
    airport_lon: "-79.2004",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2096,
    airport_code: "LYI",
    airport_name: "Linyi Arpt",
    airport_city_code: "LYI",
    airport_city_name: "Linyi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "35.0461",
    airport_lon: "118.412",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2097,
    airport_code: "LYP",
    airport_name: "Faisalabad International Arpt",
    airport_city_code: "LYP",
    airport_city_name: "Faisalabad",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "31.365014",
    airport_lon: "72.994842",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2098,
    airport_code: "LYR",
    airport_name: "Svalbard Arpt",
    airport_city_code: "LYR",
    airport_city_name: "Longyearbyen",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "78.246111",
    airport_lon: "15.465556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2099,
    airport_code: "LYS",
    airport_name: "Lyon Saint Exupery Arpt",
    airport_city_code: "LYS",
    airport_city_name: "Lyon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.726387",
    airport_lon: "5.090833",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2100,
    airport_code: "XYD",
    airport_name: "Lyon Rail Station",
    airport_city_code: "LYS",
    airport_city_name: "Lyon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46",
    airport_lon: "5",
    airport_num_airports: 3,
  },
  {
    airport_id: 2101,
    airport_code: "LYN",
    airport_name: "Bron Arpt",
    airport_city_code: "LYS",
    airport_city_name: "Lyon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.727172",
    airport_lon: "4.944275",
    airport_num_airports: 3,
  },
  {
    airport_id: 2102,
    airport_code: "LYU",
    airport_name: "Ely Municipal Arpt",
    airport_city_code: "LYU",
    airport_city_name: "Ely Mn",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "47.824444",
    airport_lon: "-91.830833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2103,
    airport_code: "LYX",
    airport_name: "Lydd Intl Arpt",
    airport_city_code: "LYX",
    airport_city_name: "Lydd",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.956111",
    airport_lon: "0.939167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2104,
    airport_code: "LZC",
    airport_name: "Lazaro Cardenas Arpt",
    airport_city_code: "LZC",
    airport_city_name: "Lazaro Cardenas Michoacan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "18.001731",
    airport_lon: "-102.220525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2105,
    airport_code: "LZH",
    airport_name: "Liuzhou Airport",
    airport_city_code: "LZH",
    airport_city_name: "Liuzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "24.2075",
    airport_lon: "109.391",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2106,
    airport_code: "LZO",
    airport_name: "Luzhou Arpt",
    airport_city_code: "LZO",
    airport_city_name: "Luzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.8522",
    airport_lon: "105.393",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2107,
    airport_code: "LZR",
    airport_name: "Lizard Island Arpt",
    airport_city_code: "LZR",
    airport_city_name: "Lizard Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-14.673056",
    airport_lon: "145.454444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2108,
    airport_code: "MAA",
    airport_name: "Chennai Arpt",
    airport_city_code: "MAA",
    airport_city_name: "Chennai",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "12.994414",
    airport_lon: "80.180517",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2109,
    airport_code: "MAB",
    airport_name: "Maraba Arpt",
    airport_city_code: "MAB",
    airport_city_name: "Maraba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-5.368589",
    airport_lon: "-49.138025",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2110,
    airport_code: "MAD",
    airport_name: "Barajas Arpt",
    airport_city_code: "MAD",
    airport_city_name: "Madrid",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "40.493556",
    airport_lon: "-3.566764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2111,
    airport_code: "MAE",
    airport_name: "Madera Municipal Arpt",
    airport_city_code: "MAE",
    airport_city_name: "Madera",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "36.988611",
    airport_lon: "-120.112444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2112,
    airport_code: "MAF",
    airport_name: "Midland Intl Arpt",
    airport_city_code: "MAF",
    airport_city_name: "Midland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.942528",
    airport_lon: "-102.201914",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2113,
    airport_code: "MAG",
    airport_name: "Madang Arpt",
    airport_city_code: "MAG",
    airport_city_name: "Madang",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-5.207083",
    airport_lon: "145.7887",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2114,
    airport_code: "MAH",
    airport_name: "Aerop De Menorca",
    airport_city_code: "MAH",
    airport_city_name: "Menorca",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "39.862597",
    airport_lon: "4.218647",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2115,
    airport_code: "MAJ",
    airport_name: "Amata Kabua Intl Arpt",
    airport_city_code: "MAJ",
    airport_city_name: "Majuro",
    airport_country_name: "MARSHALL ISLANDS",
    airport_country_code: "MH",
    airport_timezone: "12",
    airport_lat: "7.064758",
    airport_lon: "171.272022",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2116,
    airport_code: "MAK",
    airport_name: "Malakal Arpt",
    airport_city_code: "MAK",
    airport_city_name: "Malakal",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "2",
    airport_lat: "9.558969",
    airport_lon: "31.652242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2117,
    airport_code: "MAM",
    airport_name: "Servando Canales Arpt",
    airport_city_code: "MAM",
    airport_city_name: "Matamoros",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "25.769894",
    airport_lon: "-97.525311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2118,
    airport_code: "QQM",
    airport_name: "Manchester - Piccadilly Rail Station",
    airport_city_code: "MAN",
    airport_city_name: "Manchester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2119,
    airport_code: "XVC",
    airport_name: "Crewe Rail Station",
    airport_city_code: "MAN",
    airport_city_name: "Manchester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2120,
    airport_code: "MAN",
    airport_name: "Manchester Intl",
    airport_city_code: "MAN",
    airport_city_name: "Manchester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "53.353744",
    airport_lon: "-2.27495",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2121,
    airport_code: "MAO",
    airport_name: "Intl Arpt Eduardo Gomes",
    airport_city_code: "MAO",
    airport_city_name: "Manaus",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-3.038611",
    airport_lon: "-60.049721",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2122,
    airport_code: "MAQ",
    airport_name: "Mae Sot Arpt",
    airport_city_code: "MAQ",
    airport_city_name: "Mae Sot",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "16.699856",
    airport_lon: "98.545056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2123,
    airport_code: "MAR",
    airport_name: "La Chinita Arpt",
    airport_city_code: "MAR",
    airport_city_name: "Maracaibo",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.558208",
    airport_lon: "-71.727856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2124,
    airport_code: "MAS",
    airport_name: "Momote Arpt",
    airport_city_code: "MAS",
    airport_city_name: "Manus Island",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-2.06189",
    airport_lon: "147.424",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2125,
    airport_code: "MAZ",
    airport_name: "Eugenio M De Hostos Arpt",
    airport_city_code: "MAZ",
    airport_city_name: "Mayaguez",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.255694",
    airport_lon: "-67.148472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2126,
    airport_code: "MBA",
    airport_name: "Moi Intl",
    airport_city_code: "MBA",
    airport_city_name: "Mombasa",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-4.034833",
    airport_lon: "39.59425",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2127,
    airport_code: "MBD",
    airport_name: "Mmabatho International Arpt",
    airport_city_code: "MBD",
    airport_city_name: "Mmabatho",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.798444",
    airport_lon: "25.548028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2128,
    airport_code: "MBH",
    airport_name: "Maryborough Arpt",
    airport_city_code: "MBH",
    airport_city_name: "Maryborough",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-25.5133",
    airport_lon: "152.715",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2129,
    airport_code: "MBJ",
    airport_name: "Sangster Arpt",
    airport_city_code: "MBJ",
    airport_city_name: "Montego Bay",
    airport_country_name: "JAMAICA",
    airport_country_code: "JM",
    airport_timezone: "-5",
    airport_lat: "18.503717",
    airport_lon: "-77.913358",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2130,
    airport_code: "MBL",
    airport_name: "Manistee Arpt",
    airport_city_code: "MBL",
    airport_city_name: "Manistee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.2725",
    airport_lon: "-86.246944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2131,
    airport_code: "MBS",
    airport_name: "Saginaw Arpt",
    airport_city_code: "MBS",
    airport_city_name: "Saginaw",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.532913",
    airport_lon: "-84.079647",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2132,
    airport_code: "MBT",
    airport_name: "Murfreesboro Municipal Arpt",
    airport_city_code: "MBT",
    airport_city_name: "Masbate",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "12.3694",
    airport_lon: "123.629",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2133,
    airport_code: "MBW",
    airport_name: "Moorabbin Arpt",
    airport_city_code: "MBW",
    airport_city_name: "Moorabbin",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-37.975833",
    airport_lon: "145.102222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2134,
    airport_code: "MBX",
    airport_name: "Maribor Arpt",
    airport_city_code: "MBX",
    airport_city_name: "Maribor",
    airport_country_name: "SLOVENIA",
    airport_country_code: "SI",
    airport_timezone: "1",
    airport_lat: "46.479861",
    airport_lon: "15.686131",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2135,
    airport_code: "MCE",
    airport_name: "Merced Municipal Arpt",
    airport_city_code: "MCE",
    airport_city_name: "Merced",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.284722",
    airport_lon: "-120.513889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2136,
    airport_code: "MCK",
    airport_name: "McCook Municipal",
    airport_city_code: "MCK",
    airport_city_name: "McCook",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.206389",
    airport_lon: "-100.592222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2137,
    airport_code: "MCM",
    airport_name: "Hel De Monte Carlo Airport",
    airport_city_code: "MCM",
    airport_city_name: "Monte Carlo",
    airport_country_name: "MONACO",
    airport_country_code: "MC",
    airport_timezone: "1",
    airport_lat: "43.733333",
    airport_lon: "7.416667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2138,
    airport_code: "MCN",
    airport_name: "Lewis B Wilson",
    airport_city_code: "MCN",
    airport_city_name: "Macon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.69285",
    airport_lon: "-83.649211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2139,
    airport_code: "MCP",
    airport_name: "Macapa Intl Arpt",
    airport_city_code: "MCP",
    airport_city_name: "Macapa",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "0.050664",
    airport_lon: "-51.072178",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2140,
    airport_code: "MCQ",
    airport_name: "Miskolc Arpt",
    airport_city_code: "MCQ",
    airport_city_name: "Miskolc",
    airport_country_name: "HUNGARY",
    airport_country_code: "HU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2141,
    airport_code: "MCT",
    airport_name: "Seeb Intl",
    airport_city_code: "MCT",
    airport_city_name: "Muscat",
    airport_country_name: "OMAN",
    airport_country_code: "OM",
    airport_timezone: "4",
    airport_lat: "23.593278",
    airport_lon: "58.284444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2142,
    airport_code: "MCU",
    airport_name: "Gueret Arpt",
    airport_city_code: "MCU",
    airport_city_name: "Montlucon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.352525",
    airport_lon: "2.570486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2143,
    airport_code: "MCV",
    airport_name: "McArthur River Arpt",
    airport_city_code: "MCV",
    airport_city_name: "McArthur River",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-16.4425",
    airport_lon: "136.084",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2144,
    airport_code: "MCW",
    airport_name: "Mason City Municipal",
    airport_city_code: "MCW",
    airport_city_name: "Mason City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.2247",
    airport_lon: "-93.4067",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2145,
    airport_code: "MCX",
    airport_name: "Makhachkala Airport",
    airport_city_code: "MCX",
    airport_city_name: "Makhachkala Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "42.816822",
    airport_lon: "47.652294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2146,
    airport_code: "MCY",
    airport_name: "Maroochydore Arpt",
    airport_city_code: "MCY",
    airport_city_name: "Maroochydore",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-26.603333",
    airport_lon: "153.091111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2147,
    airport_code: "MCZ",
    airport_name: "Palmeres Airport",
    airport_city_code: "MCZ",
    airport_city_name: "Maceio",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-9.510808",
    airport_lon: "-35.791678",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2148,
    airport_code: "MDC",
    airport_name: "Samratulang Arpt",
    airport_city_code: "MDC",
    airport_city_name: "Manado",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "1.549447",
    airport_lon: "124.925878",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2149,
    airport_code: "EOH",
    airport_name: "Enrique Olaya Herrara",
    airport_city_code: "MDE",
    airport_city_name: "Medellin",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "6.219958",
    airport_lon: "-75.590519",
    airport_num_airports: 2,
  },
  {
    airport_id: 2150,
    airport_code: "MDE",
    airport_name: "Jose Marie Cordova",
    airport_city_code: "MDE",
    airport_city_name: "Medellin",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "6.164536",
    airport_lon: "-75.423119",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2151,
    airport_code: "MDG",
    airport_name: "Mudanjiang Arpt",
    airport_city_code: "MDG",
    airport_city_name: "Mudanjiang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "44.523889",
    airport_lon: "129.568889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2152,
    airport_code: "MDH",
    airport_name: "Southern Illinois Arpt",
    airport_city_code: "MDH",
    airport_city_name: "Carbondale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2153,
    airport_code: "MDI",
    airport_name: "Makurdi Arpt",
    airport_city_code: "MDI",
    airport_city_name: "Makurdi",
    airport_country_name: "NIGERIA",
    airport_country_code: "NG",
    airport_timezone: "1",
    airport_lat: "7.703883",
    airport_lon: "8.613939",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2154,
    airport_code: "MDL",
    airport_name: "Annisaton Arpt",
    airport_city_code: "MDL",
    airport_city_name: "Mandalay",
    airport_country_name: "MYANMAR",
    airport_country_code: "MM",
    airport_timezone: "6",
    airport_lat: "21.702156",
    airport_lon: "95.977928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2155,
    airport_code: "MDQ",
    airport_name: "Mar Del Plata Arpt",
    airport_city_code: "MDQ",
    airport_city_name: "Mar Del Plata",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-37.934167",
    airport_lon: "-57.573333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2156,
    airport_code: "MDU",
    airport_name: "Mendi Arpt",
    airport_city_code: "MDU",
    airport_city_name: "Mendi",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-6.14774",
    airport_lon: "143.657",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2157,
    airport_code: "MDY",
    airport_name: "Sand Island Field",
    airport_city_code: "MDY",
    airport_city_name: "Midway Island",
    airport_country_name: "UNITED STATES MINOR OUTLYING ISLANDS",
    airport_country_code: "UM",
    airport_timezone: "-11",
    airport_lat: "28.201725",
    airport_lon: "-177.380636",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2158,
    airport_code: "MDZ",
    airport_name: "El Plumerillo Airport",
    airport_city_code: "MDZ",
    airport_city_name: "Mendoza",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-32.831717",
    airport_lon: "-68.792856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2159,
    airport_code: "MEA",
    airport_name: "Macae Arpt",
    airport_city_code: "MEA",
    airport_city_name: "Macae",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.343",
    airport_lon: "-41.766",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2160,
    airport_code: "MEC",
    airport_name: "Manta Arpt",
    airport_city_code: "MEC",
    airport_city_name: "Manta",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-5",
    airport_lat: "-0.946078",
    airport_lon: "-80.678808",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2161,
    airport_code: "MED",
    airport_name: "Prince Mohammad Bin Abdulaziz Arpt",
    airport_city_code: "MED",
    airport_city_name: "Madinah",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "24.553422",
    airport_lon: "39.705061",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2162,
    airport_code: "MEH",
    airport_name: "Mehamn Arpt",
    airport_city_code: "MEH",
    airport_city_name: "Mehamn",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "71.029722",
    airport_lon: "27.826667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2163,
    airport_code: "MEI",
    airport_name: "Key Field",
    airport_city_code: "MEI",
    airport_city_name: "Meridian",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.332624",
    airport_lon: "-88.751868",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2164,
    airport_code: "MEB",
    airport_name: "Essendon Arpt",
    airport_city_code: "MEL",
    airport_city_name: "Melbourne",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-37.728056",
    airport_lon: "144.901944",
    airport_num_airports: 3,
  },
  {
    airport_id: 2165,
    airport_code: "AVV",
    airport_name: "Avalon Arpt",
    airport_city_code: "MEL",
    airport_city_name: "Melbourne",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-38.039444",
    airport_lon: "144.469444",
    airport_num_airports: 3,
  },
  {
    airport_id: 2166,
    airport_code: "MEL",
    airport_name: "Tullamarine Arpt",
    airport_city_code: "MEL",
    airport_city_name: "Melbourne",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-37.673333",
    airport_lon: "144.843333",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2167,
    airport_code: "AWM",
    airport_name: "West Memphis Municipal Arpt",
    airport_city_code: "MEM",
    airport_city_name: "Memphis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2168,
    airport_code: "NQA",
    airport_name: "Memphis Naval Air Station",
    airport_city_code: "MEM",
    airport_city_name: "Memphis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.356667",
    airport_lon: "-89.870278",
    airport_num_airports: 3,
  },
  {
    airport_id: 2169,
    airport_code: "MEM",
    airport_name: "Memphis Intl",
    airport_city_code: "MEM",
    airport_city_name: "Memphis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.042417",
    airport_lon: "-89.976667",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2170,
    airport_code: "MEO",
    airport_name: "Dare County Regional Arpt",
    airport_city_code: "MEO",
    airport_city_name: "Manteo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2171,
    airport_code: "MES",
    airport_name: "Polonia Arpt",
    airport_city_code: "MES",
    airport_city_name: "Medan",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "3.558056",
    airport_lon: "98.671722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2172,
    airport_code: "MEU",
    airport_name: "Monte Dourado Arpt",
    airport_city_code: "MEU",
    airport_city_name: "Monte Dourado",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-0.8894",
    airport_lon: "-52.6017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2173,
    airport_code: "MEV",
    airport_name: "Douglas County Arpt",
    airport_city_code: "MEV",
    airport_city_name: "Minden",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2174,
    airport_code: "MEX",
    airport_name: "Benito Juarez Intl Airport",
    airport_city_code: "MEX",
    airport_city_name: "Mexico City",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.436303",
    airport_lon: "-99.072097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2175,
    airport_code: "MEZ",
    airport_name: "Messina Arpt",
    airport_city_code: "MEZ",
    airport_city_name: "Messina",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.704458",
    airport_lon: "26.908978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2176,
    airport_code: "MFD",
    airport_name: "Mansfield Municipal",
    airport_city_code: "MFD",
    airport_city_name: "Mansfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.821417",
    airport_lon: "-82.516639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2177,
    airport_code: "MFE",
    airport_name: "Miller Intl",
    airport_city_code: "MFE",
    airport_city_name: "McAllen",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "26.175833",
    airport_lon: "-98.238611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2178,
    airport_code: "MFH",
    airport_name: "Mesquite Arpt",
    airport_city_code: "MFH",
    airport_city_name: "Mesquite",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2179,
    airport_code: "MFI",
    airport_name: "Marshfield Municipal Arpt",
    airport_city_code: "MFI",
    airport_city_name: "Marshfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2180,
    airport_code: "MFK",
    airport_name: "Matsu Arpt",
    airport_city_code: "MFK",
    airport_city_name: "Matsu",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "26.224153",
    airport_lon: "120.00275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2181,
    airport_code: "MFM",
    airport_name: "Macau Arpt",
    airport_city_code: "MFM",
    airport_city_name: "Macau",
    airport_country_name: "MACAO",
    airport_country_code: "MO",
    airport_timezone: "8",
    airport_lat: "22.149556",
    airport_lon: "113.591558",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2182,
    airport_code: "MFN",
    airport_name: "Milford Sound Arpt",
    airport_city_code: "MFN",
    airport_city_name: "Milford Sound",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-44.67333",
    airport_lon: "167.92333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2183,
    airport_code: "MFR",
    airport_name: "Medford Jackson Cty",
    airport_city_code: "MFR",
    airport_city_name: "Medford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "42.374228",
    airport_lon: "-122.8735",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2184,
    airport_code: "MFT",
    airport_name: "Machu Picchu Arpt",
    airport_city_code: "MFT",
    airport_city_name: "Machu Picchu",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-13.1167",
    airport_lon: "-72.5667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2185,
    airport_code: "MGA",
    airport_name: "Augusto C Sandino",
    airport_city_code: "MGA",
    airport_city_name: "Managua",
    airport_country_name: "NICARAGUA",
    airport_country_code: "NI",
    airport_timezone: "-6",
    airport_lat: "12.141494",
    airport_lon: "-86.168178",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2186,
    airport_code: "MGB",
    airport_name: "Mount Gambier Arpt",
    airport_city_code: "MGB",
    airport_city_name: "Mt Gambier",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-37.7456",
    airport_lon: "140.785",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2187,
    airport_code: "MGC",
    airport_name: "Michigan City Arpt",
    airport_city_code: "MGC",
    airport_city_name: "Michigan City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.7033",
    airport_lon: "-86.8211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2188,
    airport_code: "MGE",
    airport_name: "Dobbins Air Force Base",
    airport_city_code: "MGE",
    airport_city_name: "Marietta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.915382",
    airport_lon: "-84.516319",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2189,
    airport_code: "MGF",
    airport_name: "Regional De Maringa Arpt",
    airport_city_code: "MGF",
    airport_city_name: "Maringa",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.476392",
    airport_lon: "-52.016406",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2190,
    airport_code: "MGH",
    airport_name: "Margate Arpt",
    airport_city_code: "MGH",
    airport_city_name: "Margate",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-30.857408",
    airport_lon: "30.343019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2191,
    airport_code: "MGJ",
    airport_name: "Orange County Arpt",
    airport_city_code: "MGJ",
    airport_city_name: "Montgomery",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "41.509988",
    airport_lon: "-74.264644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2192,
    airport_code: "MGM",
    airport_name: "Dannelly Field",
    airport_city_code: "MGM",
    airport_city_name: "Montgomery",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.300639",
    airport_lon: "-86.393972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2193,
    airport_code: "MGQ",
    airport_name: "Mogadishu International Airport",
    airport_city_code: "MGQ",
    airport_city_name: "Mogadishu",
    airport_country_name: "SOMALIA",
    airport_country_code: "SO",
    airport_timezone: "3",
    airport_lat: "2.01444",
    airport_lon: "45.3047",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2194,
    airport_code: "MGR",
    airport_name: "Moultrie Arpt",
    airport_city_code: "MGR",
    airport_city_name: "Moultrie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "31.084917",
    airport_lon: "-83.80325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2195,
    airport_code: "MGV",
    airport_name: "Margaret River Station Arpt",
    airport_city_code: "MGV",
    airport_city_name: "Margaret River Station",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2196,
    airport_code: "MGW",
    airport_name: "Morgantown Municipal",
    airport_city_code: "MGW",
    airport_city_name: "Morgantown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.642908",
    airport_lon: "-79.916314",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2197,
    airport_code: "MHD",
    airport_name: "Mashad Arpt",
    airport_city_code: "MHD",
    airport_city_name: "Mashad",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "36",
    airport_lon: "59",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2198,
    airport_code: "MHE",
    airport_name: "Mitchell Municipal",
    airport_city_code: "MHE",
    airport_city_name: "Mitchell",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2199,
    airport_code: "MHG",
    airport_name: "Mannheim Neuostheim",
    airport_city_code: "MHG",
    airport_city_name: "Mannheim Germany",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.472706",
    airport_lon: "8.514264",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2200,
    airport_code: "MHH",
    airport_name: "Marsh Harbour Intl Arpt",
    airport_city_code: "MHH",
    airport_city_name: "Marsh Harbour",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "26.511406",
    airport_lon: "-77.083472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2201,
    airport_code: "MHK",
    airport_name: "Manhattan Municipal Arpt",
    airport_city_code: "MHK",
    airport_city_name: "Manhattan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.140972",
    airport_lon: "-96.670833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2202,
    airport_code: "MHQ",
    airport_name: "Mariehamn Arpt",
    airport_city_code: "MHQ",
    airport_city_name: "Mariehamn",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "1",
    airport_lat: "60.122203",
    airport_lon: "19.898156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2203,
    airport_code: "MHT",
    airport_name: "Manchester Arpt",
    airport_city_code: "MHT",
    airport_city_name: "Manchester",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.932556",
    airport_lon: "-71.435667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2204,
    airport_code: "MHU",
    airport_name: "Mount Hotham Arpt",
    airport_city_code: "MHU",
    airport_city_name: "Mount Hotham",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-37.0475",
    airport_lon: "147.334",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2205,
    airport_code: "MHV",
    airport_name: "Kern County Arpt",
    airport_city_code: "MHV",
    airport_city_name: "Mojave",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "35.059364",
    airport_lon: "-118.151856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2206,
    airport_code: "MHZ",
    airport_name: "Mildenhall Arpt",
    airport_city_code: "MHZ",
    airport_city_name: "Mildenhall",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.361933",
    airport_lon: "0.486406",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2207,
    airport_code: "OPF",
    airport_name: "Opa Locka Arpt",
    airport_city_code: "MIA",
    airport_city_name: "Miami",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "25.907",
    airport_lon: "-80.278389",
    airport_num_airports: 4,
  },
  {
    airport_id: 2208,
    airport_code: "MIA",
    airport_name: "Miami Intl",
    airport_city_code: "MIA",
    airport_city_name: "Miami",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "25.79325",
    airport_lon: "-80.290556",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 2209,
    airport_code: "MPB",
    airport_name: "Mpb Seaplane Base",
    airport_city_code: "MIA",
    airport_city_name: "Miami",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "25.7783",
    airport_lon: "-80.1703",
    airport_num_airports: 4,
  },
  {
    airport_id: 2210,
    airport_code: "TMB",
    airport_name: "Tamiami Airport",
    airport_city_code: "MIA",
    airport_city_name: "Miami",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "25.647889",
    airport_lon: "-80.432777",
    airport_num_airports: 4,
  },
  {
    airport_id: 2211,
    airport_code: "MID",
    airport_name: "Merida Intl",
    airport_city_code: "MID",
    airport_city_name: "Merida",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.936981",
    airport_lon: "-89.657672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2212,
    airport_code: "MIE",
    airport_name: "Johnson Field",
    airport_city_code: "MIE",
    airport_city_name: "Muncie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.242472",
    airport_lon: "-85.39575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2213,
    airport_code: "MIG",
    airport_name: "Mian Yang Arpt",
    airport_city_code: "MIG",
    airport_city_name: "Mian Yang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.4281",
    airport_lon: "104.741",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2214,
    airport_code: "MII",
    airport_name: "Dr Gastao Vidigal",
    airport_city_code: "MII",
    airport_city_name: "Marilia",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.196892",
    airport_lon: "-49.9264",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2215,
    airport_code: "MIK",
    airport_name: "Mikkeli Aprt",
    airport_city_code: "MIK",
    airport_city_name: "Mikkeli",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "61.6866",
    airport_lon: "27.201794",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2216,
    airport_code: "MXP",
    airport_name: "Malpensa Arpt",
    airport_city_code: "MIL",
    airport_city_name: "Milan",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.630606",
    airport_lon: "8.728111",
    airport_num_airports: 4,
  },
  {
    airport_id: 2217,
    airport_code: "XIK",
    airport_name: "Milan Central Station Railway",
    airport_city_code: "MIL",
    airport_city_name: "Milan",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 2218,
    airport_code: "SWK",
    airport_name: "Segrate Arpt",
    airport_city_code: "MIL",
    airport_city_name: "Milan",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 2219,
    airport_code: "LIN",
    airport_name: "Linate Arpt",
    airport_city_code: "MIL",
    airport_city_name: "Milan",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.445103",
    airport_lon: "9.276739",
    airport_num_airports: 4,
  },
  {
    airport_id: 2220,
    airport_code: "MIM",
    airport_name: "Merimbula Arpt",
    airport_city_code: "MIM",
    airport_city_name: "Merimbula",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-36.9086",
    airport_lon: "149.901",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2221,
    airport_code: "MIR",
    airport_name: "Habib Bourguiba Intl",
    airport_city_code: "MIR",
    airport_city_name: "Monastir",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "35.758056",
    airport_lon: "10.754722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2222,
    airport_code: "MIV",
    airport_name: "Millville Arpt",
    airport_city_code: "MIV",
    airport_city_name: "Millville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.367806",
    airport_lon: "-75.072222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2223,
    airport_code: "MIW",
    airport_name: "Marshalltown Municipal",
    airport_city_code: "MIW",
    airport_city_name: "Marshalltown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2224,
    airport_code: "MJD",
    airport_name: "Mohenjodaro Arpt",
    airport_city_code: "MJD",
    airport_city_name: "Mohenjodaro",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "27.335156",
    airport_lon: "68.143053",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2225,
    airport_code: "MJF",
    airport_name: "Kjaerstad Arpt",
    airport_city_code: "MJF",
    airport_city_name: "Mosjoen",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "65.783997",
    airport_lon: "13.214914",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2226,
    airport_code: "MJI",
    airport_name: "MITIGA",
    airport_city_code: "MJI",
    airport_city_name: "Maji",
    airport_country_name: "ETHIOPIA",
    airport_country_code: "LY",
    airport_timezone: "1",
    airport_lat: "32.8941",
    airport_lon: "13.276",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2227,
    airport_code: "MJK",
    airport_name: "Shark Bay Arpt",
    airport_city_code: "MJK",
    airport_city_name: "Monkey Mia",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-25.8939",
    airport_lon: "113.5772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2228,
    airport_code: "MJN",
    airport_name: "Amborovy Arpt",
    airport_city_code: "MJN",
    airport_city_name: "Majunga",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-15.667144",
    airport_lon: "46.351828",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2229,
    airport_code: "MJR",
    airport_name: "Miramar Arpt",
    airport_city_code: "MJR",
    airport_city_name: "Miramar",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2230,
    airport_code: "MJT",
    airport_name: "Mytilene Arpt",
    airport_city_code: "MJT",
    airport_city_name: "Mytilene",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.056667",
    airport_lon: "26.598333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2231,
    airport_code: "MJU",
    airport_name: "Mamuju Arpt",
    airport_city_code: "MJU",
    airport_city_name: "Mamuju",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-2.5",
    airport_lon: "118.833336",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2232,
    airport_code: "MJV",
    airport_name: "San Javier Airport",
    airport_city_code: "MJV",
    airport_city_name: "Murcia",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "37.774972",
    airport_lon: "-0.812389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2233,
    airport_code: "MJW",
    airport_name: "Mahenye Airfield",
    airport_city_code: "MJW",
    airport_city_name: "Mahenye",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2234,
    airport_code: "MJZ",
    airport_name: "Mirnyj Arpt",
    airport_city_code: "MJZ",
    airport_city_name: "Mirnyj",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "10",
    airport_lat: "62.534689",
    airport_lon: "114.038928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2235,
    airport_code: "MKA",
    airport_name: "Marianske Lazne Arpt",
    airport_city_code: "MKA",
    airport_city_name: "Marianske Lazne",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2236,
    airport_code: "MCI",
    airport_name: "Kansas City Intl",
    airport_city_code: "MKC",
    airport_city_name: "Kansas City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.297606",
    airport_lon: "-94.713905",
    airport_num_airports: 3,
  },
  {
    airport_id: 2237,
    airport_code: "JCI",
    airport_name: "Johnson Industrial Arpt",
    airport_city_code: "MKC",
    airport_city_name: "Kansas City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.830917",
    airport_lon: "-94.890306",
    airport_num_airports: 3,
  },
  {
    airport_id: 2238,
    airport_code: "MKC",
    airport_name: "Kansas City Municipal Arpt",
    airport_city_code: "MKC",
    airport_city_name: "Kansas City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.1275",
    airport_lon: "-94.598889",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2239,
    airport_code: "MKE",
    airport_name: "General Mitchell Intl Arpt",
    airport_city_code: "MKE",
    airport_city_name: "Milwaukee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.947222",
    airport_lon: "-87.896583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2240,
    airport_code: "MKG",
    airport_name: "Muskegon Cty Intl",
    airport_city_code: "MKG",
    airport_city_name: "Muskegon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.1695",
    airport_lon: "-86.2382",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2241,
    airport_code: "MKK",
    airport_name: "Molokai Arpt",
    airport_city_code: "MKK",
    airport_city_name: "Hoolehua",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "21.152886",
    airport_lon: "-157.096256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2242,
    airport_code: "MKL",
    airport_name: "McKellar Fld",
    airport_city_code: "MKL",
    airport_city_name: "Jackson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.599889",
    airport_lon: "-88.915611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2243,
    airport_code: "MKM",
    airport_name: "Mukah Arpt",
    airport_city_code: "MKM",
    airport_city_name: "Mukah",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "2.90639",
    airport_lon: "112.08",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2244,
    airport_code: "MKO",
    airport_name: "Davis Field",
    airport_city_code: "MKO",
    airport_city_name: "Muskogee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.656489",
    airport_lon: "-95.366656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2245,
    airport_code: "MKR",
    airport_name: "Meekatharra Arpt",
    airport_city_code: "MKR",
    airport_city_name: "Meekathara",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-26.6117",
    airport_lon: "118.548",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2246,
    airport_code: "MKT",
    airport_name: "Mankato Municipal Arpt",
    airport_city_code: "MKT",
    airport_city_name: "Mankato",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2247,
    airport_code: "MKW",
    airport_name: "Rendani Arpt",
    airport_city_code: "MKW",
    airport_city_name: "Manokwari",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-0.891833",
    airport_lon: "134.049183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2248,
    airport_code: "MKX",
    airport_name: "Mukalla Arpt",
    airport_city_code: "MKX",
    airport_city_name: "Mukalla",
    airport_country_name: "YEMEN",
    airport_country_code: "YE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2249,
    airport_code: "MKY",
    airport_name: "Mackay Arpt",
    airport_city_code: "MKY",
    airport_city_name: "Mackay",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-21.171667",
    airport_lon: "149.179722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2250,
    airport_code: "MKZ",
    airport_name: "Batu Berendum Arpt",
    airport_city_code: "MKZ",
    airport_city_name: "Malacca",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "2.263361",
    airport_lon: "102.251553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2251,
    airport_code: "MLA",
    airport_name: "Luqa Airport",
    airport_city_code: "MLA",
    airport_city_name: "Malta",
    airport_country_name: "MALTA",
    airport_country_code: "MT",
    airport_timezone: "1",
    airport_lat: "35.857497",
    airport_lon: "14.4775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2252,
    airport_code: "MLB",
    airport_name: "Melbourne Regional",
    airport_city_code: "MLB",
    airport_city_name: "Melbourne",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "28.102753",
    airport_lon: "-80.645258",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2253,
    airport_code: "MLC",
    airport_name: "McAlester Municipal Arpt",
    airport_city_code: "MLC",
    airport_city_name: "McAlester",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.882403",
    airport_lon: "-95.783463",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2254,
    airport_code: "MLE",
    airport_name: "Male Intl Arpt",
    airport_city_code: "MLE",
    airport_city_name: "Male",
    airport_country_name: "MALDIVES",
    airport_country_code: "MV",
    airport_timezone: "5",
    airport_lat: "4.191833",
    airport_lon: "73.529128",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2255,
    airport_code: "MLG",
    airport_name: "Malang Arpt",
    airport_city_code: "MLG",
    airport_city_name: "Malang",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-7.926556",
    airport_lon: "112.714514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2256,
    airport_code: "MLI",
    airport_name: "Quad City Arpt",
    airport_city_code: "MLI",
    airport_city_name: "Moline",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.448528",
    airport_lon: "-90.507539",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2257,
    airport_code: "MLM",
    airport_name: "Michoacan Municipal Arpt",
    airport_city_code: "MLM",
    airport_city_name: "Morelia",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.849944",
    airport_lon: "-101.0255",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2258,
    airport_code: "MLN",
    airport_name: "Melilla Arpt",
    airport_city_code: "MLN",
    airport_city_name: "Melilla",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "35.279817",
    airport_lon: "-2.956256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2259,
    airport_code: "MLO",
    airport_name: "Milos Arpt",
    airport_city_code: "MLO",
    airport_city_name: "Milos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.696111",
    airport_lon: "24.4775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2260,
    airport_code: "MLS",
    airport_name: "Miles City Municipal Arpt",
    airport_city_code: "MLS",
    airport_city_name: "Miles City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "46.428",
    airport_lon: "-105.886",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2261,
    airport_code: "MLT",
    airport_name: "Millinocket Arpt",
    airport_city_code: "MLT",
    airport_city_name: "Millinocket",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "45.647836",
    airport_lon: "-68.685561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2262,
    airport_code: "MLU",
    airport_name: "Monroe Regional",
    airport_city_code: "MLU",
    airport_city_name: "Monroe",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.510864",
    airport_lon: "-92.037689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2263,
    airport_code: "ROB",
    airport_name: "Roberts Intl",
    airport_city_code: "MLW",
    airport_city_name: "Monrovia",
    airport_country_name: "LIBERIA",
    airport_country_code: "LR",
    airport_timezone: "0",
    airport_lat: "6.233789",
    airport_lon: "-10.362311",
    airport_num_airports: 2,
  },
  {
    airport_id: 2264,
    airport_code: "MLW",
    airport_name: "Sprigg Payne Arpt",
    airport_city_code: "MLW",
    airport_city_name: "Monrovia",
    airport_country_name: "LIBERIA",
    airport_country_code: "LR",
    airport_timezone: "0",
    airport_lat: "6.289061",
    airport_lon: "-10.758722",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2265,
    airport_code: "MLX",
    airport_name: "Malatya Arpt",
    airport_city_code: "MLX",
    airport_city_name: "Malatya",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.435347",
    airport_lon: "38.091006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2266,
    airport_code: "MLZ",
    airport_name: "Melo Arpt",
    airport_city_code: "MLZ",
    airport_city_name: "Melo",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-100",
    airport_lat: "-32.3425",
    airport_lon: "-54.2219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2267,
    airport_code: "XFP",
    airport_name: "Malmo Railway Service",
    airport_city_code: "MMA",
    airport_city_name: "Malmo",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 2268,
    airport_code: "XFR",
    airport_name: "Malmo South Railway Service",
    airport_city_code: "MMA",
    airport_city_name: "Malmo",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 2269,
    airport_code: "MMA",
    airport_name: "Malmo Metropolitan Area Arpt",
    airport_city_code: "MMA",
    airport_city_name: "Malmo",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 2270,
    airport_code: "MMX",
    airport_name: "Sturup Arpt",
    airport_city_code: "MMA",
    airport_city_name: "Malmo",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "55.530193",
    airport_lon: "13.371639",
    airport_num_airports: 4,
  },
  {
    airport_id: 2271,
    airport_code: "MMB",
    airport_name: "Memanbetsu Arpt",
    airport_city_code: "MMB",
    airport_city_name: "Memambetsu",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "43.880606",
    airport_lon: "144.164053",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2272,
    airport_code: "MMC",
    airport_name: "Ciudad Mante Arpt",
    airport_city_code: "MMC",
    airport_city_name: "Ciudad Mante",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2273,
    airport_code: "MME",
    airport_name: "Durham Tees Valley Arpt",
    airport_city_code: "MME",
    airport_city_name: "Teesside",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "54.509189",
    airport_lon: "-1.429406",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2274,
    airport_code: "XVG",
    airport_name: "Darlington Rail Station",
    airport_city_code: "MME",
    airport_city_name: "Teesside",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2275,
    airport_code: "XNO",
    airport_name: "Northallerton Rail Station",
    airport_city_code: "MME",
    airport_city_name: "Teesside",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2276,
    airport_code: "MMG",
    airport_name: "Mount Magnet Arpt",
    airport_city_code: "MMG",
    airport_city_name: "Mount Magnet",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-28.1161",
    airport_lon: "117.842",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2277,
    airport_code: "MMH",
    airport_name: "Mammoth Lakes Municipal",
    airport_city_code: "MMH",
    airport_city_name: "Mammoth Lakes",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.624049",
    airport_lon: "-118.837772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2278,
    airport_code: "MMI",
    airport_name: "McMinn County Arpt",
    airport_city_code: "MMI",
    airport_city_name: "Athens",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.39919",
    airport_lon: "-84.56177",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2279,
    airport_code: "MMJ",
    airport_name: "Matsumoto Arpt",
    airport_city_code: "MMJ",
    airport_city_name: "Matsumoto",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "36.166758",
    airport_lon: "137.922669",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2280,
    airport_code: "MMK",
    airport_name: "Murmansk Arpt",
    airport_city_code: "MMK",
    airport_city_name: "Murmansk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "68.781672",
    airport_lon: "32.750822",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2281,
    airport_code: "MML",
    airport_name: "Marshall Municipal",
    airport_city_code: "MML",
    airport_city_name: "Marshall",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2282,
    airport_code: "MMM",
    airport_name: "Middlemount Arpt",
    airport_city_code: "MMM",
    airport_city_name: "Middlemount",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-22.8025",
    airport_lon: "148.7047",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2283,
    airport_code: "MMO",
    airport_name: "Vila Do Maio Arpt",
    airport_city_code: "MMO",
    airport_city_name: "Maio",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "15.155928",
    airport_lon: "-23.213703",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2284,
    airport_code: "MMU",
    airport_name: "Morristown Arpt",
    airport_city_code: "MMU",
    airport_city_name: "Morristown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.79935",
    airport_lon: "-74.414875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2285,
    airport_code: "MMY",
    airport_name: "Hirara Arpt",
    airport_city_code: "MMY",
    airport_city_name: "Miyako Jima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "24.782833",
    airport_lon: "125.295111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2286,
    airport_code: "MNI",
    airport_name: "Bramble Arpt",
    airport_city_code: "MNI",
    airport_city_name: "Montserrat",
    airport_country_name: "DOMINICA",
    airport_country_code: "DM",
    airport_timezone: "1",
    airport_lat: "16.791389",
    airport_lon: "-62.193333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2287,
    airport_code: "MNK",
    airport_name: "Maiana Airport",
    airport_city_code: "MNK",
    airport_city_name: "Maiana",
    airport_country_name: "KIRIBATI",
    airport_country_code: "KI",
    airport_timezone: "10",
    airport_lat: "0.933333",
    airport_lon: "173",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2288,
    airport_code: "MNL",
    airport_name: "Ninoy Aquino Intl",
    airport_city_code: "MNL",
    airport_city_name: "Manila",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "14.508647",
    airport_lon: "121.019581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2289,
    airport_code: "MNM",
    airport_name: "Menominee County",
    airport_city_code: "MNM",
    airport_city_name: "Menominee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "45.12665",
    airport_lon: "-87.638443",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2290,
    airport_code: "MNO",
    airport_name: "Manono Arpt",
    airport_city_code: "MNO",
    airport_city_name: "Manono",
    airport_country_name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    airport_country_code: "CD",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2291,
    airport_code: "MNQ",
    airport_name: "Monto Arpt",
    airport_city_code: "MNQ",
    airport_city_name: "Monto",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-24.8917",
    airport_lon: "151.1083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2292,
    airport_code: "MNZ",
    airport_name: "Manassas Arpt",
    airport_city_code: "MNZ",
    airport_city_name: "Manassas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2293,
    airport_code: "EZF",
    airport_name: "Shannon Airport",
    airport_city_code: "MNZ",
    airport_city_name: "Manassas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2294,
    airport_code: "MOB",
    airport_name: "Mobile Municipal",
    airport_city_code: "MOB",
    airport_city_name: "Mobile",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.691231",
    airport_lon: "-88.242814",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2295,
    airport_code: "MOC",
    airport_name: "Montes Claros Arpt",
    airport_city_code: "MOC",
    airport_city_name: "Montes Claros",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-16.706925",
    airport_lon: "-43.8189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2296,
    airport_code: "MOD",
    airport_name: "Harry Sham Fld",
    airport_city_code: "MOD",
    airport_city_name: "Modesto",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.625817",
    airport_lon: "-120.954422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2297,
    airport_code: "MOI",
    airport_name: "Mitiaro Island Arpt",
    airport_city_code: "MOI",
    airport_city_name: "Mitiaro Island",
    airport_country_name: "COOK ISLANDS",
    airport_country_code: "CK",
    airport_timezone: "-10",
    airport_lat: "-19.8425",
    airport_lon: "-157.703",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2298,
    airport_code: "MOL",
    airport_name: "Aro Arpt",
    airport_city_code: "MOL",
    airport_city_name: "Molde",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "62.744722",
    airport_lon: "7.2625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2299,
    airport_code: "MON",
    airport_name: "Mount Cook Arpt",
    airport_city_code: "MON",
    airport_city_name: "Mount Cook",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-43.906666",
    airport_lon: "170.128333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2300,
    airport_code: "MOP",
    airport_name: "Mt Pleasant Municipal",
    airport_city_code: "MOP",
    airport_city_name: "Mount Pleasant",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2301,
    airport_code: "MOQ",
    airport_name: "Morondava Arpt",
    airport_city_code: "MOQ",
    airport_city_name: "Morondava",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-20.28475",
    airport_lon: "44.317614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2302,
    airport_code: "MOT",
    airport_name: "Minot Intl",
    airport_city_code: "MOT",
    airport_city_name: "Minot",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "48.259378",
    airport_lon: "-101.280333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2303,
    airport_code: "MOU",
    airport_name: "Mountain Village Arpt",
    airport_city_code: "MOU",
    airport_city_name: "Mountain Village",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "62.0954",
    airport_lon: "-163.682",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2304,
    airport_code: "MOV",
    airport_name: "Moranbah Arpt",
    airport_city_code: "MOV",
    airport_city_name: "Moranbah",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-22.0578",
    airport_lon: "148.077",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2305,
    airport_code: "DME",
    airport_name: "Domodedovo Arpt",
    airport_city_code: "MOW",
    airport_city_name: "Moscow",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "55.408611",
    airport_lon: "37.906111",
    airport_num_airports: 3,
  },
  {
    airport_id: 2306,
    airport_code: "VKO",
    airport_name: "Vnukovo Arpt",
    airport_city_code: "MOW",
    airport_city_name: "Moscow",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "55.591531",
    airport_lon: "37.261486",
    airport_num_airports: 3,
  },
  {
    airport_id: 2307,
    airport_code: "SVO",
    airport_name: "Sheremetyevo Arpt",
    airport_city_code: "MOW",
    airport_city_name: "Moscow",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "55.972642",
    airport_lon: "37.414589",
    airport_num_airports: 3,
  },
  {
    airport_id: 2308,
    airport_code: "MOZ",
    airport_name: "Temae Airport",
    airport_city_code: "MOZ",
    airport_city_name: "Moorea",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-17.489972",
    airport_lon: "-149.761869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2309,
    airport_code: "MPA",
    airport_name: "Mpacha Aerodrome",
    airport_city_code: "MPA",
    airport_city_name: "Mpache",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-17.6344",
    airport_lon: "24.1767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2310,
    airport_code: "MPH",
    airport_name: "Malay Arpt",
    airport_city_code: "MPH",
    airport_city_name: "Caticlan",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "11.9215",
    airport_lon: "121.953",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2311,
    airport_code: "MPK",
    airport_name: "Mokpo Arpt",
    airport_city_code: "MPK",
    airport_city_name: "Mokpo",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "34.758906",
    airport_lon: "126.379872",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2312,
    airport_code: "MPL",
    airport_name: "Frejorgues Arpt",
    airport_city_code: "MPL",
    airport_city_name: "Montpellier",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.576194",
    airport_lon: "3.963014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2313,
    airport_code: "MPM",
    airport_name: "Maputo Intl",
    airport_city_code: "MPM",
    airport_city_name: "Maputo",
    airport_country_name: "MOZAMBIQUE",
    airport_country_code: "MZ",
    airport_timezone: "2",
    airport_lat: "-25.920836",
    airport_lon: "32.572606",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2314,
    airport_code: "MPN",
    airport_name: "Mount Pleasant Arpt",
    airport_city_code: "MPN",
    airport_city_name: "Mount Pleasant",
    airport_country_name: "FALKLAND ISLANDS (MALVINAS)",
    airport_country_code: "FK",
    airport_timezone: "-4",
    airport_lat: "-51.822777",
    airport_lon: "-58.447222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2315,
    airport_code: "MPQ",
    airport_name: "Maan Arpt",
    airport_city_code: "MPQ",
    airport_city_name: "Maan",
    airport_country_name: "JORDAN",
    airport_country_code: "JO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2316,
    airport_code: "MPR",
    airport_name: "McPherson Arpt",
    airport_city_code: "MPR",
    airport_city_name: "McPherson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2317,
    airport_code: "MPV",
    airport_name: "E F Knapp Arpt",
    airport_city_code: "MPV",
    airport_city_name: "Montpelier",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.203503",
    airport_lon: "-72.562328",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2318,
    airport_code: "MPW",
    airport_name: "Mariupol Arpt",
    airport_city_code: "MPW",
    airport_city_name: "Mariupol",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "47.0761",
    airport_lon: "37.4496",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2319,
    airport_code: "MQF",
    airport_name: "Magnitogorsk Arpt",
    airport_city_code: "MQF",
    airport_city_name: "Magnitogorsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "53.393131",
    airport_lon: "58.755661",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2320,
    airport_code: "MQK",
    airport_name: "San Matias Arpt",
    airport_city_code: "MQK",
    airport_city_name: "San Matias",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2321,
    airport_code: "MQL",
    airport_name: "Mildura Arpt",
    airport_city_code: "MQL",
    airport_city_name: "Mildura",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-34.2292",
    airport_lon: "142.086",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2322,
    airport_code: "MQM",
    airport_name: "Mardin Arpt",
    airport_city_code: "MQM",
    airport_city_name: "Mardin",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.2233",
    airport_lon: "40.6317",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2323,
    airport_code: "MQN",
    airport_name: "Rossvoll",
    airport_city_code: "MQN",
    airport_city_name: "Mo I Rana",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "66.3639",
    airport_lon: "14.3014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2324,
    airport_code: "MQS",
    airport_name: "Mustique Arpt",
    airport_city_code: "MQS",
    airport_city_name: "Mustique",
    airport_country_name: "DOMINICA",
    airport_country_code: "DM",
    airport_timezone: "-4",
    airport_lat: "12.887947",
    airport_lon: "-61.180161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2325,
    airport_code: "MQT",
    airport_name: "Sawyer Intl Airport",
    airport_city_code: "MQT",
    airport_city_name: "Marquette",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "46.353611",
    airport_lon: "-87.395278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2326,
    airport_code: "MQY",
    airport_name: "Smyrna Arpt",
    airport_city_code: "MQY",
    airport_city_name: "Smyrna",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "36.009",
    airport_lon: "-86.52",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2327,
    airport_code: "MQZ",
    airport_name: "Margaret River Arpt",
    airport_city_code: "MQZ",
    airport_city_name: "Margaret River",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-33.93",
    airport_lon: "115.1",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2328,
    airport_code: "MRA",
    airport_name: "Misurata",
    airport_city_code: "MRA",
    airport_city_name: "Misurata",
    airport_country_name: "LIBYAN ARAB JAMAHIRIYA",
    airport_country_code: "LB",
    airport_timezone: "1",
    airport_lat: "32.325",
    airport_lon: "15.061",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2329,
    airport_code: "MRB",
    airport_name: "Martinsburgh Regional Arpt",
    airport_city_code: "MRB",
    airport_city_name: "Martinsburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.2407",
    airport_lon: "-77.591",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2330,
    airport_code: "MRC",
    airport_name: "Maury Country Arpt",
    airport_city_code: "MRC",
    airport_city_name: "Columbia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2331,
    airport_code: "MRD",
    airport_name: "Alberto Carnevalli Arpt",
    airport_city_code: "MRD",
    airport_city_name: "Merida",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "8.582294",
    airport_lon: "-71.161186",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2332,
    airport_code: "MRE",
    airport_name: "Mara Lodges Arpt",
    airport_city_code: "MRE",
    airport_city_name: "Mara Lodges",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-1.406111",
    airport_lon: "35.008056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2333,
    airport_code: "MRO",
    airport_name: "Masterton Arpt",
    airport_city_code: "MRO",
    airport_city_name: "Masterton",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-40.973333",
    airport_lon: "175.633611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2334,
    airport_code: "XRF",
    airport_name: "Marseille Rail Station",
    airport_city_code: "MRS",
    airport_city_name: "Marseille",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "47.0667",
    airport_lon: "3",
    airport_num_airports: 2,
  },
  {
    airport_id: 2335,
    airport_code: "MRS",
    airport_name: "Marseille Provence Arpt",
    airport_city_code: "MRS",
    airport_city_name: "Marseille",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.435555",
    airport_lon: "5.213611",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2336,
    airport_code: "MRU",
    airport_name: "Plaisance Arptt",
    airport_city_code: "MRU",
    airport_city_name: "Mauritius",
    airport_country_name: "MAURITIUS",
    airport_country_code: "MU",
    airport_timezone: "5",
    airport_lat: "-20.430235",
    airport_lon: "57.6836",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2337,
    airport_code: "MRV",
    airport_name: "Mineralnye Vody Arpt",
    airport_city_code: "MRV",
    airport_city_name: "Mineralnye Vody",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "44.225072",
    airport_lon: "43.081889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2338,
    airport_code: "MRX",
    airport_name: "Mahshahr Airport",
    airport_city_code: "MRX",
    airport_city_name: "Mahshahr Airport",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "30.556192",
    airport_lon: "49.151879",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2339,
    airport_code: "MRY",
    airport_name: "Monterey Peninsula",
    airport_city_code: "MRY",
    airport_city_name: "Monterey",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "36.587",
    airport_lon: "-121.842944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2340,
    airport_code: "MRZ",
    airport_name: "Moree Arpt",
    airport_city_code: "MRZ",
    airport_city_name: "Moree",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-29.4989",
    airport_lon: "149.845",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2341,
    airport_code: "MSB",
    airport_name: "Marigot Seaplane Base",
    airport_city_code: "MSB",
    airport_city_name: "Marigot St Martin",
    airport_country_name: "GUADELOUPE",
    airport_country_code: "GP",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2342,
    airport_code: "MSC",
    airport_name: "Falcon Field",
    airport_city_code: "MSC",
    airport_city_name: "Mesa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2343,
    airport_code: "MSE",
    airport_name: "Kent International Arpt",
    airport_city_code: "MSE",
    airport_city_name: "Manston",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.342222",
    airport_lon: "1.346111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2344,
    airport_code: "MSJ",
    airport_name: "Misawa Arpt",
    airport_city_code: "MSJ",
    airport_city_name: "Misawa",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "40.703222",
    airport_lon: "141.368364",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2345,
    airport_code: "MSL",
    airport_name: "Muscle Shoals Arpt",
    airport_city_code: "MSL",
    airport_city_name: "Muscle Shoals",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.7453",
    airport_lon: "-87.6102",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2346,
    airport_code: "MSN",
    airport_name: "Dane County Regional",
    airport_city_code: "MSN",
    airport_city_name: "Madison",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "43.139858",
    airport_lon: "-89.337514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2347,
    airport_code: "MSO",
    airport_name: "Missoula Intl",
    airport_city_code: "MSO",
    airport_city_name: "Missoula",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "46.916306",
    airport_lon: "-114.090556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2348,
    airport_code: "MIC",
    airport_name: "Crystal Arpt",
    airport_city_code: "MSP",
    airport_city_name: "Minneapolis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2349,
    airport_code: "FCM",
    airport_name: "Flying Cloud Arpt",
    airport_city_code: "MSP",
    airport_city_name: "Minneapolis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2350,
    airport_code: "MSP",
    airport_name: "Minneapolis St Paul Intl",
    airport_city_code: "MSP",
    airport_city_name: "Minneapolis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.881956",
    airport_lon: "-93.221767",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2351,
    airport_code: "MHP",
    airport_name: "Minsk Intl 1",
    airport_city_code: "MSQ",
    airport_city_name: "Minsk",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "53.864472",
    airport_lon: "27.539683",
    airport_num_airports: 2,
  },
  {
    airport_id: 2352,
    airport_code: "MSQ",
    airport_name: "Minsk Intl 2",
    airport_city_code: "MSQ",
    airport_city_name: "Minsk",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "53.882469",
    airport_lon: "28.030731",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2353,
    airport_code: "MSR",
    airport_name: "Mus Arpt",
    airport_city_code: "MSR",
    airport_city_name: "Mus Tr",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.747769",
    airport_lon: "41.661236",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2354,
    airport_code: "MSS",
    airport_name: "Richards Field",
    airport_city_code: "MSS",
    airport_city_name: "Massena",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.935833",
    airport_lon: "-74.845547",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2355,
    airport_code: "MST",
    airport_name: "Maastricht Aachen Arpt",
    airport_city_code: "MST",
    airport_city_name: "Maastricht",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "50.911658",
    airport_lon: "5.770144",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2356,
    airport_code: "ZYT",
    airport_name: "Maastricht Rail Station",
    airport_city_code: "MST",
    airport_city_name: "Maastricht",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2357,
    airport_code: "MSU",
    airport_name: "Moshoeshoe Intl Arpt",
    airport_city_code: "MSU",
    airport_city_name: "Maseru",
    airport_country_name: "LESOTHO",
    airport_country_code: "LS",
    airport_timezone: "2",
    airport_lat: "-29.462256",
    airport_lon: "27.552503",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2358,
    airport_code: "MSV",
    airport_name: "Catskills Sulivan",
    airport_city_code: "MSV",
    airport_city_name: "Monticello",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2359,
    airport_code: "MSY",
    airport_name: "Louis Armstrong Intl Arpt",
    airport_city_code: "MSY",
    airport_city_name: "New Orleans",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.993389",
    airport_lon: "-90.258028",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2360,
    airport_code: "NEW",
    airport_name: "New Lakefront Arpt",
    airport_city_code: "MSY",
    airport_city_name: "New Orleans",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "30.0424",
    airport_lon: "-90.0283",
    airport_num_airports: 2,
  },
  {
    airport_id: 2361,
    airport_code: "MTH",
    airport_name: "Marathon Arpt",
    airport_city_code: "MTH",
    airport_city_name: "Marathon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "24.726111",
    airport_lon: "-81.051389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2362,
    airport_code: "MTJ",
    airport_name: "Montrose County",
    airport_city_code: "MTJ",
    airport_city_name: "Montrose",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.509794",
    airport_lon: "-107.894242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2363,
    airport_code: "MTL",
    airport_name: "Maitland Airport",
    airport_city_code: "MTL",
    airport_city_name: "Maitland",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-32.7033",
    airport_lon: "151.488",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2364,
    airport_code: "MTO",
    airport_name: "Coles County",
    airport_city_code: "MTO",
    airport_city_name: "Mattoon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2365,
    airport_code: "MTP",
    airport_name: "Sky Portal Arpt",
    airport_city_code: "MTP",
    airport_city_name: "Montauk",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.0765",
    airport_lon: "-71.9208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2366,
    airport_code: "MTR",
    airport_name: "S Jeronimo Arpt",
    airport_city_code: "MTR",
    airport_city_name: "Monteria",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "8.823744",
    airport_lon: "-75.825831",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2367,
    airport_code: "MTS",
    airport_name: "Matsapha Intl Arpt",
    airport_city_code: "MTS",
    airport_city_name: "Manzini",
    airport_country_name: "SWAZILAND",
    airport_country_code: "SZ",
    airport_timezone: "2",
    airport_lat: "-26.529022",
    airport_lon: "31.307519",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2368,
    airport_code: "MTT",
    airport_name: "Minatitlan Municipal Arpt",
    airport_city_code: "MTT",
    airport_city_name: "Minatitlan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "18.103419",
    airport_lon: "-94.580681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2369,
    airport_code: "MTW",
    airport_name: "Manitowoc Municipal Arpt",
    airport_city_code: "MTW",
    airport_city_name: "Manitowoc",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2370,
    airport_code: "MTY",
    airport_name: "Escobedo Arpt",
    airport_city_code: "MTY",
    airport_city_name: "Monterrey",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "25.778489",
    airport_lon: "-100.106878",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2371,
    airport_code: "NTR",
    airport_name: "Aeropuerto Del Norte",
    airport_city_code: "MTY",
    airport_city_name: "Monterrey",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "25.865572",
    airport_lon: "-100.237239",
    airport_num_airports: 2,
  },
  {
    airport_id: 2372,
    airport_code: "MUA",
    airport_name: "Munda Arpt",
    airport_city_code: "MUA",
    airport_city_name: "Munda",
    airport_country_name: "SOLOMON ISLANDS",
    airport_country_code: "SB",
    airport_timezone: "11",
    airport_lat: "-8.32797",
    airport_lon: "157.263",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2373,
    airport_code: "MUB",
    airport_name: "Maun Arpt",
    airport_city_code: "MUB",
    airport_city_name: "Maun",
    airport_country_name: "BOTSWANA",
    airport_country_code: "BW",
    airport_timezone: "2",
    airport_lat: "-19.972564",
    airport_lon: "23.431086",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2374,
    airport_code: "ZMU",
    airport_name: "Munich HBF Railway Service",
    airport_city_code: "MUC",
    airport_city_name: "Munich",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.1408",
    airport_lon: "11.555",
    airport_num_airports: 2,
  },
  {
    airport_id: 2375,
    airport_code: "MUC",
    airport_name: "Munich Intl Arpt",
    airport_city_code: "MUC",
    airport_city_name: "Munich",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.353783",
    airport_lon: "11.786086",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2376,
    airport_code: "MUE",
    airport_name: "Waimea Arpt",
    airport_city_code: "MUE",
    airport_city_name: "Kamuela",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "20.001328",
    airport_lon: "-155.668108",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2377,
    airport_code: "MUN",
    airport_name: "Quiriquire Arpt",
    airport_city_code: "MUN",
    airport_city_name: "Maturin",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "9.749067",
    airport_lon: "-63.1534",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2378,
    airport_code: "MUO",
    airport_name: "Mountain Home AFB",
    airport_city_code: "MUO",
    airport_city_name: "Mountain Home",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.043603",
    airport_lon: "-115.872431",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2379,
    airport_code: "MUR",
    airport_name: "Marudi Arpt",
    airport_city_code: "MUR",
    airport_city_name: "Marudi",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.1775",
    airport_lon: "114.321944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2380,
    airport_code: "MUX",
    airport_name: "Multan Arpt",
    airport_city_code: "MUX",
    airport_city_name: "Multan",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "30.203222",
    airport_lon: "71.419111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2381,
    airport_code: "MUZ",
    airport_name: "Musoma Arpt",
    airport_city_code: "MUZ",
    airport_city_name: "Musoma",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-1.483",
    airport_lon: "33.8",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2382,
    airport_code: "MVB",
    airport_name: "Franceville Mvengue Arpt",
    airport_city_code: "MVB",
    airport_city_name: "Franceville Mvengue",
    airport_country_name: "GABON",
    airport_country_code: "GA",
    airport_timezone: "1",
    airport_lat: "-1.656156",
    airport_lon: "13.438036",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2383,
    airport_code: "MVC",
    airport_name: "Monroe County Arpt",
    airport_city_code: "MVC",
    airport_city_name: "Monroeville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2384,
    airport_code: "MVD",
    airport_name: "Carrasco Arpt",
    airport_city_code: "MVD",
    airport_city_name: "Montevideo",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-3",
    airport_lat: "-34.838417",
    airport_lon: "-56.030806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2385,
    airport_code: "MVF",
    airport_name: "Dixsept Rosado Arpt",
    airport_city_code: "MVF",
    airport_city_name: "Mossoro",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-5.20192",
    airport_lon: "-37.3643",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2386,
    airport_code: "MVN",
    airport_name: "Mt Vernon Outland Arpt",
    airport_city_code: "MVN",
    airport_city_name: "Mt Vernon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2387,
    airport_code: "MVQ",
    airport_name: "Mogilev Arpt",
    airport_city_code: "MVQ",
    airport_city_name: "Mogilev",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "53.9549",
    airport_lon: "30.0951",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2388,
    airport_code: "MVR",
    airport_name: "Salam Arpt",
    airport_city_code: "MVR",
    airport_city_name: "Maroua",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "1",
    airport_lat: "10.451392",
    airport_lon: "14.257361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2389,
    airport_code: "MVV",
    airport_name: "Megeve Arpt",
    airport_city_code: "MVV",
    airport_city_name: "Megeve",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.8208",
    airport_lon: "6.65222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2390,
    airport_code: "MVY",
    airport_name: "Dukes County",
    airport_city_code: "MVY",
    airport_city_name: "Martha S Vineyard",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.391667",
    airport_lon: "-70.615278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2391,
    airport_code: "MVZ",
    airport_name: "Ft Victoria Arpt",
    airport_city_code: "MVZ",
    airport_city_name: "Masvingo",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "2",
    airport_lat: "-20.055333",
    airport_lon: "30.859111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2392,
    airport_code: "MWA",
    airport_name: "Williamson County",
    airport_city_code: "MWA",
    airport_city_name: "Marion",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.754957",
    airport_lon: "-89.011094",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2393,
    airport_code: "MWH",
    airport_name: "Grant County",
    airport_city_code: "MWH",
    airport_city_name: "Moses Lake",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.207708",
    airport_lon: "-119.32019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2394,
    airport_code: "MWO",
    airport_name: "Hook Field",
    airport_city_code: "MWO",
    airport_city_name: "Middletown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2395,
    airport_code: "MWZ",
    airport_name: "Mwanza Arpt",
    airport_city_code: "MWZ",
    airport_city_name: "Mwanza",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-2.444486",
    airport_lon: "32.932667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2396,
    airport_code: "MXC",
    airport_name: "San Juan County Arpt",
    airport_city_code: "MXC",
    airport_city_name: "Monticello",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2397,
    airport_code: "MXL",
    airport_name: "Rodolfg Sachez Taboada",
    airport_city_code: "MXL",
    airport_city_name: "Mexicali",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-8",
    airport_lat: "32.630634",
    airport_lon: "-115.241637",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2398,
    airport_code: "MXN",
    airport_name: "Morlaix Arpt",
    airport_city_code: "MXN",
    airport_city_name: "Morlaix",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.603222",
    airport_lon: "-3.815783",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2399,
    airport_code: "MXS",
    airport_name: "Maota Arpt",
    airport_city_code: "MXS",
    airport_city_name: "Maota",
    airport_country_name: "SAMOA",
    airport_country_code: "WS",
    airport_timezone: "-11",
    airport_lat: "-13.733",
    airport_lon: "-172.3",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2400,
    airport_code: "MXX",
    airport_name: "Mora Arpt",
    airport_city_code: "MXX",
    airport_city_name: "Mora",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "60.957908",
    airport_lon: "14.511383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2401,
    airport_code: "MXY",
    airport_name: "McCarthy Arpt",
    airport_city_code: "MXY",
    airport_city_name: "Mccarthy",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "61.437061",
    airport_lon: "-142.903074",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2402,
    airport_code: "MXZ",
    airport_name: "Meixian Arpt",
    airport_city_code: "MXZ",
    airport_city_name: "Meixian",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "24.35",
    airport_lon: "116.133",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2403,
    airport_code: "MYA",
    airport_name: "Moruya Arpt",
    airport_city_code: "MYA",
    airport_city_name: "Moruya",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-35.8978",
    airport_lon: "150.144",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2404,
    airport_code: "MYC",
    airport_name: "Maracay Arpt",
    airport_city_code: "MYC",
    airport_city_name: "Maracay",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.249978",
    airport_lon: "-67.649419",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2405,
    airport_code: "MYD",
    airport_name: "Malindi Arpt",
    airport_city_code: "MYD",
    airport_city_name: "Malindi",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-3.22931",
    airport_lon: "40.1017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2406,
    airport_code: "MYJ",
    airport_name: "Matsuyama Airport",
    airport_city_code: "MYJ",
    airport_city_name: "Matsuyama",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.827222",
    airport_lon: "132.699722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2407,
    airport_code: "MYL",
    airport_name: "Mccall Arpt",
    airport_city_code: "MYL",
    airport_city_name: "Mccall",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.889722",
    airport_lon: "-116.101389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2408,
    airport_code: "MYP",
    airport_name: "Mary Arpt",
    airport_city_code: "MYP",
    airport_city_name: "Mary",
    airport_country_name: "TURKMENISTAN",
    airport_country_code: "TM",
    airport_timezone: "5",
    airport_lat: "37.6194",
    airport_lon: "61.8967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2409,
    airport_code: "MYQ",
    airport_name: "Mysore Arpt",
    airport_city_code: "MYQ",
    airport_city_name: "Mysore",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "12.3072",
    airport_lon: "76.6497",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2410,
    airport_code: "MYR",
    airport_name: "Myrtle Beach Jetway",
    airport_city_code: "MYR",
    airport_city_name: "Myrtle Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "33.67975",
    airport_lon: "-78.928333",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2411,
    airport_code: "CRE",
    airport_name: "Grand Strand Arpt",
    airport_city_code: "MYR",
    airport_city_name: "Myrtle Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "33.8118",
    airport_lon: "-78.7239",
    airport_num_airports: 3,
  },
  {
    airport_id: 2412,
    airport_code: "GGE",
    airport_name: "George Town Arpt",
    airport_city_code: "MYR",
    airport_city_name: "Myrtle Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2413,
    airport_code: "MYV",
    airport_name: "Yuba County Arpt",
    airport_city_code: "MYV",
    airport_city_name: "Marysville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "39.0553",
    airport_lon: "-121.3411",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2414,
    airport_code: "MYW",
    airport_name: "Mtwara Arpt",
    airport_city_code: "MYW",
    airport_city_name: "Mtwara",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "2",
    airport_lat: "-10.339058",
    airport_lon: "40.181781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2415,
    airport_code: "MYY",
    airport_name: "Miri Arpt",
    airport_city_code: "MYY",
    airport_city_name: "Miri",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.322014",
    airport_lon: "113.986806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2416,
    airport_code: "MZG",
    airport_name: "Makung Arpt",
    airport_city_code: "MZG",
    airport_city_name: "Makung",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "23.568669",
    airport_lon: "119.628311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2417,
    airport_code: "MZH",
    airport_name: "Merzifon Airport",
    airport_city_code: "MZH",
    airport_city_name: "Merzifon",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.829375",
    airport_lon: "35.521992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2418,
    airport_code: "MZI",
    airport_name: "Mopti Arpt",
    airport_city_code: "MZI",
    airport_city_name: "Mopti",
    airport_country_name: "MALI",
    airport_country_code: "ML",
    airport_timezone: "0",
    airport_lat: "14.512803",
    airport_lon: "-4.079561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2419,
    airport_code: "MZL",
    airport_name: "Santaguida Arpt",
    airport_city_code: "MZL",
    airport_city_name: "Manizales",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "5.029597",
    airport_lon: "-75.464708",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2420,
    airport_code: "MZM",
    airport_name: "Frescaty Airport",
    airport_city_code: "MZM",
    airport_city_name: "Metz",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.071667",
    airport_lon: "6.131667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2421,
    airport_code: "MZO",
    airport_name: "Sierra Maestra Arpt",
    airport_city_code: "MZO",
    airport_city_name: "Manzanillo",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "20.288172",
    airport_lon: "-77.0893",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2422,
    airport_code: "MZR",
    airport_name: "Mazar I Sharif Arpt",
    airport_city_code: "MZR",
    airport_city_name: "Mazar I Sharif",
    airport_country_name: "AFGHANISTAN",
    airport_country_code: "AF",
    airport_timezone: "5",
    airport_lat: "36.706914",
    airport_lon: "67.209678",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2423,
    airport_code: "MZT",
    airport_name: "Buelina Arpt",
    airport_city_code: "MZT",
    airport_city_name: "Mazatlan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "23.161356",
    airport_lon: "-106.266072",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2424,
    airport_code: "MZV",
    airport_name: "Mulu Arpt",
    airport_city_code: "MZV",
    airport_city_name: "Mulu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.048333",
    airport_lon: "114.805",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2425,
    airport_code: "NAA",
    airport_name: "Narrabri Arpt",
    airport_city_code: "NAA",
    airport_city_name: "Narrabri",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-30.3192",
    airport_lon: "149.827",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2426,
    airport_code: "NAC",
    airport_name: "Naracoorte Arpt",
    airport_city_code: "NAC",
    airport_city_name: "Naracoorte",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2427,
    airport_code: "NAG",
    airport_name: "Dr Ambedkar Intl Arpt",
    airport_city_code: "NAG",
    airport_city_name: "Nagpur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "21.092192",
    airport_lon: "79.047183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2428,
    airport_code: "NAJ",
    airport_name: "Nakhichevan",
    airport_city_code: "NAJ",
    airport_city_name: "Nakhichevan",
    airport_country_name: "AZERBAIJAN",
    airport_country_code: "AZ",
    airport_timezone: "3",
    airport_lat: "39.1888",
    airport_lon: "45.4584",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2429,
    airport_code: "NAK",
    airport_name: "Nakhon Ratchasima Arpt",
    airport_city_code: "NAK",
    airport_city_name: "Nakhon Ratchasima",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "14.949497",
    airport_lon: "102.312736",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2430,
    airport_code: "NAL",
    airport_name: "Nalchik Arpt",
    airport_city_code: "NAL",
    airport_city_name: "Nalchik",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "43.5129",
    airport_lon: "43.6366",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2431,
    airport_code: "NAM",
    airport_name: "Namlea Arpt",
    airport_city_code: "NAM",
    airport_city_name: "Namlea",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2432,
    airport_code: "NAN",
    airport_name: "Nadi Intl",
    airport_city_code: "NAN",
    airport_city_name: "Nadi",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "12",
    airport_lat: "-17.755392",
    airport_lon: "177.443378",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2433,
    airport_code: "NAO",
    airport_name: "Nanchong Arpt",
    airport_city_code: "NAO",
    airport_city_name: "Nanchong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.754",
    airport_lon: "106.062",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2434,
    airport_code: "NAP",
    airport_name: "Capodichino Arpt",
    airport_city_code: "NAP",
    airport_city_name: "Naples",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "40.886033",
    airport_lon: "14.290781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2435,
    airport_code: "NAR",
    airport_name: "Nare Arpt",
    airport_city_code: "NAR",
    airport_city_name: "Nare",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2436,
    airport_code: "WZY",
    airport_name: "Seaplane Base Arpt",
    airport_city_code: "NAS",
    airport_city_name: "Nassau",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.0872",
    airport_lon: "-77.3239",
    airport_num_airports: 3,
  },
  {
    airport_id: 2437,
    airport_code: "PID",
    airport_name: "Paradise Island Arpt",
    airport_city_code: "NAS",
    airport_city_name: "Nassau",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.083",
    airport_lon: "-77.3",
    airport_num_airports: 3,
  },
  {
    airport_id: 2438,
    airport_code: "NAS",
    airport_name: "Nassau Intl",
    airport_city_code: "NAS",
    airport_city_name: "Nassau",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "25.038958",
    airport_lon: "-77.466231",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2439,
    airport_code: "NAT",
    airport_name: "Augusto Severo Intl Arpt",
    airport_city_code: "NAT",
    airport_city_name: "Natal",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-5.911417",
    airport_lon: "-35.247717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2440,
    airport_code: "NAV",
    airport_name: "Nevsehir Arpt",
    airport_city_code: "NAV",
    airport_city_name: "Nevsehir",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.771867",
    airport_lon: "34.53455",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2441,
    airport_code: "NAW",
    airport_name: "Narathiwat Arpt",
    airport_city_code: "NAW",
    airport_city_name: "Narathiwat",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "6.519922",
    airport_lon: "101.7434",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2442,
    airport_code: "NBC",
    airport_name: "Nijnekamsk Arpt",
    airport_city_code: "NBC",
    airport_city_name: "Naberevnye Chelny",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-5",
    airport_lat: "32.477411",
    airport_lon: "-80.723161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2443,
    airport_code: "NBE",
    airport_name: "Hammamet International Arpt",
    airport_city_code: "NBE",
    airport_city_name: "Hammamet",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "36.075833",
    airport_lon: "10.438611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2444,
    airport_code: "WIL",
    airport_name: "Wilson Airport",
    airport_city_code: "NBO",
    airport_city_name: "Nairobi",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-1.321719",
    airport_lon: "36.814833",
    airport_num_airports: 2,
  },
  {
    airport_id: 2445,
    airport_code: "NBO",
    airport_name: "Jomo Kenyatta Intl",
    airport_city_code: "NBO",
    airport_city_name: "Nairobi",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-1.319167",
    airport_lon: "36.9275",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2446,
    airport_code: "NCA",
    airport_name: "North Caicos Municipal Arpt",
    airport_city_code: "NCA",
    airport_city_name: "North Caicos",
    airport_country_name: "TURKS AND CAICOS ISLANDS",
    airport_country_code: "TC",
    airport_timezone: "-5",
    airport_lat: "21.917475",
    airport_lon: "-71.939561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2447,
    airport_code: "NCE",
    airport_name: "Cote D Azur Arpt",
    airport_city_code: "NCE",
    airport_city_name: "Nice",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.658411",
    airport_lon: "7.215872",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2448,
    airport_code: "NCL",
    airport_name: "Newcastle Arpt",
    airport_city_code: "NCL",
    airport_city_name: "Newcastle",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "55.0375",
    airport_lon: "-1.691667",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2449,
    airport_code: "XVU",
    airport_name: "Durham Rail Station",
    airport_city_code: "NCL",
    airport_city_name: "Newcastle",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2450,
    airport_code: "NCS",
    airport_name: "Newcastle Intl Arpt",
    airport_city_code: "NCS",
    airport_city_name: "Newcastle",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-27.770586",
    airport_lon: "29.976894",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2451,
    airport_code: "NCU",
    airport_name: "Nukus",
    airport_city_code: "NCU",
    airport_city_name: "Nukus",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "42.4884",
    airport_lon: "59.6233",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2452,
    airport_code: "NCY",
    airport_name: "Annecy Meythet Arpt",
    airport_city_code: "NCY",
    airport_city_name: "Annecy",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.929233",
    airport_lon: "6.098764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2453,
    airport_code: "NDB",
    airport_name: "Nouadhibou Arpt",
    airport_city_code: "NDB",
    airport_city_name: "Nouadhibou",
    airport_country_name: "MAURITANIA",
    airport_country_code: "MR",
    airport_timezone: "0",
    airport_lat: "20.933067",
    airport_lon: "-17.029956",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2454,
    airport_code: "NDG",
    airport_name: "Qiqihar Arpt",
    airport_city_code: "NDG",
    airport_city_name: "Qiqihar",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "47.239628",
    airport_lon: "123.918131",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2455,
    airport_code: "NDJ",
    airport_name: "N Djamena Arpt",
    airport_city_code: "NDJ",
    airport_city_name: "N Djamena",
    airport_country_name: "CHAD",
    airport_country_code: "TD",
    airport_timezone: "1",
    airport_lat: "12.133689",
    airport_lon: "15.034019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2456,
    airport_code: "NDK",
    airport_name: "Namdrik Arpt",
    airport_city_code: "NDK",
    airport_city_name: "Namdrik",
    airport_country_name: "MARSHALL ISLANDS",
    airport_country_code: "MH",
    airport_timezone: "12",
    airport_lat: "5.63167",
    airport_lon: "168.125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2457,
    airport_code: "NDR",
    airport_name: "Nador Arpt",
    airport_city_code: "NDR",
    airport_city_name: "Nador",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "34.9888",
    airport_lon: "-3.02821",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2458,
    airport_code: "NDY",
    airport_name: "Sanday Arpt",
    airport_city_code: "NDY",
    airport_city_name: "Sanday",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.2503",
    airport_lon: "-2.57667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2459,
    airport_code: "NEF",
    airport_name: "Neftekamsk Airport",
    airport_city_code: "NEF",
    airport_city_name: "Neftekamsk Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2460,
    airport_code: "NEG",
    airport_name: "Negril Arpt",
    airport_city_code: "NEG",
    airport_city_name: "Negril",
    airport_country_name: "JAMAICA",
    airport_country_code: "JM",
    airport_timezone: "-5",
    airport_lat: "18.34",
    airport_lon: "-78.335556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2461,
    airport_code: "NER",
    airport_name: "Neryungri Chulman",
    airport_city_code: "NER",
    airport_city_name: "Neryungri Chulman",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2462,
    airport_code: "NEV",
    airport_name: "Nevis Airport",
    airport_city_code: "NEV",
    airport_city_name: "Nevis",
    airport_country_name: "SAINT KITTS AND NEVIS",
    airport_country_code: "KN",
    airport_timezone: "-4",
    airport_lat: "17.205678",
    airport_lon: "-62.589869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2463,
    airport_code: "NFG",
    airport_name: "Nefteyugansk Arpt",
    airport_city_code: "NFG",
    airport_city_name: "Nefteyugansk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "61.1083",
    airport_lon: "72.65",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2464,
    airport_code: "FLX",
    airport_name: "Fallon Municipal Arpt",
    airport_city_code: "NFL",
    airport_city_name: "Fallon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 2465,
    airport_code: "NGA",
    airport_name: "Young Arpt",
    airport_city_code: "NGA",
    airport_city_name: "Young",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-34.25",
    airport_lon: "148.2483",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2466,
    airport_code: "NGB",
    airport_name: "Ningbo Arpt",
    airport_city_code: "NGB",
    airport_city_name: "Ningbo",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.826683",
    airport_lon: "121.461906",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2467,
    airport_code: "NGE",
    airport_name: "N Gaoundere Arpt",
    airport_city_code: "NGE",
    airport_city_name: "N Gaoundere",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "1",
    airport_lat: "7.357011",
    airport_lon: "13.559242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2468,
    airport_code: "NKM",
    airport_name: "Nagoya Komaki Arpt",
    airport_city_code: "NGO",
    airport_city_name: "Nagoya",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.255",
    airport_lon: "136.924",
    airport_num_airports: 2,
  },
  {
    airport_id: 2469,
    airport_code: "NGO",
    airport_name: "Chubu Centrair Intl Arpt",
    airport_city_code: "NGO",
    airport_city_name: "Nagoya",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.858414",
    airport_lon: "136.805408",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2470,
    airport_code: "NGS",
    airport_name: "Nagasaki Airport",
    airport_city_code: "NGS",
    airport_city_name: "Nagasaki",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "32.916944",
    airport_lon: "129.913611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2471,
    airport_code: "NHA",
    airport_name: "Nha Trang Arpt",
    airport_city_code: "NHA",
    airport_city_name: "Nha Trang",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "12.227467",
    airport_lon: "109.192322",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2472,
    airport_code: "NHZ",
    airport_name: "Naval Air Station",
    airport_city_code: "NHZ",
    airport_city_name: "Brunswick",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "43.8917",
    airport_lon: "-69.9425",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2473,
    airport_code: "NIM",
    airport_name: "Niamey Airport",
    airport_city_code: "NIM",
    airport_city_name: "Niamey",
    airport_country_name: "NIGER",
    airport_country_code: "NE",
    airport_timezone: "1",
    airport_lat: "13.481547",
    airport_lon: "2.183614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2474,
    airport_code: "NJC",
    airport_name: "Nizhnevartovsk Arpt",
    airport_city_code: "NJC",
    airport_city_name: "Nizhnevartovsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "60.949272",
    airport_lon: "76.483617",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2475,
    airport_code: "NJF",
    airport_name: "Al Najaf International Arpt",
    airport_city_code: "NJF",
    airport_city_name: "Al Najaf International Arpt",
    airport_country_name: "IRAQ",
    airport_country_code: "IQ",
    airport_timezone: "3",
    airport_lat: "31.991667",
    airport_lon: "44.404167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2476,
    airport_code: "NKC",
    airport_name: "Nouakchott Arpt",
    airport_city_code: "NKC",
    airport_city_name: "Nouakchott",
    airport_country_name: "MAURITANIA",
    airport_country_code: "MR",
    airport_timezone: "0",
    airport_lat: "18.097856",
    airport_lon: "-15.947956",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2477,
    airport_code: "NKG",
    airport_name: "Nanjing Arpt",
    airport_city_code: "NKG",
    airport_city_name: "Nanjing",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.742042",
    airport_lon: "118.862025",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2478,
    airport_code: "NLA",
    airport_name: "Ndola Arpt",
    airport_city_code: "NLA",
    airport_city_name: "N Dola",
    airport_country_name: "ZAMBIA",
    airport_country_code: "ZM",
    airport_timezone: "2",
    airport_lat: "-12.998139",
    airport_lon: "28.664944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2479,
    airport_code: "NLC",
    airport_name: "Nas Reeves Field",
    airport_city_code: "NLC",
    airport_city_name: "Lemoore",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "36.333012",
    airport_lon: "-119.95208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2480,
    airport_code: "NLD",
    airport_name: "Quetzalcoatl Intl",
    airport_city_code: "NLD",
    airport_city_name: "Nuevo Laredo",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "27.443918",
    airport_lon: "-99.57046",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2481,
    airport_code: "NLK",
    airport_name: "Norfolk Island Intl Arpt",
    airport_city_code: "NLK",
    airport_city_name: "Norfolk Island",
    airport_country_name: "NORFOLK ISLAND",
    airport_country_code: "NF",
    airport_timezone: "11",
    airport_lat: "-29.041625",
    airport_lon: "167.938742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2482,
    airport_code: "NLP",
    airport_name: "Nelspruit Airport",
    airport_city_code: "NLP",
    airport_city_name: "Nelspruit",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.5",
    airport_lon: "30.9138",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2483,
    airport_code: "MQP",
    airport_name: "Kruger Mpumalanga Intl Arpt",
    airport_city_code: "NLP",
    airport_city_name: "Nelspruit",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.3832",
    airport_lon: "31.1056",
    airport_num_airports: 2,
  },
  {
    airport_id: 2484,
    airport_code: "NLV",
    airport_name: "Nikolaev Arpt",
    airport_city_code: "NLV",
    airport_city_name: "Nikolaev",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "47.0579",
    airport_lon: "31.9198",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2485,
    airport_code: "NMA",
    airport_name: "Namangrad Airport",
    airport_city_code: "NMA",
    airport_city_name: "Namangrad",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "40.9846",
    airport_lon: "71.5567",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2486,
    airport_code: "NNG",
    airport_name: "Nanning Arpt",
    airport_city_code: "NNG",
    airport_city_name: "Nanning",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "22.608267",
    airport_lon: "108.172442",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2487,
    airport_code: "NNM",
    airport_name: "Naryan-Mar Airport",
    airport_city_code: "NNM",
    airport_city_name: "Naryan Mar",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "67.380537",
    airport_lon: "53.051016",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2488,
    airport_code: "NNR",
    airport_name: "Connemara Arpt",
    airport_city_code: "NNR",
    airport_city_name: "Spiddal",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "53.2303",
    airport_lon: "-9.46778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2489,
    airport_code: "NNT",
    airport_name: "Nan Arpt",
    airport_city_code: "NNT",
    airport_city_name: "Nan Th",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "18.807914",
    airport_lon: "100.783419",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2490,
    airport_code: "NOA",
    airport_name: "Nowra Arpt",
    airport_city_code: "NOA",
    airport_city_name: "Nowra",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-34.9489",
    airport_lon: "150.537",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2491,
    airport_code: "NOB",
    airport_name: "Nosara Beach Arpt",
    airport_city_code: "NOB",
    airport_city_name: "Nosara Beach",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "9.97649",
    airport_lon: "-85.653",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2492,
    airport_code: "NOC",
    airport_name: "Knock International",
    airport_city_code: "NOC",
    airport_city_name: "Knock",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "53.910297",
    airport_lon: "-8.818492",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2493,
    airport_code: "NOG",
    airport_name: "Nogales Arpt",
    airport_city_code: "NOG",
    airport_city_name: "Nogales",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "31.226083",
    airport_lon: "-110.975831",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2494,
    airport_code: "NOJ",
    airport_name: "Nojabrxsk Arpt",
    airport_city_code: "NOJ",
    airport_city_name: "Nojabrxsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "63.110079",
    airport_lon: "75.162243",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2495,
    airport_code: "NOS",
    airport_name: "Fascene Arpt",
    airport_city_code: "NOS",
    airport_city_name: "Nossi Be",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-13.312067",
    airport_lon: "48.314822",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2496,
    airport_code: "NOT",
    airport_name: "Novato Arpt",
    airport_city_code: "NOT",
    airport_city_name: "Novato",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2497,
    airport_code: "NOU",
    airport_name: "Tontouta Arpt",
    airport_city_code: "NOU",
    airport_city_name: "Noumea",
    airport_country_name: "NEW CALEDONIA",
    airport_country_code: "NC",
    airport_timezone: "11",
    airport_lat: "-22.014553",
    airport_lon: "166.212972",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2498,
    airport_code: "GEA",
    airport_name: "Magenta Arpt",
    airport_city_code: "NOU",
    airport_city_name: "Noumea",
    airport_country_name: "NEW CALEDONIA",
    airport_country_code: "NC",
    airport_timezone: "11",
    airport_lat: "-22.258278",
    airport_lon: "166.472806",
    airport_num_airports: 2,
  },
  {
    airport_id: 2499,
    airport_code: "NOZ",
    airport_name: "Novokuznetsk Arpt",
    airport_city_code: "NOZ",
    airport_city_name: "Novokuznetsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "53.8114",
    airport_lon: "86.8772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2500,
    airport_code: "NPE",
    airport_name: "Hawkes Bay Arpt",
    airport_city_code: "NPE",
    airport_city_name: "Napier Hastings",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-39.465833",
    airport_lon: "176.87",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2501,
    airport_code: "NPL",
    airport_name: "New Plymouth Arpt",
    airport_city_code: "NPL",
    airport_city_name: "New Plymouth",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-39.008611",
    airport_lon: "174.179167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2502,
    airport_code: "NPT",
    airport_name: "Newport State Arpt",
    airport_city_code: "NPT",
    airport_city_name: "Newport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2503,
    airport_code: "NQI",
    airport_name: "Naval Air Station Arpt",
    airport_city_code: "NQI",
    airport_city_name: "Kingsville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "27.507223",
    airport_lon: "-97.809723",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2504,
    airport_code: "NQN",
    airport_name: "Neuquen Airport",
    airport_city_code: "NQN",
    airport_city_name: "Neuquen",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-38.949",
    airport_lon: "-68.155711",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2505,
    airport_code: "EMA",
    airport_name: "East Midlands Arpt",
    airport_city_code: "NQT",
    airport_city_name: "Nottingham Uk",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.831111",
    airport_lon: "-1.328056",
    airport_num_airports: 2,
  },
  {
    airport_id: 2506,
    airport_code: "NQT",
    airport_name: "Nottingham Arpt",
    airport_city_code: "NQT",
    airport_city_name: "Nottingham Uk",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.92",
    airport_lon: "-1.079167",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2507,
    airport_code: "NQY",
    airport_name: "Newquay Civil Arpt",
    airport_city_code: "NQY",
    airport_city_name: "Newquay",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.440558",
    airport_lon: "-4.995408",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2508,
    airport_code: "NRA",
    airport_name: "Narrandera Arpt",
    airport_city_code: "NRA",
    airport_city_name: "Narrandera",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-34.7022",
    airport_lon: "146.512",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2509,
    airport_code: "XYK",
    airport_name: "Norrkoping Railway Service",
    airport_city_code: "NRK",
    airport_city_name: "Norrkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2510,
    airport_code: "NRK",
    airport_name: "Kungsangen Arpt",
    airport_city_code: "NRK",
    airport_city_name: "Norrkoping",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "58.586253",
    airport_lon: "16.250622",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2511,
    airport_code: "NRL",
    airport_name: "North Ronaldsay Arpt",
    airport_city_code: "NRL",
    airport_city_name: "North Ronaldsay",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.3675",
    airport_lon: "-2.43444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2512,
    airport_code: "NSA",
    airport_name: "Noosa Airport",
    airport_city_code: "NSA",
    airport_city_name: "Noosa",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2513,
    airport_code: "NSE",
    airport_name: "Whiting Field Nas",
    airport_city_code: "NSE",
    airport_city_name: "Milton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.724167",
    airport_lon: "-87.021944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2514,
    airport_code: "NSK",
    airport_name: "Norilsk Arpt",
    airport_city_code: "NSK",
    airport_city_name: "Norilsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "69.311053",
    airport_lon: "87.332183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2515,
    airport_code: "NSM",
    airport_name: "Norseman Arpt",
    airport_city_code: "NSM",
    airport_city_name: "Norseman",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2516,
    airport_code: "NSN",
    airport_name: "Nelson Arpt",
    airport_city_code: "NSN",
    airport_city_name: "Nelson",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-41.298333",
    airport_lon: "173.221111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2517,
    airport_code: "NSO",
    airport_name: "Scone Airport",
    airport_city_code: "NSO",
    airport_city_name: "Scone",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-32.0372",
    airport_lon: "150.832",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2518,
    airport_code: "NST",
    airport_name: "Nakhon Si Thammarat Arpt",
    airport_city_code: "NST",
    airport_city_name: "Nakhon Si Thammarat",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "8.539617",
    airport_lon: "99.944725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2519,
    airport_code: "NTB",
    airport_name: "Notodden Arpt",
    airport_city_code: "NTB",
    airport_city_name: "Notodden",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "59.5655",
    airport_lon: "9.2123",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2520,
    airport_code: "QJZ",
    airport_name: "Nantes Rail Station",
    airport_city_code: "NTE",
    airport_city_name: "Nantes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "47.1667",
    airport_lon: "-1.6167",
    airport_num_airports: 2,
  },
  {
    airport_id: 2521,
    airport_code: "NTE",
    airport_name: "Nantes Atlantique",
    airport_city_code: "NTE",
    airport_city_name: "Nantes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.153189",
    airport_lon: "-1.610725",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2522,
    airport_code: "NTG",
    airport_name: "Nantong Arpt",
    airport_city_code: "NTG",
    airport_city_name: "Nantong",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "32.0708",
    airport_lon: "120.976",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2523,
    airport_code: "NTL",
    airport_name: "Williamtown Arpt",
    airport_city_code: "NTL",
    airport_city_name: "Newcastle",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-32.78",
    airport_lon: "151.83",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2524,
    airport_code: "BEO",
    airport_name: "Belmont Arpt",
    airport_city_code: "NTL",
    airport_city_name: "Newcastle",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2525,
    airport_code: "NTN",
    airport_name: "Normanton Arpt",
    airport_city_code: "NTN",
    airport_city_name: "Normanton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-17.6836",
    airport_lon: "141.07",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2526,
    airport_code: "NTO",
    airport_name: "Santo Antao Arpt",
    airport_city_code: "NTO",
    airport_city_name: "Santo Antao",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2527,
    airport_code: "NTY",
    airport_name: "Pilansberg Arpt",
    airport_city_code: "NTY",
    airport_city_name: "Sun City",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.333822",
    airport_lon: "27.173358",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2528,
    airport_code: "NUE",
    airport_name: "Nuremberg Arpt",
    airport_city_code: "NUE",
    airport_city_name: "Nuremberg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.4987",
    airport_lon: "11.066897",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2529,
    airport_code: "ZAQ",
    airport_name: "Nuremberg Rail Station",
    airport_city_code: "NUE",
    airport_city_name: "Nuremberg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.446389",
    airport_lon: "11.081944",
    airport_num_airports: 2,
  },
  {
    airport_id: 2530,
    airport_code: "NUL",
    airport_name: "Nulato Arpt",
    airport_city_code: "NUL",
    airport_city_name: "Nulato",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "64.729444",
    airport_lon: "-158.074167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2531,
    airport_code: "NUQ",
    airport_name: "Moffett Field",
    airport_city_code: "NUQ",
    airport_city_name: "Mountain View",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.416142",
    airport_lon: "-122.049139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2532,
    airport_code: "NUR",
    airport_name: "Nullarbor Arpt",
    airport_city_code: "NUR",
    airport_city_name: "Nullarbor",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "2",
    airport_lat: "49.446",
    airport_lon: "11.081944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2533,
    airport_code: "NUX",
    airport_name: "Novy Urengoy Arpt",
    airport_city_code: "NUX",
    airport_city_name: "Novy Urengoy",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "66.041811",
    airport_lon: "76.313938",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2534,
    airport_code: "NVK",
    airport_name: "Framnes Arpt",
    airport_city_code: "NVK",
    airport_city_name: "Narvik",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "68.435833",
    airport_lon: "17.388056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2535,
    airport_code: "NVR",
    airport_name: "Yurievo Airport",
    airport_city_code: "NVR",
    airport_city_name: "Novgorod",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2536,
    airport_code: "NVS",
    airport_name: "Nevers Arpt",
    airport_city_code: "NVS",
    airport_city_name: "Nevers",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.002625",
    airport_lon: "3.113333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2537,
    airport_code: "NVT",
    airport_name: "Navegantes Arpt",
    airport_city_code: "NVT",
    airport_city_name: "Navegantes",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-26.879999",
    airport_lon: "-48.65139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2538,
    airport_code: "NWI",
    airport_name: "Norwich Airport",
    airport_city_code: "NWI",
    airport_city_name: "Norwich",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "52.675833",
    airport_lon: "1.282778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2539,
    airport_code: "NYA",
    airport_name: "Nyagan Airport",
    airport_city_code: "NYA",
    airport_city_name: "Nyagan Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "62.11",
    airport_lon: "65.615",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2540,
    airport_code: "JFK",
    airport_name: "John F Kennedy Intl",
    airport_city_code: "NYC",
    airport_city_name: "New York",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.639751",
    airport_lon: "-73.778925",
    airport_num_airports: 1,
  },
  {
    airport_id: 2541,
    airport_code: "LGA",
    airport_name: "La Guardia",
    airport_city_code: "NYC",
    airport_city_name: "New York",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.777245",
    airport_lon: "-73.872608",
    airport_num_airports: 1,
  },
  {
    airport_id: 2542,
    airport_code: "NYE",
    airport_name: "Nyeri Arpt",
    airport_city_code: "NYE",
    airport_city_name: "Nyeri",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2543,
    airport_code: "NYK",
    airport_name: "Nanyuki Arpt",
    airport_city_code: "NYK",
    airport_city_name: "Nanyuki",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "-0.067",
    airport_lon: "37.033",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2544,
    airport_code: "NYM",
    airport_name: "Nadym Airport",
    airport_city_code: "NYM",
    airport_city_name: "Nadym Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "65.4809",
    airport_lon: "72.6989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2545,
    airport_code: "NYN",
    airport_name: "Nyngan Arpt",
    airport_city_code: "NYN",
    airport_city_name: "Nyngan",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-31.5531",
    airport_lon: "147.2031",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2546,
    airport_code: "NYU",
    airport_name: "Nyaung U Arpt",
    airport_city_code: "NYU",
    airport_city_name: "Nyaung",
    airport_country_name: "MYANMAR",
    airport_country_code: "MM",
    airport_timezone: "6",
    airport_lat: "21.173833",
    airport_lon: "94.924667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2547,
    airport_code: "OAG",
    airport_name: "Springhill Arpt",
    airport_city_code: "OAG",
    airport_city_name: "Orange",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-33.3817",
    airport_lon: "149.133",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2548,
    airport_code: "OAJ",
    airport_name: "Albert J Ellis",
    airport_city_code: "OAJ",
    airport_city_name: "Jacksonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.829164",
    airport_lon: "-77.612139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2549,
    airport_code: "OAK",
    airport_name: "Metro Oakland Intl Arpt",
    airport_city_code: "OAK",
    airport_city_name: "Oakland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.721278",
    airport_lon: "-122.220722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2550,
    airport_code: "OAL",
    airport_name: "Cacoal Arpt",
    airport_city_code: "OAL",
    airport_city_name: "Cacoal",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "2",
    airport_lat: "47.78",
    airport_lon: "10.627",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2551,
    airport_code: "OAM",
    airport_name: "Oamaru Airport",
    airport_city_code: "OAM",
    airport_city_name: "Oamaru",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-44.97",
    airport_lon: "171.081667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2552,
    airport_code: "OAX",
    airport_name: "Xoxocotlan Arpt",
    airport_city_code: "OAX",
    airport_city_name: "Oaxaca",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "16.999906",
    airport_lon: "-96.726639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2553,
    airport_code: "OBE",
    airport_name: "Okeechobee County Arpt",
    airport_city_code: "OBE",
    airport_city_name: "Okeechobee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2554,
    airport_code: "OBN",
    airport_name: "Connel Arpt",
    airport_city_code: "OBN",
    airport_city_name: "Oban",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "56.464",
    airport_lon: "-5.4",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2555,
    airport_code: "OBO",
    airport_name: "Obihiro Arpt",
    airport_city_code: "OBO",
    airport_city_name: "Obihiro",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "42.733333",
    airport_lon: "143.217222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2556,
    airport_code: "OBS",
    airport_name: "Vals Lanas Arpt",
    airport_city_code: "OBS",
    airport_city_name: "Aubenas",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.544236",
    airport_lon: "4.372192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2557,
    airport_code: "OCA",
    airport_name: "Ocean Reef Arpt",
    airport_city_code: "OCA",
    airport_city_name: "Ocean Reef",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "25.325393",
    airport_lon: "-80.274775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2558,
    airport_code: "OCE",
    airport_name: "Ocean City Airport",
    airport_city_code: "OCE",
    airport_city_name: "Ocean City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2559,
    airport_code: "OCF",
    airport_name: "Taylor Field Arpt",
    airport_city_code: "OCF",
    airport_city_name: "Ocala",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2560,
    airport_code: "OCJ",
    airport_name: "Boscobel Arpt",
    airport_city_code: "OCJ",
    airport_city_name: "Ocho Rios",
    airport_country_name: "JAMAICA",
    airport_country_code: "JM",
    airport_timezone: "-5",
    airport_lat: "18.404247",
    airport_lon: "-76.969017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2561,
    airport_code: "OCN",
    airport_name: "Oceanside Municipal Arpt",
    airport_city_code: "OCN",
    airport_city_name: "Oceanside",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2562,
    airport_code: "ODB",
    airport_name: "Cordoba Airport",
    airport_city_code: "ODB",
    airport_city_name: "Cordoba",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "37.842006",
    airport_lon: "-4.848878",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2563,
    airport_code: "ZBQ",
    airport_name: "Odense Railroad Station",
    airport_city_code: "ODE",
    airport_city_name: "Odense",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2564,
    airport_code: "ZIM",
    airport_name: "Odense Bus Service",
    airport_city_code: "ODE",
    airport_city_name: "Odense",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2565,
    airport_code: "ODE",
    airport_name: "Odense Airport",
    airport_city_code: "ODE",
    airport_city_name: "Odense",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.476664",
    airport_lon: "10.330933",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2566,
    airport_code: "ODN",
    airport_name: "Long Seridan Arpt",
    airport_city_code: "ODN",
    airport_city_name: "Long Seridan",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "3.967",
    airport_lon: "115.05",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2567,
    airport_code: "ODS",
    airport_name: "Central Arpt",
    airport_city_code: "ODS",
    airport_city_name: "Odessa",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "46.426767",
    airport_lon: "30.676464",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2568,
    airport_code: "ODW",
    airport_name: "Oak Harbour Municipal",
    airport_city_code: "ODW",
    airport_city_name: "Oak Harbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "48.2515",
    airport_lon: "-122.6737",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2569,
    airport_code: "OEA",
    airport_name: "Oneal Arpt",
    airport_city_code: "OEA",
    airport_city_name: "Vincennes",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2570,
    airport_code: "OEL",
    airport_name: "Oryol Yuzhny Airport",
    airport_city_code: "OEL",
    airport_city_name: "Oryol Yuzhny Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2571,
    airport_code: "OER",
    airport_name: "Ornskoldsvik Arpt",
    airport_city_code: "OER",
    airport_city_name: "Ornskoldsvik",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "63.408339",
    airport_lon: "18.990039",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2572,
    airport_code: "OFK",
    airport_name: "Karl Stefan Fld",
    airport_city_code: "OFK",
    airport_city_name: "Norfolk",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.9856",
    airport_lon: "-97.4353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2573,
    airport_code: "OGA",
    airport_name: "Searle Field",
    airport_city_code: "OGA",
    airport_city_name: "Ogallala",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2574,
    airport_code: "OGD",
    airport_name: "Ogden Municipal",
    airport_city_code: "OGD",
    airport_city_name: "Ogden",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.1961",
    airport_lon: "-112.0122",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2575,
    airport_code: "OGG",
    airport_name: "Kahului Airport",
    airport_city_code: "OGG",
    airport_city_name: "Maui",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "20.89865",
    airport_lon: "-156.430458",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2576,
    airport_code: "OGS",
    airport_name: "Ogdensburg Municipal",
    airport_city_code: "OGS",
    airport_city_name: "Ogdensburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.681854",
    airport_lon: "-75.4655",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2577,
    airport_code: "OGZ",
    airport_name: "VLADIKAVKAZ",
    airport_city_code: "OGZ",
    airport_city_name: "VLADIKAVKAZ",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "43.2051",
    airport_lon: "44.6066",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2578,
    airport_code: "OHD",
    airport_name: "Ohrid Arpt",
    airport_city_code: "OHD",
    airport_city_name: "Ohrid",
    airport_country_name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    airport_country_code: "MK",
    airport_timezone: "1",
    airport_lat: "41.179956",
    airport_lon: "20.742325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2579,
    airport_code: "OHH",
    airport_name: "Okha Airport",
    airport_city_code: "OHH",
    airport_city_name: "Okha Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "11",
    airport_lat: "53.583333",
    airport_lon: "142.933333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2580,
    airport_code: "OHO",
    airport_name: "Okhotsk Airport",
    airport_city_code: "OHO",
    airport_city_name: "Okhotsk Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "11",
    airport_lat: "59.4101",
    airport_lon: "143.057",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2581,
    airport_code: "OIA",
    airport_name: "Ourilandia Arpt",
    airport_city_code: "OIA",
    airport_city_name: "Ourilandia",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-6.763056",
    airport_lon: "-51.05",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2582,
    airport_code: "OIM",
    airport_name: "Oshima Arpt",
    airport_city_code: "OIM",
    airport_city_name: "Oshima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.782033",
    airport_lon: "139.360306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2583,
    airport_code: "OIT",
    airport_name: "Oita Arpt",
    airport_city_code: "OIT",
    airport_city_name: "Oita",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.479444",
    airport_lon: "131.737222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2584,
    airport_code: "OKA",
    airport_name: "Naha Field",
    airport_city_code: "OKA",
    airport_city_name: "Okinawa",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "26.195814",
    airport_lon: "127.645869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2585,
    airport_code: "PWA",
    airport_name: "Wiley Post Arpt",
    airport_city_code: "OKC",
    airport_city_name: "Oklahoma City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "35.5342",
    airport_lon: "-97.6471",
    airport_num_airports: 3,
  },
  {
    airport_id: 2586,
    airport_code: "DWN",
    airport_name: "Downtown Airpark",
    airport_city_code: "OKC",
    airport_city_name: "Oklahoma City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2587,
    airport_code: "OKC",
    airport_name: "Will Rogers World Arpt",
    airport_city_code: "OKC",
    airport_city_name: "Oklahoma City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "35.393089",
    airport_lon: "-97.600733",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2588,
    airport_code: "OKE",
    airport_name: "Okino Erabu Arpt",
    airport_city_code: "OKE",
    airport_city_name: "Okino Erabu",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "-100",
    airport_lat: "27.4317",
    airport_lon: "128.7056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2589,
    airport_code: "OKJ",
    airport_name: "Okayama Arpt",
    airport_city_code: "OKJ",
    airport_city_name: "Okayama",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.756944",
    airport_lon: "133.855278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2590,
    airport_code: "OKK",
    airport_name: "Kokomo Municipal",
    airport_city_code: "OKK",
    airport_city_name: "Kokomo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2591,
    airport_code: "OKT",
    airport_name: "Oktyabrsky Airport",
    airport_city_code: "OKT",
    airport_city_name: "Oktyabrsky Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2592,
    airport_code: "OKY",
    airport_name: "Oakey Arpt",
    airport_city_code: "OKY",
    airport_city_name: "Oakey",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-27.411389",
    airport_lon: "151.735278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2593,
    airport_code: "OLB",
    airport_name: "Costa Smeralda Arpt",
    airport_city_code: "OLB",
    airport_city_name: "Olbia",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "40.898661",
    airport_lon: "9.517628",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2594,
    airport_code: "OLK",
    airport_name: "Fuerte Olimpo Arpt",
    airport_city_code: "OLK",
    airport_city_name: "Fuerte Olimpo",
    airport_country_name: "PARAGUAY",
    airport_country_code: "PY",
    airport_timezone: "-100",
    airport_lat: "60.3883",
    airport_lon: "120.4717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2595,
    airport_code: "OLM",
    airport_name: "Olympia Arpt",
    airport_city_code: "OLM",
    airport_city_name: "Olympia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "46.969404",
    airport_lon: "-122.902545",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2596,
    airport_code: "OLP",
    airport_name: "Olympic Dam Arpt",
    airport_city_code: "OLP",
    airport_city_name: "Olympic Dam",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-30.485",
    airport_lon: "136.877",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2597,
    airport_code: "OLS",
    airport_name: "International Arpt",
    airport_city_code: "OLS",
    airport_city_name: "Nogales",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "31.417722",
    airport_lon: "-110.84789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2598,
    airport_code: "OLU",
    airport_name: "Columbus Arpt",
    airport_city_code: "OLU",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2599,
    airport_code: "MIQ",
    airport_name: "Millard Airport",
    airport_city_code: "OMA",
    airport_city_name: "Omaha",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2600,
    airport_code: "OMA",
    airport_name: "Eppley Airfield",
    airport_city_code: "OMA",
    airport_city_name: "Omaha",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "41.303167",
    airport_lon: "-95.894069",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2601,
    airport_code: "OMD",
    airport_name: "Oranjemund Arpt",
    airport_city_code: "OMD",
    airport_city_name: "Oranjemund",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-28.5847",
    airport_lon: "16.4467",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2602,
    airport_code: "OME",
    airport_name: "Nome Arpt",
    airport_city_code: "OME",
    airport_city_name: "Nome",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "64.512203",
    airport_lon: "-165.445247",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2603,
    airport_code: "OMO",
    airport_name: "Mostar Arpt",
    airport_city_code: "OMO",
    airport_city_name: "Mostar",
    airport_country_name: "BOSNIA AND HERZEGOVINA",
    airport_country_code: "BA",
    airport_timezone: "1",
    airport_lat: "43.2829",
    airport_lon: "17.845878",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2604,
    airport_code: "OMR",
    airport_name: "Oradea Arpt",
    airport_city_code: "OMR",
    airport_city_name: "Oradea",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "47.025278",
    airport_lon: "21.9025",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2605,
    airport_code: "OMS",
    airport_name: "Omsk Arpt",
    airport_city_code: "OMS",
    airport_city_name: "Omsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "7",
    airport_lat: "54.967042",
    airport_lon: "73.310514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2606,
    airport_code: "OND",
    airport_name: "Ondangwa Arpt",
    airport_city_code: "OND",
    airport_city_name: "Ondangwa",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-17.8782",
    airport_lon: "15.9526",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2607,
    airport_code: "ONG",
    airport_name: "Mornington Arpt",
    airport_city_code: "ONG",
    airport_city_name: "Mornington",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-16.6625",
    airport_lon: "139.178",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2608,
    airport_code: "ONH",
    airport_name: "Oneonta Municpal",
    airport_city_code: "ONH",
    airport_city_name: "Oneonta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.524722",
    airport_lon: "-75.064444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2609,
    airport_code: "ONJ",
    airport_name: "Odate Noshiro Arpt",
    airport_city_code: "ONJ",
    airport_city_name: "Odate Noshiro",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "40.1919",
    airport_lon: "140.371",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2610,
    airport_code: "ONM",
    airport_name: "Socorro Municipal Arpt",
    airport_city_code: "ONM",
    airport_city_name: "Socorro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2611,
    airport_code: "ONP",
    airport_name: "Newport Municipal Arpt",
    airport_city_code: "ONP",
    airport_city_name: "Newport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "8",
    airport_lat: "44.580361",
    airport_lon: "-124.057917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2612,
    airport_code: "ONQ",
    airport_name: "Zonguldak Airport",
    airport_city_code: "ONQ",
    airport_city_name: "Zonguldak",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "41.506111",
    airport_lon: "32.088611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2613,
    airport_code: "ONT",
    airport_name: "Ontario Intl",
    airport_city_code: "ONT",
    airport_city_name: "Ontario",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.056",
    airport_lon: "-117.601194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2614,
    airport_code: "OOL",
    airport_name: "Gold Coast Arpt",
    airport_city_code: "OOL",
    airport_city_name: "Gold Coast",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-28.164444",
    airport_lon: "153.504722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2615,
    airport_code: "OOM",
    airport_name: "Cooma Airport",
    airport_city_code: "OOM",
    airport_city_name: "Cooma",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-36.3006",
    airport_lon: "148.974",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2616,
    airport_code: "OPA",
    airport_name: "Kopasker Arpt",
    airport_city_code: "OPA",
    airport_city_name: "Kopasker",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2617,
    airport_code: "OPO",
    airport_name: "Porto Airport",
    airport_city_code: "OPO",
    airport_city_name: "Porto",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "41.248055",
    airport_lon: "-8.681389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2618,
    airport_code: "XWR",
    airport_name: "Orebro Bofors Railway Service",
    airport_city_code: "ORB",
    airport_city_name: "Orebro Bofors",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2619,
    airport_code: "ORB",
    airport_name: "Orebro Bofors Arpt",
    airport_city_code: "ORB",
    airport_city_name: "Orebro Bofors",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "59.223733",
    airport_lon: "15.037956",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2620,
    airport_code: "XWM",
    airport_name: "Hallsberg Rail Station",
    airport_city_code: "ORB",
    airport_city_name: "Orebro Bofors",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 2621,
    airport_code: "ORE",
    airport_name: "Orleans Arpt",
    airport_city_code: "ORE",
    airport_city_name: "Orleans",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.987778",
    airport_lon: "1.760556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2622,
    airport_code: "ORF",
    airport_name: "Norfolk Intl Arpt",
    airport_city_code: "ORF",
    airport_city_name: "Norfolk",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "36.894611",
    airport_lon: "-76.201222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2623,
    airport_code: "ORH",
    airport_name: "Worcester Arpt",
    airport_city_code: "ORH",
    airport_city_name: "Worcester",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.2673",
    airport_lon: "-71.8757",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2624,
    airport_code: "ORK",
    airport_name: "Cork International Arpt",
    airport_city_code: "ORK",
    airport_city_name: "Cork",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "51.841269",
    airport_lon: "-8.491111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2625,
    airport_code: "ORL",
    airport_name: "Herndon Arpt",
    airport_city_code: "ORL",
    airport_city_name: "Orlando",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "28.545464",
    airport_lon: "-81.332936",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2626,
    airport_code: "MCO",
    airport_name: "Orlando Intl Arpt",
    airport_city_code: "ORL",
    airport_city_name: "Orlando",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "28.429394",
    airport_lon: "-81.308994",
    airport_num_airports: 2,
  },
  {
    airport_id: 2627,
    airport_code: "ORM",
    airport_name: "Northampton Rail Station",
    airport_city_code: "ORM",
    airport_city_name: "Northampton",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2628,
    airport_code: "ORN",
    airport_name: "Es Senia",
    airport_city_code: "ORN",
    airport_city_name: "Oran",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "35.623858",
    airport_lon: "-0.621183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2629,
    airport_code: "ORS",
    airport_name: "Waterport Arpt",
    airport_city_code: "ORS",
    airport_city_name: "Orpheus Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-18.634",
    airport_lon: "146.5",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2630,
    airport_code: "ITM",
    airport_name: "Itami Arpt",
    airport_city_code: "OSA",
    airport_city_name: "Osaka",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.785528",
    airport_lon: "135.438222",
    airport_num_airports: 2,
  },
  {
    airport_id: 2631,
    airport_code: "KIX",
    airport_name: "Kansai International Arpt",
    airport_city_code: "OSA",
    airport_city_name: "Osaka",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.434722",
    airport_lon: "135.244167",
    airport_num_airports: 2,
  },
  {
    airport_id: 2632,
    airport_code: "OSD",
    airport_name: "Froesoe Airport",
    airport_city_code: "OSD",
    airport_city_name: "Ostersund",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "63.1944",
    airport_lon: "14.5003",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2633,
    airport_code: "OSH",
    airport_name: "Wittman Field",
    airport_city_code: "OSH",
    airport_city_name: "Oshkosh",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.024983",
    airport_lon: "-88.551336",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2634,
    airport_code: "OSI",
    airport_name: "Osijek Arpt",
    airport_city_code: "OSI",
    airport_city_name: "Osijek",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "45.462667",
    airport_lon: "18.810156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2635,
    airport_code: "OSK",
    airport_name: "Oskarshamn",
    airport_city_code: "OSK",
    airport_city_name: "Oskarshamn",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "57.350453",
    airport_lon: "16.497972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2636,
    airport_code: "OSL",
    airport_name: "Oslo Arpt",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "60.193917",
    airport_lon: "11.100361",
    airport_num_airports: 13,
    airport_city: "true",
  },
  {
    airport_id: 2637,
    airport_code: "TRF",
    airport_name: "Sandefjord Arpt",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "59.186703",
    airport_lon: "10.258628",
    airport_num_airports: 13,
  },
  {
    airport_id: 2638,
    airport_code: "RYG",
    airport_name: "Moss Rygge Arpt",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "59.378933",
    airport_lon: "10.785389",
    airport_num_airports: 13,
  },
  {
    airport_id: 2639,
    airport_code: "XZO",
    airport_name: "Oslo Central Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2640,
    airport_code: "XND",
    airport_name: "Drammen Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2641,
    airport_code: "XKQ",
    airport_name: "Sarpsborg Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2642,
    airport_code: "XKM",
    airport_name: "Moss Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2643,
    airport_code: "XKI",
    airport_name: "Lillestrom Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2644,
    airport_code: "XKF",
    airport_name: "Fredrikstad Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2645,
    airport_code: "XKE",
    airport_name: "Rena Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2646,
    airport_code: "XKD",
    airport_name: "Halden Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2647,
    airport_code: "XKB",
    airport_name: "Kongsberg Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2648,
    airport_code: "XGU",
    airport_name: "Asker Rail Station",
    airport_city_code: "OSL",
    airport_city_name: "Oslo",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 13,
  },
  {
    airport_id: 2649,
    airport_code: "OSM",
    airport_name: "Mosul Airport",
    airport_city_code: "OSM",
    airport_city_name: "Mosul",
    airport_country_name: "IRAQ",
    airport_country_code: "IQ",
    airport_timezone: "3",
    airport_lat: "36.305833",
    airport_lon: "43.1475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2650,
    airport_code: "OSR",
    airport_name: "Mosnov Arpt",
    airport_city_code: "OSR",
    airport_city_name: "Ostrava",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "1",
    airport_lat: "49.696292",
    airport_lon: "18.111053",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2651,
    airport_code: "OSS",
    airport_name: "Osh Airport",
    airport_city_code: "OSS",
    airport_city_name: "Osh",
    airport_country_name: "KYRGYZSTAN",
    airport_country_code: "KG",
    airport_timezone: "5",
    airport_lat: "40.608989",
    airport_lon: "72.793269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2652,
    airport_code: "OST",
    airport_name: "Ostend Airport",
    airport_city_code: "OST",
    airport_city_name: "Ostende",
    airport_country_name: "BELGIUM",
    airport_country_code: "BE",
    airport_timezone: "1",
    airport_lat: "51.198889",
    airport_lon: "2.862222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2653,
    airport_code: "OSY",
    airport_name: "Namsos Arpt",
    airport_city_code: "OSY",
    airport_city_name: "Namsos",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "64.4722",
    airport_lon: "11.5786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2654,
    airport_code: "OTG",
    airport_name: "Worthington Arpt",
    airport_city_code: "OTG",
    airport_city_name: "Worthington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2655,
    airport_code: "OTH",
    airport_name: "North Bend Municipal",
    airport_city_code: "OTH",
    airport_city_name: "North Bend",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "43.4171",
    airport_lon: "-124.246",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2656,
    airport_code: "OTM",
    airport_name: "Industrial Arpt",
    airport_city_code: "OTM",
    airport_city_name: "Ottumwa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.1066",
    airport_lon: "-92.4479",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2657,
    airport_code: "OTS",
    airport_name: "Ancortes Arpt",
    airport_city_code: "OTS",
    airport_city_name: "Ancortes",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.498889",
    airport_lon: "-122.6625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2658,
    airport_code: "OTZ",
    airport_name: "Ralph Wien Memorial",
    airport_city_code: "OTZ",
    airport_city_name: "Kotzebue",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "66.884678",
    airport_lon: "-162.59855",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2659,
    airport_code: "OUA",
    airport_name: "Ouagadougou Arpt",
    airport_city_code: "OUA",
    airport_city_name: "Ouagadougou",
    airport_country_name: "BURKINA FASO",
    airport_country_code: "BF",
    airport_timezone: "0",
    airport_lat: "12.353194",
    airport_lon: "-1.512417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2660,
    airport_code: "OUD",
    airport_name: "Les Angades Arpt",
    airport_city_code: "OUD",
    airport_city_name: "Oujda",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "1",
    airport_lat: "34.78715",
    airport_lon: "-1.923986",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2661,
    airport_code: "OUH",
    airport_name: "Oudtshoorn Arpt",
    airport_city_code: "OUH",
    airport_city_name: "Oudtshoorn",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "-33.6",
    airport_lon: "22.1833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2662,
    airport_code: "OUL",
    airport_name: "Oulu Airport",
    airport_city_code: "OUL",
    airport_city_name: "Oulu",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "64.930061",
    airport_lon: "25.354564",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2663,
    airport_code: "OUZ",
    airport_name: "Zouerate Arpt",
    airport_city_code: "OUZ",
    airport_city_name: "Zouerate",
    airport_country_name: "MAURITANIA",
    airport_country_code: "MR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2664,
    airport_code: "OVB",
    airport_name: "Tolmachevo Arpt",
    airport_city_code: "OVB",
    airport_city_name: "Novosibirsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "7",
    airport_lat: "55.012622",
    airport_lon: "82.650656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2665,
    airport_code: "OVD",
    airport_name: "Asturias Airport",
    airport_city_code: "OVD",
    airport_city_name: "Asturias",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "43.563567",
    airport_lon: "-6.034622",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2666,
    airport_code: "OVS",
    airport_name: "Sovetsky Airport",
    airport_city_code: "OVS",
    airport_city_name: "Boscobel",
    airport_country_name: "UNITED STATES",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "61.32",
    airport_lon: "63.6044",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2667,
    airport_code: "OWA",
    airport_name: "Owatonna Arpt",
    airport_city_code: "OWA",
    airport_city_name: "Owatonna",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2668,
    airport_code: "OWB",
    airport_name: "Daviess County Arpt",
    airport_city_code: "OWB",
    airport_city_name: "Owensboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.7401",
    airport_lon: "-87.1668",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2669,
    airport_code: "OWD",
    airport_name: "Norwood Ma Arpt",
    airport_city_code: "OWD",
    airport_city_name: "Norwood",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "42.190528",
    airport_lon: "-71.172944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2670,
    airport_code: "OXB",
    airport_name: "Airport Osvaldo Viera",
    airport_city_code: "OXB",
    airport_city_name: "Bissau",
    airport_country_name: "GUINEA-BISSAU",
    airport_country_code: "GW",
    airport_timezone: "0",
    airport_lat: "11.89485",
    airport_lon: "-15.653681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2671,
    airport_code: "OXC",
    airport_name: "Waterbury Oxford Arpt",
    airport_city_code: "OXC",
    airport_city_name: "Oxford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "41.478556",
    airport_lon: "-73.13525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2672,
    airport_code: "OXF",
    airport_name: "Kidlington Arpt",
    airport_city_code: "OXF",
    airport_city_name: "Oxford",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.836944",
    airport_lon: "-1.32",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2673,
    airport_code: "BZZ",
    airport_name: "Brize Norton Raf Station",
    airport_city_code: "OXF",
    airport_city_name: "Oxford",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.749964",
    airport_lon: "-1.583617",
    airport_num_airports: 2,
  },
  {
    airport_id: 2674,
    airport_code: "OXR",
    airport_name: "Oxnard Ventura Arpt",
    airport_city_code: "OXR",
    airport_city_name: "Oxnard",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.200833",
    airport_lon: "-119.207222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2675,
    airport_code: "OYO",
    airport_name: "Tres Arroyos Arpt",
    airport_city_code: "OYO",
    airport_city_name: "Tres Arroyos",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2676,
    airport_code: "OZC",
    airport_name: "Labo Arpt",
    airport_city_code: "OZC",
    airport_city_name: "Labo Arpt",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "8.178508",
    airport_lon: "123.841731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2677,
    airport_code: "OZH",
    airport_name: "Zaporozhye Arpt",
    airport_city_code: "OZH",
    airport_city_name: "Zaporozhe",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "47.867",
    airport_lon: "35.3157",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2678,
    airport_code: "OZZ",
    airport_name: "Ourzazate",
    airport_city_code: "OZZ",
    airport_city_name: "Ourzazate",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "30.939053",
    airport_lon: "-6.909431",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2679,
    airport_code: "PAD",
    airport_name: "Paderborn Airport",
    airport_city_code: "PAD",
    airport_city_name: "Paderborn",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "51.614089",
    airport_lon: "8.616317",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2680,
    airport_code: "PAE",
    airport_name: "Snohomish Cty Arpt",
    airport_city_code: "PAE",
    airport_city_name: "Everett",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.906342",
    airport_lon: "-122.281564",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2681,
    airport_code: "PAH",
    airport_name: "Barkley Regional",
    airport_city_code: "PAH",
    airport_city_name: "Paducah",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.060288",
    airport_lon: "-88.772958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2682,
    airport_code: "PAO",
    airport_name: "Palo Alto Arpt",
    airport_city_code: "PAO",
    airport_city_name: "Palo Alto",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2683,
    airport_code: "PAP",
    airport_name: "Mais Gate Arpt",
    airport_city_code: "PAP",
    airport_city_name: "Port Au Prince",
    airport_country_name: "HAITI",
    airport_country_code: "HT",
    airport_timezone: "-5",
    airport_lat: "18.58005",
    airport_lon: "-72.292542",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2684,
    airport_code: "ORY",
    airport_name: "Orly Arpt",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.725278",
    airport_lon: "2.359444",
    airport_num_airports: 7,
  },
  {
    airport_id: 2685,
    airport_code: "JDP",
    airport_name: "Issy Les Moulineaux Arpt",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 7,
  },
  {
    airport_id: 2686,
    airport_code: "LBG",
    airport_name: "Le Bourget Arpt",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.969444",
    airport_lon: "2.441389",
    airport_num_airports: 7,
  },
  {
    airport_id: 2687,
    airport_code: "CDG",
    airport_name: "Charles De Gaulle Intl Arpt",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.012779",
    airport_lon: "2.55",
    airport_num_airports: 7,
  },
  {
    airport_id: 2688,
    airport_code: "BVA",
    airport_name: "Beauvais Tille Arpt",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.454444",
    airport_lon: "2.112778",
    airport_num_airports: 7,
  },
  {
    airport_id: 2689,
    airport_code: "XPG",
    airport_name: "Gare du Nord Railway Station",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.880931",
    airport_lon: "2.355323",
    airport_num_airports: 7,
  },
  {
    airport_id: 2690,
    airport_code: "XCR",
    airport_name: "Vatry Arpt",
    airport_city_code: "PAR",
    airport_city_name: "Paris",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "48.7733",
    airport_lon: "4.2061",
    airport_num_airports: 7,
  },
  {
    airport_id: 2691,
    airport_code: "PAS",
    airport_name: "Paros Community Arpt",
    airport_city_code: "PAS",
    airport_city_name: "Paros",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.010278",
    airport_lon: "25.127778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2692,
    airport_code: "PAT",
    airport_name: "Jai Prakash Narayan Arpt",
    airport_city_code: "PAT",
    airport_city_name: "Patna",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "25.591317",
    airport_lon: "85.087992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2693,
    airport_code: "PAZ",
    airport_name: "Tajin Arpt",
    airport_city_code: "PAZ",
    airport_city_name: "Poza Rica",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.602671",
    airport_lon: "-97.460839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2694,
    airport_code: "PBC",
    airport_name: "Huejostingo Arpt",
    airport_city_code: "PBC",
    airport_city_name: "Puebla",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.158144",
    airport_lon: "-98.371447",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2695,
    airport_code: "PBD",
    airport_name: "Porbandar Arpt",
    airport_city_code: "PBD",
    airport_city_name: "Porbandar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "21.648675",
    airport_lon: "69.657219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2696,
    airport_code: "PBF",
    airport_name: "Pine Bluff Arpt",
    airport_city_code: "PBF",
    airport_city_name: "Pine Bluff",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.173142",
    airport_lon: "-91.935597",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2697,
    airport_code: "PBG",
    airport_name: "Plattsburgh Intl Arpt",
    airport_city_code: "PBG",
    airport_city_name: "Plattsburgh",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.650944",
    airport_lon: "-73.468139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2698,
    airport_code: "PBH",
    airport_name: "Paro Arpt",
    airport_city_code: "PBH",
    airport_city_name: "Paro",
    airport_country_name: "BHUTAN",
    airport_country_code: "BT",
    airport_timezone: "6",
    airport_lat: "27.403192",
    airport_lon: "89.424606",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2699,
    airport_code: "LNA",
    airport_name: "Palm Beach County Arpt",
    airport_city_code: "PBI",
    airport_city_name: "West Palm Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.593",
    airport_lon: "-80.085056",
    airport_num_airports: 2,
  },
  {
    airport_id: 2700,
    airport_code: "PBI",
    airport_name: "Palm Beach Intl Arpt",
    airport_city_code: "PBI",
    airport_city_name: "West Palm Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.683161",
    airport_lon: "-80.095589",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2701,
    airport_code: "PBM",
    airport_name: "Zanderij Intl Arpt",
    airport_city_code: "PBM",
    airport_city_name: "Paramaribo",
    airport_country_name: "SURINAME",
    airport_country_code: "SR",
    airport_timezone: "-3",
    airport_lat: "5.452831",
    airport_lon: "-55.187783",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2702,
    airport_code: "ORG",
    airport_name: "Zorg En Hoop Arpt",
    airport_city_code: "PBM",
    airport_city_name: "Paramaribo",
    airport_country_name: "SURINAME",
    airport_country_code: "SR",
    airport_timezone: "-3",
    airport_lat: "5.81108",
    airport_lon: "-55.1907",
    airport_num_airports: 2,
  },
  {
    airport_id: 2703,
    airport_code: "PBO",
    airport_name: "Paraburdoo Arpt",
    airport_city_code: "PBO",
    airport_city_name: "Paraburdoo",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-23.1711",
    airport_lon: "117.745",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2704,
    airport_code: "PBR",
    airport_name: "Puerto Barrios Arpt",
    airport_city_code: "PBR",
    airport_city_name: "Puerto Barrios",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-6",
    airport_lat: "15.730878",
    airport_lon: "-88.583767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2705,
    airport_code: "PBZ",
    airport_name: "Plettenberg Bay Arpt",
    airport_city_code: "PBZ",
    airport_city_name: "Plettenberg Bay",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2706,
    airport_code: "PCD",
    airport_name: "Prairie Du Chien Municipal Arpt",
    airport_city_code: "PCD",
    airport_city_name: "Prairie Du Chien",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2707,
    airport_code: "PCJ",
    airport_name: "Puerto La Victoria Arpt",
    airport_city_code: "PCJ",
    airport_city_name: "Puerto La Victoria",
    airport_country_name: "PARAGUAY",
    airport_country_code: "PY",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2708,
    airport_code: "PCL",
    airport_name: "Capitan Rolden Arpt",
    airport_city_code: "PCL",
    airport_city_name: "Pucallpa",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-8.377939",
    airport_lon: "-74.574297",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2709,
    airport_code: "PCM",
    airport_name: "Playa Del Carmen Arpt",
    airport_city_code: "PCM",
    airport_city_name: "Playa Del Carmen",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2710,
    airport_code: "PCN",
    airport_name: "Koromiko",
    airport_city_code: "PCN",
    airport_city_name: "Picton",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-41.348333",
    airport_lon: "173.955278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2711,
    airport_code: "PCT",
    airport_name: "Princeton Arpt",
    airport_city_code: "PCT",
    airport_city_name: "Princeton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2712,
    airport_code: "PDG",
    airport_name: "Tabing Arpt",
    airport_city_code: "PDG",
    airport_city_name: "Padang",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-0.874989",
    airport_lon: "100.351881",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2713,
    airport_code: "PDL",
    airport_name: "Nordela Arpt",
    airport_city_code: "PDL",
    airport_city_name: "Ponta Delgada",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "37.741184",
    airport_lon: "-25.69787",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2714,
    airport_code: "PDP",
    airport_name: "Cap Curbelo Arpt",
    airport_city_code: "PDP",
    airport_city_name: "Punta Del Este",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-3",
    airport_lat: "-34.855139",
    airport_lon: "-55.094278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2715,
    airport_code: "PDS",
    airport_name: "Piedras Negras Intl Arpt",
    airport_city_code: "PDS",
    airport_city_name: "Piedras Negras",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "28.627394",
    airport_lon: "-100.535211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2716,
    airport_code: "PDT",
    airport_name: "Pendleton Municipal",
    airport_city_code: "PDT",
    airport_city_name: "Pendleton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "45.695",
    airport_lon: "-118.841389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2717,
    airport_code: "PDU",
    airport_name: "Paysandu Arpt",
    airport_city_code: "PDU",
    airport_city_name: "Paysandu",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2718,
    airport_code: "PDV",
    airport_name: "Plovdiv Arpt",
    airport_city_code: "PDV",
    airport_city_name: "Plovdiv",
    airport_country_name: "BULGARIA",
    airport_country_code: "BG",
    airport_timezone: "2",
    airport_lat: "42.067806",
    airport_lon: "24.850833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2719,
    airport_code: "PDX",
    airport_name: "Portland Intl Arpt",
    airport_city_code: "PDX",
    airport_city_name: "Portland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "45.588722",
    airport_lon: "-122.5975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2720,
    airport_code: "PED",
    airport_name: "Pardubice Arpt",
    airport_city_code: "PED",
    airport_city_name: "Pardubice",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "1",
    airport_lat: "50.013419",
    airport_lon: "15.738647",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2721,
    airport_code: "PEE",
    airport_name: "Perm Arpt",
    airport_city_code: "PEE",
    airport_city_name: "Perm",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "57.914517",
    airport_lon: "56.021214",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2722,
    airport_code: "PEF",
    airport_name: "Peenemuende Arpt",
    airport_city_code: "PEF",
    airport_city_name: "Peenemuende",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2723,
    airport_code: "PEG",
    airport_name: "Sant Egidio Arpt",
    airport_city_code: "PEG",
    airport_city_name: "Perugia",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "43.095906",
    airport_lon: "12.513222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2724,
    airport_code: "PEI",
    airport_name: "Matecana Arpt",
    airport_city_code: "PEI",
    airport_city_name: "Pereira",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "4.812675",
    airport_lon: "-75.739519",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2725,
    airport_code: "PEJ",
    airport_name: "Peschiei Arpt",
    airport_city_code: "PEJ",
    airport_city_name: "Peschiei",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2726,
    airport_code: "PEM",
    airport_name: "Puerto Maldonado Arpt",
    airport_city_code: "PEM",
    airport_city_name: "Puerto Maldonado",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-12.613611",
    airport_lon: "-69.228611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2727,
    airport_code: "PEN",
    airport_name: "Penang Intl Arpt",
    airport_city_code: "PEN",
    airport_city_name: "Penang",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.297139",
    airport_lon: "100.276864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2728,
    airport_code: "PER",
    airport_name: "Perth Arpt",
    airport_city_code: "PER",
    airport_city_name: "Perth",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-31.940278",
    airport_lon: "115.966944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2729,
    airport_code: "PES",
    airport_name: "Petrozavodsk Arpt",
    airport_city_code: "PES",
    airport_city_name: "Petrozavodsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "61.8852",
    airport_lon: "34.1547",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2730,
    airport_code: "PET",
    airport_name: "Pelotas Federal Arpt",
    airport_city_code: "PET",
    airport_city_name: "Pelotas",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-31.718353",
    airport_lon: "-52.327689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2731,
    airport_code: "PEV",
    airport_name: "Pecs Arpt",
    airport_city_code: "PEV",
    airport_city_name: "Pecs",
    airport_country_name: "HUNGARY",
    airport_country_code: "HU",
    airport_timezone: "1",
    airport_lat: "45.990928",
    airport_lon: "18.240983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2732,
    airport_code: "PEW",
    airport_name: "Peshawar Arpt",
    airport_city_code: "PEW",
    airport_city_name: "Peshawar",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "33.993911",
    airport_lon: "71.514581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2733,
    airport_code: "PEX",
    airport_name: "Pechora Airport",
    airport_city_code: "PEX",
    airport_city_name: "Pechora Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "65.070387",
    airport_lon: "57.082045",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2734,
    airport_code: "PEZ",
    airport_name: "Penza Arpt",
    airport_city_code: "PEZ",
    airport_city_name: "Penza",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "53.1106",
    airport_lon: "45.0211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2735,
    airport_code: "PFB",
    airport_name: "Passo Fundo Arpt",
    airport_city_code: "PFB",
    airport_city_name: "Passo Fundo",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-28.243989",
    airport_lon: "-52.326558",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2736,
    airport_code: "ECP",
    airport_name: "Northwest Florida Beaches Intl Arpt",
    airport_city_code: "PFN",
    airport_city_name: "Panama City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.3417",
    airport_lon: "-85.7973",
    airport_num_airports: 2,
  },
  {
    airport_id: 2737,
    airport_code: "PFN",
    airport_name: "Bay County Arpt",
    airport_city_code: "PFN",
    airport_city_name: "Panama City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.212083",
    airport_lon: "-85.682806",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2738,
    airport_code: "PFO",
    airport_name: "Paphos Intl Airport",
    airport_city_code: "PFO",
    airport_city_name: "Paphos",
    airport_country_name: "CYPRUS",
    airport_country_code: "CY",
    airport_timezone: "2",
    airport_lat: "34.718039",
    airport_lon: "32.485731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2739,
    airport_code: "PFQ",
    airport_name: "Parsabad Arpt",
    airport_city_code: "PFQ",
    airport_city_name: "Parsabad Arpt",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "39.603606",
    airport_lon: "47.8815",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2740,
    airport_code: "PGA",
    airport_name: "Page Airport",
    airport_city_code: "PGA",
    airport_city_name: "Page",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "36.9261",
    airport_lon: "-111.4483",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2741,
    airport_code: "PGD",
    airport_name: "Charlotte County",
    airport_city_code: "PGD",
    airport_city_name: "Punta Gorda",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "26.919722",
    airport_lon: "-81.990556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2742,
    airport_code: "PGF",
    airport_name: "Llabanere Arpt",
    airport_city_code: "PGF",
    airport_city_name: "Perpignan",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "42.740442",
    airport_lon: "2.870667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2743,
    airport_code: "PGH",
    airport_name: "Pantnagar Arpt",
    airport_city_code: "PGH",
    airport_city_name: "Pantnagar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "29.033408",
    airport_lon: "79.473744",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2744,
    airport_code: "PGL",
    airport_name: "Jackson Cnty",
    airport_city_code: "PGL",
    airport_city_name: "Pascagoula",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2745,
    airport_code: "PGO",
    airport_name: "Stevens Field Arpt",
    airport_city_code: "PGO",
    airport_city_name: "Pagosa Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2746,
    airport_code: "PGU",
    airport_name: "Ala'Marvdasht Arpt",
    airport_city_code: "PGU",
    airport_city_name: "Ala'Marvdasht Arpt",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "27.379444",
    airport_lon: "52.7375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2747,
    airport_code: "PGV",
    airport_name: "Pitt Greenville Arpt",
    airport_city_code: "PGV",
    airport_city_name: "Greenville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.635278",
    airport_lon: "-77.385278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2748,
    airport_code: "PGX",
    airport_name: "Perigueux Arpt",
    airport_city_code: "PGX",
    airport_city_name: "Perigueux",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.198055",
    airport_lon: "0.815556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2749,
    airport_code: "PHB",
    airport_name: "Santos Dumont Airport",
    airport_city_code: "PHB",
    airport_city_name: "Parnaiba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2750,
    airport_code: "PHC",
    airport_name: "Port Harcourt Arpt",
    airport_city_code: "PHC",
    airport_city_name: "Port Harcourt",
    airport_country_name: "NIGERIA",
    airport_country_code: "NG",
    airport_timezone: "1",
    airport_lat: "5.015494",
    airport_lon: "6.949594",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2751,
    airport_code: "PHG",
    airport_name: "Port Harcourt City Arpt",
    airport_city_code: "PHC",
    airport_city_name: "Port Harcourt",
    airport_country_name: "NIGERIA",
    airport_country_code: "NG",
    airport_timezone: "-100",
    airport_lat: "4.85",
    airport_lon: "7.0167",
    airport_num_airports: 2,
  },
  {
    airport_id: 2752,
    airport_code: "PHE",
    airport_name: "Port Hedland Arpt",
    airport_city_code: "PHE",
    airport_city_name: "Port Hedland",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-20.377778",
    airport_lon: "118.626389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2753,
    airport_code: "PHF",
    airport_name: "Williamsburg Intl Arpt",
    airport_city_code: "PHF",
    airport_city_name: "Newport News",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.131894",
    airport_lon: "-76.492989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2754,
    airport_code: "PHH",
    airport_name: "Phan Thiet Arpt",
    airport_city_code: "PHH",
    airport_city_name: "Phan Thiet",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2755,
    airport_code: "PNE",
    airport_name: "N Philadelphia",
    airport_city_code: "PHL",
    airport_city_name: "Philadelphia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.081944",
    airport_lon: "-75.010586",
    airport_num_airports: 2,
  },
  {
    airport_id: 2756,
    airport_code: "PHL",
    airport_name: "Philadelphia Intl Arpt",
    airport_city_code: "PHL",
    airport_city_name: "Philadelphia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.871944",
    airport_lon: "-75.241139",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2757,
    airport_code: "PHN",
    airport_name: "St Clair County Intl Arpt",
    airport_city_code: "PHN",
    airport_city_name: "Port Huron",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.910957",
    airport_lon: "-82.528862",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2758,
    airport_code: "PHS",
    airport_name: "Phitsanulok Arpt",
    airport_city_code: "PHS",
    airport_city_name: "Phitsanulok",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "16.782939",
    airport_lon: "100.279122",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2759,
    airport_code: "PHT",
    airport_name: "Henry County Arpt",
    airport_city_code: "PHT",
    airport_city_name: "Paris",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.7002",
    airport_lon: "-87.6696",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2760,
    airport_code: "PHW",
    airport_name: "Phalaborwa Arpt",
    airport_city_code: "PHW",
    airport_city_name: "Phalaborwa",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-23.937166",
    airport_lon: "31.15539",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2761,
    airport_code: "PHX",
    airport_name: "Sky Harbor Intl Arpt",
    airport_city_code: "PHX",
    airport_city_name: "Phoenix",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.434278",
    airport_lon: "-112.011583",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 2762,
    airport_code: "AZA",
    airport_name: "Williams Gateway Arpt",
    airport_city_code: "PHX",
    airport_city_name: "Phoenix",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.307833",
    airport_lon: "-111.655",
    airport_num_airports: 3,
  },
  {
    airport_id: 2763,
    airport_code: "DVT",
    airport_name: "Phoenix Deer Valley Airport",
    airport_city_code: "PHX",
    airport_city_name: "Phoenix",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.4117",
    airport_lon: "112.457",
    airport_num_airports: 3,
  },
  {
    airport_id: 2764,
    airport_code: "PIA",
    airport_name: "Greater Peoria Arpt",
    airport_city_code: "PIA",
    airport_city_name: "Peoria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.664203",
    airport_lon: "-89.693258",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2765,
    airport_code: "PIH",
    airport_name: "Pocatello Municipal Arpt",
    airport_city_code: "PIH",
    airport_city_name: "Pocatello",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "42.9098",
    airport_lon: "-112.596",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2766,
    airport_code: "PIR",
    airport_name: "Pierre Municipal",
    airport_city_code: "PIR",
    airport_city_name: "Pierre",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.3827",
    airport_lon: "-100.286",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2767,
    airport_code: "XOP",
    airport_name: "Poitiers Rail Station",
    airport_city_code: "PIS",
    airport_city_name: "Poitiers",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "46.58",
    airport_lon: "0.3",
    airport_num_airports: 2,
  },
  {
    airport_id: 2768,
    airport_code: "PIS",
    airport_name: "Biard Airport",
    airport_city_code: "PIS",
    airport_city_name: "Poitiers",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.587745",
    airport_lon: "0.306666",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2769,
    airport_code: "AGC",
    airport_name: "Allegheny Cty Arpt",
    airport_city_code: "PIT",
    airport_city_name: "Pittsburgh",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.3544",
    airport_lon: "-79.9302",
    airport_num_airports: 2,
  },
  {
    airport_id: 2770,
    airport_code: "PIT",
    airport_name: "Pittsburgh Intl Arpt",
    airport_city_code: "PIT",
    airport_city_name: "Pittsburgh",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.491467",
    airport_lon: "-80.232872",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2771,
    airport_code: "PIU",
    airport_name: "Piura Arpt",
    airport_city_code: "PIU",
    airport_city_name: "Piura",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-5.20575",
    airport_lon: "-80.616444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2772,
    airport_code: "PIW",
    airport_name: "Pikwitonei Rail Station",
    airport_city_code: "PIW",
    airport_city_name: "Pikwitonei",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "55.5889",
    airport_lon: "-97.1642",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2773,
    airport_code: "PIX",
    airport_name: "Pico Arpt",
    airport_city_code: "PIX",
    airport_city_name: "Pico Island",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "38.554333",
    airport_lon: "-28.441333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2774,
    airport_code: "PIZ",
    airport_name: "Dew Station Arpt",
    airport_city_code: "PIZ",
    airport_city_name: "Point Lay",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "69.732875",
    airport_lon: "-163.005342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2775,
    airport_code: "PJA",
    airport_name: "Pajala Arpt",
    airport_city_code: "PJA",
    airport_city_name: "Pajala",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "67.2456",
    airport_lon: "23.0689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2776,
    airport_code: "PJB",
    airport_name: "Payson Arpt",
    airport_city_code: "PJB",
    airport_city_name: "Payson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2777,
    airport_code: "PKB",
    airport_name: "Wood County",
    airport_city_code: "PKB",
    airport_city_name: "Parkersburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.345104",
    airport_lon: "-81.439203",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2778,
    airport_code: "PKC",
    airport_name: "Petropavlovsk Kamchatskiy Arpt",
    airport_city_code: "PKC",
    airport_city_name: "Petropavlovsk Kamchatskiy",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "12",
    airport_lat: "53.167889",
    airport_lon: "158.453669",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2779,
    airport_code: "PKD",
    airport_name: "Park Rapids Municipal Arpt",
    airport_city_code: "PKD",
    airport_city_name: "Park Rapids",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "46.9006",
    airport_lon: "-95.0731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2780,
    airport_code: "PKE",
    airport_name: "Parkes Arpt",
    airport_city_code: "PKE",
    airport_city_name: "Parkes",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-33.1314",
    airport_lon: "148.239",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2781,
    airport_code: "PKG",
    airport_name: "Pangkor Arpt",
    airport_city_code: "PKG",
    airport_city_name: "Pangkor",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.24472",
    airport_lon: "100.553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2782,
    airport_code: "PKH",
    airport_name: "Alexion Arpt",
    airport_city_code: "PKH",
    airport_city_name: "Portoheli",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.298792",
    airport_lon: "23.148986",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2783,
    airport_code: "PKJ",
    airport_name: "Playa Grand Arpt",
    airport_city_code: "PKJ",
    airport_city_name: "Playa Grande",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2784,
    airport_code: "PKN",
    airport_name: "Pangkalanbuun Arpt",
    airport_city_code: "PKN",
    airport_city_name: "Pangkalanbun",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-2.705197",
    airport_lon: "111.673208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2785,
    airport_code: "PKR",
    airport_name: "Pokhara Arpt",
    airport_city_code: "PKR",
    airport_city_name: "Pokhara",
    airport_country_name: "NEPAL",
    airport_country_code: "NP",
    airport_timezone: "5",
    airport_lat: "28.200881",
    airport_lon: "83.982056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2786,
    airport_code: "PKU",
    airport_name: "Simpang Tiga Arpt",
    airport_city_code: "PKU",
    airport_city_name: "Pekanbaru",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "0.460786",
    airport_lon: "101.444539",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2787,
    airport_code: "PKV",
    airport_name: "Pskov Arpt",
    airport_city_code: "PKV",
    airport_city_name: "Pskov",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "57.783917",
    airport_lon: "28.395614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2788,
    airport_code: "PKW",
    airport_name: "Selebi Phikwe Arpt",
    airport_city_code: "PKW",
    airport_city_name: "Selebi Phikwe",
    airport_country_name: "BOTSWANA",
    airport_country_code: "BW",
    airport_timezone: "2",
    airport_lat: "-22.05835",
    airport_lon: "27.828767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2789,
    airport_code: "PKZ",
    airport_name: "Pakse Arpt",
    airport_city_code: "PKZ",
    airport_city_name: "Pakse",
    airport_country_name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    airport_country_code: "LA",
    airport_timezone: "7",
    airport_lat: "15.132053",
    airport_lon: "105.781417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2790,
    airport_code: "PLD",
    airport_name: "Playa Samara Arpt",
    airport_city_code: "PLD",
    airport_city_name: "Playa Samara",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "10.25",
    airport_lon: "-85.417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2791,
    airport_code: "PLH",
    airport_name: "Roborough Arpt",
    airport_city_code: "PLH",
    airport_city_name: "Plymouth",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.422778",
    airport_lon: "-4.105833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2792,
    airport_code: "PLJ",
    airport_name: "Placencia Arpt",
    airport_city_code: "PLJ",
    airport_city_name: "Placencia",
    airport_country_name: "BELIZE",
    airport_country_code: "BZ",
    airport_timezone: "-6",
    airport_lat: "16.536944",
    airport_lon: "-88.361667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2793,
    airport_code: "PLK",
    airport_name: "M Graham Clark Arpt",
    airport_city_code: "PLK",
    airport_city_name: "Point Lookout",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2794,
    airport_code: "PLM",
    airport_name: "Mahmud Badaruddin Li Arpt",
    airport_city_code: "PLM",
    airport_city_name: "Palembang",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-2.89825",
    airport_lon: "104.699903",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2795,
    airport_code: "PLN",
    airport_name: "Emmet Cty Arpt",
    airport_city_code: "PLN",
    airport_city_name: "Pellston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "45.5709",
    airport_lon: "-84.7967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2796,
    airport_code: "PLO",
    airport_name: "Port Lincoln Arpt",
    airport_city_code: "PLO",
    airport_city_name: "Port Lincoln",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-34.6053",
    airport_lon: "135.88",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2797,
    airport_code: "PLP",
    airport_name: "LA PALMA",
    airport_city_code: "PLP",
    airport_city_name: "LA PALMA",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "8.40667",
    airport_lon: "-78.1417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2798,
    airport_code: "PLQ",
    airport_name: "Palanga Intl Arpt",
    airport_city_code: "PLQ",
    airport_city_name: "Palanga",
    airport_country_name: "LITHUANIA",
    airport_country_code: "LT",
    airport_timezone: "2",
    airport_lat: "55.973228",
    airport_lon: "21.093856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2799,
    airport_code: "PLS",
    airport_name: "Providenciales Intl",
    airport_city_code: "PLS",
    airport_city_name: "Providenciales",
    airport_country_name: "TURKS AND CAICOS ISLANDS",
    airport_country_code: "TC",
    airport_timezone: "-5",
    airport_lat: "21.773625",
    airport_lon: "-72.265886",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2800,
    airport_code: "PLV",
    airport_name: "Poltava Arpt",
    airport_city_code: "PLV",
    airport_city_name: "Poltava",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "3",
    airport_lat: "49.34261",
    airport_lon: "34.23551",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2801,
    airport_code: "PLW",
    airport_name: "Mutiara Arpt",
    airport_city_code: "PLW",
    airport_city_name: "Palu",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "-0.918542",
    airport_lon: "119.909642",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2802,
    airport_code: "PLX",
    airport_name: "SEMIPALATINSK",
    airport_city_code: "PLX",
    airport_city_name: "SEMIPALATINSK",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "50.3513",
    airport_lon: "80.2344",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2803,
    airport_code: "PLY",
    airport_name: "Plymouth Municipal Arpt",
    airport_city_code: "PLY",
    airport_city_name: "Plymouth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2804,
    airport_code: "PLZ",
    airport_name: "Port Elizabeth Airport",
    airport_city_code: "PLZ",
    airport_city_name: "Port Elizabeth",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-33.984919",
    airport_lon: "25.617275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2805,
    airport_code: "PMC",
    airport_name: "Tepual Arpt",
    airport_city_code: "PMC",
    airport_city_name: "Puerto Montt",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-41.438886",
    airport_lon: "-73.093953",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2806,
    airport_code: "PMD",
    airport_name: "La Palmdale Regional Arpt",
    airport_city_code: "PMD",
    airport_city_name: "Palmdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.629391",
    airport_lon: "-118.08456",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2807,
    airport_code: "PME",
    airport_name: "Portsmouth Arpt",
    airport_city_code: "PME",
    airport_city_name: "Portsmouth",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.8",
    airport_lon: "-1.083333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2808,
    airport_code: "PMF",
    airport_name: "Parma Arpt",
    airport_city_code: "PMF",
    airport_city_name: "Parma",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.824483",
    airport_lon: "10.296367",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2809,
    airport_code: "PMG",
    airport_name: "International",
    airport_city_code: "PMG",
    airport_city_name: "Ponta Pora",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-22.549639",
    airport_lon: "-55.702614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2810,
    airport_code: "PMI",
    airport_name: "Palma Mallorca Arpt",
    airport_city_code: "PMI",
    airport_city_name: "Palma Mallorca",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "39.55361",
    airport_lon: "2.727778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2811,
    airport_code: "PMK",
    airport_name: "Palm Island Arpt",
    airport_city_code: "PMK",
    airport_city_name: "Palm Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-18.7553",
    airport_lon: "146.581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2812,
    airport_code: "PMO",
    airport_name: "Punta Raisi Arpt",
    airport_city_code: "PMO",
    airport_city_name: "Palermo",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "38.175958",
    airport_lon: "13.091019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2813,
    airport_code: "PMR",
    airport_name: "Palmerston North Arpt",
    airport_city_code: "PMR",
    airport_city_name: "Palmerston",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-40.320556",
    airport_lon: "175.616944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2814,
    airport_code: "PMS",
    airport_name: "Palmyra Arpt",
    airport_city_code: "PMS",
    airport_city_name: "Palmyra",
    airport_country_name: "SYRIA",
    airport_country_code: "SY",
    airport_timezone: "2",
    airport_lat: "34.557361",
    airport_lon: "38.316889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2815,
    airport_code: "PMV",
    airport_name: "Delcaribe Gen S Marino Arpt",
    airport_city_code: "PMV",
    airport_city_name: "Porlamar",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.912926",
    airport_lon: "-63.967581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2816,
    airport_code: "PMW",
    airport_name: "Palmas Arpt",
    airport_city_code: "PMW",
    airport_city_name: "Palmas",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-10.241667",
    airport_lon: "-48.35278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2817,
    airport_code: "PMY",
    airport_name: "El Tehuelche Arpt",
    airport_city_code: "PMY",
    airport_city_name: "Puerto Madryn",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-42.759161",
    airport_lon: "-65.102725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2818,
    airport_code: "PMZ",
    airport_name: "Palmar Sur",
    airport_city_code: "PMZ",
    airport_city_name: "Palmar",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "8.951025",
    airport_lon: "-83.468583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2819,
    airport_code: "PNA",
    airport_name: "Pamplona Noain Arpt",
    airport_city_code: "PNA",
    airport_city_name: "Pamplona",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "42.770039",
    airport_lon: "-1.646331",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2820,
    airport_code: "PNC",
    airport_name: "Ponca City Municipal Arpt",
    airport_city_code: "PNC",
    airport_city_name: "Ponca City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.731958",
    airport_lon: "-97.099781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2821,
    airport_code: "PNG",
    airport_name: "Paranagua Municipal",
    airport_city_code: "PNG",
    airport_city_name: "Paranagua",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-25.54",
    airport_lon: "-48.5306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2822,
    airport_code: "PNH",
    airport_name: "Pochentong Arpt",
    airport_city_code: "PNH",
    airport_city_name: "Phnom Penh",
    airport_country_name: "CAMBODIA",
    airport_country_code: "KH",
    airport_timezone: "7",
    airport_lat: "11.546556",
    airport_lon: "104.844139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2823,
    airport_code: "PNI",
    airport_name: "Pohnpei Arpt",
    airport_city_code: "PNI",
    airport_city_name: "Pohnpei",
    airport_country_name: "MICRONESIA, FEDERATED STATES OF",
    airport_country_code: "FM",
    airport_timezone: "11",
    airport_lat: "6.9851",
    airport_lon: "158.208989",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2824,
    airport_code: "PNJ",
    airport_name: "Sha He Kou Arpt",
    airport_city_code: "PNJ",
    airport_city_name: "Penglai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2825,
    airport_code: "PNK",
    airport_name: "Supadio International Arpt",
    airport_city_code: "PNK",
    airport_city_name: "Pontianak",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-0.150711",
    airport_lon: "109.403892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2826,
    airport_code: "PNL",
    airport_name: "Pantelleria Arpt",
    airport_city_code: "PNL",
    airport_city_name: "Pantelleria",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "36.816519",
    airport_lon: "11.968864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2827,
    airport_code: "PNO",
    airport_name: "Pinotepa Nacional Arpt",
    airport_city_code: "PNO",
    airport_city_name: "Pinotepa Nacional",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2828,
    airport_code: "PNP",
    airport_name: "Girua Arpt",
    airport_city_code: "PNP",
    airport_city_name: "Popondetta",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-8.80454",
    airport_lon: "148.309",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2829,
    airport_code: "PNQ",
    airport_name: "Lohegaon Arpt",
    airport_city_code: "PNQ",
    airport_city_name: "Pune",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "18.582111",
    airport_lon: "73.919697",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2830,
    airport_code: "PNR",
    airport_name: "Pointe Noire Arpt",
    airport_city_code: "PNR",
    airport_city_name: "Pointe Noire",
    airport_country_name: "CONGO",
    airport_country_code: "CG",
    airport_timezone: "1",
    airport_lat: "-4.816028",
    airport_lon: "11.886597",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2831,
    airport_code: "PNS",
    airport_name: "Pensacola Regional Municipal",
    airport_city_code: "PNS",
    airport_city_name: "Pensacola",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.473425",
    airport_lon: "-87.186611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2832,
    airport_code: "PNT",
    airport_name: "Teniente Julio Gallardo Arpt",
    airport_city_code: "PNT",
    airport_city_name: "Puerto Natales",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-3",
    airport_lat: "-51.733333",
    airport_lon: "-72.516667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2833,
    airport_code: "PNX",
    airport_name: "Grayson County Arpt",
    airport_city_code: "PNX",
    airport_city_name: "Sherman",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2834,
    airport_code: "PNZ",
    airport_name: "Petrolina Internacional Arpt",
    airport_city_code: "PNZ",
    airport_city_name: "Petrolina",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-9.362411",
    airport_lon: "-40.569097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2835,
    airport_code: "POA",
    airport_name: "Porto Alegre Airport",
    airport_city_code: "POA",
    airport_city_name: "Porto Alegre",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-29.994428",
    airport_lon: "-51.171428",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2836,
    airport_code: "POC",
    airport_name: "Brackett Field",
    airport_city_code: "POC",
    airport_city_name: "La Verne",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2837,
    airport_code: "POE",
    airport_name: "Fort Polk Arpt",
    airport_city_code: "POE",
    airport_city_name: "Fort Polk",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.044833",
    airport_lon: "-93.191667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2838,
    airport_code: "POF",
    airport_name: "Earl Fields Memorial",
    airport_city_code: "POF",
    airport_city_name: "Poplar Bluff",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "36.7739",
    airport_lon: "-90.3248",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2839,
    airport_code: "POG",
    airport_name: "Port Gentil Arpt",
    airport_city_code: "POG",
    airport_city_name: "Port Gentil",
    airport_country_name: "GABON",
    airport_country_code: "GA",
    airport_timezone: "1",
    airport_lat: "-0.711739",
    airport_lon: "8.754383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2840,
    airport_code: "POL",
    airport_name: "Pemba Arpt",
    airport_city_code: "POL",
    airport_city_name: "Pemba",
    airport_country_name: "MOZAMBIQUE",
    airport_country_code: "MZ",
    airport_timezone: "2",
    airport_lat: "-12.986753",
    airport_lon: "40.522492",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2841,
    airport_code: "POM",
    airport_name: "Jackson Field",
    airport_city_code: "POM",
    airport_city_name: "Port Moresby",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-9.443383",
    airport_lon: "147.22005",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2842,
    airport_code: "POP",
    airport_name: "La Union Arpt",
    airport_city_code: "POP",
    airport_city_name: "Puerto Plata",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "19.7579",
    airport_lon: "-70.570033",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2843,
    airport_code: "POR",
    airport_name: "Pori Airport",
    airport_city_code: "POR",
    airport_city_name: "Pori",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "61.461686",
    airport_lon: "21.799983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2844,
    airport_code: "POS",
    airport_name: "Piarco Arpt",
    airport_city_code: "POS",
    airport_city_name: "Port Of Spain",
    airport_country_name: "TRINIDAD AND TOBAGO",
    airport_country_code: "TT",
    airport_timezone: "-4",
    airport_lat: "10.595369",
    airport_lon: "-61.337242",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2845,
    airport_code: "POU",
    airport_name: "Dutchess County",
    airport_city_code: "POU",
    airport_city_name: "Poughkeepsie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.6266",
    airport_lon: "-73.8842",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2846,
    airport_code: "POW",
    airport_name: "Portoroz Airport",
    airport_city_code: "POW",
    airport_city_name: "Portoroz",
    airport_country_name: "SLOVENIA",
    airport_country_code: "SI",
    airport_timezone: "1",
    airport_lat: "45.473353",
    airport_lon: "13.614978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2847,
    airport_code: "POX",
    airport_name: "Cormeille En Vexin",
    airport_city_code: "POX",
    airport_city_name: "Pontoise",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.096647",
    airport_lon: "2.040833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2848,
    airport_code: "POZ",
    airport_name: "Lawica Arpt",
    airport_city_code: "POZ",
    airport_city_name: "Poznan",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "52.421031",
    airport_lon: "16.826325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2849,
    airport_code: "PPB",
    airport_name: "A De Barros",
    airport_city_code: "PPB",
    airport_city_name: "Presidente Prudente",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.175056",
    airport_lon: "-51.424639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2850,
    airport_code: "PPE",
    airport_name: "Puerto Penasco Municipal",
    airport_city_code: "PPE",
    airport_city_name: "Puerto Penasco",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "31.351878",
    airport_lon: "-113.525728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2851,
    airport_code: "PPG",
    airport_name: "Pago Pago Arpt",
    airport_city_code: "PPG",
    airport_city_name: "Pago Pago",
    airport_country_name: "AMERICAN SAMOA",
    airport_country_code: "AS",
    airport_timezone: "-11",
    airport_lat: "-14.331",
    airport_lon: "-170.7105",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2852,
    airport_code: "PPI",
    airport_name: "Port Porie Arpt",
    airport_city_code: "PPI",
    airport_city_name: "Port Pirie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-33.2389",
    airport_lon: "137.995",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2853,
    airport_code: "PPK",
    airport_name: "Petropavlovsk Arpt",
    airport_city_code: "PPK",
    airport_city_name: "Petropavlovsk",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "54.7747",
    airport_lon: "69.1839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2854,
    airport_code: "PPL",
    airport_name: "Phaplu Airport",
    airport_city_code: "PPL",
    airport_city_name: "Phaplu",
    airport_country_name: "NEPAL",
    airport_country_code: "NP",
    airport_timezone: "5",
    airport_lat: "27.517",
    airport_lon: "86.6",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2855,
    airport_code: "PPM",
    airport_name: "Pompano Beach Arpt",
    airport_city_code: "PPM",
    airport_city_name: "Pompano Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2856,
    airport_code: "PPN",
    airport_name: "Machangara Arpt",
    airport_city_code: "PPN",
    airport_city_name: "Popayan",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "2.4544",
    airport_lon: "-76.609319",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2857,
    airport_code: "PPP",
    airport_name: "Whitsunday Coast Arpt",
    airport_city_code: "PPP",
    airport_city_name: "Proserpine",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.495",
    airport_lon: "148.552222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2858,
    airport_code: "PPQ",
    airport_name: "Paraparaumu Arpt",
    airport_city_code: "PPQ",
    airport_city_name: "Paraparaumu",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-40.904722",
    airport_lon: "174.989167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2859,
    airport_code: "PPS",
    airport_name: "Puerto Princesa Arpt",
    airport_city_code: "PPS",
    airport_city_name: "Puerto Princesa",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "9.742119",
    airport_lon: "118.758731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2860,
    airport_code: "PPT",
    airport_name: "Intl Tahiti Faaa",
    airport_city_code: "PPT",
    airport_city_name: "Papeete",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-17.556667",
    airport_lon: "-149.611389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2861,
    airport_code: "PPW",
    airport_name: "Papa Westray Arpt",
    airport_city_code: "PPW",
    airport_city_name: "Papa Westray",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.3517",
    airport_lon: "-2.90028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2862,
    airport_code: "PQC",
    airport_name: "Duong Dang Arpt",
    airport_city_code: "PQC",
    airport_city_name: "Phu Quoc",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "10.227025",
    airport_lon: "103.967169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2863,
    airport_code: "PQI",
    airport_name: "Northern Maine Regional",
    airport_city_code: "PQI",
    airport_city_name: "Presque Isle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "46.688958",
    airport_lon: "-68.044797",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2864,
    airport_code: "PQM",
    airport_name: "Palenque Arpt",
    airport_city_code: "PQM",
    airport_city_name: "Palenque",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2865,
    airport_code: "PQQ",
    airport_name: "Port Macquarie Arpt",
    airport_city_code: "PQQ",
    airport_city_name: "Pt Macquarie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-31.4358",
    airport_lon: "152.863",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2866,
    airport_code: "PRA",
    airport_name: "Parana Arpt",
    airport_city_code: "PRA",
    airport_city_name: "Parana",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-31.794778",
    airport_lon: "-60.480361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2867,
    airport_code: "PRB",
    airport_name: "Paso Robles Cty",
    airport_city_code: "PRB",
    airport_city_name: "Paso Robles",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "35.6729",
    airport_lon: "-120.6271",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2868,
    airport_code: "PRC",
    airport_name: "Prescott Municipal",
    airport_city_code: "PRC",
    airport_city_name: "Prescott",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "34.654472",
    airport_lon: "-112.419583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2869,
    airport_code: "PRG",
    airport_name: "Ruzyne Arpt",
    airport_city_code: "PRG",
    airport_city_name: "Prague",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "1",
    airport_lat: "50.100833",
    airport_lon: "14.26",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2870,
    airport_code: "PRH",
    airport_name: "Phrae Arpt",
    airport_city_code: "PRH",
    airport_city_name: "Phrae",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "18.132169",
    airport_lon: "100.164664",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2871,
    airport_code: "PRI",
    airport_name: "Praslin Island Arpt",
    airport_city_code: "PRI",
    airport_city_name: "Praslin Island",
    airport_country_name: "SEYCHELLES",
    airport_country_code: "SC",
    airport_timezone: "4",
    airport_lat: "-4.319292",
    airport_lon: "55.691417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2872,
    airport_code: "PRM",
    airport_name: "Portimao Airport",
    airport_city_code: "PRM",
    airport_city_name: "Portimao",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-100",
    airport_lat: "37.1499",
    airport_lon: "-8.584",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2873,
    airport_code: "PRN",
    airport_name: "Pristina Arpt",
    airport_city_code: "PRN",
    airport_city_name: "Pristina",
    airport_country_name: "KOSOVO",
    airport_country_code: "KV",
    airport_timezone: "1",
    airport_lat: "42.572778",
    airport_lon: "21.035833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2874,
    airport_code: "PRP",
    airport_name: "Propriano Arpt",
    airport_city_code: "PRP",
    airport_city_name: "Propriano",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2875,
    airport_code: "PRX",
    airport_name: "Paris Cox Field Arpt",
    airport_city_code: "PRX",
    airport_city_name: "Paris",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2876,
    airport_code: "PRY",
    airport_name: "Wonderboom Arpt",
    airport_city_code: "PRY",
    airport_city_name: "Pretoria",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-25.653858",
    airport_lon: "28.224231",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2877,
    airport_code: "HPR",
    airport_name: "Central Hpr Arpt",
    airport_city_code: "PRY",
    airport_city_name: "Pretoria",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2878,
    airport_code: "PSA",
    airport_name: "Gal Galilei Arpt",
    airport_city_code: "PSA",
    airport_city_name: "Pisa",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "43.683917",
    airport_lon: "10.39275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2879,
    airport_code: "PSB",
    airport_name: "Midstate Arpt",
    airport_city_code: "PSB",
    airport_city_name: "Philipsburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2880,
    airport_code: "PSC",
    airport_name: "Tri Cities Arpt",
    airport_city_code: "PSC",
    airport_city_name: "Pasco",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.2647",
    airport_lon: "-119.119",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2881,
    airport_code: "PSE",
    airport_name: "Mercedita Arpt",
    airport_city_code: "PSE",
    airport_city_name: "Ponce",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.008306",
    airport_lon: "-66.563028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2882,
    airport_code: "PSF",
    airport_name: "Pittsfield Municipal",
    airport_city_code: "PSF",
    airport_city_name: "Pittsfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.6389",
    airport_lon: "-90.7784",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2883,
    airport_code: "PSG",
    airport_name: "Petersburg Municipal",
    airport_city_code: "PSG",
    airport_city_name: "Petersburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "56.801667",
    airport_lon: "-132.945278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2884,
    airport_code: "PSK",
    airport_name: "New River Valley Arpt",
    airport_city_code: "PSK",
    airport_city_name: "Dublin",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2885,
    airport_code: "PSL",
    airport_name: "Perth Airport",
    airport_city_code: "PSL",
    airport_city_name: "Perth",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "56.4392",
    airport_lon: "-3.3722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2886,
    airport_code: "PSM",
    airport_name: "Portsmouth Pease Intl Arpt",
    airport_city_code: "PSM",
    airport_city_name: "Portsmouth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.0779",
    airport_lon: "-70.8233",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2887,
    airport_code: "PSO",
    airport_name: "Cano Arpt",
    airport_city_code: "PSO",
    airport_city_name: "Pasto",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "1.396247",
    airport_lon: "-77.291478",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2888,
    airport_code: "PSP",
    airport_name: "Palm Springs Municipal",
    airport_city_code: "PSP",
    airport_city_name: "Palm Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.829667",
    airport_lon: "-116.506694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2889,
    airport_code: "PSR",
    airport_name: "Liberi Airport",
    airport_city_code: "PSR",
    airport_city_name: "Pescara",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "42.431656",
    airport_lon: "14.181067",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2890,
    airport_code: "PSS",
    airport_name: "Posadas Arpt",
    airport_city_code: "PSS",
    airport_city_name: "Posadas",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-27.385839",
    airport_lon: "-55.970728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2891,
    airport_code: "PTB",
    airport_name: "Petersburg Municipal Arpt",
    airport_city_code: "PTB",
    airport_city_name: "Petersburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2892,
    airport_code: "PTF",
    airport_name: "Malololailai Arpt",
    airport_city_code: "PTF",
    airport_city_name: "Malololailai",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "12",
    airport_lat: "-17.7779",
    airport_lon: "177.197",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2893,
    airport_code: "PTG",
    airport_name: "Pietersburg Arpt",
    airport_city_code: "PTG",
    airport_city_name: "Pietersburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-23.926089",
    airport_lon: "29.484422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2894,
    airport_code: "PTI",
    airport_name: "Port Douglas Arpt",
    airport_city_code: "PTI",
    airport_city_name: "Port Douglas",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2895,
    airport_code: "PTJ",
    airport_name: "Portland Arpt",
    airport_city_code: "PTJ",
    airport_city_name: "Portland",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-38.3181",
    airport_lon: "141.471",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2896,
    airport_code: "PTK",
    airport_name: "Oakland Pontiac Arpt",
    airport_city_code: "PTK",
    airport_city_name: "Pontiac",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "42.6655",
    airport_lon: "-83.4201",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2897,
    airport_code: "PTO",
    airport_name: "Pato Branco Municipal",
    airport_city_code: "PTO",
    airport_city_name: "Pato Branco",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-26.2172",
    airport_lon: "-52.6936",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2898,
    airport_code: "PTP",
    airport_name: "Le Raizet Arpt",
    airport_city_code: "PTP",
    airport_city_name: "Pointe A Pitre",
    airport_country_name: "GUADELOUPE",
    airport_country_code: "GP",
    airport_timezone: "-4",
    airport_lat: "16.265306",
    airport_lon: "-61.531806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2899,
    airport_code: "PTS",
    airport_name: "Atkinson Municipal Arpt",
    airport_city_code: "PTS",
    airport_city_name: "Pittsburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2900,
    airport_code: "PTW",
    airport_name: "Pottstown Limerick",
    airport_city_code: "PTW",
    airport_city_name: "Pottstown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2901,
    airport_code: "PTY",
    airport_name: "Tocumen Intl Arpt",
    airport_city_code: "PTY",
    airport_city_name: "Panama City",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "9.071364",
    airport_lon: "-79.383453",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2902,
    airport_code: "PAC",
    airport_name: "Paitilla Arpt",
    airport_city_code: "PTY",
    airport_city_name: "Panama City",
    airport_country_name: "PANAMA",
    airport_country_code: "PA",
    airport_timezone: "-5",
    airport_lat: "8.973339",
    airport_lon: "-79.555583",
    airport_num_airports: 2,
  },
  {
    airport_id: 2903,
    airport_code: "PUB",
    airport_name: "Pueblo Arpt",
    airport_city_code: "PUB",
    airport_city_name: "Pueblo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.289085",
    airport_lon: "-104.496572",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2904,
    airport_code: "TAD",
    airport_name: "Las Animas Arpt",
    airport_city_code: "PUB",
    airport_city_name: "Pueblo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 2905,
    airport_code: "PUC",
    airport_name: "Carbon Cty Municipal Arpt",
    airport_city_code: "PUC",
    airport_city_name: "Price",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.609722",
    airport_lon: "-110.75278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2906,
    airport_code: "PUF",
    airport_name: "Uzein Airport",
    airport_city_code: "PUF",
    airport_city_name: "Pau Fr",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.38",
    airport_lon: "-0.418611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2907,
    airport_code: "PUG",
    airport_name: "Port Augusta Arpt",
    airport_city_code: "PUG",
    airport_city_name: "Port Augusta",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-32.506944",
    airport_lon: "137.716667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2908,
    airport_code: "PUJ",
    airport_name: "Punta Cana Arpt",
    airport_city_code: "PUJ",
    airport_city_name: "Punta Cana",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "18.567367",
    airport_lon: "-68.363431",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2909,
    airport_code: "PUQ",
    airport_name: "Presidente Ibanez Arpt",
    airport_city_code: "PUQ",
    airport_city_name: "Punta Arenas",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-53.002642",
    airport_lon: "-70.854586",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2910,
    airport_code: "PUS",
    airport_name: "Kimhae Arpt",
    airport_city_code: "PUS",
    airport_city_name: "Busan",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.179528",
    airport_lon: "128.938222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2911,
    airport_code: "PUW",
    airport_name: "Pullman Moscow Arpt",
    airport_city_code: "PUW",
    airport_city_name: "Pullman",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.743861",
    airport_lon: "-117.109583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2912,
    airport_code: "PUX",
    airport_name: "Puerto Varas Arpt",
    airport_city_code: "PUX",
    airport_city_name: "Puerto Varas",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2913,
    airport_code: "PUY",
    airport_name: "Pula Arpt",
    airport_city_code: "PUY",
    airport_city_name: "Pula",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "44.893533",
    airport_lon: "13.922192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2914,
    airport_code: "PVC",
    airport_name: "Provincetown Municipal",
    airport_city_code: "PVC",
    airport_city_name: "Provincetown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.071945",
    airport_lon: "-70.22139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2915,
    airport_code: "PVD",
    airport_name: "T F Green St Arpt",
    airport_city_code: "PVD",
    airport_city_name: "Providence",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.732581",
    airport_lon: "-71.420383",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2916,
    airport_code: "PVF",
    airport_name: "Placerville Arpt",
    airport_city_code: "PVF",
    airport_city_name: "Placerville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2917,
    airport_code: "PVH",
    airport_name: "Belmonte Arpt",
    airport_city_code: "PVH",
    airport_city_name: "Porto Velho",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-8.709294",
    airport_lon: "-63.902281",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2918,
    airport_code: "PVK",
    airport_name: "Aktion Arpt",
    airport_city_code: "PVK",
    airport_city_name: "Preveza",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "38.925467",
    airport_lon: "20.765311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2919,
    airport_code: "PVR",
    airport_name: "Ordaz Arpt",
    airport_city_code: "PVR",
    airport_city_name: "Puerto Vallarta",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.680083",
    airport_lon: "-105.254167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2920,
    airport_code: "PVS",
    airport_name: "Provideniya Bay Airport",
    airport_city_code: "PVS",
    airport_city_name: "Provideniya Bay Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "12",
    airport_lat: "64.378139",
    airport_lon: "-173.243306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2921,
    airport_code: "PVU",
    airport_name: "Provo Arpt",
    airport_city_code: "PVU",
    airport_city_name: "Provo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.218056",
    airport_lon: "-111.722222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2922,
    airport_code: "PVW",
    airport_name: "Hale County Arpt",
    airport_city_code: "PVW",
    airport_city_name: "Plainview",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2923,
    airport_code: "PVZ",
    airport_name: "Casement Arpt",
    airport_city_code: "PVZ",
    airport_city_name: "Painesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2924,
    airport_code: "PWE",
    airport_name: "Pevek Airport",
    airport_city_code: "PWE",
    airport_city_name: "Pevek Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "12",
    airport_lat: "69.783283",
    airport_lon: "170.597006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2925,
    airport_code: "PWM",
    airport_name: "Portland Intl Jetport",
    airport_city_code: "PWM",
    airport_city_name: "Portland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.646161",
    airport_lon: "-70.309281",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2926,
    airport_code: "PWQ",
    airport_name: "Pavlodar Arpt",
    airport_city_code: "PWQ",
    airport_city_name: "Pavlodar",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "52.195",
    airport_lon: "77.073889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2927,
    airport_code: "PWT",
    airport_name: "Bremerton Municipal",
    airport_city_code: "PWT",
    airport_city_name: "Bremerton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.490244",
    airport_lon: "-122.764814",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2928,
    airport_code: "PXM",
    airport_name: "Puerto Escondido Municipal",
    airport_city_code: "PXM",
    airport_city_name: "Puerto Escondido",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "15.876861",
    airport_lon: "-97.089117",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2929,
    airport_code: "PXO",
    airport_name: "Porto Santo Arpt",
    airport_city_code: "PXO",
    airport_city_name: "Porto Santo",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "33.073386",
    airport_lon: "-16.349975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2930,
    airport_code: "PXU",
    airport_name: "Pleiku Arpt",
    airport_city_code: "PXU",
    airport_city_name: "Pleiku",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "14.004522",
    airport_lon: "108.017158",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2931,
    airport_code: "PYJ",
    airport_name: "Polyarny Airport",
    airport_city_code: "PYJ",
    airport_city_name: "Polyarny Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "10",
    airport_lat: "66.400431",
    airport_lon: "112.030325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2932,
    airport_code: "PYM",
    airport_name: "Plymouth Arpt",
    airport_city_code: "PYM",
    airport_city_name: "Plymouth",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "41.909028",
    airport_lon: "-70.728778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2933,
    airport_code: "PYX",
    airport_name: "Pattaya Arpt",
    airport_city_code: "PYX",
    airport_city_name: "Pattaya",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2934,
    airport_code: "PYY",
    airport_name: "Pai Arpt",
    airport_city_code: "PYY",
    airport_city_name: "Pai City",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "19.372",
    airport_lon: "98.437",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2935,
    airport_code: "PZB",
    airport_name: "Pietermaritzburg Arpt",
    airport_city_code: "PZB",
    airport_city_name: "Pietermaritzburg",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-29.648975",
    airport_lon: "30.398667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2936,
    airport_code: "PZE",
    airport_name: "Penzance Arpt",
    airport_city_code: "PZE",
    airport_city_name: "Penzance",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.1281",
    airport_lon: "-5.51845",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2937,
    airport_code: "PZI",
    airport_name: "Pan Zhi Hua Bao An Ying Arpt",
    airport_city_code: "PZI",
    airport_city_name: "Panzhihua",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "26.54",
    airport_lon: "101.799",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2938,
    airport_code: "PZO",
    airport_name: "Puerto Ordaz Arpt",
    airport_city_code: "PZO",
    airport_city_name: "Puerto Ordaz",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "8.288528",
    airport_lon: "-62.760361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2939,
    airport_code: "PZU",
    airport_name: "Port Sudan New intl Arpt",
    airport_city_code: "PZU",
    airport_city_name: "Port Sudan",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "2",
    airport_lat: "19.4336",
    airport_lon: "37.2341",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2940,
    airport_code: "PZY",
    airport_name: "Piestany Arpt",
    airport_city_code: "PZY",
    airport_city_name: "Piestany",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "1",
    airport_lat: "48.625247",
    airport_lon: "17.828444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2941,
    airport_code: "ZYM",
    airport_name: "Arnhem Rail Station",
    airport_city_code: "QAR",
    airport_city_name: "Arnhem",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 2942,
    airport_code: "QBC",
    airport_name: "Bella Colla Municipal",
    airport_city_code: "QBC",
    airport_city_name: "Bella Coola",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "52.3875",
    airport_lon: "-126.596",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2943,
    airport_code: "QCE",
    airport_name: "Copper Mountain Van Service",
    airport_city_code: "QCE",
    airport_city_name: "Copper Mountain",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2944,
    airport_code: "QDH",
    airport_name: "Ashford Intl Rail Station",
    airport_city_code: "QDH",
    airport_city_name: "Ashford",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2945,
    airport_code: "XGM",
    airport_name: "Grantham Rail Station",
    airport_city_code: "QEW",
    airport_city_name: "Leicester",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 2946,
    airport_code: "QFQ",
    airport_name: "Harbour Arpt",
    airport_city_code: "QFQ",
    airport_city_name: "Maloy",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2947,
    airport_code: "QHV",
    airport_name: "Novo Hamburgo Arpt",
    airport_city_code: "QHV",
    airport_city_name: "Novo Hamburgo",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2948,
    airport_code: "QIG",
    airport_name: "Iguatu Arpt",
    airport_city_code: "QIG",
    airport_city_name: "Iguatu",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-6.3475",
    airport_lon: "-39.2983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2949,
    airport_code: "QKB",
    airport_name: "Breckenridge Van Service",
    airport_city_code: "QKB",
    airport_city_name: "Breckenridge",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2950,
    airport_code: "QKS",
    airport_name: "Keystone Van Service",
    airport_city_code: "QKS",
    airport_city_name: "Keystone",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2951,
    airport_code: "QLE",
    airport_name: "Leeton Arpt",
    airport_city_code: "QLE",
    airport_city_name: "Leeton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2952,
    airport_code: "QNT",
    airport_name: "Niteroi Arpt",
    airport_city_code: "QNT",
    airport_city_name: "Niteroi",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2953,
    airport_code: "QQX",
    airport_name: "Bath Rail Service",
    airport_city_code: "QQX",
    airport_city_name: "Bath",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2954,
    airport_code: "QQY",
    airport_name: "York Rail Station",
    airport_city_code: "QQY",
    airport_city_name: "York",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2955,
    airport_code: "QRO",
    airport_name: "Queretaro Arpt",
    airport_city_code: "QRO",
    airport_city_name: "Queretaro",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "20.617289",
    airport_lon: "-100.185658",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2956,
    airport_code: "QRV",
    airport_name: "Arras Arpt",
    airport_city_code: "QRV",
    airport_city_name: "Arras",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2957,
    airport_code: "QSF",
    airport_name: "Setif Arpt",
    airport_city_code: "QSF",
    airport_city_name: "Setif",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "0",
    airport_lat: "36.1781",
    airport_lon: "5.32449",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2958,
    airport_code: "QXB",
    airport_name: "Aix en Provence Rail Station",
    airport_city_code: "QXB",
    airport_city_name: "Aix En Provence",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.505554",
    airport_lon: "5.367778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2959,
    airport_code: "QYH",
    airport_name: "Hengelo Railway Service",
    airport_city_code: "QYH",
    airport_city_name: "Hengelo",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2960,
    airport_code: "QYM",
    airport_name: "Amersfoort Railway Service",
    airport_city_code: "QYM",
    airport_city_name: "Amersfoort",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2961,
    airport_code: "QYO",
    airport_name: "Olsztyn Arpt",
    airport_city_code: "QYO",
    airport_city_name: "Olsztyn",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2962,
    airport_code: "QYX",
    airport_name: "Uppsala Railway Service",
    airport_city_code: "QYX",
    airport_city_name: "Uppsala",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "59.9008",
    airport_lon: "17.5958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2963,
    airport_code: "RAB",
    airport_name: "Lakunai Arpt",
    airport_city_code: "RAB",
    airport_city_name: "Rabaul",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-4.34046",
    airport_lon: "152.38",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2964,
    airport_code: "RAC",
    airport_name: "Horlick Arpt",
    airport_city_code: "RAC",
    airport_city_name: "Racine",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2965,
    airport_code: "RAI",
    airport_name: "Francisco Mendes",
    airport_city_code: "RAI",
    airport_city_name: "Praia",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "14.9245",
    airport_lon: "-23.4935",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2966,
    airport_code: "RAJ",
    airport_name: "Rajkot Civil Arpt",
    airport_city_code: "RAJ",
    airport_city_name: "Rajkot",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "22.309183",
    airport_lon: "70.779525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2967,
    airport_code: "RAK",
    airport_name: "Menara Airport",
    airport_city_code: "RAK",
    airport_city_name: "Marrakech",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "31.606886",
    airport_lon: "-8.0363",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2968,
    airport_code: "RAL",
    airport_name: "Riverside Municipal",
    airport_city_code: "RAL",
    airport_city_name: "Riverside",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.951875",
    airport_lon: "-117.445103",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2969,
    airport_code: "RAO",
    airport_name: "Leite Lopes",
    airport_city_code: "RAO",
    airport_city_name: "Ribeirao Preto",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-21.134167",
    airport_lon: "-47.774189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2970,
    airport_code: "RAP",
    airport_name: "Rapid City Regional Arpt",
    airport_city_code: "RAP",
    airport_city_name: "Rapid City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.045278",
    airport_lon: "-103.057222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2971,
    airport_code: "RAR",
    airport_name: "Rarotonga Arpt",
    airport_city_code: "RAR",
    airport_city_name: "Rarotonga",
    airport_country_name: "COOK ISLANDS",
    airport_country_code: "CK",
    airport_timezone: "-10",
    airport_lat: "-21.202739",
    airport_lon: "-159.805556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2972,
    airport_code: "RAT",
    airport_name: "Raduzhny Airport",
    airport_city_code: "RAT",
    airport_city_name: "Raduzhny Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "62.1586",
    airport_lon: "77.3289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2973,
    airport_code: "RAY",
    airport_name: "Rothesay Heliport",
    airport_city_code: "RAY",
    airport_city_name: "Rothesay",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2974,
    airport_code: "RBA",
    airport_name: "Sale Airport",
    airport_city_code: "RBA",
    airport_city_name: "Rabat",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "34.051467",
    airport_lon: "-6.751519",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2975,
    airport_code: "RBF",
    airport_name: "Big Bear City Arpt",
    airport_city_code: "RBF",
    airport_city_name: "Big Bear City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2976,
    airport_code: "RBG",
    airport_name: "Roseburg Municipal",
    airport_city_code: "RBG",
    airport_city_name: "Roseburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2977,
    airport_code: "RBM",
    airport_name: "Wallmuhle",
    airport_city_code: "RBM",
    airport_city_name: "Straubing",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-6",
    airport_lat: "34.850089",
    airport_lon: "-92.300153",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2978,
    airport_code: "RBR",
    airport_name: "Pres Medici Arpt",
    airport_city_code: "RBR",
    airport_city_name: "Rio Branco",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-5",
    airport_lat: "-9.583",
    airport_lon: "-67.4836",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2979,
    airport_code: "RBW",
    airport_name: "Walterboro Municipal Arpt",
    airport_city_code: "RBW",
    airport_city_name: "Walterboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2980,
    airport_code: "RCB",
    airport_name: "Richards Bay Arpt",
    airport_city_code: "RCB",
    airport_city_name: "Richards Bay",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-28.741039",
    airport_lon: "32.092111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2981,
    airport_code: "RCE",
    airport_name: "Roche Harbor Arpt",
    airport_city_code: "RCE",
    airport_city_name: "Roche Harbor",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "48.608056",
    airport_lon: "-123.159722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2982,
    airport_code: "RCO",
    airport_name: "St Agnant Arpt",
    airport_city_code: "RCO",
    airport_city_name: "Rochefort",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "45.887779",
    airport_lon: "-0.983056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2983,
    airport_code: "RCU",
    airport_name: "Rio Cuarto Arpt",
    airport_city_code: "RCU",
    airport_city_name: "Rio Cuarto",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-33.085128",
    airport_lon: "-64.261314",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2984,
    airport_code: "RDD",
    airport_name: "Redding Municipal Arpt",
    airport_city_code: "RDD",
    airport_city_name: "Redding",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "40.509",
    airport_lon: "-122.293389",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 2985,
    airport_code: "RBL",
    airport_name: "Flight Service Station",
    airport_city_code: "RDD",
    airport_city_name: "Redding",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.1506",
    airport_lon: "-122.2523",
    airport_num_airports: 2,
  },
  {
    airport_id: 2986,
    airport_code: "RDG",
    airport_name: "Reading Municipal",
    airport_city_code: "RDG",
    airport_city_name: "Reading",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.3785",
    airport_lon: "-75.9652",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2987,
    airport_code: "RDM",
    airport_name: "Roberts Field",
    airport_city_code: "RDM",
    airport_city_name: "Redmond",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "44.254066",
    airport_lon: "-121.149964",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2988,
    airport_code: "RDN",
    airport_name: "Lts Pulau Redang Arpt",
    airport_city_code: "RDN",
    airport_city_name: "Redang",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.76528",
    airport_lon: "103.007",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2989,
    airport_code: "RDU",
    airport_name: "Raleigh Durham Intl Arpt",
    airport_city_code: "RDU",
    airport_city_name: "Raleigh",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.877639",
    airport_lon: "-78.787472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2990,
    airport_code: "RDZ",
    airport_name: "Marcillac",
    airport_city_code: "RDZ",
    airport_city_name: "Rodez",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.407869",
    airport_lon: "2.482672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2991,
    airport_code: "REC",
    airport_name: "Recife Airport",
    airport_city_code: "REC",
    airport_city_name: "Recife",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-8.126794",
    airport_lon: "-34.923039",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2992,
    airport_code: "REG",
    airport_name: "Tito Menniti Arpt",
    airport_city_code: "REG",
    airport_city_name: "Reggio Calabria",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "38.071206",
    airport_lon: "15.651556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2993,
    airport_code: "KEF",
    airport_name: "Reykjavik Keflavik Intl Arpt",
    airport_city_code: "REK",
    airport_city_name: "Reykjavik",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "63.985",
    airport_lon: "-22.605556",
    airport_num_airports: 2,
  },
  {
    airport_id: 2994,
    airport_code: "RKV",
    airport_name: "Reykjavik Domestic Arpt",
    airport_city_code: "REK",
    airport_city_name: "Reykjavik",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "64.13",
    airport_lon: "-21.940556",
    airport_num_airports: 2,
  },
  {
    airport_id: 2995,
    airport_code: "REL",
    airport_name: "Trelew Arpt",
    airport_city_code: "REL",
    airport_city_name: "Trelew",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-43.2105",
    airport_lon: "-65.270319",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2996,
    airport_code: "REN",
    airport_name: "Orenburg Arpt",
    airport_city_code: "REN",
    airport_city_name: "Orenburg",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "51.795786",
    airport_lon: "55.456744",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2997,
    airport_code: "REP",
    airport_name: "Siem Reap Arpt",
    airport_city_code: "REP",
    airport_city_name: "Siem Reap",
    airport_country_name: "CAMBODIA",
    airport_country_code: "KH",
    airport_timezone: "7",
    airport_lat: "13.410666",
    airport_lon: "103.81284",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2998,
    airport_code: "RER",
    airport_name: "Base Aerea Del Sur Arpt",
    airport_city_code: "RER",
    airport_city_name: "Retalhuleu",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 2999,
    airport_code: "RES",
    airport_name: "Resistencia Arpt",
    airport_city_code: "RES",
    airport_city_name: "Resistencia",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-27.449986",
    airport_lon: "-59.056125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3000,
    airport_code: "REU",
    airport_name: "Reus Arpt",
    airport_city_code: "REU",
    airport_city_name: "Reus",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "41.147392",
    airport_lon: "1.167172",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3001,
    airport_code: "REX",
    airport_name: "General Lucio Blanco Arpt",
    airport_city_code: "REX",
    airport_city_name: "Reynosa",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "26.008908",
    airport_lon: "-98.228513",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3002,
    airport_code: "ZRF",
    airport_name: "Peoria Rockford Bus Terminal",
    airport_city_code: "RFD",
    airport_city_name: "Rockford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3003,
    airport_code: "ZRK",
    airport_name: "Van Galder Bus Terminal",
    airport_city_code: "RFD",
    airport_city_name: "Rockford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3004,
    airport_code: "RFP",
    airport_name: "Raiatea Arpt",
    airport_city_code: "RFP",
    airport_city_name: "Raiatea",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-16.722861",
    airport_lon: "-151.465856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3005,
    airport_code: "RFR",
    airport_name: "Rio Frio Arpt",
    airport_city_code: "RFR",
    airport_city_name: "Rio Frio",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3006,
    airport_code: "RGA",
    airport_name: "Rio Grande Arpt",
    airport_city_code: "RGA",
    airport_city_name: "Rio Grande",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-53.777667",
    airport_lon: "-67.749389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3007,
    airport_code: "RGI",
    airport_name: "Rangiroa Arpt",
    airport_city_code: "RGI",
    airport_city_name: "Rangiroa Island",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-14.954283",
    airport_lon: "-147.6608",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3008,
    airport_code: "RGL",
    airport_name: "Rio Gallegos Internacional Arpt",
    airport_city_code: "RGL",
    airport_city_name: "Rio Gallegos",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-51.608875",
    airport_lon: "-69.312636",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3009,
    airport_code: "RGN",
    airport_name: "Mingaladon",
    airport_city_code: "RGN",
    airport_city_name: "Yangon",
    airport_country_name: "MYANMAR",
    airport_country_code: "MM",
    airport_timezone: "6",
    airport_lat: "16.907305",
    airport_lon: "96.133222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3010,
    airport_code: "RGR",
    airport_name: "Ranger Municipal Arpt",
    airport_city_code: "RGR",
    airport_city_name: "Ranger",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3011,
    airport_code: "RHE",
    airport_name: "Champagne Arpt",
    airport_city_code: "RHE",
    airport_city_name: "Reims",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.31",
    airport_lon: "4.05",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3012,
    airport_code: "RHI",
    airport_name: "Oneida County",
    airport_city_code: "RHI",
    airport_city_name: "Rhinelander",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "45.6312",
    airport_lon: "-89.4675",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3013,
    airport_code: "RHN",
    airport_name: "Rosh Pina Airport",
    airport_city_code: "RHN",
    airport_city_name: "Rosh Pina",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3014,
    airport_code: "RHO",
    airport_name: "Diagoras Arpt",
    airport_city_code: "RHO",
    airport_city_name: "Rhodes",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "36.405419",
    airport_lon: "28.086192",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3015,
    airport_code: "RIA",
    airport_name: "Base Aerea Arpt",
    airport_city_code: "RIA",
    airport_city_name: "Santa Maria",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-29.711358",
    airport_lon: "-53.688153",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3016,
    airport_code: "RIC",
    airport_name: "Richmond Intl Arpt",
    airport_city_code: "RIC",
    airport_city_name: "Richmond",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.505167",
    airport_lon: "-77.319667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3017,
    airport_code: "RIF",
    airport_name: "Reynolds Municipal Arpt",
    airport_city_code: "RIF",
    airport_city_name: "Richfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.736436",
    airport_lon: "-112.098944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3018,
    airport_code: "RIG",
    airport_name: "Rio Grande Airport",
    airport_city_code: "RIG",
    airport_city_name: "Rio Grande",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-32.082617",
    airport_lon: "-52.166542",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3019,
    airport_code: "RIL",
    airport_name: "Garfield Cty Arpt",
    airport_city_code: "RIL",
    airport_city_name: "Rifle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.526306",
    airport_lon: "-107.726944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3020,
    airport_code: "GIG",
    airport_name: "Rio Internacional",
    airport_city_code: "RIO",
    airport_city_name: "Rio De Janeiro",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.808903",
    airport_lon: "-43.243647",
    airport_num_airports: 2,
  },
  {
    airport_id: 3021,
    airport_code: "SDU",
    airport_name: "Santos Dumont Arpt",
    airport_city_code: "RIO",
    airport_city_name: "Rio De Janeiro",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-22.910461",
    airport_lon: "-43.163133",
    airport_num_airports: 2,
  },
  {
    airport_id: 3022,
    airport_code: "RIW",
    airport_name: "Riverton Municipal",
    airport_city_code: "RIW",
    airport_city_name: "Riverton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.064167",
    airport_lon: "-108.459722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3023,
    airport_code: "RIX",
    airport_name: "Riga Arpt",
    airport_city_code: "RIX",
    airport_city_name: "Riga",
    airport_country_name: "LATVIA",
    airport_country_code: "LV",
    airport_timezone: "2",
    airport_lat: "56.923611",
    airport_lon: "23.971111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3024,
    airport_code: "RIY",
    airport_name: "Riyan Mukalla Arpt",
    airport_city_code: "RIY",
    airport_city_name: "Riyan",
    airport_country_name: "YEMEN",
    airport_country_code: "YE",
    airport_timezone: "3",
    airport_lat: "14.662639",
    airport_lon: "49.375028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3025,
    airport_code: "RJA",
    airport_name: "Rajahmundry Arpt",
    airport_city_code: "RJA",
    airport_city_name: "Rajahmundry",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "17.110361",
    airport_lon: "81.818208",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3026,
    airport_code: "RJK",
    airport_name: "Rijeka Arpt",
    airport_city_code: "RJK",
    airport_city_name: "Rijeka",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "45.216889",
    airport_lon: "14.570267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3027,
    airport_code: "RJL",
    airport_name: "Agoncillo Arpt",
    airport_city_code: "RJL",
    airport_city_name: "Logrono",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "42.4542",
    airport_lon: "-2.32083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3028,
    airport_code: "RKC",
    airport_name: "Yreka Arpt",
    airport_city_code: "RKC",
    airport_city_name: "Yreka",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3029,
    airport_code: "RKD",
    airport_name: "Rockland Arpt",
    airport_city_code: "RKD",
    airport_city_name: "Rockland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.060111",
    airport_lon: "-69.09923",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3030,
    airport_code: "RKE",
    airport_name: "Roskilde Arpt",
    airport_city_code: "RKE",
    airport_city_name: "Roskilde",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.585567",
    airport_lon: "12.131428",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3031,
    airport_code: "RKH",
    airport_name: "Rock Hill York County Arpt",
    airport_city_code: "RKH",
    airport_city_name: "Rock Hill",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "34.9878",
    airport_lon: "-81.0572",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3032,
    airport_code: "RKP",
    airport_name: "Aransas County Arpt",
    airport_city_code: "RKP",
    airport_city_name: "Rockport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "28.086222",
    airport_lon: "-97.043694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3033,
    airport_code: "RKS",
    airport_name: "Rock Springs Municipal",
    airport_city_code: "RKS",
    airport_city_name: "Rock Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "41.5942",
    airport_lon: "-109.065",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3034,
    airport_code: "RKT",
    airport_name: "Ras Al Khaimah Arpt",
    airport_city_code: "RKT",
    airport_city_name: "Ras Al Khaimah",
    airport_country_name: "UNITED ARAB EMIRATES",
    airport_country_code: "AE",
    airport_timezone: "4",
    airport_lat: "25.613483",
    airport_lon: "55.938817",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3035,
    airport_code: "RLA",
    airport_name: "National Arpt",
    airport_city_code: "RLA",
    airport_city_name: "Rolla",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "38.1274",
    airport_lon: "-91.7695",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3036,
    airport_code: "RLD",
    airport_name: "Richland Arpt",
    airport_city_code: "RLD",
    airport_city_name: "Richland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3037,
    airport_code: "RLG",
    airport_name: "Laage Arpt",
    airport_city_code: "RLG",
    airport_city_name: "Rostock Laage",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.918167",
    airport_lon: "12.278333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3038,
    airport_code: "RMA",
    airport_name: "Roma Arpt",
    airport_city_code: "RMA",
    airport_city_name: "Roma",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-26.545",
    airport_lon: "148.775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3039,
    airport_code: "RME",
    airport_name: "Griffiss Air Force Base",
    airport_city_code: "RME",
    airport_city_name: "Rome",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.2338",
    airport_lon: "-75.407033",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3040,
    airport_code: "RMF",
    airport_name: "Marsa Alam Arpt",
    airport_city_code: "RMF",
    airport_city_name: "Marsa Alam",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "25.557111",
    airport_lon: "34.583711",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3041,
    airport_code: "RMG",
    airport_name: "Richard B Russell Arpt",
    airport_city_code: "RMG",
    airport_city_name: "Rome",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "34.350778",
    airport_lon: "-85.158667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3042,
    airport_code: "RMI",
    airport_name: "Miramare Airport",
    airport_city_code: "RMI",
    airport_city_name: "Rimini",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "44.020292",
    airport_lon: "12.611747",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3043,
    airport_code: "RMK",
    airport_name: "Renmark Airport",
    airport_city_code: "RMK",
    airport_city_name: "Renmark",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-34.118",
    airport_lon: "140.404",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3044,
    airport_code: "RMS",
    airport_name: "Ramsteim Arpt",
    airport_city_code: "RMS",
    airport_city_name: "Ramstein",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.436911",
    airport_lon: "7.600283",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3045,
    airport_code: "XXY",
    airport_name: "Ronneby Railway Service",
    airport_city_code: "RNB",
    airport_city_name: "Ronneby",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3046,
    airport_code: "XYO",
    airport_name: "Karlshamn Rail Station",
    airport_city_code: "RNB",
    airport_city_name: "Ronneby",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3047,
    airport_code: "RNB",
    airport_name: "Kallinge Arpt",
    airport_city_code: "RNB",
    airport_city_name: "Ronneby",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "56.266667",
    airport_lon: "15.265",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3048,
    airport_code: "RNE",
    airport_name: "Renaison Arpt",
    airport_city_code: "RNE",
    airport_city_name: "Roanne",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.058334",
    airport_lon: "4.001389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3049,
    airport_code: "RNH",
    airport_name: "New Richmond Municipal",
    airport_city_code: "RNH",
    airport_city_name: "New Richmond",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3050,
    airport_code: "RNN",
    airport_name: "Bornholm Arpt",
    airport_city_code: "RNN",
    airport_city_name: "Bornholm",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.063267",
    airport_lon: "14.759558",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3051,
    airport_code: "RNO",
    airport_name: "Reno Tahoe Intl Arpt",
    airport_city_code: "RNO",
    airport_city_name: "Reno",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "39.499108",
    airport_lon: "-119.768108",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3052,
    airport_code: "RNS",
    airport_name: "Saint Jacques Arpt",
    airport_city_code: "RNS",
    airport_city_name: "Rennes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.069508",
    airport_lon: "-1.734794",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3053,
    airport_code: "ZFJ",
    airport_name: "Gare de Rennes",
    airport_city_code: "RNS",
    airport_city_name: "Rennes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "48.15",
    airport_lon: "4.85",
    airport_num_airports: 2,
  },
  {
    airport_id: 3054,
    airport_code: "RNT",
    airport_name: "Renton Arpt",
    airport_city_code: "RNT",
    airport_city_name: "Renton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "47.4933",
    airport_lon: "-122.215",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3055,
    airport_code: "ROA",
    airport_name: "Roanoke Regional Arpt",
    airport_city_code: "ROA",
    airport_city_name: "Roanoke",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.325472",
    airport_lon: "-79.975417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3056,
    airport_code: "ROC",
    airport_name: "Monroe Cty Arpt New York",
    airport_city_code: "ROC",
    airport_city_name: "Rochester",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.118866",
    airport_lon: "-77.672389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3057,
    airport_code: "ROG",
    airport_name: "Rogers Arpt",
    airport_city_code: "ROG",
    airport_city_name: "Rogers",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3058,
    airport_code: "ROI",
    airport_name: "Roi Et Arpt",
    airport_city_code: "ROI",
    airport_city_name: "Roi Et",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "16.116761",
    airport_lon: "103.773797",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3059,
    airport_code: "ROK",
    airport_name: "Rockhampton Arpt",
    airport_city_code: "ROK",
    airport_city_name: "Rockhampton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-23.381944",
    airport_lon: "150.475278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3060,
    airport_code: "CIA",
    airport_name: "Ciampino Arpt",
    airport_city_code: "ROM",
    airport_city_name: "Rome",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "41.799361",
    airport_lon: "12.594936",
    airport_num_airports: 2,
  },
  {
    airport_id: 3061,
    airport_code: "FCO",
    airport_name: "Fiumicino Arpt",
    airport_city_code: "ROM",
    airport_city_name: "Rome",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "41.804475",
    airport_lon: "12.250797",
    airport_num_airports: 2,
  },
  {
    airport_id: 3062,
    airport_code: "RON",
    airport_name: "Rondon Arpt",
    airport_city_code: "RON",
    airport_city_name: "Rondon",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-100",
    airport_lat: "-24.5122",
    airport_lon: "-54.0533",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3063,
    airport_code: "ROO",
    airport_name: "Rondonopolis Arpt",
    airport_city_code: "ROO",
    airport_city_name: "Rondonopolis",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-16.466667",
    airport_lon: "-54.633333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3064,
    airport_code: "ROP",
    airport_name: "Rota Arpt",
    airport_city_code: "ROP",
    airport_city_name: "Rota",
    airport_country_name: "NORTHERN MARIANA ISLANDS",
    airport_country_code: "MP",
    airport_timezone: "10",
    airport_lat: "14.174308",
    airport_lon: "145.242536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3065,
    airport_code: "ROR",
    airport_name: "Airai Airport",
    airport_city_code: "ROR",
    airport_city_name: "Koror",
    airport_country_name: "PALAU",
    airport_country_code: "PW",
    airport_timezone: "9",
    airport_lat: "7.367303",
    airport_lon: "134.544278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3066,
    airport_code: "ROS",
    airport_name: "Fisherton Airport",
    airport_city_code: "ROS",
    airport_city_name: "Rosario",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-32.903611",
    airport_lon: "-60.785",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3067,
    airport_code: "ROT",
    airport_name: "Rotorua Arpt",
    airport_city_code: "ROT",
    airport_city_name: "Rotorua",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-38.109167",
    airport_lon: "176.317222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3068,
    airport_code: "ROV",
    airport_name: "Rostov Arpt",
    airport_city_code: "ROV",
    airport_city_name: "Rostov",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "47.258208",
    airport_lon: "39.818089",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3069,
    airport_code: "ROW",
    airport_name: "Industrial Aircenter",
    airport_city_code: "ROW",
    airport_city_name: "Roswell",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.301556",
    airport_lon: "-104.530556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3070,
    airport_code: "RPN",
    airport_name: "Rosh Pina Arpt",
    airport_city_code: "RPN",
    airport_city_name: "Rosh Pina",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "2",
    airport_lat: "32.981047",
    airport_lon: "35.571908",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3071,
    airport_code: "RPR",
    airport_name: "Raipur Arpt",
    airport_city_code: "RPR",
    airport_city_name: "Raipur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "21.180406",
    airport_lon: "81.738753",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3072,
    airport_code: "RRG",
    airport_name: "Rodrigues Island Arpt",
    airport_city_code: "RRG",
    airport_city_name: "Rodrigues Island",
    airport_country_name: "MAURITIUS",
    airport_country_code: "MU",
    airport_timezone: "4",
    airport_lat: "-19.757658",
    airport_lon: "63.360983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3073,
    airport_code: "RRO",
    airport_name: "Sorrento Arpt",
    airport_city_code: "RRO",
    airport_city_name: "Sorrento",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3074,
    airport_code: "RRS",
    airport_name: "Roros Arpt",
    airport_city_code: "RRS",
    airport_city_name: "Roros",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "62.578411",
    airport_lon: "11.342347",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3075,
    airport_code: "RSA",
    airport_name: "Santa Rosa Arpt",
    airport_city_code: "RSA",
    airport_city_name: "Santa Rosa",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-36.588322",
    airport_lon: "-64.275694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3076,
    airport_code: "RSD",
    airport_name: "S Eleuthera Arpt",
    airport_city_code: "RSD",
    airport_city_name: "Rock Sound",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "24.8917",
    airport_lon: "-76.177739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3077,
    airport_code: "RSH",
    airport_name: "Russian Seaplane Base",
    airport_city_code: "RSH",
    airport_city_name: "Russian Mission",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "61.775",
    airport_lon: "-161.319444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3078,
    airport_code: "RSN",
    airport_name: "Ruston Regional Arpt",
    airport_city_code: "RSN",
    airport_city_name: "Ruston",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3079,
    airport_code: "RST",
    airport_name: "Rochester Municipal",
    airport_city_code: "RST",
    airport_city_name: "Rochester",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "43.908283",
    airport_lon: "-92.500014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3080,
    airport_code: "RSU",
    airport_name: "Yeosu Arpt",
    airport_city_code: "RSU",
    airport_city_name: "Yeosu",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "34.842328",
    airport_lon: "127.61685",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3081,
    airport_code: "RTB",
    airport_name: "Roatan Arpt",
    airport_city_code: "RTB",
    airport_city_name: "Roatan",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-6",
    airport_lat: "16.316814",
    airport_lon: "-86.522961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3082,
    airport_code: "QRH",
    airport_name: "Rotterdam Central Rail Station",
    airport_city_code: "RTM",
    airport_city_name: "Rotterdam",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3083,
    airport_code: "RTM",
    airport_name: "Rotterdam Arpt",
    airport_city_code: "RTM",
    airport_city_name: "Rotterdam",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "51.956944",
    airport_lon: "4.437222",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3084,
    airport_code: "RTN",
    airport_name: "Crews Fld",
    airport_city_code: "RTN",
    airport_city_name: "Raton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3085,
    airport_code: "RTS",
    airport_name: "Rottnest Island Arpt",
    airport_city_code: "RTS",
    airport_city_name: "Rottnest",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3086,
    airport_code: "RTW",
    airport_name: "Saratov Arpt",
    airport_city_code: "RTW",
    airport_city_name: "Saratov",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "51.334366",
    airport_lon: "46.022952",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3087,
    airport_code: "OSW",
    airport_name: "Orsk Airport",
    airport_city_code: "RU",
    airport_city_name: "Orsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "52.1",
    airport_lon: "58.566666",
    airport_num_airports: 1,
  },
  {
    airport_id: 3088,
    airport_code: "RUH",
    airport_name: "King Khaled Intl",
    airport_city_code: "RUH",
    airport_city_name: "Riyadh",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "24.95764",
    airport_lon: "46.698776",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3089,
    airport_code: "RUI",
    airport_name: "Ruidoso Municipal Arpt",
    airport_city_code: "RUI",
    airport_city_name: "Ruidoso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "33.4629",
    airport_lon: "-105.5348",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3090,
    airport_code: "RUN",
    airport_name: "Gillot Airport",
    airport_city_code: "RUN",
    airport_city_name: "Reunion Island",
    airport_country_name: "REUNION",
    airport_country_code: "RE",
    airport_timezone: "5",
    airport_lat: "-20.8871",
    airport_lon: "55.510308",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3091,
    airport_code: "RUT",
    airport_name: "Rutland Arpt",
    airport_city_code: "RUT",
    airport_city_name: "Rutland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.5294",
    airport_lon: "-72.9496",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3092,
    airport_code: "RUY",
    airport_name: "Copan Arpt",
    airport_city_code: "RUY",
    airport_city_name: "Copan",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3093,
    airport_code: "RVK",
    airport_name: "Ryumsjoen Arpt",
    airport_city_code: "RVK",
    airport_city_name: "Roervik",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "64.8383",
    airport_lon: "11.1461",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3094,
    airport_code: "RVN",
    airport_name: "Rovaniemi Arpt",
    airport_city_code: "RVN",
    airport_city_name: "Rovaniemi",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "66.564822",
    airport_lon: "25.830411",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3095,
    airport_code: "RVR",
    airport_name: "Green River Arpt",
    airport_city_code: "RVR",
    airport_city_name: "Green River",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.245278",
    airport_lon: "-65.643333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3096,
    airport_code: "RVT",
    airport_name: "Ravensthorpe Arpt",
    airport_city_code: "RVT",
    airport_city_name: "Ravensthorpe",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-33.797222",
    airport_lon: "120.208056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3097,
    airport_code: "RWI",
    airport_name: "Wilson Arpt",
    airport_city_code: "RWI",
    airport_city_name: "Rocky Mount",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.8563",
    airport_lon: "-77.8919",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3098,
    airport_code: "RWL",
    airport_name: "Rawlins Municipal",
    airport_city_code: "RWL",
    airport_city_name: "Rawlins",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "41.805598",
    airport_lon: "-107.19994",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3099,
    airport_code: "RWN",
    airport_name: "Rovno Arpt",
    airport_city_code: "RWN",
    airport_city_name: "Rovno",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "50.6071",
    airport_lon: "26.1416",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3100,
    airport_code: "RWS",
    airport_name: "Sumare Arpt",
    airport_city_code: "RWS",
    airport_city_name: "Sumare",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3101,
    airport_code: "RXS",
    airport_name: "Roxas City Arpt",
    airport_city_code: "RXS",
    airport_city_name: "Roxas City",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "11.597669",
    airport_lon: "122.751669",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3102,
    airport_code: "RYB",
    airport_name: "Rybinsk Staroselye",
    airport_city_code: "RYB",
    airport_city_name: "Rybinsk Staroselye",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "58.1042",
    airport_lon: "38.9294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3103,
    airport_code: "RZE",
    airport_name: "Jasionka Arpt",
    airport_city_code: "RZE",
    airport_city_name: "Rzeszow",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "50.109958",
    airport_lon: "22.019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3104,
    airport_code: "RZN",
    airport_name: "Ryazan Arpt",
    airport_city_code: "RZN",
    airport_city_name: "Ryazan",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3105,
    airport_code: "RZZ",
    airport_name: "Halifax Cty Arpt",
    airport_city_code: "RZZ",
    airport_city_name: "Roanoke Rapids",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3106,
    airport_code: "SAB",
    airport_name: "Juancho Yraus Quinl",
    airport_city_code: "SAB",
    airport_city_name: "Saba Island",
    airport_country_name: "NETHERLANDS ANTILLES",
    airport_country_code: "AN",
    airport_timezone: "-4",
    airport_lat: "17.645278",
    airport_lon: "-63.220556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3107,
    airport_code: "SMF",
    airport_name: "Sacramento International",
    airport_city_code: "SAC",
    airport_city_name: "Sacramento",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "38.695417",
    airport_lon: "-121.590778",
    airport_num_airports: 3,
  },
  {
    airport_id: 3108,
    airport_code: "MHR",
    airport_name: "Mather Air Force Base",
    airport_city_code: "SAC",
    airport_city_name: "Sacramento",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "38.553897",
    airport_lon: "-121.297592",
    airport_num_airports: 3,
  },
  {
    airport_id: 3109,
    airport_code: "SAC",
    airport_name: "Sacramento Executive Arpt",
    airport_city_code: "SAC",
    airport_city_name: "Sacramento",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "38.512524",
    airport_lon: "-121.49347",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3110,
    airport_code: "SAF",
    airport_name: "Santa Fe Municipal",
    airport_city_code: "SAF",
    airport_city_name: "Santa Fe",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "35.617108",
    airport_lon: "-106.089422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3111,
    airport_code: "SAH",
    airport_name: "Sanaa Intl Airport",
    airport_city_code: "SAH",
    airport_city_name: "Sanaa",
    airport_country_name: "YEMEN",
    airport_country_code: "YE",
    airport_timezone: "3",
    airport_lat: "15.476258",
    airport_lon: "44.219739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3112,
    airport_code: "SAK",
    airport_name: "Saudarkrokur Arpt",
    airport_city_code: "SAK",
    airport_city_name: "Saudarkrokur",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "65.731667",
    airport_lon: "-19.572778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3113,
    airport_code: "SAL",
    airport_name: "Comalapa Intl Arpt",
    airport_city_code: "SAL",
    airport_city_name: "San Salvador",
    airport_country_name: "EL SALVADOR",
    airport_country_code: "SV",
    airport_timezone: "-6",
    airport_lat: "13.440947",
    airport_lon: "-89.055728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3114,
    airport_code: "SAM",
    airport_name: "Salamo Arpt",
    airport_city_code: "SAM",
    airport_city_name: "Salamo",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3115,
    airport_code: "SDM",
    airport_name: "Brown Field Municipal",
    airport_city_code: "SAN",
    airport_city_name: "San Diego",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.572272",
    airport_lon: "-116.980161",
    airport_num_airports: 4,
  },
  {
    airport_id: 3116,
    airport_code: "MYF",
    airport_name: "Montgomery Field",
    airport_city_code: "SAN",
    airport_city_name: "San Diego",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "32.4759",
    airport_lon: "117.759",
    airport_num_airports: 4,
  },
  {
    airport_id: 3117,
    airport_code: "SEE",
    airport_name: "Gillespie Field",
    airport_city_code: "SAN",
    airport_city_name: "San Diego",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "32.826222",
    airport_lon: "-116.972444",
    airport_num_airports: 4,
  },
  {
    airport_id: 3118,
    airport_code: "SAN",
    airport_name: "Lindbergh Intl Arpt",
    airport_city_code: "SAN",
    airport_city_name: "San Diego",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "32.733556",
    airport_lon: "-117.189667",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 3119,
    airport_code: "GRU",
    airport_name: "Guarulhos Arpt",
    airport_city_code: "SAO",
    airport_city_name: "Sao Paulo",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.432075",
    airport_lon: "-46.469511",
    airport_num_airports: 3,
  },
  {
    airport_id: 3120,
    airport_code: "CGH",
    airport_name: "Congonhas Arpt",
    airport_city_code: "SAO",
    airport_city_name: "Sao Paulo",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.626692",
    airport_lon: "-46.655375",
    airport_num_airports: 3,
  },
  {
    airport_id: 3121,
    airport_code: "VCP",
    airport_name: "Viracopos Arpt",
    airport_city_code: "SAO",
    airport_city_name: "Sao Paulo",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.0075",
    airport_lon: "-47.134444",
    airport_num_airports: 3,
  },
  {
    airport_id: 3122,
    airport_code: "SAP",
    airport_name: "Ramon Villeda Morales Arpt",
    airport_city_code: "SAP",
    airport_city_name: "San Pedro Sula",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-6",
    airport_lat: "15.452639",
    airport_lon: "-87.923556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3123,
    airport_code: "SAR",
    airport_name: "Sparta Community Airport",
    airport_city_code: "SAR",
    airport_city_name: "Sparta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "38.1489",
    airport_lon: "-89.6987",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3124,
    airport_code: "SSF",
    airport_name: "Stinson Municipal Arpt",
    airport_city_code: "SAT",
    airport_city_name: "San Antonio",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3125,
    airport_code: "SAT",
    airport_name: "San Antonio Intl",
    airport_city_code: "SAT",
    airport_city_name: "San Antonio",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.533694",
    airport_lon: "-98.469778",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3126,
    airport_code: "SAV",
    airport_name: "Savannah Intl Arpt",
    airport_city_code: "SAV",
    airport_city_name: "Savannah",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "32.127583",
    airport_lon: "-81.202139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3127,
    airport_code: "SAY",
    airport_name: "Siena Arpt",
    airport_city_code: "SAY",
    airport_city_name: "Siena",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "43.256286",
    airport_lon: "11.255036",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3128,
    airport_code: "SAZ",
    airport_name: "Sasstown Arpt",
    airport_city_code: "SAZ",
    airport_city_name: "Sasstown",
    airport_country_name: "LIBERIA",
    airport_country_code: "LR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3129,
    airport_code: "SBA",
    airport_name: "Santa Barbara Arpt",
    airport_city_code: "SBA",
    airport_city_name: "Santa Barbara",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.426211",
    airport_lon: "-119.840372",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3130,
    airport_code: "SBH",
    airport_name: "St Jean Arpt",
    airport_city_code: "SBH",
    airport_city_name: "St Barthelemy",
    airport_country_name: "GUADELOUPE",
    airport_country_code: "GP",
    airport_timezone: "-4",
    airport_lat: "17.9023",
    airport_lon: "-62.8324",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3131,
    airport_code: "SBK",
    airport_name: "Tremuson Armor Arpt",
    airport_city_code: "SBK",
    airport_city_name: "St Brieuc",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.537777",
    airport_lon: "-2.854445",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3132,
    airport_code: "SBM",
    airport_name: "Sheboygan Arpt",
    airport_city_code: "SBM",
    airport_city_name: "Sheboygan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3133,
    airport_code: "SBN",
    airport_name: "Michiana Regional Arpt",
    airport_city_code: "SBN",
    airport_city_name: "South Bend",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.708661",
    airport_lon: "-86.31725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3134,
    airport_code: "SBS",
    airport_name: "Steamboat Arpt",
    airport_city_code: "SBS",
    airport_city_name: "Steamboat Springs",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "40.51625",
    airport_lon: "-106.866306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3135,
    airport_code: "SBD",
    airport_name: "San Bernardino Intl Arpt",
    airport_city_code: "SBT",
    airport_city_name: "San Bernardino",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.095352",
    airport_lon: "-117.234872",
    airport_num_airports: 1,
  },
  {
    airport_id: 3136,
    airport_code: "SBU",
    airport_name: "Springbok Arpt",
    airport_city_code: "SBU",
    airport_city_name: "Springbok",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-29.689333",
    airport_lon: "17.939611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3137,
    airport_code: "SBW",
    airport_name: "Sibu Arpt",
    airport_city_code: "SBW",
    airport_city_name: "Sibu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "2.261603",
    airport_lon: "111.985322",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3138,
    airport_code: "SBY",
    airport_name: "Wicomico Regional Arpt",
    airport_city_code: "SBY",
    airport_city_name: "Salisbury Ocean City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.340525",
    airport_lon: "-75.510289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3139,
    airport_code: "SBZ",
    airport_name: "Sibiu Arpt",
    airport_city_code: "SBZ",
    airport_city_name: "Sibiu",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "45.785597",
    airport_lon: "24.091342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3140,
    airport_code: "SCC",
    airport_name: "Prudhoe Bay Deadhorse Arpt",
    airport_city_code: "SCC",
    airport_city_name: "Prudhoe Bay Deadhorse",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "70.19475",
    airport_lon: "-148.465167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3141,
    airport_code: "SCE",
    airport_name: "University Park Arpt",
    airport_city_code: "SCE",
    airport_city_name: "State College",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.849278",
    airport_lon: "-77.848694",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3142,
    airport_code: "SCF",
    airport_name: "Scottsdale Municipal Arpt",
    airport_city_code: "SCF",
    airport_city_name: "Scottsdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3143,
    airport_code: "SCH",
    airport_name: "Schenectady County Arpt",
    airport_city_code: "SCH",
    airport_city_name: "Schenectady",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.852456",
    airport_lon: "-73.928867",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3144,
    airport_code: "SCI",
    airport_name: "Aeropuerto Santo Domingo",
    airport_city_code: "SCI",
    airport_city_name: "San Crystobal",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3145,
    airport_code: "SCK",
    airport_name: "Stockton Metro Arpt",
    airport_city_code: "SCK",
    airport_city_name: "Stockton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.894167",
    airport_lon: "-121.238306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3146,
    airport_code: "ULC",
    airport_name: "Los Cerrillos",
    airport_city_code: "SCL",
    airport_city_name: "Santiago",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3147,
    airport_code: "SCL",
    airport_name: "Arturo Merino Benitez",
    airport_city_code: "SCL",
    airport_city_name: "Santiago",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-33.392975",
    airport_lon: "-70.785803",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3148,
    airport_code: "QFZ",
    airport_name: "Saarbruecken Rail Station",
    airport_city_code: "SCN",
    airport_city_name: "Saarbruecken",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3149,
    airport_code: "SCN",
    airport_name: "Ensheim Arpt",
    airport_city_code: "SCN",
    airport_city_name: "Saarbruecken",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.214553",
    airport_lon: "7.109508",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3150,
    airport_code: "SCO",
    airport_name: "Aktau Arpt",
    airport_city_code: "SCO",
    airport_city_name: "Aktau",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "5",
    airport_lat: "43.513557",
    airport_lon: "51.052817",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3151,
    airport_code: "SCQ",
    airport_name: "Santiago Airport",
    airport_city_code: "SCQ",
    airport_city_name: "Santiago De Compostela",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "42.896333",
    airport_lon: "-8.415144",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3152,
    airport_code: "SCU",
    airport_name: "Antonio Maceo Arpt",
    airport_city_code: "SCU",
    airport_city_name: "Santiago",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "19.969769",
    airport_lon: "-75.835414",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3153,
    airport_code: "SCV",
    airport_name: "Salcea Arpt",
    airport_city_code: "SCV",
    airport_city_name: "Suceava",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "47.6875",
    airport_lon: "26.354056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3154,
    airport_code: "SCW",
    airport_name: "Syktyvkar Arpt",
    airport_city_code: "SCW",
    airport_city_name: "Syktyvkar",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "61.64705",
    airport_lon: "50.84505",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3155,
    airport_code: "SCX",
    airport_name: "Salina Cruz Arpt",
    airport_city_code: "SCX",
    airport_city_name: "Salina Cruz",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "16.2126",
    airport_lon: "-95.2016",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3156,
    airport_code: "SCY",
    airport_name: "San Cristobal Airport",
    airport_city_code: "SCY",
    airport_city_name: "San Cristobal",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-6",
    airport_lat: "-0.910206",
    airport_lon: "-89.61745",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3157,
    airport_code: "SDB",
    airport_name: "Langebaanweg Arpt",
    airport_city_code: "SDB",
    airport_city_name: "Saldanha Bay",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "-32.9688",
    airport_lon: "18.1653",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3158,
    airport_code: "SDE",
    airport_name: "Santiago Des Estero Arpt",
    airport_city_code: "SDE",
    airport_city_name: "Santiago Del Estero",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-27.765617",
    airport_lon: "-64.310122",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3159,
    airport_code: "LOU",
    airport_name: "Bowman Field Arpt",
    airport_city_code: "SDF",
    airport_city_name: "Louisville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.228",
    airport_lon: "-85.663722",
    airport_num_airports: 2,
  },
  {
    airport_id: 3160,
    airport_code: "SDF",
    airport_name: "Louisville International Arpt",
    airport_city_code: "SDF",
    airport_city_name: "Louisville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.174086",
    airport_lon: "-85.736499",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3161,
    airport_code: "SDJ",
    airport_name: "Sendai Arpt",
    airport_city_code: "SDJ",
    airport_city_name: "Sendai",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "38.139722",
    airport_lon: "140.916944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3162,
    airport_code: "SDK",
    airport_name: "Sandakan Arpt",
    airport_city_code: "SDK",
    airport_city_name: "Sandakan",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.900897",
    airport_lon: "118.059486",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3163,
    airport_code: "XXZ",
    airport_name: "Sundsvall Rail Service",
    airport_city_code: "SDL",
    airport_city_name: "Sundsvall",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3164,
    airport_code: "SDL",
    airport_name: "Sundsvall Arpt",
    airport_city_code: "SDL",
    airport_city_name: "Sundsvall",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "62.528125",
    airport_lon: "17.443928",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3165,
    airport_code: "SDN",
    airport_name: "Anda Airport",
    airport_city_code: "SDN",
    airport_city_name: "Sandane",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "61.83",
    airport_lon: "6.10583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3166,
    airport_code: "SDP",
    airport_name: "Sand Point Arpt",
    airport_city_code: "SDP",
    airport_city_name: "Sand Point",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "55.315",
    airport_lon: "-160.523",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3167,
    airport_code: "SDQ",
    airport_name: "Las Americas Arpt",
    airport_city_code: "SDQ",
    airport_city_name: "Santo Domingo",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "18.429664",
    airport_lon: "-69.668925",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3168,
    airport_code: "HEX",
    airport_name: "Herrera Arpt",
    airport_city_code: "SDQ",
    airport_city_name: "Santo Domingo",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-6",
    airport_lat: "18.475",
    airport_lon: "-69.975",
    airport_num_airports: 2,
  },
  {
    airport_id: 3169,
    airport_code: "SDR",
    airport_name: "Santander Airport",
    airport_city_code: "SDR",
    airport_city_name: "Santander",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "43.427064",
    airport_lon: "-3.820006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3170,
    airport_code: "SDX",
    airport_name: "Sedona Arpt",
    airport_city_code: "SDX",
    airport_city_name: "Sedona",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "34.848628",
    airport_lon: "-111.788472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3171,
    airport_code: "SDY",
    airport_name: "Sindey Richland Municipal Arpt",
    airport_city_code: "SDY",
    airport_city_name: "Sidney",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "47.706944",
    airport_lon: "-104.1925",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3172,
    airport_code: "SCS",
    airport_name: "Scatsta Arpt",
    airport_city_code: "SDZ",
    airport_city_name: "Shetland Islands Area",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "60.4328",
    airport_lon: "-1.2961",
    airport_num_airports: 1,
  },
  {
    airport_id: 3173,
    airport_code: "SEA",
    airport_name: "Seattle Tacoma Intl Arpt",
    airport_city_code: "SEA",
    airport_city_name: "Seattle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.449",
    airport_lon: "-122.309306",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 3174,
    airport_code: "BFI",
    airport_name: "Seattle Boeing Field",
    airport_city_code: "SEA",
    airport_city_name: "Seattle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.53",
    airport_lon: "-122.301947",
    airport_num_airports: 4,
  },
  {
    airport_id: 3175,
    airport_code: "KEH",
    airport_name: "Kenmore Air Harbor",
    airport_city_code: "SEA",
    airport_city_name: "Seattle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "47.7548",
    airport_lon: "-122.259",
    airport_num_airports: 4,
  },
  {
    airport_id: 3176,
    airport_code: "LKE",
    airport_name: "Lake Union Seaplane Base",
    airport_city_code: "SEA",
    airport_city_name: "Seattle",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "47.629",
    airport_lon: "-122.339",
    airport_num_airports: 4,
  },
  {
    airport_id: 3177,
    airport_code: "SEF",
    airport_name: "Air Terminal Arpt",
    airport_city_code: "SEF",
    airport_city_name: "Sebring",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3178,
    airport_code: "ICN",
    airport_name: "Incheon Intl Arpt",
    airport_city_code: "SEL",
    airport_city_name: "Seoul",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "37.469075",
    airport_lon: "126.450517",
    airport_num_airports: 2,
  },
  {
    airport_id: 3179,
    airport_code: "GMP",
    airport_name: "Gimpo Intl Arpt",
    airport_city_code: "SEL",
    airport_city_name: "Seoul",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "37.558311",
    airport_lon: "126.790586",
    airport_num_airports: 2,
  },
  {
    airport_id: 3180,
    airport_code: "SEN",
    airport_name: "Southend Municipal Arpt",
    airport_city_code: "SEN",
    airport_city_name: "Southend",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.571389",
    airport_lon: "0.695556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3181,
    airport_code: "SEP",
    airport_name: "Clark Field",
    airport_city_code: "SEP",
    airport_city_name: "Stephenville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3182,
    airport_code: "SEW",
    airport_name: "Siwa Arpt",
    airport_city_code: "SEW",
    airport_city_name: "Siwa",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3183,
    airport_code: "SEY",
    airport_name: "Selibaby Arpt",
    airport_city_code: "SEY",
    airport_city_name: "Selibaby",
    airport_country_name: "MAURITANIA",
    airport_country_code: "MR",
    airport_timezone: "0",
    airport_lat: "15.179692",
    airport_lon: "-12.207272",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3184,
    airport_code: "SEZ",
    airport_name: "Seychelles Intl Arpt",
    airport_city_code: "SEZ",
    airport_city_name: "Mahe Island",
    airport_country_name: "SEYCHELLES",
    airport_country_code: "SC",
    airport_timezone: "4",
    airport_lat: "-4.674342",
    airport_lon: "55.521839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3185,
    airport_code: "SFA",
    airport_name: "El Maou Airport",
    airport_city_code: "SFA",
    airport_city_name: "Sfax",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "34.717953",
    airport_lon: "10.690972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3186,
    airport_code: "SFB",
    airport_name: "Orlando Sanford Arpt",
    airport_city_code: "SFB",
    airport_city_name: "Sanford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "28.777639",
    airport_lon: "-81.237489",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3187,
    airport_code: "SFD",
    airport_name: "Las Flecheras",
    airport_city_code: "SFD",
    airport_city_name: "San Fernando De Apure",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "7.883317",
    airport_lon: "-67.444025",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3188,
    airport_code: "SFE",
    airport_name: "San Fernando Arpt",
    airport_city_code: "SFE",
    airport_city_name: "San Fernando",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "16.595589",
    airport_lon: "120.303219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3189,
    airport_code: "SFG",
    airport_name: "Esperance Airport",
    airport_city_code: "SFG",
    airport_city_name: "St Martin",
    airport_country_name: "NETHERLANDS ANTILLES",
    airport_country_code: "AN",
    airport_timezone: "-4",
    airport_lat: "18.099914",
    airport_lon: "-63.047197",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3190,
    airport_code: "SFH",
    airport_name: "San Felipe Arpt",
    airport_city_code: "SFH",
    airport_city_name: "San Felipe",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-4",
    airport_lat: "10.278728",
    airport_lon: "-68.755211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3191,
    airport_code: "SFJ",
    airport_name: "Sondre Stromfjord Arpt",
    airport_city_code: "SFJ",
    airport_city_name: "Kangerlussuaq",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "67.016969",
    airport_lon: "-50.689325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3192,
    airport_code: "SFL",
    airport_name: "Sao Filipe Arpt",
    airport_city_code: "SFL",
    airport_city_name: "Sao Filipe",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "14.885",
    airport_lon: "-24.48",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3193,
    airport_code: "SFM",
    airport_name: "Sanford Regional Arpt",
    airport_city_code: "SFM",
    airport_city_name: "Sanford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.39383",
    airport_lon: "-70.708",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3194,
    airport_code: "SFN",
    airport_name: "Santa Fe Arpt",
    airport_city_code: "SFN",
    airport_city_name: "Santa Fe",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-31.711666",
    airport_lon: "-60.811668",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3195,
    airport_code: "SFO",
    airport_name: "San Francisco Intl Arpt",
    airport_city_code: "SFO",
    airport_city_name: "San Francisco",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.618972",
    airport_lon: "-122.374889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3196,
    airport_code: "SFQ",
    airport_name: "Sanli Urfa Arpt",
    airport_city_code: "SFQ",
    airport_city_name: "Sanli Urfa",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.094261",
    airport_lon: "38.847103",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3197,
    airport_code: "GNY",
    airport_name: "Guney Anadolu Airport",
    airport_city_code: "SFQ",
    airport_city_name: "Sanli Urfa",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "37.0919",
    airport_lon: "38.8462",
    airport_num_airports: 2,
  },
  {
    airport_id: 3198,
    airport_code: "SFS",
    airport_name: "Subic Bay Intl Arpt",
    airport_city_code: "SFS",
    airport_city_name: "Subic Bay",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "14.7944",
    airport_lon: "120.271",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3199,
    airport_code: "SFT",
    airport_name: "Skelleftea Arpt",
    airport_city_code: "SFT",
    airport_city_name: "Skelleftea",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "64.624772",
    airport_lon: "21.076892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3200,
    airport_code: "SFX",
    airport_name: "San Felix Arpt",
    airport_city_code: "SFX",
    airport_city_name: "San Felix",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3201,
    airport_code: "CEF",
    airport_name: "Westover Metro",
    airport_city_code: "SFY",
    airport_city_name: "Springfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "42.194014",
    airport_lon: "-72.534783",
    airport_num_airports: 1,
  },
  {
    airport_id: 3202,
    airport_code: "SFZ",
    airport_name: "Smithfield Arpt",
    airport_city_code: "SFZ",
    airport_city_name: "Smithfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.920764",
    airport_lon: "-71.491381",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3203,
    airport_code: "SGC",
    airport_name: "Surgut Arpt",
    airport_city_code: "SGC",
    airport_city_name: "Surgut",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "61.343694",
    airport_lon: "73.401842",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3204,
    airport_code: "SGD",
    airport_name: "Sonderborg Arpt",
    airport_city_code: "SGD",
    airport_city_name: "Sonderborg",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "54.964367",
    airport_lon: "9.791731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3205,
    airport_code: "SGE",
    airport_name: "Siegerland Arpt",
    airport_city_code: "SGE",
    airport_city_name: "Siegen",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "50.7077",
    airport_lon: "8.083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3206,
    airport_code: "SGF",
    airport_name: "Springfield Branson Regional Arpt",
    airport_city_code: "SGF",
    airport_city_name: "Springfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.245667",
    airport_lon: "-93.388639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3207,
    airport_code: "SGN",
    airport_name: "Tan Son Nhut Arpt",
    airport_city_code: "SGN",
    airport_city_name: "Ho Chi Minh City",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "10.818797",
    airport_lon: "106.651856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3208,
    airport_code: "SGO",
    airport_name: "St George Arpt",
    airport_city_code: "SGO",
    airport_city_name: "St George",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-28.0497",
    airport_lon: "148.595",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3209,
    airport_code: "SGR",
    airport_name: "Hull Field Arpt",
    airport_city_code: "SGR",
    airport_city_name: "Sugar Land",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.62225",
    airport_lon: "-95.656528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3210,
    airport_code: "SGT",
    airport_name: "Stuttgart Municipal",
    airport_city_code: "SGT",
    airport_city_name: "Stuttgart",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3211,
    airport_code: "SGU",
    airport_name: "Saint George Municipal Arpt",
    airport_city_code: "SGU",
    airport_city_name: "St George",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.090583",
    airport_lon: "-113.593056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3212,
    airport_code: "SGY",
    airport_name: "Skagway Municipal Arpt",
    airport_city_code: "SGY",
    airport_city_name: "Skagway",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.4601",
    airport_lon: "-135.316",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3213,
    airport_code: "SHA",
    airport_name: "Hongqiao International Arpt",
    airport_city_code: "SHA",
    airport_city_name: "Shanghai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.197875",
    airport_lon: "121.336319",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3214,
    airport_code: "PVG",
    airport_name: "Pudong International Arpt",
    airport_city_code: "SHA",
    airport_city_name: "Shanghai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.143378",
    airport_lon: "121.805214",
    airport_num_airports: 2,
  },
  {
    airport_id: 3215,
    airport_code: "SHB",
    airport_name: "Nakashibetsu Arpt",
    airport_city_code: "SHB",
    airport_city_name: "Nakashibetsu",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "43.5775",
    airport_lon: "144.96",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3216,
    airport_code: "SHD",
    airport_name: "Shenandoah Valley Arpt",
    airport_city_code: "SHD",
    airport_city_name: "Staunton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.263889",
    airport_lon: "-78.896389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3217,
    airport_code: "SHE",
    airport_name: "Shenyang Arpt",
    airport_city_code: "SHE",
    airport_city_name: "Shenyang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "41.3824",
    airport_lon: "123.2901",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3218,
    airport_code: "SHO",
    airport_name: "Seolak Arpt",
    airport_city_code: "SHO",
    airport_city_name: "Sokcho",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "38.142614",
    airport_lon: "128.598556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3219,
    airport_code: "SHP",
    airport_name: "Qinhuangdao Arpt",
    airport_city_code: "SHP",
    airport_city_name: "Qinhuangdao",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "39.9681",
    airport_lon: "119.731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3220,
    airport_code: "SHR",
    airport_name: "Sheridan Cty Arpt",
    airport_city_code: "SHR",
    airport_city_name: "Sheridan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.7692",
    airport_lon: "-106.98",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3221,
    airport_code: "SHS",
    airport_name: "Shashi Arpt",
    airport_city_code: "SHS",
    airport_city_name: "Shashi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3222,
    airport_code: "SHT",
    airport_name: "Shepparton",
    airport_city_code: "SHT",
    airport_city_name: "Shepparton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-36.4283",
    airport_lon: "145.3933",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3223,
    airport_code: "SHV",
    airport_name: "Shreveport Regional Arpt",
    airport_city_code: "SHV",
    airport_city_name: "Shreveport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.446629",
    airport_lon: "-93.8256",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3224,
    airport_code: "BAD",
    airport_name: "Barksdale Air Force Base",
    airport_city_code: "SHV",
    airport_city_name: "Shreveport",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.50182",
    airport_lon: "-93.662674",
    airport_num_airports: 2,
  },
  {
    airport_id: 3225,
    airport_code: "SHW",
    airport_name: "Sharurah Arpt",
    airport_city_code: "SHW",
    airport_city_name: "Sharurah",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "17.466875",
    airport_lon: "47.121431",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3226,
    airport_code: "SHY",
    airport_name: "Shinyanga Arpt",
    airport_city_code: "SHY",
    airport_city_name: "Shinyanga",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-3.667",
    airport_lon: "33.417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3227,
    airport_code: "XIY",
    airport_name: "Xianyang Arpt",
    airport_city_code: "SIA",
    airport_city_name: "Xi An",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.447119",
    airport_lon: "108.751592",
    airport_num_airports: 2,
  },
  {
    airport_id: 3228,
    airport_code: "SIA",
    airport_name: "Xiguan Airport",
    airport_city_code: "SIA",
    airport_city_name: "Xi An",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.3767",
    airport_lon: "109.12",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3229,
    airport_code: "NOP",
    airport_name: "Sinop Arpt",
    airport_city_code: "SIC",
    airport_city_name: "Sinop",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "42.0158",
    airport_lon: "35.0664",
    airport_num_airports: 1,
  },
  {
    airport_id: 3230,
    airport_code: "SID",
    airport_name: "Amilcar Cabral Intl Arpt",
    airport_city_code: "SID",
    airport_city_name: "Sal Island",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "16.741389",
    airport_lon: "-22.949444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3231,
    airport_code: "SIG",
    airport_name: "SIG Isla Grande Arpt",
    airport_city_code: "SIG",
    airport_city_name: "Isla Grande",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.456828",
    airport_lon: "-66.098139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3232,
    airport_code: "SIN",
    airport_name: "Changi Intl Arpt",
    airport_city_code: "SIN",
    airport_city_name: "Singapore",
    airport_country_name: "SINGAPORE",
    airport_country_code: "SG",
    airport_timezone: "8",
    airport_lat: "1.350189",
    airport_lon: "103.994433",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3233,
    airport_code: "SIP",
    airport_name: "Simferopol Arpt",
    airport_city_code: "SIP",
    airport_city_name: "Simferopol",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "45.052222",
    airport_lon: "33.975139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3234,
    airport_code: "SIR",
    airport_name: "Sion Arpt",
    airport_city_code: "SIR",
    airport_city_name: "Sion",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "46.219592",
    airport_lon: "7.326764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3235,
    airport_code: "SIT",
    airport_name: "Sitka Airport",
    airport_city_code: "SIT",
    airport_city_name: "Sitka",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "57.047138",
    airport_lon: "-135.361611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3236,
    airport_code: "SIX",
    airport_name: "Singleton Arpt",
    airport_city_code: "SIX",
    airport_city_name: "Singleton",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3237,
    airport_code: "SJC",
    airport_name: "San Jose Intl Arpt",
    airport_city_code: "SJC",
    airport_city_name: "San Jose",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.3626",
    airport_lon: "-121.929022",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 3238,
    airport_code: "ZSM",
    airport_name: "Santa Clara Bus Service",
    airport_city_code: "SJC",
    airport_city_name: "San Jose",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 3239,
    airport_code: "ZJO",
    airport_name: "San Jose Bus Service",
    airport_city_code: "SJC",
    airport_city_name: "San Jose",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 3240,
    airport_code: "RHV",
    airport_name: "Reid Hillview Of Santa Clara County",
    airport_city_code: "SJC",
    airport_city_name: "San Jose",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 3241,
    airport_code: "SJD",
    airport_name: "Los Cabos Arpt",
    airport_city_code: "SJD",
    airport_city_name: "San Jose Cabo",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-7",
    airport_lat: "23.15185",
    airport_lon: "-109.721044",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3242,
    airport_code: "SJF",
    airport_name: "St John Island Arpt",
    airport_city_code: "SJF",
    airport_city_name: "St John",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3243,
    airport_code: "SJJ",
    airport_name: "Butmir Arpt",
    airport_city_code: "SJJ",
    airport_city_name: "Sarajevo",
    airport_country_name: "BOSNIA AND HERZEGOVINA",
    airport_country_code: "BA",
    airport_timezone: "1",
    airport_lat: "43.824583",
    airport_lon: "18.331467",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3244,
    airport_code: "SJK",
    airport_name: "Sao Jose Dos Campos Arpt",
    airport_city_code: "SJK",
    airport_city_name: "Sao Jose Dos Campos",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.228172",
    airport_lon: "-45.862739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3245,
    airport_code: "SJO",
    airport_name: "Juan Santa Maria Intl",
    airport_city_code: "SJO",
    airport_city_name: "San Jose",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "9.993861",
    airport_lon: "-84.208806",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3246,
    airport_code: "SYQ",
    airport_name: "Tobias Bolanos Intl",
    airport_city_code: "SJO",
    airport_city_name: "San Jose",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "9.957053",
    airport_lon: "-84.139797",
    airport_num_airports: 2,
  },
  {
    airport_id: 3247,
    airport_code: "SJP",
    airport_name: "Sao Jose Do Rio Preto",
    airport_city_code: "SJP",
    airport_city_name: "Sao Jose Do Rio Preto",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-20.816567",
    airport_lon: "-49.406511",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3248,
    airport_code: "SJT",
    airport_name: "Mathis Field",
    airport_city_code: "SJT",
    airport_city_name: "San Angelo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.35775",
    airport_lon: "-100.496306",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3249,
    airport_code: "SJU",
    airport_name: "Luiz Munoz Marin Intl",
    airport_city_code: "SJU",
    airport_city_name: "San Juan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.439417",
    airport_lon: "-66.001833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3250,
    airport_code: "SJW",
    airport_name: "Daguocun Arpt",
    airport_city_code: "SJW",
    airport_city_name: "Shijiazhuang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "38.280686",
    airport_lon: "114.6973",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3251,
    airport_code: "SJY",
    airport_name: "Ilmajoki Arpt",
    airport_city_code: "SJY",
    airport_city_name: "Seinajoki",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "62.6921",
    airport_lon: "22.8323",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3252,
    airport_code: "SJZ",
    airport_name: "Sao Jorge Arpt",
    airport_city_code: "SJZ",
    airport_city_name: "Sao Jorge Island",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "38.6655",
    airport_lon: "-28.175817",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3253,
    airport_code: "SKB",
    airport_name: "Robert L Bradshaw Arpt",
    airport_city_code: "SKB",
    airport_city_name: "St Kitts",
    airport_country_name: "SAINT KITTS AND NEVIS",
    airport_country_code: "KN",
    airport_timezone: "-4",
    airport_lat: "17.311194",
    airport_lon: "-62.718667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3254,
    airport_code: "SKD",
    airport_name: "Samarkand Arpt",
    airport_city_code: "SKD",
    airport_city_name: "Samarkand",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "39.700547",
    airport_lon: "66.983829",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3255,
    airport_code: "XKK",
    airport_name: "Larvik Rail Station",
    airport_city_code: "SKE",
    airport_city_name: "Skien",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3256,
    airport_code: "XKP",
    airport_name: "Porsgrunn Rail Station",
    airport_city_code: "SKE",
    airport_city_name: "Skien",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3257,
    airport_code: "SKE",
    airport_name: "Skien Arpt",
    airport_city_code: "SKE",
    airport_city_name: "Skien",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "59.185",
    airport_lon: "9.566944",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3258,
    airport_code: "SKG",
    airport_name: "Makedonia Arpt",
    airport_city_code: "SKG",
    airport_city_name: "Thessaloniki",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "40.519725",
    airport_lon: "22.97095",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3259,
    airport_code: "SKL",
    airport_name: "Broadford Arpt",
    airport_city_code: "SKL",
    airport_city_name: "Isle Of Skye Hebrides Islands",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3260,
    airport_code: "SKN",
    airport_name: "Skagen Arpt",
    airport_city_code: "SKN",
    airport_city_name: "Stokmarknes",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "68.580833",
    airport_lon: "15.026111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3261,
    airport_code: "SKP",
    airport_name: "Skopje Arpt",
    airport_city_code: "SKP",
    airport_city_name: "Skopje",
    airport_country_name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    airport_country_code: "MK",
    airport_timezone: "1",
    airport_lat: "41.961622",
    airport_lon: "21.621381",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3262,
    airport_code: "SKS",
    airport_name: "Skrydstrup Airport",
    airport_city_code: "SKS",
    airport_city_name: "Skrydstrup",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "55.225553",
    airport_lon: "9.263931",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3263,
    airport_code: "SKT",
    airport_name: "Sialkot Arpt",
    airport_city_code: "SKT",
    airport_city_name: "Sialkot",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "32.5356",
    airport_lon: "74.3639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3264,
    airport_code: "SKU",
    airport_name: "Skiros Arpt",
    airport_city_code: "SKU",
    airport_city_name: "Skiros",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "38.967553",
    airport_lon: "24.487228",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3265,
    airport_code: "SKX",
    airport_name: "Saransk Arpt",
    airport_city_code: "SKX",
    airport_city_name: "Saransk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "54.1251",
    airport_lon: "45.2123",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3266,
    airport_code: "SKY",
    airport_name: "Griffing Sandusky Arpt",
    airport_city_code: "SKY",
    airport_city_name: "Sandusky",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.433361",
    airport_lon: "-82.652333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3267,
    airport_code: "SLA",
    airport_name: "General Belgrano Intl Arpt",
    airport_city_code: "SLA",
    airport_city_name: "Salta",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-24.855978",
    airport_lon: "-65.486169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3268,
    airport_code: "SLC",
    airport_name: "Salt Lake City Intl Arpt",
    airport_city_code: "SLC",
    airport_city_name: "Salt Lake City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "40.788389",
    airport_lon: "-111.977772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3269,
    airport_code: "SLD",
    airport_name: "Sliac Arpt",
    airport_city_code: "SLD",
    airport_city_name: "Sliac",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "1",
    airport_lat: "48.637839",
    airport_lon: "19.134108",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3270,
    airport_code: "SLE",
    airport_name: "McNary Field",
    airport_city_code: "SLE",
    airport_city_name: "Salem",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.9095",
    airport_lon: "-123.003",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3271,
    airport_code: "SLK",
    airport_name: "Adirondack Arpt",
    airport_city_code: "SLK",
    airport_city_name: "Saranac Lake",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.3853",
    airport_lon: "-74.2062",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3272,
    airport_code: "SLL",
    airport_name: "Salalah Arpt",
    airport_city_code: "SLL",
    airport_city_name: "Salalah",
    airport_country_name: "OMAN",
    airport_country_code: "OM",
    airport_timezone: "4",
    airport_lat: "17.038719",
    airport_lon: "54.091297",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3273,
    airport_code: "SLM",
    airport_name: "Matacan Arpt",
    airport_city_code: "SLM",
    airport_city_name: "Salamanca",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "40.952117",
    airport_lon: "-5.501986",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3274,
    airport_code: "SLN",
    airport_name: "Salina Municipal",
    airport_city_code: "SLN",
    airport_city_name: "Salina",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.791",
    airport_lon: "-97.6522",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3275,
    airport_code: "SLP",
    airport_name: "San Luis Potosi Municipal Arpt",
    airport_city_code: "SLP",
    airport_city_name: "San Luis Potosi",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "22.254303",
    airport_lon: "-100.930806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3276,
    airport_code: "SLT",
    airport_name: "Salida Arpt",
    airport_city_code: "SLT",
    airport_city_name: "Salida",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3277,
    airport_code: "SLU",
    airport_name: "Vigie Field Arpt",
    airport_city_code: "SLU",
    airport_city_name: "St Lucia",
    airport_country_name: "SAINT LUCIA",
    airport_country_code: "LC",
    airport_timezone: "-4",
    airport_lat: "14.020228",
    airport_lon: "-60.992936",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3278,
    airport_code: "SLV",
    airport_name: "Simla Arpt",
    airport_city_code: "SLV",
    airport_city_name: "Simla",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "31.081803",
    airport_lon: "77.067967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3279,
    airport_code: "SLW",
    airport_name: "Saltillo Arpt",
    airport_city_code: "SLW",
    airport_city_name: "Saltillo",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "25.549497",
    airport_lon: "-100.928669",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3280,
    airport_code: "SLY",
    airport_name: "Salehard Airport",
    airport_city_code: "SLY",
    airport_city_name: "Salekhard",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "66.590753",
    airport_lon: "66.611042",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3281,
    airport_code: "SLZ",
    airport_name: "Mal Cunha Machado",
    airport_city_code: "SLZ",
    airport_city_name: "Sao Luiz",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-2.585361",
    airport_lon: "-44.234139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3282,
    airport_code: "SMA",
    airport_name: "Vilo Do Porto Arpt",
    airport_city_code: "SMA",
    airport_city_name: "Santa Maria",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "36.97139",
    airport_lon: "-25.170639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3283,
    airport_code: "SME",
    airport_name: "Pulaski County Arpt",
    airport_city_code: "SME",
    airport_city_name: "Somerset",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.053611",
    airport_lon: "-84.615556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3284,
    airport_code: "SMI",
    airport_name: "Samos Arpt",
    airport_city_code: "SMI",
    airport_city_name: "Samos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.689999",
    airport_lon: "26.911667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3285,
    airport_code: "SML",
    airport_name: "Stella Maris Arpt",
    airport_city_code: "SML",
    airport_city_name: "Stella Maris",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "23.581444",
    airport_lon: "-75.270475",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3286,
    airport_code: "SMP",
    airport_name: "Stockholm Arpt",
    airport_city_code: "SMP",
    airport_city_name: "Stockholm",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-4.566667",
    airport_lon: "151.96666",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3287,
    airport_code: "SMR",
    airport_name: "Simon Bolivar",
    airport_city_code: "SMR",
    airport_city_name: "Santa Marta",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "11.11965",
    airport_lon: "-74.230647",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3288,
    airport_code: "SMS",
    airport_name: "St Marie Arpt",
    airport_city_code: "SMS",
    airport_city_name: "Saint Marie",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-17.093889",
    airport_lon: "49.815834",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3289,
    airport_code: "SMV",
    airport_name: "Samedan Arpt",
    airport_city_code: "SMV",
    airport_city_name: "St Moritz",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "46.534075",
    airport_lon: "9.884106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3290,
    airport_code: "SMX",
    airport_name: "Santa Maria Public Arpt",
    airport_city_code: "SMX",
    airport_city_name: "Santa Maria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.8989",
    airport_lon: "-120.457",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3291,
    airport_code: "LPC",
    airport_name: "Lompoc Arpt",
    airport_city_code: "SMX",
    airport_city_name: "Santa Maria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3292,
    airport_code: "NYI",
    airport_name: "Sunyani",
    airport_city_code: "SN",
    airport_city_name: "Sunyani",
    airport_country_name: "GHANA",
    airport_country_code: "GH",
    airport_timezone: "0",
    airport_lat: "5.605186",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 3293,
    airport_code: "SNA",
    airport_name: "John Wayne Arpt",
    airport_city_code: "SNA",
    airport_city_name: "Santa Ana",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "33.675667",
    airport_lon: "-117.868222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3294,
    airport_code: "SNE",
    airport_name: "Preguica Arpt",
    airport_city_code: "SNE",
    airport_city_name: "Sao Nicolau",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "16.588356",
    airport_lon: "-24.284656",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3295,
    airport_code: "SNF",
    airport_name: "San Felipe Airport",
    airport_city_code: "SNF",
    airport_city_name: "San Felipe",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-100",
    airport_lat: "10.2833",
    airport_lon: "-68.7333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3296,
    airport_code: "SNH",
    airport_name: "Stanthorpe",
    airport_city_code: "SNH",
    airport_city_name: "Stanthorpe",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-28.62",
    airport_lon: "151.99",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3297,
    airport_code: "SNI",
    airport_name: "R E Murray Arpt",
    airport_city_code: "SNI",
    airport_city_name: "Sinoe",
    airport_country_name: "LIBERIA",
    airport_country_code: "LR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3298,
    airport_code: "SNL",
    airport_name: "Shawnee Airport",
    airport_city_code: "SNL",
    airport_city_name: "Shawnee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3299,
    airport_code: "SNN",
    airport_name: "Shannon Arpt",
    airport_city_code: "SNN",
    airport_city_name: "Shannon",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "52.701978",
    airport_lon: "-8.924817",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3300,
    airport_code: "SNO",
    airport_name: "Sakon Nakhon Arpt",
    airport_city_code: "SNO",
    airport_city_name: "Sakon Nakhon",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "17.195142",
    airport_lon: "104.118625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3301,
    airport_code: "SNQ",
    airport_name: "San Quintin Arpt",
    airport_city_code: "SNQ",
    airport_city_name: "San Quintin",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3302,
    airport_code: "SNR",
    airport_name: "Montoir Airport",
    airport_city_code: "SNR",
    airport_city_name: "St Nazaire",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.312189",
    airport_lon: "-2.149181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3303,
    airport_code: "SNS",
    airport_name: "Salinas Arpt",
    airport_city_code: "SNS",
    airport_city_name: "Salinas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "36.6628",
    airport_lon: "-121.6064",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3304,
    airport_code: "SNU",
    airport_name: "Santa Clara Arpt",
    airport_city_code: "SNU",
    airport_city_name: "Santa Clara",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "22.492192",
    airport_lon: "-79.943611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3305,
    airport_code: "SNY",
    airport_name: "Sidney Municipal",
    airport_city_code: "SNY",
    airport_city_name: "Sidney",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.1013",
    airport_lon: "-102.9853",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3306,
    airport_code: "SOB",
    airport_name: "Saarmelleek Balaton Arpt",
    airport_city_code: "SOB",
    airport_city_name: "Saarmelleek",
    airport_country_name: "HUNGARY",
    airport_country_code: "HU",
    airport_timezone: "1",
    airport_lat: "46.686389",
    airport_lon: "17.159056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3307,
    airport_code: "SOC",
    airport_name: "Adi Sumarno Arpt",
    airport_city_code: "SOC",
    airport_city_name: "Solo",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-7.516089",
    airport_lon: "110.756892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3308,
    airport_code: "SOD",
    airport_name: "Sorocaba Arpt",
    airport_city_code: "SOD",
    airport_city_name: "Sorocaba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.478",
    airport_lon: "-47.49",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3309,
    airport_code: "SOF",
    airport_name: "Sofia Vrazhdebna Arpt",
    airport_city_code: "SOF",
    airport_city_name: "Sofia",
    airport_country_name: "BULGARIA",
    airport_country_code: "BG",
    airport_timezone: "2",
    airport_lat: "42.695194",
    airport_lon: "23.406167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3310,
    airport_code: "SOG",
    airport_name: "Haukasen Arpt",
    airport_city_code: "SOG",
    airport_city_name: "Sogndal",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "61.1561",
    airport_lon: "7.13778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3311,
    airport_code: "SOI",
    airport_name: "South Molle Island Arpt",
    airport_city_code: "SOI",
    airport_city_name: "South Molle",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3312,
    airport_code: "SOM",
    airport_name: "El Tigre Arpt",
    airport_city_code: "SOM",
    airport_city_name: "San Tome",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "8.945147",
    airport_lon: "-64.151083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3313,
    airport_code: "SON",
    airport_name: "Pekoa Arpt",
    airport_city_code: "SON",
    airport_city_name: "Espiritu Santo",
    airport_country_name: "VANUATU",
    airport_country_code: "VU",
    airport_timezone: "11",
    airport_lat: "-15.505033",
    airport_lon: "167.219742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3314,
    airport_code: "SOO",
    airport_name: "Soderhamn Arpt",
    airport_city_code: "SOO",
    airport_city_name: "Soderhamn",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "61.2614",
    airport_lon: "17.0983",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3315,
    airport_code: "SOP",
    airport_name: "Pinehurst Arpt",
    airport_city_code: "SOP",
    airport_city_name: "Pinehurst",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "35.2374",
    airport_lon: "-79.3912",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3316,
    airport_code: "SOQ",
    airport_name: "Jefman Arpt",
    airport_city_code: "SOQ",
    airport_city_name: "Sorong",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-0.926358",
    airport_lon: "131.121194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3317,
    airport_code: "SOU",
    airport_name: "Southampton Intl Arpt",
    airport_city_code: "SOU",
    airport_city_name: "Southampton",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "50.950261",
    airport_lon: "-1.356803",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3318,
    airport_code: "SOV",
    airport_name: "Seldovia Arpt",
    airport_city_code: "SOV",
    airport_city_name: "Seldovia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "59.4424",
    airport_lon: "-151.7041",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3319,
    airport_code: "SOW",
    airport_name: "Show Low Arpt",
    airport_city_code: "SOW",
    airport_city_name: "Show Low",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "34.265556",
    airport_lon: "-110.005556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3320,
    airport_code: "SOY",
    airport_name: "Stronsay Arpt",
    airport_city_code: "SOY",
    airport_city_name: "Stronsay",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.1553",
    airport_lon: "-2.64139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3321,
    airport_code: "SPC",
    airport_name: "La Palma Arpt",
    airport_city_code: "SPC",
    airport_city_name: "Santa Cruz",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "28.626478",
    airport_lon: "-17.755611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3322,
    airport_code: "SPF",
    airport_name: "Black Hills Clyde Ice Field",
    airport_city_code: "SPF",
    airport_city_name: "Spearfish",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.481141",
    airport_lon: "-103.786005",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3323,
    airport_code: "SPG",
    airport_name: "Whitted Arpt",
    airport_city_code: "SPG",
    airport_city_name: "St Petersburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "27.765111",
    airport_lon: "-82.626972",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3324,
    airport_code: "SPI",
    airport_name: "Capital Airport",
    airport_city_code: "SPI",
    airport_city_name: "Springfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.8441",
    airport_lon: "-89.677889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3325,
    airport_code: "SPJ",
    airport_name: "Sparta Arpt",
    airport_city_code: "SPJ",
    airport_city_name: "Sparta",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3326,
    airport_code: "OKD",
    airport_name: "Okadama Arpt",
    airport_city_code: "SPK",
    airport_city_name: "Sapporo",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "43.1161",
    airport_lon: "141.38",
    airport_num_airports: 2,
  },
  {
    airport_id: 3327,
    airport_code: "CTS",
    airport_name: "Chitose Arpt",
    airport_city_code: "SPK",
    airport_city_name: "Sapporo",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "42.7752",
    airport_lon: "141.692283",
    airport_num_airports: 2,
  },
  {
    airport_id: 3328,
    airport_code: "SPM",
    airport_name: "Spangdahlem Air Base",
    airport_city_code: "SPM",
    airport_city_name: "Spangdahlem",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.972667",
    airport_lon: "6.6925",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3329,
    airport_code: "SPN",
    airport_name: "Saipan Intl",
    airport_city_code: "SPN",
    airport_city_name: "Saipan",
    airport_country_name: "NORTHERN MARIANA ISLANDS",
    airport_country_code: "MP",
    airport_timezone: "10",
    airport_lat: "15.119003",
    airport_lon: "145.729356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3330,
    airport_code: "SPR",
    airport_name: "San Pedro Arpt",
    airport_city_code: "SPR",
    airport_city_name: "San Pedro",
    airport_country_name: "BELIZE",
    airport_country_code: "BZ",
    airport_timezone: "-6",
    airport_lat: "17.913936",
    airport_lon: "-87.971075",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3331,
    airport_code: "SPS",
    airport_name: "Wichita Falls Municipal Arpt",
    airport_city_code: "SPS",
    airport_city_name: "Wichita Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.988797",
    airport_lon: "-98.491894",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3332,
    airport_code: "SPU",
    airport_name: "Split Arpt",
    airport_city_code: "SPU",
    airport_city_name: "Split",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "43.538944",
    airport_lon: "16.297964",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3333,
    airport_code: "SPW",
    airport_name: "Spencer Municipal",
    airport_city_code: "SPW",
    airport_city_name: "Spencer",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "43.165527",
    airport_lon: "-95.202805",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3334,
    airport_code: "SPZ",
    airport_name: "Springdale Municipal Arpt",
    airport_city_code: "SPZ",
    airport_city_name: "Springdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "39.403028",
    airport_lon: "-119.251194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3335,
    airport_code: "SQA",
    airport_name: "Santa Ynez Arpt",
    airport_city_code: "SQA",
    airport_city_name: "Santa Ynez",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3336,
    airport_code: "SQH",
    airport_name: "Na San Arpt",
    airport_city_code: "SQH",
    airport_city_name: "Son La",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "21.217",
    airport_lon: "104.033",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3337,
    airport_code: "SQI",
    airport_name: "Whiteside Cty Municipal",
    airport_city_code: "SQI",
    airport_city_name: "Sterling",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3338,
    airport_code: "SQL",
    airport_name: "San Carlos Arpt",
    airport_city_code: "SQL",
    airport_city_name: "San Carlos",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "37.511944",
    airport_lon: "-122.249444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3339,
    airport_code: "SQO",
    airport_name: "Gunnarn Arpt",
    airport_city_code: "SQO",
    airport_city_name: "Storuman",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "64.960894",
    airport_lon: "17.696583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3340,
    airport_code: "SRE",
    airport_name: "Sucre Arpt",
    airport_city_code: "SRE",
    airport_city_name: "Sucre",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-19.007083",
    airport_lon: "-65.288747",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3341,
    airport_code: "SRG",
    airport_name: "Achmad Uani Arpt",
    airport_city_code: "SRG",
    airport_city_name: "Semarang",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-6.971447",
    airport_lon: "110.374122",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3342,
    airport_code: "SRL",
    airport_name: "Santa Rosalia Arpt",
    airport_city_code: "SRL",
    airport_city_name: "Santa Rosalia",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3343,
    airport_code: "SRN",
    airport_name: "Strahan Arpt",
    airport_city_code: "SRN",
    airport_city_name: "Strahan",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-42.155",
    airport_lon: "145.292",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3344,
    airport_code: "SRP",
    airport_name: "Stord Arpt",
    airport_city_code: "SRP",
    airport_city_name: "Stord",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "59.791925",
    airport_lon: "5.34085",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3345,
    airport_code: "SRQ",
    airport_name: "Sarasota Bradenton Arpt",
    airport_city_code: "SRQ",
    airport_city_name: "Sarasota",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "27.395444",
    airport_lon: "-82.554389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3346,
    airport_code: "SRU",
    airport_name: "Skypark Arpt",
    airport_city_code: "SRU",
    airport_city_name: "Santa Cruz",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3347,
    airport_code: "SRZ",
    airport_name: "El Trompillo Arpt",
    airport_city_code: "SRZ",
    airport_city_name: "Santa Cruz",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-17.8",
    airport_lon: "-63.166667",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3348,
    airport_code: "VVI",
    airport_name: "Viru Viru Intl Arpt",
    airport_city_code: "SRZ",
    airport_city_name: "Santa Cruz",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-17.644756",
    airport_lon: "-63.135364",
    airport_num_airports: 2,
  },
  {
    airport_id: 3349,
    airport_code: "SSA",
    airport_name: "Luis E Magalhaes Arpt",
    airport_city_code: "SSA",
    airport_city_name: "Salvador",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-12.910994",
    airport_lon: "-38.331044",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3350,
    airport_code: "SSG",
    airport_name: "Santa Isabel Arpt",
    airport_city_code: "SSG",
    airport_city_name: "Malabo",
    airport_country_name: "EQUATORIAL GUINEA",
    airport_country_code: "GQ",
    airport_timezone: "1",
    airport_lat: "3.755267",
    airport_lon: "8.708717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3351,
    airport_code: "SSH",
    airport_name: "Ophira Arpt",
    airport_city_code: "SSH",
    airport_city_name: "Sharm El Sheik",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "27.977286",
    airport_lon: "34.39495",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3352,
    airport_code: "SSI",
    airport_name: "McKinnon Arpt",
    airport_city_code: "SSI",
    airport_city_name: "St Simons Is",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "31.1518",
    airport_lon: "-81.3913",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3353,
    airport_code: "SSJ",
    airport_name: "Stokka Arpt",
    airport_city_code: "SSJ",
    airport_city_name: "Sandnessjoen",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "65.956828",
    airport_lon: "12.468944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3354,
    airport_code: "CIU",
    airport_name: "Chippewa Cnty Intl",
    airport_city_code: "SSM",
    airport_city_name: "Sault Ste Marie",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "46.2508",
    airport_lon: "-84.4724",
    airport_num_airports: 1,
  },
  {
    airport_id: 3355,
    airport_code: "SSQ",
    airport_name: "La Sarre Rail Station",
    airport_city_code: "SSQ",
    airport_city_name: "La Sarre",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3356,
    airport_code: "SZF",
    airport_name: "Carsamba Arpt",
    airport_city_code: "SSX",
    airport_city_name: "Samsun",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "41.2545",
    airport_lon: "36.5671",
    airport_num_airports: 1,
  },
  {
    airport_id: 3357,
    airport_code: "SSZ",
    airport_name: "Santos Arpt",
    airport_city_code: "SSZ",
    airport_city_name: "Santos",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-23.925206",
    airport_lon: "-46.2875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3358,
    airport_code: "STB",
    airport_name: "Las Delicias Arpt",
    airport_city_code: "STB",
    airport_city_name: "Santa Barbara",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "8.974425",
    airport_lon: "-71.943014",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3359,
    airport_code: "STC",
    airport_name: "Saint Cloud Municipal",
    airport_city_code: "STC",
    airport_city_name: "Saint Cloud",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "45.546556",
    airport_lon: "-94.059889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3360,
    airport_code: "STD",
    airport_name: "Mayor Humberto Vivas Guerrero Arpt",
    airport_city_code: "STD",
    airport_city_name: "Santo Domingo",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "7.565111",
    airport_lon: "-72.035125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3361,
    airport_code: "STE",
    airport_name: "Stevens Point Municipal",
    airport_city_code: "STE",
    airport_city_name: "Stevens Point",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3362,
    airport_code: "STI",
    airport_name: "Santiago Municipal",
    airport_city_code: "STI",
    airport_city_name: "Santiago",
    airport_country_name: "DOMINICAN REPUBLIC",
    airport_country_code: "DO",
    airport_timezone: "-4",
    airport_lat: "19.406092",
    airport_lon: "-70.604689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3363,
    airport_code: "STJ",
    airport_name: "Rosecrans Memorial Arpt",
    airport_city_code: "STJ",
    airport_city_name: "St Joseph",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.771944",
    airport_lon: "-94.909706",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3364,
    airport_code: "SUS",
    airport_name: "Spirit Of St Louis Arpt",
    airport_city_code: "STL",
    airport_city_name: "St Louis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "38.662119",
    airport_lon: "-90.652044",
    airport_num_airports: 3,
  },
  {
    airport_id: 3365,
    airport_code: "CPS",
    airport_name: "East St Louis",
    airport_city_code: "STL",
    airport_city_name: "St Louis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.570724",
    airport_lon: "-90.156221",
    airport_num_airports: 3,
  },
  {
    airport_id: 3366,
    airport_code: "STL",
    airport_name: "Lambert St Louis Intl",
    airport_city_code: "STL",
    airport_city_name: "St Louis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.748697",
    airport_lon: "-90.370028",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3367,
    airport_code: "STM",
    airport_name: "Eduardo Gomes Arpt",
    airport_city_code: "STM",
    airport_city_name: "Santarem",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-2.422431",
    airport_lon: "-54.792789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3368,
    airport_code: "XEW",
    airport_name: "Flemingsberg Rail Station",
    airport_city_code: "STO",
    airport_city_name: "Stockholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 6,
  },
  {
    airport_id: 3369,
    airport_code: "ARN",
    airport_name: "Arlanda Arpt",
    airport_city_code: "STO",
    airport_city_name: "Stockholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "59.651944",
    airport_lon: "17.918611",
    airport_num_airports: 6,
  },
  {
    airport_id: 3370,
    airport_code: "XEV",
    airport_name: "Stockholm Rail Station",
    airport_city_code: "STO",
    airport_city_name: "Stockholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 6,
  },
  {
    airport_id: 3371,
    airport_code: "NYO",
    airport_name: "Skavsta Airport",
    airport_city_code: "STO",
    airport_city_name: "Stockholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "58.788636",
    airport_lon: "16.912189",
    airport_num_airports: 6,
  },
  {
    airport_id: 3372,
    airport_code: "VST",
    airport_name: "Hasslo Airport",
    airport_city_code: "STO",
    airport_city_name: "Stockholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "59.589444",
    airport_lon: "16.633611",
    airport_num_airports: 6,
  },
  {
    airport_id: 3373,
    airport_code: "BMA",
    airport_name: "Bromma Airport",
    airport_city_code: "STO",
    airport_city_name: "Stockholm",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "59.354372",
    airport_lon: "17.94165",
    airport_num_airports: 6,
  },
  {
    airport_id: 3374,
    airport_code: "STP",
    airport_name: "Downtown St Paul Arpt",
    airport_city_code: "STP",
    airport_city_name: "St Paul",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "0",
    airport_lat: "51.53",
    airport_lon: "-0.125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3375,
    airport_code: "STR",
    airport_name: "Stuttgart Arpt",
    airport_city_code: "STR",
    airport_city_name: "Stuttgart",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.689878",
    airport_lon: "9.221964",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3376,
    airport_code: "ZWS",
    airport_name: "Stuttgart Railway Service",
    airport_city_code: "STR",
    airport_city_name: "Stuttgart",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.783611",
    airport_lon: "9.181667",
    airport_num_airports: 2,
  },
  {
    airport_id: 3377,
    airport_code: "STS",
    airport_name: "Sonoma Cty Arpt",
    airport_city_code: "STS",
    airport_city_name: "Santa Rosa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "38.508978",
    airport_lon: "-122.81288",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3378,
    airport_code: "STT",
    airport_name: "Cyril E King Arpt",
    airport_city_code: "STT",
    airport_city_name: "St Thomas",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.337306",
    airport_lon: "-64.973361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3379,
    airport_code: "STW",
    airport_name: "Stavropol Arpt",
    airport_city_code: "STW",
    airport_city_name: "Stavropol",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "45.109165",
    airport_lon: "42.112778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3380,
    airport_code: "STX",
    airport_name: "Alexander Hamilton Arpt",
    airport_city_code: "STX",
    airport_city_name: "St Croix",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "17.701889",
    airport_lon: "-64.798556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3381,
    airport_code: "STY",
    airport_name: "Salto Arpt",
    airport_city_code: "STY",
    airport_city_name: "Salto",
    airport_country_name: "URUGUAY",
    airport_country_code: "UY",
    airport_timezone: "-3",
    airport_lat: "-31.438481",
    airport_lon: "-57.985294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3382,
    airport_code: "SUA",
    airport_name: "Witham Field",
    airport_city_code: "SUA",
    airport_city_name: "Stuart",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "27.1817",
    airport_lon: "-80.221294",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3383,
    airport_code: "SUB",
    airport_name: "Juanda Arpt",
    airport_city_code: "SUB",
    airport_city_name: "Surabaya",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-7.379831",
    airport_lon: "112.786858",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3384,
    airport_code: "SUE",
    airport_name: "Door Country Arpt",
    airport_city_code: "SUE",
    airport_city_name: "Sturgeon Bay",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "44.843667",
    airport_lon: "-87.421556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3385,
    airport_code: "SUF",
    airport_name: "S Eufemia Arpt",
    airport_city_code: "SUF",
    airport_city_name: "Lamezia Terme",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "38.905394",
    airport_lon: "16.242269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3386,
    airport_code: "SUH",
    airport_name: "Sur Arpt",
    airport_city_code: "SUH",
    airport_city_name: "Sur Om",
    airport_country_name: "OMAN",
    airport_country_code: "OM",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3387,
    airport_code: "SUM",
    airport_name: "Sumter Municipal Arpt",
    airport_city_code: "SUM",
    airport_city_name: "Sumter",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3388,
    airport_code: "SUN",
    airport_name: "Friedman Memorial",
    airport_city_code: "SUN",
    airport_city_name: "Sun Valley",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.504444",
    airport_lon: "-114.296194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3389,
    airport_code: "SUU",
    airport_name: "Travis AFB",
    airport_city_code: "SUU",
    airport_city_name: "Fairfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "38.262692",
    airport_lon: "-121.927464",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3390,
    airport_code: "SUV",
    airport_name: "Nausori Airport",
    airport_city_code: "SUV",
    airport_city_name: "Suva",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "12",
    airport_lat: "-18.043267",
    airport_lon: "178.559228",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3391,
    airport_code: "SUW",
    airport_name: "Richard I Bong Arpt",
    airport_city_code: "SUW",
    airport_city_name: "Superior",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3392,
    airport_code: "SUX",
    airport_name: "Sioux Gateway Arpt",
    airport_city_code: "SUX",
    airport_city_name: "Sioux City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.402603",
    airport_lon: "-96.384367",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3393,
    airport_code: "SVB",
    airport_name: "Sambava Arpt",
    airport_city_code: "SVB",
    airport_city_name: "Sambava",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-14.278611",
    airport_lon: "50.174721",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3394,
    airport_code: "SVC",
    airport_name: "Grant County Airport",
    airport_city_code: "SVC",
    airport_city_name: "Silver City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.6365",
    airport_lon: "-108.156",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3395,
    airport_code: "SVD",
    airport_name: "Arnos Vale Arpt",
    airport_city_code: "SVD",
    airport_city_name: "St Vincent",
    airport_country_name: "SAINT VINCENT AND THE GRENADINES",
    airport_country_code: "VC",
    airport_timezone: "-4",
    airport_lat: "13.144306",
    airport_lon: "-61.210861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3396,
    airport_code: "ZWC",
    airport_name: "Stavanger Rail Station",
    airport_city_code: "SVG",
    airport_city_name: "Stavanger",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 3397,
    airport_code: "XOQ",
    airport_name: "Sira Rail Station",
    airport_city_code: "SVG",
    airport_city_name: "Stavanger",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 3398,
    airport_code: "XKC",
    airport_name: "Sandnes Rail Station",
    airport_city_code: "SVG",
    airport_city_name: "Stavanger",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 3399,
    airport_code: "SVG",
    airport_name: "Sola Airport",
    airport_city_code: "SVG",
    airport_city_name: "Stavanger",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "58.876778",
    airport_lon: "5.637856",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 3400,
    airport_code: "SVH",
    airport_name: "Statesville Municipal Arpt",
    airport_city_code: "SVH",
    airport_city_name: "Statesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3401,
    airport_code: "SVJ",
    airport_name: "Helle",
    airport_city_code: "SVJ",
    airport_city_name: "Svolvaer",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "68.2433",
    airport_lon: "14.6692",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3402,
    airport_code: "SVL",
    airport_name: "Savonlinna Arpt",
    airport_city_code: "SVL",
    airport_city_name: "Savonlinna",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "61.943064",
    airport_lon: "28.945136",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3403,
    airport_code: "SVQ",
    airport_name: "San Pablo Arpt",
    airport_city_code: "SVQ",
    airport_city_name: "Sevilla",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "37.418",
    airport_lon: "-5.893106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3404,
    airport_code: "SVU",
    airport_name: "Savusavu Airport",
    airport_city_code: "SVU",
    airport_city_name: "Savusavu",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "12",
    airport_lat: "-16.8028",
    airport_lon: "179.341",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3405,
    airport_code: "SVX",
    airport_name: "Koltsovo Arpt",
    airport_city_code: "SVX",
    airport_city_name: "Ekaterinburg",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "56.743108",
    airport_lon: "60.802728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3406,
    airport_code: "SVZ",
    airport_name: "San Antonio Arpt",
    airport_city_code: "SVZ",
    airport_city_name: "San Antonio",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "7.840831",
    airport_lon: "-72.439742",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3407,
    airport_code: "SWA",
    airport_name: "Shantou Airport",
    airport_city_code: "SWA",
    airport_city_name: "Shantou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "23.4",
    airport_lon: "116.683",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3408,
    airport_code: "SWD",
    airport_name: "Seward Airport",
    airport_city_code: "SWD",
    airport_city_name: "Seward",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "60.126938",
    airport_lon: "-149.418812",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3409,
    airport_code: "SWF",
    airport_name: "Stewart Airport",
    airport_city_code: "SWF",
    airport_city_name: "Newburgh",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.504094",
    airport_lon: "-74.104839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3410,
    airport_code: "SWH",
    airport_name: "Swan Hill Arpt",
    airport_city_code: "SWH",
    airport_city_name: "Swan Hill",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-35.3772",
    airport_lon: "143.5322",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3411,
    airport_code: "SWI",
    airport_name: "Swindon Rail Station",
    airport_city_code: "SWI",
    airport_city_name: "Swindon",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3412,
    airport_code: "XWS",
    airport_name: "Swindon Rail Station",
    airport_city_code: "SWI",
    airport_city_name: "Swindon",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3413,
    airport_code: "SWO",
    airport_name: "Searcy Fld",
    airport_city_code: "SWO",
    airport_city_name: "Stillwater",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3414,
    airport_code: "SWS",
    airport_name: "Fairwood Comm",
    airport_city_code: "SWS",
    airport_city_name: "Swansea",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "51.605333",
    airport_lon: "-4.067833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3415,
    airport_code: "SWT",
    airport_name: "Strezhevoy Airport",
    airport_city_code: "SWT",
    airport_city_name: "Strezhevoy Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "7",
    airport_lat: "60.716667",
    airport_lon: "77.65",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3416,
    airport_code: "SWU",
    airport_name: "Su Won Arpt",
    airport_city_code: "SWU",
    airport_city_name: "Su Won",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3417,
    airport_code: "SXB",
    airport_name: "Enzheim Arpt",
    airport_city_code: "SXB",
    airport_city_name: "Strasbourg",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.538319",
    airport_lon: "7.628233",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3418,
    airport_code: "XER",
    airport_name: "Strasbourg Bus Service",
    airport_city_code: "SXB",
    airport_city_name: "Strasbourg",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3419,
    airport_code: "SXE",
    airport_name: "Sale Arpt",
    airport_city_code: "SXE",
    airport_city_name: "Sale",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-38.0983",
    airport_lon: "147.15",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3420,
    airport_code: "SXL",
    airport_name: "Collooney",
    airport_city_code: "SXL",
    airport_city_name: "Sligo",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "54.280214",
    airport_lon: "-8.599206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3421,
    airport_code: "SXM",
    airport_name: "Princ Juliana Arpt",
    airport_city_code: "SXM",
    airport_city_name: "St Maarten",
    airport_country_name: "NETHERLANDS ANTILLES",
    airport_country_code: "AN",
    airport_timezone: "-4",
    airport_lat: "18.040953",
    airport_lon: "-63.1089",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3422,
    airport_code: "SXR",
    airport_name: "Srinagar Arpt",
    airport_city_code: "SXR",
    airport_city_name: "Srinagar",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "33.987139",
    airport_lon: "74.77425",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3423,
    airport_code: "SXZ",
    airport_name: "Siirt Airport",
    airport_city_code: "SXZ",
    airport_city_name: "Siirt Airport",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3424,
    airport_code: "RSE",
    airport_name: "Au Rose Bay Arpt",
    airport_city_code: "SYD",
    airport_city_name: "Sydney",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3425,
    airport_code: "SYD",
    airport_name: "Sydney Kingsford Smith Arpt",
    airport_city_code: "SYD",
    airport_city_name: "Sydney",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-33.946111",
    airport_lon: "151.177222",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3426,
    airport_code: "LBH",
    airport_name: "Palm Beach Arpt",
    airport_city_code: "SYD",
    airport_city_name: "Sydney",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3427,
    airport_code: "SYO",
    airport_name: "Shonai Arpt",
    airport_city_code: "SYO",
    airport_city_name: "Shonai",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "38.812222",
    airport_lon: "139.787222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3428,
    airport_code: "SYR",
    airport_name: "Hancock Intl",
    airport_city_code: "SYR",
    airport_city_name: "Syracuse",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "43.111187",
    airport_lon: "-76.106311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3429,
    airport_code: "SYX",
    airport_name: "Sanya Arpt",
    airport_city_code: "SYX",
    airport_city_name: "Sanya",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "18.302897",
    airport_lon: "109.412272",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3430,
    airport_code: "SYY",
    airport_name: "Stornoway Arpt",
    airport_city_code: "SYY",
    airport_city_name: "Stornoway",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "58.215556",
    airport_lon: "-6.331111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3431,
    airport_code: "SYZ",
    airport_name: "Shiraz Arpt",
    airport_city_code: "SYZ",
    airport_city_name: "Shiraz",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "29.539242",
    airport_lon: "52.589786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3432,
    airport_code: "SZC",
    airport_name: "Guanacaste Arpt",
    airport_city_code: "SZC",
    airport_city_name: "Santa Cruz",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3433,
    airport_code: "SZD",
    airport_name: "Sheffield City Arpt",
    airport_city_code: "SZD",
    airport_city_name: "Sheffield",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "53.3943",
    airport_lon: "-1.3885",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3434,
    airport_code: "SZG",
    airport_name: "W A Mozart Arpt",
    airport_city_code: "SZG",
    airport_city_name: "Salzburg",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "1",
    airport_lat: "47.793304",
    airport_lon: "13.004333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3435,
    airport_code: "SZJ",
    airport_name: "Siguanea Arpt",
    airport_city_code: "SZJ",
    airport_city_name: "Siguanea",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-100",
    airport_lat: "21.6333",
    airport_lon: "-82.95",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3436,
    airport_code: "SZK",
    airport_name: "Skukuza Arpt",
    airport_city_code: "SZK",
    airport_city_name: "Skukuza",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-24.960944",
    airport_lon: "31.588731",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3437,
    airport_code: "SZO",
    airport_name: "Shanzhou Arpt",
    airport_city_code: "SZO",
    airport_city_name: "Shanzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3438,
    airport_code: "SZP",
    airport_name: "Santa Paula Arpt",
    airport_city_code: "SZP",
    airport_city_name: "Santa Paula",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3439,
    airport_code: "SZR",
    airport_name: "Stara Zagora Arpt",
    airport_city_code: "SZR",
    airport_city_name: "Stara Zagora",
    airport_country_name: "BULGARIA",
    airport_country_code: "BG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3440,
    airport_code: "SZT",
    airport_name: "San Cristobal Arpt",
    airport_city_code: "SZT",
    airport_city_name: "San Cristobal De Las Casas",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "16.6904",
    airport_lon: "-92.5303",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3441,
    airport_code: "SZV",
    airport_name: "Suzhou Arpt",
    airport_city_code: "SZV",
    airport_city_name: "Suzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3442,
    airport_code: "SZX",
    airport_name: "Shenzhen Arpt",
    airport_city_code: "SZX",
    airport_city_name: "Shenzhen",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "22.639258",
    airport_lon: "113.810664",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3443,
    airport_code: "SZY",
    airport_name: "Mazury Arpt",
    airport_city_code: "SZY",
    airport_city_name: "Szymany",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "-100",
    airport_lat: "53.4819",
    airport_lon: "20.9377",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3444,
    airport_code: "SZZ",
    airport_name: "Goleniow Arpt",
    airport_city_code: "SZZ",
    airport_city_name: "Szczecin",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "53.584731",
    airport_lon: "14.902206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3445,
    airport_code: "TAB",
    airport_name: "Crown Point Arpt",
    airport_city_code: "TAB",
    airport_city_name: "Tobago",
    airport_country_name: "TRINIDAD AND TOBAGO",
    airport_country_code: "TT",
    airport_timezone: "-4",
    airport_lat: "11.149658",
    airport_lon: "-60.832194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3446,
    airport_code: "TAC",
    airport_name: "D Z Romualdez Arpt",
    airport_city_code: "TAC",
    airport_city_name: "Tacloban",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "11.227628",
    airport_lon: "125.027758",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3447,
    airport_code: "TAE",
    airport_name: "Daegu Arpt",
    airport_city_code: "TAE",
    airport_city_name: "Daegu",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.894108",
    airport_lon: "128.658856",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3448,
    airport_code: "TAG",
    airport_name: "Tagbilaran Arpt",
    airport_city_code: "TAG",
    airport_city_name: "Tagbilaran",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "9.664081",
    airport_lon: "123.853247",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3449,
    airport_code: "TAH",
    airport_name: "Tanna Arpt",
    airport_city_code: "TAH",
    airport_city_name: "Tanna",
    airport_country_name: "VANUATU",
    airport_country_code: "VU",
    airport_timezone: "11",
    airport_lat: "-19.455198",
    airport_lon: "169.22394",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3450,
    airport_code: "TAK",
    airport_name: "Takamatsu Arpt",
    airport_city_code: "TAK",
    airport_city_name: "Takamatsu",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.214167",
    airport_lon: "134.015556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3451,
    airport_code: "TAL",
    airport_name: "Ralph Calhoun Arpt",
    airport_city_code: "TAL",
    airport_city_name: "Tanana",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "65.179556",
    airport_lon: "-152.075833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3452,
    airport_code: "TAM",
    airport_name: "General F Javier Mina",
    airport_city_code: "TAM",
    airport_city_name: "Tampico",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "22.29645",
    airport_lon: "-97.865931",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3453,
    airport_code: "TAO",
    airport_name: "Liuting Arpt",
    airport_city_code: "TAO",
    airport_city_name: "Qingdao",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "36.266108",
    airport_lon: "120.374436",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3454,
    airport_code: "TAP",
    airport_name: "Tapachula Arpt",
    airport_city_code: "TAP",
    airport_city_name: "Tapachula",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "14.794339",
    airport_lon: "-92.370025",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3455,
    airport_code: "TAR",
    airport_name: "M A Grottag Arpt",
    airport_city_code: "TAR",
    airport_city_name: "Taranto",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "40.517514",
    airport_lon: "17.403212",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3456,
    airport_code: "TAS",
    airport_name: "Vostochny Arpt",
    airport_city_code: "TAS",
    airport_city_name: "Tashkent",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "41.257861",
    airport_lon: "69.281186",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3457,
    airport_code: "TAT",
    airport_name: "Poprad Tatry Arpt",
    airport_city_code: "TAT",
    airport_city_name: "Poprad Tatry",
    airport_country_name: "SLOVAKIA",
    airport_country_code: "SK",
    airport_timezone: "1",
    airport_lat: "49.073594",
    airport_lon: "20.241142",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3458,
    airport_code: "TAY",
    airport_name: "Tartu Arpt",
    airport_city_code: "TAY",
    airport_city_name: "Tartu",
    airport_country_name: "ESTONIA",
    airport_country_code: "EE",
    airport_timezone: "2",
    airport_lat: "58.307461",
    airport_lon: "26.690428",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3459,
    airport_code: "TAZ",
    airport_name: "Dashoguz Arpt",
    airport_city_code: "TAZ",
    airport_city_name: "Dashoguz",
    airport_country_name: "TURKMENISTAN",
    airport_country_code: "TM",
    airport_timezone: "5",
    airport_lat: "41.764722",
    airport_lon: "59.833056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3460,
    airport_code: "TBB",
    airport_name: "Tuy Hoa Arpt",
    airport_city_code: "TBB",
    airport_city_name: "Tuy Hoa",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "13.04955",
    airport_lon: "109.333706",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3461,
    airport_code: "TBG",
    airport_name: "Tabubil Arpt",
    airport_city_code: "TBG",
    airport_city_name: "Tububil",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-5.27861",
    airport_lon: "141.226",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3462,
    airport_code: "TBJ",
    airport_name: "Tabarka Arpt",
    airport_city_code: "TBJ",
    airport_city_name: "Tabarka",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "36.978333",
    airport_lon: "8.876389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3463,
    airport_code: "TBN",
    airport_name: "Forney Field",
    airport_city_code: "TBN",
    airport_city_name: "Ft Leonard Wood",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "37.741631",
    airport_lon: "-92.140736",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3464,
    airport_code: "TBO",
    airport_name: "Tabora Arpt",
    airport_city_code: "TBO",
    airport_city_name: "Tabora",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-5.07639",
    airport_lon: "32.8333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3465,
    airport_code: "TBP",
    airport_name: "Tumbes Arpt",
    airport_city_code: "TBP",
    airport_city_name: "Tumbes",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-3.552528",
    airport_lon: "-80.381356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3466,
    airport_code: "TBS",
    airport_name: "Novo Alexeyevka Arpt",
    airport_city_code: "TBS",
    airport_city_name: "Tbilisi",
    airport_country_name: "GEORGIA",
    airport_country_code: "GE",
    airport_timezone: "4",
    airport_lat: "41.669167",
    airport_lon: "44.954722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3467,
    airport_code: "TBT",
    airport_name: "Tabatinga Intl Arpt",
    airport_city_code: "TBT",
    airport_city_name: "Tabatinga",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-4.255669",
    airport_lon: "-69.935828",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3468,
    airport_code: "TBU",
    airport_name: "Tongatapu Intl",
    airport_city_code: "TBU",
    airport_city_name: "Nuku Alofa",
    airport_country_name: "TONGA",
    airport_country_code: "TO",
    airport_timezone: "13",
    airport_lat: "-21.241214",
    airport_lon: "-175.149644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3469,
    airport_code: "TBW",
    airport_name: "Tambov Arpt",
    airport_city_code: "TBW",
    airport_city_name: "Tambov",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "52.81",
    airport_lon: "41.48",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3470,
    airport_code: "TBZ",
    airport_name: "Tabriz International Arpt",
    airport_city_code: "TBZ",
    airport_city_name: "Tabriz",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "38.133889",
    airport_lon: "46.235",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3471,
    airport_code: "TCA",
    airport_name: "Tennant Creek Arpt",
    airport_city_code: "TCA",
    airport_city_name: "Tennant Creek",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-19.6344",
    airport_lon: "134.183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3472,
    airport_code: "TCB",
    airport_name: "Treasure Cay Arpt",
    airport_city_code: "TCB",
    airport_city_name: "Treasure Cay",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "26.745336",
    airport_lon: "-77.391269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3473,
    airport_code: "TCE",
    airport_name: "Tulcea Arpt",
    airport_city_code: "TCE",
    airport_city_name: "Tulcea",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "45.062486",
    airport_lon: "28.714311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3474,
    airport_code: "TFS",
    airport_name: "Reina Sofia Arpt",
    airport_city_code: "TCI",
    airport_city_name: "Tenerife",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "28.044475",
    airport_lon: "-16.572489",
    airport_num_airports: 2,
  },
  {
    airport_id: 3475,
    airport_code: "TFN",
    airport_name: "Tenerife Norte Los Rodeos Arpt",
    airport_city_code: "TCI",
    airport_city_name: "Tenerife",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "28.482653",
    airport_lon: "-16.341536",
    airport_num_airports: 2,
  },
  {
    airport_id: 3476,
    airport_code: "TCL",
    airport_name: "Van De Graff Arpt",
    airport_city_code: "TCL",
    airport_city_name: "Tuscaloosa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.220627",
    airport_lon: "-87.611403",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3477,
    airport_code: "TCN",
    airport_name: "Tehuacan Arpt",
    airport_city_code: "TCN",
    airport_city_name: "Tehuacan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "18.497189",
    airport_lon: "-97.419942",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3478,
    airport_code: "TCP",
    airport_name: "Ras An Naqb Arpt",
    airport_city_code: "TCP",
    airport_city_name: "Taba",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "2",
    airport_lat: "29.587778",
    airport_lon: "34.778056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3479,
    airport_code: "TCR",
    airport_name: "Tuticorin Arpt",
    airport_city_code: "TCR",
    airport_city_name: "Tuticorin",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "-100",
    airport_lat: "8.7833",
    airport_lon: "78.1333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3480,
    airport_code: "TCU",
    airport_name: "Thaba Nchu Arpt",
    airport_city_code: "TCU",
    airport_city_name: "Thaba Nchu",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3481,
    airport_code: "TCW",
    airport_name: "Tocumwal Arpt",
    airport_city_code: "TCW",
    airport_city_name: "Tocumwal",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-35.8117",
    airport_lon: "145.6083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3482,
    airport_code: "TDD",
    airport_name: "Trinidad Arpt",
    airport_city_code: "TDD",
    airport_city_name: "Trinidad",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-14.818739",
    airport_lon: "-64.918019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3483,
    airport_code: "TDK",
    airport_name: "Taldy Kurgan Arpt",
    airport_city_code: "TDK",
    airport_city_name: "Taldy Kurgan",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "45.016667",
    airport_lon: "78.366667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3484,
    airport_code: "TDL",
    airport_name: "Tandil Arpt",
    airport_city_code: "TDL",
    airport_city_name: "Tandil",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-37.237392",
    airport_lon: "-59.227922",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3485,
    airport_code: "TDX",
    airport_name: "Trat Arpt",
    airport_city_code: "TDX",
    airport_city_name: "Trat",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "12.274572",
    airport_lon: "102.318958",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3486,
    airport_code: "TEA",
    airport_name: "Tela Arpt",
    airport_city_code: "TEA",
    airport_city_name: "Tela",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-6",
    airport_lat: "15.775864",
    airport_lon: "-87.475847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3487,
    airport_code: "TEB",
    airport_name: "Teterboro Arpt",
    airport_city_code: "TEB",
    airport_city_name: "Teterboro",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.850103",
    airport_lon: "-74.060837",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3488,
    airport_code: "TEC",
    airport_name: "Telemaco Borba Arpt",
    airport_city_code: "TEC",
    airport_city_name: "Telemaco Borba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-24.3158",
    airport_lon: "-50.6517",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3489,
    airport_code: "TED",
    airport_name: "Thisted Arpt",
    airport_city_code: "TED",
    airport_city_name: "Thisted",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "1",
    airport_lat: "57.0688",
    airport_lon: "8.705225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3490,
    airport_code: "TEN",
    airport_name: "Tongren Arpt",
    airport_city_code: "TEN",
    airport_city_name: "Tongren",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "27.884",
    airport_lon: "109.31",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3491,
    airport_code: "TEQ",
    airport_name: "Corlu Airport Information",
    airport_city_code: "TEQ",
    airport_city_name: "Corlu",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "41.13825",
    airport_lon: "27.919094",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3492,
    airport_code: "TER",
    airport_name: "Lajes Arpt",
    airport_city_code: "TER",
    airport_city_name: "Terceira",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-1",
    airport_lat: "38.761842",
    airport_lon: "-27.090797",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3493,
    airport_code: "TET",
    airport_name: "Matunda Arpt",
    airport_city_code: "TET",
    airport_city_name: "Tete",
    airport_country_name: "MOZAMBIQUE",
    airport_country_code: "MZ",
    airport_timezone: "2",
    airport_lat: "-16.104817",
    airport_lon: "33.640181",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3494,
    airport_code: "TEU",
    airport_name: "Manapouri Airport",
    airport_city_code: "TEU",
    airport_city_name: "Te Anau",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-45.533056",
    airport_lon: "167.65",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3495,
    airport_code: "TEX",
    airport_name: "Telluride Municipal Arpt",
    airport_city_code: "TEX",
    airport_city_name: "Telluride",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "37.953759",
    airport_lon: "-107.90848",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3496,
    airport_code: "TFF",
    airport_name: "Tefe Arpt",
    airport_city_code: "TFF",
    airport_city_name: "Tefe",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-3.382944",
    airport_lon: "-64.724056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3497,
    airport_code: "TGD",
    airport_name: "Golubovci Arpt",
    airport_city_code: "TGD",
    airport_city_name: "Podgorica",
    airport_country_name: "MONTENEGRO",
    airport_country_code: "ME",
    airport_timezone: "1",
    airport_lat: "42.359392",
    airport_lon: "19.251894",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3498,
    airport_code: "TGG",
    airport_name: "Sultan Mahmood Arpt",
    airport_city_code: "TGG",
    airport_city_name: "Kuala Terengganu",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.382639",
    airport_lon: "103.10336",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3499,
    airport_code: "TGM",
    airport_name: "Tirgu Mures Arpt",
    airport_city_code: "TGM",
    airport_city_name: "Tirgu Mures",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "46.467714",
    airport_lon: "24.412525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3500,
    airport_code: "TGN",
    airport_name: "La Trobe Traralgon Arpt",
    airport_city_code: "TGN",
    airport_city_name: "Traralgon",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3501,
    airport_code: "TGO",
    airport_name: "Tongliao Arpt",
    airport_city_code: "TGO",
    airport_city_name: "Tongliao",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "43.5567",
    airport_lon: "122.2",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3502,
    airport_code: "TGT",
    airport_name: "Tanga Arpt",
    airport_city_code: "TGT",
    airport_city_name: "Tanga",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-5.092358",
    airport_lon: "39.071158",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3503,
    airport_code: "TGU",
    airport_name: "Toncontin Arpt",
    airport_city_code: "TGU",
    airport_city_name: "Tegucigalpa",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-6",
    airport_lat: "14.060883",
    airport_lon: "-87.217197",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3504,
    airport_code: "TGZ",
    airport_name: "Llano San Juan Arpt",
    airport_city_code: "TGZ",
    airport_city_name: "Tuxtla Gutierrez",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "16.561822",
    airport_lon: "-93.026081",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3505,
    airport_code: "THA",
    airport_name: "Northern Arpt",
    airport_city_code: "THA",
    airport_city_name: "Tullahoma",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3506,
    airport_code: "THC",
    airport_name: "Tchien Arpt",
    airport_city_code: "THC",
    airport_city_name: "Tchien",
    airport_country_name: "LIBERIA",
    airport_country_code: "LR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3507,
    airport_code: "THE",
    airport_name: "Teresina Arpt",
    airport_city_code: "THE",
    airport_city_name: "Teresina",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-5.059942",
    airport_lon: "-42.823478",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3508,
    airport_code: "THG",
    airport_name: "Thangool Arpt",
    airport_city_code: "THG",
    airport_city_name: "Thangool",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-24.493889",
    airport_lon: "150.576111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3509,
    airport_code: "THN",
    airport_name: "Trollhattan Arpt",
    airport_city_code: "THN",
    airport_city_name: "Trollhattan",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "58.318056",
    airport_lon: "12.345",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3510,
    airport_code: "THP",
    airport_name: "Hot Springs",
    airport_city_code: "THP",
    airport_city_name: "Thermopolis",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3511,
    airport_code: "THR",
    airport_name: "Mehrabad Arpt",
    airport_city_code: "THR",
    airport_city_name: "Teheran",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "4",
    airport_lat: "35.689167",
    airport_lon: "51.313416",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3512,
    airport_code: "IKA",
    airport_name: "Imam Khomeini Arpt",
    airport_city_code: "THR",
    airport_city_name: "Teheran",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "3",
    airport_lat: "35",
    airport_lon: "51",
    airport_num_airports: 2,
  },
  {
    airport_id: 3513,
    airport_code: "THS",
    airport_name: "Sukhothai Arpt",
    airport_city_code: "THS",
    airport_city_name: "Sukhothai",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "17.237992",
    airport_lon: "99.818183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3514,
    airport_code: "THV",
    airport_name: "York Arpt",
    airport_city_code: "THV",
    airport_city_name: "York",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3515,
    airport_code: "TIA",
    airport_name: "Rinas Arpt",
    airport_city_code: "TIA",
    airport_city_name: "Tirana",
    airport_country_name: "ALBANIA",
    airport_country_code: "AL",
    airport_timezone: "1",
    airport_lat: "41.414742",
    airport_lon: "19.720561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3516,
    airport_code: "TIF",
    airport_name: "Taif Airport",
    airport_city_code: "TIF",
    airport_city_name: "Taif",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "21.483418",
    airport_lon: "40.544334",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3517,
    airport_code: "TIG",
    airport_name: "Tingwon Arpt",
    airport_city_code: "TIG",
    airport_city_name: "Tingwon",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3518,
    airport_code: "TIH",
    airport_name: "Tikehau Arpt",
    airport_city_code: "TIH",
    airport_city_name: "Tikehau",
    airport_country_name: "FRENCH POLYNESIA",
    airport_country_code: "PF",
    airport_timezone: "-10",
    airport_lat: "-15.119617",
    airport_lon: "-148.230697",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3519,
    airport_code: "TIJ",
    airport_name: "Gen Abelardo Rodriguez",
    airport_city_code: "TIJ",
    airport_city_name: "Tijuana",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-8",
    airport_lat: "32.541064",
    airport_lon: "-116.970158",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3520,
    airport_code: "TIM",
    airport_name: "Timika Arpt",
    airport_city_code: "TIM",
    airport_city_name: "Timika",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "9",
    airport_lat: "-4.528275",
    airport_lon: "136.887375",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3521,
    airport_code: "TIP",
    airport_name: "Tripoli Intl Arpt",
    airport_city_code: "TIP",
    airport_city_name: "Tripoli",
    airport_country_name: "LIBYAN ARAB JAMAHIRIYA",
    airport_country_code: "LY",
    airport_timezone: "1",
    airport_lat: "32.663544",
    airport_lon: "13.159011",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3522,
    airport_code: "TIQ",
    airport_name: "Tinian Arpt",
    airport_city_code: "TIQ",
    airport_city_name: "Tinian",
    airport_country_name: "NORTHERN MARIANA ISLANDS",
    airport_country_code: "MP",
    airport_timezone: "10",
    airport_lat: "14.999203",
    airport_lon: "145.61935",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3523,
    airport_code: "TIR",
    airport_name: "Tirupati Arpt",
    airport_city_code: "TIR",
    airport_city_name: "Tirupati",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "13.632492",
    airport_lon: "79.543256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3524,
    airport_code: "TIS",
    airport_name: "Thursday Island Arpt",
    airport_city_code: "TIS",
    airport_city_name: "Thursday Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-10.5864",
    airport_lon: "142.29",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3525,
    airport_code: "TIU",
    airport_name: "Timaru Arpt",
    airport_city_code: "TIU",
    airport_city_name: "Timaru",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-44.302778",
    airport_lon: "171.225278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3526,
    airport_code: "TIV",
    airport_name: "Tivat Arpt",
    airport_city_code: "TIV",
    airport_city_name: "Tivat",
    airport_country_name: "MONTENEGRO",
    airport_country_code: "ME",
    airport_timezone: "1",
    airport_lat: "42.404664",
    airport_lon: "18.723286",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3527,
    airport_code: "TIW",
    airport_name: "Tacoma Industrial Arpt",
    airport_city_code: "TIW",
    airport_city_name: "Tacoma",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "47.2679",
    airport_lon: "-122.5781",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3528,
    airport_code: "TIX",
    airport_name: "Space Center Executive Arpt",
    airport_city_code: "TIX",
    airport_city_name: "Titusville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "28.5148",
    airport_lon: "-80.7992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3529,
    airport_code: "TIZ",
    airport_name: "Tari Arpt",
    airport_city_code: "TIZ",
    airport_city_name: "Tari",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-5.845",
    airport_lon: "142.948",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3530,
    airport_code: "TJA",
    airport_name: "Tarija Arpt",
    airport_city_code: "TJA",
    airport_city_name: "Tarija",
    airport_country_name: "BOLIVIA",
    airport_country_code: "BO",
    airport_timezone: "-4",
    airport_lat: "-21.555736",
    airport_lon: "-64.701325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3531,
    airport_code: "TJK",
    airport_name: "TOKAT HAVALIMANI",
    airport_city_code: "TJK",
    airport_city_name: "TOKAT HAVALIMANI",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3532,
    airport_code: "TJM",
    airport_name: "Tyumen Arpt",
    airport_city_code: "TJM",
    airport_city_name: "Tyumen",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "57.189567",
    airport_lon: "65.3243",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3533,
    airport_code: "TJQ",
    airport_name: "Bulutumbang Arpt",
    airport_city_code: "TJQ",
    airport_city_name: "Tanjung Pandan",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-2.745722",
    airport_lon: "107.754917",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3534,
    airport_code: "TKF",
    airport_name: "Truckee Arpt",
    airport_city_code: "TKF",
    airport_city_name: "Truckee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.32",
    airport_lon: "-120.1396",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3535,
    airport_code: "TKG",
    airport_name: "Branti Arpt",
    airport_city_code: "TKG",
    airport_city_name: "Bandar Lampung",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "7",
    airport_lat: "-5.242339",
    airport_lon: "105.178939",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3536,
    airport_code: "TKJ",
    airport_name: "Tok Arpt",
    airport_city_code: "TKJ",
    airport_city_name: "Tok Ak",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "63.2955",
    airport_lon: "-143.0061",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3537,
    airport_code: "TKK",
    airport_name: "Truk Arpt",
    airport_city_code: "TKK",
    airport_city_name: "Truk",
    airport_country_name: "MICRONESIA, FEDERATED STATES OF",
    airport_country_code: "FM",
    airport_timezone: "10",
    airport_lat: "7.461869",
    airport_lon: "151.843006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3538,
    airport_code: "TKM",
    airport_name: "El Peten Arpt",
    airport_city_code: "TKM",
    airport_city_name: "Tikal",
    airport_country_name: "GUATEMALA",
    airport_country_code: "GT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3539,
    airport_code: "TKN",
    airport_name: "Tokunoshima Arpt",
    airport_city_code: "TKN",
    airport_city_name: "Tokunoshima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "27.836381",
    airport_lon: "128.881253",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3540,
    airport_code: "TKQ",
    airport_name: "Kigoma Arpt",
    airport_city_code: "TKQ",
    airport_city_name: "Kigoma",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-4.883",
    airport_lon: "29.633",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3541,
    airport_code: "TKS",
    airport_name: "Tokushima Arpt",
    airport_city_code: "TKS",
    airport_city_name: "Tokushima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.132808",
    airport_lon: "134.606639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3542,
    airport_code: "TKU",
    airport_name: "Turku Arpt",
    airport_city_code: "TKU",
    airport_city_name: "Turku",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "60.514142",
    airport_lon: "22.262808",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3543,
    airport_code: "TLC",
    airport_name: "Morelos Arpt",
    airport_city_code: "TLC",
    airport_city_name: "Toluca",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.337072",
    airport_lon: "-99.566008",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3544,
    airport_code: "TLD",
    airport_name: "Limpopo Valley Arpt",
    airport_city_code: "TLD",
    airport_city_name: "Tuli Block",
    airport_country_name: "BOTSWANA",
    airport_country_code: "BW",
    airport_timezone: "2",
    airport_lat: "-22.1892",
    airport_lon: "29.1269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3545,
    airport_code: "TLE",
    airport_name: "Tulear Arpt",
    airport_city_code: "TLE",
    airport_city_name: "Tulear",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-23.383369",
    airport_lon: "43.728453",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3546,
    airport_code: "TLH",
    airport_name: "Tallahassee Municipal",
    airport_city_code: "TLH",
    airport_city_name: "Tallahassee",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "30.396528",
    airport_lon: "-84.350333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3547,
    airport_code: "TLL",
    airport_name: "Ulemiste Arpt",
    airport_city_code: "TLL",
    airport_city_name: "Tallinn",
    airport_country_name: "ESTONIA",
    airport_country_code: "EE",
    airport_timezone: "2",
    airport_lat: "59.413317",
    airport_lon: "24.832844",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3548,
    airport_code: "TLM",
    airport_name: "Zenata Arpt",
    airport_city_code: "TLM",
    airport_city_name: "Tlemcen",
    airport_country_name: "ALGERIA",
    airport_country_code: "DZ",
    airport_timezone: "1",
    airport_lat: "35.016667",
    airport_lon: "-1.45",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3549,
    airport_code: "TLN",
    airport_name: "Le Palyvestre Arpt",
    airport_city_code: "TLN",
    airport_city_name: "Toulon",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.0973",
    airport_lon: "6.14603",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3550,
    airport_code: "TLR",
    airport_name: "Mefford Field",
    airport_city_code: "TLR",
    airport_city_name: "Tulare",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3551,
    airport_code: "TLS",
    airport_name: "Blagnac Arpt",
    airport_city_code: "TLS",
    airport_city_name: "Toulouse",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "43.629075",
    airport_lon: "1.363819",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3552,
    airport_code: "TLV",
    airport_name: "Ben Gurion Intl Arpt",
    airport_city_code: "TLV",
    airport_city_name: "Tel Aviv",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "2",
    airport_lat: "32.011389",
    airport_lon: "34.886667",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3553,
    airport_code: "SDV",
    airport_name: "Dov Airport",
    airport_city_code: "TLV",
    airport_city_name: "Tel Aviv",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "2",
    airport_lat: "32.114661",
    airport_lon: "34.782239",
    airport_num_airports: 2,
  },
  {
    airport_id: 3554,
    airport_code: "TMA",
    airport_name: "Henry Tift Myers Arpt",
    airport_city_code: "TMA",
    airport_city_name: "Tifton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "31.428981",
    airport_lon: "-83.488545",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3555,
    airport_code: "TMG",
    airport_name: "Tomanggong Arpt",
    airport_city_code: "TMG",
    airport_city_name: "Tomanggong",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "5.4",
    airport_lon: "118.65",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3556,
    airport_code: "TML",
    airport_name: "Tamale Arpt",
    airport_city_code: "TML",
    airport_city_name: "Tamale",
    airport_country_name: "GHANA",
    airport_country_code: "GH",
    airport_timezone: "0",
    airport_lat: "9.557192",
    airport_lon: "-0.863214",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3557,
    airport_code: "TMM",
    airport_name: "Tamatave Arpt",
    airport_city_code: "TMM",
    airport_city_name: "Tamatave",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-18.109517",
    airport_lon: "49.392536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3558,
    airport_code: "TMP",
    airport_name: "Tampere Pirkkala Arpt",
    airport_city_code: "TMP",
    airport_city_name: "Tampere",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "61.414147",
    airport_lon: "23.604392",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3559,
    airport_code: "TMS",
    airport_name: "Sao Tome Intl Arpt",
    airport_city_code: "TMS",
    airport_city_name: "Sao Tome Is",
    airport_country_name: "SAO TOME AND PRINCIPE",
    airport_country_code: "ST",
    airport_timezone: "0",
    airport_lat: "0.378175",
    airport_lon: "6.712153",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3560,
    airport_code: "TMT",
    airport_name: "Trombetas Arpt",
    airport_city_code: "TMT",
    airport_city_name: "Trombetas",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "-1.4896",
    airport_lon: "-56.3968",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3561,
    airport_code: "TMU",
    airport_name: "Tambor Arpt",
    airport_city_code: "TMU",
    airport_city_name: "Tambor",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "9.73852",
    airport_lon: "-85.0138",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3562,
    airport_code: "TMW",
    airport_name: "Tamworth Arpt",
    airport_city_code: "TMW",
    airport_city_name: "Tamworth",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-31.083889",
    airport_lon: "150.846667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3563,
    airport_code: "TMZ",
    airport_name: "Thames Arpt",
    airport_city_code: "TMZ",
    airport_city_name: "Thames",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3564,
    airport_code: "TNA",
    airport_name: "Jinan Arpt",
    airport_city_code: "TNA",
    airport_city_name: "Jinan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "36.857214",
    airport_lon: "117.215992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3565,
    airport_code: "TND",
    airport_name: "Trinidad CU Arpt",
    airport_city_code: "TND",
    airport_city_name: "Trinidad",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "21.788461",
    airport_lon: "-79.997203",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3566,
    airport_code: "TNE",
    airport_name: "Tanega Shima Arpt",
    airport_city_code: "TNE",
    airport_city_name: "Tanega Shima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "30.605067",
    airport_lon: "130.991231",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3567,
    airport_code: "TNF",
    airport_name: "Toussus Le Noble Arpt",
    airport_city_code: "TNF",
    airport_city_name: "Toussus Le Noble",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "48.751922",
    airport_lon: "2.106189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3568,
    airport_code: "TNG",
    airport_name: "Boukhalef Arpt",
    airport_city_code: "TNG",
    airport_city_name: "Tangier",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "35.726917",
    airport_lon: "-5.916889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3569,
    airport_code: "TNN",
    airport_name: "Tainan Arpt",
    airport_city_code: "TNN",
    airport_city_name: "Tainan",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "22.950361",
    airport_lon: "120.205778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3570,
    airport_code: "TNO",
    airport_name: "Tamarindo Arpt",
    airport_city_code: "TNO",
    airport_city_name: "Tamarindo",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "10.3557",
    airport_lon: "-85.852892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3571,
    airport_code: "TNR",
    airport_name: "Ivato Arpt",
    airport_city_code: "TNR",
    airport_city_name: "Tananarive",
    airport_country_name: "MADAGASCAR",
    airport_country_code: "MG",
    airport_timezone: "3",
    airport_lat: "-18.79695",
    airport_lon: "47.478806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3572,
    airport_code: "TNU",
    airport_name: "Municipal Newton Arpt",
    airport_city_code: "TNU",
    airport_city_name: "Newton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3573,
    airport_code: "TOB",
    airport_name: "Tobruk Arpt",
    airport_city_code: "TOB",
    airport_city_name: "Tobruk",
    airport_country_name: "LIBYAN ARAB JAMAHIRIYA",
    airport_country_code: "LY",
    airport_timezone: "1",
    airport_lat: "31.861",
    airport_lon: "23.907",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3574,
    airport_code: "TOD",
    airport_name: "Tioman Arpt",
    airport_city_code: "TOD",
    airport_city_name: "Tioman",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "2.818183",
    airport_lon: "104.160019",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3575,
    airport_code: "TOE",
    airport_name: "Tozeur Arpt",
    airport_city_code: "TOE",
    airport_city_name: "Tozeur",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "33.939722",
    airport_lon: "8.110556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3576,
    airport_code: "TOF",
    airport_name: "Tomsk Arpt",
    airport_city_code: "TOF",
    airport_city_name: "Tomsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "8",
    airport_lat: "56.380278",
    airport_lon: "85.208333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3577,
    airport_code: "TOG",
    airport_name: "Togiak Village Arpt",
    airport_city_code: "TOG",
    airport_city_name: "Togiak",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.0528",
    airport_lon: "-160.397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3578,
    airport_code: "TOH",
    airport_name: "Torres Airstrip",
    airport_city_code: "TOH",
    airport_city_name: "Torres",
    airport_country_name: "VANUATU",
    airport_country_code: "VU",
    airport_timezone: "11",
    airport_lat: "-13.328",
    airport_lon: "166.638",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3579,
    airport_code: "TOL",
    airport_name: "Toledo Express Arpt",
    airport_city_code: "TOL",
    airport_city_name: "Toledo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.586806",
    airport_lon: "-83.807833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3580,
    airport_code: "TOP",
    airport_name: "Phillip Billard Arpt",
    airport_city_code: "TOP",
    airport_city_name: "Topeka",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.068657",
    airport_lon: "-95.622482",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3581,
    airport_code: "FOE",
    airport_name: "Forbes Field",
    airport_city_code: "TOP",
    airport_city_name: "Topeka",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "38.950944",
    airport_lon: "-95.663611",
    airport_num_airports: 2,
  },
  {
    airport_id: 3582,
    airport_code: "TOR",
    airport_name: "Torrington Minicipal Arpt",
    airport_city_code: "TOR",
    airport_city_name: "Torrington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3583,
    airport_code: "TOS",
    airport_name: "Tromso Langnes Arpt",
    airport_city_code: "TOS",
    airport_city_name: "Tromso",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "69.683333",
    airport_lon: "18.918919",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3584,
    airport_code: "RAD",
    airport_name: "Road Town Arpt",
    airport_city_code: "TOV",
    airport_city_name: "Tortola Westend",
    airport_country_name: "VIRGIN ISLANDS, BRITISH",
    airport_country_code: "VG",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 3585,
    airport_code: "TOW",
    airport_name: "Toledo Arpt",
    airport_city_code: "TOW",
    airport_city_name: "Toledo",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-24.6863",
    airport_lon: "-53.6975",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3586,
    airport_code: "TOX",
    airport_name: "Tobolsk Arpt",
    airport_city_code: "TOX",
    airport_city_name: "Tobolsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3587,
    airport_code: "TOY",
    airport_name: "Toyama Arpt",
    airport_city_code: "TOY",
    airport_city_name: "Toyama",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "36.648333",
    airport_lon: "137.1875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3588,
    airport_code: "TPA",
    airport_name: "Tampa Intl",
    airport_city_code: "TPA",
    airport_city_name: "Tampa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "27.975472",
    airport_lon: "-82.53325",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3589,
    airport_code: "TPF",
    airport_name: "Peter O Knight Arpt",
    airport_city_code: "TPA",
    airport_city_name: "Tampa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3590,
    airport_code: "PIE",
    airport_name: "St Petersburg Clearwater Arpt",
    airport_city_code: "TPA",
    airport_city_name: "Tampa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "27.910167",
    airport_lon: "-82.687389",
    airport_num_airports: 3,
  },
  {
    airport_id: 3591,
    airport_code: "TPE",
    airport_name: "Taiwan Taoyuan Intl Arpt",
    airport_city_code: "TPE",
    airport_city_name: "Taipei",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "25.077731",
    airport_lon: "121.232822",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3592,
    airport_code: "TSA",
    airport_name: "Sung Shan Arpt",
    airport_city_code: "TPE",
    airport_city_name: "Taipei",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "25.069722",
    airport_lon: "121.5525",
    airport_num_airports: 2,
  },
  {
    airport_id: 3593,
    airport_code: "TPH",
    airport_name: "Tonopah Arpt",
    airport_city_code: "TPH",
    airport_city_name: "Tonopah",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "38.0601",
    airport_lon: "-117.0872",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3594,
    airport_code: "TPL",
    airport_name: "Temple Arpt",
    airport_city_code: "TPL",
    airport_city_name: "Temple",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "31.1525",
    airport_lon: "-97.407778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3595,
    airport_code: "TPQ",
    airport_name: "Tepic Arpt",
    airport_city_code: "TPQ",
    airport_city_name: "Tepic",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "21.419453",
    airport_lon: "-104.842581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3596,
    airport_code: "TPR",
    airport_name: "Tom Price",
    airport_city_code: "TPR",
    airport_city_name: "Tom Price",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3597,
    airport_code: "TPS",
    airport_name: "Birgi Arpt",
    airport_city_code: "TPS",
    airport_city_name: "Trapani",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "37.911403",
    airport_lon: "12.487961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3598,
    airport_code: "TQR",
    airport_name: "San Domino Island Arpt",
    airport_city_code: "TQR",
    airport_city_name: "San Domino Island",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "42.1025",
    airport_lon: "15.488",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3599,
    airport_code: "TRC",
    airport_name: "Francisco Sarabia Arpt",
    airport_city_code: "TRC",
    airport_city_name: "Torreon",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "25.568278",
    airport_lon: "-103.410583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3600,
    airport_code: "XOD",
    airport_name: "Oppdal Rail Station",
    airport_city_code: "TRD",
    airport_city_name: "Trondheim",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3601,
    airport_code: "XZT",
    airport_name: "Trondheim Rail Station",
    airport_city_code: "TRD",
    airport_city_name: "Trondheim",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 3602,
    airport_code: "TRD",
    airport_name: "Trondheim Vaernes Arpt",
    airport_city_code: "TRD",
    airport_city_name: "Trondheim",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "63.457556",
    airport_lon: "10.92425",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 3603,
    airport_code: "TRE",
    airport_name: "Tiree Arpt",
    airport_city_code: "TRE",
    airport_city_name: "Tiree",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "56.499167",
    airport_lon: "-6.869167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3604,
    airport_code: "TRG",
    airport_name: "Tauranga Arpt",
    airport_city_code: "TRG",
    airport_city_name: "Tauranga",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-37.671944",
    airport_lon: "176.19611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3605,
    airport_code: "TRI",
    airport_name: "Tri City Regional Arpt",
    airport_city_code: "TRI",
    airport_city_name: "Bristol",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "36.475222",
    airport_lon: "-82.407417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3606,
    airport_code: "TRK",
    airport_name: "Tarakan Arpt",
    airport_city_code: "TRK",
    airport_city_name: "Tarakan",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "3.326694",
    airport_lon: "117.565569",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3607,
    airport_code: "TRL",
    airport_name: "Terrell Field Arpt",
    airport_city_code: "TRL",
    airport_city_name: "Terrell",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3608,
    airport_code: "TRN",
    airport_name: "Torino Caselle Arpt",
    airport_city_code: "TRN",
    airport_city_name: "Turin",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.200761",
    airport_lon: "7.649631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3609,
    airport_code: "TRO",
    airport_name: "Taree Arpt",
    airport_city_code: "TRO",
    airport_city_name: "Taree",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-31.8886",
    airport_lon: "152.514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3610,
    airport_code: "TRS",
    airport_name: "Ronchi Dei Legionari Arpt",
    airport_city_code: "TRS",
    airport_city_name: "Trieste",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.8275",
    airport_lon: "13.472222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3611,
    airport_code: "TRU",
    airport_name: "Trujillo Arpt",
    airport_city_code: "TRU",
    airport_city_name: "Trujillo",
    airport_country_name: "PERU",
    airport_country_code: "PE",
    airport_timezone: "-5",
    airport_lat: "-8.081411",
    airport_lon: "-79.108761",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3612,
    airport_code: "TRV",
    airport_name: "Thiruvananthapuram Arpt",
    airport_city_code: "TRV",
    airport_city_name: "Thiruvananthapuram",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "8.482122",
    airport_lon: "76.920114",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3613,
    airport_code: "TRW",
    airport_name: "Bonriki Arpt",
    airport_city_code: "TRW",
    airport_city_name: "Tarawa",
    airport_country_name: "KIRIBATI",
    airport_country_code: "KI",
    airport_timezone: "12",
    airport_lat: "1.381636",
    airport_lon: "173.147036",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3614,
    airport_code: "TRZ",
    airport_name: "Tiruchirapally Civil Arpt",
    airport_city_code: "TRZ",
    airport_city_name: "Tiruchirappali",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "10.765364",
    airport_lon: "78.709722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3615,
    airport_code: "TSE",
    airport_name: "Astana Arpt",
    airport_city_code: "TSE",
    airport_city_name: "Astana",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "51.022222",
    airport_lon: "71.466944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3616,
    airport_code: "TSF",
    airport_name: "Treviso Arpt",
    airport_city_code: "TSF",
    airport_city_name: "Treviso",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.6484",
    airport_lon: "12.194422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3617,
    airport_code: "TSJ",
    airport_name: "Tsushima Arpt",
    airport_city_code: "TSJ",
    airport_city_name: "Tsushima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.284889",
    airport_lon: "129.33055",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3618,
    airport_code: "TSM",
    airport_name: "Taos Airport",
    airport_city_code: "TSM",
    airport_city_name: "Taos",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3619,
    airport_code: "TSN",
    airport_name: "Tianjin Airport",
    airport_city_code: "TSN",
    airport_city_name: "Tianjin",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "39.124353",
    airport_lon: "117.346183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3620,
    airport_code: "TSR",
    airport_name: "Timisoara Arpt",
    airport_city_code: "TSR",
    airport_city_name: "Timisoara",
    airport_country_name: "ROMANIA",
    airport_country_code: "RO",
    airport_timezone: "2",
    airport_lat: "45.809861",
    airport_lon: "21.337861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3621,
    airport_code: "TST",
    airport_name: "Trang Arpt",
    airport_city_code: "TST",
    airport_city_name: "Trang",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "7.508744",
    airport_lon: "99.616578",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3622,
    airport_code: "TSV",
    airport_name: "Townsville Arpt",
    airport_city_code: "TSV",
    airport_city_name: "Townsville",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-19.2525",
    airport_lon: "146.765278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3623,
    airport_code: "TTB",
    airport_name: "Arbatax Arpt",
    airport_city_code: "TTB",
    airport_city_name: "Tortoli",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "39.918761",
    airport_lon: "9.682981",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3624,
    airport_code: "TTD",
    airport_name: "Troutdale Arpt",
    airport_city_code: "TTD",
    airport_city_name: "Troutdale",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "45.54937",
    airport_lon: "-122.401253",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3625,
    airport_code: "TTJ",
    airport_name: "Tottori Arpt",
    airport_city_code: "TTJ",
    airport_city_name: "Tottori",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.530069",
    airport_lon: "134.166553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3626,
    airport_code: "TTN",
    airport_name: "Trenton Mercer Arpt",
    airport_city_code: "TTN",
    airport_city_name: "Trenton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.276692",
    airport_lon: "-74.813469",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3627,
    airport_code: "TRX",
    airport_name: "Trenton Airport",
    airport_city_code: "TTN",
    airport_city_name: "Trenton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3628,
    airport_code: "TTQ",
    airport_name: "Tortuquero Arpt",
    airport_city_code: "TTQ",
    airport_city_name: "Tortuquero",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "10.569",
    airport_lon: "-83.5148",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3629,
    airport_code: "TTT",
    airport_name: "Taitung Arpt",
    airport_city_code: "TTT",
    airport_city_name: "Taitung",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "22.754986",
    airport_lon: "121.101681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3630,
    airport_code: "TUC",
    airport_name: "Benjamin Matienzo Airport",
    airport_city_code: "TUC",
    airport_city_name: "Tucuman",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-26.840861",
    airport_lon: "-65.104944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3631,
    airport_code: "TUF",
    airport_name: "Saint Symphorien Arpt",
    airport_city_code: "TUF",
    airport_city_name: "Tours",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.432222",
    airport_lon: "0.727606",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3632,
    airport_code: "XSH",
    airport_name: "Tours Rail Station",
    airport_city_code: "TUF",
    airport_city_name: "Tours",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.385626",
    airport_lon: "0.723347",
    airport_num_airports: 2,
  },
  {
    airport_id: 3633,
    airport_code: "TUG",
    airport_name: "Tuguegarao Arpt",
    airport_city_code: "TUG",
    airport_city_name: "Tuguegarao",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "17.638311",
    airport_lon: "121.730614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3634,
    airport_code: "TUI",
    airport_name: "Turaif Arpt",
    airport_city_code: "TUI",
    airport_city_name: "Turaif",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "31.692683",
    airport_lon: "38.7312",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3635,
    airport_code: "TUK",
    airport_name: "Turbat Arpt",
    airport_city_code: "TUK",
    airport_city_name: "Turbat",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "25.986369",
    airport_lon: "63.030167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3636,
    airport_code: "TUL",
    airport_name: "Tulsa Intl",
    airport_city_code: "TUL",
    airport_city_name: "Tulsa",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "36.198389",
    airport_lon: "-95.888111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3637,
    airport_code: "TUN",
    airport_name: "Carthage Arpt",
    airport_city_code: "TUN",
    airport_city_name: "Tunis",
    airport_country_name: "TUNISIA",
    airport_country_code: "TN",
    airport_timezone: "1",
    airport_lat: "36.851033",
    airport_lon: "10.227217",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3638,
    airport_code: "TUO",
    airport_name: "Taupo Arpt",
    airport_city_code: "TUO",
    airport_city_name: "Taupo",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-38.739723",
    airport_lon: "176.084444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3639,
    airport_code: "TUP",
    airport_name: "C D Lemons Municipal Arpt",
    airport_city_code: "TUP",
    airport_city_name: "Tupelo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "34.2681",
    airport_lon: "-88.7699",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3640,
    airport_code: "TUR",
    airport_name: "Tucurui Arpt",
    airport_city_code: "TUR",
    airport_city_name: "Tucurui",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-3.786008",
    airport_lon: "-49.720267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3641,
    airport_code: "TUS",
    airport_name: "Tucson Intl Arpt",
    airport_city_code: "TUS",
    airport_city_name: "Tucson",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.116083",
    airport_lon: "-110.941028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3642,
    airport_code: "TUU",
    airport_name: "Tabuk Arpt",
    airport_city_code: "TUU",
    airport_city_name: "Tabuk",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "28.365417",
    airport_lon: "36.618889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3643,
    airport_code: "TUY",
    airport_name: "Tulum Arpt",
    airport_city_code: "TUY",
    airport_city_name: "Tulum",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3644,
    airport_code: "TVC",
    airport_name: "Cherry Capital Arpt",
    airport_city_code: "TVC",
    airport_city_name: "Traverse City",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "44.741445",
    airport_lon: "-85.582235",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3645,
    airport_code: "TVF",
    airport_name: "Thief River Falls Numicipal",
    airport_city_code: "TVF",
    airport_city_name: "Thief River Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "48.065556",
    airport_lon: "-96.185",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3646,
    airport_code: "TVL",
    airport_name: "Lake Tahoe Arpt",
    airport_city_code: "TVL",
    airport_city_name: "Lake Tahoe",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "38.893889",
    airport_lon: "-119.995278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3647,
    airport_code: "TVU",
    airport_name: "Matei Arpt",
    airport_city_code: "TVU",
    airport_city_name: "Taveuni",
    airport_country_name: "FIJI",
    airport_country_code: "FJ",
    airport_timezone: "12",
    airport_lat: "-16.6906",
    airport_lon: "-179.877",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3648,
    airport_code: "TWA",
    airport_name: "Twin Hills Arpt",
    airport_city_code: "TWA",
    airport_city_name: "Twin Hills",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.074444",
    airport_lon: "-160.275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3649,
    airport_code: "TWB",
    airport_name: "Toowoomba Arpt",
    airport_city_code: "TWB",
    airport_city_name: "Toowoomba",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-27.542778",
    airport_lon: "151.916389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3650,
    airport_code: "TWF",
    airport_name: "Twin Falls City County Arpt",
    airport_city_code: "TWF",
    airport_city_name: "Twin Falls",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "42.481803",
    airport_lon: "-114.487733",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3651,
    airport_code: "TWU",
    airport_name: "Tawau Arpt",
    airport_city_code: "TWU",
    airport_city_name: "Tawau",
    airport_country_name: "MALAYSIA",
    airport_country_code: "MY",
    airport_timezone: "8",
    airport_lat: "4.313369",
    airport_lon: "118.121953",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3652,
    airport_code: "TXG",
    airport_name: "Taichung Arpt",
    airport_city_code: "TXG",
    airport_city_name: "Taichung",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "24.1863",
    airport_lon: "120.654",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3653,
    airport_code: "RMQ",
    airport_name: "Ching Chuan Kang",
    airport_city_code: "TXG",
    airport_city_name: "Taichung",
    airport_country_name: "TAIWAN, PROVINCE OF CHINA",
    airport_country_code: "TW",
    airport_timezone: "8",
    airport_lat: "24.264668",
    airport_lon: "120.62058",
    airport_num_airports: 2,
  },
  {
    airport_id: 3654,
    airport_code: "TXK",
    airport_name: "Texarkana Municipal",
    airport_city_code: "TXK",
    airport_city_name: "Texarkana",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.453722",
    airport_lon: "-93.991028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3655,
    airport_code: "TXN",
    airport_name: "Tunxi Arpt",
    airport_city_code: "TXN",
    airport_city_name: "Tunxi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.7333",
    airport_lon: "118.256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3656,
    airport_code: "TYD",
    airport_name: "Tynda Airport",
    airport_city_code: "TYD",
    airport_city_name: "Tynda Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "0",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3657,
    airport_code: "TYN",
    airport_name: "Taiyuan Arpt",
    airport_city_code: "TYN",
    airport_city_name: "Taiyuan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "37.746897",
    airport_lon: "112.628428",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3658,
    airport_code: "HND",
    airport_name: "Haneda Arpt",
    airport_city_code: "TYO",
    airport_city_name: "Tokyo",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.552258",
    airport_lon: "139.779694",
    airport_num_airports: 2,
  },
  {
    airport_id: 3659,
    airport_code: "NRT",
    airport_name: "Narita",
    airport_city_code: "TYO",
    airport_city_name: "Tokyo",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.764722",
    airport_lon: "140.386389",
    airport_num_airports: 2,
  },
  {
    airport_id: 3660,
    airport_code: "TYR",
    airport_name: "Pounds Field",
    airport_city_code: "TYR",
    airport_city_name: "Tyler",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "32.354139",
    airport_lon: "-95.402386",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3661,
    airport_code: "TYS",
    airport_name: "McGhee Tyson Arpt",
    airport_city_code: "TYS",
    airport_city_name: "Knoxville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "35.810972",
    airport_lon: "-83.994028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3662,
    airport_code: "TZL",
    airport_name: "Tuzla Intl Arpt",
    airport_city_code: "TZL",
    airport_city_name: "Tuzla",
    airport_country_name: "BOSNIA AND HERZEGOVINA",
    airport_country_code: "BA",
    airport_timezone: "-100",
    airport_lat: "44.4587",
    airport_lon: "18.7248",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3663,
    airport_code: "TZN",
    airport_name: "South Andros Arpt",
    airport_city_code: "TZN",
    airport_city_name: "South Andros",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-100",
    airport_lat: "24.1586",
    airport_lon: "-77.5892",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3664,
    airport_code: "TZX",
    airport_name: "Trabzon Arpt",
    airport_city_code: "TZX",
    airport_city_name: "Trabzon",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "40.995108",
    airport_lon: "39.789728",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3665,
    airport_code: "UA",
    airport_name: "UDJ",
    airport_city_code: "UA",
    airport_city_name: "UDJ",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "0",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3666,
    airport_code: "UAC",
    airport_name: "San Luis Rio Colorado Municipal",
    airport_city_code: "UAC",
    airport_city_name: "San Luis Rio Colorado",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3667,
    airport_code: "UAK",
    airport_name: "Narsarsuaq Arpt",
    airport_city_code: "UAK",
    airport_city_name: "Narsarsuaq",
    airport_country_name: "GREENLAND",
    airport_country_code: "GL",
    airport_timezone: "-3",
    airport_lat: "61.160517",
    airport_lon: "-45.425978",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3668,
    airport_code: "UAQ",
    airport_name: "San Juan Arpt",
    airport_city_code: "UAQ",
    airport_city_name: "San Juan",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-31.571472",
    airport_lon: "-68.418194",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3669,
    airport_code: "UAS",
    airport_name: "Samburu Airstrip",
    airport_city_code: "UAS",
    airport_city_name: "Samburu",
    airport_country_name: "KENYA",
    airport_country_code: "KE",
    airport_timezone: "3",
    airport_lat: "0.530583",
    airport_lon: "37.5342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3670,
    airport_code: "UBA",
    airport_name: "Uberaba Airport",
    airport_city_code: "UBA",
    airport_city_name: "Uberaba",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-19.765",
    airport_lon: "-47.964778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3671,
    airport_code: "UBJ",
    airport_name: "Ube Airport",
    airport_city_code: "UBJ",
    airport_city_name: "Ube Jp",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "33.93",
    airport_lon: "131.278611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3672,
    airport_code: "UBP",
    airport_name: "Muang Ubon Arpt",
    airport_city_code: "UBP",
    airport_city_name: "Ubon Ratchathani",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "15.251278",
    airport_lon: "104.870231",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3673,
    airport_code: "UBS",
    airport_name: "Lowndes Cty Arpt",
    airport_city_code: "UBS",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3674,
    airport_code: "GTR",
    airport_name: "Golden Arpt",
    airport_city_code: "UBS",
    airport_city_name: "Columbus",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "33.450333",
    airport_lon: "-88.591361",
    airport_num_airports: 2,
  },
  {
    airport_id: 3675,
    airport_code: "UCA",
    airport_name: "Oneida County Arpt",
    airport_city_code: "UCA",
    airport_city_name: "Utica",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "43.1451",
    airport_lon: "-75.3839",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3676,
    airport_code: "UCK",
    airport_name: "Lutsk Arpt",
    airport_city_code: "UCK",
    airport_city_name: "Lutsk",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3677,
    airport_code: "UCT",
    airport_name: "Ukhta Airport",
    airport_city_code: "UCT",
    airport_city_name: "Ukhta Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "63.340297",
    airport_lon: "53.482592",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3678,
    airport_code: "UDD",
    airport_name: "Bermuda Dunes Arpt",
    airport_city_code: "UDD",
    airport_city_name: "Palm Desert",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "33.748438",
    airport_lon: "-116.274813",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3679,
    airport_code: "UDE",
    airport_name: "Volkel Arpt",
    airport_city_code: "UDE",
    airport_city_name: "Uden",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "1",
    airport_lat: "51.656389",
    airport_lon: "5.708611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3680,
    airport_code: "UDI",
    airport_name: "Eduardo Gomes Airprt",
    airport_city_code: "UDI",
    airport_city_name: "Uberlandia",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-18.882844",
    airport_lon: "-48.225594",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3681,
    airport_code: "UDJ",
    airport_name: "Uzhhorod International Airport",
    airport_city_code: "UDJ",
    airport_city_name: "Uzhhorod International Airport",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.634278",
    airport_lon: "22.263356",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3682,
    airport_code: "UDN",
    airport_name: "Campoformido Arpt",
    airport_city_code: "UDN",
    airport_city_name: "Udine",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "45.9347",
    airport_lon: "12.9422",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3683,
    airport_code: "UDR",
    airport_name: "Maharana Pratap Arpt",
    airport_city_code: "UDR",
    airport_city_name: "Udaipur",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "24.617697",
    airport_lon: "73.8961",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3684,
    airport_code: "UEE",
    airport_name: "Queenstown Arpt",
    airport_city_code: "UEE",
    airport_city_name: "Queenstown",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3685,
    airport_code: "UEO",
    airport_name: "Kumejima Arpt",
    airport_city_code: "UEO",
    airport_city_name: "Kume Jima",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "26.363506",
    airport_lon: "126.713806",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3686,
    airport_code: "UFA",
    airport_name: "Ufa Arpt",
    airport_city_code: "UFA",
    airport_city_name: "Ufa RU",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "54.557511",
    airport_lon: "55.874417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3687,
    airport_code: "UGC",
    airport_name: "Urgench Arpt",
    airport_city_code: "UGC",
    airport_city_name: "Urgench",
    airport_country_name: "UZBEKISTAN",
    airport_country_code: "UZ",
    airport_timezone: "5",
    airport_lat: "41.5843",
    airport_lon: "60.6417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3688,
    airport_code: "UGN",
    airport_name: "Memorial Arpt",
    airport_city_code: "UGN",
    airport_city_name: "Waukegan",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "42.422161",
    airport_lon: "-87.867908",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3689,
    airport_code: "UHE",
    airport_name: "Uherske Hradiste Kunovice Arpt",
    airport_city_code: "UHE",
    airport_city_name: "Uherske Hradiste",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "-100",
    airport_lat: "49.0294",
    airport_lon: "17.4397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3690,
    airport_code: "UIH",
    airport_name: "Qui Nhon Arpt",
    airport_city_code: "UIH",
    airport_city_name: "Qui Nhon",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "13.954986",
    airport_lon: "109.042267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3691,
    airport_code: "UII",
    airport_name: "Utila Arpt",
    airport_city_code: "UII",
    airport_city_name: "Utila",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-6",
    airport_lat: "16.091667",
    airport_lon: "-86.8875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3692,
    airport_code: "UIK",
    airport_name: "Ust-Ilimsk Airport",
    airport_city_code: "UIK",
    airport_city_name: "Ust-Ilimsk Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "9",
    airport_lat: "58.08",
    airport_lon: "108.08",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3693,
    airport_code: "UIN",
    airport_name: "Baldwin Field",
    airport_city_code: "UIN",
    airport_city_name: "Quincy",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "39.9427",
    airport_lon: "-91.1946",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3694,
    airport_code: "UIO",
    airport_name: "Mariscal Arpt",
    airport_city_code: "UIO",
    airport_city_name: "Quito",
    airport_country_name: "ECUADOR",
    airport_country_code: "EC",
    airport_timezone: "-5",
    airport_lat: "-0.141144",
    airport_lon: "-78.488214",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3695,
    airport_code: "UIP",
    airport_name: "Pluguffan Arpt",
    airport_city_code: "UIP",
    airport_city_name: "Quimper",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.974981",
    airport_lon: "-4.167786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3696,
    airport_code: "UIR",
    airport_name: "Quirindi Arpt",
    airport_city_code: "UIR",
    airport_city_name: "Quirindi",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-31.4983",
    airport_lon: "150.5183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3697,
    airport_code: "UKB",
    airport_name: "Kobe Arpt",
    airport_city_code: "UKB",
    airport_city_name: "Kobe",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "34.632778",
    airport_lon: "135.223889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3698,
    airport_code: "UKI",
    airport_name: "Ukiah Arpt",
    airport_city_code: "UKI",
    airport_city_name: "Ukiah",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.126",
    airport_lon: "-123.2009",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3699,
    airport_code: "UKK",
    airport_name: "Ust Kamenogorsk Arpt",
    airport_city_code: "UKK",
    airport_city_name: "Ust Kamenogorsk",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "6",
    airport_lat: "50.0366",
    airport_lon: "82.4942",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3700,
    airport_code: "UKS",
    airport_name: "Belbek Arpt",
    airport_city_code: "UKS",
    airport_city_name: "Sevastopol",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "44.691431",
    airport_lon: "33.57567",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3701,
    airport_code: "UKT",
    airport_name: "Upper Bucks Arpt",
    airport_city_code: "UKT",
    airport_city_name: "Quakertown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3702,
    airport_code: "UKX",
    airport_name: "Ust-Kut Airport",
    airport_city_code: "UKX",
    airport_city_name: "Ust-Kut Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "9",
    airport_lat: "56.85",
    airport_lon: "105.7167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3703,
    airport_code: "UKY",
    airport_name: "Kyoto Arpt",
    airport_city_code: "UKY",
    airport_city_name: "Kyoto",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.016667",
    airport_lon: "135.766667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3704,
    airport_code: "ULM",
    airport_name: "New Ulm Arpt",
    airport_city_code: "ULM",
    airport_city_name: "New Ulm",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3705,
    airport_code: "ULN",
    airport_name: "Buyant Uhaa Airport",
    airport_city_code: "ULN",
    airport_city_name: "Ulaanbaatar",
    airport_country_name: "MONGOLIA",
    airport_country_code: "MN",
    airport_timezone: "8",
    airport_lat: "47.843056",
    airport_lon: "106.766639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3706,
    airport_code: "ULP",
    airport_name: "Quilpie Arpt",
    airport_city_code: "ULP",
    airport_city_name: "Quilpie",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-26.6122",
    airport_lon: "144.253",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3707,
    airport_code: "ULV",
    airport_name: "Ulyanovsk Baratayeveka Airport",
    airport_city_code: "ULV",
    airport_city_name: "Baratayeveka",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3708,
    airport_code: "ULY",
    airport_name: "Ulyanovsk Arpt",
    airport_city_code: "ULY",
    airport_city_name: "Ulyanovsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "54.401",
    airport_lon: "48.8027",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3709,
    airport_code: "UME",
    airport_name: "Umea Airport",
    airport_city_code: "UME",
    airport_city_name: "Umea",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "63.791828",
    airport_lon: "20.282758",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3710,
    airport_code: "UMU",
    airport_name: "Ernesto Geisel Arpt",
    airport_city_code: "UMU",
    airport_city_name: "Umuarama",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-23.798611",
    airport_lon: "-53.313611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3711,
    airport_code: "UMY",
    airport_name: "Sumy Arpt",
    airport_city_code: "UMY",
    airport_city_name: "Sumy",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "-100",
    airport_lat: "50.8583",
    airport_lon: "34.7625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3712,
    airport_code: "UNA",
    airport_name: "Una Airport",
    airport_city_code: "UNA",
    airport_city_name: "Una BR",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-4",
    airport_lat: "-15.355278",
    airport_lon: "-38.998889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3713,
    airport_code: "UNI",
    airport_name: "Union Island Arpt",
    airport_city_code: "UNI",
    airport_city_name: "Union Island",
    airport_country_name: "SAINT VINCENT AND THE GRENADINES",
    airport_country_code: "VC",
    airport_timezone: "-4",
    airport_lat: "12.583",
    airport_lon: "-61.417",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3714,
    airport_code: "UNK",
    airport_name: "Unalakleet Arpt",
    airport_city_code: "UNK",
    airport_city_name: "Unalakleet",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "63.888333",
    airport_lon: "-160.798889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3715,
    airport_code: "UNN",
    airport_name: "Ranong Arpt",
    airport_city_code: "UNN",
    airport_city_name: "Ranong",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "9.777622",
    airport_lon: "98.585483",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3716,
    airport_code: "UNT",
    airport_name: "Baltasound Arpt",
    airport_city_code: "UNT",
    airport_city_name: "Unst",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "60.7472",
    airport_lon: "-0.85385",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3717,
    airport_code: "UOX",
    airport_name: "University Oxford",
    airport_city_code: "UOX",
    airport_city_name: "University Oxford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3718,
    airport_code: "UPG",
    airport_name: "Hasanudin Arpt",
    airport_city_code: "UPG",
    airport_city_name: "Ujung Pandang",
    airport_country_name: "INDONESIA",
    airport_country_code: "ID",
    airport_timezone: "8",
    airport_lat: "-5.061631",
    airport_lon: "119.554042",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3719,
    airport_code: "UPN",
    airport_name: "Uruapan Arpt",
    airport_city_code: "UPN",
    airport_city_name: "Uruapan",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.396692",
    airport_lon: "-102.039056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3720,
    airport_code: "URA",
    airport_name: "Uralsk Arpt",
    airport_city_code: "URA",
    airport_city_name: "Uralsk",
    airport_country_name: "KAZAKHSTAN",
    airport_country_code: "KZ",
    airport_timezone: "5",
    airport_lat: "51.150833",
    airport_lon: "51.543056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3721,
    airport_code: "URC",
    airport_name: "Urumqi Arpt",
    airport_city_code: "URC",
    airport_city_name: "Urumqi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "43.907106",
    airport_lon: "87.474244",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3722,
    airport_code: "URE",
    airport_name: "Kuressaare Arpt",
    airport_city_code: "URE",
    airport_city_name: "Kuressaare",
    airport_country_name: "ESTONIA",
    airport_country_code: "EE",
    airport_timezone: "2",
    airport_lat: "58.229883",
    airport_lon: "22.509494",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3723,
    airport_code: "URG",
    airport_name: "Ruben Berta Arpt",
    airport_city_code: "URG",
    airport_city_name: "Uruguaina",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-29.782178",
    airport_lon: "-57.038189",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3724,
    airport_code: "URJ",
    airport_name: "Urai Airport",
    airport_city_code: "URJ",
    airport_city_name: "Urai Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "60.1",
    airport_lon: "64.83",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3725,
    airport_code: "URO",
    airport_name: "Boos Airport",
    airport_city_code: "URO",
    airport_city_name: "Rouen",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "49.384172",
    airport_lon: "1.1748",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3726,
    airport_code: "URS",
    airport_name: "Kursk Arpt",
    airport_city_code: "URS",
    airport_city_name: "Kursk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "51.7506",
    airport_lon: "36.2956",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3727,
    airport_code: "URT",
    airport_name: "Surat Thani Arpt",
    airport_city_code: "URT",
    airport_city_name: "Surat Thani",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "9.1325",
    airport_lon: "99.135556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3728,
    airport_code: "URY",
    airport_name: "Gurayat Arpt",
    airport_city_code: "URY",
    airport_city_name: "Gurayat",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "31.411942",
    airport_lon: "37.279469",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3729,
    airport_code: "USH",
    airport_name: "Islas Malvinas Arpt",
    airport_city_code: "USH",
    airport_city_name: "Ushuaia",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-54.843278",
    airport_lon: "-68.29575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3730,
    airport_code: "USK",
    airport_name: "Usinsk Arpt",
    airport_city_code: "USK",
    airport_city_name: "Usinsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "66.00077",
    airport_lon: "57.221113",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3731,
    airport_code: "USL",
    airport_name: "Useless Loop Arpt",
    airport_city_code: "USL",
    airport_city_name: "Useless Loop",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3732,
    airport_code: "USM",
    airport_name: "Koh Samui Arpt",
    airport_city_code: "USM",
    airport_city_name: "Koh Samui",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "9.547794",
    airport_lon: "100.062272",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3733,
    airport_code: "USN",
    airport_name: "Ulsan Arpt",
    airport_city_code: "USN",
    airport_city_name: "Ulsan",
    airport_country_name: "KOREA, REPUBLIC OF",
    airport_country_code: "KR",
    airport_timezone: "9",
    airport_lat: "35.593494",
    airport_lon: "129.351722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3734,
    airport_code: "USQ",
    airport_name: "Usak Airport",
    airport_city_code: "USQ",
    airport_city_name: "Usak",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.681478",
    airport_lon: "29.471675",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3735,
    airport_code: "USS",
    airport_name: "Sancti Spiritus Arpt",
    airport_city_code: "USS",
    airport_city_name: "Sancti Spiritus",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3736,
    airport_code: "UST",
    airport_name: "St Augustine Arpt",
    airport_city_code: "UST",
    airport_city_name: "St Augustine",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "29.959167",
    airport_lon: "-81.339722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3737,
    airport_code: "UTA",
    airport_name: "Mutare Arpt",
    airport_city_code: "UTA",
    airport_city_name: "Mutare",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3738,
    airport_code: "ZYU",
    airport_name: "Utrecht Rail Station",
    airport_city_code: "UTC",
    airport_city_name: "Utrecht",
    airport_country_name: "NETHERLANDS",
    airport_country_code: "NL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 3739,
    airport_code: "UTH",
    airport_name: "Udon Thani Arpt",
    airport_city_code: "UTH",
    airport_city_name: "Udon Thani",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "17.386436",
    airport_lon: "102.788247",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3740,
    airport_code: "UTN",
    airport_name: "Upington Airport",
    airport_city_code: "UTN",
    airport_city_name: "Upington",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-28.399097",
    airport_lon: "21.260239",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3741,
    airport_code: "UTP",
    airport_name: "U Tapao Arpt",
    airport_city_code: "UTP",
    airport_city_name: "U Tapao",
    airport_country_name: "THAILAND",
    airport_country_code: "TH",
    airport_timezone: "7",
    airport_lat: "12.679944",
    airport_lon: "101.005028",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3742,
    airport_code: "UTT",
    airport_name: "Umtata Arpt",
    airport_city_code: "UTT",
    airport_city_name: "Umtata",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-31.547903",
    airport_lon: "28.674289",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3743,
    airport_code: "UTW",
    airport_name: "Queenstown Airport",
    airport_city_code: "UTW",
    airport_city_name: "Queenstown",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-31.920197",
    airport_lon: "26.882206",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3744,
    airport_code: "UUA",
    airport_name: "Bugulma Airport",
    airport_city_code: "UUA",
    airport_city_name: "Bugulma Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "54.64",
    airport_lon: "52.8017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3745,
    airport_code: "UUD",
    airport_name: "Ulan Ude Arpt",
    airport_city_code: "UUD",
    airport_city_name: "Ulan Ude",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "9",
    airport_lat: "51.807764",
    airport_lon: "107.437644",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3746,
    airport_code: "UUS",
    airport_name: "Yuzhno Sakhalinsk Arpt",
    airport_city_code: "UUS",
    airport_city_name: "Yuzhno Sakhalinsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "11",
    airport_lat: "46.888672",
    airport_lon: "142.717531",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3747,
    airport_code: "UVA",
    airport_name: "Garner Field",
    airport_city_code: "UVA",
    airport_city_name: "Uvalde",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "29.215429",
    airport_lon: "-99.748962",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3748,
    airport_code: "UVF",
    airport_name: "Hewanorra Arpt",
    airport_city_code: "UVF",
    airport_city_name: "Vieux Fort St Lucia",
    airport_country_name: "SAINT LUCIA",
    airport_country_code: "LC",
    airport_timezone: "-4",
    airport_lat: "13.733194",
    airport_lon: "-60.952597",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3749,
    airport_code: "UVL",
    airport_name: "Kharga Arpt",
    airport_city_code: "UVL",
    airport_city_name: "Kharga",
    airport_country_name: "EGYPT",
    airport_country_code: "EG",
    airport_timezone: "-100",
    airport_lat: "25.4736",
    airport_lon: "30.5908",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3750,
    airport_code: "WIE",
    airport_name: "Air Base",
    airport_city_code: "UWE",
    airport_city_name: "Wiesbaden",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "50.05",
    airport_lon: "8.325",
    airport_num_airports: 1,
  },
  {
    airport_id: 3751,
    airport_code: "UYL",
    airport_name: "Nyala Arpt",
    airport_city_code: "UYL",
    airport_city_name: "Nyala",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "2",
    airport_lat: "12.0535",
    airport_lon: "24.9562",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3752,
    airport_code: "VAA",
    airport_name: "Vaasa Arpt",
    airport_city_code: "VAA",
    airport_city_name: "Vaasa",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "63.05065",
    airport_lon: "21.762175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3753,
    airport_code: "XHK",
    airport_name: "Gare de Valence TGV",
    airport_city_code: "VAF",
    airport_city_name: "Valence",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "0",
    airport_lat: "-100",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3754,
    airport_code: "VAF",
    airport_name: "Chabeuil Airport",
    airport_city_code: "VAF",
    airport_city_name: "Valence",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "44.921594",
    airport_lon: "4.9699",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3755,
    airport_code: "VAG",
    airport_name: "Maj Brig Trompowsky Arpt",
    airport_city_code: "VAG",
    airport_city_name: "Varginha",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-21.590067",
    airport_lon: "-45.473342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3756,
    airport_code: "VAI",
    airport_name: "Vanimo Arpt",
    airport_city_code: "VAI",
    airport_city_name: "Vanimo",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-2.69717",
    airport_lon: "141.302",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3757,
    airport_code: "VAN",
    airport_name: "Van Arpt",
    airport_city_code: "VAN",
    airport_city_name: "Van TR",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "38.468219",
    airport_lon: "43.3323",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3758,
    airport_code: "VAR",
    airport_name: "Varna",
    airport_city_code: "VAR",
    airport_city_name: "Varna",
    airport_country_name: "BULGARIA",
    airport_country_code: "BG",
    airport_timezone: "2",
    airport_lat: "43.232072",
    airport_lon: "27.825106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3759,
    airport_code: "VAS",
    airport_name: "Sivas Arpt",
    airport_city_code: "VAS",
    airport_city_name: "Sivas",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "39.813828",
    airport_lon: "36.903497",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3760,
    airport_code: "VAV",
    airport_name: "Lupepau U Arpt",
    airport_city_code: "VAV",
    airport_city_name: "Vava U",
    airport_country_name: "TONGA",
    airport_country_code: "TO",
    airport_timezone: "13",
    airport_lat: "-18.585336",
    airport_lon: "-173.961717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3761,
    airport_code: "VAW",
    airport_name: "Vardoe Luftan",
    airport_city_code: "VAW",
    airport_city_name: "Vardoe",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "70.355392",
    airport_lon: "31.044889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3762,
    airport_code: "VBY",
    airport_name: "Visby Airport",
    airport_city_code: "VBY",
    airport_city_name: "Visby",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "57.662797",
    airport_lon: "18.346211",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3763,
    airport_code: "VCA",
    airport_name: "Can Tho Arpt",
    airport_city_code: "VCA",
    airport_city_name: "Can Tho",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "10.085119",
    airport_lon: "105.711922",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3764,
    airport_code: "VCE",
    airport_name: "Marco Polo Arpt",
    airport_city_code: "VCE",
    airport_city_name: "Venice",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.505278",
    airport_lon: "12.351944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3765,
    airport_code: "VCL",
    airport_name: "Chulai Arpt",
    airport_city_code: "VCL",
    airport_city_name: "Tamky",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "15.405944",
    airport_lon: "108.705889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3766,
    airport_code: "VCT",
    airport_name: "Victoria Regional Arpt",
    airport_city_code: "VCT",
    airport_city_name: "Victoria",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "28.8526",
    airport_lon: "-96.9185",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3767,
    airport_code: "VCV",
    airport_name: "George AFB",
    airport_city_code: "VCV",
    airport_city_name: "Victorville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "34.597453",
    airport_lon: "-117.382997",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3768,
    airport_code: "VDA",
    airport_name: "Ovda Arpt",
    airport_city_code: "VDA",
    airport_city_name: "Ovda",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "2",
    airport_lat: "29.94025",
    airport_lon: "34.93585",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3769,
    airport_code: "VDB",
    airport_name: "Valdres Arpt",
    airport_city_code: "VDB",
    airport_city_name: "Fagernes",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "61.015556",
    airport_lon: "9.288056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3770,
    airport_code: "VDC",
    airport_name: "Vitoria Da Conquista Arpt",
    airport_city_code: "VDC",
    airport_city_name: "Vitoria Da Conquista",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-14.862761",
    airport_lon: "-40.863106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3771,
    airport_code: "VDE",
    airport_name: "Hierro Arpt",
    airport_city_code: "VDE",
    airport_city_name: "Valverde",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "0",
    airport_lat: "27.814847",
    airport_lon: "-17.887056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3772,
    airport_code: "VDI",
    airport_name: "Vidalia Municipal Arpt",
    airport_city_code: "VDI",
    airport_city_name: "Vidalia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3773,
    airport_code: "VDM",
    airport_name: "Viedma Arpt",
    airport_city_code: "VDM",
    airport_city_name: "Viedma",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-40.869222",
    airport_lon: "-63.000389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3774,
    airport_code: "VDS",
    airport_name: "Vadso",
    airport_city_code: "VDS",
    airport_city_name: "Vadso",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "1",
    airport_lat: "70.065",
    airport_lon: "29.844",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3775,
    airport_code: "VDZ",
    airport_name: "Valdez Municipal Arpt",
    airport_city_code: "VDZ",
    airport_city_name: "Valdez",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "61.133949",
    airport_lon: "-146.248342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3776,
    airport_code: "VEJ",
    airport_name: "Vejle Arpt",
    airport_city_code: "VEJ",
    airport_city_name: "Vejle",
    airport_country_name: "DENMARK",
    airport_country_code: "DK",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3777,
    airport_code: "VEL",
    airport_name: "Vernal Municipal",
    airport_city_code: "VEL",
    airport_city_name: "Vernal",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "40.440833",
    airport_lon: "-109.51",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3778,
    airport_code: "VER",
    airport_name: "Las Bajadas General Heriberto Jara",
    airport_city_code: "VER",
    airport_city_name: "Veracruz",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.145931",
    airport_lon: "-96.187267",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3779,
    airport_code: "VEY",
    airport_name: "Vestmannaeyjar Arpt",
    airport_city_code: "VEY",
    airport_city_name: "Vestmannaeyjar",
    airport_country_name: "ICELAND",
    airport_country_code: "IS",
    airport_timezone: "0",
    airport_lat: "63.424303",
    airport_lon: "-20.278875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3780,
    airport_code: "VFA",
    airport_name: "Victoria Falls Arpt",
    airport_city_code: "VFA",
    airport_city_name: "Victoria Falls",
    airport_country_name: "ZIMBABWE",
    airport_country_code: "ZW",
    airport_timezone: "2",
    airport_lat: "-18.095881",
    airport_lon: "25.839006",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3781,
    airport_code: "VGA",
    airport_name: "Vijayawada Arpt",
    airport_city_code: "VGA",
    airport_city_name: "Vijayawada",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "16.530433",
    airport_lon: "80.796847",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3782,
    airport_code: "VGD",
    airport_name: "Vologda Arpt",
    airport_city_code: "VGD",
    airport_city_name: "Vologda",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "59.281667",
    airport_lon: "39.946667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3783,
    airport_code: "VGO",
    airport_name: "Vigo Airport",
    airport_city_code: "VGO",
    airport_city_name: "Vigo",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "42.2318",
    airport_lon: "-8.626775",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3784,
    airport_code: "VHM",
    airport_name: "Vilhelmina Arpt",
    airport_city_code: "VHM",
    airport_city_name: "Vilhelmina",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "64.579083",
    airport_lon: "16.833575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3785,
    airport_code: "VHN",
    airport_name: "Culberson Cty Arpt",
    airport_city_code: "VHN",
    airport_city_name: "Van Horn",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3786,
    airport_code: "VHY",
    airport_name: "Charmeil Arpt",
    airport_city_code: "VHY",
    airport_city_name: "Vichy",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "46.169689",
    airport_lon: "3.403736",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3787,
    airport_code: "VIB",
    airport_name: "Villa Constitucion Arpt",
    airport_city_code: "VIB",
    airport_city_name: "Villa Constitucion",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3788,
    airport_code: "VIC",
    airport_name: "Vicenza Arpt",
    airport_city_code: "VIC",
    airport_city_name: "Vicenza",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.573411",
    airport_lon: "11.52955",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3789,
    airport_code: "VDD",
    airport_name: "Vienna Danubepier Arpt",
    airport_city_code: "VIE",
    airport_city_name: "Vienna",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "2",
    airport_lat: "48.2",
    airport_lon: "16.366667",
    airport_num_airports: 2,
  },
  {
    airport_id: 3790,
    airport_code: "VIE",
    airport_name: "Vienna Intl Arpt",
    airport_city_code: "VIE",
    airport_city_name: "Vienna",
    airport_country_name: "AUSTRIA",
    airport_country_code: "AT",
    airport_timezone: "1",
    airport_lat: "48.110278",
    airport_lon: "16.569722",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3791,
    airport_code: "VIF",
    airport_name: "Vieste Arpt",
    airport_city_code: "VIF",
    airport_city_name: "Vieste",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3792,
    airport_code: "VIG",
    airport_name: "El Vigia Arpt",
    airport_city_code: "VIG",
    airport_city_name: "El Vigia",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "8.6241",
    airport_lon: "-71.672819",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3793,
    airport_code: "VII",
    airport_name: "Vinh City Arpt",
    airport_city_code: "VII",
    airport_city_name: "Vinh City",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "18.737569",
    airport_lon: "105.670764",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3794,
    airport_code: "VIJ",
    airport_name: "Virgin Gorda Arpt",
    airport_city_code: "VIJ",
    airport_city_name: "Virgin Gorda",
    airport_country_name: "VIRGIN ISLANDS, BRITISH",
    airport_country_code: "VG",
    airport_timezone: "-4",
    airport_lat: "18.4464",
    airport_lon: "-64.4275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3795,
    airport_code: "VIL",
    airport_name: "Dakhla Airport",
    airport_city_code: "VIL",
    airport_city_name: "Dakhla",
    airport_country_name: "MOROCCO",
    airport_country_code: "MA",
    airport_timezone: "0",
    airport_lat: "23.7183",
    airport_lon: "-15.932",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3796,
    airport_code: "VIN",
    airport_name: "Vinnitsa Arpt",
    airport_city_code: "VIN",
    airport_city_name: "Vinnitsa",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "49.2433",
    airport_lon: "28.6063",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3797,
    airport_code: "VIS",
    airport_name: "Visalia Municipal",
    airport_city_code: "VIS",
    airport_city_name: "Visalia",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "36.318611",
    airport_lon: "-119.392778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3798,
    airport_code: "VIT",
    airport_name: "Vitoria Arpt",
    airport_city_code: "VIT",
    airport_city_name: "Vitoria",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "42.882836",
    airport_lon: "-2.724469",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3799,
    airport_code: "VIX",
    airport_name: "Eurico Sales Arpt",
    airport_city_code: "VIX",
    airport_city_name: "Vitoria",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-20.258056",
    airport_lon: "-40.286389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3800,
    airport_code: "VJI",
    airport_name: "Virginia Highlands Arpt",
    airport_city_code: "VJI",
    airport_city_name: "Abingdon",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3801,
    airport_code: "VKG",
    airport_name: "Rach Gia Arpt",
    airport_city_code: "VKG",
    airport_city_name: "Rach Gia",
    airport_country_name: "VIET NAM",
    airport_country_code: "VN",
    airport_timezone: "7",
    airport_lat: "9.949676",
    airport_lon: "105.133659",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3802,
    airport_code: "VKS",
    airport_name: "Vicksburg Arpt",
    airport_city_code: "VKS",
    airport_city_name: "Vicksburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3803,
    airport_code: "VKT",
    airport_name: "Vorkuta Airport",
    airport_city_code: "VKT",
    airport_city_name: "Vorkuta",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "6",
    airport_lat: "67.4886",
    airport_lon: "63.9931",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3804,
    airport_code: "VLC",
    airport_name: "Valencia Arpt",
    airport_city_code: "VLC",
    airport_city_name: "Valencia",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "39.489314",
    airport_lon: "-0.481625",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3805,
    airport_code: "VLD",
    airport_name: "Valdosta Regional",
    airport_city_code: "VLD",
    airport_city_name: "Valdosta",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "30.7825",
    airport_lon: "-83.2767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3806,
    airport_code: "VLG",
    airport_name: "Villa Gesell Arpt",
    airport_city_code: "VLG",
    airport_city_name: "Villa Gesell",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-3",
    airport_lat: "-37.235408",
    airport_lon: "-57.029239",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3807,
    airport_code: "VLI",
    airport_name: "Bauerfield Arpt",
    airport_city_code: "VLI",
    airport_city_name: "Port Vila",
    airport_country_name: "VANUATU",
    airport_country_code: "VU",
    airport_timezone: "11",
    airport_lat: "-17.699325",
    airport_lon: "168.319794",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3808,
    airport_code: "VLL",
    airport_name: "Valladolid Arpt",
    airport_city_code: "VLL",
    airport_city_name: "Valladolid",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "41.706111",
    airport_lon: "-4.851944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3809,
    airport_code: "VLN",
    airport_name: "Valenica Arpt",
    airport_city_code: "VLN",
    airport_city_name: "Valencia",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "10.149733",
    airport_lon: "-67.9284",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3810,
    airport_code: "VLO",
    airport_name: "Stolport Arpt",
    airport_city_code: "VLO",
    airport_city_name: "Vallejo",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3811,
    airport_code: "VLU",
    airport_name: "Velikiye Luki Airport",
    airport_city_code: "VLU",
    airport_city_name: "Velikiye Luki Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3812,
    airport_code: "VLV",
    airport_name: "Carvajal Arpt",
    airport_city_code: "VLV",
    airport_city_name: "Valera",
    airport_country_name: "VENEZUELA",
    airport_country_code: "VE",
    airport_timezone: "-4",
    airport_lat: "9.340797",
    airport_lon: "-70.584089",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3813,
    airport_code: "VLY",
    airport_name: "Anglesey Arpt",
    airport_city_code: "VLY",
    airport_city_name: "Anglesey",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "53.2481",
    airport_lon: "-4.5353",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3814,
    airport_code: "VME",
    airport_name: "Villa Mercedes Arpt",
    airport_city_code: "VME",
    airport_city_name: "Villa Mercedes",
    airport_country_name: "ARGENTINA",
    airport_country_code: "AR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3815,
    airport_code: "VMI",
    airport_name: "Inc Arpt",
    airport_city_code: "VMI",
    airport_city_name: "Vallemi",
    airport_country_name: "PARAGUAY",
    airport_country_code: "PY",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3816,
    airport_code: "VNC",
    airport_name: "Venice Municipal Airport",
    airport_city_code: "VNC",
    airport_city_name: "Venice",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3817,
    airport_code: "VNE",
    airport_name: "Muecon Arpt",
    airport_city_code: "VNE",
    airport_city_name: "Vannes",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "1",
    airport_lat: "47.723303",
    airport_lon: "-2.718561",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3818,
    airport_code: "VNO",
    airport_name: "Vilnius Arpt",
    airport_city_code: "VNO",
    airport_city_name: "Vilnius",
    airport_country_name: "LITHUANIA",
    airport_country_code: "LT",
    airport_timezone: "2",
    airport_lat: "54.634133",
    airport_lon: "25.285767",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3819,
    airport_code: "VNS",
    airport_name: "Lal Bahadur Shastri Arpt",
    airport_city_code: "VNS",
    airport_city_name: "Varanasi",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "5",
    airport_lat: "25.452358",
    airport_lon: "82.859342",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3820,
    airport_code: "VNT",
    airport_name: "Ventspils Intl Arpt",
    airport_city_code: "VNT",
    airport_city_name: "Ventspils",
    airport_country_name: "LATVIA",
    airport_country_code: "LV",
    airport_timezone: "-100",
    airport_lat: "57.3578",
    airport_lon: "21.5442",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3821,
    airport_code: "VNX",
    airport_name: "Vilanculos Arpt",
    airport_city_code: "VNX",
    airport_city_name: "Vilanculos",
    airport_country_name: "MOZAMBIQUE",
    airport_country_code: "MZ",
    airport_timezone: "2",
    airport_lat: "-22.018431",
    airport_lon: "35.313297",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3822,
    airport_code: "VOG",
    airport_name: "Volgograd Arpt",
    airport_city_code: "VOG",
    airport_city_name: "Volgograd",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "48.782528",
    airport_lon: "44.345544",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3823,
    airport_code: "VOL",
    airport_name: "Nea Anchialos Arpt",
    airport_city_code: "VOL",
    airport_city_name: "Volos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "39.219619",
    airport_lon: "22.794339",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3824,
    airport_code: "VOZ",
    airport_name: "Voronezh Arpt",
    airport_city_code: "VOZ",
    airport_city_name: "Voronezh",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "4",
    airport_lat: "51.814211",
    airport_lon: "39.229589",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3825,
    airport_code: "VPS",
    airport_name: "Ft Walton Beach Arpt",
    airport_city_code: "VPS",
    airport_city_name: "Valparaiso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-6",
    airport_lat: "30.48325",
    airport_lon: "-86.5254",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3826,
    airport_code: "EGI",
    airport_name: "Duke Field",
    airport_city_code: "VPS",
    airport_city_name: "Valparaiso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3827,
    airport_code: "VPZ",
    airport_name: "Porter County",
    airport_city_code: "VPZ",
    airport_city_name: "Valparaiso",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3828,
    airport_code: "VQS",
    airport_name: "Vieques Arpt",
    airport_city_code: "VQS",
    airport_city_name: "Vieques",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-4",
    airport_lat: "18.1158",
    airport_lon: "-65.4227",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3829,
    airport_code: "VRA",
    airport_name: "Juan Gualberto Gomez Arpt",
    airport_city_code: "VRA",
    airport_city_name: "Varadero",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-5",
    airport_lat: "23.034445",
    airport_lon: "-81.435278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3830,
    airport_code: "VRB",
    airport_name: "Vero Beach Municipal Arpt",
    airport_city_code: "VRB",
    airport_city_name: "Vero Beach",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "27.655556",
    airport_lon: "-80.417944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3831,
    airport_code: "VRK",
    airport_name: "Varkaus",
    airport_city_code: "VRK",
    airport_city_name: "Varkaus",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "2",
    airport_lat: "62.171111",
    airport_lon: "27.868611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3832,
    airport_code: "VRL",
    airport_name: "Vila Real Arpt",
    airport_city_code: "VRL",
    airport_city_name: "Vila Real",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "0",
    airport_lat: "41.274334",
    airport_lon: "-7.720472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3833,
    airport_code: "VRN",
    airport_name: "Verona Airport",
    airport_city_code: "VRN",
    airport_city_name: "Verona",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.395706",
    airport_lon: "10.888533",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3834,
    airport_code: "VBS",
    airport_name: "Montichiari Arpt",
    airport_city_code: "VRN",
    airport_city_name: "Verona",
    airport_country_name: "ITALY",
    airport_country_code: "IT",
    airport_timezone: "1",
    airport_lat: "45.428889",
    airport_lon: "10.330556",
    airport_num_airports: 2,
  },
  {
    airport_id: 3835,
    airport_code: "VRO",
    airport_name: "Kawama Arpt",
    airport_city_code: "VRO",
    airport_city_name: "Matanzas",
    airport_country_name: "CUBA",
    airport_country_code: "CU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3836,
    airport_code: "VSA",
    airport_name: "Capt Carlos Rovirosa Perez",
    airport_city_code: "VSA",
    airport_city_name: "Villahermosa",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "17.997",
    airport_lon: "-92.817361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3837,
    airport_code: "VSE",
    airport_name: "Viseu Arpt",
    airport_city_code: "VSE",
    airport_city_name: "Viseu",
    airport_country_name: "PORTUGAL",
    airport_country_code: "PT",
    airport_timezone: "-100",
    airport_lat: "40.7255",
    airport_lon: "-7.889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3838,
    airport_code: "VSG",
    airport_name: "Lugansk Arpt",
    airport_city_code: "VSG",
    airport_city_name: "Lugansk",
    airport_country_name: "UKRAINE",
    airport_country_code: "UA",
    airport_timezone: "2",
    airport_lat: "48.4174",
    airport_lon: "39.3741",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3839,
    airport_code: "XYX",
    airport_name: "Sala Rail Station",
    airport_city_code: "VST",
    airport_city_name: "Vasteras",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
  },
  {
    airport_id: 3840,
    airport_code: "VTB",
    airport_name: "Vitebsk Arpt",
    airport_city_code: "VTB",
    airport_city_name: "Vitebsk",
    airport_country_name: "BELARUS",
    airport_country_code: "BY",
    airport_timezone: "2",
    airport_lat: "55.1265",
    airport_lon: "30.349639",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3841,
    airport_code: "VTE",
    airport_name: "Wattay Arpt",
    airport_city_code: "VTE",
    airport_city_name: "Vientiane",
    airport_country_name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    airport_country_code: "LA",
    airport_timezone: "7",
    airport_lat: "17.988322",
    airport_lon: "102.563256",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3842,
    airport_code: "VTZ",
    airport_name: "Vishakhapatnam",
    airport_city_code: "VTZ",
    airport_city_name: "Vishakhapatanam",
    airport_country_name: "INDIA",
    airport_country_code: "IN",
    airport_timezone: "6",
    airport_lat: "17.721167",
    airport_lon: "83.224483",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3843,
    airport_code: "VUP",
    airport_name: "Valledupar Arpt",
    airport_city_code: "VUP",
    airport_city_name: "Valleduper",
    airport_country_name: "COLOMBIA",
    airport_country_code: "CO",
    airport_timezone: "-5",
    airport_lat: "10.435042",
    airport_lon: "-73.249506",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3844,
    airport_code: "VUS",
    airport_name: "Velikiy Ustyug Airport",
    airport_city_code: "VUS",
    airport_city_name: "Velikiy Ustyug Airport",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "-2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3845,
    airport_code: "VVO",
    airport_name: "Vladivostok Arpt",
    airport_city_code: "VVO",
    airport_city_name: "Vladivostok",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "11",
    airport_lat: "43.398953",
    airport_lon: "132.148017",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3846,
    airport_code: "VXE",
    airport_name: "San Pedro Airport",
    airport_city_code: "VXE",
    airport_city_name: "Sao Vicente",
    airport_country_name: "CAPE VERDE",
    airport_country_code: "CV",
    airport_timezone: "-1",
    airport_lat: "16.833689",
    airport_lon: "-25.054661",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3847,
    airport_code: "VXO",
    airport_name: "Vaxjo Airport",
    airport_city_code: "VXO",
    airport_city_name: "Vaxjo",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "1",
    airport_lat: "56.929144",
    airport_lon: "14.727994",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3848,
    airport_code: "VYD",
    airport_name: "Vryheid Arpt",
    airport_city_code: "VYD",
    airport_city_name: "Vryheid",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3849,
    airport_code: "VYS",
    airport_name: "Illinois Valley Regional Arpt",
    airport_city_code: "VYS",
    airport_city_name: "Peru",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "41.3519",
    airport_lon: "-89.1531",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3850,
    airport_code: "WAG",
    airport_name: "Wanganui Arpt",
    airport_city_code: "WAG",
    airport_city_name: "Wanganui",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-39.962222",
    airport_lon: "175.025278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3851,
    airport_code: "WAL",
    airport_name: "Wallops Arpt",
    airport_city_code: "WAL",
    airport_city_name: "Chincoteague",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "37.940194",
    airport_lon: "-75.466389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3852,
    airport_code: "DCA",
    airport_name: "Ronald Reagan National Arpt",
    airport_city_code: "WAS",
    airport_city_name: "Washington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.852083",
    airport_lon: "-77.037722",
    airport_num_airports: 2,
  },
  {
    airport_id: 3853,
    airport_code: "IAD",
    airport_name: "Washington Dulles Intl",
    airport_city_code: "WAS",
    airport_city_name: "Washington",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "38.944533",
    airport_lon: "-77.455811",
    airport_num_airports: 2,
  },
  {
    airport_id: 3854,
    airport_code: "WAT",
    airport_name: "Waterford Arpt",
    airport_city_code: "WAT",
    airport_city_name: "Waterford",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "0",
    airport_lat: "52.1872",
    airport_lon: "-7.086964",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3855,
    airport_code: "WAW",
    airport_name: "Warsaw Intl Arpt",
    airport_city_code: "WAW",
    airport_city_name: "Warsaw",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "52.16575",
    airport_lon: "20.967122",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3856,
    airport_code: "WAY",
    airport_name: "Green County Airport",
    airport_city_code: "WAY",
    airport_city_name: "Waynesburg",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3857,
    airport_code: "WAZ",
    airport_name: "Warwick Arpt",
    airport_city_code: "WAZ",
    airport_city_name: "Warwick",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3858,
    airport_code: "WBU",
    airport_name: "Boulder Municipal Arpt",
    airport_city_code: "WBU",
    airport_city_name: "Boulder",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3859,
    airport_code: "WDG",
    airport_name: "Woodring Municipal",
    airport_city_code: "WDG",
    airport_city_name: "Enid",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3860,
    airport_code: "WDH",
    airport_name: "Hosea Kutako International Arpt",
    airport_city_code: "WDH",
    airport_city_name: "Windhoek",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-22.486667",
    airport_lon: "17.4625",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3861,
    airport_code: "ERS",
    airport_name: "Eros Arpt",
    airport_city_code: "WDH",
    airport_city_name: "Windhoek",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-22.6122",
    airport_lon: "17.0804",
    airport_num_airports: 2,
  },
  {
    airport_id: 3862,
    airport_code: "WEA",
    airport_name: "Parker County Airport",
    airport_city_code: "WEA",
    airport_city_name: "Weatherford",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3863,
    airport_code: "WEF",
    airport_name: "Weifang Arpt",
    airport_city_code: "WEF",
    airport_city_name: "Weifang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "36.6467",
    airport_lon: "119.119",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3864,
    airport_code: "WEH",
    airport_name: "Weihai Arpu",
    airport_city_code: "WEH",
    airport_city_name: "Weihai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "37.1871",
    airport_lon: "122.229",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3865,
    airport_code: "WEI",
    airport_name: "Weipa Arpt",
    airport_city_code: "WEI",
    airport_city_name: "Weipa",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-12.678611",
    airport_lon: "141.925278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3866,
    airport_code: "WEL",
    airport_name: "Welkom Arpt",
    airport_city_code: "WEL",
    airport_city_name: "Welkom",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "2",
    airport_lat: "-27.998",
    airport_lon: "26.669586",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3867,
    airport_code: "WEW",
    airport_name: "Wee Waa Airport",
    airport_city_code: "WEW",
    airport_city_name: "Wee Waa",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3868,
    airport_code: "WEX",
    airport_name: "Castlebridge Arpt",
    airport_city_code: "WEX",
    airport_city_name: "Wexford",
    airport_country_name: "IRELAND",
    airport_country_code: "IE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3869,
    airport_code: "WGA",
    airport_name: "Forrest Hill Arpt",
    airport_city_code: "WGA",
    airport_city_name: "Wagga Wagga",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-35.165278",
    airport_lon: "147.466389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3870,
    airport_code: "WGE",
    airport_name: "Walgett Arpt",
    airport_city_code: "WGE",
    airport_city_name: "Walgett",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-30.0318",
    airport_lon: "148.1222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3871,
    airport_code: "WGO",
    airport_name: "Winchester Arpt",
    airport_city_code: "WGO",
    airport_city_name: "Winchester",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3872,
    airport_code: "WGT",
    airport_name: "Wangaratta",
    airport_city_code: "WGT",
    airport_city_name: "Wangaratta",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-36.4175",
    airport_lon: "146.3056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3873,
    airport_code: "WHK",
    airport_name: "Whakatane Arpt",
    airport_city_code: "WHK",
    airport_city_name: "Whakatane",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-37.920556",
    airport_lon: "176.914167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3874,
    airport_code: "WHL",
    airport_name: "Welshpool Arpt",
    airport_city_code: "WHL",
    airport_city_name: "Welshpool",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3875,
    airport_code: "WIC",
    airport_name: "Wick Arpt",
    airport_city_code: "WIC",
    airport_city_name: "Wick",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "58.458889",
    airport_lon: "-3.093056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3876,
    airport_code: "WJF",
    airport_name: "Williams J Fox Arpt",
    airport_city_code: "WJF",
    airport_city_name: "Lancaster",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "34.7411",
    airport_lon: "-118.2186",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3877,
    airport_code: "RZH",
    airport_name: "Quartz Hill Arpt",
    airport_city_code: "WJF",
    airport_city_name: "Lancaster",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3878,
    airport_code: "WKA",
    airport_name: "Wanaka Arpt",
    airport_city_code: "WKA",
    airport_city_name: "Wanaka",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-44.722222",
    airport_lon: "169.245556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3879,
    airport_code: "WKJ",
    airport_name: "Hokkaido Arpt",
    airport_city_code: "WKJ",
    airport_city_name: "Wakkanai",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "45.404167",
    airport_lon: "141.800833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3880,
    airport_code: "WLD",
    airport_name: "Arkansas City Arpt",
    airport_city_code: "WLD",
    airport_city_name: "Winfield",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3881,
    airport_code: "WLG",
    airport_name: "Wellington Arpt",
    airport_city_code: "WLG",
    airport_city_name: "Wellington",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-41.327221",
    airport_lon: "174.805278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3882,
    airport_code: "WLK",
    airport_name: "Selawik Arpt",
    airport_city_code: "WLK",
    airport_city_name: "Selawik",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "66.6",
    airport_lon: "-159.985833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3883,
    airport_code: "WMB",
    airport_name: "Warrnambool Arpt",
    airport_city_code: "WMB",
    airport_city_name: "Warrnambool",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-38.2961",
    airport_lon: "142.4447",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3884,
    airport_code: "WMC",
    airport_name: "Winnemucca Municipal Arpt",
    airport_city_code: "WMC",
    airport_city_name: "Winnemucca",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "40.8966",
    airport_lon: "-117.8059",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3885,
    airport_code: "WMH",
    airport_name: "Mountain Home Arpt",
    airport_city_code: "WMH",
    airport_city_name: "Mountain Home",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3886,
    airport_code: "WMI",
    airport_name: "Warsaw-Modlin Mazovia Airport",
    airport_city_code: "WMI",
    airport_city_name: "Warsaw-Modlin Mazovia Airport",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "2",
    airport_lat: "52.45111",
    airport_lon: "20.651667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3887,
    airport_code: "WNP",
    airport_name: "Naga Arpt",
    airport_city_code: "WNP",
    airport_city_name: "Naga",
    airport_country_name: "PHILIPPINES",
    airport_country_code: "PH",
    airport_timezone: "8",
    airport_lat: "13.584886",
    airport_lon: "123.270239",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3888,
    airport_code: "WNR",
    airport_name: "Windorah Arpt",
    airport_city_code: "WNR",
    airport_city_name: "Windorah",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-25.4131",
    airport_lon: "142.667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3889,
    airport_code: "WNS",
    airport_name: "Nawabshah Arpt",
    airport_city_code: "WNS",
    airport_city_name: "Nawabshah",
    airport_country_name: "PAKISTAN",
    airport_country_code: "PK",
    airport_timezone: "5",
    airport_lat: "26.219442",
    airport_lon: "68.390053",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3890,
    airport_code: "WNZ",
    airport_name: "Wenzhou Arpt",
    airport_city_code: "WNZ",
    airport_city_name: "Wenzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "27.9122",
    airport_lon: "120.852",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3891,
    airport_code: "WOL",
    airport_name: "Wollongong Arpt",
    airport_city_code: "WOL",
    airport_city_name: "Wollongong",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-34.5611",
    airport_lon: "150.789",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3892,
    airport_code: "WPA",
    airport_name: "Puerto Aisen Arpt",
    airport_city_code: "WPA",
    airport_city_name: "Puerto Aisen",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-100",
    airport_lat: "-45.4",
    airport_lon: "-72.6667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3893,
    airport_code: "WRE",
    airport_name: "Whangarei Arpt",
    airport_city_code: "WRE",
    airport_city_name: "Whangarei",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-35.768333",
    airport_lon: "174.365",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3894,
    airport_code: "WRG",
    airport_name: "Wrangell Seaplane Base",
    airport_city_code: "WRG",
    airport_city_name: "Wrangell",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "56.4843",
    airport_lon: "-132.37",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3895,
    airport_code: "WRL",
    airport_name: "Worland Municipal",
    airport_city_code: "WRL",
    airport_city_name: "Worland",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "43.9657",
    airport_lon: "-107.951",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3896,
    airport_code: "WRO",
    airport_name: "Strachowice",
    airport_city_code: "WRO",
    airport_city_name: "Wroclaw",
    airport_country_name: "POLAND",
    airport_country_code: "PL",
    airport_timezone: "1",
    airport_lat: "51.102683",
    airport_lon: "16.885836",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3897,
    airport_code: "WRY",
    airport_name: "Westray Arpt",
    airport_city_code: "WRY",
    airport_city_name: "Westray",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "0",
    airport_lat: "59.3503",
    airport_lon: "-2.95",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3898,
    airport_code: "WST",
    airport_name: "Westerly Municipal",
    airport_city_code: "WST",
    airport_city_name: "Westerly",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "0",
    airport_lat: "53.351333",
    airport_lon: "-6.4875",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3899,
    airport_code: "WSY",
    airport_name: "Whitsunday Airstrip",
    airport_city_code: "WSY",
    airport_city_name: "Airlie Beach",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-20.276",
    airport_lon: "148.755",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3900,
    airport_code: "WSZ",
    airport_name: "Westport Airport",
    airport_city_code: "WSZ",
    airport_city_name: "Westport",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-41.738056",
    airport_lon: "171.580833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3901,
    airport_code: "WUH",
    airport_name: "Wuhan Arpt",
    airport_city_code: "WUH",
    airport_city_name: "Wuhan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.783758",
    airport_lon: "114.2081",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3902,
    airport_code: "WUN",
    airport_name: "Wiluna Arpt",
    airport_city_code: "WUN",
    airport_city_name: "Wiluna",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-26.6292",
    airport_lon: "120.221",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3903,
    airport_code: "WUS",
    airport_name: "Wuyishan Arpt",
    airport_city_code: "WUS",
    airport_city_name: "Wuyishan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "27.7019",
    airport_lon: "118.001",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3904,
    airport_code: "WUU",
    airport_name: "Wau Arpt",
    airport_city_code: "WUU",
    airport_city_name: "Wau City",
    airport_country_name: "SUDAN",
    airport_country_code: "SD",
    airport_timezone: "3",
    airport_lat: "7.725",
    airport_lon: "27.98",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3905,
    airport_code: "WUX",
    airport_name: "Wuxi Arpt",
    airport_city_code: "WUX",
    airport_city_name: "Wuxi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "31.4944",
    airport_lon: "120.429",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3906,
    airport_code: "WVB",
    airport_name: "Rooikop Arpt",
    airport_city_code: "WVB",
    airport_city_name: "Walvis Bay",
    airport_country_name: "NAMIBIA",
    airport_country_code: "NA",
    airport_timezone: "1",
    airport_lat: "-22.9799",
    airport_lon: "14.6453",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3907,
    airport_code: "WVI",
    airport_name: "Watsonville Municipal Arpt",
    airport_city_code: "WVI",
    airport_city_name: "Watsonville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3908,
    airport_code: "WVL",
    airport_name: "Robert La Fleur Arpt",
    airport_city_code: "WVL",
    airport_city_name: "Waterville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3909,
    airport_code: "WVN",
    airport_name: "Wilhelmshaven Arpt",
    airport_city_code: "WVN",
    airport_city_name: "Wilhelmshaven",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.504833",
    airport_lon: "8.053333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3910,
    airport_code: "WWD",
    airport_name: "Cape May Arpt",
    airport_city_code: "WWD",
    airport_city_name: "Cape May",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.008507",
    airport_lon: "-74.908275",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3911,
    airport_code: "WWK",
    airport_name: "Boram Arpt",
    airport_city_code: "WWK",
    airport_city_name: "Wewak",
    airport_country_name: "PAPUA NEW GUINEA",
    airport_country_code: "PG",
    airport_timezone: "10",
    airport_lat: "-3.583828",
    airport_lon: "143.669186",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3912,
    airport_code: "WWR",
    airport_name: "West Woodward Arpt",
    airport_city_code: "WWR",
    airport_city_name: "Woodward",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3913,
    airport_code: "WWT",
    airport_name: "Newtok Arpt",
    airport_city_code: "WWT",
    airport_city_name: "Newtok",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-10",
    airport_lat: "60.939167",
    airport_lon: "-164.641111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3914,
    airport_code: "WXF",
    airport_name: "Wether Field Raf",
    airport_city_code: "WXF",
    airport_city_name: "Braintree",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3915,
    airport_code: "WXN",
    airport_name: "Wanxian Arpt",
    airport_city_code: "WXN",
    airport_city_name: "Wanxian",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.8361",
    airport_lon: "108.406",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3916,
    airport_code: "WYA",
    airport_name: "Whyalla Arpt",
    airport_city_code: "WYA",
    airport_city_name: "Whyalla",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "9",
    airport_lat: "-33.0589",
    airport_lon: "137.514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3917,
    airport_code: "WYN",
    airport_name: "Wyndham Arpt",
    airport_city_code: "WYN",
    airport_city_name: "Wyndham",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-15.5117",
    airport_lon: "128.1533",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3918,
    airport_code: "WYS",
    airport_name: "West Yellowstone Arpt",
    airport_city_code: "WYS",
    airport_city_name: "West Yellowstone",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "44.688333",
    airport_lon: "-111.1175",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3919,
    airport_code: "XAL",
    airport_name: "Alamos Arpt",
    airport_city_code: "XAL",
    airport_city_name: "Alamos",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3920,
    airport_code: "XAP",
    airport_name: "Chapeco Arpt",
    airport_city_code: "XAP",
    airport_city_name: "Chapeco",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-3",
    airport_lat: "-27.134219",
    airport_lon: "-52.656553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3921,
    airport_code: "XAW",
    airport_name: "Capreol Rail Station",
    airport_city_code: "XAW",
    airport_city_name: "Capreol",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3922,
    airport_code: "XAZ",
    airport_name: "Campbellton Rail Station",
    airport_city_code: "XAZ",
    airport_city_name: "Campbellton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3923,
    airport_code: "XBR",
    airport_name: "Brockville Arpt",
    airport_city_code: "XBR",
    airport_city_name: "Brockville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3924,
    airport_code: "XBW",
    airport_name: "Killineq Arpt",
    airport_city_code: "XBW",
    airport_city_name: "Killineq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3925,
    airport_code: "XCH",
    airport_name: "Christmas Island Arpt",
    airport_city_code: "XCH",
    airport_city_name: "Christmas Island",
    airport_country_name: "CHRISTMAS ISLAND",
    airport_country_code: "CX",
    airport_timezone: "7",
    airport_lat: "-10.450556",
    airport_lon: "105.690278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3926,
    airport_code: "XCI",
    airport_name: "Chambord Rail Station",
    airport_city_code: "XCI",
    airport_city_name: "Chambord",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3927,
    airport_code: "XCM",
    airport_name: "Chatham Airport",
    airport_city_code: "XCM",
    airport_city_name: "Chatham",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3928,
    airport_code: "XCO",
    airport_name: "Colac Arpt",
    airport_city_code: "XCO",
    airport_city_name: "Colac",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3929,
    airport_code: "XDK",
    airport_name: "Dunkerque Arpt",
    airport_city_code: "XDK",
    airport_city_name: "Dunkerque",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3930,
    airport_code: "XDL",
    airport_name: "Chandler Rail Station",
    airport_city_code: "XDL",
    airport_city_name: "Chandler",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3931,
    airport_code: "XDM",
    airport_name: "Drummondville Rail Station",
    airport_city_code: "XDM",
    airport_city_name: "Drummondville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3932,
    airport_code: "XDO",
    airport_name: "Grande-Riviere Rail Station",
    airport_city_code: "XDO",
    airport_city_name: "Grande Riviere",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3933,
    airport_code: "XDU",
    airport_name: "Hervey Rail Station",
    airport_city_code: "XDU",
    airport_city_name: "Hervey",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3934,
    airport_code: "XED",
    airport_name: "Railway Service",
    airport_city_code: "XED",
    airport_city_name: "Disneyland Paris Rail",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3935,
    airport_code: "XEE",
    airport_name: "Lac Edouard Rail Station",
    airport_city_code: "XEE",
    airport_city_name: "Lac Edouard",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3936,
    airport_code: "XEH",
    airport_name: "Ladysmith Rail Station",
    airport_city_code: "XEH",
    airport_city_name: "Ladysmith",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3937,
    airport_code: "XEJ",
    airport_name: "Langford Rail Station",
    airport_city_code: "XEJ",
    airport_city_name: "Langford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3938,
    airport_code: "XEK",
    airport_name: "Melville Rail Station",
    airport_city_code: "XEK",
    airport_city_name: "Melville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3939,
    airport_code: "XEL",
    airport_name: "New Carlisle Rail Station",
    airport_city_code: "XEL",
    airport_city_name: "New Carlisle",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3940,
    airport_code: "XEM",
    airport_name: "New Richmond Rail Station",
    airport_city_code: "XEM",
    airport_city_name: "New Richmond",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3941,
    airport_code: "XFD",
    airport_name: "Stratford Rail Station",
    airport_city_code: "XFD",
    airport_city_name: "Stratford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3942,
    airport_code: "XFE",
    airport_name: "Parent Rail Station",
    airport_city_code: "XFE",
    airport_city_name: "Parent",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3943,
    airport_code: "XFG",
    airport_name: "Perce Rail Station",
    airport_city_code: "XFG",
    airport_city_name: "Perce",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3944,
    airport_code: "XFI",
    airport_name: "Port-Daniel Rail Station",
    airport_city_code: "XFI",
    airport_city_name: "Port Daniel",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3945,
    airport_code: "XFK",
    airport_name: "Senneterre Rail Station",
    airport_city_code: "XFK",
    airport_city_name: "Senneterre",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3946,
    airport_code: "XFL",
    airport_name: "Shawinigan Rail Station",
    airport_city_code: "XFL",
    airport_city_name: "Shawinigan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "29.2821",
    airport_lon: "-81.1212",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3947,
    airport_code: "XFM",
    airport_name: "Shawnigan Rail Station",
    airport_city_code: "XFM",
    airport_city_name: "Shawnigan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3948,
    airport_code: "XFN",
    airport_name: "Xiangfan Arpt",
    airport_city_code: "XFN",
    airport_city_name: "Xiangfan",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "32.1506",
    airport_lon: "112.291",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3949,
    airport_code: "XFO",
    airport_name: "Taschereau Rail Station",
    airport_city_code: "XFO",
    airport_city_name: "Taschereau",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3950,
    airport_code: "XFQ",
    airport_name: "Weymont Rail Station",
    airport_city_code: "XFQ",
    airport_city_name: "Weymont",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3951,
    airport_code: "XFS",
    airport_name: "Alexandria Rail Station",
    airport_city_code: "XFS",
    airport_city_name: "Alexandria",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3952,
    airport_code: "XFV",
    airport_name: "Brantford Rail Station",
    airport_city_code: "XFV",
    airport_city_name: "Brantford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "43.1314",
    airport_lon: "-80.3425",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3953,
    airport_code: "XGC",
    airport_name: "Lund C Rail Station",
    airport_city_code: "XGC",
    airport_city_name: "Lund C",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3954,
    airport_code: "XGJ",
    airport_name: "Cobourg Rail Station",
    airport_city_code: "XGJ",
    airport_city_name: "Cobourg",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3955,
    airport_code: "XGK",
    airport_name: "Coteau Rail Station",
    airport_city_code: "XGK",
    airport_city_name: "Coteau",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3956,
    airport_code: "XGR",
    airport_name: "Kangiqsualujjuaq Arpt",
    airport_city_code: "XGR",
    airport_city_name: "Kangiqsualujjuaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "58.7114",
    airport_lon: "-65.9928",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3957,
    airport_code: "XGW",
    airport_name: "Gananoque Rail Station",
    airport_city_code: "XGW",
    airport_city_name: "Gananoque",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3958,
    airport_code: "XGY",
    airport_name: "Grimsby Rail Station",
    airport_city_code: "XGY",
    airport_city_name: "Grimsby",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3959,
    airport_code: "XHF",
    airport_name: "Honefoss Rail Station",
    airport_city_code: "XHF",
    airport_city_name: "Honefoss",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3960,
    airport_code: "XHM",
    airport_name: "Georgetown Rail Station",
    airport_city_code: "XHM",
    airport_city_name: "Georgetown",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3961,
    airport_code: "XHQ",
    airport_name: "Hopa Artvin",
    airport_city_code: "XHQ",
    airport_city_name: "Hopa",
    airport_country_name: "TURKEY",
    airport_country_code: "TR",
    airport_timezone: "2",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3962,
    airport_code: "XHS",
    airport_name: "Chemainus Rail Station",
    airport_city_code: "XHS",
    airport_city_name: "Chemainus",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3963,
    airport_code: "XIA",
    airport_name: "Guelph Rail Station",
    airport_city_code: "XIA",
    airport_city_name: "Guelph",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3964,
    airport_code: "XIB",
    airport_name: "Ingersoll Rail Station",
    airport_city_code: "XIB",
    airport_city_name: "Ingersoll",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3965,
    airport_code: "XIC",
    airport_name: "Xichang Arpt",
    airport_city_code: "XIC",
    airport_city_name: "Xichang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "27.989083",
    airport_lon: "102.184361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3966,
    airport_code: "XID",
    airport_name: "Maxville Rail Station",
    airport_city_code: "XID",
    airport_city_name: "Maxville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3967,
    airport_code: "XIF",
    airport_name: "Napanee Rail Station",
    airport_city_code: "XIF",
    airport_city_name: "Napanee",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3968,
    airport_code: "XII",
    airport_name: "Prescott Rail Station",
    airport_city_code: "XII",
    airport_city_name: "Prescott",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3969,
    airport_code: "XIM",
    airport_name: "Saint Hyacinthe Rail Station",
    airport_city_code: "XIM",
    airport_city_name: "Saint Hyacinthe",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3970,
    airport_code: "XIO",
    airport_name: "St Marys Rail Station",
    airport_city_code: "XIO",
    airport_city_name: "St Marys",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3971,
    airport_code: "XIP",
    airport_name: "Woodstock Rail Station",
    airport_city_code: "XIP",
    airport_city_name: "Woodstock",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3972,
    airport_code: "XJL",
    airport_name: "Joliette Rail Station",
    airport_city_code: "XJL",
    airport_city_name: "Joliette",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3973,
    airport_code: "XJQ",
    airport_name: "Jonquiere Rail Station",
    airport_city_code: "XJQ",
    airport_city_name: "Jonquiere",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3974,
    airport_code: "XKH",
    airport_name: "Xieng Khouang Arpt",
    airport_city_code: "XKH",
    airport_city_name: "Xieng Khouang",
    airport_country_name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    airport_country_code: "LA",
    airport_timezone: "7",
    airport_lat: "19.449997",
    airport_lon: "103.158333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3975,
    airport_code: "XKJ",
    airport_name: "Steinkjer Rail Station",
    airport_city_code: "XKJ",
    airport_city_name: "Steinkjer",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 3976,
    airport_code: "XKG",
    airport_name: "Grong Rail Station",
    airport_city_code: "XKJ",
    airport_city_name: "Steinkjer",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 3977,
    airport_code: "XKV",
    airport_name: "Sackville Rail Station",
    airport_city_code: "XKV",
    airport_city_name: "Sackville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3978,
    airport_code: "XLP",
    airport_name: "Matapedia Rail Station",
    airport_city_code: "XLP",
    airport_city_name: "Matapedia",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3979,
    airport_code: "XLV",
    airport_name: "Niagara Falls Rail Station",
    airport_city_code: "XLV",
    airport_city_name: "Niagara Falls",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3980,
    airport_code: "XLY",
    airport_name: "Aldershot Rail Station",
    airport_city_code: "XLY",
    airport_city_name: "Aldershot",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3981,
    airport_code: "XLZ",
    airport_name: "Truro Rail Station",
    airport_city_code: "XLZ",
    airport_city_name: "Truro",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3982,
    airport_code: "XMB",
    airport_name: "M'Bahiakro Arpt",
    airport_city_code: "XMB",
    airport_city_name: "M'Bahiakro",
    airport_country_name: "COTE D'IVOIRE",
    airport_country_code: "CI",
    airport_timezone: "0",
    airport_lat: "7.45",
    airport_lon: "-4.333333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3983,
    airport_code: "XMN",
    airport_name: "Xiamen Intl Airport",
    airport_city_code: "XMN",
    airport_city_name: "Xiamen",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "24.544036",
    airport_lon: "118.127739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3984,
    airport_code: "XMY",
    airport_name: "Yam Island Arpt",
    airport_city_code: "XMY",
    airport_city_name: "Yam Island",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-9.90111",
    airport_lon: "142.776",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3985,
    airport_code: "XNM",
    airport_name: "Nottingham Rail Station",
    airport_city_code: "XNM",
    airport_city_name: "Nottingham",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3986,
    airport_code: "XNN",
    airport_name: "Xining Arpt",
    airport_city_code: "XNN",
    airport_city_name: "Xining",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "36.5275",
    airport_lon: "102.043",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3987,
    airport_code: "XNT",
    airport_name: "Xingtai Airport",
    airport_city_code: "XNT",
    airport_city_name: "Xingtai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3988,
    airport_code: "XOK",
    airport_name: "Oakville Rail Station",
    airport_city_code: "XOK",
    airport_city_name: "Oakville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3989,
    airport_code: "XON",
    airport_name: "Carleton Rail Station",
    airport_city_code: "XON",
    airport_city_name: "Carleton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3990,
    airport_code: "XOR",
    airport_name: "Otta Rail Station",
    airport_city_code: "XOR",
    airport_city_name: "Otta",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3991,
    airport_code: "XPB",
    airport_name: "Parksville Rail Station",
    airport_city_code: "XPB",
    airport_city_name: "Parksville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3992,
    airport_code: "XPH",
    airport_name: "Port Hope Rail Station",
    airport_city_code: "XPH",
    airport_city_name: "Port Hope",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3993,
    airport_code: "XPK",
    airport_name: "Pukatawagan Rail Station",
    airport_city_code: "XPK",
    airport_city_name: "Pukatawagan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "55.7489",
    airport_lon: "-101.2664",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3994,
    airport_code: "XPL",
    airport_name: "Palmerola Air Base",
    airport_city_code: "XPL",
    airport_city_name: "Comayagua",
    airport_country_name: "HONDURAS",
    airport_country_code: "HN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3995,
    airport_code: "XPN",
    airport_name: "Brampton Rail Station",
    airport_city_code: "XPN",
    airport_city_name: "Brampton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3996,
    airport_code: "XPT",
    airport_name: "Preston Rail Station",
    airport_city_code: "XPT",
    airport_city_name: "Preston",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3997,
    airport_code: "XPX",
    airport_name: "Pointe-aux-Trembles Rail Station",
    airport_city_code: "XPX",
    airport_city_name: "Pointe Aux Trembles",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3998,
    airport_code: "XQG",
    airport_name: "Berwick Rail Station",
    airport_city_code: "XQG",
    airport_city_name: "Berwick Upon Tweed",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 3999,
    airport_code: "XQP",
    airport_name: "Quepos Arpt",
    airport_city_code: "XQP",
    airport_city_name: "Quepos",
    airport_country_name: "COSTA RICA",
    airport_country_code: "CR",
    airport_timezone: "-6",
    airport_lat: "9.443164",
    airport_lon: "-84.129772",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4000,
    airport_code: "XQU",
    airport_name: "Qualicum Arpt",
    airport_city_code: "XQU",
    airport_city_name: "Qualicum",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.337222",
    airport_lon: "-124.393889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4001,
    airport_code: "XRD",
    airport_name: "Rail Station Egersund",
    airport_city_code: "XRD",
    airport_city_name: "Egersund",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4002,
    airport_code: "XRG",
    airport_name: "Rugeley Rail Station",
    airport_city_code: "XRG",
    airport_city_name: "Rugeley",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4003,
    airport_code: "XRP",
    airport_name: "Pine Ridge Rail Station",
    airport_city_code: "XRP",
    airport_city_name: "Riviere A Pierre",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4004,
    airport_code: "XRU",
    airport_name: "Rugby Rail Station",
    airport_city_code: "XRU",
    airport_city_name: "Rugby",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4005,
    airport_code: "XRY",
    airport_name: "La Parra Arpt",
    airport_city_code: "XRY",
    airport_city_name: "Jerez De La Frontera",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "36.744622",
    airport_lon: "-6.060111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4006,
    airport_code: "XSC",
    airport_name: "South Caicos Intl Arpt",
    airport_city_code: "XSC",
    airport_city_name: "South Caicos",
    airport_country_name: "TURKS AND CAICOS ISLANDS",
    airport_country_code: "TC",
    airport_timezone: "-5",
    airport_lat: "21.515739",
    airport_lon: "-71.528528",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4007,
    airport_code: "XSR",
    airport_name: "Salisbury Rail Station",
    airport_city_code: "XSR",
    airport_city_name: "Salisbury",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4008,
    airport_code: "XSS",
    airport_name: "Soissons Railway Station",
    airport_city_code: "XSS",
    airport_city_name: "Soissons",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4009,
    airport_code: "XTG",
    airport_name: "Thargomindah Arpt",
    airport_city_code: "XTG",
    airport_city_name: "Thargomindah",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "10",
    airport_lat: "-27.9864",
    airport_lon: "143.811",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4010,
    airport_code: "XTY",
    airport_name: "Strathroy Rail Station",
    airport_city_code: "XTY",
    airport_city_name: "Strathroy",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4011,
    airport_code: "XUG",
    airport_name: "Holmestrand Railway",
    airport_city_code: "XUG",
    airport_city_name: "Holmestrand",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4012,
    airport_code: "XUZ",
    airport_name: "Xuzhou Arpt",
    airport_city_code: "XUZ",
    airport_city_name: "Xuzhou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "34.16",
    airport_lon: "117.11",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4013,
    airport_code: "XVA",
    airport_name: "Stockport Rail Station",
    airport_city_code: "XVA",
    airport_city_name: "Stockport",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4014,
    airport_code: "XVH",
    airport_name: "Peterborough Rail Station",
    airport_city_code: "XVH",
    airport_city_name: "Peterborough",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4015,
    airport_code: "XVJ",
    airport_name: "Stevenage Rail Station",
    airport_city_code: "XVJ",
    airport_city_name: "Stevenage",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4016,
    airport_code: "XVV",
    airport_name: "Belleville Rail Station",
    airport_city_code: "XVV",
    airport_city_name: "Belleville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "44.1793",
    airport_lon: "-77.3747",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4017,
    airport_code: "XVW",
    airport_name: "Belleville Rail Station",
    airport_city_code: "XVW",
    airport_city_name: "Wolverhampton",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "52.5175",
    airport_lon: "-2.2594",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4018,
    airport_code: "XWA",
    airport_name: "Watford Rail Station",
    airport_city_code: "XWA",
    airport_city_name: "Watford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4019,
    airport_code: "XWD",
    airport_name: "Wakefield Westgate Rail Station",
    airport_city_code: "XWD",
    airport_city_name: "Wakefield Westgate",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4020,
    airport_code: "XWF",
    airport_name: "Falun Railway Service",
    airport_city_code: "XWF",
    airport_city_name: "Falun",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4021,
    airport_code: "XJF",
    airport_name: "Falun Bus Service",
    airport_city_code: "XWF",
    airport_city_name: "Falun",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4022,
    airport_code: "XWH",
    airport_name: "Stroke on Trent Rail Station",
    airport_city_code: "XWH",
    airport_city_name: "Stoke On Trent",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4023,
    airport_code: "XWV",
    airport_name: "Varberg Rail Station",
    airport_city_code: "XWV",
    airport_city_name: "Varberg",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4024,
    airport_code: "XWY",
    airport_name: "Wyoming Rail Station",
    airport_city_code: "XWY",
    airport_city_name: "Wyoming",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4025,
    airport_code: "XXA",
    airport_name: "Alvesta Railway Service",
    airport_city_code: "XXA",
    airport_city_name: "Alvesta",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4026,
    airport_code: "XXD",
    airport_name: "Degerfors Rail Station",
    airport_city_code: "XXD",
    airport_city_name: "Degerfors",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4027,
    airport_code: "XXL",
    airport_name: "Lillehammer Rail Station",
    airport_city_code: "XXL",
    airport_city_name: "Lille Hammer",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4028,
    airport_code: "XXM",
    airport_name: "Mjolby Rail Station",
    airport_city_code: "XXM",
    airport_city_name: "Mjolby",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4029,
    airport_code: "XYN",
    airport_name: "Kristinehamn Rail Station",
    airport_city_code: "XYN",
    airport_city_name: "Kristinehamn",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4030,
    airport_code: "XYP",
    airport_name: "Railway Service",
    airport_city_code: "XYP",
    airport_city_name: "Avesta Krylbo",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4031,
    airport_code: "XYY",
    airport_name: "Arvika Rail Station",
    airport_city_code: "XYY",
    airport_city_name: "Arvika",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4032,
    airport_code: "XYZ",
    airport_name: "Harnosand Rail Station",
    airport_city_code: "XYZ",
    airport_city_name: "Harnosand",
    airport_country_name: "SWEDEN",
    airport_country_code: "SE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4033,
    airport_code: "XZB",
    airport_name: "Casselman Rail Station",
    airport_city_code: "XZB",
    airport_city_name: "Casselman",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4034,
    airport_code: "XZC",
    airport_name: "Glencoe Rail Station",
    airport_city_code: "XZC",
    airport_city_name: "Glencoe",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4035,
    airport_code: "XZD",
    airport_name: "Kongsvinger Rail Station",
    airport_city_code: "XZD",
    airport_city_name: "Kongsvinger",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4036,
    airport_code: "XZJ",
    airport_name: "Off Line Point",
    airport_city_code: "XZJ",
    airport_city_name: "Rail",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4037,
    airport_code: "XZK",
    airport_name: "Amherst Rail Station",
    airport_city_code: "XZK",
    airport_city_name: "Amherst",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4038,
    airport_code: "XZP",
    airport_name: "Off Line Point",
    airport_city_code: "XZP",
    airport_city_name: "Pass",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4039,
    airport_code: "YAG",
    airport_name: "Fort Frances Municipal",
    airport_city_code: "YAG",
    airport_city_name: "Fort Frances",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "48.6542",
    airport_lon: "-93.4397",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4040,
    airport_code: "YAK",
    airport_name: "Yakutat Arpt",
    airport_city_code: "YAK",
    airport_city_name: "Yakutat",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-9",
    airport_lat: "59.3012",
    airport_lon: "-139.3937",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4041,
    airport_code: "YAM",
    airport_name: "Sault Ste Marie Arpt",
    airport_city_code: "YAM",
    airport_city_name: "Sault Ste Marie",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "46.485001",
    airport_lon: "-84.509445",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4042,
    airport_code: "NSI",
    airport_name: "Nsimalen Arpt",
    airport_city_code: "YAO",
    airport_city_name: "Yaounde",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "1",
    airport_lat: "3.722556",
    airport_lon: "11.553269",
    airport_num_airports: 2,
  },
  {
    airport_id: 4043,
    airport_code: "YAO",
    airport_name: "Yaounde Airport",
    airport_city_code: "YAO",
    airport_city_name: "Yaounde",
    airport_country_name: "CAMEROON",
    airport_country_code: "CM",
    airport_timezone: "1",
    airport_lat: "3.836039",
    airport_lon: "11.523461",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4044,
    airport_code: "YAP",
    airport_name: "Yap Arpt",
    airport_city_code: "YAP",
    airport_city_name: "Yap Fm",
    airport_country_name: "MICRONESIA, FEDERATED STATES OF",
    airport_country_code: "FM",
    airport_timezone: "10",
    airport_lat: "9.498911",
    airport_lon: "138.082497",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4045,
    airport_code: "YAT",
    airport_name: "Attawapiskat Arpt",
    airport_city_code: "YAT",
    airport_city_name: "Attawapiskat",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "52.9275",
    airport_lon: "-82.4319",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4046,
    airport_code: "YAY",
    airport_name: "St Anthony Arpt",
    airport_city_code: "YAY",
    airport_city_name: "St Anthony",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "51.391944",
    airport_lon: "-56.083056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4047,
    airport_code: "YAZ",
    airport_name: "Tofino Arpt",
    airport_city_code: "YAZ",
    airport_city_name: "Tofino",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.082222",
    airport_lon: "-125.7725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4048,
    airport_code: "YBA",
    airport_name: "Banff Arpt",
    airport_city_code: "YBA",
    airport_city_name: "Banff",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4049,
    airport_code: "YBB",
    airport_name: "Kugaaruk Arpt",
    airport_city_code: "YBB",
    airport_city_name: "Pelly Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "68.534444",
    airport_lon: "-89.808056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4050,
    airport_code: "YBC",
    airport_name: "Baie Comeau Arpt",
    airport_city_code: "YBC",
    airport_city_name: "Baie Comeau",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.1325",
    airport_lon: "-68.204444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4051,
    airport_code: "YBD",
    airport_name: "New Westminster Rail Station",
    airport_city_code: "YBD",
    airport_city_name: "New Westminster",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4052,
    airport_code: "YBG",
    airport_name: "Bagotville Arpt",
    airport_city_code: "YBG",
    airport_city_name: "Bagotville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.330555",
    airport_lon: "-70.996391",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4053,
    airport_code: "YBK",
    airport_name: "Baker Lake Arpt",
    airport_city_code: "YBK",
    airport_city_name: "Baker Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "64.298889",
    airport_lon: "-96.077778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4054,
    airport_code: "YBL",
    airport_name: "Campbell River Municipal",
    airport_city_code: "YBL",
    airport_city_name: "Campbell River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.950832",
    airport_lon: "-125.270833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4055,
    airport_code: "YHH",
    airport_name: "Harbor Airport",
    airport_city_code: "YBL",
    airport_city_name: "Campbell River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4056,
    airport_code: "YBP",
    airport_name: "Yibin Airport",
    airport_city_code: "YBP",
    airport_city_name: "Yibin",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "28.800278",
    airport_lon: "104.544444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4057,
    airport_code: "YBR",
    airport_name: "Brandon Municipal Arpt",
    airport_city_code: "YBR",
    airport_city_name: "Brandon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.91",
    airport_lon: "-99.951944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4058,
    airport_code: "YBW",
    airport_name: "Bedwell Harbor Arpt",
    airport_city_code: "YBW",
    airport_city_name: "Bedwell Harbor",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "51.1031",
    airport_lon: "-114.374",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4059,
    airport_code: "YBX",
    airport_name: "Blanc Sablon Arpt",
    airport_city_code: "YBX",
    airport_city_name: "Blanc Sablon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "51.4436",
    airport_lon: "-57.1853",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4060,
    airport_code: "YCA",
    airport_name: "Courtenay Rail Station",
    airport_city_code: "YCA",
    airport_city_name: "Courtenay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4061,
    airport_code: "YCB",
    airport_name: "Cambridge Bay Arpt",
    airport_city_code: "YCB",
    airport_city_name: "Cambridge Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "69.108055",
    airport_lon: "-105.138333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4062,
    airport_code: "YCC",
    airport_name: "Cornwall Regional Arpt",
    airport_city_code: "YCC",
    airport_city_name: "Cornwall",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.092778",
    airport_lon: "-74.567778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4063,
    airport_code: "ZNA",
    airport_name: "Harbour Seaplane Base",
    airport_city_code: "YCD",
    airport_city_name: "Nanaimo",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.183333",
    airport_lon: "-123.95",
    airport_num_airports: 2,
  },
  {
    airport_id: 4064,
    airport_code: "YCD",
    airport_name: "Nanaimo Arpt",
    airport_city_code: "YCD",
    airport_city_name: "Nanaimo",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.052333",
    airport_lon: "-123.870167",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4065,
    airport_code: "YCG",
    airport_name: "Ralph West Arpt",
    airport_city_code: "YCG",
    airport_city_name: "Castlegar",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.296389",
    airport_lon: "-117.6325",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4066,
    airport_code: "XEY",
    airport_name: "Newcastle Rail Station",
    airport_city_code: "YCH",
    airport_city_name: "Miramichi",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4067,
    airport_code: "YCH",
    airport_name: "Miramichi Arpt",
    airport_city_code: "YCH",
    airport_city_name: "Miramichi",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "47.007778",
    airport_lon: "-65.449167",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4068,
    airport_code: "YCK",
    airport_name: "Colville Municipal",
    airport_city_code: "YCK",
    airport_city_name: "Colville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "67.0333",
    airport_lon: "-126.083",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4069,
    airport_code: "YCL",
    airport_name: "Charlo Municipal Arpt",
    airport_city_code: "YCL",
    airport_city_name: "Charlo",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "47.990833",
    airport_lon: "-66.330278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4070,
    airport_code: "YCM",
    airport_name: "St Catharines Rail Station",
    airport_city_code: "YCM",
    airport_city_name: "St Catherines",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4071,
    airport_code: "YCN",
    airport_name: "Cochrane Rail Station",
    airport_city_code: "YCN",
    airport_city_name: "Cochrane",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "49.1056",
    airport_lon: "-81.0136",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4072,
    airport_code: "YCO",
    airport_name: "Kugluktuk Arpt",
    airport_city_code: "YCO",
    airport_city_name: "Kugluktuk Coppermine",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "67.816667",
    airport_lon: "-115.143889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4073,
    airport_code: "YCS",
    airport_name: "Chesterfield Inlet Arpt",
    airport_city_code: "YCS",
    airport_city_name: "Chesterfield Inlet",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "63.3469",
    airport_lon: "-90.7311",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4074,
    airport_code: "YCU",
    airport_name: "Yun Cheng Arpt",
    airport_city_code: "YCU",
    airport_city_name: "Yun Cheng",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "35.018",
    airport_lon: "110.993",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4075,
    airport_code: "YCW",
    airport_name: "Chilliwack Arpt",
    airport_city_code: "YCW",
    airport_city_name: "Chilliwack",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.152779",
    airport_lon: "-121.93889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4076,
    airport_code: "YCY",
    airport_name: "Clyde River",
    airport_city_code: "YCY",
    airport_city_name: "Clyde River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "70.486111",
    airport_lon: "-68.516667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4077,
    airport_code: "YCZ",
    airport_name: "Fairmont Springs Arpt",
    airport_city_code: "YCZ",
    airport_city_name: "Fairmont Springs",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4078,
    airport_code: "YDA",
    airport_name: "Dawson City Arpt",
    airport_city_code: "YDA",
    airport_city_name: "Dawson City",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "64.043056",
    airport_lon: "-139.127778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4079,
    airport_code: "YDF",
    airport_name: "Deer Lake Municipal",
    airport_city_code: "YDF",
    airport_city_name: "Deer Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "49.210833",
    airport_lon: "-57.391388",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4080,
    airport_code: "YDL",
    airport_name: "Dease Lake Arpt",
    airport_city_code: "YDL",
    airport_city_name: "Dease Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "58.422222",
    airport_lon: "-130.032222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4081,
    airport_code: "YDN",
    airport_name: "Dauphin Rail Station",
    airport_city_code: "YDN",
    airport_city_name: "Dauphin",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "51.100834",
    airport_lon: "-100.0525",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4082,
    airport_code: "YDP",
    airport_name: "Nain Arpt",
    airport_city_code: "YDP",
    airport_city_name: "Nain",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "56.5492",
    airport_lon: "-61.6803",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4083,
    airport_code: "YDQ",
    airport_name: "Dawson Creek Arpt",
    airport_city_code: "YDQ",
    airport_city_name: "Dawson Creek",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "55.742333",
    airport_lon: "-120.183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4084,
    airport_code: "YXD",
    airport_name: "Edmonton Municipal Arpt",
    airport_city_code: "YEA",
    airport_city_name: "Edmonton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "53.5725",
    airport_lon: "-113.520556",
    airport_num_airports: 4,
  },
  {
    airport_id: 4085,
    airport_code: "YED",
    airport_name: "Namao Field",
    airport_city_code: "YEA",
    airport_city_name: "Edmonton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 4086,
    airport_code: "YEG",
    airport_name: "Edmonton Intl Arpt",
    airport_city_code: "YEA",
    airport_city_name: "Edmonton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "53.309723",
    airport_lon: "-113.579722",
    airport_num_airports: 4,
  },
  {
    airport_id: 4087,
    airport_code: "XZL",
    airport_name: "Edmonton Rail Station",
    airport_city_code: "YEA",
    airport_city_name: "Edmonton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "53.5789",
    airport_lon: "-113.5307",
    airport_num_airports: 4,
  },
  {
    airport_id: 4088,
    airport_code: "YEB",
    airport_name: "Bar River Arpt",
    airport_city_code: "YEB",
    airport_city_name: "Bar River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4089,
    airport_code: "YEH",
    airport_name: "Asaloyeh Arpt",
    airport_city_code: "YEH",
    airport_city_name: "Asaloyeh",
    airport_country_name: "IRAN",
    airport_country_code: "IR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4090,
    airport_code: "YEK",
    airport_name: "Arviat Arpt",
    airport_city_code: "YEK",
    airport_city_name: "Arviat",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "61.094166",
    airport_lon: "-94.070833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4091,
    airport_code: "YEL",
    airport_name: "Elliot Lake Arpt",
    airport_city_code: "YEL",
    airport_city_name: "Elliot Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "46.3514",
    airport_lon: "-82.5614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4092,
    airport_code: "YEN",
    airport_name: "Estevan Arpt",
    airport_city_code: "YEN",
    airport_city_name: "Estevan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.210278",
    airport_lon: "-102.965833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4093,
    airport_code: "YER",
    airport_name: "Fort Severn Arpt",
    airport_city_code: "YER",
    airport_city_name: "Fort Severn",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "56.0189",
    airport_lon: "-87.6761",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4094,
    airport_code: "YET",
    airport_name: "Edson Arpt",
    airport_city_code: "YET",
    airport_city_name: "Edson",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "53.578888",
    airport_lon: "-116.465",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4095,
    airport_code: "YEV",
    airport_name: "Inuvik Mike Zubko Arpt",
    airport_city_code: "YEV",
    airport_city_name: "Inuvik",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "68.304167",
    airport_lon: "-133.482778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4096,
    airport_code: "YEY",
    airport_name: "Amos Rail Station",
    airport_city_code: "YEY",
    airport_city_name: "Amos",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4097,
    airport_code: "YFB",
    airport_name: "Iqaluit Arpt",
    airport_city_code: "YFB",
    airport_city_name: "Iqaluit",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "63.75639",
    airport_lon: "-68.555832",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4098,
    airport_code: "YFC",
    airport_name: "Fredericton Municipal",
    airport_city_code: "YFC",
    airport_city_name: "Fredericton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "45.868889",
    airport_lon: "-66.537222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4099,
    airport_code: "YFO",
    airport_name: "Flin Flon Municipal Arpt",
    airport_city_code: "YFO",
    airport_city_name: "Flin Flon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "54.678055",
    airport_lon: "-101.681667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4100,
    airport_code: "YFX",
    airport_name: "Fox Harbour Arpt",
    airport_city_code: "YFX",
    airport_city_name: "Fox Harbour",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "52.3728",
    airport_lon: "-55.6739",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4101,
    airport_code: "YGJ",
    airport_name: "Miho Arpt",
    airport_city_code: "YGJ",
    airport_city_name: "Yonago",
    airport_country_name: "JAPAN",
    airport_country_code: "JP",
    airport_timezone: "9",
    airport_lat: "35.492222",
    airport_lon: "133.236389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4102,
    airport_code: "YGK",
    airport_name: "Norman Rodgers Arpt",
    airport_city_code: "YGK",
    airport_city_name: "Kingston",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "44.225277",
    airport_lon: "-76.596944",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4103,
    airport_code: "XEG",
    airport_name: "Kingston Rail Station",
    airport_city_code: "YGK",
    airport_city_name: "Kingston",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "44.256944",
    airport_lon: "-76.536943",
    airport_num_airports: 2,
  },
  {
    airport_id: 4104,
    airport_code: "YGL",
    airport_name: "La Grande Municipal Arpt",
    airport_city_code: "YGL",
    airport_city_name: "La Grande",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "53.625278",
    airport_lon: "-77.704167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4105,
    airport_code: "YGP",
    airport_name: "Gaspe Municipal Arpt",
    airport_city_code: "YGP",
    airport_city_name: "Gaspe",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.775278",
    airport_lon: "-64.478611",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4106,
    airport_code: "XDD",
    airport_name: "Gaspe Rail Station",
    airport_city_code: "YGP",
    airport_city_name: "Gaspe",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4107,
    airport_code: "YGR",
    airport_name: "House Harbour Arpt",
    airport_city_code: "YGR",
    airport_city_name: "Iles De Madeleine",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "47.424721",
    airport_lon: "-61.778056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4108,
    airport_code: "YGT",
    airport_name: "Igloolik Arpt",
    airport_city_code: "YGT",
    airport_city_name: "Igloolik",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "69.3647",
    airport_lon: "-81.8161",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4109,
    airport_code: "YGV",
    airport_name: "Havre St Pierre Municipal Arpt",
    airport_city_code: "YGV",
    airport_city_name: "Havre St Pierre",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "50.281944",
    airport_lon: "-63.611389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4110,
    airport_code: "YGW",
    airport_name: "Kuujjuaraapik Arpt",
    airport_city_code: "YGW",
    airport_city_name: "Poste De La Baleine",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "55.2819",
    airport_lon: "-77.7653",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4111,
    airport_code: "YGX",
    airport_name: "Gillam Rail Station",
    airport_city_code: "YGX",
    airport_city_name: "Gillam",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "56.3575",
    airport_lon: "-94.7106",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4112,
    airport_code: "YHA",
    airport_name: "Port Hope Simpson Arpt",
    airport_city_code: "YHA",
    airport_city_name: "Port Hope Simpson",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "52.5281",
    airport_lon: "-56.2861",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4113,
    airport_code: "YHB",
    airport_name: "Hudson Bay Rail Station",
    airport_city_code: "YHB",
    airport_city_name: "Hudson Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "52.816666",
    airport_lon: "-102.31139",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4114,
    airport_code: "YHD",
    airport_name: "Dryden Municipal",
    airport_city_code: "YHD",
    airport_city_name: "Dryden",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.831667",
    airport_lon: "-92.744167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4115,
    airport_code: "YHE",
    airport_name: "Hope Arpt",
    airport_city_code: "YHE",
    airport_city_name: "Hope",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "49.3667",
    airport_lon: "-121.4833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4116,
    airport_code: "YHF",
    airport_name: "Hearst Municipal Arpt",
    airport_city_code: "YHF",
    airport_city_name: "Hearst",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.713889",
    airport_lon: "-83.686944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4117,
    airport_code: "YHG",
    airport_name: "Charlottetown Municipal Arpt",
    airport_city_code: "YHG",
    airport_city_name: "Charlottetown",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "52.7647",
    airport_lon: "-56.1094",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4118,
    airport_code: "YHK",
    airport_name: "Gjoa Haven Arpt",
    airport_city_code: "YHK",
    airport_city_name: "Gjoa Haven",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "68.635556",
    airport_lon: "-95.849722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4119,
    airport_code: "YHM",
    airport_name: "Civic Airport",
    airport_city_code: "YHM",
    airport_city_name: "Hamilton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.173611",
    airport_lon: "-79.935",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4120,
    airport_code: "YHN",
    airport_name: "Hornepayne Rail Station",
    airport_city_code: "YHN",
    airport_city_name: "Hornepayne",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.193056",
    airport_lon: "-84.758889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4121,
    airport_code: "YHO",
    airport_name: "Hopedale Arpt",
    airport_city_code: "YHO",
    airport_city_name: "Hopedale",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "55.4483",
    airport_lon: "-60.2286",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4122,
    airport_code: "YHR",
    airport_name: "Chevery Arpt",
    airport_city_code: "YHR",
    airport_city_name: "Chevery",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "50.4689",
    airport_lon: "-59.6367",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4123,
    airport_code: "YHS",
    airport_name: "Sechelt Arpt",
    airport_city_code: "YHS",
    airport_city_name: "Sechelt",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.460556",
    airport_lon: "-123.718611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4124,
    airport_code: "YHY",
    airport_name: "Hay River Municipal Arpt",
    airport_city_code: "YHY",
    airport_city_name: "Hay River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "60.839722",
    airport_lon: "-115.782778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4125,
    airport_code: "YHZ",
    airport_name: "Halifax Intl",
    airport_city_code: "YHZ",
    airport_city_name: "Halifax",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "44.880833",
    airport_lon: "-63.50861",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4126,
    airport_code: "XDG",
    airport_name: "Halifax Rail Station",
    airport_city_code: "YHZ",
    airport_city_name: "Halifax",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4127,
    airport_code: "YIB",
    airport_name: "Atikokan Municipal Arpt",
    airport_city_code: "YIB",
    airport_city_name: "Atikokan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "48.773888",
    airport_lon: "-91.638611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4128,
    airport_code: "YIF",
    airport_name: "Pakuashipi Arpt",
    airport_city_code: "YIF",
    airport_city_name: "Pakuashipi",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "51.2117",
    airport_lon: "-58.6583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4129,
    airport_code: "YIH",
    airport_name: "Yichang Arpt",
    airport_city_code: "YIH",
    airport_city_name: "Yichang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "30.671",
    airport_lon: "111.441",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4130,
    airport_code: "YIK",
    airport_name: "Ivujivik Arpt",
    airport_city_code: "YIK",
    airport_city_name: "Ivujivik",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "62.4173",
    airport_lon: "-77.9253",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4131,
    airport_code: "YIO",
    airport_name: "Pond Inlet Arpt",
    airport_city_code: "YIO",
    airport_city_name: "Pond Inlet",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "72.683334",
    airport_lon: "-77.966667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4132,
    airport_code: "YIW",
    airport_name: "Yiwu Arpt",
    airport_city_code: "YIW",
    airport_city_name: "Yiwu",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "29.3447",
    airport_lon: "120.032",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4133,
    airport_code: "YJA",
    airport_name: "Jasper Airport",
    airport_city_code: "YJA",
    airport_city_name: "Jasper",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4134,
    airport_code: "XDH",
    airport_name: "Jasper Rail Station",
    airport_city_code: "YJA",
    airport_city_name: "Jasper",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4135,
    airport_code: "YJT",
    airport_name: "Stephenville Municipal",
    airport_city_code: "YJT",
    airport_city_name: "Stephenville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "48.544167",
    airport_lon: "-58.549999",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4136,
    airport_code: "YKA",
    airport_name: "Davie Fulton Arpt",
    airport_city_code: "YKA",
    airport_city_name: "Kamloops",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "50.702222",
    airport_lon: "-120.444444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4137,
    airport_code: "YKF",
    airport_name: "Kitchener Waterloo Regional",
    airport_city_code: "YKF",
    airport_city_name: "Kitchener",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.460833",
    airport_lon: "-80.378611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4138,
    airport_code: "YKG",
    airport_name: "Kangirsuk Arpt",
    airport_city_code: "YKG",
    airport_city_name: "Kangirsuk",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "60.0272",
    airport_lon: "-69.9992",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4139,
    airport_code: "YKL",
    airport_name: "Schefferville Arpt",
    airport_city_code: "YKL",
    airport_city_name: "Schefferville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "54.805278",
    airport_lon: "-66.805278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4140,
    airport_code: "YKM",
    airport_name: "Yakima Terminal Arpt",
    airport_city_code: "YKM",
    airport_city_name: "Yakima",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-8",
    airport_lat: "46.5682",
    airport_lon: "-120.544",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4141,
    airport_code: "YKN",
    airport_name: "Chan Gurney Municipal",
    airport_city_code: "YKN",
    airport_city_name: "Yankton",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4142,
    airport_code: "YKQ",
    airport_name: "Waskaganish Arpt",
    airport_city_code: "YKQ",
    airport_city_name: "Waskaganish",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "51.4733",
    airport_lon: "-78.7583",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4143,
    airport_code: "YKS",
    airport_name: "Yakutsk Arpt",
    airport_city_code: "YKS",
    airport_city_name: "Yakutsk",
    airport_country_name: "RUSSIA",
    airport_country_code: "RU",
    airport_timezone: "10",
    airport_lat: "62.09325",
    airport_lon: "129.770672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4144,
    airport_code: "YKU",
    airport_name: "Chisasibi Arpt",
    airport_city_code: "YKU",
    airport_city_name: "Chisasibi",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "53.8056",
    airport_lon: "-78.9169",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4145,
    airport_code: "YKX",
    airport_name: "Kirkland Lake Municipal Arpt",
    airport_city_code: "YKX",
    airport_city_name: "Kirkland",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.210278",
    airport_lon: "-79.981389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4146,
    airport_code: "YLC",
    airport_name: "Kimmirut Lake Harbour Arpt",
    airport_city_code: "YLC",
    airport_city_name: "Kimmirut Lake Harbour",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "62.85",
    airport_lon: "-69.8833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4147,
    airport_code: "YLD",
    airport_name: "Chapleau Rail Station",
    airport_city_code: "YLD",
    airport_city_name: "Chapleau",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "47.82",
    airport_lon: "-83.346667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4148,
    airport_code: "YLI",
    airport_name: "Ylivieska Arpt",
    airport_city_code: "YLI",
    airport_city_name: "Ylivieska",
    airport_country_name: "FINLAND",
    airport_country_code: "FI",
    airport_timezone: "-100",
    airport_lat: "64.0547",
    airport_lon: "24.7253",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4149,
    airport_code: "YLJ",
    airport_name: "Meadow Lake Arpt",
    airport_city_code: "YLJ",
    airport_city_name: "Meadow Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "54.125278",
    airport_lon: "-108.522778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4150,
    airport_code: "YLL",
    airport_name: "Lloydminster Arpt",
    airport_city_code: "YLL",
    airport_city_name: "Lloydminster",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "53.309166",
    airport_lon: "-110.0725",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4151,
    airport_code: "YLQ",
    airport_name: "La Tuque Rail Station",
    airport_city_code: "YLQ",
    airport_city_name: "La Tuque",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "47.4097",
    airport_lon: "-72.7889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4152,
    airport_code: "YLW",
    airport_name: "Ellison Field",
    airport_city_code: "YLW",
    airport_city_name: "Kelowna",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.956112",
    airport_lon: "-119.377778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4153,
    airport_code: "YLY",
    airport_name: "Langley Regional Arpt",
    airport_city_code: "YLY",
    airport_city_name: "Langley",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "49.1008",
    airport_lon: "-122.6308",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4154,
    airport_code: "YMB",
    airport_name: "Merritt Arpt",
    airport_city_code: "YMB",
    airport_city_name: "Merritt",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4155,
    airport_code: "YME",
    airport_name: "Matane Airport",
    airport_city_code: "YME",
    airport_city_name: "Matane",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4156,
    airport_code: "YMG",
    airport_name: "Manitouwadge Municipal Arpt",
    airport_city_code: "YMG",
    airport_city_name: "Manitouwadge",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.083889",
    airport_lon: "-85.860556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4157,
    airport_code: "YMH",
    airport_name: "Marys Harbour Arpt",
    airport_city_code: "YMH",
    airport_city_name: "Marys Harbour",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "52.3028",
    airport_lon: "-55.8472",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4158,
    airport_code: "YMI",
    airport_name: "Minaki Rail Station",
    airport_city_code: "YMI",
    airport_city_name: "Minaki",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4159,
    airport_code: "YMM",
    airport_name: "Ft McMurray Municipal Arpt",
    airport_city_code: "YMM",
    airport_city_name: "Ft McMurray",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "56.653333",
    airport_lon: "-111.221944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4160,
    airport_code: "YMN",
    airport_name: "Makkovik Arpt",
    airport_city_code: "YMN",
    airport_city_name: "Makkovik",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "55.0769",
    airport_lon: "-59.1864",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4161,
    airport_code: "YMO",
    airport_name: "Moosonee Arpt",
    airport_city_code: "YMO",
    airport_city_name: "Moosonee",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "51.291111",
    airport_lon: "-80.607778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4162,
    airport_code: "XLM",
    airport_name: "Saint Lambert Rail Station",
    airport_city_code: "YMQ",
    airport_city_name: "Montreal",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 6,
  },
  {
    airport_id: 4163,
    airport_code: "YMX",
    airport_name: "Mirabel Intl Arpt",
    airport_city_code: "YMQ",
    airport_city_name: "Montreal",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.681944",
    airport_lon: "-74.005278",
    airport_num_airports: 6,
  },
  {
    airport_id: 4164,
    airport_code: "YMY",
    airport_name: "Montreal Downtown Rail Station",
    airport_city_code: "YMQ",
    airport_city_name: "Montreal",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.499722",
    airport_lon: "-73.566111",
    airport_num_airports: 6,
  },
  {
    airport_id: 4165,
    airport_code: "YHU",
    airport_name: "St Hubert Arpt",
    airport_city_code: "YMQ",
    airport_city_name: "Montreal",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.5175",
    airport_lon: "-73.416944",
    airport_num_airports: 6,
  },
  {
    airport_id: 4166,
    airport_code: "XAX",
    airport_name: "Dorval Rail Station",
    airport_city_code: "YMQ",
    airport_city_name: "Montreal",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.448611",
    airport_lon: "-73.74111",
    airport_num_airports: 6,
  },
  {
    airport_id: 4167,
    airport_code: "YUL",
    airport_name: "Pierre Elliott Trudeau Intl Arpt",
    airport_city_code: "YMQ",
    airport_city_name: "Montreal",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.470556",
    airport_lon: "-73.740833",
    airport_num_airports: 6,
  },
  {
    airport_id: 4168,
    airport_code: "YMT",
    airport_name: "Chibougamau Arpt",
    airport_city_code: "YMT",
    airport_city_name: "Chibougamau",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.7719",
    airport_lon: "-74.5281",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4169,
    airport_code: "YNA",
    airport_name: "Natashquan Arpt",
    airport_city_code: "YNA",
    airport_city_name: "Natashquan",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "50.19",
    airport_lon: "-61.789167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4170,
    airport_code: "YNB",
    airport_name: "Yanbo Arpt",
    airport_city_code: "YNB",
    airport_city_name: "Yanbo",
    airport_country_name: "SAUDI ARABIA",
    airport_country_code: "SA",
    airport_timezone: "3",
    airport_lat: "24.144244",
    airport_lon: "38.06335",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4171,
    airport_code: "YND",
    airport_name: "Gatineau Hull Municipal Arpt",
    airport_city_code: "YND",
    airport_city_name: "Gatineau Hull",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.521694",
    airport_lon: "-75.563589",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4172,
    airport_code: "YNG",
    airport_name: "Youngstown Municipal",
    airport_city_code: "YNG",
    airport_city_name: "Youngstown",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "41.260736",
    airport_lon: "-80.679097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4173,
    airport_code: "YNJ",
    airport_name: "Yanji Arpt",
    airport_city_code: "YNJ",
    airport_city_name: "Yanji",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "42.8828",
    airport_lon: "129.451258",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4174,
    airport_code: "YNN",
    airport_name: "Yandi Arpt",
    airport_city_code: "YNN",
    airport_city_name: "Yandicoogina",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4175,
    airport_code: "YNP",
    airport_name: "Natuashish Arpt",
    airport_city_code: "YNP",
    airport_city_name: "Natuashish",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "55.9128",
    airport_lon: "-61.1853",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4176,
    airport_code: "YNT",
    airport_name: "Laishan Arpt",
    airport_city_code: "YNT",
    airport_city_name: "Yantai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "37.401667",
    airport_lon: "121.371667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4177,
    airport_code: "YNZ",
    airport_name: "Yancheng Arpt",
    airport_city_code: "YNZ",
    airport_city_name: "Yancheng",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "33.3856",
    airport_lon: "120.125",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4178,
    airport_code: "YOD",
    airport_name: "Cold Lake Arpt",
    airport_city_code: "YOD",
    airport_city_name: "Cold Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "54.404999",
    airport_lon: "-110.279444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4179,
    airport_code: "YOJ",
    airport_name: "Footner Lake Arpt",
    airport_city_code: "YOJ",
    airport_city_name: "High Level",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "58.621389",
    airport_lon: "-117.164722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4180,
    airport_code: "YOO",
    airport_name: "Oshawa Municipal Arpt",
    airport_city_code: "YOO",
    airport_city_name: "Oshawa",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.9228",
    airport_lon: "-78.895",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4181,
    airport_code: "YOP",
    airport_name: "Rainbow Lake Arpt",
    airport_city_code: "YOP",
    airport_city_name: "Rainbow Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "58.491389",
    airport_lon: "-119.407778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4182,
    airport_code: "YOS",
    airport_name: "Billy Bishop Regional Arpt",
    airport_city_code: "YOS",
    airport_city_name: "Owen Sound",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4183,
    airport_code: "YOT",
    airport_name: "Yotvata Arpt",
    airport_city_code: "YOT",
    airport_city_name: "Yotvata",
    airport_country_name: "ISRAEL",
    airport_country_code: "IL",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4184,
    airport_code: "YOW",
    airport_name: "Ottawa Intl Arpt",
    airport_city_code: "YOW",
    airport_city_name: "Ottawa",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.3225",
    airport_lon: "-75.669167",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4185,
    airport_code: "XDS",
    airport_name: "Ottawa Rail Station",
    airport_city_code: "YOW",
    airport_city_name: "Ottawa",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.4164",
    airport_lon: "-75.6517",
    airport_num_airports: 2,
  },
  {
    airport_id: 4186,
    airport_code: "YPA",
    airport_name: "Prince Albert Municipal Arpt",
    airport_city_code: "YPA",
    airport_city_name: "Prince Albert",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "53.214167",
    airport_lon: "-105.672778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4187,
    airport_code: "YPB",
    airport_name: "Port Alberni Arpt",
    airport_city_code: "YPB",
    airport_city_name: "Port Alberni",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "49.267",
    airport_lon: "-124.833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4188,
    airport_code: "YPD",
    airport_name: "Parry Sound Municipal Arpt",
    airport_city_code: "YPD",
    airport_city_name: "Parry Sound",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "70.1678",
    airport_lon: "-124.6914",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4189,
    airport_code: "YPE",
    airport_name: "Municipal Peace River",
    airport_city_code: "YPE",
    airport_city_name: "Peace River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "56.226944",
    airport_lon: "-117.447222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4190,
    airport_code: "YPF",
    airport_name: "Esquimalt Rail Station",
    airport_city_code: "YPF",
    airport_city_name: "Esquimalt",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4191,
    airport_code: "YPG",
    airport_name: "Portage La Prairie Rail Station",
    airport_city_code: "YPG",
    airport_city_name: "Portage La Prairie",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.903056",
    airport_lon: "-98.273889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4192,
    airport_code: "YPH",
    airport_name: "Inukjuak Arpt",
    airport_city_code: "YPH",
    airport_city_name: "Inukjuak",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "58.4719",
    airport_lon: "-78.0769",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4193,
    airport_code: "YPJ",
    airport_name: "Aupaluk Arpt",
    airport_city_code: "YPJ",
    airport_city_name: "Aupaluk",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "59.2967",
    airport_lon: "-69.5997",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4194,
    airport_code: "YPL",
    airport_name: "Pickle Lake Arpt",
    airport_city_code: "YPL",
    airport_city_name: "Pickle Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "51.446388",
    airport_lon: "-90.214167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4195,
    airport_code: "YPM",
    airport_name: "Pikangikum Arpt",
    airport_city_code: "YPM",
    airport_city_name: "St Pierre",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "51.8197",
    airport_lon: "-93.9733",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4196,
    airport_code: "YPN",
    airport_name: "Port Menier Arpt",
    airport_city_code: "YPN",
    airport_city_name: "Port Menier",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.836389",
    airport_lon: "-64.288611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4197,
    airport_code: "YPQ",
    airport_name: "Peterborough Arpt",
    airport_city_code: "YPQ",
    airport_city_name: "Peterborough",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "44.23",
    airport_lon: "-78.363333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4198,
    airport_code: "YPR",
    airport_name: "Digby Island Arpt",
    airport_city_code: "YPR",
    airport_city_name: "Prince Rupert",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "54.28611",
    airport_lon: "-130.444722",
    airport_num_airports: 3,
    airport_city: "true",
  },
  {
    airport_id: 4199,
    airport_code: "XDW",
    airport_name: "Prince Rupert Rail Station",
    airport_city_code: "YPR",
    airport_city_name: "Prince Rupert",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 3,
  },
  {
    airport_id: 4200,
    airport_code: "ZSW",
    airport_name: "Seal Cove Arpt",
    airport_city_code: "YPR",
    airport_city_name: "Prince Rupert",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "54.3333",
    airport_lon: "-130.283",
    airport_num_airports: 3,
  },
  {
    airport_id: 4201,
    airport_code: "YPS",
    airport_name: "Port Hawkesbury Arpt",
    airport_city_code: "YPS",
    airport_city_name: "Port Hawkesbury",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "45.6567",
    airport_lon: "-61.3681",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4202,
    airport_code: "YPW",
    airport_name: "Westview Arpt",
    airport_city_code: "YPW",
    airport_city_name: "Powell River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.8342",
    airport_lon: "-124.5",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4203,
    airport_code: "YPX",
    airport_name: "Povungnituk Arpt",
    airport_city_code: "YPX",
    airport_city_name: "Puvirnituq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "60.0506",
    airport_lon: "-77.2869",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4204,
    airport_code: "YPZ",
    airport_name: "Burns Lake Rail Station",
    airport_city_code: "YPZ",
    airport_city_name: "Burns Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "54.3717",
    airport_lon: "-125.9514",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4205,
    airport_code: "YQA",
    airport_name: "Muskoka Arpt",
    airport_city_code: "YQA",
    airport_city_name: "Muskoka",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "44.974722",
    airport_lon: "-79.303333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4206,
    airport_code: "XLJ",
    airport_name: "Quebec City Rail Station",
    airport_city_code: "YQB",
    airport_city_name: "Quebec",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 4207,
    airport_code: "XFZ",
    airport_name: "Charny Rail Station",
    airport_city_code: "YQB",
    airport_city_name: "Quebec",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 4208,
    airport_code: "YQB",
    airport_name: "Quebec Intl",
    airport_city_code: "YQB",
    airport_city_name: "Quebec",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "46.791111",
    airport_lon: "-71.393333",
    airport_num_airports: 5,
    airport_city: "true",
  },
  {
    airport_id: 4209,
    airport_code: "XFY",
    airport_name: "Sainte-Foy Rail Station",
    airport_city_code: "YQB",
    airport_city_name: "Quebec",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 4210,
    airport_code: "XLK",
    airport_name: "Levis Rail Station",
    airport_city_code: "YQB",
    airport_city_name: "Quebec",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 4211,
    airport_code: "YQC",
    airport_name: "Quaqtaq Arpt",
    airport_city_code: "YQC",
    airport_city_name: "Quaqtaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "61.0464",
    airport_lon: "-69.6178",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4212,
    airport_code: "XDZ",
    airport_name: "The Pas Rail Station",
    airport_city_code: "YQD",
    airport_city_name: "The Pas",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4213,
    airport_code: "YQD",
    airport_name: "The Pas Municipal Arpt",
    airport_city_code: "YQD",
    airport_city_name: "The Pas",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "53.9714",
    airport_lon: "-101.091",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4214,
    airport_code: "XEC",
    airport_name: "Windsor Rail Station",
    airport_city_code: "YQG",
    airport_city_name: "Windsor",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4215,
    airport_code: "YQG",
    airport_name: "Windsor Intl Arpt",
    airport_city_code: "YQG",
    airport_city_name: "Windsor",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "42.275556",
    airport_lon: "-82.955556",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4216,
    airport_code: "YQH",
    airport_name: "Watson Lake Arpt",
    airport_city_code: "YQH",
    airport_city_name: "Watson Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "60.116389",
    airport_lon: "-128.8225",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4217,
    airport_code: "YQI",
    airport_name: "Municipal Yarmouth",
    airport_city_code: "YQI",
    airport_city_name: "Yarmouth",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.8269",
    airport_lon: "-66.0881",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4218,
    airport_code: "YQK",
    airport_name: "Kenora Arpt",
    airport_city_code: "YQK",
    airport_city_name: "Kenora",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.788334",
    airport_lon: "-94.363056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4219,
    airport_code: "YQL",
    airport_name: "Lethbridge Arpt",
    airport_city_code: "YQL",
    airport_city_name: "Lethbridge",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "49.630278",
    airport_lon: "-112.799722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4220,
    airport_code: "YQM",
    airport_name: "Moncton Municipal",
    airport_city_code: "YQM",
    airport_city_name: "Moncton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "46.112221",
    airport_lon: "-64.678611",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4221,
    airport_code: "XDP",
    airport_name: "Moncton Rail Station",
    airport_city_code: "YQM",
    airport_city_name: "Moncton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4222,
    airport_code: "YQN",
    airport_name: "Nakina Rail Station",
    airport_city_code: "YQN",
    airport_city_name: "Nakina",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "50.182777",
    airport_lon: "-86.696388",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4223,
    airport_code: "YQQ",
    airport_name: "Royal Canadian Air Force Station",
    airport_city_code: "YQQ",
    airport_city_name: "Comox",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.710833",
    airport_lon: "-124.886667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4224,
    airport_code: "YQR",
    airport_name: "Regina Municipal",
    airport_city_code: "YQR",
    airport_city_name: "Regina",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "50.431944",
    airport_lon: "-104.665833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4225,
    airport_code: "YMJ",
    airport_name: "Moose Jaw Arpt",
    airport_city_code: "YQR",
    airport_city_name: "Regina",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "50.330278",
    airport_lon: "-105.559167",
    airport_num_airports: 2,
  },
  {
    airport_id: 4226,
    airport_code: "YQS",
    airport_name: "Pembroke Area Municipal Arpt",
    airport_city_code: "YQS",
    airport_city_name: "St Thomas",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "42.77",
    airport_lon: "-81.1108",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4227,
    airport_code: "YQT",
    airport_name: "Thunder Bay Arpt",
    airport_city_code: "YQT",
    airport_city_name: "Thunder Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.371944",
    airport_lon: "-89.323889",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4228,
    airport_code: "YQU",
    airport_name: "Grande Prairie Arpt",
    airport_city_code: "YQU",
    airport_city_name: "Grande Prairie",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "55.179722",
    airport_lon: "-118.885",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4229,
    airport_code: "YQV",
    airport_name: "Yorkton Airport",
    airport_city_code: "YQV",
    airport_city_name: "Yorkton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "51.264721",
    airport_lon: "-102.461667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4230,
    airport_code: "YQW",
    airport_name: "North Battleford Arpt",
    airport_city_code: "YQW",
    airport_city_name: "North Battleford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "52.769167",
    airport_lon: "-108.24361",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4231,
    airport_code: "YQX",
    airport_name: "Gander International",
    airport_city_code: "YQX",
    airport_city_name: "Gander",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "48.936944",
    airport_lon: "-54.568056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4232,
    airport_code: "YQY",
    airport_name: "Sydney Airport",
    airport_city_code: "YQY",
    airport_city_name: "Sydney",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "46.161388",
    airport_lon: "-60.047779",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4233,
    airport_code: "YQZ",
    airport_name: "Quesnel Arpt",
    airport_city_code: "YQZ",
    airport_city_name: "Quesnel",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "53.026112",
    airport_lon: "-122.510278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4234,
    airport_code: "YRB",
    airport_name: "Resolute Arpt",
    airport_city_code: "YRB",
    airport_city_name: "Resolute",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "74.716944",
    airport_lon: "-94.969444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4235,
    airport_code: "YRF",
    airport_name: "Cartwright Arpt",
    airport_city_code: "YRF",
    airport_city_name: "Cartwright",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "53.6828",
    airport_lon: "-57.0419",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4236,
    airport_code: "YRG",
    airport_name: "Rigolet Arpt",
    airport_city_code: "YRG",
    airport_city_name: "Rigolet",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "54.1797",
    airport_lon: "-58.4575",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4237,
    airport_code: "YRI",
    airport_name: "Riviere Du Loup Arpt",
    airport_city_code: "YRI",
    airport_city_name: "Riviere Du Loup",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "47.764444",
    airport_lon: "-69.584722",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4238,
    airport_code: "YRJ",
    airport_name: "Roberval Airport",
    airport_city_code: "YRJ",
    airport_city_name: "Roberval",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.52",
    airport_lon: "-72.265556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4239,
    airport_code: "YRL",
    airport_name: "Federal Red Lake",
    airport_city_code: "YRL",
    airport_city_name: "Red Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "51.0669",
    airport_lon: "-93.7931",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4240,
    airport_code: "YRQ",
    airport_name: "Three Rivers Arpt",
    airport_city_code: "YRQ",
    airport_city_name: "Trois Rivieres",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "46.3528",
    airport_lon: "-72.6794",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4241,
    airport_code: "YRT",
    airport_name: "Rankin Inlet Arpt",
    airport_city_code: "YRT",
    airport_city_name: "Rankin Inlet",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "62.81139",
    airport_lon: "-92.115833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4242,
    airport_code: "YSB",
    airport_name: "Sudbury Municipal Arpt",
    airport_city_code: "YSB",
    airport_city_name: "Sudbury",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "46.625",
    airport_lon: "-80.798889",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4243,
    airport_code: "XDY",
    airport_name: "Sudbury Junction Rail Station",
    airport_city_code: "YSB",
    airport_city_name: "Sudbury",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4244,
    airport_code: "YSC",
    airport_name: "Sherbrooke Airport",
    airport_city_code: "YSC",
    airport_city_name: "Sherbrooke",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.438611",
    airport_lon: "-71.691389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4245,
    airport_code: "YSH",
    airport_name: "Smith Falls Rail Station",
    airport_city_code: "YSH",
    airport_city_name: "Smith Falls",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4246,
    airport_code: "YSJ",
    airport_name: "St John Municipal",
    airport_city_code: "YSJ",
    airport_city_name: "St John",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "45.316111",
    airport_lon: "-65.890278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4247,
    airport_code: "YSK",
    airport_name: "Sanikiluaq Arpt",
    airport_city_code: "YSK",
    airport_city_name: "Sanikiluaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "56.5378",
    airport_lon: "-79.2467",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4248,
    airport_code: "YSL",
    airport_name: "Edmunston Arpt",
    airport_city_code: "YSL",
    airport_city_name: "St Leonard",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "47.1575",
    airport_lon: "-67.8347",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4249,
    airport_code: "YSM",
    airport_name: "Ft Smith Municipal Arpt",
    airport_city_code: "YSM",
    airport_city_name: "Ft Smith",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "60.020278",
    airport_lon: "-111.961944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4250,
    airport_code: "YSN",
    airport_name: "Salmon Arm Municipal",
    airport_city_code: "YSN",
    airport_city_name: "Salmon Arm",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "43.1917",
    airport_lon: "-79.1717",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4251,
    airport_code: "YSO",
    airport_name: "Postville Arpt",
    airport_city_code: "YSO",
    airport_city_name: "Postville",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "54.910278",
    airport_lon: "-59.785278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4252,
    airport_code: "YSP",
    airport_name: "Marathon Municipal Arpt",
    airport_city_code: "YSP",
    airport_city_name: "Marathon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.755278",
    airport_lon: "-86.344444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4253,
    airport_code: "YST",
    airport_name: "St Therese Pt Municipal",
    airport_city_code: "YST",
    airport_city_name: "St Theris Point",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "53.8456",
    airport_lon: "-94.8519",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4254,
    airport_code: "YTA",
    airport_name: "Pembroke And Area",
    airport_city_code: "YTA",
    airport_city_name: "Pembroke",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "45.864399",
    airport_lon: "-77.251701",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4255,
    airport_code: "YTD",
    airport_name: "Thicket Portage Rail Station",
    airport_city_code: "YTD",
    airport_city_name: "Thicket Portage",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "55.3189",
    airport_lon: "-97.7078",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4256,
    airport_code: "YTE",
    airport_name: "Cape Dorset Arpt",
    airport_city_code: "YTE",
    airport_city_name: "Cape Dorset",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "64.23",
    airport_lon: "-76.526667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4257,
    airport_code: "YTF",
    airport_name: "Alma Municipal Arpt",
    airport_city_code: "YTF",
    airport_city_name: "Alma",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "48.508611",
    airport_lon: "-71.641389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4258,
    airport_code: "YTH",
    airport_name: "Thompson Arpt",
    airport_city_code: "YTH",
    airport_city_name: "Thompson",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "55.801111",
    airport_lon: "-97.864166",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4259,
    airport_code: "YTJ",
    airport_name: "Terrace Bay Municipal Arpt",
    airport_city_code: "YTJ",
    airport_city_name: "Terrace Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "48.8133",
    airport_lon: "-87.0994",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4260,
    airport_code: "YTL",
    airport_name: "Big Trout Arpt",
    airport_city_code: "YTL",
    airport_city_name: "Big Trout",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "53.8178",
    airport_lon: "-89.8969",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4261,
    airport_code: "YTM",
    airport_name: "International Airport",
    airport_city_code: "YTM",
    airport_city_name: "Mont Tremblant",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "46.409444",
    airport_lon: "-74.78",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4262,
    airport_code: "YBZ",
    airport_name: "Toronto Downtown Rail Station",
    airport_city_code: "YTO",
    airport_city_name: "Toronto",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.645278",
    airport_lon: "-79.380556",
    airport_num_airports: 5,
  },
  {
    airport_id: 4263,
    airport_code: "YYZ",
    airport_name: "Lester B Pearson Intl",
    airport_city_code: "YTO",
    airport_city_name: "Toronto",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.677223",
    airport_lon: "-79.630556",
    airport_num_airports: 5,
  },
  {
    airport_id: 4264,
    airport_code: "YTZ",
    airport_name: "Toronto City Centre Airport",
    airport_city_code: "YTO",
    airport_city_name: "Toronto",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.627499",
    airport_lon: "-79.396167",
    airport_num_airports: 5,
  },
  {
    airport_id: 4265,
    airport_code: "YKZ",
    airport_name: "Buttonville Arpt",
    airport_city_code: "YTO",
    airport_city_name: "Toronto",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.862221",
    airport_lon: "-79.37",
    airport_num_airports: 5,
  },
  {
    airport_id: 4266,
    airport_code: "XLQ",
    airport_name: "Guildwood Rail Station",
    airport_city_code: "YTO",
    airport_city_name: "Toronto",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 5,
  },
  {
    airport_id: 4267,
    airport_code: "YTQ",
    airport_name: "Tasiujaq Arpt",
    airport_city_code: "YTQ",
    airport_city_name: "Tasiujaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "58.6678",
    airport_lon: "-69.9558",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4268,
    airport_code: "YTR",
    airport_name: "Trenton Municipal Arpt",
    airport_city_code: "YTR",
    airport_city_name: "Trenton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "44.118889",
    airport_lon: "-77.528056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4269,
    airport_code: "YTS",
    airport_name: "Timmins Municipal Arpt",
    airport_city_code: "YTS",
    airport_city_name: "Timmins",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.569721",
    airport_lon: "-81.376667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4270,
    airport_code: "YUD",
    airport_name: "Umiujaq Arpt",
    airport_city_code: "YUD",
    airport_city_name: "Umiujaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "56.5361",
    airport_lon: "-76.5183",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4271,
    airport_code: "YUM",
    airport_name: "Yuma Intl",
    airport_city_code: "YUM",
    airport_city_name: "Yuma",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-7",
    airport_lat: "32.656578",
    airport_lon: "-114.60598",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4272,
    airport_code: "YUX",
    airport_name: "Hall Beach Arpt",
    airport_city_code: "YUX",
    airport_city_name: "Hall Beach",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "68.776111",
    airport_lon: "-81.243611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4273,
    airport_code: "YUY",
    airport_name: "Rouyn Noranda Arpt",
    airport_city_code: "YUY",
    airport_city_name: "Rouyn Noranda",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.206111",
    airport_lon: "-78.835556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4274,
    airport_code: "YVA",
    airport_name: "Iconi Arpt",
    airport_city_code: "YVA",
    airport_city_name: "Moroni",
    airport_country_name: "COMOROS",
    airport_country_code: "KM",
    airport_timezone: "3",
    airport_lat: "-11.7108",
    airport_lon: "43.2439",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4275,
    airport_code: "HAH",
    airport_name: "Prince Said Ibrahim In",
    airport_city_code: "YVA",
    airport_city_name: "Moroni",
    airport_country_name: "COMOROS",
    airport_country_code: "KM",
    airport_timezone: "3",
    airport_lat: "-11.533661",
    airport_lon: "43.27185",
    airport_num_airports: 2,
  },
  {
    airport_id: 4276,
    airport_code: "YVB",
    airport_name: "Municipal Bonaventure",
    airport_city_code: "YVB",
    airport_city_name: "Bonaventure",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "48.0711",
    airport_lon: "-65.4603",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4277,
    airport_code: "YVE",
    airport_name: "Vernon Municipal Arpt",
    airport_city_code: "YVE",
    airport_city_name: "Vernon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "50.25",
    airport_lon: "-119.3333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4278,
    airport_code: "YVG",
    airport_name: "Vermilion Arpt",
    airport_city_code: "YVG",
    airport_city_name: "Vermilion",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "53.355833",
    airport_lon: "-110.82389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4279,
    airport_code: "YVM",
    airport_name: "Qikiqtarjuaq Arpt",
    airport_city_code: "YVM",
    airport_city_name: "Qikiqtarjuaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "67.545833",
    airport_lon: "-64.031389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4280,
    airport_code: "YVO",
    airport_name: "Val d Or Municipal Arpt",
    airport_city_code: "YVO",
    airport_city_name: "Val D Or",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.053333",
    airport_lon: "-77.782778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4281,
    airport_code: "YVP",
    airport_name: "Kuujjuaq Arpt",
    airport_city_code: "YVP",
    airport_city_name: "Kuujjuaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "58.096111",
    airport_lon: "-68.426944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4282,
    airport_code: "YVQ",
    airport_name: "Norman Wells Municipal Arpt",
    airport_city_code: "YVQ",
    airport_city_name: "Norman Wells",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "65.281617",
    airport_lon: "-126.798219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4283,
    airport_code: "YVR",
    airport_name: "Vancouver Intl Arpt",
    airport_city_code: "YVR",
    airport_city_name: "Vancouver",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.193889",
    airport_lon: "-123.184444",
    airport_num_airports: 4,
    airport_city: "true",
  },
  {
    airport_id: 4284,
    airport_code: "YDT",
    airport_name: "Boundary Bay Arpt",
    airport_city_code: "YVR",
    airport_city_name: "Vancouver",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.073889",
    airport_lon: "-123.0075",
    airport_num_airports: 4,
  },
  {
    airport_id: 4285,
    airport_code: "CXH",
    airport_name: "Coal Harbor Sea Plane Arpt",
    airport_city_code: "YVR",
    airport_city_name: "Vancouver",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.289722",
    airport_lon: "-123.115833",
    airport_num_airports: 4,
  },
  {
    airport_id: 4286,
    airport_code: "XEA",
    airport_name: "Vancouver Rail Station",
    airport_city_code: "YVR",
    airport_city_name: "Vancouver",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 4,
  },
  {
    airport_id: 4287,
    airport_code: "YVZ",
    airport_name: "Deer Lake Arpt",
    airport_city_code: "YVZ",
    airport_city_name: "Deer Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "52.6558",
    airport_lon: "-94.0614",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4288,
    airport_code: "YWB",
    airport_name: "Kangiqsujuaq Arpt",
    airport_city_code: "YWB",
    airport_city_name: "Kangiqsujuaq",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "61.588611",
    airport_lon: "-71.929444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4289,
    airport_code: "XEF",
    airport_name: "Winnipeg Rail Station",
    airport_city_code: "YWG",
    airport_city_name: "Winnipeg",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.8889",
    airport_lon: "-97.1342",
    airport_num_airports: 2,
  },
  {
    airport_id: 4290,
    airport_code: "YWG",
    airport_name: "Winnipeg Intl Arpt",
    airport_city_code: "YWG",
    airport_city_name: "Winnipeg",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "49.910036",
    airport_lon: "-97.239886",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4291,
    airport_code: "YWK",
    airport_name: "Wabush Arpt",
    airport_city_code: "YWK",
    airport_city_name: "Wabush",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "52.921944",
    airport_lon: "-66.864444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4292,
    airport_code: "YWL",
    airport_name: "Williams Lake Municipal",
    airport_city_code: "YWL",
    airport_city_name: "Williams Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "52.183056",
    airport_lon: "-122.054167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4293,
    airport_code: "YWR",
    airport_name: "White River Rail Station",
    airport_city_code: "YWR",
    airport_city_name: "White River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4294,
    airport_code: "YWS",
    airport_name: "Whistler Arpt",
    airport_city_code: "YWS",
    airport_city_name: "Whistler",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "50.1436",
    airport_lon: "-122.949",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4295,
    airport_code: "YXC",
    airport_name: "Cranbrook Municipal",
    airport_city_code: "YXC",
    airport_city_name: "Cranbrook",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "49.612222",
    airport_lon: "-115.781944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4296,
    airport_code: "YKY",
    airport_name: "Kindersley Arpt",
    airport_city_code: "YXE",
    airport_city_name: "Saskatoon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "51.5175",
    airport_lon: "-109.180833",
    airport_num_airports: 2,
  },
  {
    airport_id: 4297,
    airport_code: "YXE",
    airport_name: "Saskatoon Municipal",
    airport_city_code: "YXE",
    airport_city_name: "Saskatoon",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "52.170834",
    airport_lon: "-106.699722",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4298,
    airport_code: "YXH",
    airport_name: "Medicine Hat Airport",
    airport_city_code: "YXH",
    airport_city_name: "Medicine Hat",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "50.01889",
    airport_lon: "-110.720833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4299,
    airport_code: "YXJ",
    airport_name: "Ft St John Municipal Arpt",
    airport_city_code: "YXJ",
    airport_city_name: "Ft St John",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "56.238056",
    airport_lon: "-120.740278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4300,
    airport_code: "YXK",
    airport_name: "Rimouski Municipal Arpt",
    airport_city_code: "YXK",
    airport_city_name: "Rimouski",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "48.478056",
    airport_lon: "-68.496944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4301,
    airport_code: "YXL",
    airport_name: "Sioux Lookout Arpt",
    airport_city_code: "YXL",
    airport_city_name: "Sioux Lookout",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "50.113889",
    airport_lon: "-91.905278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4302,
    airport_code: "YXN",
    airport_name: "Whale Cove Arpt",
    airport_city_code: "YXN",
    airport_city_name: "Whale Cove",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "62.24",
    airport_lon: "-92.5981",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4303,
    airport_code: "YXP",
    airport_name: "Pangnirtung Arpt",
    airport_city_code: "YXP",
    airport_city_name: "Pangnirtung",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "66.145",
    airport_lon: "-65.713611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4304,
    airport_code: "XDV",
    airport_name: "Prince George Rail Station",
    airport_city_code: "YXS",
    airport_city_name: "Prince George",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4305,
    airport_code: "YXS",
    airport_name: "Prince George Municipal",
    airport_city_code: "YXS",
    airport_city_name: "Prince George",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "53.889444",
    airport_lon: "-122.678889",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4306,
    airport_code: "YXT",
    airport_name: "Terrace Municipal Arpt",
    airport_city_code: "YXT",
    airport_city_name: "Terrace",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "54.468508",
    airport_lon: "-128.576219",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4307,
    airport_code: "XDQ",
    airport_name: "London Rail Station",
    airport_city_code: "YXU",
    airport_city_name: "London",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4308,
    airport_code: "YXU",
    airport_name: "London Municipal",
    airport_city_code: "YXU",
    airport_city_name: "London",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "43.033056",
    airport_lon: "-81.151111",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4309,
    airport_code: "YXX",
    airport_name: "Abbotsford Arpt",
    airport_city_code: "YXX",
    airport_city_name: "Abbotsford",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.025278",
    airport_lon: "-122.360556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4310,
    airport_code: "YXY",
    airport_name: "Whitehorse Arpt",
    airport_city_code: "YXY",
    airport_city_name: "Whitehorse",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "60.709553",
    airport_lon: "-135.067269",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4311,
    airport_code: "YXZ",
    airport_name: "Municipal Wawa",
    airport_city_code: "YXZ",
    airport_city_name: "Wawa",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "47.966944",
    airport_lon: "-84.786389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4312,
    airport_code: "YYB",
    airport_name: "North Bay Municipal",
    airport_city_code: "YYB",
    airport_city_name: "North Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "46.363611",
    airport_lon: "-79.422778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4313,
    airport_code: "YYC",
    airport_name: "Calgary Intl Arpt",
    airport_city_code: "YYC",
    airport_city_name: "Calgary",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "51.113888",
    airport_lon: "-114.020278",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4314,
    airport_code: "YQF",
    airport_name: "Red Deer Arpt",
    airport_city_code: "YYC",
    airport_city_name: "Calgary",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "52.182222",
    airport_lon: "-113.894444",
    airport_num_airports: 2,
  },
  {
    airport_id: 4315,
    airport_code: "YYD",
    airport_name: "Smithers Municipal",
    airport_city_code: "YYD",
    airport_city_name: "Smithers",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "54.824722",
    airport_lon: "-127.182778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4316,
    airport_code: "YYE",
    airport_name: "Ft Nelson Municipal Arpt",
    airport_city_code: "YYE",
    airport_city_name: "Fort Nelson",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "58.836389",
    airport_lon: "-122.596944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4317,
    airport_code: "YYF",
    airport_name: "Penticton Municipal Arpt",
    airport_city_code: "YYF",
    airport_city_name: "Penticton",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.463056",
    airport_lon: "-119.602222",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4318,
    airport_code: "YYG",
    airport_name: "Charlottetown Municipal",
    airport_city_code: "YYG",
    airport_city_name: "Charlottetown",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "46.290001",
    airport_lon: "-63.121111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4319,
    airport_code: "YYH",
    airport_name: "Taloyoak Arpt",
    airport_city_code: "YYH",
    airport_city_name: "Taloyoak",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "69.546667",
    airport_lon: "-93.576667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4320,
    airport_code: "YYI",
    airport_name: "Rivers Rail Station",
    airport_city_code: "YYI",
    airport_city_name: "Rivers",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4321,
    airport_code: "YYJ",
    airport_name: "Victoria Intl Arpt",
    airport_city_code: "YYJ",
    airport_city_name: "Victoria",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "48.646944",
    airport_lon: "-123.425833",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4322,
    airport_code: "YWH",
    airport_name: "Inner Harbor Sea Plane Arpt",
    airport_city_code: "YYJ",
    airport_city_name: "Victoria",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "48.422778",
    airport_lon: "-123.3875",
    airport_num_airports: 2,
  },
  {
    airport_id: 4323,
    airport_code: "YYL",
    airport_name: "Lynn Lake Rail Station",
    airport_city_code: "YYL",
    airport_city_name: "Lynn Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "56.863888",
    airport_lon: "-101.07611",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4324,
    airport_code: "YYN",
    airport_name: "Swift Current Arpt",
    airport_city_code: "YYN",
    airport_city_name: "Swift Current",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "50.291944",
    airport_lon: "-107.690556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4325,
    airport_code: "XAD",
    airport_name: "Churchill Rail Station",
    airport_city_code: "YYQ",
    airport_city_name: "Churchill",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "58.76775",
    airport_lon: "-94.17425",
    airport_num_airports: 2,
  },
  {
    airport_id: 4326,
    airport_code: "YYQ",
    airport_name: "Churchill Arpt",
    airport_city_code: "YYQ",
    airport_city_name: "Churchill",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "58.739167",
    airport_lon: "-94.065",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4327,
    airport_code: "YYR",
    airport_name: "Municipal Goose Bay",
    airport_city_code: "YYR",
    airport_city_name: "Goose Bay",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "53.319168",
    airport_lon: "-60.425833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4328,
    airport_code: "YYT",
    airport_name: "St Johns Arpt",
    airport_city_code: "YYT",
    airport_city_name: "St Johns",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-3",
    airport_lat: "47.61861",
    airport_lon: "-52.751945",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4329,
    airport_code: "YYU",
    airport_name: "Japuskasing Municipal Arpt",
    airport_city_code: "YYU",
    airport_city_name: "Kapuskasing",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "49.413889",
    airport_lon: "-82.4675",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4330,
    airport_code: "YYW",
    airport_name: "Armstrong Rail Station",
    airport_city_code: "YYW",
    airport_city_name: "Armstromg",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "50.290279",
    airport_lon: "-88.909721",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4331,
    airport_code: "YYY",
    airport_name: "Mont Joli Arpt",
    airport_city_code: "YYY",
    airport_city_name: "Mont Joli",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "48.608612",
    airport_lon: "-68.208056",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4332,
    airport_code: "YZA",
    airport_name: "Ashcroft Rail Station",
    airport_city_code: "YZA",
    airport_city_name: "Ashcroft",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4333,
    airport_code: "YZF",
    airport_name: "Yellowknife Arpt",
    airport_city_code: "YZF",
    airport_city_name: "Yellowknife",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "62.462778",
    airport_lon: "-114.440278",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4334,
    airport_code: "YZG",
    airport_name: "Salluit Arpt",
    airport_city_code: "YZG",
    airport_city_name: "Salluit",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "62.1794",
    airport_lon: "-75.6672",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4335,
    airport_code: "YZP",
    airport_name: "Federal Airport",
    airport_city_code: "YZP",
    airport_city_name: "Sandspit",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "53.254333",
    airport_lon: "-131.813833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4336,
    airport_code: "YZR",
    airport_name: "Sarnia Airport",
    airport_city_code: "YZR",
    airport_city_name: "Sarnia",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "42.999444",
    airport_lon: "-82.308889",
    airport_num_airports: 2,
    airport_city: "true",
  },
  {
    airport_id: 4337,
    airport_code: "XDX",
    airport_name: "Sarina Rail Station",
    airport_city_code: "YZR",
    airport_city_name: "Sarnia",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 2,
  },
  {
    airport_id: 4338,
    airport_code: "YZS",
    airport_name: "Coral Harbour Arpt",
    airport_city_code: "YZS",
    airport_city_name: "Coral Harbour",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-7",
    airport_lat: "50.330278",
    airport_lon: "-115.873333",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4339,
    airport_code: "YZT",
    airport_name: "Port Hardy Municipal",
    airport_city_code: "YZT",
    airport_city_name: "Port Hardy",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "50.680556",
    airport_lon: "-127.366667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4340,
    airport_code: "YZV",
    airport_name: "Sept Iles Municipal Arpt",
    airport_city_code: "YZV",
    airport_city_name: "Sept Iles",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-5",
    airport_lat: "50.223333",
    airport_lon: "-66.265556",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4341,
    airport_code: "YZX",
    airport_name: "Greenwood Arpt",
    airport_city_code: "YZX",
    airport_city_name: "Greenwood",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "44.984444",
    airport_lon: "-64.916944",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4342,
    airport_code: "YZZ",
    airport_name: "Trail Arpt",
    airport_city_code: "YZZ",
    airport_city_name: "Trail",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-8",
    airport_lat: "49.055556",
    airport_lon: "-117.609167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4343,
    airport_code: "ZAD",
    airport_name: "Zadar Arpt",
    airport_city_code: "ZAD",
    airport_city_name: "Zadar",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "44.108269",
    airport_lon: "15.346697",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4344,
    airport_code: "ZAG",
    airport_name: "Zagreb Arpt",
    airport_city_code: "ZAG",
    airport_city_name: "Zagreb",
    airport_country_name: "CROATIA",
    airport_country_code: "HR",
    airport_timezone: "1",
    airport_lat: "45.742931",
    airport_lon: "16.068778",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4345,
    airport_code: "ZAL",
    airport_name: "Pichoy",
    airport_city_code: "ZAL",
    airport_city_name: "Valdivia",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-39.649956",
    airport_lon: "-73.086111",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4346,
    airport_code: "ZAO",
    airport_name: "Laberandie Arpt",
    airport_city_code: "ZAO",
    airport_city_name: "Cahors",
    airport_country_name: "FRANCE",
    airport_country_code: "FR",
    airport_timezone: "-100",
    airport_lat: "44.3506",
    airport_lon: "1.4786",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4347,
    airport_code: "ZAZ",
    airport_name: "Zaragoza Airport",
    airport_city_code: "ZAZ",
    airport_city_name: "Zaragoza",
    airport_country_name: "SPAIN",
    airport_country_code: "ES",
    airport_timezone: "1",
    airport_lat: "41.666242",
    airport_lon: "-1.041553",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4348,
    airport_code: "ZBD",
    airport_name: "Jiang Men Ferry",
    airport_city_code: "ZBD",
    airport_city_name: "Jiang Men",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4349,
    airport_code: "ZBF",
    airport_name: "Bathurst Arpt",
    airport_city_code: "ZBF",
    airport_city_name: "Bathurst",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "47.6297",
    airport_lon: "-65.7389",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4350,
    airport_code: "ZBL",
    airport_name: "Biloela Arpt",
    airport_city_code: "ZBL",
    airport_city_name: "Biloela",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "-100",
    airport_lat: "-24.4",
    airport_lon: "150.5",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4351,
    airport_code: "ZBM",
    airport_name: "Bromont Regional Arpt",
    airport_city_code: "ZBM",
    airport_city_name: "Bromont",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "45.2908",
    airport_lon: "-72.7414",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4352,
    airport_code: "ZBW",
    airport_name: "Atibaia Arpt",
    airport_city_code: "ZBW",
    airport_city_name: "Atibaia",
    airport_country_name: "BRAZIL",
    airport_country_code: "BR",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4353,
    airport_code: "ZBZ",
    airport_name: "Xin Hui Ferry Arpt",
    airport_city_code: "ZBZ",
    airport_city_name: "Xin Hui",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4354,
    airport_code: "ZCL",
    airport_name: "Zacatecas Airport",
    airport_city_code: "ZCL",
    airport_city_name: "Zacatecas",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "22.897112",
    airport_lon: "-102.68689",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4355,
    airport_code: "ZCO",
    airport_name: "Manquehue Arpt",
    airport_city_code: "ZCO",
    airport_city_name: "Temuco",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-38.766819",
    airport_lon: "-72.637097",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4356,
    airport_code: "ZCU",
    airport_name: "She Kou Ferry",
    airport_city_code: "ZCU",
    airport_city_name: "She Kou",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4357,
    airport_code: "ZEC",
    airport_name: "Secunda Arpt",
    airport_city_code: "ZEC",
    airport_city_name: "Secunda",
    airport_country_name: "SOUTH AFRICA",
    airport_country_code: "ZA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4358,
    airport_code: "ZFI",
    airport_name: "Chesterfield Bus Station",
    airport_city_code: "ZFI",
    airport_city_name: "Chesterfield",
    airport_country_name: "UNITED KINGDOM",
    airport_country_code: "GB",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4359,
    airport_code: "ZFV",
    airport_name: "Philadelphia Rail Station",
    airport_city_code: "ZFV",
    airport_city_name: "Philadelphia Rail Station",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "39.9557",
    airport_lon: "-75.182",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4360,
    airport_code: "ZGS",
    airport_name: "Gethsemanie Arpt",
    airport_city_code: "ZGS",
    airport_city_name: "Gethsemanie",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "50.333",
    airport_lon: "-60.667",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4361,
    airport_code: "ZHA",
    airport_name: "Zhanjiang Airport",
    airport_city_code: "ZHA",
    airport_city_name: "Zhangjiang",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "21.2144",
    airport_lon: "110.358",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4362,
    airport_code: "ZHO",
    airport_name: "Houston Bus Station",
    airport_city_code: "ZHO",
    airport_city_name: "Houston",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4363,
    airport_code: "ZIG",
    airport_name: "Ziguinchor Arpt",
    airport_city_code: "ZIG",
    airport_city_name: "Ziguinchor",
    airport_country_name: "SENEGAL",
    airport_country_code: "SN",
    airport_timezone: "0",
    airport_lat: "12.555617",
    airport_lon: "-16.281783",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4364,
    airport_code: "ZIH",
    airport_name: "Zihuatanejo Intl",
    airport_city_code: "ZIH",
    airport_city_name: "Zihuatanejo",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "17.601569",
    airport_lon: "-101.460536",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4365,
    airport_code: "ZJN",
    airport_name: "Swan River Municipal Arpt",
    airport_city_code: "ZJN",
    airport_city_name: "Swan River",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "52.1206",
    airport_lon: "-101.236",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4366,
    airport_code: "ZKG",
    airport_name: "Kegaska Arpt",
    airport_city_code: "ZKG",
    airport_city_name: "Kegaska",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "50.195833",
    airport_lon: "-61.265833",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4367,
    airport_code: "ZLO",
    airport_name: "Manzanillo Arpt",
    airport_city_code: "ZLO",
    airport_city_name: "Manzanillo",
    airport_country_name: "MEXICO",
    airport_country_code: "MX",
    airport_timezone: "-6",
    airport_lat: "19.144778",
    airport_lon: "-104.558631",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4368,
    airport_code: "ZLT",
    airport_name: "La Tabatiere Arpt",
    airport_city_code: "ZLT",
    airport_city_name: "La Tabatiere",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "50.8308",
    airport_lon: "-58.9756",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4369,
    airport_code: "ZNE",
    airport_name: "Newman Airport",
    airport_city_code: "ZNE",
    airport_city_name: "Newman",
    airport_country_name: "AUSTRALIA",
    airport_country_code: "AU",
    airport_timezone: "8",
    airport_lat: "-23.4178",
    airport_lon: "119.803",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4370,
    airport_code: "IGS",
    airport_name: "Ingolstadt Arpt",
    airport_city_code: "ZNQ",
    airport_city_name: "Ingolstadt",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "48.7777",
    airport_lon: "11.422",
    airport_num_airports: 1,
  },
  {
    airport_id: 4371,
    airport_code: "ZNZ",
    airport_name: "Kisauni Arpt",
    airport_city_code: "ZNZ",
    airport_city_name: "Zanzibar",
    airport_country_name: "TANZANIA",
    airport_country_code: "TZ",
    airport_timezone: "3",
    airport_lat: "-6.222025",
    airport_lon: "39.224886",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4372,
    airport_code: "ZOS",
    airport_name: "Canal Balo Arpt",
    airport_city_code: "ZOS",
    airport_city_name: "Osorno",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-40.611208",
    airport_lon: "-73.061042",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4373,
    airport_code: "ZPC",
    airport_name: "Pucon Arpt",
    airport_city_code: "ZPC",
    airport_city_name: "Pucon",
    airport_country_name: "CHILE",
    airport_country_code: "CL",
    airport_timezone: "-4",
    airport_lat: "-39.2928",
    airport_lon: "-71.9159",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4374,
    airport_code: "ZPE",
    airport_name: "Osnabrueck Rail Service",
    airport_city_code: "ZPE",
    airport_city_name: "Osnabruck",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4375,
    airport_code: "ZPP",
    airport_name: "Rail Station Reutlingen",
    airport_city_code: "ZPP",
    airport_city_name: "Reutlingen",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4376,
    airport_code: "ZQF",
    airport_name: "Trier Arpt",
    airport_city_code: "ZQF",
    airport_city_name: "Trier",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.8635",
    airport_lon: "6.788167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4377,
    airport_code: "ZQN",
    airport_name: "Frankton Airport",
    airport_city_code: "ZQN",
    airport_city_name: "Queenstown",
    airport_country_name: "NEW ZEALAND",
    airport_country_code: "NZ",
    airport_timezone: "12",
    airport_lat: "-45.021111",
    airport_lon: "168.739167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4378,
    airport_code: "ZQS",
    airport_name: "Queen Charlotte Island Arpt",
    airport_city_code: "ZQS",
    airport_city_name: "Queen Charlotte Island",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4379,
    airport_code: "ZQW",
    airport_name: "Zweibruecken Arpt",
    airport_city_code: "ZQW",
    airport_city_name: "Zweibruecken",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "49.209445",
    airport_lon: "7.401323",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4380,
    airport_code: "ZRH",
    airport_name: "Zurich Airport",
    airport_city_code: "ZRH",
    airport_city_name: "Zurich",
    airport_country_name: "SWITZERLAND",
    airport_country_code: "CH",
    airport_timezone: "1",
    airport_lat: "47.464722",
    airport_lon: "8.549167",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4381,
    airport_code: "ZSA",
    airport_name: "San Salvador Arpt",
    airport_city_code: "ZSA",
    airport_city_name: "San Salvador",
    airport_country_name: "BAHAMAS",
    airport_country_code: "BS",
    airport_timezone: "-5",
    airport_lat: "24.063275",
    airport_lon: "-74.523967",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4382,
    airport_code: "ZSE",
    airport_name: "St Pierre Dela Reunion Arpt",
    airport_city_code: "ZSE",
    airport_city_name: "St Pierre Dela Reunion",
    airport_country_name: "REUNION",
    airport_country_code: "RE",
    airport_timezone: "5",
    airport_lat: "-21.320039",
    airport_lon: "55.423581",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4383,
    airport_code: "ZSG",
    airport_name: "Sonneberg Railway Station",
    airport_city_code: "ZSG",
    airport_city_name: "Sonneberg",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4384,
    airport_code: "ZSJ",
    airport_name: "Sandy Lake Arpt",
    airport_city_code: "ZSJ",
    airport_city_name: "Sandy Lake",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-6",
    airport_lat: "53.0642",
    airport_lon: "-93.3444",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4385,
    airport_code: "SZW",
    airport_name: "Parchim Arpt",
    airport_city_code: "ZSR",
    airport_city_name: "Schwerin",
    airport_country_name: "GERMANY",
    airport_country_code: "DE",
    airport_timezone: "1",
    airport_lat: "53.426997",
    airport_lon: "11.783436",
    airport_num_airports: 1,
  },
  {
    airport_id: 4386,
    airport_code: "ZTB",
    airport_name: "Tete A La Baleine Arpt",
    airport_city_code: "ZTB",
    airport_city_name: "Tete A La Baleine",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "50.6744",
    airport_lon: "-59.3836",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4387,
    airport_code: "ZTH",
    airport_name: "Zakinthos Arpt",
    airport_city_code: "ZTH",
    airport_city_name: "Zakinthos",
    airport_country_name: "GREECE",
    airport_country_code: "GR",
    airport_timezone: "2",
    airport_lat: "37.750853",
    airport_lon: "20.88425",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4388,
    airport_code: "ZUH",
    airport_name: "Zhuhai Arpt",
    airport_city_code: "ZUH",
    airport_city_name: "Zhuhai",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "8",
    airport_lat: "22.0064",
    airport_lon: "113.376",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4389,
    airport_code: "ZUM",
    airport_name: "Churchhill Falls Arpt",
    airport_city_code: "ZUM",
    airport_city_name: "Churchhill Falls",
    airport_country_name: "CANADA",
    airport_country_code: "CA",
    airport_timezone: "-4",
    airport_lat: "53.5619",
    airport_lon: "-64.1064",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4390,
    airport_code: "ZVD",
    airport_name: "Drangedal Rail Station",
    airport_city_code: "ZVD",
    airport_city_name: "Drangedal",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4391,
    airport_code: "ZXO",
    airport_name: "Fauske Rail Station",
    airport_city_code: "ZXO",
    airport_city_name: "Fauske",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4392,
    airport_code: "ZXU",
    airport_name: "Rygge Rail Station",
    airport_city_code: "ZXU",
    airport_city_name: "Rygge",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4393,
    airport_code: "ZXX",
    airport_name: "Rade Rail Station",
    airport_city_code: "ZXX",
    airport_city_name: "Rade",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4394,
    airport_code: "ZYI",
    airport_name: "Zunyi Arpt",
    airport_city_code: "ZYI",
    airport_city_name: "Zunyi",
    airport_country_name: "CHINA",
    airport_country_code: "CN",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4395,
    airport_code: "ZYL",
    airport_name: "Osmani International Airport",
    airport_city_code: "ZYL",
    airport_city_name: "Sylhet",
    airport_country_name: "BANGLADESH",
    airport_country_code: "BD",
    airport_timezone: "6",
    airport_lat: "24.963242",
    airport_lon: "91.866783",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4396,
    airport_code: "ZYP",
    airport_name: "New York Penn STN",
    airport_city_code: "ZYP",
    airport_city_name: "New York Penn STN",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-5",
    airport_lat: "40.7505",
    airport_lon: "-73.9935",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4397,
    airport_code: "ZYV",
    airport_name: "Vegarshei Rail Station",
    airport_city_code: "ZYV",
    airport_city_name: "Vegarshei",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4398,
    airport_code: "ZYW",
    airport_name: "Sandvika Rail Station",
    airport_city_code: "ZYW",
    airport_city_name: "Sandvika",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4399,
    airport_code: "ZYY",
    airport_name: "Marnardal Rail Station",
    airport_city_code: "ZYY",
    airport_city_name: "Marnardal",
    airport_country_name: "NORWAY",
    airport_country_code: "NO",
    airport_timezone: "-100",
    airport_lat: "0",
    airport_lon: "0",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4400,
    airport_code: "ZZV",
    airport_name: "Zanesville Arpt",
    airport_city_code: "ZZV",
    airport_city_name: "Zanesville",
    airport_country_name: "UNITED STATES",
    airport_country_code: "US",
    airport_timezone: "-100",
    airport_lat: "39.9445",
    airport_lon: "-81.8921",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4401,
    airport_code: "PRG",
    airport_name: "Vodochody Arpt",
    airport_city_code: "PRG",
    airport_city_name: "Prague",
    airport_country_name: "CZECH REPUBLIC",
    airport_country_code: "CZ",
    airport_timezone: "1",
    airport_lat: "50.100833",
    airport_lon: "14.26",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4402,
    airport_code: "QYG",
    airport_name: "Railway Germany",
    airport_city_code: "QYG",
    airport_city_name: "Railway Germany",
    airport_country_name: "Germany",
    airport_country_code: "DE",
    airport_lat: "54",
    airport_lon: "13",
    airport_num_airports: 0,
  },
  {
    airport_id: 4403,
    airport_code: "QYG",
    airport_name: "Railway Germany",
    airport_city_code: "QYG",
    airport_city_name: "Railway Germany",
    airport_country_name: "Germany",
    airport_country_code: "DE",
    airport_lat: "54",
    airport_lon: "13",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4404,
    airport_code: "BZL",
    airport_name: "Barisal Airport",
    airport_city_code: "BZL",
    airport_city_name: "Barisal",
    airport_country_name: "Bangladesh",
    airport_country_code: "BD",
    airport_num_airports: 0,
    airport_city: "true",
  },
  {
    airport_id: 4405,
    airport_code: "CGP",
    airport_name: "Shah Amanat International Airport",
    airport_city_code: "CGP",
    airport_city_name: "Chittagong",
    airport_country_name: "Bangladesh",
    airport_country_code: "BD",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4406,
    airport_code: "CXB",
    airport_name: "Cox's Bazar Airport",
    airport_city_code: "CXB",
    airport_city_name: "Cox's Bazar",
    airport_country_name: "Bangladesh",
    airport_country_code: "BD",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4407,
    airport_code: "JSR",
    airport_name: "Jessore Airport",
    airport_city_code: "JSR",
    airport_city_name: "Jessore",
    airport_country_name: "Bangladesh",
    airport_country_code: "BD",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4408,
    airport_code: "RJH",
    airport_name: "Shah Makhdum Airport",
    airport_city_code: "RJH",
    airport_city_name: "Rajshahi",
    airport_country_name: "Bangladesh",
    airport_country_code: "BD",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4409,
    airport_code: "SPD",
    airport_name: "Saidpur Airport",
    airport_city_code: "SPD",
    airport_city_name: "Saidpur Nilphamari",
    airport_country_name: "Bangladesh",
    airport_country_code: "BD",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4411,
    airport_code: "STV",
    airport_name: "Surat Airport",
    airport_city_code: "STV",
    airport_city_name: "Surat Magdalla",
    airport_country_name: "India",
    airport_country_code: "IN",
    airport_num_airports: 1,
    airport_city: "true",
  },
  {
    airport_id: 4412,
    airport_code: "CNN",
    airport_name: "Kannur International Airport",
    airport_city_code: "CNN",
    airport_city_name: "Kannur",
    airport_country_name: "India",
    airport_country_code: "IN",
    airport_num_airports: 1,
    airport_city: "1",
  },
  {
    airport_id: 4413,
    airport_code: "SAG",
    airport_name: "Shirdi International Airport",
    airport_city_code: "SAG",
    airport_city_name: "Shirdi",
    airport_country_name: "India",
    airport_country_code: "IN",
    airport_num_airports: 1,
    airport_city: "1",
  },
];